import main_logo from "../../img/eclub.golf.png"
import { IonPage, IonToolbar, isPlatform, IonButtons, IonTitle, IonMenuButton, IonContent, IonGrid, IonRow, IonCol, IonCard, IonIcon, IonText } from '@ionic/react';
import { useEffect, useState } from 'react';
import { calendar, chatbubbles, trophy, cart } from "ionicons/icons";
// eslint-disable-next-line
const AdminHome: React.FC = () => {
  const [current_user, setCurrentUser] = useState<any>([]);
  useEffect(() => {
      let user = localStorage.getItem('firebase_user');
      if(user != null) {
        setCurrentUser(JSON.parse(user));
      }
  }, [])
  return (
    <IonPage>
      <IonToolbar mode="ios" className={isPlatform('ios') ? 'ios-toolbar-top': 'toolbar-top' }>
        <IonButtons slot="start">
          <img src={current_user.logo_url} className="pro_pic"/>
        </IonButtons>
        <IonTitle>
          <img src={main_logo} className="main_logo"/>
        </IonTitle>
        <IonButtons slot="end">
          <IonMenuButton>
          </IonMenuButton>
        </IonButtons>
      </IonToolbar>
      <IonContent>
        <IonGrid>
          <IonRow className="ion-justify-content-center ion-text-center">
            Admin
          </IonRow>
        </IonGrid>
        <IonRow className="ion-text-center ion-justify-content-center">
          <IonCol style={{width:"120px"}} onClick={()=>{
            window.location.href="/admin_feed"
          }}>              
            <IonCard className="ion-padding admin-cards" style={{margin:"auto"}}>
              <IonIcon icon={chatbubbles} className="s-35 color-blue"/>
            </IonCard>     
            <br/>                               
            <IonText className="s-16 wg-600">Feed</IonText>                                    
          </IonCol>
          <IonCol style={{width:"120px"}} onClick={()=>{
            window.location.href="/leaderboards"
          }}>              
            <IonCard className="ion-padding admin-cards" style={{margin:"auto"}}>
              <IonIcon icon={chatbubbles} className="s-35 color-blue"/>
            </IonCard>     
            <br/>                               
            <IonText className="s-16 wg-600">Generate LeaderBoards</IonText>                                    
          </IonCol>
          <IonCol style={{width:"120px"}} onClick={()=>{
            window.location.href="/admin_events"
          }}>              
            <IonCard className="ion-padding admin-cards" style={{margin:"auto"}}>
              <IonIcon icon={calendar} className="s-35 color-blue"/>
            </IonCard>     
            <br/>                               
            <IonText className="s-16 wg-600">Events</IonText>                                    
          </IonCol>
          <IonCol style={{width:"120px"}} onClick={()=>{
            window.location.href="/admin_championships"
          }}>              
            <IonCard className="ion-padding admin-cards" style={{margin:"auto"}}>
              <IonIcon icon={trophy} className="s-35 color-blue"/>
            </IonCard>     
            <br/>                               
            <IonText className="s-16 wg-600">Championships</IonText>                                    
          </IonCol>
          <IonCol style={{width:"120px"}} onClick={()=>{
            window.location.href="/user_registrations"
          }}>              
            <IonCard className="ion-padding admin-cards" style={{margin:"auto"}}>
              <IonIcon icon={trophy} className="s-35 color-blue"/>
            </IonCard>     
            <br/>                               
            <IonText className="s-16 wg-600">Registrations</IonText>                                    
          </IonCol>
          <IonCol style={{width:"120px"}} onClick={()=>{
            window.location.href="/manage_pro_shop"
          }}>              
            <IonCard className="ion-padding admin-cards" style={{margin:"auto"}}>
              <IonIcon icon={cart} className="s-35 color-blue"/>
            </IonCard>     
            <br/>                               
            <IonText className="s-16 wg-600">Pro Shop</IonText>                                    
          </IonCol>
          <IonCol style={{width:"120px"}} onClick={()=>{
            window.location.href="/admin_tours"
          }}>              
            <IonCard className="ion-padding admin-cards" style={{margin:"auto"}}>
              <IonIcon icon={cart} className="s-35 color-blue"/>
            </IonCard>     
            <br/>                               
            <IonText className="s-16 wg-600">Tours</IonText>                                    
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  )
};

export default AdminHome;