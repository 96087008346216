import main_logo from "../../img/eclub.golf.png";
import ppw_logo from "../../img/ppw.png";
import default_profile_logo from "../../img/fewbats.svg";
import tao_logo from "../../img/netscore.png";
import tt_logo from "../../img/tao.png";
import default_user_logo from "../../img/default.png";
import * as utils from "../../js/utils";
import Table from '../Components/table';
import Quill from '../Components/get_quill';
import BottomBar from '../Components/bottom_toolbar'
import PayPal from '../Components/paypal_payment';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { Checkmark, CheckmarkDone, TrashBin } from "@styled-icons/ionicons-outline";
import { CalendarEdit } from "@styled-icons/fluentui-system-regular";
import { Cancel } from "@styled-icons/material-rounded";
import { ChatLeftText } from "@styled-icons/bootstrap";

import {
  IonButton,
  IonInput,
  IonMenuToggle,
  IonRange,
  IonSelect,
  IonSelectOption,
  IonSlide,
  IonSlides,
  isPlatform,
  useIonAlert,
  useIonToast,
  IonSearchbar,
  IonList
} from "@ionic/react";
import {
  IonModal,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonButtons,
  IonSegment,
  IonSegmentButton,
  IonGrid,
  IonFab,
  IonFabButton,
  IonFabList,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonAvatar,
  IonItem,
  IonRow,
  IonCol,
  IonText,
  useIonActionSheet,
  IonAccordion,
  IonAccordionGroup,
} from "@ionic/react";
import "../../css/locker_room.css";
import {
  add,
  create,
  trash,
  duplicateOutline,
  peopleOutline,
  informationCircleOutline,
  notificationsOutline,
  chevronDownOutline,
  chevronUpOutline,
  chevronForwardOutline,
  arrowBackOutline,
  addCircleOutline,
  paperPlane,
  closeOutline
} from "ionicons/icons";
import {
  Chatbubbles,
  Calendar,
  Gift,
  Grid,
} from '@styled-icons/ionicons-outline';
import {
  Golf
} from '@styled-icons/ionicons-solid';
import {
  useEffect,
  useState,
} from "react";
import _ from "lodash";
import { format } from "date-fns";
import firebase from "../../js/firebase.js";
import EditTable from "../Components/edit_table";



import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  getDocs,
  deleteDoc,
  orderBy
} from "firebase/firestore";
import {
  createEntryWithoutId,
  updateEntry,
} from "../../js/firebase_doc_functions";
import { Browser } from "@capacitor/browser";
import Chart from "react-apexcharts";
import PpwScoreTable from "../Components/ppw_score_table";
import { useParams } from "react-router";
const db = getFirestore(firebase);
const Leagues: React.FC = () => {
  const { tab }: any = useParams();
  const [leagues_tab_value, setLeaguesTabValue] = useState<string>(tab && tab === "scores" ? "Scores" : "Leagues");
  const [present, dismiss] = useIonActionSheet();
  const [handicap, setHandicap] = useState<string>("N/A");
  const [current_user, setCurrentUser] = useState<any>([]);
  const [current_pack, setCurrentPack] = useState<any>({});
  const [display_pack, setDisplayPack] = useState<any>({});
  const [best_round, setBestRound] = useState<any>([]);
  const [best_rounds, setBestRounds] = useState<any>([]);
  const [comments, setComments] = useState<any>([]);
  const [all_rounds, setAllRounds] = useState<any>([]);
  const [other_rounds, setOtherRounds] = useState<any>([]);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [profile_data, setProfileData] = useState<any>({});
  const [latest_round, setLatestRound] = useState<any>({});
  const [state_values, setStateValues] = useState<any>([]);
  const [state2_values, setState2Values] = useState<any>([]);
  const [pack_city, setPackCity] = useState<string>('');
  const [showJoinPackModal, setShowJoinPackModal] = useState(false);
  const [selected_pack_id, setSelectedPackId] = useState("");
  const [url, setUrl] = useState<string>("");
  const [notifications, setNotifications] = useState<boolean>(false);
  const [selected_pack, setSelectedPack] = useState<any>([]);
  const [front_holes, setFrontHoles] = useState<boolean>(false);
  const [back_holes, setBackHoles] = useState<boolean>(false);
  const [packs_list, setPacksList] = useState<any>([]);
  const [presentToast, dismissToast] = useIonToast();
  const [chips_qty, setChipsQty] = useState<any>(250);
  const [amount, setAmount] = useState<any>(25);
  const [pack_message, setPackMessage] = useState<string>();
  const [presentAlert, dismissAlert] = useIonAlert();
  const [content, setContent] = useState<any>('');
  const [key_date, setKeyDate] = useState<number>();
  const [required_data, setRequiredData] = useState<any>();
  const [all_packs, setAllPacks] = useState<any>([]);
  const [pack_users, setPackUsers] = useState<any>([]);
  const [val2, setVal2] = useState<any>([
    "Eagles",
    "Birds",
    "Par",
    "Bogey",
    "Double",
    "Triple",
  ]);
  const [paypal_data, setPaypalData] = useState<any>();
  const [set_paypal_payment, setPaypalPayment] = useState(false);
  const [buy_format, setBuyFormat] = useState<string>('');
  const [referrer, setReferrer] = useState<string>('');
  const [packs,setPacks] = useState<any>([]);
  const [new_packs,setNewPacks] = useState<any>([]);
  const [pack_id,setPackId] = useState<string>('');
  const handleCallback = async () => {
    if (referrer === "ppw") {
      await updateEntry("USER_PROFILE", current_user.id, { ppw_validated: true });
      current_user.ppw_validated = true;
      localStorage.setItem('firebase_user', JSON.stringify(current_user));
      setPaypalPayment(false);
      showToast('Successfully signed up for Play.Post.Win.', 2000);
    } else if (referrer === "netscore") {
      await updateEntry("USER_PROFILE", current_user.id, { netscore_validated: true });
      current_user.netscore_validated = true;
      localStorage.setItem('firebase_user', JSON.stringify(current_user));
      setPaypalPayment(false);
      showToast('Successfully signed up for Net Score Challenge.', 2000);
    } else if (referrer === "tt") {
      if (tour && tour.id.length > 0) {
        let players = tour.players.filter((x: any) =>
          x.user_id == current_user.user_id,
        );
        if (players.length <= 0) {
          tour.players.push({ user_id: current_user.user_id, user_name: current_user.first_name + ' ' + current_user.last_name });
          await updateEntry("TOUR_SETTINGS", tour.id, { players: tour.players })
          await createEntryWithoutId("TOUR_SIGNUPS", {
            tour_name: tour.tour_name,
            tour_city: tour.city,
            format: tour.format,
            first_name: current_user.first_name,
            last_name: current_user.last_name,
            company: "",
            email: current_user.email,
            city: current_user.city,
            tour_id: tour.id,
            user_id: current_user.user_id,
            players: tour.players
          });
          getTourData(tour.id);
          setPaypalPayment(false);
          setShowTourModal(false);
          showToast('Successfully signed up for tour', 2000);
        } else {
          setPaypalPayment(false);
          setShowTourModal(false);
          showToast('Error!', 2000);
        }
      } else {
        showToast('Error fecthing tour details. Please try again after some time.', 2000);
      }
    }
  }
  const getNotificationCounts = async (user_id: string) => {
    const q = query(collection(db, "CHECKIN_NOTIFICATIONS"), where('created_at', '>', 0), where('user_id', '==', user_id));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
      querySnapshot.docs.forEach((notifications) => {
        const notification = notifications.data();
        if (!notification.match_status && !(notification.match_status === 'Declined' || notification.match_status === 'Accepted')) {
          setNotifications(true);
        }
      });
    }
  }

  const openActionsheet = async (league: string) => {
    if (league == "ppw") {
      setBuyFormat('Play.Post.Win.');
      setReferrer('ppw');
      if (current_user.ppw_validated) {
        present({
          header: "Play.Post.Win.",
          buttons: [
            // {
            //   text: "Scores",
            //   handler: () => {
            //     console.log("scores");
            //     window.location.href = "/ppw_checkins";
            //   },
            // },
            {
              text: "About",
              handler: async () => {
                await Browser.open({ url: "https://eclub.golf/ppw.html" });
              },
            },
            {
              text: "Leaderboards",
              handler: async () => {
                window.location.href = "/ppw_leaderboards"
              },
            },
            {
              text: "Post score",
              handler: async () => {
                fetchPPWScores(current_user.user_id);
                //window.location.href="/add_ppw_checkin"
              },
            },
            {
              text: "Close",
              role: "cancel",
            },
          ],
        });
      } else {
        present({
          header: "Play.Post.Win.",
          buttons: [
            {
              text: "About",
              handler: async () => {
                await Browser.open({ url: "https://eclub.golf/ppw.html" });
              },
            },
            {
              text: "Register",
              handler: async () => {
                const q = query(collection(db, "CONFIG"), where('type', '==', "PAYPAL"));
                const querySnapshot = await getDocs(q);
                if (querySnapshot && querySnapshot.docs.length > 0) {
                  var obj = {
                    "currency": "USD",
                    "client-id": querySnapshot.docs[0].data().value,
                    "components": "buttons"
                  }
                  setPaypalData(obj);
                  setPaypalPayment(true);
                } else {
                  setPaypalData({});
                }
              },
            },
            {
              text: "Close",
              role: "cancel",
            },
          ],
        });
      }
    } else if (league == "net_score") {
      setBuyFormat('Net Score Challenge');
      setReferrer('netscore');
      if (current_user.netscore_validated) {
        present({
          header: "Net Score Challenge",
          buttons: [
            {
              text: "About",
              handler: async () => {
                await Browser.open({ url: "https://eclub.golf/netscore.html" });
              },
            },
            {
              text: "Leaderboards",
              handler: async () => {
                window.location.href = "/netscore_challenge_leaderboards";
              },
            },
            {
              text: "Schedule",
              handler: async () => {
                window.location.href = "/netscore_challenge_checkins";
              },
            },
            {
              text: "Set a match",
              handler: async () => {
                window.location.href = "/netscore_challenge_create_checkin";
              },
            },
            {
              text: "Close",
              role: "cancel",
            },
          ],
        });
      } else {
        present({
          header: "Net Score Challenge",
          buttons: [
            {
              text: "About",
              handler: async () => {
                await Browser.open({ url: "https://eclub.golf/netscore.html" });
              },
            },
            {
              text: "Register",
              handler: async () => {
                const q = query(collection(db, "CONFIG"), where('type', '==', "PAYPAL"));
                const querySnapshot = await getDocs(q);
                if (querySnapshot && querySnapshot.docs.length > 0) {
                  var obj = {
                    "currency": "USD",
                    "client-id": querySnapshot.docs[0].data().value,
                    "components": "buttons"
                  }
                  setPaypalData(obj);
                  setPaypalPayment(true);
                } else {
                  setPaypalData({});
                }
              }
            },
            {
              text: "Close",
              role: "cancel",
            },
          ],
        });
      }
    } else if (league == "tt") {
      setBuyFormat('TwilightTOUR');
      setReferrer('tt');
      if (current_user.tt_validated) {
        present({
          header: "TwilighTOUR",
          buttons: [
            {
              text: "About",
              handler: async () => {
                await Browser.open({ url: "https://eclub.golf/tt.html" });
              },
            },
            {
              text: "Leaderboards(Coming soon!)",
              handler: () => {
              },
            },

            {
              text: "Check in",
              handler: async () => {
                //window.location.href = "/twilightour_create_checkin";
              },
            },
            {
              text: "Close",
              role: "cancel",
            },
          ],
        });
      } else {
        present({
          header: "TwilighTour",
          buttons: [
            {
              text: "About",
              handler: async () => {
                await Browser.open({ url: "https://eclub.golf/tt.html" });
              },
            },
            {
              text: "Register(Coming soon!)",
              handler: async () => {
                const q = query(collection(db, "CONFIG"), where('type', '==', "PAYPAL"));
                const querySnapshot = await getDocs(q);
                if (querySnapshot && querySnapshot.docs.length > 0) {
                  var obj = {
                    "currency": "USD",
                    "client-id": querySnapshot.docs[0].data().value,
                    "components": "buttons"
                  }
                  setPaypalData(obj);
                  setPaypalPayment(true);
                } else {
                  setPaypalData({});
                }
              },
            },
            {
              text: "Close",
              role: "cancel",
            },
          ],
        });
      }
    }
  };

  const openTourActionsheet = async (tour_id: string) => {

    let tourData = tours.filter((x: any) => x.id == tour_id);
    if (tourData.length > 0) {
      setTour(tourData[0]);
      let players = tourData[0].players.filter((x: any) =>
        x.user_id == current_user.user_id
      );
      if (players.length <= 0) {
        present({
          header: tourData[0].tour_name,
          buttons: [
            {
              text: "Register",
              handler: async () => {
                setReferrer('tt');
                getTourData(tour_id);
              },
            },
            {
              text: "Close",
              role: "cancel",
            },
          ],
        });
      } else {
        if (tourData[0].format === "2~1:1 Matches") {
          present({
            header: tourData[0].tour_name,
            buttons: [
              {
                text: "About",
                handler: async () => {
                  getTourData(tour_id);
                },
              },
              {
                text: "Leaderboards",
                handler: async () => {
                  window.location.href = "/twilightour_one_on_oneleaderboards/" + tour_id;
                },
              },
              {
                text: "Schedule",
                handler: async () => {
                  window.location.href = "/twilightour_checkins/" + tour_id;
                },
              },
              {
                text: "Set a match",
                handler: async () => {
                  window.location.href = "/twilightour_create_checkin/" + tour_id
                },
              },
              {
                text: "Close",
                role: "cancel",
              },
            ],
          });
        } else if (tourData[0].format === "3~Weekly pack leaders") {

        } else if (tourData[0].format === '1~Peoria based' || tourData[0].format === "3~Intl Peoria") {
          present({
            header: tourData[0].tour_name,
            buttons: [
              {
                text: "About",
                handler: async () => {
                  getTourData(tour_id);
                },
              },
              {
                text: "Leaderboards",
                handler: async () => {
                  window.location.href = "/twilightour_leaderboards/" + tour_id;
                },
              },
              {
                text: "Post score",
                handler: async () => {
                  validateTtScores(current_user.user_id, tourData[0].format, tour_id);
                  //window.location.href="/twilightour_create_checkin/"+tour_id
                },
              },
              {
                text: "Close",
                role: "cancel",
              },
            ],
          });
        }
      }
    }
  };
  const showTTPaypalPayment = async (tour: any, chips: any, amount: any) => {
    const q = query(collection(db, "CONFIG"), where('type', '==', "PAYPAL"));
    const querySnapshot = await getDocs(q);
    if (querySnapshot && querySnapshot.docs.length > 0) {
      var obj = {
        "currency": "USD",
        "client-id": querySnapshot.docs[0].data().value,
        "components": "buttons"
      }
      setRequiredData(tour);
      setPaypalData(obj);
      setChipsQty(parseInt(chips) + 100);
      setAmount(parseInt(amount) + 10);
      setPaypalPayment(true);
    } else {
      setPaypalPayment(false);
      setPaypalData({});
      showToast('Error fecthing payment details. Please try again after some time.', 2000);
    }
  }

  const getUserDeatilsFromGHIN = async (user: any) => {
    let ghin_number = user.ghin_number.length > 0 && user.ghin_number !== 0 ? user.ghin_number : 0;
    let last_name = user.last_name.length > 0 ? user.last_name : "";
    let response = await utils.getGolferData(ghin_number, last_name);
    if (response != "error") {
      let golfers = response || { golfers: [{ handicap_index: 0 }] };
      await updateEntry("USER_PROFILE", user.id, {
        handicap_index: golfers.golfers[0] !== undefined ? golfers.golfers[0].handicap_index : 'N/A',
      });
      setHandicap(golfers.golfers[0] !== undefined ? golfers.golfers[0].handicap_index.toString() : 'N/A');
    }
  };
  const allPacksList = async (user_packs: any) => {
    const q = query(
      collection(db, "PACKS"),
      where("pack_id", "!=", "kuFOI2OPKCuhCwrewzBqDI8w71IE")
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs && querySnapshot.docs.length > 0) {
      await Promise.all(
        querySnapshot.docs.map(async (pack) => {
          var doc_item = pack.data();
          doc_item.id = pack.id;
          return doc_item;
        })
      ).then((results) => {
        let new_packs = _.differenceBy(results, user_packs, "pack_id");
        console.log("curr", new_packs);
        setPacksList(new_packs);
      });
    } else {
      console.log("No packs available");
    }
  };
  const [filtered_packs, setFilteredPacks] = useState<any>([]);
  const filterPacks = async (city: string) => {
    if (city === "Any") {
      setFilteredPacks(packs_list);
    } else {
      let filtered_packs = packs_list.filter((x: any) => x.city === city);
      setFilteredPacks(filtered_packs);
    }
  }
  const setPack = async (pack_id: string) => {
    let filtered_pack = packs_list.filter((x: any) => x.pack_id == pack_id);
    if (filtered_pack.length > 0) {
      setSelectedPack(filtered_pack[0]);
    }
  };
  const sendJoinRequest = async () => {
    if (selected_pack && selected_pack.pack_name != null) {
      var obj = {
        created_name: current_user.first_name + " " + current_user.last_name,
        created_by: current_user.user_id,
        type: "JOIN_REQUEST",
        description: pack_message,
        source: {
          user_name: current_user.first_name + " " + current_user.last_name,
          user_id: current_user.user_id,
        },
        is_read: false,
        pack_id: selected_pack.pack_id,
        pack_name: selected_pack.pack_name,
        user_id: selected_pack.pack_lead_id,
      };
      await createEntryWithoutId("PACK_NOTIFICATIONS", obj);
      presentToast("Request sent", 1800);
    }
  };
  const getProfileData = async (user: any) => {
    const roundsClassificationQuery = query(
      collection(db, "CONFIG"),
      where("type", "==", "ROUNDS_CLASSIFICATION")
    );
    const roundsClassificationSnapshot = await getDocs(
      roundsClassificationQuery
    );
    const scoresQuery = query(
      collection(db, "SCORE_RECORDS"),
      where("created_at", ">", 0),
      where("user_id", "==", user.user_id),
      where("score_submitted", "==", true)
    );
    const scoresSnapshot = await getDocs(scoresQuery);
    console.log(scoresSnapshot)
    await Promise.all(
      scoresSnapshot.docs.map(async (competition) => {
        var doc_item = competition.data();
        doc_item.id = competition.id;
        let courseStr = doc_item.course_name.split("-");
        if (courseStr[0] != undefined || courseStr[0] != null)
          doc_item.course_name = courseStr[0];
        if (courseStr[1] != undefined || courseStr[1] != null)
          doc_item.course_sub_name = courseStr[1];
        if (doc_item.tee) {
          let teeset = doc_item.tee.split("~");
          doc_item.tee =
            doc_item.holes == 18 ? teeset[0] : teeset[0] + "~" + doc_item.tee_type;
        }
        if (doc_item.points) {
          //doc_item.points.birdie =  doc_item.points.birdie +  doc_item.points.eagle + doc_item.points.double_eagle;
          doc_item.total_points = doc_item.points.total_points;
        } else {
          doc_item.points = {
            triple_bogey: 0,
            double_bogey: 0,
            bogey: 0,
            par_value: 0,
            birdie: 0,
            eagle: 0,
            double_eagle: 0,
            total_points: 0,
          };
          doc_item.total_points = 0;
        }
        return doc_item;
      })
    ).then((results) => {
      console.log(results.length)
      if (results.length > 0) {
        let roundsClassification = roundsClassificationSnapshot.docs && roundsClassificationSnapshot.docs.length > 0 ? roundsClassificationSnapshot.docs[0].data() : new Array();
        var classificationSetup = results.length > 10 ? roundsClassification.rounds.filter((round: any) => round.count == 10) : roundsClassification.rounds.filter((round: any) => round.count == results.length);
        var latestRound = _.sortBy(results, (e) => e.competition_date_timestamp).reverse()[0];
        var sortedRecords = _.sortBy(results, (e) => e.total_points).reverse();
        const allRecords = _.sortBy(sortedRecords, (e: any) => e.competition_date_timestamp).reverse();
        setAllRounds(allRecords);
        var bestRecords = new Array();
        var otherRecords = new Array();
        bestRecords = sortedRecords.map((record, idx) => {
          if (idx < classificationSetup[0].best) {
            record.best = true;
            return record;
          }
        })
          .filter((record) => record);
        otherRecords = sortedRecords
          .map((record, idx) => {
            if (
              idx > classificationSetup[0].best &&
              idx <= classificationSetup[0].count
            ) {
              record.best = false;
              return record;
            }
          })
          .filter((record) => record);
        var bestRound = bestRecords[0] || new Array();
        let bestRoundStrokesData = bestRound.hole_details.map((rec: any) => {
          return rec.raw_score;
        });
        let birdieBogeyParTotal =
          bestRound.points.birdie +
          bestRound.points.par_value +
          bestRound.points.bogey;
        var bestRoundTableData = {
          strokes: bestRoundStrokesData.reduce(
            (a: number, b: number) => a + b,
            0
          ),
          total_points: bestRound.points.total_points,
          birdie: bestRound.points.birdie,
          par: bestRound.points.par_value,
          bogey: bestRound.points.bogey,
          double_bogey: bestRound.points.double_bogey,
          triple_bogey: bestRound.points.triple_bogey,
          others: 18 - birdieBogeyParTotal,
        };
        var bestRoundsTableData = {
          strokes: 0,
          total_points: 0,
          birdie: 0,
          par: 0,
          bogey: 0,
          double_bogey: 0,
          triple_bogey: 0,
          others: 0,
          count: classificationSetup[0].best,
        };
        var otherRoundsTableData = {
          strokes: 0,
          total_points: 0,
          birdie: 0,
          par: 0,
          bogey: 0,
          double_bogey: 0,
          triple_bogey: 0,
          others: 0,
          count: classificationSetup[0].other,
        };
        let bestRoundsPointsData = bestRecords.map((record: any) => {
          if (record.holes == 9) {
            record.total_points = record.total_points * 2;
            return record.total_points;
          } else {
            return record.total_points;
          }
        });
        bestRoundsTableData.total_points = Math.round(
          bestRoundsPointsData.reduce((a, b) => a + b, 0) / bestRecords.length
        );
        let bestRoundsStrokesData = new Array();
        bestRecords.map((record: any) => {
          let rawScoresData = record.hole_details.map((rec: any) => {
            return rec.raw_score;
          });
          bestRoundsStrokesData = bestRoundsStrokesData.concat(rawScoresData);
          return record;
        });
        bestRoundsTableData.strokes = Math.floor(
          bestRoundsStrokesData.reduce((a, b) => a + b, 0) / bestRecords.length
        );
        let bestRoundsBirdieData = bestRecords.map((record: any) => {
          return record.points.birdie;
        });
        bestRoundsTableData.birdie = Math.round(
          bestRoundsBirdieData.reduce((a, b) => a + b, 0) / bestRecords.length
        );
        let bestRoundsParData = bestRecords.map((record: any) => {
          return record.points.par_value;
        });
        bestRoundsTableData.par = Math.round(
          bestRoundsParData.reduce((a, b) => a + b, 0) / bestRecords.length
        );
        let bestRoundsBogeyData = bestRecords.map((record: any) => {
          return record.points.bogey;
        });
        bestRoundsTableData.bogey = Math.round(
          bestRoundsBogeyData.reduce((a, b) => a + b, 0) / bestRecords.length
        );
        let bestBirdieBogeyParTotal =
          bestRoundsTableData.birdie +
          bestRoundsTableData.par +
          bestRoundsTableData.bogey;
        bestRoundsTableData.others =
          bestBirdieBogeyParTotal > 0 ? 18 - bestBirdieBogeyParTotal : 0;

        let otherRoundsPointsData = otherRecords.map((record: any) => {
          if (record.holes == 9) {
            //record.total_points = record.total_points * 2;
            return record.total_points;
          } else {
            return record.total_points;
          }
        });
        otherRoundsTableData.total_points = Math.round(
          otherRoundsPointsData.reduce((a, b) => a + b, 0) / otherRecords.length
        );
        let otherRoundsStrokesData = new Array();
        otherRecords.map((record: any) => {
          let rawScoresData = record.hole_details.map((rec: any) => {
            return rec.raw_score;
          });
          otherRoundsStrokesData = otherRoundsStrokesData.concat(rawScoresData);
          return record;
        });
        otherRoundsTableData.strokes = Math.floor(
          otherRoundsStrokesData.reduce((a, b) => a + b, 0) /
          otherRecords.length
        );
        let otherRoundsBirdieData = otherRecords.map((record: any) => {
          return record.points.birdie;
        });
        otherRoundsTableData.birdie = Math.round(
          otherRoundsBirdieData.reduce((a, b) => a + b, 0) / otherRecords.length
        );
        let otherRoundsParData = otherRecords.map((record: any) => {
          return record.points.par_value;
        });
        otherRoundsTableData.par = Math.round(
          otherRoundsParData.reduce((a, b) => a + b, 0) / otherRecords.length
        );
        let otherRoundsBogeyData = otherRecords.map((record: any) => {
          return record.points.bogey;
        });
        otherRoundsTableData.bogey = Math.round(
          otherRoundsBogeyData.reduce((a, b) => a + b, 0) / otherRecords.length
        );
        let othersBirdieBogeyParTotal =
          otherRoundsTableData.birdie +
          otherRoundsTableData.par +
          otherRoundsTableData.bogey;
        otherRoundsTableData.others =
          othersBirdieBogeyParTotal > 0 ? 18 - othersBirdieBogeyParTotal : 0;

        var allStrokes = Math.floor(
          (bestRoundsTableData.strokes > 0
            ? bestRoundsTableData.strokes * 0.7
            : 0) +
          (otherRoundsTableData.strokes > 0
            ? otherRoundsTableData.strokes * 0.3
            : 0)
        );
        var allPoints = Math.floor(
          (bestRoundsTableData.total_points > 0
            ? bestRoundsTableData.total_points * 0.7
            : 0) +
          (otherRoundsTableData.total_points > 0
            ? otherRoundsTableData.total_points * 0.3
            : 0)
        );
        //updateEntry("USER_PROFILE", user.id, {"quota": allPoints, "strokes": allStrokes});
        // user.quota = allPoints;
        // setCurrentUser(user);
        // localStorage.setItem('firebase_user', JSON.stringify(user));
        let allRounds = [...bestRecords, ...otherRecords];
        allRounds = _.sortBy(allRounds, (e: any) => e.competition_date_timestamp).reverse();
        setBestRound(bestRoundTableData);
        setBestRounds(bestRoundsTableData);
        setOtherRounds(otherRoundsTableData);
        setProfileData({
          all_strokes: allStrokes,
          all_points: allPoints,
        });
        setLatestRound({
          date: format(new Date(latestRound.competition_date), "MM/dd"),
          total_points: latestRound.points.total_points,
        });

      } else {
        setBestRound({
          strokes: 0,
          total_points: 0,
          birdie: 0,
          par: 0,
          bogey: 0,
          double_bogey: 0,
          triple_bogey: 0,
          others: 0,
        });
        setBestRounds({
          strokes: 0,
          total_points: 0,
          birdie: 0,
          par: 0,
          bogey: 0,
          double_bogey: 0,
          triple_bogey: 0,
          others: 0,
          count: 0,
        });
        setOtherRounds({
          strokes: 0,
          total_points: 0,
          birdie: 0,
          par: 0,
          bogey: 0,
          double_bogey: 0,
          triple_bogey: 0,
          others: 0,
          count: 0,
        });
        setProfileData({
          all_strokes: 0,
          all_points: 0,
        });
        setLatestRound({
          date: format(new Date(), "MM/dd"),
          total_points: 0,
        });
        setAllRounds([]);
      }
    });
  };
  const getGraphData = async (
    holes: number,
    rounds: number,
    user_id: string
  ) => {
    var competition_dates = new Array();
    var competition_points = new Array();
    var competition_scores_birdie = new Array();
    var competition_scores_par = new Array();
    var competition_scores_bogey = new Array();
    var competition_scores_others = new Array();
    var competition_quotas = new Array();

    const scoresQuery = query(
      collection(db, "SCORE_RECORDS"),
      where("created_at", ">", 0),
      where("user_id", "==", user_id || current_user.user_id),
      where("holes", "==", holes),
      where("score_submitted", "==", true)
    );
    const scoresSnapshot = await getDocs(scoresQuery);
    await Promise.all(
      scoresSnapshot.docs.map(async (competition) => {
        var doc_item = competition.data();
        if (doc_item.points) {
          doc_item.total_points = doc_item.points.total_points;
          return doc_item;
        } else {
          return;
        }
      })
    ).then((results) => {
      let filteredResults = results.filter((res) => res);
      var bestRecords = _.sortBy(
        filteredResults,
        (e: any) => e.total_points
      ).reverse();
      bestRecords = _.take(bestRecords, 3);
      bestRecords = _.sortBy(
        bestRecords,
        (e: any) => e.competition_date_timestamp
      ).reverse();
      var recentRecords = _.sortBy(
        filteredResults,
        (e: any) => e.competition_date_timestamp
      ).reverse();
      recentRecords = _.takeRight(recentRecords, 10);

      if (rounds == 3) {
        let state2values;
        bestRecords &&
          bestRecords.forEach((record: any, i) => {
            competition_dates.push(
              record.competition_date.slice(5, record.competition_date.length)
            );
            competition_points.push(
              record.points ? record.points.total_points : 0
            );
            competition_quotas.push(record.quota ? record.quota : 0);
            competition_scores_birdie.push(
              record.points
                ? record.points.birdie +
                record.points.eagle +
                record.points.double_eagle
                : 0
            );
            competition_scores_par.push(
              record.points ? record.points.par_value : 0
            );
            competition_scores_bogey.push(
              record.points ? record.points.bogey : 0
            );
            competition_scores_others.push(
              record.points
                ? record.points.double_bogey + record.points.triple_bogey
                : 0
            );
            if (i == bestRecords.length - 1) {
              var birdie = Math.round(
                competition_scores_birdie.reduce((a, b) => a + b, 0) /
                competition_scores_birdie.length
              );
              var par = Math.round(
                competition_scores_par.reduce((a, b) => a + b, 0) /
                competition_scores_par.length
              );
              var bogey = Math.round(
                competition_scores_bogey.reduce((a, b) => a + b, 0) /
                competition_scores_bogey.length
              );
              var other =
                holes == 18
                  ? 18 - (birdie + par + bogey)
                  : 9 - (birdie + par + bogey);
              state2values = new Array();
              state2values.push(birdie);
              state2values.push(par);
              state2values.push(bogey);
              state2values.push(other);
              setState2Values(state2values);
            }
          });
        let stateValues = new Array();
        stateValues.push(competition_dates.reverse());
        stateValues.push(competition_points.reverse());
        stateValues.push(competition_quotas.reverse());
        setStateValues(stateValues);
        state = {
          options: {
            chart: {
              height: 310,
              width: 400,
              id: "line",
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                borderRadius: 10,
                dataLabels: {
                  position: "bottom", // top, center, bottom
                },
              },
            },
            curve: "smooth",
            legend: {
              show: true,
              fontSize: "15px",
              type: "string",
            },
            markers: {
              radius: 4,
            },
            stroke: {
              width: [0, 4],
            },
            dataLabels: {
              enabled: true,
            },
            xaxis: {
              show: true,
              categories: stateValues[0],
              labels: {
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: false,
              },
            },
            yaxis: {
              show: false,
            },
            // yaxis: [{
            //   title: {
            //     text: '',
            //   },

            // }, {
            //   opposite: true,
            //   title: {
            //     text: ''
            //   }
            // }],
          },
          series: [
            {
              name: "Score",
              type: "column",
              data: stateValues[1],
            },
            {
              name: "Target",
              type: "line",
              data: stateValues[2],
            },
          ],
        };
        state2 = {
          series: state2values,
          options: {
            chart: {
              width: 320,
            },
            labels: ["Birdie+", "Par", "Bogey", "Other"],
            dataLabels: {
              formatter: function (val: any, opts: any) {
                return opts.w.config.series[opts.seriesIndex];
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 300,
                  },
                  legend: {
                    position: "top",
                  },
                },
              },
            ],
          },
        };
      }
      if (rounds == 10) {
        let state2values;
        recentRecords &&
          recentRecords.forEach((record: any, i) => {
            competition_dates.push(
              record.competition_date.slice(5, record.competition_date.length)
            );
            competition_points.push(
              record.points ? record.points.total_points : 0
            );
            competition_quotas.push(record.quota ? record.quota : 0);
            competition_scores_birdie.push(
              record.points
                ? record.points.birdie +
                record.points.eagle +
                record.points.double_eagle
                : 0
            );
            competition_scores_par.push(
              record.points ? record.points.par_value : 0
            );
            competition_scores_bogey.push(
              record.points ? record.points.bogey : 0
            );
            competition_scores_others.push(
              record.points
                ? record.points.double_bogey + record.points.triple_bogey
                : 0
            );
            if (i == recentRecords.length - 1) {
              var birdie = Math.round(
                competition_scores_birdie.reduce((a, b) => a + b, 0) /
                competition_scores_birdie.length
              );
              var par = Math.round(
                competition_scores_par.reduce((a, b) => a + b, 0) /
                competition_scores_par.length
              );
              var bogey = Math.round(
                competition_scores_bogey.reduce((a, b) => a + b, 0) /
                competition_scores_bogey.length
              );
              var other =
                holes == 18
                  ? 18 - (birdie + par + bogey)
                  : 9 - (birdie + par + bogey);
              state2values = new Array();
              state2values.push(birdie);
              state2values.push(par);
              state2values.push(bogey);
              state2values.push(other);
              setState2Values(state2values);
            }
          });
        let stateValues = new Array();
        stateValues.push(competition_dates.reverse());
        stateValues.push(competition_points.reverse());
        stateValues.push(competition_quotas.reverse());
        setStateValues(stateValues);
        state = {
          options: {
            chart: {
              height: 310,
              width: 400,
              id: "line",
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                borderRadius: 5,
                dataLabels: {
                  position: "bottom", // top, center, bottom
                },
              },
            },
            curve: "smooth",
            legend: {
              show: true,
              fontSize: "15px",
              type: "string",
            },
            markers: {
              radius: 4,
            },
            stroke: {
              width: [0, 4],
            },
            dataLabels: {
              enabled: true,
              // offsetY: -5,
              // offsetX: -2
            },
            xaxis: {
              show: true,
              categories: stateValues[0],
              labels: {
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: false,
              },
            },
            yaxis: {
              show: false,
            },
            // yaxis: [{
            //   title: {
            //     text: '',
            //   },

            // }, {
            //   opposite: true,
            //   title: {
            //     text: ''
            //   }
            // }],
          },
          series: [
            {
              name: "Score",
              type: "column",
              data: stateValues[1],
            },
            {
              name: "Target",
              type: "line",
              data: stateValues[2],
            },
          ],
        };
        state2 = {
          series: state2values,
          options: {
            chart: {
              width: 320,
            },
            labels: ["Birdie+", "Par", "Bogey", "Other"],
            dataLabels: {
              formatter: function (val: any, opts: any) {
                return opts.w.config.series[opts.seriesIndex];
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 300,
                  },
                  legend: {
                    position: "top",
                  },
                },
              },
            ],
          },
        };
      }
    });
  };
  const deletePendingScore = async (id: string) => {
    await deleteDoc(doc(db, "SCORE_RECORDS", id));
    let newPendingScores = pending_scores.filter((x: any) => x.id != id);
    setPendingScores(newPendingScores);
  };
  const deleteTTPendingScore = async (id: string) => {
    await deleteDoc(doc(db, "SCORE_RECORDS", id));
    let newPendingScores = tt_pending_scores.filter((x: any) => x.id != id);
    setTtPendingScores(newPendingScores);
  };
  const deletePPWPendingScore = async (id: string, scoreId: string) => {
    await deleteDoc(doc(db, "PPW_COMPETITIONS", id));
    await deleteDoc(doc(db, "SCORE_RECORDS", scoreId));
    let newPPWPendingScores = ppwScoredCard.filter((x: any) => x.id != id);
    setPpwScoreCard(newPPWPendingScores);
  };
  const toggleCard = (header: any) => {
    all_rounds && all_rounds.forEach((key: any, i: number) => {
      if (key.id == header) {
        key.toggle = !key.toggle
      }
    })
    // console.log(header,cards)
    setAllRounds(Array.from(new Set([...all_rounds])))
  }
  const [score_modal, setScoreModal] = useState<any>(false)
  const [pending_scores, setPendingScores] = useState<any>([]);
  const [pending_scores_modal, setPendingScoresModal] = useState<any>(false);
  const [players_in_pack_modal, setPlayersInPackModal] = useState<any>(false);
  const [tt_pending_scores, setTtPendingScores] = useState<any>([]);
  const [tt_pending_scores_modal, setTtPendingScoresModal] = useState<any>(false);
  const [ppwScoredCard, setPpwScoreCard] = useState<any>(false);
  const [ppwScoreCardModal, setPpwScoreCardModal] = useState<any>(false);
  const [pendingPPWScores, setPendingPpwScores] = useState<any>(false);
  const [ppwScoreId, setPPWScoreId] = useState<String>('');
  const [showQuotaModal, setShowQuotaModal] = useState<any>(false);
  const [show_scores_modal, setShowScoresModal] = useState(false);
  const [score_record, setScoreRecord] = useState<any>([]);
  const [edit_hole_details, setEditHoleDetails] = useState<any>([]);
  const [hole_points, setHolePoints] = useState<any>();
  const [tee_value, setTeeValue] = useState<number>();
  const [holes, setHoles] = useState<any>();
  const [tours, setTours] = useState<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [tour_id, setTourId] = useState<any>();
  const [showToast, hideToast] = useIonToast();
  const [tour, setTour] = useState<any>({
    tour_name: "",
    city: "",
    format: "",
    season_prize_pool: 0,
    weekly_prize_pool: 0,
    fixed_courses: "",
    fixed_tees: "",
    holes: 0,
    players: [],
    state: '',
    selected_courses: [],
    selected_tees: [],
    pull_scores_from: "",
    partner_required: "",
    visibility: "",
    youth_on_course: 0,
    id: "",
    status: ""
  });
  const [show_tour_modal, setShowTourModal] = useState<boolean>(false);
  const validateScores = async (id: any) => {
    const scoresQuery = query(
      collection(db, "SCORE_RECORDS"),
      where("created_at", ">", 0),
      where("user_id", "==", id || current_user.user_id),
      where("score_submitted", "==", false)
    );
    const scoresSnapshot = await getDocs(scoresQuery);
    if (scoresSnapshot.docs.length > 0) {
      await Promise.all(
        scoresSnapshot.docs.map(async (competition) => {
          var doc_item = competition.data();
          doc_item.id = competition.id;
          let courseStr = doc_item.course_name.split("-");
          if (courseStr[0] != undefined || courseStr[0] != null)
            doc_item.course_name = courseStr[0];
          if (courseStr[1] != undefined || courseStr[1] != null)
            doc_item.course_sub_name = courseStr[1];
          return doc_item;
        })
      ).then((results) => {
        setPendingScores(_.sortBy(results, (e: any) => e.created_at).reverse());
        setPendingScoresModal(true);
      });
    } else {
      window.location.href = "/create_score";
    }
  };

  const validateTtScores = async (id: any, type: any, tour_id: any) => {
    setTourId(tour_id);
    const scoresQuery = query(
      collection(db, "SCORE_RECORDS"),
      where("created_at", ">", 0),
      where("user_id", "==", id || current_user.user_id),
      where("score_submitted", "==", false),
      where("format", "==", type)
    );
    const scoresSnapshot = await getDocs(scoresQuery);
    if (scoresSnapshot.docs.length > 0) {
      await Promise.all(
        scoresSnapshot.docs.map(async (competition) => {
          var doc_item = competition.data();
          doc_item.id = competition.id;
          let courseStr = doc_item.course_name.split("-");
          if (courseStr[0] != undefined || courseStr[0] != null)
            doc_item.course_name = courseStr[0];
          if (courseStr[1] != undefined || courseStr[1] != null)
            doc_item.course_sub_name = courseStr[1];
          return doc_item;
        })
      ).then((results) => {
        console.log(
          "pending scores",
          _.sortBy(results, (e: any) => e.created_at).reverse()
        );
        setTtPendingScores(_.sortBy(results, (e: any) => e.created_at).reverse());
        setTtPendingScoresModal(true);
      });
    } else {
      window.location.href = "/twilightour_create_checkin/" + tour_id;
    }
  };
  const fetchPPWScores = async (id: any) => {
    const ppwQuery = query(collection(db, "PPW_COMPETITIONS"), where('user_id', '==', id), where('created_at', '>', 0), where("score_submitted", "==", false), orderBy('created_at', 'desc'));
    const ppwSnapshot = await getDocs(ppwQuery);
    if (ppwSnapshot.docs.length > 0) {
      await Promise.all(ppwSnapshot.docs.map(async competition => {
        var doc_item = competition.data();
        doc_item.id = competition.id;
        //doc_item.ppwScoreId = doc_item.scoreId;

        return doc_item;
        //doc_item.date = format(new Date(doc_item.played_at), "MM-dd-yyyy");
      })
      ).then((results) => {
        setPpwScoreCard(_.sortBy(results, (e: any) => e.created_at).reverse());
        setPendingPpwScores(true);
        //setPpwScoreCardModal(true);
      })
    } else {
      window.location.href = "/add_ppw_checkin";
    }
  };
  const getTours = async (user_id: string) => {
    const q = query(collection(db, "TOUR_SETTINGS"), where('created_at', '>', 0), where('status', '==', 'Approved'));
    const querySnapshot = await getDocs(q);
    await Promise.all(querySnapshot.docs.map(async competition => {
      var doc_item = competition.data();
      doc_item.id = competition.id;
      let players = doc_item.players || [];
      if (players.filter((x: any) => x.user_id === user_id).length <= 0) {
        doc_item.joined = false;
      } else {
        doc_item.joined = true;
      }
      return doc_item;
    }))
      .then(results => {
        setTours(_.sortBy(results, (e: any) => e.created_at).reverse());
      });
  }
  const getTourData = async (tour_id: string) => {
    let tourData = tours.filter((x: any) => x.id == tour_id);
    if (tourData.length > 0) {
      setTour(tourData[0]);
      setShowTourModal(true);
    }
  }

  let state = {
    options: {
      chart: {
        height: 310,
        width: 400,
        id: "line",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          dataLabels: {
            position: "bottom", // top, center, bottom
          },
        },
      },
      legend: {
        show: true,
        fontSize: "15px",
        type: "string",
      },
      markers: {
        radius: 4,
      },
      curve: "smooth",
      stroke: {
        width: [0, 4],
      },
      dataLabels: {
        enabled: true,
        // offsetY: -5,
        // offsetX: -2
      },
      xaxis: {
        show: true,
        categories: state_values[0],
        labels: {
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: false,
        },
      },
      yaxis: {
        show: false,
      },
      // yaxis: [{
      //   title: {
      //     text: '',
      //   },

      // }, {
      //   opposite: true,
      //   title: {
      //     text: ''
      //   }
      // }],
    },
    series: [
      {
        name: "Score",
        type: "column",
        data: state_values[1],
      },
      {
        name: "Target",
        type: "line",
        data: state_values[2],
      },
    ],
  };
  let state2 = {
    series: state2_values,
    options: {
      chart: {
        width: 320,
      },
      labels: ["Birdie+", "Par", "Bogey", "Other"],
      dataLabels: {
        formatter: function (val: any, opts: any) {
          return opts.w.config.series[opts.seriesIndex];
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "top",
            },
          },
        },
      ],
    },
  };
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };
  const [hole, setHole] = useState<any>("18");
  const [rounds, setRounds] = useState<any>("10");
  const customAlertOptions = {
    header: "Holes",
  };
  const customAlertOptions1 = {
    header: "Rounds",
  };
  const customAlertOptions2 = {
    header: "Select pack",
  };
  const customAlertOptions3 = {
    header: "Select city",
  };

  const [tee_times, setTeeTimes] = useState<any>([]);
  const [current_teetime_id, setCurrentTeeTimeId] = useState<any>();
  const [teetime_data, setTeetimeData] = useState<any>([]);
  const [show_teetime_modal, setShowTeetimeModal] = useState(false);

  const acceptTeeTime = async (id: string) => {
    let teetime = tee_times.filter((x: any) => x.id == id);
    if (teetime.length > 0) {
      teetime[0].accepted_users = [
        ...teetime[0].accepted_users,
        ...[
          {
            user_id: current_user.user_id,
            user_name: current_user.first_name + " " + current_user.last_name,
          },
        ],
      ];
      updateEntry("TEE_TIMES", id, {
        accepted_users: teetime[0].accepted_users,
        pending_users: teetime[0].pending_users.filter(
          (user: any) => user.user_id != current_user.user_id
        ),
      });
      presentToast("Sucessfully accepted this tee time.", 2000);
      getTeeTimes();
    }
  };

  const declineTeeTime = async (id: string) => {
    let teetime = tee_times.filter((x: any) => x.id == id);
    if (teetime.length > 0) {
      teetime[0].rejected_users = [...teetime[0].rejected_users,...[
          {
            user_id: current_user.user_id,
            user_name: current_user.first_name + " " + current_user.last_name,
          },
        ],
      ];
      updateEntry("TEE_TIMES", id, {
        rejected_users: teetime[0].rejected_users,
        pending_users: teetime[0].pending_users.filter(
          (user: any) => user.user_id != current_user.user_id
        ),
      });
      presentToast("Sucessfully declined this tee time.", 2000);
      getTeeTimes();
    }
  };

  const openEditTeeTime = async (id: string) => {

  };

  const deleteTeeTime = async (id: string) => {
    await updateEntry("TEE_TIMES", id, { is_active: false });
    let newTeeTimes = tee_times.filter((x: any) => x.id != id);
    setTeeTimes(newTeeTimes);
  };

  const getTeeTimes = async () => {
    let pack_ids = [];
    if (current_user.packs.length > 0) {
      console.log("pack length > 0")
      let userPacks = current_user.packs;
      pack_ids = [
        ...userPacks.map((x: any) => {
          return x.pack_id;
        }),
      ];
    }
    const teetimesQuery = query(
      collection(db, "TEE_TIMES"),
      where("created_at", ">", 0),
      where("is_active", "==", true),
      where("publish_to", "==", "Pack"),
      where("pack_id", "in", pack_ids),
    );

    const teetimesSnapshot = await getDocs(teetimesQuery);
    console.log(teetimesSnapshot.docs.length);
    await Promise.all(
      teetimesSnapshot.docs.map(async (teetime) => {
        var doc_item = teetime.data();
        doc_item.id = teetime.id;
        let pendingUser = doc_item.pending_users.filter(
          (user: any) => user.user_id == current_user.user_id
        );
        let acceptedUser = doc_item.accepted_users.filter(
          (user: any) => user.user_id == current_user.user_id
        );
        let rejectedUser = doc_item.rejected_users.filter(
          (user: any) => user.user_id == current_user.user_id
        );
        let courseStr = doc_item.course_name.split("-");
        if (courseStr[0] != undefined || courseStr[0] != null)
          doc_item.course_name = courseStr[0];
        if (courseStr[1] != undefined || courseStr[1] != null)
          doc_item.course_sub_name = courseStr[1];
        doc_item.remaining_signup_spots =
          parseInt(doc_item.available_signup_spots) -
          doc_item.accepted_users.length;
        const commentsQuery = query(
          collection(db, "TEE_TIME_COMMENTS"),
          where("teetime_id", "==", doc_item.id)
        );
        const querySnapshot = await getDocs(commentsQuery);
        doc_item.comments = querySnapshot.docs.length;
        doc_item.commented =
          querySnapshot.docs.filter(
            (doc) => current_user.user_id == doc.data().user_id
          ).length > 0
            ? true
            : false;
        if (doc_item.user_id == current_user.user_id) {
          doc_item.own_event = true;
        } else {
          doc_item.own_event = false;
        }
        if (pendingUser.length > 0) {
          doc_item.responded = false;
          return doc_item;
        } else {
          doc_item.responded = true;
          if (acceptedUser.length > 0) {
            doc_item.response = true;
            return doc_item;
          }
          if (rejectedUser.length > 0) {
            doc_item.response = false;
            return doc_item;
          }
          return doc_item;
        }
      })
    ).then((results) => {
      setTeeTimes(_.sortBy(results, (e: any) => e.created_at).reverse());
    });
  };

  const getTeetimeData = async (teetime_id: string) => {
    let teetimeData = tee_times.filter((x: any) => x.id == teetime_id);
    if (teetimeData.length > 0) {
      setTeetimeData(teetimeData[0]);
      setShowTeetimeModal(true);
    }
  };

  const onQuillChange = (quillData: any) => {
    setContent(quillData);
  };

  const loadTeeCommentsData = async (teetime_id: string) => {
    const q = query(collection(db, "TEE_TIME_COMMENTS"), where('teetime_id', '==', teetime_id));
    const querySnapshot = await getDocs(q);
    if (querySnapshot && querySnapshot.docs.length > 0) {
      let comments = querySnapshot.docs.map(function (documentSnapshot) {
        var doc_item = documentSnapshot.data();
        doc_item.id = documentSnapshot.id;
        if (doc_item.user_image == undefined || doc_item.user_image == null) {
          doc_item.user_image = default_user_logo;
        }
        if (doc_item.user_id == current_user.user_id) {
          doc_item.own_comment = true;
        }
        return doc_item;
      });
      comments = _.sortBy(comments, e => e.created_at).reverse();
      setComments(comments);
      setCurrentTeeTimeId(teetime_id);
      setShowCommentsModal(true);
    } else {
      setComments([]);
      setCurrentTeeTimeId(teetime_id);
      setShowCommentsModal(true);
    }
  };

  const makeTeeComment = async () => {
    var comment = {
      user_id: current_user.user_id,
      user_name: current_user.first_name + ' ' + current_user.last_name,
      user_image: current_user.logo_url != undefined ? current_user.logo_url : null,
      city: current_user.city,
      comment: content,
      teetime_id: current_teetime_id,
      created_at: new Date().getTime(),
      updated_at: new Date().getTime()
    }
    await createEntryWithoutId('TEE_TIME_COMMENTS', comment);
    let newComments = comments;
    newComments.push(comment);
    setComments(_.sortBy(comments, (e: any) => e.created_at).reverse());
    setKeyDate(new Date().getTime());
    getTeeTimes();
  };
  const getAllPacks = async()=> {
    const q = query(collection(db, "PACKS"), where('pack_id', '!=', 'kuFOI2OPKCuhCwrewzBqDI8w71IE'));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.docs && querySnapshot.docs.length > 0) {
        await Promise.all(querySnapshot.docs.map(async pack => {
            var doc_item = pack.data();
            doc_item.id = pack.id;
            return doc_item;
        }))
        .then(results=> {
            setAllPacks(results);
        });
    } else {
        console.log("No packs available");
    }
  }

  const getPackPlayers = async(pack_id:string) => {
      const packQuery = query(
          collection(db, "PACKS"),
          where("pack_id", "==", pack_id)
      );
      const packQuerySnapshot = await getDocs(packQuery);
      if(packQuerySnapshot.docs && packQuerySnapshot.docs.length > 0) {
          let pack = packQuerySnapshot.docs[0].data();
          let user_ids = [
              ...pack.players.map((x: any) => {
                  return x.user_id;
              }),
          ];
          const q = query(
              collection(db, "USER_PROFILE"),
              where("created_at", ">", 0)
          );
          const querySnapshot = await getDocs(q);
          if (querySnapshot.docs && querySnapshot.docs.length > 0) {
              await Promise.all(
                  querySnapshot.docs.map(async (user) => {
                      if(user_ids.filter(x=> x == user.data().user_id).length > 0) {
                          var doc_item = user.data();
                          doc_item.id = user.id;
                          return doc_item;
                      } else {
                          return;
                      }
                  })
              ).then((results) => {
                  setPackUsers(results.filter(x=> x));
              })
          }
      }
  }

  useEffect(() => {
    utils.usgaAdminLogin();
    let user = localStorage.getItem("firebase_user");
    if (user != null) {
      if (JSON.parse(user).preferred_tee == undefined || JSON.parse(user).driving_distance == undefined || JSON.parse(user).gender == undefined || JSON.parse(user).beginning_quota == undefined) {
        window.location.href = "/questions"
      } else {
        getUserDeatilsFromGHIN(JSON.parse(user));
        if (JSON.parse(user).preferred_tee && JSON.parse(user).preferred_tee == "Back") {
          setTeeValue(1);
        }
        if (JSON.parse(user).preferred_tee && JSON.parse(user).preferred_tee == "Middle Back") {
          setTeeValue(2);
        }
        if (JSON.parse(user).preferred_tee && JSON.parse(user).preferred_tee == "Middle") {
          setTeeValue(3);
        }
        if (JSON.parse(user).preferred_tee && JSON.parse(user).preferred_tee == "Middle Front") {
          setTeeValue(4);
        }
        if (JSON.parse(user).preferred_tee && JSON.parse(user).preferred_tee == "Front") {
          setTeeValue(5);
        }
        setCurrentUser(JSON.parse(user));
        getProfileData(JSON.parse(user));
        getGraphData(Number(hole), Number(rounds), JSON.parse(user).user_id);
        getTours(JSON.parse(user).user_id);
        getNotificationCounts(JSON.parse(user).user_id);
      }
    }
    let current_pack = localStorage.getItem('current_pack');
    if (current_pack != null) {
      setCurrentPack(JSON.parse(current_pack));
      getAllPacks();
      setNewPacks(current_user.packs);
      setPacks(current_user.packs)

    }
  }, [tee_value]);
  return (
    <IonPage>
      <IonToolbar
        mode="ios"
        className={isPlatform("ios") ? "ios-toolbar-top" : "toolbar-top"}
      >
        {/* <IonButtons slot="start" className="slot-start-padding">
          {current_user.logo_url ? (
            <a href="/profile" className="pd-r-10">
              <img src={current_user.logo_url} className="pro_pic" />
            </a>
          ) : (
            <a href="/profile" className="pd-r-10">
              <img src={default_profile_logo} className="pro_pic" />
            </a>
          )}
        </IonButtons> */}
        <IonLabel slot="start" className="s-18 w-800 color-blue" style={{ padding: '0px 0px 0px 10px' }}>
          {leagues_tab_value}
        </IonLabel>
        {/* <IonTitle>
          <img src={main_logo} className="main_logo" />
        </IonTitle> */}
        <IonButtons slot="end" className="slot-end-padding" onClick={() => {
          allPacksList(current_user.packs);
          setShowJoinPackModal(true);
        }}>
          <IonIcon icon={peopleOutline} className="s-30 color-blue" />
        </IonButtons>
        <IonButtons slot="end" style={{ padding: '0px 5px 0px 10px' }} onClick={() => window.location.href = "/notifications"}>
          <IonIcon icon={notificationsOutline} className="s-30 color-blue" />
          {
            notifications ? (
              <span className='notification-dot'></span>
            ) : (
              <span className='notification'></span>
            )
          }
        </IonButtons>
        {/* <IonButtons slot="end" style={{padding: '0px 5px 0px 10px'}}>
          {current_user.logo_url ? (
            <a href="/profile" className="pd-r-10">
              <img src={current_user.logo_url} className="pro_pic" />
            </a>
          ) : (
            <a href="/profile" className="pd-r-10">
              <img src={default_profile_logo} className="pro_pic" />
            </a>
          )}
        </IonButtons> */}
      </IonToolbar>
      <IonContent>
        <IonGrid style={{ height: '100%' }}>
          {leagues_tab_value == "Scores" ? (
            <>
              <IonGrid style={{ padding: '0px' }}>
                <div className="score-header">
                  <div className="user-quota-align">
                    <div className="userName-align">
                      <IonText className="wg-600 s-18 profile-text" style={{ padding: '10px 0px' }}>
                        {current_user.first_name + " " + current_user.last_name}
                      </IonText>
                    </div>
                    <div>
                      <IonCard
                        className="bg card"
                        onClick={() => {
                          // getScores("filtered")
                          setShowQuotaModal(true);
                        }}
                      >
                        <div className="quota-text-align">
                          <div>
                            <IonText className="s-18 white wg-600">
                              {current_user.quota}
                            </IonText>{" "}
                            <br></br>
                            <IonText className="s-11 white mrl-10">Quota</IonText>
                            <IonIcon
                              icon={informationCircleOutline}
                              className="white s-15 wg-600 inf-icon"
                            />
                          </div>
                        </div>
                      </IonCard>
                    </div>
                  </div>
                  <div className="user-driving-align">
                    <div className="driving-distance-align">
                      {
                        (current_user.driving_distance != undefined && current_user.driving_distance.text) ? (

                          <IonText className='s-12'>Driving Distance:&nbsp;&nbsp;{current_user.driving_distance.text}</IonText>

                        ) : (
                          <></>
                        )
                      }
                    </div>
                    <div>
                      <IonCol className="margin-auto profile-text" size="7">
                        <IonRow className="ion-text-center ion-justify-content-center">
                          <IonText className="s-12">Suggested tees: <b>{current_user.preferred_tee}</b></IonText>
                        </IonRow>
                        <IonRange min={1} max={5} step={1} color="transparent" value={tee_value} className="custom-profile-ion-range-slider" disabled={true}>
                          <IonText slot="start" className="s-12 wg-600 gray range-left">&#124;</IonText>
                          <IonText slot="end" className="s-12 wg-600 gray range-right">&#124;</IonText>
                        </IonRange>

                      </IonCol>
                    </div>
                  </div>
                </div>

                {/* <div>
                    {
                    (current_user.driving_distance != undefined && current_user.driving_distance.text) ? (

                        <IonText className='s-12'>Driving Distance:&nbsp;&nbsp;{current_user.driving_distance.text}</IonText>

                    ) : (
                      <></>
                    )
                  }
                    </div> */}

                {/* <IonRow className="ion-justify-content-center ion-text-center row-8">
                  <IonCol size="5">
                    <IonCard
                      className="bg card"
                      onClick={() => {
                        // getScores("filtered")
                        setShowQuotaModal(true);
                      }}
                    >
                      <div className="mt-10">
                        <IonText className="s-18 white wg-600">
                          {current_user.quota}
                        </IonText>{" "}
                        <br />
                        <IonText className="s-11 white mrl-10">Quota</IonText>
                        <IonIcon
                          icon={informationCircleOutline}
                          className="white s-15 wg-600 inf-icon"
                        />
                      </div>
                    </IonCard>
                  </IonCol>
                  <IonCol className="margin-auto profile-text" size="7">
                    <IonRange min={1} max={5} step={1} color="transparent" value={tee_value} className="custom-profile-ion-range-slider" disabled={true}>
                      <IonText slot="start" className="s-12 wg-600 gray range-left">&#124;</IonText>
                      <IonText slot="end" className="s-12 wg-600 gray range-right">&#124;</IonText>
                    </IonRange>
                    <IonRow className="ion-text-center ion-justify-content-center">
                      <IonText className="s-12">Preferred tees: <b>{current_user.preferred_tee}</b></IonText>
                    </IonRow>
                  </IonCol>
                </IonRow> */}
                {/* <IonRow className="display-flex ion-justify-content-center">
                  {
                    (handicap == "N/A") ? (
                      <IonText className="s-14" onClick={() => { window.location.href = "/profile" }}>
                        Handicap Index<sup>&#8482;</sup>: <span className='create-button-outline color-blue'>Validate</span>
                      </IonText>
                    ) : (
                      <IonText className="s-14">
                        Handicap Index<sup>&#8482;</sup>: {handicap}
                      </IonText>
                    )
                  }
                </IonRow> */}
              </IonGrid>
              <IonGrid className="grid-2 mt-20">
                <IonCard className="stats-card bg ion-justify-content-center ion-text-center">
                </IonCard>
                {
                  all_rounds && all_rounds.map((key: any, i: number) => {
                    return (
                      <IonCard className="social-card score-summary-card" key={i}>
                        <IonRow className="pad-ltb">
                          <IonCol size="8" className="pad-0">
                            <IonText className="color-blue s-15 wg-600">
                              {key.course_name}
                            </IonText>
                            <br />
                            <IonText className="s-12 wg-500">{format(new Date(key.competition_date_timestamp), 'MM/dd/yyyy')} &nbsp;{key.game_date_time} &nbsp;{key.tee}&nbsp;<span className="s-10">{key.course_rating}/{key.slope_rating}/{key.par}</span></IonText>
                          </IonCol>
                          <IonCol size="3" className="pad-0 ion-text-center">
                            <IonText className="color-blue s-15 wg-600">
                              {key.points.total_points}
                            </IonText>
                            <IonText className="s-12 wg-500">/{key.quota}</IonText><br />
                            <IonText className="s-12 wg-500">Score/Target</IonText>
                          </IonCol>
                          {
                            (!key.toggle) ? (
                              <IonCol size="1" className="pad-0" onClick={() => {
                                toggleCard(key.id)
                              }}>
                                <IonIcon icon={chevronDownOutline} style={{ fontSize: "18px", marginTop: "5px" }} />
                              </IonCol>
                            ) : (
                              <IonCol size="1" className="pad-0" onClick={() => {
                                toggleCard(key.id)
                              }}>
                                <IonIcon icon={chevronUpOutline} style={{ fontSize: "18px", marginTop: "5px" }} />
                              </IonCol>
                            )
                          }
                        </IonRow>
                        {
                          (key.toggle) ? (
                            <IonCardContent style={{ paddingTop: "0px" }}>
                              <IonRow className="ion-justify-content-center ion-text-center" style={{ width: "90%", margin: "auto", borderBottom: "0.5px  solid black" }}>
                                <IonCol size="2">
                                  <IonText className="s-11">Eagle</IonText><br />
                                  <IonText className="black s-14">{key.points.eagle}</IonText>
                                </IonCol>
                                <IonCol size="2">
                                  <IonText className="s-11">Birdie</IonText><br />
                                  <IonText className="black s-14">{key.points.birdie}</IonText>
                                </IonCol>
                                <IonCol size="2">
                                  <IonText className="s-11">Par</IonText><br />
                                  <IonText className="black s-14">{key.points.par_value}</IonText>
                                </IonCol>
                                <IonCol size="2">
                                  <IonText className="s-11">Bogey</IonText><br />
                                  <IonText className="black s-14">{key.points.bogey}</IonText>
                                </IonCol>
                                <IonCol size="2">
                                  <IonText className="s-11">Double</IonText><br />
                                  <IonText className="black s-14">{key.points.double_bogey}</IonText>
                                </IonCol>
                                <IonCol size="2">
                                  <IonText className="s-11">Triple</IonText><br />
                                  <IonText className="black s-14">{key.points.triple_bogey}</IonText>
                                </IonCol>
                              </IonRow>
                              <IonRow className="fr mt-5 mb-5">
                                <IonText className="s-13 color-blue" onClick={() => {
                                  var score_record = {
                                    hole_details: _.sortBy(key.hole_details, e => e.hole_number),
                                    points: key.points,
                                    total_points: key.total_points,
                                    user_name: key.user_name,
                                    course_name: key.course_name,
                                    tee: key.tee,
                                    holes: key.holes,
                                    date: format(new Date(key.competition_date_timestamp), "MM/dd/yyyy"),
                                    game_date_time: key.game_date_time
                                  };
                                  setScoreRecord(score_record)
                                  if (key.holes == 9) {
                                    setFrontHoles(key.tee_type == "Front" ? true : false);
                                    setBackHoles(key.tee_type == "Back" ? true : false);
                                  }
                                  setHoles(key.holes);
                                  setEditHoleDetails(score_record.hole_details);
                                  setHolePoints(score_record.points)
                                  setScoreModal(true)
                                }}>View Scorecard</IonText><IonIcon size="19" icon={chevronForwardOutline} className="color-blue" />
                              </IonRow>
                            </IonCardContent>
                          ) : (
                            <>
                            </>
                          )
                        }
                      </IonCard>
                    )
                  })
                }
              </IonGrid>
            </>
          ) : leagues_tab_value == "Leagues" ? (
            <>
              {/*
              <IonCard
                className="league-cards"
              >
                <IonCardContent className="nsp-card">
                  <IonRow className="ion-justify-content-center ion-text-center" onClick={() => Browser.open({ url: "https://eclub.golf/tt.html" })}>
                    <IonText className="s-14">
                      Make every round count<br />with our city wide leaderboards.
                    </IonText>
                  </IonRow>
                  <IonRow className="mt-10">
                    <IonCol size="6" onClick={() => openActionsheet("ppw")}>
                      <IonCard className="tour-cards">
                        <IonRow className="ion-text-center ion-justify-content-center mt-30">
                          <img
                            src={require(`../../img/ppw.png`).default}
                            width="130"
                            height="50"
                          />
                        </IonRow>
                      </IonCard>
                    </IonCol>
                    <IonCol size="6" onClick={() => openActionsheet("net_score")}>
                      <IonCard className="tour-cards">
                        <IonRow className="ion-text-center ion-justify-content-center mt-30">
                          <img
                            src={require(`../../img/netscore.png`).default}
                            width="130"
                            height="40"
                          />
                        </IonRow>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="6" onClick={() => openActionsheet("ppw")}>

                      <IonRow className="ion-text-center ion-justify-content-center s-16 wg-600">
                        Only Fun. No fuss.
                      </IonRow>
                    </IonCol>
                    <IonCol size="6" onClick={() => openActionsheet("net_score")}>

                      <IonRow className="ion-text-center ion-justify-content-center s-16 wg-600">
                        When playing just for fun is not enough
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
              */}
              <IonCard
                className="league-cards"
              >
                {/* <IonCardHeader>
                  <IonRow className="ion-justify-content-center">
                    <img
                      src={require(`../../img/twilightour.png`).default}
                      width="160"
                      height="60"
                    />
                  </IonRow>
                </IonCardHeader> */}
                <IonCardContent className="tt-card">
                  <IonRow className="ion-justify-content-center ion-text-center mt-10" onClick={() => Browser.open({ url: "https://eclub.golf/tt.html" })}>
                    <IonText className="wg-600 s-18">
                      Your TOUR on your terms
                      <br />
                    </IonText>
                  </IonRow>
                  <IonRow className="ion-justify-content-center ion-text-center" >
                    <IonText className="s-14">
                      Choose one of the available leagues <br />or &nbsp;
                      <span className="create-button-solid-white" onClick={()=>{
                        window.location.href="/create_tour"
                      }}><b>Create</b> </span> &nbsp; your own.
                    </IonText>
                  </IonRow>
                  <IonRow className="mt-10">
                    {
                      tours && tours.map((item: any, index: number) => {
                        return (
                          <IonCol size="6" key={index} onClick={e => {
                            openTourActionsheet(item.id);
                          }}>
                            <IonCard className="tour-cards">
                              {/* <IonRow className="ion-justify-content-center">
                          <img src={main_logo} alt={item.pack_name} className="tours-pic" />
                          </IonRow> */}
                              <IonRow className="ion-text-center ion-justify-content-center">
                                <IonText className="s-16 wg-600 color-blue">
                                  {item.tour_name}
                                </IonText>
                              </IonRow>
                              <div className="tours-content">
                                {
                                  item.fixed_courses == "Yes" ? (
                                    <IonRow className="ion-text-center ion-justify-content-center">
                                      <IonText className="s-12 black wg-600 gray">Selected courses</IonText>
                                    </IonRow>
                                  ) : (
                                    <IonRow className="ion-text-center ion-justify-content-center">
                                      <IonText className="s-12 black wg-600 gray">Any course</IonText>
                                    </IonRow>
                                  )
                                }

                                <IonRow className="ion-text-center ion-justify-content-center">
                                  {
                                    item.city !== "Any" ? (
                                      <IonText className="s-12 black wg-600 gray">{item.city}</IonText>
                                    ) : (
                                      <IonText className="s-12 black wg-600 gray">Any City</IonText>
                                    )
                                  }
                                </IonRow>
                                <IonRow className="ion-text-center ion-justify-content-center">
                                  {
                                    item.joined === true ? (
                                      <IonText className="s-12 black wg-600 gray"><span className="">{(item.format || '').split('~')[1]}</span>&nbsp;(<span className="gray">Participant</span>)</IonText>
                                    ) : (
                                      <IonText className="s-12 black wg-600 gray"><span className="">{(item.format || '').split('~')[1]}</span>&nbsp;(<span className="color-red">New</span>)</IonText>
                                    )
                                  }
                                </IonRow>
                              </div>
                            </IonCard>
                          </IonCol>
                        )
                      })
                    }
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </>
          ) : leagues_tab_value == "Stats" ? (
            <IonGrid className="grid-2 mt-20">
              <IonRow>
                <IonCol>
                  <IonItem className="" lines="none">
                    <IonSelect
                      value={hole}
                      interfaceOptions={customAlertOptions}
                      interface="popover"
                      placeholder=""
                      className="s-14 ta wid-100"
                      onIonChange={(e) => {
                        setHole(e.detail.value);
                        getGraphData(
                          Number(e.detail.value),
                          Number(rounds),
                          current_user.user_id
                        );
                      }}
                      mode="ios"
                    >
                      <IonSelectOption value="18">18 Hole</IonSelectOption>
                      <IonSelectOption value="9">9 Hole</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem className="" lines="none">
                    <IonSelect
                      value={rounds}
                      interfaceOptions={customAlertOptions1}
                      interface="popover"
                      placeholder=""
                      className="s-14 ta wid-100"
                      onIonChange={(e) => {
                        setRounds(e.detail.value);
                        getGraphData(
                          Number(hole),
                          Number(e.detail.value),
                          current_user.user_id
                        );
                      }}
                      mode="ios"
                    >
                      <IonSelectOption value="3" className="s-12">
                        Best Rounds
                      </IonSelectOption>
                      <IonSelectOption value="10" className="s-12">
                        Last 10 Rounds
                      </IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
              </IonRow>
              <br />
              <IonSlides pager={true} options={slideOpts}>
                <br />
                <br />
                <br />
                <IonSlide>
                  <Chart
                    options={state && state.options}
                    series={state && state.series}
                    type="line"
                    height={300}
                  />
                </IonSlide>
                {/* <IonSlide>
                    <Chart
                      options={state2 && state2.options}
                      series={state2 && state2.series}
                      type="pie"
                      width="350"
                      height={300}
                    />
                  </IonSlide> */}
              </IonSlides>
              <IonRow className="ion-justify-content-center vh-5">
                <IonText className="wg-600 s-18 color-blue">
                  More stats coming soon!
                </IonText>
              </IonRow>
            </IonGrid>
          ) : leagues_tab_value == "Tee Times" ? (
<>
              {tee_times &&
                tee_times.map((teetime: any, index: number) => {
                  return (
                    <IonCard className="social-card" key={index} >
                      <IonCardHeader className="card-header">
                        <IonRow className="pd-5">
                          <IonCol className="display-flex" >
                            <div className="color-blue">
                              {teetime.user_image == "" ||
                                teetime.user_image == undefined ? (
                                <IonAvatar slot="start" className="card-avatar">
                                  <img src={default_user_logo} />
                                </IonAvatar>
                              ) : (
                                <IonAvatar slot="start" className="card-avatar">
                                  <img src={teetime.user_image} />
                                </IonAvatar>
                              )}
                            </div>
                            <IonLabel className="social-name">
                              <h4>{teetime.user_name}</h4>
                              <p>
                                {format(
                                  teetime.created_at,
                                  "MMM dd, yyyy HH:mm"
                                )}
                              </p>
                            </IonLabel>
                          </IonCol>
                          <IonCol size="2" className="card-options"></IonCol>
                        </IonRow>
                      </IonCardHeader>
                      <IonCardContent className="card-content">
                        <IonRow>
                          <IonCol className="pad-0" size="8" >
                            <IonText className="color-red s-13">
                              {teetime.course_name}
                            </IonText>
                            <IonText className="s-12 pl-5">
                              on {format(teetime.event_date, "MM/dd")}
                              <br />
                            </IonText>
                            <IonText className="s-12">
                              (Signup by {format(teetime.confirm_by, "MM/dd")})
                            </IonText>
                            <br />
                            <IonText className="s-12">
                              {teetime.description}
                            </IonText>
                          </IonCol>
                          <IonCol className="pad-0" size="4">
                            <IonCard className="tee-card" onClick={e => {
                              getTeetimeData(teetime.id)
                              }}>
                              <IonText className="s-12 black">
                                {teetime.remaining_signup_spots}/
                                {teetime.available_signup_spots}
                                <br />
                              </IonText>
                              <IonText className="s-12 black">
                                Available
                              </IonText>
                            </IonCard>
                          </IonCol>
                        </IonRow>
                      </IonCardContent>
                      <IonRow className="display-flex card-footer">
                        <IonCol size="3" className="ion-text-center">
                          {
                            teetime.own_event ? (
                              <div className="color-blue pointer"
                                onClick={() => {
                                  getTeetimeData(teetime.id)
                                }}
                              >
                                <CalendarEdit className="card-like-icon" />
                                <IonLabel>
                                  <IonText className="card-like-text">
                                    Edit
                                  </IonText>
                                </IonLabel>
                              </div>
                            ) : !teetime.own_event &&
                              teetime.responded &&
                              teetime.response ? (
                              <div className="color-blue pointer">
                                <CheckmarkDone className="card-like-icon" />
                                <IonLabel>
                                  <IonText className="card-like-text">
                                    Going
                                  </IonText>
                                </IonLabel>
                              </div>
                            ) : !teetime.own_event &&
                              teetime.responded &&
                              !teetime.response ? (
                              <div className="color-red pointer no-space">
                                <Cancel className="card-like-icon" />
                                <IonLabel>
                                  <IonText className="card-like-text">
                                    Can't make it
                                  </IonText>
                                </IonLabel>
                              </div>
                            ) : (
                              <div className="color-blue pointer"
                                onClick={() => {
                                  acceptTeeTime(teetime.id);
                                }}
                              >
                                <Checkmark className="card-like-icon" />
                                <IonLabel>
                                  <IonText className="card-like-text">
                                    Going
                                  </IonText>
                                </IonLabel>
                              </div>
                            )
                          }
                        </IonCol>
                        <IonCol size="5" className="ion-text-center">
                          {
                            teetime.own_event ? (
                              <>
                                <div className="color-blue pointer"
                                  onClick={() => {
                                    presentAlert({
                                      header: 'Delete Tee time?',
                                      message: 'Are you sure to delete this tee time?',
                                      buttons: [
                                        'Cancel',
                                        {
                                          text: 'Yes', handler: (d) => {
                                            deleteTeeTime(teetime.id);
                                          }
                                        },
                                      ]
                                    })
                                  }}
                                >
                                  <TrashBin className="card-like-icon" />
                                  <IonLabel>
                                    <IonText className="card-like-text">
                                      Delete
                                    </IonText>
                                  </IonLabel>
                                </div>
                              </>
                            ) : !teetime.own_event &&
                              teetime.responded &&
                              teetime.response ? (
                              <></>
                            ) : /* !teetime.own_event &&
                              teetime.responded &&
                              !teetime.response ? (
                              <div className="color-red pointer">
                                <Cancel className="card-like-icon" />
                                <IonLabel>
                                  <IonText className="card-like-text">
                                    Can't make it
                                  </IonText>
                                </IonLabel>
                              </div>
                            ) : */ (
                                <div
                                  onClick={() => {
                                    declineTeeTime(teetime.id);
                                  }}
                                  className="color-blue pointer no-space"
                                >
                                  {!teetime.own_event && !teetime.responded ? (
                                    <>
                                      <Checkmark className="card-like-icon" />
                                      <IonLabel>
                                        <IonText className="card-like-text">
                                          Can't make it
                                        </IonText>
                                      </IonLabel>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              )
                          }
                        </IonCol>
                        <IonCol size="4" className="ion-text-center">
                          <div className="color-blue pointer" onClick={() => { loadTeeCommentsData(teetime.id) }}>
                            <ChatLeftText className="card-comment-icon" />
                            <IonLabel>
                              <IonText className="card-comment">
                                {teetime.comments} comments
                              </IonText>
                            </IonLabel>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonCard>
                  );
                })}
            </>
          ) : (
            <>
            <IonContent>
            <IonRow className="ion-justify-content-center ion-text-center mt-10">
              <IonCol>
                <IonText className="s-20">Take a look at pack and player stats here!</IonText>
              </IonCol>
            </IonRow>
            <IonAccordionGroup>
            {
            (new_packs && new_packs.map((key:any,i:number)=>{
              return(
                // <IonAccordion key={i}>
                //     <IonLabel onClick={()=>{
                //       getPackPlayers(key.pack_id);
                //       setPlayersInPackModal(true);
                //     }}>
                //     {key.pack_name}
                //     </IonLabel>
                <IonAccordion key={i} value={key.pack_name.toLowerCase()} onClick={() => getPackPlayers(key.pack_id)}>
                    <IonItem slot="header">
                      <IonLabel className="ion-padding-start">{ key.pack_name }</IonLabel>
                    </IonItem>
                    <div className="ion-padding" slot="content">
                    {
                      <IonGrid>
                          <IonCard className="social-card">
                              <IonRow className="ion-justify-content-center ion-text-center bb-line">
                                  <IonCol size="2">
                                      <IonText className="s-14 wg-600 color-gray">S.No</IonText>
                                  </IonCol>
                                  <IonCol>
                                  <IonText className="s-14 wg-600 color-gray">Name</IonText>
                                  </IonCol>
                                  <IonCol>
                                  <IonText className="s-14 wg-600 color-gray">Quota</IonText>
                                  </IonCol>
                                  <IonCol>
                                  <IonText className="s-14 wg-600 color-gray">Driving Distance</IonText>
                                  </IonCol>
                              </IonRow>
                              {

                                  pack_users && pack_users.map((item:any, index:number)=> {
                                      return(
                                          <IonRow className="ion-justify-content-center ion-text-center bb-line" key={i}>
                                              <IonCol size="2">
                                                  <IonText className="s-14 color-black wg-500">{index+1}</IonText>
                                              </IonCol>
                                              <IonCol  onClick={()=> {window.location.href="/player_profile/"+item.user_id}} >
                                              <IonText className="s-14 color-red wg-600">{item.first_name+' '+item.last_name}</IonText>
                                              </IonCol>
                                              <IonCol>
                                              <IonText className="s-14 color-black wg-600">{item.quota ? item.quota : ''}</IonText>
                                              </IonCol>
                                              <IonCol>
                                              <IonText className="s-14 color-black wg-600">{item.driving_distance  && item.driving_distance.text ?item.driving_distance.text:'' }</IonText>
                                              </IonCol>
                                          </IonRow>
                                      )
                                  })
                              }
                          </IonCard>
                      </IonGrid>
                    }

                    </div>
                </IonAccordion>
              )
            }))
            }
            </IonAccordionGroup>

                <IonGrid>
                    <IonList>
                      {
                      }

                    </IonList>
                </IonGrid>
            </IonContent>
            </>
          )}
        </IonGrid>
        {
          leagues_tab_value == "Tee Times" ? (
            <IonFab vertical="bottom" horizontal="end" slot="fixed">
                <IonFabButton
                  className="main-fab"
                  onClick={() => {
                    window.location.href = "/create_tee_time";
                  }}
                >
                  <IonIcon icon={add} />
                </IonFabButton>
            </IonFab>
          ) : (
            <></>
          )
        }
        {/* <IonModal isOpen={showModal}>
          <>
            <IonHeader mode="ios">
              <IonToolbar>
                <IonTitle>Post Comments</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <Comments options={comments} feed_id={current_feed_id} />
            </IonContent>
          </>
        </IonModal> */}
        <IonModal isOpen={show_scores_modal} className="my-custom-class">
          <>
            <IonHeader>
              <IonToolbar>
                <IonLabel slot="start" className="s-16 w-600 color-blue">{score_record.course_name} - {score_record.tee}</IonLabel>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <EditTable
                options={score_record}
                options1={edit_hole_details}
                options2={hole_points}
                holes={holes}
                front9={front_holes}
                back9={back_holes}
              />
              <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{ marginBottom: '15px' }}>
                <IonFabButton
                  className="main-fab"
                  onClick={() => {
                    setShowScoresModal(false)
                  }}
                >
                  <IonIcon icon={arrowBackOutline} />
                </IonFabButton>
              </IonFab>
            </IonContent>
          </>
        </IonModal>
        <IonModal isOpen={ppwScoreCardModal} className="my-custom-class">
          <>
            <IonHeader>
              <IonToolbar>
                <IonLabel slot="start" className="s-16 w-600 color-blue">{score_record.course_name} - {score_record.tee}</IonLabel>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <PpwScoreTable
                options={score_record}
                options1={edit_hole_details}
                options2={hole_points}
                score_id={ppwScoreId}
              />
              <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{ marginBottom: '15px' }}>
                <IonFabButton
                  className="main-fab"
                  onClick={() => {
                    setPpwScoreCardModal(false)
                  }}
                >
                  <IonIcon icon={arrowBackOutline} />
                </IonFabButton>
              </IonFab>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={pending_scores_modal}
          showBackdrop={true}
          className="pending-scores-modal"
          backdropDismiss={true}
          initialBreakpoint={0.7}
          onDidDismiss={() => setPendingScoresModal(false)}
          mode="md"
        >
          <IonHeader mode="ios">
            <IonToolbar>
              <IonTitle>Pending Scores</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid>
              {pending_scores &&
                pending_scores.map((key: any, i: number) => {
                  return (
                    <IonCard className="social-card score-summary-card" key={i}>
                      <IonRow className="pad-ltb">
                        <IonCol size="9" className="pad-0">
                          <IonText className="color-blue s-15 wg-600">
                            {key.course_name}
                          </IonText>
                          <br />
                          <IonText className="s-12 wg-500">
                            {format(
                              new Date(key.competition_date_timestamp),
                              "MM/dd/yyyy"
                            )}&nbsp; {key.game_date_time}
                            &nbsp;{key.tee}&nbsp;
                            <span className="s-10">
                              {key.course_rating}/{key.slope_rating}
                            </span>
                          </IonText>
                        </IonCol>
                        <IonCol size="3" className="pad-0 ion-text-center">
                          <IonRow className="ion-text-center ion-justify-content-center mt-5">
                            <IonIcon icon={create} className="color-blue s-20" onClick={() => {
                              setScoreRecord(key);
                              if (key.holes == 9) {
                                setFrontHoles(key.tee_type == "Front" ? true : false);
                                setBackHoles(key.tee_type == "Back" ? true : false);
                              }
                              setHoles(key.holes);
                              setEditHoleDetails(key.hole_details);
                              setHolePoints(key.points);
                              setShowScoresModal(true);
                            }}
                            ></IonIcon>
                            &nbsp;&nbsp;&nbsp;
                            <IonIcon icon={trash} className="color-red s-20" onClick={() => {
                              presentAlert({
                                header: "Delete Pending Score?",
                                message:
                                  "Are you sure to delete this pending score?",
                                buttons: [
                                  "Cancel",
                                  {
                                    text: "Yes",
                                    handler: (d) => {
                                      deletePendingScore(key.id);
                                    },
                                  },
                                ],
                              });
                            }}
                            ></IonIcon>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </IonCard>
                  );
                })}
            </IonGrid>
            <IonGrid className="">
              <IonRow className="ion-justify-content-center ion-text-center mt-25">
                <button
                  className="button-send bg-blue s-15 button-border-20"
                  onClick={() => {
                    window.location.href = "/create_score";
                  }}
                >
                  <IonText className="">Post New Score</IonText>
                </button>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
        <IonModal
          isOpen={tt_pending_scores_modal}
          showBackdrop={true}
          className="pending-scores-modal"
          backdropDismiss={true}
          initialBreakpoint={0.7}
          onDidDismiss={() => setTtPendingScoresModal(false)}
          mode="md"
        >
          <IonHeader mode="ios">
            <IonToolbar>
              <IonTitle>Pending Scores</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid>
              {tt_pending_scores &&
                tt_pending_scores.map((key: any, i: number) => {
                  return (
                    <IonCard className="social-card score-summary-card" key={i}>
                      <IonRow className="pad-ltb">
                        <IonCol size="9" className="pad-0">
                          <IonText className="color-blue s-15 wg-600">
                            {key.course_name}
                          </IonText>
                          <br />
                          <IonText className="s-12 wg-500">
                            {format(
                              new Date(key.competition_date_timestamp),
                              "MM/dd/yyyy"
                            )}&nbsp;{key.game_date_time}
                            &nbsp;{key.tee}&nbsp;
                            <span className="s-10">
                              {key.course_rating}/{key.slope_rating}
                            </span>
                          </IonText>
                        </IonCol>
                        <IonCol size="3" className="pad-0 ion-text-center">
                          <IonRow className="ion-text-center ion-justify-content-center mt-5">
                            <IonIcon icon={create} className="color-blue s-20" onClick={() => {
                              setScoreRecord(key);
                              if (key.holes == 9) {
                                setFrontHoles(key.tee_type == "Front" ? true : false);
                                setBackHoles(key.tee_type == "Back" ? true : false);
                              }
                              setHoles(key.holes);
                              setEditHoleDetails(key.hole_details);
                              setHolePoints(key.points);
                              setShowScoresModal(true);
                            }}
                            ></IonIcon>
                            &nbsp;&nbsp;&nbsp;
                            <IonIcon icon={trash} className="color-red s-20" onClick={() => {
                              presentAlert({
                                header: "Delete Pending Score?",
                                message:
                                  "Are you sure to delete this pending score?",
                                buttons: [
                                  "Cancel",
                                  {
                                    text: "Yes",
                                    handler: (d) => {
                                      deleteTTPendingScore(key.id);
                                    },
                                  },
                                ],
                              });
                            }}
                            ></IonIcon>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </IonCard>
                  );
                })}
            </IonGrid>
            <IonGrid className="">
              <IonRow className="ion-justify-content-center ion-text-center mt-25">
                <button
                  className="button-send bg-blue s-15 button-border-20"
                  onClick={() => {
                    window.location.href = "/twilightour_create_checkin/" + tour_id;
                  }}
                >
                  <IonText className="">Post New Score</IonText>
                </button>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
        <IonModal
          isOpen={pendingPPWScores}
          showBackdrop={true}
          className="pending-scores-modal"
          backdropDismiss={true}
          initialBreakpoint={0.7}
          onDidDismiss={() => setPendingPpwScores(false)}
          mode="md"
        >
          <IonHeader mode="ios">
            <IonToolbar>
              <IonTitle>Pending Scores</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className='modal-height-score'>
            <IonGrid>
              {ppwScoredCard &&
                ppwScoredCard.map((key: any, i: number) => {
                  return (
                    <IonCard className="social-card score-summary-card" key={i}>
                      <IonRow className="pad-ltb">
                        <IonCol size="9" className="pad-0">
                          <IonText className="color-blue s-15 wg-600">
                            {key.course_name}
                          </IonText>
                          <br />
                          <IonText className="s-12 wg-500">
                            {key.competition_date_timestamp ? format(
                              new Date(key.competition_date_timestamp),
                              "MM/dd/yyyy"
                            ) : ''} &nbsp; {key.game_date_time}
                            &nbsp; {key.tee}&nbsp;
                            <span className="s-10">
                              {key.course_rating}/{key.slope_rating}
                            </span>
                          </IonText>
                        </IonCol>
                        <IonCol size="3" className="pad-0 ion-text-center">
                          <IonRow className="ion-text-center ion-justify-content-center mt-5">
                            {console.log(key)}
                            <IonIcon icon={create} className="color-blue s-20" onClick={() => {
                              setScoreRecord(key);
                              setPPWScoreId(key.id)
                              setHoles(key.holes);
                              setEditHoleDetails(key.hole_details);
                              setHolePoints(key.points);
                              setPpwScoreCardModal(true);
                            }}
                            ></IonIcon>
                            &nbsp;&nbsp;&nbsp;
                            <IonIcon icon={trash} className="color-red s-20" onClick={() => {
                              presentAlert({
                                header: "Delete Pending Score?",
                                message:
                                  "Are you sure to delete this pending score?",
                                buttons: [
                                  "Cancel",
                                  {
                                    text: "Yes",
                                    handler: (d) => {
                                      deletePPWPendingScore(key.id, key.scoreId);
                                    },
                                  },
                                ],
                              });
                            }}
                            ></IonIcon>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </IonCard>
                  );
                })}
            </IonGrid>
            <IonGrid className="">
              <IonRow className="ion-justify-content-center ion-text-center mt-25">
                <button
                  className="button-send bg-blue s-15 button-border-20"
                  onClick={() => {
                    window.location.href = "/add_ppw_checkin";
                  }}
                >
                  <IonText className="">Post New Score</IonText>
                </button>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>

        <IonModal
          isOpen={players_in_pack_modal}
          showBackdrop={true}
          className="players-in-pack-modal"
          backdropDismiss={true}
          initialBreakpoint={0.7}
          onDidDismiss={() => {setPlayersInPackModal(false);getPackPlayers("NONE")}}
          mode="md"
        >
          <IonHeader mode="ios">
            <IonToolbar>
              <IonTitle>Players</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>

              {/* {
                  (my_pack_bottom_tab==="List")?( */}
                  <IonGrid>
                      <IonCard className="social-card">
                          <IonRow className="ion-justify-content-center ion-text-center bb-line">
                              <IonCol size="2">
                                  <IonText className="s-14 wg-600 color-gray">S.No</IonText>
                              </IonCol>
                              <IonCol>
                              <IonText className="s-14 wg-600 color-gray">Name</IonText>
                              </IonCol>
                              <IonCol>
                              <IonText className="s-14 wg-600 color-gray">Quota</IonText>
                              </IonCol>
                              <IonCol>
                              <IonText className="s-14 wg-600 color-gray">Driving Distance</IonText>
                              </IonCol>
                          </IonRow>
                          {
                              pack_users && pack_users.map((item:any, index:number)=> {
                                  return(
                                      <IonRow className="ion-justify-content-center ion-text-center bb-line" key={index}>
                                          <IonCol size="2">
                                              <IonText className="s-14 color-black wg-500">{index+1}</IonText>
                                          </IonCol>
                                          <IonCol  onClick={()=> {window.location.href="/player_profile/"+item.user_id}} >
                                          <IonText className="s-14 color-red wg-600">{item.first_name+' '+item.last_name}</IonText>
                                          </IonCol>
                                          <IonCol>
                                          <IonText className="s-14 color-black wg-600">{item.quota ? item.quota : ''}</IonText>
                                          </IonCol>
                                          <IonCol>
                                          <IonText className="s-14 color-black wg-600">{item.driving_distance  && item.driving_distance.text ?item.driving_distance.text:'' }</IonText>
                                          </IonCol>
                                      </IonRow>
                                  )
                              })
                          }
                      </IonCard>
                  </IonGrid>
              {/*     ):(
                      <IonGrid className="mt-15">
                          <IonRow className="ion-justify-content-center ion-text-center mt-10">
                              <IonText className="s-15 black wg-bold">Settings</IonText>
                          </IonRow>
                          <IonRow className="ion-justify-content-center ion-text-center mt-5">
                              <IonCol size="5.5">
                                  <IonText className="s-13 wg-bold black">Points Scale</IonText>
                              </IonCol>
                              <IonCol size="0.5">
                              <IonText className="s-13 wg-bold black">:</IonText>
                              </IonCol>
                              <IonCol size="6">
                                  <IonText className="s-13">Bogey: 1, Par: 2,Birdie: 4,Eagle or better: 8, *Double bogey: 0.5</IonText>
                              </IonCol>
                          </IonRow>
                          <IonRow className="ion-justify-content-center ion-text-center mt-5">
                              <IonCol size="5.5">
                                  <IonText className="s-13 wg-bold black">Adjustment based on leaderboard position:</IonText>
                              </IonCol>
                              <IonCol size="0.5">
                              <IonText className="s-13 wg-bold black">:</IonText>
                              </IonCol>
                              <IonCol size="6">
                              <IonText className="s-13">80% - 100%</IonText>
                              </IonCol>
                          </IonRow>
                          <IonRow className="ion-justify-content-center ion-text-center mt-5">
                              <IonCol size="5.5">
                                  <IonLabel position="fixed" className="s-13 wg-bold black">Prize pool per Player / Round:</IonLabel>
                              </IonCol>
                              <IonCol size="0.5">
                              <IonText className="s-13 wg-bold black">:</IonText>
                              </IonCol>
                              <IonCol size="6">
                              <IonText className="s-13">50 chips($5)</IonText>
                              </IonCol>

                          </IonRow>
                          <IonRow className="ion-justify-content-center ion-text-center mt-10">
                              <IonText className="s-12 black">*If your pack has any custom configurations, please reach out to pack admin</IonText>
                          </IonRow>
                      </IonGrid>
                  )
              } */}
          </IonContent>
        </IonModal>

        <IonModal isOpen={show_tour_modal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true}
          onDidDismiss={() => setShowTourModal(false)} mode="md">
          <IonToolbar
            mode="ios"
            className={
              isPlatform("ios")
                ? "ios-toolbar-top box-shadow"
                : "toolbar-top box-shadow"
            }
          >
            <IonLabel slot="start" className="s-16 w-800 color-blue" style={{ padding: '0px 0px 0px 10px' }}>
              {tour.tour_name}
            </IonLabel>
          </IonToolbar>
          <IonContent>
            <IonGrid className="mt-15">
              <IonRow className="ion-justify-content-center ion-text-center mt-5">
                <IonText className="s-13">
                  {tour.description}
                </IonText>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-10">
                <IonText className="s-15 black wg-bold">Season: {tour.season_timeline}</IonText>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center">
                <IonCol size="5.5" className="pad-0 text-align-right">
                  <IonText className="s-13 wg-bold black">Format</IonText>
                </IonCol>
                <IonCol size="0.5" className="pad-0">
                  <IonText className="s-13 wg-bold black">:</IonText>
                </IonCol>
                <IonCol size="5.5" className="pad-0 text-align-left">
                  <IonText className="s-13">
                    {(tour.format || '').split('~')[1]}
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center">
                <IonCol size="5.5" className="pad-0 text-align-right">
                  <IonText className="s-13 wg-bold black">Partner required</IonText>
                </IonCol>
                <IonCol size="0.5" className="pad-0">
                  <IonText className="s-13 wg-bold black">:</IonText>
                </IonCol>
                <IonCol size="5.5" className="pad-0 text-align-left">
                  <IonText className="s-13">
                    {tour.partner_required}
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center">
                <IonCol size="5.5" className="pad-0 text-align-right">
                  <IonText className="s-13 wg-bold black">Total dues</IonText>
                </IonCol>
                <IonCol size="0.5" className="pad-0">
                  <IonText className="s-13 wg-bold black">:</IonText>
                </IonCol>
                <IonCol size="5.5" className="pad-0 text-align-left">
                  {
                    tour.youth_on_course > 0 ? (
                      <IonText className="s-13">
                        ${tour.season_prize_pool + tour.youth_on_course + 10}
                      </IonText>
                    ) : (
                      <IonText className="s-13">
                        ${tour.season_prize_pool + tour.youth_on_course + 10}
                      </IonText>
                    )
                  }
                </IonCol>
              </IonRow>
              {
                tour.weekly_prize_pool > 0 ? (
                  <IonRow className="ion-justify-content-center ion-text-center">
                    <IonCol size="5.5" className="pad-0 text-align-right">
                      <IonText className="s-13 wg-bold black">Weekly prize pool</IonText>
                    </IonCol>
                    <IonCol size="0.5" className="pad-0">
                      <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="5.5" className="pad-0 text-align-left">
                      <IonText className="s-13">
                        ${tour.weekly_prize_pool}
                      </IonText>
                    </IonCol>
                  </IonRow>
                ) : (
                  <></>
                )
              }
              <IonRow className="ion-justify-content-center ion-text-center">
                <IonCol size="5.5" className="pad-0 text-align-right">
                  <IonText className="s-13 wg-bold black">Post score to</IonText>
                </IonCol>
                <IonCol size="0.5" className="pad-0">
                  <IonText className="s-13 wg-bold black">:</IonText>
                </IonCol>
                <IonCol size="5.5" className="pad-0 text-align-left">
                  <IonText className="s-13">
                    {tour.pull_scores_from}
                  </IonText>
                </IonCol>
              </IonRow>
              {
                tour.fixed_courses == "No" ? (
                  <IonRow className="ion-justify-content-center ion-text-center">
                    <IonCol size="5.5" className="pad-0 text-align-right">
                      <IonText className="s-13 wg-bold black">Courses</IonText>
                    </IonCol>
                    <IonCol size="0.5" className="pad-0">
                      <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="5.5" className="pad-0 text-align-left">
                      <IonText className="s-13">
                        Any
                      </IonText>
                    </IonCol>
                  </IonRow>
                ) : (
                  <IonRow className="ion-justify-content-center ion-text-center">
                    <IonCol size="5.5" className="pad-0 text-align-right">
                      <IonText className="s-13 wg-bold black">Selected courses</IonText>
                    </IonCol>
                    <IonCol size="0.5" className="pad-0">
                      <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="5.5" className="pad-0 text-align-left">
                      {tour.selected_courses && tour.selected_courses.map((item: string, index: number) => {
                        return (
                          <div key={index}>
                            <IonText className="s-13">
                              {item.split('-')[0]}
                            </IonText>
                            <br />
                          </div>
                        )
                      })}
                    </IonCol>
                  </IonRow>
                )
              }
              {
                tour.target_based_on_handicap == "Yes" ? (
                  <IonRow className="ion-justify-content-center ion-text-center">
                    <IonCol size="5.5" className="pad-0 text-align-right">
                      <IonText className="s-13 wg-bold black">Handicap Index<sup className="s-8">TM</sup></IonText>
                    </IonCol>
                    <IonCol size="0.5" className="pad-0">
                      <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="5.5" className="pad-0 text-align-left">
                      <IonText className="s-13">
                        Required
                      </IonText>
                    </IonCol>
                  </IonRow>
                ) : (
                  <></>
                )
              }
              {
                tour.url && tour.url.length > 0 ? (
                  <IonRow className="ion-justify-content-center ion-text-center">
                    <IonCol size="5.5" className="pad-0 text-align-right">
                      <IonText className="s-13 wg-bold black">Details</IonText>
                    </IonCol>
                    <IonCol size="0.5" className="pad-0">
                      <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="5.5" className="pad-0 text-align-left">
                      <IonText className="s-13 color-red wg-600" onClick={e => {
                        Browser.open({ 'url': tour.url });
                      }}>
                        Show
                      </IonText>
                    </IonCol>
                  </IonRow>
                ) : (
                  <></>
                )
              }
              <IonRow className="ion-justify-content-center ion-text-center mt-15">
                <IonText className="s-15 black wg-bold">Players list</IonText>
              </IonRow>
              <IonGrid>
                <IonCard className="social-card">
                  <IonRow className="ion-justify-content-center ion-text-center bb-line">
                    <IonCol size="2">
                      <IonText className="s-14 wg-600 color-gray">S.No</IonText>
                    </IonCol>
                    {
                      tour.tour_name === "Corporate After Work League" ? (
                        <>
                          <IonCol size="6">
                            <IonText className="s-14 wg-600 color-gray">Name</IonText>
                          </IonCol>
                          <IonCol size="4">
                            <IonText className="s-14 wg-600 color-gray">Company</IonText>
                          </IonCol>
                        </>
                      ) : (
                        <IonCol size="10">
                          <IonText className="s-14 wg-600 color-gray">Name</IonText>
                        </IonCol>
                      )
                    }
                  </IonRow>
                  {
                    tour.players && tour.players.map((item: any, index: number) => {
                      return (
                        <IonRow className="ion-justify-content-center ion-text-center bb-line" key={index}>
                          <IonCol size="2">
                            <IonText className="s-14 color-black wg-500">{index + 1}</IonText>
                          </IonCol>
                          {
                            tour.tour_name === "Corporate After Work League" ? (
                              <>
                                <IonCol size="6">
                                  <IonText className="s-14 color-red wg-600" onClick={() => { window.location.href = "/player_profile/" + item.user_id }}>{item.user_name}</IonText>
                                </IonCol>
                                <IonCol size="4">
                                  <IonText className="s-14 color-black wg-600">{item.company}</IonText>
                                </IonCol>
                              </>
                            ) : (
                              <IonCol size="10">
                                <IonText className="s-14 color-red wg-600" onClick={() => { window.location.href = "/player_profile/" + item.user_id }}>{item.user_name}</IonText>
                              </IonCol>
                            )
                          }
                        </IonRow>
                      )
                    })
                  }
                </IonCard>
              </IonGrid>
              {
                tour.joined ? (
                  <></>
                ) : (
                  <IonRow className="ion-justify-content-center ion-text-center mt-15">
                    <IonCol size="3">

                    </IonCol>
                    <IonCol size="6">
                      <IonButton className="pro-button" onClick={e => {
                        showTTPaypalPayment(tour, (tour.season_prize_pool + tour.youth_on_course) * 10,
                          tour.season_prize_pool + tour.youth_on_course);
                      }}>
                        Join
                      </IonButton>
                    </IonCol>
                    <IonCol size="3">

                    </IonCol>
                  </IonRow>
                )
              }
            </IonGrid>
            <IonFab
              vertical="bottom"
              horizontal="end"
              slot="fixed"
              className="back-fab-button"
              style={{ marginBottom: "45px" }}
            >
              <IonFabButton
                className="main-fab"
                onClick={() => {
                  setShowTourModal(false);
                }}
              >
                <IonIcon icon={closeOutline} />
              </IonFabButton>
            </IonFab>
          </IonContent>
        </IonModal>
        <IonModal
          isOpen={showQuotaModal}
          showBackdrop={true}
          className="my-custom-class2"
          backdropDismiss={true}
          onDidDismiss={() => setShowQuotaModal(false)}
          mode="md"
        >
          <IonHeader mode="ios">
            <IonToolbar>
              <IonTitle>Quota</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid className="pending-height">
              {/* <IonRow className="ion-justify-content-center ion-text-center">
                    <IonCol size="4" className="pad-0">
                        <IonText className="s-11 black">
                        <br/>
                        <br/>
                        Bogey: 1pt
                        </IonText>
                    </IonCol>
                    <IonCol size="4" className="pad-0">
                        <IonText className="s-11 black">
                        <i>Points scale</i>
                        </IonText><br/>
                        <IonText className="s-11 black">
                        Birdie+: 4 pts
                        </IonText><br/>
                        <IonText className="s-11 black">
                        Double: 0.5 pt
                        </IonText><br/>
                        <IonText className="s-11 black">
                        Triple: -0.5 pt
                        </IonText><br/>
                    </IonCol>
                    <IonCol size="4" className="pad-0">
                        <IonText className="s-11 black">
                        <br/>
                        <br/>
                        Par: 2 pts
                        </IonText>
                    </IonCol>
                </IonRow> */}
              <IonCard className="social-card">
                <IonRow className="ion-justify-content-center ion-text-center bb-line">
                  <IonCol size="6">
                    <IonText className="s-14 wg-600 color-dark-gray">Score</IonText>
                  </IonCol>
                  <IonCol size="6">
                    <IonText className="s-14 wg-600 color-dark-gray">Point Scale</IonText>
                  </IonCol>

                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center bb-line">
                  <IonCol size="6">
                    <IonText className="s-14 color-black wg-500">Birdie+</IonText>
                  </IonCol>
                  <IonCol size="6">
                    <IonText className="s-14 color-black wg-500">4 points</IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center bb-line">
                  <IonCol size="6">
                    <IonText className="s-14 color-black wg-500">Par</IonText>
                  </IonCol>
                  <IonCol size="6">
                    <IonText className="s-14 color-black wg-500">2 points</IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center bb-line">
                  <IonCol size="6">
                    <IonText className="s-14 color-black wg-500">Bogey</IonText>
                  </IonCol>
                  <IonCol size="6">
                    <IonText className="s-14 color-black wg-500">1 point</IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center bb-line">
                  <IonCol size="6">
                    <IonText className="s-14 color-black wg-500">Double</IonText>
                  </IonCol>
                  <IonCol size="6">
                    <IonText className="s-14 color-black wg-500">0 points</IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center bb-line">
                  <IonCol size="6">
                    <IonText className="s-14 color-black wg-500">Triple-</IonText>
                  </IonCol>
                  <IonCol size="6">
                    <IonText className="s-14 color-black wg-500">-1 points</IonText>
                  </IonCol>
                </IonRow>
              </IonCard>
              <IonRow className="ion-justify-content-center ion-text-center mt-25">
                <IonText className="s-14">
                  Quota is an indicative target based on past points performance.<br /><br />
                  Quota is reset every Monday to reflect<br />player's best scoring ability week after week<br /><br />
                  It is increased by 1 for every 2 points scored above target<br />and decreased by 1 for every 4 points scored below target
                </IonText>
              </IonRow>
            </IonGrid>
            <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button">
              <IonFabButton
                className="main-fab"
                onClick={() => {
                  setShowQuotaModal(false);
                }}
              >
                <IonIcon icon={arrowBackOutline} />
              </IonFabButton>
            </IonFab>
          </IonContent>
        </IonModal>
        <IonModal isOpen={score_modal} className='my-custom-class' onDidDismiss={() => { setScoreModal(false); }}>
          <>
            <IonHeader>
              <IonToolbar>
                <IonLabel slot="start" className="s-16 w-600 color-blue">{score_record.course_name} - {score_record.tee}</IonLabel>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <Table options={score_record} holes={holes} front9={front_holes} back9={back_holes} />
              <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{ marginBottom: '15px' }}>
                <IonFabButton
                  className="main-fab"
                  onClick={() => {
                    setScoreModal(false)
                  }}
                >
                  <IonIcon icon={arrowBackOutline} />
                </IonFabButton>
              </IonFab>
            </IonContent>
          </>
        </IonModal>
        <IonModal isOpen={show_teetime_modal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true}
          onDidDismiss={() => setShowTeetimeModal(false)} mode="md">
          <IonToolbar
            mode="ios"
            className={
              isPlatform("ios")
                ? "ios-toolbar-top box-shadow"
                : "toolbar-top box-shadow"
            }
          >
            <IonLabel slot="start" className="s-16 w-800 color-blue" style={{ padding: '0px 0px 0px 10px' }}>
              Tour details
            </IonLabel>
          </IonToolbar>
          <IonContent>
            <IonGrid className="mt-15">
              <IonGrid>
                <IonRow className="ion-justify-content-center ion-text-center mt-15">
                  <IonText className="s-15 black wg-bold">Going</IonText>
                </IonRow>
                <IonCard className="social-card">
                  <IonRow className="ion-justify-content-center ion-text-center bb-line">
                    <IonCol size="2">
                      <IonText className="s-14 wg-600 color-gray">S.No</IonText>
                    </IonCol>
                    <IonCol size="10">
                      <IonText className="s-14 wg-600 color-gray">Name</IonText>
                    </IonCol>
                  </IonRow>
                  {
                    teetime_data.accepted_users && teetime_data.accepted_users.map((item: any, index: number) => {
                      return (
                        <IonRow className="ion-justify-content-center ion-text-center bb-line" key={index}>
                          <IonCol size="2">
                            <IonText className="s-14 color-black wg-500">{index + 1}</IonText>
                          </IonCol>

                          <IonCol size="10">
                            <IonText className="s-14 color-black wg-600">{item.user_name}</IonText>
                          </IonCol>
                        </IonRow>
                      )
                    })
                  }
                </IonCard>
                <IonRow className="ion-justify-content-center ion-text-center mt-15">
                  <IonText className="s-15 black wg-bold">Yet to decide</IonText>
                </IonRow>
                <IonCard className="social-card">
                  <IonRow className="ion-justify-content-center ion-text-center bb-line">
                    <IonCol size="2">
                      <IonText className="s-14 wg-600 color-gray">S.No</IonText>
                    </IonCol>
                    <IonCol size="10">
                      <IonText className="s-14 wg-600 color-gray">Name</IonText>
                    </IonCol>
                  </IonRow>
                  {
                    teetime_data.pending_users && teetime_data.pending_users.map((item: any, index: number) => {
                      return (
                        <IonRow className="ion-justify-content-center ion-text-center bb-line" key={index}>
                          <IonCol size="2">
                            <IonText className="s-14 color-black wg-500">{index + 1}</IonText>
                          </IonCol>

                          <IonCol size="10">
                            <IonText className="s-14 color-black wg-600">{item.user_name}</IonText>
                          </IonCol>
                        </IonRow>
                      )
                    })
                  }
                </IonCard>
                <IonRow className="ion-justify-content-center ion-text-center mt-15">
                  <IonText className="s-15 black wg-bold">Not going</IonText>
                </IonRow>
                <IonCard className="social-card">
                  <IonRow className="ion-justify-content-center ion-text-center bb-line">
                    <IonCol size="2">
                      <IonText className="s-14 wg-600 color-gray">S.No</IonText>
                    </IonCol>
                    <IonCol size="10">
                      <IonText className="s-14 wg-600 color-gray">Name</IonText>
                    </IonCol>
                  </IonRow>
                  {
                    teetime_data.rejected_users && teetime_data.rejected_users.map((item: any, index: number) => {
                      return (
                        <IonRow className="ion-justify-content-center ion-text-center bb-line" key={index}>
                          <IonCol size="2">
                            <IonText className="s-14 color-black wg-500">{index + 1}</IonText>
                          </IonCol>

                          <IonCol size="10">
                            <IonText className="s-14 color-black wg-600">{item.user_name}</IonText>
                          </IonCol>
                        </IonRow>
                      )
                    })
                  }
                </IonCard>
              </IonGrid>
            </IonGrid>
            <IonFab
              vertical="bottom"
              horizontal="end"
              slot="fixed"
              className="back-fab-button"
              style={{ marginBottom: "45px" }}
            >
              <IonFabButton
                className="main-fab"
                onClick={() => {
                  setShowTeetimeModal(false);
                }}
              >
                <IonIcon icon={closeOutline} />
              </IonFabButton>
            </IonFab>
          </IonContent>
        </IonModal>
        <IonModal isOpen={showCommentsModal}>
          <>
            <IonHeader mode="ios">
              <IonToolbar>
                <IonButtons slot="start">
                  {/* <a onClick={()=> setShowModal(false)}>
                  <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                </a> */}
                </IonButtons>
                <IonTitle>Post Comments</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonGrid className="mt-25">
                {comments &&
                  comments.map((key: any, i: number) => {
                    return (
                      <IonRow className="ion-justify-content-center" key={i}>
                        <IonCol size="3" className="pl-10">
                          <IonAvatar style={{height:"35px",width:"35px"}}>
                            <img src={key.user_image} alt=''/>
                          </IonAvatar>
                        </IonCol>
                        <IonCol size="9" style={{ marginRight: "-10px" }}>
                          <IonRow>
                            <IonCol size="6">
                              <IonText className="color-blue s-14">
                                {key.user_name}
                              </IonText>
                            </IonCol>
                            <IonCol size="6" className="ion-text-end">
                              <IonText className="s-12 comment_text">
                                {format(key.created_at, "MM-dd-yyyy HH:mm")}
                              </IonText>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonText className="s-13 pl-5">
                              {key.comment_text}
                              <div
                                className="dynamic-content-div"
                                dangerouslySetInnerHTML={{
                                  __html: key.comment,
                                }}
                              ></div>
                            </IonText>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    );
                  })}
              </IonGrid>
              <IonGrid className="mt-25">
                <Quill key={key_date} onQuill={onQuillChange} content={''} />
                <IonRow className="display-flex mt-10 justify-content-space-evenly ion-text-center">
                  <button
                    className="button-close bg-red white wg-bold comments-close"
                    onClick={() => {
                      setShowCommentsModal(false);
                    }}
                  >
                    Close
                  </button>
                  {
                    (content.length > 0) ? (
                      <button
                        className="button-close white wg-bold bg-blue"
                        onClick={() => {
                          makeTeeComment();
                        }}
                      >
                        Post
                      </button>
                    ) : (
                      <button
                        className="button-close white wg-bold bg-blue disabled-button"
                      >
                        Post
                      </button>
                    )
                  }
                </IonRow>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal isOpen={set_paypal_payment}>
          <>
            <IonHeader mode="ios">
              <IonToolbar>
                <IonButtons slot="start">
                  {/* <a onClick={()=> setShowModal(false)}>
                        <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                        </a> */}
                </IonButtons>
                <IonTitle>
                  {tour.tour_name} Payment
                </IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonGrid className="mt-15">
                <IonRow className="ion-justify-content-center ion-text-center mt-10">
                  <IonCol>
                    <IonLabel position="fixed">Total Price:</IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonText>${amount}</IonText>
                  </IonCol>

                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center mt-10">
                  <IonCol>
                    <IonText>Total Processing Charges:</IonText>
                  </IonCol>
                  <IonCol>
                    <IonText>{((((chips_qty * 0.1) * 3.49) / 100) + 0.60).toFixed(2)}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center mt-10">
                  <IonCol>
                    <IonLabel position="fixed">Total Cost:</IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonText>${amount + Number((((amount * 3.49) / 100) + 0.60).toFixed(2))}</IonText>
                  </IonCol>

                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center mt-25">

                </IonRow>
              </IonGrid>
              <PayPalScriptProvider
                options={paypal_data}
              >
                <PayPal paypal_data={paypal_data} required_data={required_data} referrer={referrer} chips_qty={chips_qty} amount={amount} currency={'USD'} showSpinner={false} parentCallback={handleCallback} />
              </PayPalScriptProvider>
              <IonRow className="display-flex ion-justify-content-center ion-text-center mt-25">
                <button className='button-close bg-blue wg-bold' style={{ width: "50%", height: "35px", borderRadius: "50px", fontSize: "16px" }} onClick={() => setPaypalPayment(false)}>
                  Close
                </button>
              </IonRow>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showJoinPackModal}
          showBackdrop={true}
          className="join-pack-modal"
          backdropDismiss={true}
          initialBreakpoint={0.7}
          onDidDismiss={() => setShowJoinPackModal(false)} mode="md"
        >
          <IonHeader mode="ios">
            <IonToolbar>
              <IonTitle>Join a Pack</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid className="">
              {selected_pack && selected_pack.pack_name ? (
                <>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <img src={selected_pack.logo_url} width="80" height="80" />
                    <br />
                  </IonRow>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <IonText>Pack name: {selected_pack.pack_name}</IonText>
                  </IonRow>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <IonText>City: {selected_pack.city}</IonText>
                  </IonRow>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <IonText>Pack Type: {selected_pack.pack_type}</IonText>
                  </IonRow>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <IonText>Description: {selected_pack.description}</IonText>
                    <br />
                  </IonRow>
                </>
              ) : (
                <></>
              )}
              <IonRow className="ion-justify-content-center mt-10">
                <IonSelect value={pack_city}
                  interfaceOptions={customAlertOptions2}
                  interface="popover"
                  placeholder="Select a City" className="s-14 select-pack-dropdown" onIonChange={e => {
                    setPackCity(e.detail.value)
                    filterPacks(e.detail.value)
                  }} mode="ios">
                  <IonSelectOption value="Any">Any</IonSelectOption>
                  <IonSelectOption value="Kansas City">Kansas City Metro</IonSelectOption>
                  <IonSelectOption value="Port St. Lucie">Port St. Lucie, FL</IonSelectOption>
                  <IonSelectOption value="St. Louis">St. Louis, MO</IonSelectOption>
                  <IonSelectOption value="Des Moines">Des Moines, IA</IonSelectOption>
                  <IonSelectOption value="Wichita">Wichita, KS</IonSelectOption>
                  <IonSelectOption value="Salt Lake City">Salt Lake City, UT</IonSelectOption>
                  <IonSelectOption value="Topeka">Topeka, KS</IonSelectOption>
                  <IonSelectOption value="Salina">Salina, KS</IonSelectOption>
                  <IonSelectOption value="Manhattan">Manhattan, KS</IonSelectOption>
                  <IonSelectOption value="Hutchinson">Hutchinson, KS</IonSelectOption>
                  <IonSelectOption value="Lawrence">Lawrence, KS</IonSelectOption>
                  <IonSelectOption value="Leavenworth">Leavenworth, KS</IonSelectOption>
                  <IonSelectOption value="Denver">Denver, CO</IonSelectOption>
                </IonSelect>
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10">
                <IonSelect
                  value={selected_pack_id}
                  interfaceOptions={customAlertOptions3}
                  interface="popover"
                  placeholder="Select Pack"
                  className="s-14 select-pack-dropdown"
                  onIonChange={(e) => {
                    setSelectedPackId(e.detail.value);
                    setPack(e.detail.value);
                  }}
                  mode="ios"
                >
                  {filtered_packs &&
                    filtered_packs.map((pack: any, index: number) => {
                      return (
                        <IonSelectOption value={pack.pack_id} key={index}>
                          {pack.pack_name}
                        </IonSelectOption>
                      );
                    })}
                </IonSelect>
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10">
                <IonItem
                  lines="none"
                  className="select-pack-dropdown"
                  style={{ height: "43px" }}
                >
                  <IonInput
                    value={pack_message}
                    placeholder="Message"
                    onIonChange={(e) => setPackMessage(e.detail.value!)}
                    clearInput
                  ></IonInput>
                </IonItem>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-25">
                <button
                  className="button-send white s-15"
                  onClick={sendJoinRequest}
                >
                  <IonIcon icon={paperPlane} className="white" />
                  <IonText className="pl-5 send-request">Send Request</IonText>
                </button>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-25">
                <IonText>Or you can  <span className="create-button-outline color-blue" onClick={() => {
                  window.location.href = "/create_pack"
                }}>Create</span> your own pack</IonText>
              </IonRow>
            </IonGrid>
            <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{ marginBottom: '45px' }}>
              <IonFabButton
                className="main-fab"
                onClick={() => {
                  setShowJoinPackModal(false)
                }}
              >
                <IonIcon icon={arrowBackOutline} />
              </IonFabButton>
            </IonFab>
          </IonContent>
        </IonModal>
      </IonContent>
      <IonToolbar mode="ios" className="segment-toolbar" slot="bottom">
        <IonSegment
          onIonChange={(e) => {
            setLeaguesTabValue(e.detail.value!);
            if (e.detail.value == "Scores") {
              getProfileData(current_user);
            } else if (e.detail.value == "Stats") {
              getGraphData(Number(hole), Number(rounds), current_user.user_id);
            } else if (e.detail.value == "Tee Times") {
              getTeeTimes();
            } else if (e.detail.value == "Players") {

            }
          }}
          value={leagues_tab_value}
          mode="ios"
          className="new-segment"
        >
          <IonSegmentButton value="Leagues" className="new-segment-button">
            <IonLabel className="new-segment-button-label">Join/Create</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="Players" className="new-segment-button">
            <IonLabel className="new-segment-button-label">Players</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="Tee Times" className="new-segment-button">
            <IonLabel className="new-segment-button-label">Tee Times</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </IonToolbar>
      <BottomBar cur_page="leagues"/>
    </IonPage>
  );
};

export default Leagues;
