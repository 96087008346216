import {
  IonButton,
  IonContent,
  IonPage,
  IonToolbar,
  IonIcon,
  IonLabel,
  IonText,
  IonGrid,
  IonSegment,
  IonSegmentButton,
  IonCard,
  IonRow,
  IonCol,
  IonFab,
  IonFabButton,
  IonModal,
  IonTitle,
  IonLoading,
  useIonToast,
  IonButtons,
} from "@ionic/react";
import "../../css/eclubhouse.css";
import main_logo from "../../img/eclub.golf.png"
import { isPlatform } from "@ionic/react";
import { useEffect, useState } from "react";
import _ from "lodash";
import { format } from "date-fns";
import { RefresherEventDetail } from "@ionic/core";
import firebase from "../../js/firebase.js";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { addCircleOutline, arrowBackOutline, closeOutline } from "ionicons/icons";
import { updateEntry } from "../../js/firebase_doc_functions";

const db = getFirestore(firebase);

const Tours: React.FC = () => {
  const [current_user, setCurrentUser] = useState<any>([]);
  const [tours_tab_value, setToursTabValue] = useState<any>("New");
  const [tours, setTours] = useState<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [showToast, hideToast] = useIonToast();
  const [tour, setTour] = useState<any>({
    tour_name: "",
    city: "",
    format: "",
    season_prize_pool: 0,
    weekly_prize_pool: 0,
    fixed_courses: "",
    fixed_tees: "",
    holes: 0,
    players: [],
    selected_courses: [],
    selected_tees: [],
    pull_scores_from: "",
    partner_required: "",
    visibility: "",
    youth_on_course: 0,
    status: ""
  });
  const [show_tour_modal, setShowTourModal] = useState<boolean>(false);

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    console.log("Begin async operation");
    setTimeout(() => {
      console.log("Async operation has ended");
      window.location.href = "/leagues/formats";
      event.detail.complete();
    }, 2000);
  }
  const getTours = async(user_id:string) => {
    const q = query(collection(db, "TOUR_SETTINGS"), where('created_at', '>', 0), where('status', '==', 'Approved'));
    const querySnapshot = await getDocs(q);
    await Promise.all(querySnapshot.docs.map(async competition => {
      var doc_item = competition.data();
      doc_item.id = competition.id;
      let players = doc_item.players || [];
      if(players.filter((x:any)=> x.user_id === user_id).length <= 0) {
        doc_item.joined = false;
      } else {
        doc_item.joined = true;
      }
      return doc_item;
    }))
    .then(results => {
      setTours(_.sortBy(results, (e: any) => e.created_at).reverse());
    });
  }
  const getTourData = async (tour_id:string) => {
    let tourData = tours.filter((x:any)=> x.id == tour_id);
    if(tourData.length > 0) {
      setTour(tourData[0]);
      setShowTourModal(true);
    } 
  }
  const joinTour = async() => {
    setShowLoading(true);
    let players = tour.players.filter((x:any)=> 
      x.user_id == current_user.user_id
    );
    if(players.length <= 0) {
      players.push({user_id: current_user.user_id, user_name: current_user.first_name+' '+current_user.last_name});
      await updateEntry("TOUR_SETTINGS", tour.id, {players: players});
      setShowLoading(false);
      showToast('Succesfully signed up for tour', 2000);
    }
  }

  useEffect(() => {
    let user = localStorage.getItem("firebase_user");
    if (user != null) {
      setCurrentUser(JSON.parse(user));
      getTours(JSON.parse(user).user_id);
    }

  }, []);
  // console.log("darkmode checking",document.body.classList.contains('dark'))
  return (
    <IonPage>
      <IonToolbar
        mode="ios"
        className={isPlatform("ios") ? "ios-toolbar-top" : "toolbar-top"}
      >
        <IonLabel slot="start" className="s-18 w-800 color-blue" style={{padding: '0px 0px 0px 10px'}}>Tours</IonLabel>
        <IonButtons
          slot="end"
          className="slot-end-padding"
          onClick={() => (window.location.href = "/create_tour")}
        >
          <IonIcon icon={addCircleOutline} className="s-30 color-blue" />
        </IonButtons>
      </IonToolbar>
      <IonContent>
        <IonGrid className="pad-0">
        <IonRow className="ion-justify-content-center ion-text-center mt-10">
          <IonText className="s-12 black">
            *If you need any custom configuration, reach out to info@eclub.golf
          </IonText>
        </IonRow>
          <IonRow className="mt-10">
          {
            tours && tours.map((item: any, index:number) => {
              return (
                <IonCol size="6" key={index} onClick={e=> {
                  getTourData(item.id);
                }}>
                <IonCard className="tour-cards">
                  {/* <IonRow className="ion-justify-content-center">
                  <img src={main_logo} alt={item.pack_name} className="tours-pic" />
                  </IonRow> */}
                  <IonRow className="ion-text-center ion-justify-content-center"> 
                    <IonText className="s-16 wg-600 color-blue">
                      {item.tour_name}
                    </IonText>
                  </IonRow>
                  <IonRow className="ion-text-center ion-justify-content-center">
                    <IonText className="s-12 black wg-600 gray">{(item.format || '').split('~')[1]}</IonText>
                  </IonRow>
                  <IonRow className="ion-text-center ion-justify-content-center">
                    <IonText className="s-12 black wg-600 gray">City: {item.city}</IonText>
                  </IonRow>
                  <IonRow className="ion-text-center ion-justify-content-center">
                      {
                        item.joined === true ? (
                          <IonText className="s-12 black wg-600 color-blue">Participant</IonText>
                        ) : (
                          <IonText className="s-12 black wg-600 color-red">New</IonText>
                        )
                      }
                  </IonRow>
                </IonCard>
                </IonCol>
              )
            })
          }
          </IonRow>
        </IonGrid>
        <IonModal isOpen={show_tour_modal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true} 
        onDidDismiss={() => setShowTourModal(false)} mode="md">
          <IonToolbar
            mode="ios"
            className={
              isPlatform("ios")
                ? "ios-toolbar-top box-shadow"
                : "toolbar-top box-shadow"
            }
          >
            <IonLabel slot="start" className="s-16 w-800 color-blue" style={{padding: '0px 0px 0px 10px'}}>
            {tour.tour_name}
            </IonLabel>
          </IonToolbar>
          <IonContent>
            <IonGrid className="mt-15">
              <IonRow className="ion-justify-content-center ion-text-center mt-5">
                  <IonText className="s-13">
                    {tour.description}
                  </IonText>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-10">
                <IonText className="s-15 black wg-bold">Settings</IonText>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-5">
                <IonCol size="5.5">
                  <IonText className="s-13 wg-bold black">Format</IonText>
                </IonCol>
                <IonCol size="0.5">
                  <IonText className="s-13 wg-bold black">:</IonText>
                </IonCol>
                <IonCol size="6">
                  <IonText className="s-13">
                    {(tour.format || '').split('~')[1]}
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-5">
                <IonCol size="5.5">
                  <IonText className="s-13 wg-bold black">Partner required</IonText>
                </IonCol>
                <IonCol size="0.5">
                  <IonText className="s-13 wg-bold black">:</IonText>
                </IonCol>
                <IonCol size="6">
                  <IonText className="s-13">
                    {tour.partner_required}
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-5">
                <IonCol size="5.5">
                  <IonText className="s-13 wg-bold black">Season dues</IonText>
                </IonCol>
                <IonCol size="0.5">
                  <IonText className="s-13 wg-bold black">:</IonText>
                </IonCol>
                <IonCol size="6">
                  <IonText className="s-13">
                    $10
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-5">
                <IonCol size="5.5">
                  <IonText className="s-13 wg-bold black">Season prize pool</IonText>
                </IonCol>
                <IonCol size="0.5">
                  <IonText className="s-13 wg-bold black">:</IonText>
                </IonCol>
                <IonCol size="6">
                  <IonText className="s-13">
                    ${tour.season_prize_pool}
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-5">
                <IonCol size="5.5">
                  <IonText className="s-13 wg-bold black">Youth on Course</IonText>
                </IonCol>
                <IonCol size="0.5">
                  <IonText className="s-13 wg-bold black">:</IonText>
                </IonCol>
                <IonCol size="6">
                  <IonText className="s-13">
                    ${tour.youth_on_course}
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-5">
                <IonCol size="5.5">
                  <IonText className="s-13 wg-bold black">Weekly prize pool</IonText>
                </IonCol>
                <IonCol size="0.5">
                  <IonText className="s-13 wg-bold black">:</IonText>
                </IonCol>
                <IonCol size="6">
                  <IonText className="s-13">
                    ${tour.weekly_prize_pool}
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-5">
                <IonCol size="5.5">
                  <IonText className="s-13 wg-bold black">Pull scores from</IonText>
                </IonCol>
                <IonCol size="0.5">
                  <IonText className="s-13 wg-bold black">:</IonText>
                </IonCol>
                <IonCol size="6">
                  <IonText className="s-13">
                    {tour.pull_scores_from}
                  </IonText>
                </IonCol>
              </IonRow>
              {
                tour.fixed_courses == "No" ? (
                  <IonRow className="ion-justify-content-center ion-text-center mt-5">
                    <IonCol size="5.5">
                      <IonText className="s-13 wg-bold black">Courses</IonText>
                    </IonCol>
                    <IonCol size="0.5">
                      <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="6">
                      <IonText className="s-13">
                        Any
                      </IonText>
                    </IonCol>
                  </IonRow>
                ) : (
                  <IonRow className="ion-justify-content-center ion-text-center mt-5">
                    <IonCol size="5.5">
                      <IonText className="s-13 wg-bold black">Selected courses</IonText>
                    </IonCol>
                    <IonCol size="0.5">
                      <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="6">
                      {tour.selected_courses && tour.selected_courses.map((item:string, index:number)=> {
                        return (
                          <div  key={index}>
                          <IonText className="s-13">
                            {item}
                          </IonText>
                          <br/>
                          </div>
                        )
                      })}
                    </IonCol>
                  </IonRow>
                )
              }
              {
                tour.target_based_on_handicap == "Yes" ? (
                  <IonRow className="ion-justify-content-center ion-text-center mt-5">
                    <IonCol size="5.5">
                      <IonText className="s-13 wg-bold black">Handicap Index<sup className="s-8">TM</sup></IonText>
                    </IonCol>
                    <IonCol size="0.5">
                      <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="6">
                      <IonText className="s-13">
                        Required
                      </IonText>
                    </IonCol>
                  </IonRow>
                ) : (
                  <></>
                )
              }
              <IonRow className="ion-justify-content-center ion-text-center mt-15">
                <IonText className="s-15 black wg-bold">Players list</IonText>
              </IonRow>
              <IonGrid>
                <IonCard className="social-card">
                    <IonRow className="ion-justify-content-center ion-text-center bb-line">
                        <IonCol size="2">
                            <IonText className="s-14 wg-600 color-gray">S.No</IonText>
                        </IonCol>
                        <IonCol size="10">
                        <IonText className="s-14 wg-600 color-gray">Name</IonText>
                        </IonCol>
                    </IonRow>
                    {
                      tour.players && tour.players.map((item:any, index:number)=> {
                        return(
                          <IonRow className="ion-justify-content-center ion-text-center bb-line" key={index}>
                              <IonCol size="2">
                                  <IonText className="s-14 color-black wg-500">{index+1}</IonText>
                              </IonCol>
                              <IonCol size="10">
                              <IonText className="s-14 color-black wg-600">{item.user_name}</IonText>
                              </IonCol>
                          </IonRow>
                        )
                      })
                    }
                </IonCard>
              </IonGrid>
              {
                tour.joined ? (
                  <></>
                ) : (
                  <IonRow className="ion-justify-content-center ion-text-center mt-15">
                    <IonCol size="3">

                    </IonCol>
                    <IonCol size="6">
                      <IonButton className="pro-button" onClick={e=> {
                        joinTour();
                      }}>
                        Join
                      </IonButton>
                    </IonCol>
                    <IonCol size="3">

                    </IonCol>
                  </IonRow>
                )
              }
            </IonGrid>
            <IonFab
              vertical="bottom"
              horizontal="end"
              slot="fixed"
              className="back-fab-button"
              style={{ marginBottom: "45px" }}
            >
              <IonFabButton
                className="main-fab"
                onClick={() => {
                  setShowTourModal(false);
                }}
              >
                <IonIcon icon={closeOutline} />
              </IonFabButton>
            </IonFab>
          </IonContent>
        </IonModal>
        <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '45px'}}>
            <IonFabButton
            className="main-fab"
            onClick={() => {
              window.location.href="/leagues/formats"
            }}
            >
            <IonIcon icon={arrowBackOutline} />
            </IonFabButton>
        </IonFab>
        <IonLoading
          isOpen={showLoading}
          message={'Signing up..'}
        />
      </IonContent>
    </IonPage>
  );
};

export default Tours;
