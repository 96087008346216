import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonLabel,
  IonButtons,
  IonGrid,
  IonCard,
  IonCol,
  IonItem,
  IonRow,
  IonText,
  IonInput,
  IonChip,
  IonModal,
  IonSearchbar,
  IonList,
  IonLoading,
  IonRadioGroup,
  IonRadio,
  IonFab,
  IonFabButton,
} from "@ionic/react";
import { arrowBackOutline, chevronBackOutline, closeCircle } from "ionicons/icons";
import { useEffect, useState } from "react";
import * as utils from "../../js/utils";
import { format } from "date-fns";
import _ from "lodash";
import { createEntryWithoutId, updateEntry } from "../../js/firebase_doc_functions";
import firebase from "../../js/firebase.js";

import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

const db = getFirestore(firebase);

const CreatePack: React.FC = () => {
  const [select_state, setSelectState] = useState("");
  const [all_players, setAllPlayers] = useState<any>([]);
  const [current_user, setCurrentUser] = useState<any>();
  const [city, setCity] = useState<string>("");
  const [course_list, setCourseList] = useState<any>([]);
  const [pack_visibility, setPackVisibility] = useState<any>("Public");
  const [pack_name, setPackName] = useState<string>("");
  const [showLoading, setShowLoading] = useState(false);
  const [players_list, setPlayersList] = useState<any>([]);
  const [pack_players, setPackPlayers] = useState<any>([]);
  const [selected_player, setSelectedPlayer] = useState<any>("");
  const [selected_player_id, setSelectedPlayerId] = useState<any>("");
  const [selected_players_list, setSelectedPlayersList] = useState<any>([]);

  const getUsers = async (user_id: string) => {
    var condition = where("created_at", ">", 0);
    const q = query(collection(db, "USER_PROFILE"), condition);

    const querySnapshot = await getDocs(q);
    var records = new Array();
    records = querySnapshot.docs
      .map((doc) => {
        let doc_item = doc.data();
        doc_item.id = doc.id;
        return doc_item;
      })
      .filter((doc: any) => doc.user_id != user_id);
    setAllPlayers(records);
  };

  const add_players = (name: any, id: string) => {
    if (!selected_players_list.includes(name)) {
      selected_players_list.push(name);
      setSelectedPlayersList(Array.from(new Set([...selected_players_list])));
      setPackPlayers(Array.from(new Set([...pack_players, ...[{user_id:id, user_name:name, is_pack_lead:false}]])));
      setSelectedPlayer("");
    }
  };
  const del_players = (name: any) => {
    if (selected_players_list.includes(name)) {
      setSelectedPlayersList(
        selected_players_list.filter((ele: any) => {
          return ele != name;
        })
      );
      setPackPlayers(pack_players.filter((x:any)=> x.user_name != name));
    }
  };

  const createPack = async () => {
    setShowLoading(true);
    let pack_id = await utils.randomString(28, "#aA");
    var pack_obj = {
      city: "Any",
      pack_id: pack_id,
      pack_description: '',
      pack_type: 'TheAmateurOpen',
      players: pack_players.length > 0 ? pack_players : [] ,
      pack_lead_id: current_user.user_id,
      pack_visibility: pack_visibility,
      user_name: current_user.first_name + " " + current_user.last_name,
      user_id: current_user.user_id,
      pack_name: pack_name,
    };

    pack_obj.players.push({"user_name": current_user.first_name+' '+current_user.last_name, "user_id": current_user.user_id, 'is_pack_lead': true})
    await createEntryWithoutId("PACKS", pack_obj);
    let new_user = current_user;
    new_user.packs.push({pack_name: pack_name, pack_id: pack_id});
    await updateEntry("USER_PROFILE", current_user.id, {packs: new_user.packs});
    localStorage.setItem("firebase_user", JSON.stringify(new_user));
    if(pack_players.length > 0) {
      await Promise.all(
        pack_players.map(async (player: any) => {
          await createEntryWithoutId("PACK_NOTIFICATIONS", {
            'pack_name': pack_name,
            'pack_id': pack_id,
            'user_id': player.user_id,
            'user_name': player.user_name,
            'is_read': false,
            'invited_user_id': current_user.user_id,
            'invited_user_name': current_user.first_name +' '+current_user.last_name,
            'type': 'JOIN_REQUEST'
          });
        })
      ).then((results) => {
        setShowLoading(false);
        window.location.href = "/locker_room";
      });
    } else {
      setShowLoading(false);
      window.location.href = "/locker_room";
    }
  };

  const customAlertOptions = {
    header: "Select State",
    translucent: true,
  };
  const customAlertOptions1 = {
    header: "Select one",
  };

  const customAlertOptions2 = {
    header: "Publish to",
  };

  const states = [
    { city: "Kansas City", city_name: "Kansas City Metro" },
    { city: "St. Louis", city_name: "St. Louis, MO" },
    { city: "Des Moines", city_name: "Des Moines, IA" },
    { city: "Wichita", city_name: "Wichita, KS" },
    { city: "Salt Lake City", city_name: "Salt Lake City, UT" },
    { city: "Iowa City", city_name: "Iowa City, IA" },
    { city: "Dallas", city_name: "Dallas, TX" },
    { city: "New Jersey", city_name: "New Jersey, NJ" },
    { city: "Columbus", city_name: "Columbus, OH" },
    { city: "Charlotte", city_name: "Charlotte, NC" },
    { city: "Denver", city_name: "Denver, CO" },
    { city: "Other", city_name: "Other" },
  ];
  const [show1, setShow1] = useState<any>(true);
  const [show2, setShow2] = useState<any>(false);
  const [show3, setShow3] = useState<any>(false);
  const [cities, setCities] = useState<any>(states);
  const [new_players, setNewPlayers] = useState<any>(players_list);
  const [showStateModal, setShowStateModal] = useState(false);
  const [showPlayersModal, setShowPlayersModal] = useState(false);
  useEffect(() => {
    let user = localStorage.getItem("firebase_user");
    if (user != null) {
      setCurrentUser(JSON.parse(user));
      getUsers(JSON.parse(user).user_id);
      setCity(JSON.parse(user).city)
    }
  }, []);
  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar>
          {/* <IonButtons slot="start">
            <a onClick={() => (window.location.href = "/locker_room")}>
              <IonIcon className="well-back-icon" icon={chevronBackOutline} />
            </a>
          </IonButtons> */}
          <IonTitle>Create a Pack</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid
          style={{ paddingLeft: "8%", margin: "auto", maxWidth: "345px" }}
        >
          <IonRow
            style={{
              borderBottom: "1px solid gray",
              width: "80%",
              margin: "35px 10px 0px 15px",
            }}
            className="ion-justify-content-center ion-text-center"
          ></IonRow>
          <IonRow
            className="ion-justify-content-center ion-text-start pl-10"
            style={{ marginTop: "-9%" }}
          >
            <IonCol size="4">
              <div
                className={
                  show1 || show2 || show3 ? "circle bg-green" : "circle"
                }
              >
                1
              </div>
              <IonText className="pl-5">Basic</IonText>
            </IonCol>
            <IonCol size="4">
              <div className={show2 || show3 ? "circle bg-green" : "circle"}>
                2
              </div>
              <IonText>Players</IonText>
            </IonCol>
            <IonCol size="4">
              <div className={show3 ? "circle bg-green" : "circle"}>3</div>
              <IonText>Type</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid>
          {show1 ? (
            <>
              <IonRow className="ion-justify-content-center mt-25 mb-10 ion-text-center">
                {/* <IonText className="wg-600 black s-18 myclub-header">Create Event</IonText> */}
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10 mb-10">
                <IonItem
                  lines="none"
                  className="select-pack-dropdown"
                  style={{ height: "40px" }}
                >
                  <IonInput
                    style={{ paddingTop: "5px" }}
                    value={pack_name}
                    className="s-14 custom-input"
                    placeholder="Pack name"
                    onIonChange={(e) => setPackName(e.detail.value!)}
                    // clearInput
                  ></IonInput>
                </IonItem>
              </IonRow>
              {/*<IonRow className="ion-justify-content-center mt-10 mb-10">
                <IonItem
                  lines="none"
                  className="select-pack-dropdown"
                  style={{ height: "40px" }}
                >
                  <IonInput
                    style={{ paddingTop: "5px" }}
                    value={city}
                    className="s-14 custom-input"
                    placeholder="Pack city"
                    disabled={true}
                    // clearInput
                  ></IonInput>
                </IonItem>
              </IonRow>*/}
              <IonRow className="ion-justify-content-center mt-10">
                <button
                  className="button-close bg-blue white tt-upper wg-bold"
                  onClick={() => {
                    setShow2(true);
                    setShow1(false);
                    setShow3(false);
                  }}
                >
                  Next
                </button>
              </IonRow>
            </>
          ) : show2 ? (
            <>
              <IonRow className="ion-justify-content-center mt-25 mb-10 ion-text-center">
                {/* <IonText className="wg-600 black s-18 myclub-header">Create Event2</IonText> */}
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10 select-modal">
                <IonInput
                  value={select_state}
                  placeholder="Select player city"
                  className="s-14 select-pack-dropdown pl-20"
                  mode="ios"
                  onClick={() => {
                    setShowStateModal(true);
                    setCities(states);
                  }}
                ></IonInput>
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10 mb-10">
                <IonCol size="9">
                  <IonInput
                    value={selected_player}
                    placeholder="Search players"
                    className="s-14 select-pack-dropdown pl-20"
                    mode="ios"
                    onClick={() => {
                      setShowPlayersModal(true);
                    }}
                  ></IonInput>
                </IonCol>
                <IonCol size="3">
                  {
                    (selected_player.length > 0)?(
                      <button
                        className="add-button bg white tt-upper wg-bold"
                        onClick={() => {
                          if (selected_player.length > 0) {
                            add_players(selected_player, selected_player_id);
                          }
                        }}
                      >
                        add
                      </button>
                    ):(
                      <button
                        className="add-button bg white tt-upper wg-bold disabled-button"
                      >
                        add
                      </button>
                    )
                  }
                </IonCol>
              </IonRow>
              <IonRow className="pl-15">
                {selected_players_list &&
                  selected_players_list.map((key: any, i: number) => {
                    return (
                      <IonChip key={i}>
                        <IonLabel>{key}</IonLabel>
                        <IonIcon
                          icon={closeCircle}
                          onClick={() => {
                            del_players(key);
                          }}
                        />
                      </IonChip>
                    );
                  })}
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-10">
                <IonCol>
                  <button
                    className="button-close bg-red white tt-upper wg-bold"
                    onClick={() => {
                      setShow2(false);
                      setShow1(true);
                      setShow3(false);
                    }}
                  >
                    back
                  </button>
                </IonCol>
                <IonCol>
                  <button
                    className="button-close bg-blue white tt-upper wg-bold"
                    onClick={() => {
                      setShow2(false);
                      setShow1(false);
                      setShow3(true);
                    }}
                  >
                    Next
                  </button>
                </IonCol>
              </IonRow>
            </>
          ) : show3 ? (
            <>
              <IonRow className="ion-justify-content-center mt-25 mb-10 ion-text-center">
                {/* <IonText className="wg-600 black s-18 myclub-header">Create Event3</IonText> */}
              </IonRow>
              <IonCard
                className="profile-cards mt-10"
                style={{ height: "200px", overflow: "auto" }}
              >
                <IonList mode="ios">
                  <IonRadioGroup
                    value={pack_visibility}
                    onIonChange={(e) => {
                      setPackVisibility(e.detail.value);
                    }}
                  >
                    <IonItem>
                      <IonLabel style={{ color: "#00365f", fontWeight: "800" }}>
                        Public
                        <br />
                        <span className="s-12">
                          (Players can search and join your pack)
                        </span>
                      </IonLabel>
                      <IonRadio slot="end" value="Public" />
                    </IonItem>
                    <IonItem>
                      <IonLabel style={{ color: "#00365f", fontWeight: "800" }}>
                        Private
                        <br />
                        <span className="s-12">
                          (Only you can invite players)
                        </span>
                      </IonLabel>
                      <IonRadio slot="end" value="Private" />
                    </IonItem>
                  </IonRadioGroup>
                </IonList>
              </IonCard>
              <IonRow className="ion-justify-content-center ion-text-center ion-mt-10">
                <IonCol>
                  <button
                    className="button-close bg-red white tt-upper wg-bold"
                    onClick={() => {
                      setShow2(true);
                      setShow1(false);
                      setShow3(false);
                    }}
                  >
                    back
                  </button>
                </IonCol>
                <IonCol>
                  <button
                    className="button-close bg-blue white tt-upper wg-bold"
                    onClick={() => {
                      createPack();
                    }}
                  >
                    finish
                  </button>
                </IonCol>
              </IonRow>
            </>
          ) : (
            <>
              <p>Success</p>
            </>
          )}
        </IonGrid>
        <IonModal
          isOpen={showStateModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowStateModal(false)} mode="md"
        >
          <>
            <IonSearchbar
              onIonChange={(e: any) => {
                if (String(e.detail.value!).length > 0) {
                  let glo = new RegExp(String(e.detail.value!).toLowerCase());
                  let new_city_arr = new Array();
                  states.map((item: any) => {
                    let real_item = item;
                    item = item.state_name.toLowerCase();

                    if (glo.test(item)) {
                      new_city_arr.push(real_item);
                    }
                  });
                  setCities(new_city_arr);
                } else {
                  setCities(states);
                }
              }} mode="ios"
            ></IonSearchbar>

            <IonContent>
              <IonGrid>
                <IonList>
                  {cities &&
                    cities.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              console.log(key.city_name);
                              setSelectState(key.city);
                              let city_players = all_players.filter((x:any)=> x.city == key.city);
                              setPlayersList(city_players);
                              setNewPlayers(city_players);
                              setShowStateModal(false);
                            }}
                          >
                            {key.city_name}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showPlayersModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowPlayersModal(false)} mode="md"
        >
          <>
            <IonSearchbar
              onIonChange={(e: any) => {
                console.log(e.detail.value!);
                if (String(e.detail.value!).length > 0) {
                  let glo = new RegExp(String(e.detail.value!).toLowerCase());
                  let new_players_arr = new Array();
                  players_list.map((item: any) => {
                    console.log("a", item);
                    let real_item = item;
                    item =
                      item.first_name.toLowerCase() +
                      " " +
                      item.last_name.toLowerCase();

                    if (glo.test(item)) {
                      console.log("item", real_item);
                      new_players_arr.push(real_item);
                    }
                  });
                  console.log(new_players_arr);
                  setNewPlayers(new_players_arr);
                } else {
                  setNewPlayers(players_list);
                }
              }} mode="ios"
            ></IonSearchbar>

            <IonContent>
              <IonGrid>
                <IonList>
                  {new_players &&
                    new_players.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              console.log(key);
                              setSelectedPlayer(
                                key.first_name + " " + key.last_name
                              );
                              setSelectedPlayerId(key.user_id)
                              setShowPlayersModal(false);
                            }}
                          >
                            {key.first_name + " " + key.last_name}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonLoading isOpen={showLoading} message={"Kicking off.."} />
        <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button">
            <IonFabButton
            className="main-fab"
            onClick={() => {
                window.location.href = "/locker_room";
            }}
            >
            <IonIcon icon={arrowBackOutline} />
            </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};
export default CreatePack;
