import {
  IonContent,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonLabel,
  IonButtons,
  IonGrid,
  IonFab,
  IonFabButton,
  IonCard,
  IonCol,
  IonItem,
  IonRow,
  IonText,
  IonInput,
  IonList,
  IonModal,
  IonSearchbar,
  useIonActionSheet,
  useIonAlert,
  IonDatetime,
  IonHeader,
} from "@ionic/react";
import "../../css/eclubhouse.css";
import {
  arrowBackOutline,
  calendar,
  chevronBackOutline,
  ellipsisVerticalCircleOutline,
} from "ionicons/icons";
import { isPlatform } from "@ionic/react";
import { Browser } from "@capacitor/browser";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import _ from "lodash";
import firebase from "../../js/firebase.js";

import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { endOfMonth, startOfMonth } from "date-fns/esm";
import { updateEntry } from "../../js/firebase_doc_functions";
import Quill from "../Components/get_quill";

const db = getFirestore(firebase);

const Championships: React.FC = () => {
  const [championships_data, setChampionshipsData] = useState<any>([]);
  const [presentIonSheet, dismissIonsheet] = useIonActionSheet();
  const [presentAlert, dismissAlert] = useIonAlert();
  const [championships_master_data, setChampionshipsMasterData] = useState<any>([]);
  const [current_user, setCurrentUser] = useState<any>([]);
  const [championship_type, setEventType] = useState<any>("All Types");
  const [championship_month, setEventMonth] = useState<any>("2022");
  const [championship_city, setChampionshipCity] = useState<string>("All Cities");
  const [showChampionshipTypeModal, setShowChampionshipTypeModal] = useState<boolean>(false);
  const [showChampionshipMonthModal, setShowChampionshipMonthModal] = useState<boolean>(false);
  const [showChampionshipCityModal, setShowChampionshipCityModal] = useState(false);
  const [showChampionshipRegionModal, setShowChampionshipRegionModal] = useState(false);
  const [regions, setRegions] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [championship_region, setChampionshipRegion] = useState<string>("All Regions");
  const [new_types, setNewTypes] = useState<any>([]);
  const [new_cities, setNewCities] = useState<any>(cities);
  const [new_regions, setNewRegions] = useState<any>(regions);
  const [showEventEditModal, setShowEventEditModal] = useState(false);
  const [event_name, setEventName] = useState<string>("");
  const [rounds, setRounds] = useState<string>("");
  const [selected_date, setSelectedDate] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [event_format, setEventFormat] = useState<string>("");
  const [days_of_week, setDaysOfWeek] = useState<string>("");
  const [age, setAge] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [publish_to, setPublishTo] = useState<string>("");
  const [organized_by, setOrganizedBy] = useState<string>("");
  const [content, setContent] = useState<string>('');
  const [event_id, setEventId] = useState<string>('');
  const [championship_types, setEventTypes] = useState<any>([
    { text: "All", value: "All" },
    { text: "Men's Championships", value: "Men's Championships" },
    { text: "Senior Men's Champs", value: "Senior Men's Champs" },
    { text: "Women's Championships", value: "Women's Championships" },
    { text: "Senior Women's Champs", value: "Senior Women's Champs" },
    { text: "Junior Boys", value: "Junior Boys" },
    { text: "Junior Girls", value: "Junior Girls" },
    { text: "Member Play Days", value: "Member Play Days" },
    { text: "Seminars", value: "Seminars" },
    { text: "USGA Qualifiers", value: "USGA Qualifiers" },
    { text: "One-Day Series", value: "One-Day Series" },
    { text: "Team Events", value: "Team Events" },
  ]);
  const [months, setMonths] = useState<any>([
    { text: "All", value: "All" },
    { text: "January", value: 1 },
    { text: "February", value: 2 },
    { text: "March", value: 3 },
    { text: "April", value: 4 },
    { text: "May", value: 5 },
    { text: "June", value: 6 },
    { text: "July", value: 7 },
    { text: "August", value: 8 },
    { text: "September", value: 9 },
    { text: "October", value: 10 },
    { text: "November", value: 11 },
    { text: "December", value: 12 },
  ]);
  const onQuillChange = (quillData: any) => {
    setContent(quillData);
  };
  const updateEvent = async() => {
    var obj = {
      event_name: event_name,
      rounds: Number(rounds),
      url: url,
      format: event_format,
      days_of_week: days_of_week,
      age: age,
      gender: gender,
      organized_by: organized_by,
      event_date: selected_date,
      event_description: content,
      event_date_timestamp: new Date(selected_date).getTime(),
    }
    await updateEntry("EVENTS", event_id, obj);
    getChampionships();
    setShowEventEditModal(false);
  }
  const getConfig = async () => {
    let typesQuery = query(
      collection(db, "CONFIG"),
      where("type", "==", "EVENT_TYPES")
    );
    const typesQuerySnapshot = await getDocs(typesQuery);
    setEventTypes(typesQuerySnapshot.docs[0].data().types);
    setNewTypes(typesQuerySnapshot.docs[0].data().types);
    
    let citiesQuery = query(
      collection(db, "CONFIG"),
      where("type", "==", "EVENT_CITIES")
    );
    const citiesQuerySnapshot = await getDocs(citiesQuery);
    setCities(citiesQuerySnapshot.docs[0].data().cities);
    setNewCities(citiesQuerySnapshot.docs[0].data().cities);

    let regionsQuery = query(
      collection(db, "REGIONS"),
      where("created_at", ">", 0)
    );
    const regionsQuerySnapshot = await getDocs(regionsQuery);
    await Promise.all(
      regionsQuerySnapshot.docs.map(async (region) => {
        var doc_item = region.data();
        doc_item.id = region.id;
        return doc_item;
      })
    ).then((results) => {
      setRegions(results);
    });
  };

  const getChampionships = async () => {
    const q = query(
      collection(db, "CHAMPIONSHIPS"),
      where("created_at", ">", 0),
      orderBy("created_at", "desc")
    );
    const querySnapshot = await getDocs(q);
    if(querySnapshot.docs && querySnapshot.docs.length > 0) {
        await Promise.all(
        querySnapshot.docs.map(async (event) => {
            var doc_item = event.data();
            doc_item.id = event.id;
            return doc_item;
        })
        ).then((results) => {
        setChampionshipsData(results);
        setChampionshipsMasterData(results);
        });
    }
  };

  const openActionsheet = async (event_id: string) => {
    presentIonSheet({
      buttons: [
        {
          text: "Edit",
          handler: () => {
            setEventId(event_id);
            let event = championships_master_data.filter((x:any)=> x.id == event_id);
            console.log(event[0]);
            setEventName(event[0].event_name);
            setRounds(event[0].rounds);
            setUrl(event[0].url);
            setEventFormat(event[0].format);
            setDaysOfWeek(event[0].days_of_week);
            setAge(event[0].age);
            setGender(event[0].gender);
            setOrganizedBy(event[0].organized_by);
            setSelectedDate(format(new Date(event[0].event_date), "MM/dd/yyyy"));
            setContent(event[0].event_description);
            setShowEventEditModal(true);
          },
        },
        {
          text: "Delete",
          handler: async () => {
            presentAlert({
              header: 'Delete event?',
              message: 'Are you sure to delete this event?',
              buttons: [
                'Cancel',
                { text: 'Yes', handler: async (d) => {
                  await deleteDoc(doc(db, "EVENTS", event_id));
                  getChampionships();
                  alert("Post deleted successfully!");
                }},
              ]
            })
          },
        },
        {
          text: "Close",
          role: "cancel",
        },
      ],
    });
  };
  const filterChampionships = async (championship_type: string, championship_month: any, championship_region:string, championship_city:string) => { 
    let date =
    new Date().getFullYear() +
    " " +
    championship_month +
    " " +
    new Date().getDate();
    let startOf = startOfMonth(new Date(date));
    let endOf = endOfMonth(new Date(date));
    let results = new Array();
      if(championship_type == "All Types" && championship_month == "2022" && championship_region == "All Regions" && championship_city == "All Cities") {
        results = championships_master_data;
      }
      if(championship_type != "All Types") {
        let filteredChampionships = championships_master_data.filter(
            (x: any) => x.event_type.toLowerCase() == championship_type.toLowerCase() &&
            (championship_month != "2022" ? (x.event_date_timestamp >= new Date(startOf).getTime() &&
            x.event_date_timestamp <= new Date(endOf).getTime()) : x.event_date_timestamp > 0) &&
            (championship_region == "All Regions" ? x.region_name.length > 0 : x.region_name.toLowerCase() == championship_region.toLowerCase()) &&
            (championship_city == "All Cities" ? x.city.length > 0 : x.city.toLowerCase() == championship_city.toLowerCase())
        );
        results = results.concat(filteredChampionships);
      }
      if(championship_month != "2022") {
        let filteredChampionships = championships_master_data.filter(
            (x: any) =>
            (championship_type == "All Types" ? x.event_type.length > 0 : x.event_type.toLowerCase() == championship_type.toLowerCase()) &&
            x.event_date_timestamp >= new Date(startOf).getTime() &&
            x.event_date_timestamp <= new Date(endOf).getTime() &&
            (championship_region == "All Regions" ? x.region_name.length > 0 : x.region_name.toLowerCase() == championship_region.toLowerCase()) &&
            (championship_city == "All Cities" ? x.city.length > 0 : x.city.toLowerCase() == championship_city.toLowerCase())
        );
        results = results.concat(filteredChampionships);
      }
      if(championship_region != "All Regions") {
        let filteredChampionships = championships_master_data.filter(
            (x: any) => (championship_type == "All Types" ? x.event_type.length > 0 : x.event_type.toLowerCase() == championship_type.toLowerCase()) &&
            (championship_month != "2022" ? (x.event_date_timestamp >= new Date(startOf).getTime() &&
            x.event_date_timestamp <= new Date(endOf).getTime()) : x.event_date_timestamp > 0) &&
            x.region_name.toLowerCase() == championship_region.toLowerCase() &&
            (championship_city == "All Cities" ? x.city.length > 0 : x.city.toLowerCase() == championship_city.toLowerCase())
          );
          results = results.concat(filteredChampionships);
      }
      if(championship_city != "All Cities") {
        let filteredChampionships = championships_master_data.filter(
            (x: any) => (championship_type == "All Types" ? x.event_type.length > 0 : x.event_type.toLowerCase() == championship_type.toLowerCase()) &&
            (championship_month != "2022" ? (x.event_date_timestamp >= new Date(startOf).getTime() &&
            x.event_date_timestamp <= new Date(endOf).getTime()) : x.event_date_timestamp > 0) &&
            (championship_region == "All Regions" ? x.region_name.length > 0 : x.region_name.toLowerCase() == championship_region.toLowerCase()) &&
            x.city.toLowerCase() == championship_city.toLowerCase()
          );
          results = results.concat(filteredChampionships);
      }
      let uniqueChampionships = _.uniqBy(results, 'id');
      setChampionshipsData(uniqueChampionships);
  };
  const toggleChampionshipCard = (header:any)=>{
    championships_data && championships_data.forEach((key:any,i:number)=>{
      if(key.id == header){
        key.toggle = key.toggle == true ? false : true;
      } else {
        key.toggle = false
      }
    })
    // console.log(header,cards)
    setChampionshipsData(Array.from(new Set([...championships_data])))
  }
  const [showDatePicker, setShowDatePicker] = useState(false);
  useEffect(() => {
    let user = localStorage.getItem("firebase_user");
    if (user != null) {
      setCurrentUser(JSON.parse(user));
      getConfig();
      getChampionships();
    }
  }, []);
  return (
    <IonPage>
      <IonToolbar
        mode="ios"
        className={
          isPlatform("ios")
            ? "ios-toolbar-top box-shadow"
            : "toolbar-top box-shadow"
        }
      >
        <IonButtons slot="start">
          <a onClick={() => (window.location.href = "/admin_home")}>
            <IonIcon className="well-back-icon" icon={chevronBackOutline} />
          </a>
        </IonButtons>
        <IonTitle>
          <IonLabel>
            <IonText>Events</IonText>
          </IonLabel>
        </IonTitle>
        <IonButtons slot="end"></IonButtons>
      </IonToolbar>
      <IonContent>
        <IonRow className="ion-justify-content-center mt-10">
          <IonCol size="3">
            <IonInput
              value={championship_type}
              placeholder="Select type"
              className="s-14 select-dropdown pl-20"
              mode="ios"
              onClick={() => {
                setShowChampionshipTypeModal(true);
                setEventType(championship_type);
              }}
            ></IonInput>
          </IonCol>
          <IonCol size="3">
            <IonInput
              value={championship_month}
              placeholder="Select month"
              className="s-14 select-dropdown pl-20"
              mode="ios"
              onClick={() => {
                setShowChampionshipMonthModal(true);
                setEventMonth(championship_month);
              }}
            ></IonInput>
          </IonCol>
          <IonCol size="3">
            <IonInput
              value={championship_region}
              placeholder="Select region"
              className="s-14 select-dropdown pl-20"
              mode="ios"
              onClick={() => {
                setShowChampionshipRegionModal(true);
                setChampionshipRegion(championship_region);
              }}
            ></IonInput>
          </IonCol>
          <IonCol size="3">
            <IonInput
              value={championship_city}
              placeholder="Select city"
              className="s-14 select-dropdown pl-20"
              mode="ios"
              onClick={() => {
                setShowChampionshipCityModal(true);
                setChampionshipCity(championship_city);
              }}
            ></IonInput>
          </IonCol>
        </IonRow>
        <IonContent style={{ overflowY: "auto" }}>
          {championships_data &&
            championships_data.map((item: any, index: number) => {
              return (
                <IonCard key={index} className="pro-shop-cards">
                  <IonRow className="ion-text-start">
                    <IonCol size="10" className="pad-5">
                      <IonText className="s-16 wg-600 color-blue">{item.event_name}</IonText><br/>
                      <IonText className="s-12 wg-600 color-gray">{format(item.event_date_timestamp, "MM/dd/yyyy")}</IonText>
                    </IonCol>
                    <IonCol size="2" className="events-card-options">
                      <IonRow
                        onClick={() =>
                          openActionsheet(item.id)
                        }
                      >
                        <IonIcon
                          icon={ellipsisVerticalCircleOutline}
                          className="edit-trash"
                        />
                      </IonRow>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-text-start">
                    <IonCol size="12" className="pad-5">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.event_description,
                        }}
                        className="mb-10 color-dark-gray"
                      ></div>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-text-start mt-10">
                    <IonCol size="12">
                      <a className="blue-anchor-button" style={{textDecoration: 'none', cursor: 'pointer'}} onClick={()=> {
                        Browser.open({ url: item.url });
                      }}>
                        <IonText>
                         Details
                        </IonText>
                      </a>
                    </IonCol>
                  </IonRow>
                </IonCard>
              );
            })}
        </IonContent>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton
            className="main-fab"
            onClick={() => {
              window.location.href = "/create_event";
            }}
          >
            <IonIcon icon={calendar} />
          </IonFabButton>
        </IonFab>
        <IonModal
          isOpen={showChampionshipTypeModal}
          className="my-custom-class2"
          initialBreakpoint={0.5}
          showBackdrop={true}
          backdropDismiss={true}
          onDidDismiss={() => setShowChampionshipTypeModal(false)}
          mode="md"
        >
          <>
            <IonContent>
              <IonGrid>
                <IonList>
                  {championship_types &&
                    championship_types.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              setEventType(key);
                              filterChampionships(key, championship_month, championship_region, championship_city);
                              setShowChampionshipTypeModal(false);
                            }}
                          >
                            {key}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showChampionshipMonthModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowChampionshipMonthModal(false)}
          mode="md"
        >
          <>
            <IonContent>
              <IonGrid>
                <IonList>
                  {months &&
                    months.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              setEventMonth(key.text);
                              filterChampionships(championship_type, key.value, championship_region, championship_city);
                              setShowChampionshipMonthModal(false);
                            }}
                          >
                            {key.text}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showChampionshipCityModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowChampionshipCityModal(false)}
          mode="md"
        >
          <>
            <IonSearchbar
              onIonChange={(e: any) => {
                if (String(e.detail.value!).length > 0) {
                  let glo = new RegExp(String(e.detail.value!).toLowerCase());
                  let new_city_arr = new Array();
                  cities.map((item: any) => {
                    let real_item = item;
                    item = item.state_name.toLowerCase();

                    if (glo.test(item)) {
                      new_city_arr.push(real_item);
                    }
                  });
                  setNewCities(new_city_arr);
                } else {
                  setNewCities(cities);
                }
              }}
              mode="ios"
            ></IonSearchbar>
            <IonContent>
              <IonGrid>
                <IonList>
                  {cities &&
                    cities.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              setChampionshipCity(key);
                              filterChampionships(championship_type, championship_month, championship_region, key);
                              setShowChampionshipCityModal(false);
                            }}
                          >
                            {key}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showChampionshipRegionModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowChampionshipRegionModal(false)}
          mode="md"
        >
          <>
            <IonSearchbar
              onIonChange={(e: any) => {
                if (String(e.detail.value!).length > 0) {
                  let glo = new RegExp(String(e.detail.value!).toLowerCase());
                  let new_city_arr = new Array();
                  regions.map((item: any) => {
                    let real_item = item;
                    item = item.state_name.toLowerCase();
                    if (glo.test(item)) {
                      new_city_arr.push(real_item);
                    }
                  });
                  setNewRegions(new_city_arr);
                } else {
                  setNewRegions(regions);
                }
              }}
              mode="ios"
            ></IonSearchbar>
            <IonContent>
              <IonGrid>
                <IonList>
                <IonItem>
                    <IonLabel
                      onClick={() => {
                        setChampionshipRegion("All Regions");
                        filterChampionships(championship_type, championship_month, "All Regions", championship_city);
                        setShowChampionshipRegionModal(false);
                      }}
                    >
                      All Regions
                    </IonLabel>
                  </IonItem>
                  {regions &&
                    regions.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              setChampionshipRegion(key.region_name);
                              filterChampionships(championship_type, championship_month, key.region_name, championship_city);
                              setShowChampionshipRegionModal(false);
                            }}
                          >
                            {key.region_name}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showEventEditModal}
          showBackdrop={true}
          backdropDismiss={true}
          onDidDismiss={() => setShowEventEditModal(false)}
          mode="md"
        >
          <IonHeader mode="ios">
              <IonToolbar>
                <IonButtons slot="start">
                  {/* <a onClick={()=> setShowModal(false)}>
                  <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                </a> */}
                </IonButtons>
                <IonTitle>Edit Event</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
        <IonGrid>
          <>
            <IonRow className="ion-justify-content-center mt-10 select-modal">
              <IonInput
                value={event_name}
                placeholder="Event name"
                className="s-14 select-pack-dropdown pl-20"
                mode="ios"
                onIonChange={(e) => {
                  setEventName(e.detail.value!);
                }}
              >
              </IonInput>
            </IonRow>
            <IonRow
              className="ion-justify-content-center mt-10 select-modal"
              style={{ padding: "0px 10px 0px 10px" }}
            >
              <IonCol className="pad-0">
                <IonInput
                  value={event_format}
                  placeholder="Event format"
                  className="s-14 select-pack-dropdown pl-20"
                  mode="ios"
                  onIonChange={(e) => {
                    setEventFormat(e.detail.value!);
                  }}
                >
                </IonInput>
              </IonCol>
              <IonCol className="pad-0">
                <IonInput
                  value={rounds}
                  placeholder="Rounds"
                  className="s-14 select-pack-dropdown pl-20"
                  mode="ios"
                  onIonChange={(e) => {
                    setRounds(e.detail.value!);
                  }}
                >
                </IonInput>
              </IonCol>
            </IonRow>
            <IonRow
              className="ion-justify-content-center mt-10 select-modal"
              style={{ padding: "0px 10px 0px 10px" }}
            >
              <IonCol className="pad-0">
                <IonInput value={selected_date}            
                  placeholder="Select date" className="s-14 select-pack-dropdown pl-20" 
                  mode="ios" 
                  onClick={()=> {
                    setShowDatePicker(true);
                  }}>
                </IonInput>
                {
                    (showDatePicker) ? (
                        <IonDatetime
                            presentation="date"
                            size='cover'
                            onIonChange={(e) => {
                                setSelectedDate(format(new Date(e.detail.value!), "MM/dd/yyyy"));
                                setShowDatePicker(false);
                            }}
                        ></IonDatetime>
                    ) : (
                        <></>
                    )
                }
              </IonCol>
              <IonCol className="pad-0">
                <IonInput
                  value={days_of_week}
                  placeholder="Days of week"
                  className="s-14 select-pack-dropdown pl-20"
                  mode="ios"
                  onIonChange={(e) => {
                    setDaysOfWeek(e.detail.value!);
                  }}
                >
                </IonInput>
              </IonCol>
            </IonRow>
            <IonRow
              className="ion-justify-content-center mt-10 select-modal"
            >
                <IonInput
                  value={age}
                  placeholder="Age/Gender"
                  className="s-14 select-pack-dropdown pl-20"
                  mode="ios"
                  onIonChange={(e) => {
                    setAge(e.detail.value!);
                  }}
                >
                </IonInput>
            </IonRow>
            <IonRow
              className="ion-justify-content-center mt-10 select-modal"
            >
              <IonInput
                value={organized_by}
                placeholder="Organized by"
                className="s-14 select-pack-dropdown pl-20"
                mode="ios"
                onIonChange={(e) => {
                  setOrganizedBy(e.detail.value!);
                }}
              >
              </IonInput>
            </IonRow> 
            <IonRow
              className="ion-justify-content-center mt-10 mb-10 select-modal"
            >
              <IonInput
                value={url}
                placeholder="Add URL"
                className="s-14 select-pack-dropdown pl-20"
                mode="ios"
                onIonChange={(e) => {
                  setUrl(e.detail.value!);
                }}
              >
              </IonInput>
            </IonRow>
            <Quill onQuill={onQuillChange} content={content}/>
          </>
          <IonRow className="ion-justify-content-center ion-text-center mt-25">
            <button className="button-send white s-15 bg" onClick={updateEvent}>
              <IonText className="wg-bold">Update</IonText>
            </button>
          </IonRow>
        </IonGrid>
        <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '45px'}}>
          <IonFabButton
          className="main-fab"
          onClick={() => {
            setShowEventEditModal(false);
          }}
          >
          <IonIcon icon={arrowBackOutline} />
          </IonFabButton>
        </IonFab>
      </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Championships;
