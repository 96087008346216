import main_logo from "../../img/eclub.golf.png";
import default_profile_logo from "../../img/fewbats.svg";
import default_user_logo from "../../img/default.png";
import default_eclub_logo from "../../img/web_hi_res_512.png";
import Comments from "../Components/comments";
import { Checkmark, CheckmarkDone, TrashBin } from "@styled-icons/ionicons-outline";
import Likes from "../Components/like";
import {
  IonInput,
  IonMenuToggle,
  IonSelect,
  IonSelectOption,
  isPlatform,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import {
  IonModal,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonButtons,
  IonMenuButton,
  IonSegment,
  IonSegmentButton,
  IonGrid,
  IonFab,
  IonFabButton,
  IonFabList,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonAvatar,
  IonItem,
  IonRow,
  IonCol,
  IonText,
  useIonLoading,
  IonSkeletonText,
  useIonActionSheet,
} from "@ionic/react";
import "../../css/locker_room.css";
import {
  chevronBackOutline,
  add,
  duplicateOutline,
  peopleOutline,
  ellipsisVerticalCircleOutline,
  informationCircleOutline,
  paperPlane,
  notificationsOutline,
  arrowBackOutline,
  closeOutline,
} from "ionicons/icons";
import {
  Chatbubbles,
} from '@styled-icons/ionicons-solid';
import {
  Golf,
  Calendar,
  Gift,
  Grid,
} from '@styled-icons/ionicons-outline';
import { ChatLeftText } from "@styled-icons/bootstrap";
import { Heart } from "@styled-icons/bootstrap";
import { HeartFill } from "@styled-icons/bootstrap";
import { CalendarEdit } from "@styled-icons/fluentui-system-regular";
import {
  useEffect,
  useState,
} from "react";
import {
  Cancel,
} from "@styled-icons/material-rounded";
import _ from "lodash";
import { format } from "date-fns";
import firebase from "../../js/firebase.js";
import Quill from "../Components/get_quill";
import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  orderBy,
  getDocs,
  deleteDoc,
  limit,
} from "firebase/firestore";
import {
  createEntryWithoutId, deleteEntry, updateEntry,
} from "../../js/firebase_doc_functions";
import { Browser } from "@capacitor/browser";
import * as utils from '../../js/utils';
import BottomBar from "../Components/bottom_toolbar";


const db = getFirestore(firebase);

const LockerRoom: React.FC = () => {
  const [presentIonSheet, dismissIonsheet] = useIonActionSheet();
  const [current_user, setCurrentUser] = useState<any>();
  const [connect_tab_value, setConnectTabValue] = useState<any>("Community");
  const [user_image, setUserImage] = useState<any>("");
  const [all_users, setAllUsers] = useState<any>();
  const [pack_feed, setPackFeed] = useState<any>([]);
  const [community_feed, setCommunityFeed] = useState<any>([]);
  const [club_feed, setClubFeed] = useState<any>([]);
  const [comments, setComments] = useState<any>([]);
  const [likes, setLikes] = useState<any>([]);
  const [current_feed_id, setCurrentFeedId] = useState<any>("");
  const [present, dismiss] = useIonLoading();
  const [notifications, setNotifications] = useState<boolean>(false);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [show_skeleton_effect, setShowSkeletonEffect] = useState<Boolean>(true);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [key_date, setKeyDate] = useState<number>();
  const [content, setContent] = useState<any>("");
  const [showJoinPackModal, setShowJoinPackModal] = useState<boolean>(false)

  const [selected_pack_id, setSelectedPackId] = useState("");
  const [url, setUrl] = useState<string>("");
  const [selected_pack, setSelectedPack] = useState<any>([]);
  const [packs_list, setPacksList] = useState<any>([]);
  const [presentToast, dismissToast] = useIonToast();
  const [pack_city, setPackCity] = useState<string>('');
  const [pack_message, setPackMessage] = useState<string>();
  const [presentAlert, dismissAlert] = useIonAlert();
  const [espn_feed, setEspnFeed] = useState<any>([]);
  const [current_app_version, setCurrentAppVersion] = useState<string>();
  const [deployed_app_version, setDeployedAppVersion] = useState<string>();
  const customAlertOptions = {
    header: "Select Pack",
  };

  const customAlertOptions1 = {
    header: "Select city",
  };
  const getAppDetails = async () => {
    // let app_info = "2.0.30";
    // const q = query(collection(db, "CONFIG"), where("type", "==", "APP_VERSION"));
    // const querySnapshot = await getDocs(q);
    // if(querySnapshot.docs && querySnapshot.docs.length > 0) {
    //   console.log("app_info", app_info);
    //   console.log("device_info", querySnapshot.docs[0].data().value);
    //   let current_deployed_version = querySnapshot.docs[0].data().value;
    //   if(app_info !== current_deployed_version) {
    //     setCurrentAppVersion(app_info);
    //     setDeployedAppVersion(current_deployed_version);
    //     showAlert();
    //   }
    // }
  }
  const showAlert = () => {
    presentAlert({
      cssClass: 'my-css',
      header: 'Update your app',
      message: 'You are using an older version; please upgrade to v2.0.30',
      buttons: [
        'Cancel',
        { text: 'Ok', handler: (d) => console.log('ok pressed') },
      ],
      onDidDismiss: (e) => console.log('did dismiss'),
    })
  }
  const getNotificationCounts = async (user_id: string) => {
    const q = query(collection(db, "CHECKIN_NOTIFICATIONS"), where('created_at', '>', 0), where('user_id', '==', user_id));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
      querySnapshot.docs.forEach((notifications) => {
        const notification = notifications.data();
        if (!notification.match_status && !(notification.match_status === 'Declined' || notification.match_status === 'Accepted')) {
          setNotifications(true);
        }
      });
    }
  }
  const getUsers = async () => {
    var condition = where("created_at", ">", 0);
    const q = query(collection(db, "USER_PROFILE"), condition);
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
      await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          var doc_item = doc.data();
          doc_item.id = doc.id;
          return doc_item;
        })
      ).then(results => {
        setAllUsers(results);
      })
    }
  };
  const [showLoading, setShowLoading] = useState(false);

  const openActionsheet = async (feed_id: string, tab_value: string) => {
    presentIonSheet({
      buttons: [
        {
          text: "Edit",
          handler: () => { },
        },
        {
          text: "Delete",
          handler: async () => {
            presentAlert({
              header: 'Delete post?',
              message: 'Are you sure to delete this post?',
              buttons: [
                'Cancel',
                {
                  text: 'Yes', handler: async (d) => {
                    await deleteDoc(doc(db, "NEWS_FEED", feed_id));
                    getFeed(tab_value, 1, current_user);
                    alert("Post deleted successfully!");
                  }
                },
              ]
            })
          },
        },
        {
          text: "Close",
          role: "cancel",
        },
      ],
    });
  };

  const onQuillChange = (quillData: any) => {
    setContent(quillData);
  };

  const allPacksList = async (user_packs: any) => {
    const q = query(
      collection(db, "PACKS"),
      where("pack_id", "!=", "kuFOI2OPKCuhCwrewzBqDI8w71IE")
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs && querySnapshot.docs.length > 0) {
      await Promise.all(
        querySnapshot.docs.map(async (pack) => {
          var doc_item = pack.data();
          doc_item.id = pack.id;
          return doc_item;
        })
      ).then((results) => {
        let new_packs = _.differenceBy(results, user_packs, "pack_id");
        console.log("curr", new_packs);
        setPacksList(new_packs);
      });
    } else {
      console.log("No packs available");
    }
  };
  const [filtered_packs, setFilteredPacks] = useState<any>([]);
  const filterPacks = async (city: string) => {
    if (city === "Any") {
      setFilteredPacks(packs_list);
    } else {
      let filtered_packs = packs_list.filter((x: any) => x.city === city);
      setFilteredPacks(filtered_packs);
    }
  }
  const setPack = async (pack_id: string) => {
    let filtered_pack = packs_list.filter((x: any) => x.pack_id == pack_id);
    if (filtered_pack.length > 0) {
      setSelectedPack(filtered_pack[0]);
    }
  };

  const sendJoinRequest = async () => {
    if (selected_pack && selected_pack.pack_name != null) {
      var obj = {
        created_name: current_user.first_name + " " + current_user.last_name,
        created_by: current_user.user_id,
        type: "JOIN_REQUEST",
        description: pack_message,
        source: {
          user_name: current_user.first_name + " " + current_user.last_name,
          user_id: current_user.user_id,
        },
        is_read: false,
        pack_id: selected_pack.pack_id,
        pack_name: selected_pack.pack_name,
        user_id: selected_pack.pack_lead_id,
      };
      await createEntryWithoutId("PACK_NOTIFICATIONS", obj);
      presentToast("Request sent", 1800);
    }
  };


  const getESPNFeed = async () => {
    let feed_data = await utils.getEspnNewsFeed() || [];

    let posts = new Array();
    if (feed_data != undefined) {
      feed_data.map((post: any) => {
        let obj = {
          "user_image": "https://a.espncdn.com/i/espn/teamlogos/lrg/trans/espn_dotcom_black.gif",
          "user_name": 'ESPN',
          "created_at": post.pubDate,
          "post_text": post.description,
          "url": post.link[0].toString(),
          "post_image": post.enclosure[0].$.url.toString(),
          "timestamp": new Date(post.pubDate).getTime()
        }
        posts.push(obj);
        return post;
      });
      setEspnFeed(_.sortBy(posts, (e) => e.timestamp).reverse());
    }
  }

  const getFeed = async (tab_value: any, start_at: 1, user: any) => {
    if (tab_value == "My Packs" && user.packs.length > 0) {
      console.log("pack length > 0")
      setShowSkeletonEffect(true);
      let userPacks = user.packs;
      let pack_ids = [
        ...userPacks.map((x: any) => {
          return x.pack_id;
        }),
      ];
      const q = query(
        collection(db, "NEWS_FEED"),
        where("created_at", ">", 0),
        where("visibility", "==", "Pack"),
        where("pack_id", "in", pack_ids),
        orderBy("created_at", "desc"),
        limit(10)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs && querySnapshot.docs.length > 0) {
        await Promise.all(
          querySnapshot.docs.map(async (documentSnapshot) => {
            var doc_item = documentSnapshot.data();
            doc_item.id = documentSnapshot.id;
            doc_item.user_image =
              all_users != undefined
                ? all_users.filter(
                  (rec: any) => rec.user_id == doc_item.user_id
                )[0].logo_url
                : "";
            if (user.user_id == doc_item.user_id) {
              doc_item.own_content = true;
            } else {
              doc_item.own_content = false;
            }
            const likesQuery = query(
              collection(db, "NEWS_FEED_LIKES"),
              where("feed_id", "==", doc_item.feed_id)
            );
            const likesQuerySnapshot = await getDocs(likesQuery);
            doc_item.likes = likesQuerySnapshot.docs.length;
            doc_item.liked =
              likesQuerySnapshot.docs.filter(
                (doc) => user.user_id == doc.data().user_id
              ).length > 0
                ? true
                : false;
            const commentsQuery = query(
              collection(db, "NEWS_FEED_COMMENTS"),
              where("feed_id", "==", doc_item.feed_id)
            );
            const querySnapshot = await getDocs(commentsQuery);
            doc_item.comments = querySnapshot.docs.length;
            doc_item.commented =
              querySnapshot.docs.filter(
                (doc) => user.user_id == doc.data().user_id
              ).length > 0
                ? true
                : false;
            return doc_item;
          })
        ).then((results) => {
          setPackFeed(_.sortBy(results, (e: any) => e.created_at).reverse());
          setShowSkeletonEffect(false);
        });
      } else {
        setPackFeed([]);
        setShowSkeletonEffect(false);
        //  setShowJoinPackModal(true)
      }
    }
    else if (tab_value == "Community") {
      setShowSkeletonEffect(true);
      const q = query(
        collection(db, "NEWS_FEED"),
        where("created_at", ">", 0),
        where("visibility", "==", "Community"),
        orderBy("created_at", "desc"),
        limit(10)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs && querySnapshot.docs.length > 0) {
        await Promise.all(
          querySnapshot.docs.map(async (documentSnapshot) => {
            var doc_item = documentSnapshot.data();
            doc_item.id = documentSnapshot.id;
            doc_item.user_image = all_users != undefined ? all_users.filter((rec: any) => rec.user_id == doc_item.user_id)[0].logo_url : "";
            if ((doc_item.user_image == "" ||
              doc_item.user_image == undefined || doc_item.user_image == null)) {
              doc_item.user_image = default_user_logo;
            }
            if (user.user_id == doc_item.user_id) {
              doc_item.own_content = true;
            } else {
              doc_item.own_content = false;
            }
            const likesQuery = query(
              collection(db, "NEWS_FEED_LIKES"),
              where("feed_id", "==", doc_item.feed_id)
            );
            const likesQuerySnapshot = await getDocs(likesQuery);
            doc_item.likes = likesQuerySnapshot.docs.length;
            doc_item.liked =
              likesQuerySnapshot.docs.filter(
                (doc) => user.user_id == doc.data().user_id
              ).length > 0
                ? true
                : false;
            const commentsQuery = query(
              collection(db, "NEWS_FEED_COMMENTS"),
              where("feed_id", "==", doc_item.feed_id)
            );
            const querySnapshot = await getDocs(commentsQuery);
            doc_item.comments = querySnapshot.docs.length;
            doc_item.commented =
              querySnapshot.docs.filter(
                (doc) => user.user_id == doc.data().user_id
              ).length > 0
                ? true
                : false;
            return doc_item;
          })
        ).then((results) => {
          setCommunityFeed(
            _.sortBy(results, (e: any) => e.created_at).reverse()
          );
          setShowSkeletonEffect(false);
        });
      } else {
        setCommunityFeed([]);
        setShowSkeletonEffect(false);
      }
    }
    else if (tab_value == "My Club") {
      setShowSkeletonEffect(true);
      var records = new Array();
      const q = query(
        collection(db, "NEWS_FEED"),
        where("created_at", ">", 0),
        //  where("region_id", "==", user.region_id),
        where("visibility", "==", "MyClub"),
        orderBy("created_at", "desc"),
        limit(10)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs && querySnapshot.docs.length > 0) {
        await Promise.all(
          querySnapshot.docs.map(async (documentSnapshot) => {
            var doc_item = documentSnapshot.data();
            if (doc_item.visibility == "MyClub") {
              doc_item.id = documentSnapshot.id;
              doc_item.user_image =
                all_users != undefined
                  ? all_users.filter(
                    (rec: any) => rec.user_id == doc_item.user_id
                  )[0].logo_url
                  : "";
              if (user.user_id == doc_item.user_id) {
                doc_item.own_content = true;
              } else {
                doc_item.own_content = false;
              }
              const likesQuery = query(
                collection(db, "NEWS_FEED_LIKES"),
                where("feed_id", "==", doc_item.feed_id)
              );
              const likesQuerySnapshot = await getDocs(likesQuery);
              doc_item.likes = likesQuerySnapshot.docs.length;
              doc_item.liked =
                likesQuerySnapshot.docs.filter(
                  (doc) => user.user_id == doc.data().user_id
                ).length > 0
                  ? true
                  : false;
              const commentsQuery = query(
                collection(db, "NEWS_FEED_COMMENTS"),
                where("feed_id", "==", doc_item.feed_id)
              );
              const querySnapshot = await getDocs(commentsQuery);
              doc_item.comments = querySnapshot.docs.length;
              doc_item.commented =
                querySnapshot.docs.filter(
                  (doc) => user.user_id == doc.data().user_id
                ).length > 0
                  ? true
                  : false;
              return doc_item;
            } else {
              return;
            }
          })
        ).then((results) => {
          results = results.filter((x) => x);
          setClubFeed(_.sortBy(results, (e: any) => e.created_at).reverse());
          setShowSkeletonEffect(false);
        });
      } else {
        setClubFeed([]);
        setShowSkeletonEffect(false);
      }
    }
    if (tab_value == "Tee Times") {
      //     getTeeTimes();
    }
    else {
      return;
    }
  };

  const loadLikesData = async (feed_id: string) => {
    const q = query(
      collection(db, "NEWS_FEED_LIKES"),
      where("feed_id", "==", feed_id)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot && querySnapshot.docs.length > 0) {
      let likes = querySnapshot.docs.map(function (documentSnapshot) {
        var doc_item = documentSnapshot.data();
        return doc_item;
      });
      likes = _.sortBy(likes, (e) => e.created_at).reverse();
      setLikes(likes);
      setShowModal1(true);
    } else {
      setLikes([]);
    }
  };

  const makeComment = async () => {
    var comment = {
      user_id: current_user.user_id,
      user_name: current_user.first_name + " " + current_user.last_name,
      user_image:
        current_user.logo_url != undefined ? current_user.logo_url : null,
      city: current_user.city,
      comment: content,
      feed_id: current_feed_id,
      created_at: new Date().getTime(),
      updated_at: new Date().getTime(),
      url: url
    };
    await createEntryWithoutId("NEWS_FEED_COMMENTS", comment);
    let newComments = comments;
    newComments.push(comment);
    setComments(_.sortBy(comments, (e: any) => e.created_at).reverse());
    setKeyDate(new Date().getTime());
  };

  const loadCommentsData = async (feed_id: string, type: string) => {
    const q = query(
      collection(db, "NEWS_FEED_COMMENTS"),
      where("feed_id", "==", feed_id)
    );
    const querySnapshot = await getDocs(q);
    if (type == "My Packs") {
      var feedData = pack_feed.filter((x: any) => x.feed_id == feed_id);
    } else if ((type = "Community")) {
      var feedData = community_feed.filter((x: any) => x.feed_id == feed_id);
    } else {
      var feedData = club_feed.filter((x: any) => x.feed_id == feed_id);
    }
    console.log(feedData);
    if (querySnapshot && querySnapshot.docs.length > 0) {
      let comments = querySnapshot.docs.map(function (documentSnapshot) {
        var doc_item = documentSnapshot.data();
        doc_item.id = documentSnapshot.id;
        if (doc_item.user_id == current_user.user_id) {
          doc_item.own_comment = true;
        }
        if (feedData[0].user_id == current_user.user_id) {
          doc_item.own_post = true;
        }
        return doc_item;
      });
      comments = _.sortBy(comments, (e) => e.created_at).reverse();
      setComments(comments);
      setCurrentFeedId(feed_id);
      setShowModal(true);
    } else {
      setComments([]);
      setCurrentFeedId(feed_id);
      setShowModal(true);
    }
  };
  const likeFeed = async (feed: any, type: string) => {
    const likesFeedQuery = query(collection(db, 'NEWS_FEED_LIKES'), where('feed_id', '==', feed.feed_id));
    const querySnapshot = await getDocs(likesFeedQuery);
    let liked = querySnapshot.docs.filter(doc => current_user.user_id == doc.data().user_id);
    if (liked.length === 0) {
      createEntryWithoutId('NEWS_FEED_LIKES', {
        'feed_id': feed.feed_id,
        'user_id': current_user.user_id,
        'user_name': current_user.first_name + ' ' + current_user.last_name,
        'user_image': current_user.logo_url != undefined ? current_user.logo_url : null
      });
    }
    feed.liked = true;
    feed.likes += 1;
    if (type === "Community") {
      setCommunityFeed((community_feed: any) => [...community_feed, feed]);
    }
    else {
      setPackFeed((pack_feed: any) => [...pack_feed, feed]);
    }
  }
  const unLikeFeed = async (feed: any, type: string) => {
    let liked: any = [];
    const likesFeedQuery = query(collection(db, 'NEWS_FEED_LIKES'), where('feed_id', '==', feed.feed_id));
    const querySnapshot = await getDocs(likesFeedQuery);
    querySnapshot.docs.filter((doc) => {
      if (current_user.user_id == doc.data().user_id) {
        let doc_item = doc.data();
        doc_item.id = doc.id;
        liked.push(doc_item);
      }
    });
    const result = await deleteEntry("NEWS_FEED_LIKES", liked[0].id);
    feed.liked = false;
    feed.likes -= 1;
    if (type === "Community") {
      setCommunityFeed((community_feed: any) => [...community_feed, feed]);
    }
    else {
      setPackFeed((pack_feed: any) => [...pack_feed, feed]);
    }
  }
  useEffect(() => {
    if (isPlatform('ios') || isPlatform('iphone') || isPlatform('android') || isPlatform('ipad')) {
      getAppDetails();
    }
    let user = localStorage.getItem("firebase_user");
    if (user != null) {
      setCurrentUser(JSON.parse(user));
      setUserImage(JSON.parse(user).logo_url);
      getUsers();
      getFeed("Community", 1, JSON.parse(user));
      getFeed("My Packs", 1, JSON.parse(user));
      getNotificationCounts(JSON.parse(user).user_id);
    }
    if (!document.body.classList.contains('dark') && localStorage.getItem("dark") != null) {
      document.body.classList.toggle("dark");
    }
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [data, setData] = useState<any>("");
  return (
    <IonPage>
      <IonToolbar
        mode="ios"
        className={isPlatform("ios") ? "ios-toolbar-top" : "toolbar-top"}
      >
        {/* <IonButtons slot="start" className="slot-start-padding">
          {user_image ? (
            <a href="/profile" className="pd-r-10">
              <img src={user_image} className="pro_pic" />
            </a>
          ) : (
            <a href="/profile" className="pd-r-10">
              <img src={default_profile_logo} className="pro_pic" />
            </a>
          )}
        </IonButtons> */}
        <IonLabel slot="start" className="s-18 w-800 color-blue" style={{ padding: '0px 0px 0px 10px' }}>{connect_tab_value}</IonLabel>
        {/* <IonTitle>
          <img src={main_logo} className="main_logo" />Catch the latest scoop...
        </IonTitle> */}
        <IonButtons slot="end" className="slot-end-padding" onClick={() => {
          allPacksList(current_user.packs);
          setShowJoinPackModal(true);
        }}>
          <IonIcon icon={peopleOutline} className="s-30 color-blue" />
        </IonButtons>
        <IonButtons slot="end" style={{ padding: '0px 5px 0px 10px' }} onClick={() => window.location.href = "/notifications"}>
          <IonIcon icon={notificationsOutline} className="s-30 color-blue" />
          {
            notifications ? (
              <span className='notification-dot'></span>
            ) : (
              <></>
            )
          }
        </IonButtons>
      </IonToolbar>
      <IonContent>
        <IonGrid>
          {connect_tab_value == "My Packs" ? (
            <>
              {
                pack_feed && pack_feed.length > 0 && !show_skeleton_effect ? (
                  <>
                    {
                      pack_feed.map((feed: any, index: number) => {
                        return (
                          <IonCard className="social-card" key={index}>
                            <IonCardHeader className="card-header">
                              <IonRow className="pd-5">
                                <IonCol className="display-flex">
                                  <div>
                                    {(feed.user_image == "" ||
                                      feed.user_image == undefined || feed.user_image == null) ? (
                                      <IonAvatar
                                        slot="start"
                                        className="card-avatar profile-link"
                                        onClick={() => { window.location.href = "/player_profile/" + feed.user_id }}
                                      >
                                        <img src={default_user_logo} alt={default_user_logo} />
                                      </IonAvatar>
                                    ) : (
                                      <IonAvatar
                                        slot="start"
                                        className="card-avatar profile-link"
                                        onClick={() => { window.location.href = "/player_profile/" + feed.user_id }}
                                      >
                                        <img src={feed.user_image} alt={feed.user_name} />
                                      </IonAvatar>
                                    )}
                                  </div>

                                  <IonLabel className="social-name">
                                    <h4 onClick={() => { window.location.href = "/player_profile/" + feed.user_id }} className="profile-link">{feed.user_name}&nbsp;&nbsp;<span className="s-11 gray">{feed.pack_name} - {feed.city}</span></h4>
                                    <p>
                                      {format(
                                        feed.created_at,
                                        "MMM dd, yyyy HH:mm"
                                      )}
                                    </p>
                                  </IonLabel>

                                  {feed.own_content ? (
                                    <IonRow
                                      onClick={() =>
                                        openActionsheet(feed.id, "My Packs")
                                      }
                                      className="social-options"
                                    >
                                      <IonIcon
                                        icon={ellipsisVerticalCircleOutline}
                                        className="edit-trash"
                                      />
                                    </IonRow>
                                  ) : (
                                    <></>
                                  )}
                                </IonCol>
                              </IonRow>
                            </IonCardHeader>
                            <IonCardContent className="card-content">
                              {feed.post_text}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: feed.post_content,
                                }}
                                className="mb-10"
                              ></div>
                              <IonRow className="mb-5">
                                <a className="pointer" onClick={() => {
                                  Browser.open({ url: feed.url });
                                }}>
                                  <IonText className="s-13 pl-5">
                                    {feed.url}
                                  </IonText>
                                </a>
                              </IonRow>
                              {feed.post_image == "" ||
                                feed.post_image == undefined ? (
                                <></>
                              ) : (
                                <IonRow className="mb-5 ion-justify-content-center ion-text-center">
                                  <img src={feed.post_image} style={{ maxHeight: '50%', maxWidth: '50%', borderRadius: '10px' }} />
                                </IonRow>
                              )}
                            </IonCardContent>
                            <IonRow className="display-flex card-footer">
                              <div>
                                {feed.liked ?
                                  <HeartFill className="card-like-icon pointer" color="red" onClick={() => { unLikeFeed(feed, connect_tab_value) }} />
                                  :
                                  <Heart className="card-like-icon pointer" onClick={() => { likeFeed(feed, connect_tab_value) }} />
                                }
                                <a className="pointer"
                                  onClick={() => {
                                    loadLikesData(feed.feed_id);
                                  }}
                                >
                                  <IonLabel>
                                    <IonText className="card-like-text">
                                      {feed.likes} like(s)
                                    </IonText>
                                  </IonLabel>
                                </a>
                              </div>
                              <a className="pointer"
                                onClick={() => {
                                  loadCommentsData(feed.feed_id, "My Packs");
                                }}
                              >
                                <ChatLeftText className="card-comment-icon" />
                                <IonLabel>
                                  <IonText className="card-comment">
                                    {feed.comments} comment(s)
                                  </IonText>
                                </IonLabel>
                              </a>
                            </IonRow>
                          </IonCard>
                        );
                      })}
                  </>
                ) : (pack_feed &&
                  pack_feed.length == 0 &&
                  !show_skeleton_effect) ? (
                  <div className="center ion-justify-content-center ion-text-center vh-25">
                    <p>
                      You can exchange messages with your packs here.
                    </p>
                  </div>
                ) : (
                  <>
                    {Array(4)
                      .fill(0)
                      .map((key: any, i: number) => {
                        return (
                          <IonCard className="social-card" key={i}>
                            <IonCardHeader>
                              <IonItem>
                                <IonAvatar slot="start">
                                  <IonSkeletonText animated />
                                </IonAvatar>
                                <IonLabel>
                                  <h3>
                                    <IonSkeletonText
                                      animated
                                      style={{ width: "50%" }}
                                    />
                                  </h3>
                                  <p>
                                    <IonSkeletonText
                                      animated
                                      style={{ width: "80%" }}
                                    />
                                  </p>
                                </IonLabel>
                              </IonItem>
                            </IonCardHeader>
                            <IonCardContent>
                              <p>
                                <IonSkeletonText
                                  animated
                                  style={{ width: "50%" }}
                                />
                              </p>
                              <p>
                                <IonSkeletonText
                                  animated
                                  style={{ width: "80%" }}
                                />
                              </p>
                              <p>
                                <IonSkeletonText
                                  animated
                                  style={{ width: "60%" }}
                                />
                              </p>
                            </IonCardContent>
                            <IonRow>
                              <IonCol size="1">
                                {/* <IonIcon icon={heart}/>                           */}
                              </IonCol>
                              <IonCol size="4">
                                <IonSkeletonText
                                  animated
                                  style={{ width: "50%" }}
                                />
                              </IonCol>
                              <IonCol size="2"></IonCol>
                              <IonCol size="1">
                                {/* <IonIcon icon={chatbox}/> */}
                              </IonCol>
                              <IonCol size="4">
                                <IonSkeletonText
                                  animated
                                  style={{ width: "50%" }}
                                />
                              </IonCol>
                            </IonRow>
                          </IonCard>
                        );
                      }
                      )
                    }
                  </>
                )
              }
            </>
          ) : connect_tab_value == "Community" ? (
            <>
              {community_feed &&
                community_feed.length > 0 &&
                !show_skeleton_effect ? (
                <>
                  {community_feed.map((feed: any, index: number) => {
                    return (
                      <IonCard className="social-card" key={index}>
                        <IonCardHeader className="card-header">
                          <IonRow className="pd-5">
                            <IonCol className="display-flex">
                              <div>
                                <IonAvatar
                                  slot="start"
                                  className="card-avatar profile-link"
                                  onClick={() => { window.location.href = "/player_profile/" + feed.user_id }}
                                >
                                  <img src={feed.user_image} alt={feed.user_name} />
                                </IonAvatar>
                              </div>

                              <IonLabel className="social-name">
                                <h4 onClick={() => { window.location.href = "/player_profile/" + feed.user_id }} className="profile-link">{feed.user_name}&nbsp;&nbsp;<span className="s-11 gray">{feed.pack_name} - {feed.city}</span></h4>
                                <p>
                                  {format(
                                    feed.created_at,
                                    "MMM dd, yyyy HH:mm"
                                  )}
                                </p>
                              </IonLabel>

                              {feed.own_content ? (
                                <IonRow
                                  onClick={() =>
                                    openActionsheet(feed.id, "Community")
                                  }
                                  className="social-options"
                                >
                                  <IonIcon
                                    icon={ellipsisVerticalCircleOutline}
                                    className="edit-trash"
                                    size="20"
                                  />
                                </IonRow>
                              ) : (
                                <></>
                              )}
                            </IonCol>
                          </IonRow>
                        </IonCardHeader>
                        <IonCardContent className="card-content">
                          {feed.post_text}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: feed.post_content,
                            }}
                            className="mb-10"
                          ></div>
                          <IonRow className="mb-5">
                            <a className="pointer" onClick={() => {
                              Browser.open({ url: feed.url });
                            }}>
                              <IonText className="s-13 pl-5">
                                {feed.url}
                              </IonText>
                            </a>
                          </IonRow>
                          {feed.post_image == "" ||
                            feed.post_image == undefined ? (
                            <></>
                          ) : (
                            <IonRow className="mb-5 ion-justify-content-center ion-text-center">
                              <img src={feed.post_image} style={{ maxHeight: '50%', maxWidth: '50%', borderRadius: '10px' }} />
                            </IonRow>
                          )}
                        </IonCardContent>
                        <IonRow className="display-flex card-footer">
                          <div>
                            {feed.liked ?
                              <HeartFill className="card-like-icon pointer" color="red" onClick={() => { unLikeFeed(feed, connect_tab_value) }} />
                              :
                              <Heart className="card-like-icon pointer" onClick={() => { likeFeed(feed, connect_tab_value) }} />
                            }
                            <a className="pointer"
                              onClick={() => {
                                loadLikesData(feed.feed_id);
                              }}
                            >
                              <IonLabel>
                                <IonText className="card-like-text">
                                  {feed.likes} like(s)
                                </IonText>
                              </IonLabel>
                            </a>
                          </div>
                          <a className="pointer"
                            onClick={() => {
                              loadCommentsData(feed.feed_id, "Community");
                            }}
                          >
                            <ChatLeftText className="card-comment-icon" />
                            <IonLabel>
                              <IonText className="card-comment">
                                {feed.comments} comment(s)
                              </IonText>
                            </IonLabel>
                          </a>
                        </IonRow>
                      </IonCard>
                    );
                  })}
                </>
              ) : community_feed &&
                community_feed.length == 0 &&
                !show_skeleton_effect ? (
                <div className="center ion-justify-content-center ion-text-center vh-25">
                  <p>No data</p>
                </div>
              ) : (
                <>
                  {Array(4)
                    .fill(0)
                    .map((key: any, i: number) => {
                      return (
                        <IonCard className="social-card" key={i}>
                          <IonCardHeader>
                            <IonItem>
                              <IonAvatar slot="start">
                                <IonSkeletonText animated />
                              </IonAvatar>
                              <IonLabel>
                                <h3>
                                  <IonSkeletonText
                                    animated
                                    style={{ width: "50%" }}
                                  />
                                </h3>
                                <p>
                                  <IonSkeletonText
                                    animated
                                    style={{ width: "80%" }}
                                  />
                                </p>
                              </IonLabel>
                            </IonItem>
                          </IonCardHeader>
                          <IonCardContent>
                            <p>
                              <IonSkeletonText
                                animated
                                style={{ width: "50%" }}
                              />
                            </p>
                            <p>
                              <IonSkeletonText
                                animated
                                style={{ width: "80%" }}
                              />
                            </p>
                            <p>
                              <IonSkeletonText
                                animated
                                style={{ width: "60%" }}
                              />
                            </p>
                          </IonCardContent>
                          <IonRow>
                            <IonCol size="1">
                              {/* <IonIcon icon={heart}/>                           */}
                            </IonCol>
                            <IonCol size="4">
                              <IonSkeletonText
                                animated
                                style={{ width: "50%" }}
                              />
                            </IonCol>
                            <IonCol size="2"></IonCol>
                            <IonCol size="1">
                              {/* <IonIcon icon={chatbox}/> */}
                            </IonCol>
                            <IonCol size="4">
                              <IonSkeletonText
                                animated
                                style={{ width: "50%" }}
                              />
                            </IonCol>
                          </IonRow>
                        </IonCard>
                      );
                    })}
                </>
              )}
            </>
          ) : connect_tab_value == "My Club" ? (
            <>
              {club_feed && club_feed.length > 0 && !show_skeleton_effect ? (
                <>
                  {club_feed.map((feed: any, index: number) => {
                    return (
                      <div key={index}>
                        {/* <IonRow className="ion-text-center ion-justify-content-center">
                          <IonText className="myclub-header">Exclusive for CLG Members</IonText>
                        </IonRow> */}
                        <IonCard className="social-card">
                          <IonCardHeader className="card-header">
                            <IonRow className="pd-5">
                              <IonCol className="display-flex">
                                <div>
                                  {(feed.user_image == "" ||
                                    feed.user_image == undefined || feed.user_image == null) ? (
                                    <IonAvatar
                                      slot="start"
                                      className="card-avatar profile-link"
                                      onClick={() => { window.location.href = "/player_profile/" + feed.user_id }}
                                    >
                                      <img src={default_user_logo} alt={feed.user_name} />
                                    </IonAvatar>
                                  ) : (
                                    <IonAvatar
                                      slot="start"
                                      className="card-avatar profile-link"
                                      onClick={() => { window.location.href = "/player_profile/" + feed.user_id }}
                                    >
                                      <img src={feed.user_image} alt={feed.user_name} />
                                    </IonAvatar>
                                  )}
                                </div>

                                <IonLabel className="social-name">
                                  <h4 onClick={() => { window.location.href = "/player_profile/" + feed.user_id }} className="profile-link">{feed.user_name}</h4>
                                  <p>
                                    {format(
                                      feed.created_at,
                                      "MMM dd, yyyy HH:mm"
                                    )}
                                  </p>
                                </IonLabel>

                                {feed.own_content ? (
                                  <IonRow
                                    onClick={() =>
                                      openActionsheet(feed.id, "My Club")
                                    }
                                    className="social-options"
                                  >
                                    <IonIcon
                                      icon={ellipsisVerticalCircleOutline}
                                      className="edit-trash"
                                    />
                                  </IonRow>
                                ) : (
                                  <></>
                                )}
                              </IonCol>
                            </IonRow>
                          </IonCardHeader>
                          <IonCardContent className="card-content">
                            {feed.post_text}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: feed.post_content,
                              }}
                              className="mb-10"
                            ></div>
                            <IonRow className="mb-5">
                              <a style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => {
                                Browser.open({ url: feed.url });
                              }}>
                                <IonText className="s-13 pl-5">
                                  {feed.url}
                                </IonText>
                              </a>
                            </IonRow>
                            {feed.post_image == "" ||
                              feed.post_image == undefined ? (
                              <></>
                            ) : (
                              <img src={feed.post_image} />
                            )}
                          </IonCardContent>
                          <IonRow className="display-flex card-footer">
                            <a className="pointer"
                              onClick={() => {
                                loadLikesData(feed.feed_id);
                              }}
                            >
                              <Heart className="card-like-icon" />
                              <IonLabel>
                                <IonText className="card-like-text">
                                  {feed.likes} like(s)
                                </IonText>
                              </IonLabel>
                            </a>
                            <a className="pointer"
                              onClick={() => {
                                loadCommentsData(feed.feed_id, "My Club");
                              }}
                            >
                              <ChatLeftText className="card-comment-icon" />
                              <IonLabel>
                                <IonText className="card-comment">
                                  {feed.comments} comment(s)
                                </IonText>
                              </IonLabel>
                            </a>
                          </IonRow>
                        </IonCard>
                      </div>
                    );
                  })}
                </>
              ) : club_feed &&
                club_feed.length == 0 &&
                !show_skeleton_effect ? (
                <div className="center vh-25">
                  <p>No Data</p>
                </div>
              ) : (
                <>
                  {Array(4)
                    .fill(0)
                    .map((key: any, i: number) => {
                      return (
                        <IonCard className="social-card" key={i}>
                          <IonCardHeader>
                            <IonItem>
                              <IonAvatar slot="start">
                                <IonSkeletonText animated />
                              </IonAvatar>
                              <IonLabel>
                                <h3>
                                  <IonSkeletonText
                                    animated
                                    style={{ width: "50%" }}
                                  />
                                </h3>
                                <p>
                                  <IonSkeletonText
                                    animated
                                    style={{ width: "80%" }}
                                  />
                                </p>
                              </IonLabel>
                            </IonItem>
                          </IonCardHeader>
                          <IonCardContent>
                            <p>
                              <IonSkeletonText
                                animated
                                style={{ width: "50%" }}
                              />
                            </p>
                            <p>
                              <IonSkeletonText
                                animated
                                style={{ width: "80%" }}
                              />
                            </p>
                            <p>
                              <IonSkeletonText
                                animated
                                style={{ width: "60%" }}
                              />
                            </p>
                          </IonCardContent>
                          <IonRow>
                            <IonCol size="1">
                              {/* <IonIcon icon={heart}/>                           */}
                            </IonCol>
                            <IonCol size="4">
                              <IonSkeletonText
                                animated
                                style={{ width: "50%" }}
                              />
                            </IonCol>
                            <IonCol size="2"></IonCol>
                            <IonCol size="1">
                              {/* <IonIcon icon={chatbox}/> */}
                            </IonCol>
                            <IonCol size="4">
                              <IonSkeletonText
                                animated
                                style={{ width: "50%" }}
                              />
                            </IonCol>
                          </IonRow>
                        </IonCard>
                      );
                    })}
                </>
              )}
            </>
          ) : (connect_tab_value == "News Feed") ? (
            <>
              {
                espn_feed && espn_feed.length > 0 ? (
                  <>
                    {espn_feed.map((feed: any, index: number) => {
                      return (
                        <div key={index}>
                          {/* <IonRow className="ion-text-center ion-justify-content-center">
                          <IonText className="myclub-header">Exclusive for CLG Members</IonText>
                        </IonRow> */}
                          <IonCard className="social-card">
                            <IonCardHeader className="card-header">
                              <IonRow className="pd-5">
                                <IonCol size="2">
                                  <div>
                                    {(feed.user_image == "" ||
                                      feed.user_image == undefined || feed.user_image == null) ? (
                                      <IonAvatar
                                        slot="start"
                                        className="card-avatar profile-link"
                                      >
                                        <img src={default_user_logo} alt={feed.user_name} />
                                      </IonAvatar>
                                    ) : (
                                      <IonAvatar
                                        slot="start"
                                        className="card-avatar profile-link"
                                      >
                                        <img src={feed.user_image} alt={feed.user_name} />
                                      </IonAvatar>
                                    )}
                                  </div>
                                </IonCol>
                                <IonCol size="8" className="user-details mt-10">
                                  <IonLabel>
                                    <p>
                                      {format(
                                        feed.timestamp,
                                        "MMM dd, yyyy HH:mm"
                                      )}
                                    </p>
                                  </IonLabel>
                                </IonCol>
                                <IonCol size="2" className="card-options">

                                </IonCol>
                              </IonRow>
                            </IonCardHeader>
                            <IonCardContent className="card-content mt-0">
                              {feed.post_text}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: feed.post_content,
                                }}
                                className="mb-10"
                              ></div>
                              {/* <IonRow className="mb-5 text-overflow">
                              <a style={{textDecoration: 'none', cursor: 'pointer'}} onClick={()=> {
                                Browser.open({ url: feed.url });
                              }}>
                                <IonText className="s-13 pl-5">
                                  {feed.url}
                                </IonText>
                              </a>
                            </IonRow> */}
                              {feed.post_image == "" ||
                                feed.post_image == undefined ? (
                                <></>
                              ) : (
                                <>
                                  {
                                    feed.url.length > 0 ? (
                                      <a style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => {
                                        Browser.open({ url: feed.url });
                                      }}>
                                        <img src={feed.post_image} style={{ borderRadius: '10px' }} />
                                      </a>
                                    ) : (
                                      <img src={feed.post_image} style={{ borderRadius: '10px' }} />
                                    )
                                  }
                                </>
                              )}
                            </IonCardContent>
                          </IonCard>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )
              }
            </>
          ) : (
            <></>
          )

          }
        </IonGrid>
        {connect_tab_value == "Tee Times" ?
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton
              className="main-fab"
              onClick={() => {
                window.location.href = "/create_tee_time";
              }}
            >
              <IonIcon icon={add} />

            </IonFabButton>
          </IonFab>
          :
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton
              className="main-fab"
              onClick={() => {
                if (connect_tab_value == "Community") {
                  localStorage.setItem("tab_value", "Community")
                }
                else if (connect_tab_value == "My Packs") {
                  localStorage.setItem("tab_value", "My_Packs")
                }
                window.location.href = "/create_post";
              }}
            >
              <IonIcon icon={add} />

            </IonFabButton>
          </IonFab>
        }
        {/* <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton className="main-fab">
            <IonIcon icon={add} />
          </IonFabButton>
          <IonFabList side="top" className="connect-fab-list">
            <IonFabButton
              className="about-fab"
              onClick={() => {
                window.location.href = "/connect_about";
              }}
            >
              <IonIcon
                icon={informationCircleOutline}
                style={{ color: "white" }}
              />
              <IonText className="white">About</IonText>
            </IonFabButton>
            <IonFabButton
              className="create-post-fab"
              onClick={() => {
                window.location.href = "/create_post";
              }}
            >
              <IonIcon icon={duplicateOutline} style={{ color: "white" }} />
              <IonText className="white pl-5">Post</IonText>
            </IonFabButton>
            <IonFabButton
              className="share-tee-time"
              onClick={() => {
                window.location.href = "/create_tee_time";
              }}
            >
              <IonIcon icon={peopleOutline} style={{ color: "white" }} />
              <IonText className="white pl-5">Tee times</IonText>
            </IonFabButton>
          </IonFabList>
        </IonFab> */}
        {/* <IonModal isOpen={showModal}>
          <>
            <IonHeader mode="ios">
              <IonToolbar>
                <IonTitle>Post Comments</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <Comments options={comments} feed_id={current_feed_id} />
            </IonContent>
          </>
        </IonModal> */}
        <IonModal isOpen={showModal}>
          <>
            <IonHeader mode="ios">
              <IonToolbar>
                <IonButtons slot="start">
                  {/* <a onClick={()=> setShowModal(false)}>
                  <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                </a> */}
                </IonButtons>
                <IonTitle>Post Comments</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonGrid className="mt-25">
                {comments &&
                  comments.map((key: any, i: number) => {
                    return (
                      <IonRow className="ion-justify-content-center" key={i}>
                        <IonCol size="2" className="pad-ltb">
                          <IonAvatar style={{ height: "35px", width: "35px" }}>
                            <img src={key.user_image} alt={key.user_name} />
                          </IonAvatar>
                        </IonCol>
                        <IonCol size="10" style={{ marginRight: "-10px" }}>
                          <IonRow>
                            <IonCol size="6">
                              <IonText className="color-blue s-14">
                                {key.user_name}
                              </IonText>
                            </IonCol>
                            <IonCol size="6" className="ion-text-end">
                              <IonText className="s-12 comment_text">
                                {format(key.created_at, "MM-dd-yyyy HH:mm")}
                              </IonText>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonText className="s-13 pl-5">
                              {key.comment_text}
                              <div
                                className="dynamic-content-div"
                                dangerouslySetInnerHTML={{
                                  __html: key.comment,
                                }}
                              ></div>
                            </IonText>
                            <br />
                            <a style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => {
                              Browser.open({ url: key.url });
                            }}>
                              <IonText className="s-13 pl-5">
                                {key.url}
                              </IonText>
                            </a>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    );
                  })}
              </IonGrid>
              <IonGrid className="mt-25">
                <Quill key={key_date} onQuill={onQuillChange} content={''} />
                <IonRow
                  className="ion-justify-content-center mt-10 select-modal"
                >
                  <IonInput
                    value={url}
                    placeholder="Add URL"
                    className="s-14 select-dropdown pl-20"
                    mode="ios"
                    onIonChange={(e) => {
                      setUrl(e.detail.value!);
                    }}
                  >
                  </IonInput>
                </IonRow>
                <IonRow className="display-flex mt-10 justify-content-space-evenly ion-text-center">
                  <button
                    className="button-close bg-red white wg-bold comments-close"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    Close
                  </button>
                  {
                    (content.length > 0) ? (
                      <button
                        className="button-close white wg-bold bg-blue"
                        onClick={() => {
                          makeComment();
                          getFeed(connect_tab_value, 1, current_user);
                        }}
                      >
                        Post
                      </button>
                    ) : (
                      <button
                        className="button-close white wg-bold bg-blue disabled-button"
                      >
                        Post
                      </button>
                    )
                  }
                </IonRow>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal isOpen={showModal1}>
          <>
            <IonHeader mode="ios">
              <IonToolbar>
                <IonButtons slot="start">
                  <a onClick={() => setShowModal1(false)}>
                    <IonIcon
                      className="well-back-icon"
                      icon={chevronBackOutline}
                    />
                  </a>
                </IonButtons>
                <IonTitle>Likes</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <Likes options={likes} />
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showJoinPackModal}
          showBackdrop={true}
          className="join-pack-modal"
          backdropDismiss={true}
          initialBreakpoint={0.7}
          onDidDismiss={() => setShowJoinPackModal(false)} mode="md"
        >
          <IonHeader mode="ios">
            <IonToolbar>
              <IonTitle>Join a Pack</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonText>You can communicate with your packs and friends in this space. If you have not joined a pack yet, please join one below or create your own.</IonText>
            <IonGrid className="">
              {selected_pack && selected_pack.pack_name ? (
                <>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <img src={selected_pack.logo_url} width="80" height="80" />
                    <br />
                  </IonRow>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <IonText>Pack name: {selected_pack.pack_name}</IonText>
                  </IonRow>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <IonText>City: {selected_pack.city}</IonText>
                  </IonRow>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <IonText>Pack Type: {selected_pack.pack_type}</IonText>
                  </IonRow>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <IonText>Description: {selected_pack.description}</IonText>
                    <br />
                  </IonRow>
                </>
              ) : (
                <></>
              )}
              <IonRow className="ion-justify-content-center mt-10">
                <IonSelect value={pack_city}
                  interfaceOptions={customAlertOptions1}
                  interface="popover"
                  placeholder="Select a City" className="s-14 select-pack-dropdown" onIonChange={e => {
                    setPackCity(e.detail.value)
                    filterPacks(e.detail.value)
                  }} mode="ios">
                  <IonSelectOption value="Any">Any</IonSelectOption>

                </IonSelect>
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10">
                <IonSelect
                  value={selected_pack_id}
                  interfaceOptions={customAlertOptions}
                  interface="popover"
                  placeholder="Select Pack"
                  className="s-14 select-pack-dropdown"
                  onIonChange={(e) => {
                    setSelectedPackId(e.detail.value);
                    setPack(e.detail.value);
                  }}
                  mode="ios"
                >
                  {filtered_packs &&
                    filtered_packs.map((pack: any, index: number) => {
                      return (
                        <IonSelectOption value={pack.pack_id} key={index}>
                          {pack.pack_name}
                        </IonSelectOption>
                      );
                    })}
                </IonSelect>
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10">
                <IonItem
                  lines="none"
                  className="select-pack-dropdown"
                  style={{ height: "43px" }}
                >
                  <IonInput
                    value={pack_message}
                    placeholder="Message"
                    onIonChange={(e) => setPackMessage(e.detail.value!)}
                    clearInput
                  ></IonInput>
                </IonItem>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-25">
                <button
                  className="button-send white s-15"
                  onClick={sendJoinRequest}
                >
                  <IonIcon icon={paperPlane} className="white" />
                  <IonText className="pl-5 send-request">Send Request</IonText>
                </button>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-25">
                <IonText>Or you can   <span className="create-button-outline color-blue" onClick={() => {
                  window.location.href = "/create_pack"
                }}>create</span> your own pack</IonText>
              </IonRow>
            </IonGrid>
            <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{ marginBottom: '45px' }}>
              <IonFabButton
                className="main-fab"
                onClick={() => {
                  setShowJoinPackModal(false)
                }}
              >
                <IonIcon icon={arrowBackOutline} />
              </IonFabButton>
            </IonFab>
          </IonContent>
        </IonModal>
        <IonModal isOpen={showCommentsModal}>
          <>
            <IonHeader mode="ios">
              <IonToolbar>
                <IonButtons slot="start">
                  {/* <a onClick={()=> setShowModal(false)}>
                  <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                </a> */}
                </IonButtons>
                <IonTitle>Post Comments</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonGrid className="mt-25">
                {
                  comments && comments.map((key: any, i: number) => {
                    return (
                      <IonRow className="ion-justify-content-center" key={i}>
                        <IonCol size="2" className="pad-ltb">
                          <IonAvatar style={{ height: "35px", width: "35px" }}>
                            <img src={key.user_image} />
                          </IonAvatar>
                        </IonCol>
                        <IonCol size="10" style={{ marginRight: '-10px' }}>
                          <IonRow>
                            <IonCol size="6">
                              <IonText className="color-blue s-14">
                                {key.user_name}
                              </IonText>
                            </IonCol>
                            <IonCol size="6" className="ion-text-end">
                              <IonText className="s-12 comment_text">
                                {format(key.created_at, 'MM-dd-yyyy HH:mm')}
                              </IonText>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonText className="s-13 pl-5">
                              {key.comment_text}
                              <div className="dynamic-content-div" dangerouslySetInnerHTML={{
                                __html: key.comment
                              }}>
                              </div>
                            </IonText>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    )
                  })
                }
              </IonGrid>
              <IonGrid className="mt-25">
                <Quill key={key_date} onQuill={onQuillChange} content={''} />
                <IonRow className="display-flex justify-content-space-evenly ion-text-center">
                  <button className='button-close bg-orange white tt-upper wg-bold comments-close' onClick={() => {
                    setShowCommentsModal(false)
                  }}>
                    Close
                  </button>
                  <button className='button-close white tt-upper wg-bold bg' onClick={() => {
                    //       makeTeeComment()
                    //       getTeeTimes()
                  }}>Post</button>
                </IonRow>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
      </IonContent>
      <IonToolbar mode="ios" className="segment-toolbar" slot="bottom">
        <IonSegment
          onIonChange={(e) => {
            console.log("tab1 Segment selected", e.detail.value);

            setConnectTabValue(e.detail.value);
            getFeed(e.detail.value, 1, current_user);
            setData("");
            setTimeout(() => {
              setData("Normal text");
              console.log("set data");
            }, 8000);
            if (e.detail.value == "News Feed") {
              getESPNFeed();
            }
          }}
          value={connect_tab_value}
          mode="ios"
          className="new-segment"
        >
          <IonSegmentButton value="Community" className="new-segment-button">
            <IonLabel className="new-segment-button-label">Community</IonLabel>
          </IonSegmentButton>
          {/* <IonSegmentButton value="My Club" className="new-segment-button">
            <IonLabel className="new-segment-button-label">My Club</IonLabel>
          </IonSegmentButton> */}
          <IonSegmentButton value="My Packs" className="new-segment-button">
            <IonLabel className="new-segment-button-label">My Packs</IonLabel>
          </IonSegmentButton>
          {  /*<IonSegmentButton value="Tee Times" className="new-segment-button">
            <IonLabel className="new-segment-button-label">Tee Times</IonLabel>
        </IonSegmentButton> */}
          <IonSegmentButton value="News Feed" className="new-segment-button">
            <IonLabel className="new-segment-button-label">News Feed</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </IonToolbar>
      <BottomBar cur_page="lockerroom" />
    </IonPage>
  );
};

export default LockerRoom;
