import { IonContent, IonPage, IonTitle, IonToolbar, IonTabBar, IonTabButton, IonIcon, IonGrid, IonFab, IonFabButton,
  IonCard, IonCol, IonRow, IonText, isPlatform, IonAccordion, IonAccordionGroup,
  IonItem, IonLabel } from '@ionic/react';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { arrowBackOutline, list, settings } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import firebase from '../../js/firebase';

const db = getFirestore(firebase);

const MyPack: React.FC = () => {
    const [current_user, setCurentUser] = useState<any>({});
    const [pack_users, setPackUsers] = useState<any>([]);
    const [current_pack, setCurrentPack] = useState<any>([]);
    const getPackPlayers = async(pack_id:string) => {
        const packQuery = query(
            collection(db, "PACKS"),
            where("pack_id", "==", pack_id)
        );
        const packQuerySnapshot = await getDocs(packQuery);
        if(packQuerySnapshot.docs && packQuerySnapshot.docs.length > 0) {
            let pack = packQuerySnapshot.docs[0].data();
            let user_ids = [
                ...pack.players.map((x: any) => {
                    return x.user_id;
                }),
            ];
            const q = query(
                collection(db, "USER_PROFILE"),
                where("created_at", ">", 0)
            );
            const querySnapshot = await getDocs(q);
            if (querySnapshot.docs && querySnapshot.docs.length > 0) {
                await Promise.all(
                    querySnapshot.docs.map(async (user) => {
                        if(user_ids.filter(x=> x == user.data().user_id).length > 0) {
                            var doc_item = user.data();
                            doc_item.id = user.id;
                            return doc_item;
                        } else {
                            return;
                        }
                    })
                ).then((results) => {
                    setPackUsers(results.filter(x=> x));
                })
            }
        }
    }
    useEffect(() => {
        let user = localStorage.getItem('firebase_user');
        if(user != null) {
          setCurentUser(JSON.parse(user));
        }
        let pack = localStorage.getItem('current_pack');
        if(pack != null) {
            setCurrentPack(JSON.parse(pack));
            getPackPlayers(JSON.parse(pack).pack_id);
        }
    }, []);
    const [my_pack_bottom_tab,setMyPackBottomTab] = useState<any>("List");
    return (
        <IonPage>

          <IonToolbar mode="ios" className={isPlatform('ios') ? 'ios-toolbar-top box-shadow': 'toolbar-top box-shadow' }>
            {/* <IonButtons slot="start">
                <a onClick={()=> window.history.back()}>
                    <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                </a>
            </IonButtons> */}
            <IonTitle>
                <IonLabel>
                    <IonText className='wg-bold s-16'>{current_pack.pack_name +' '+current_pack.city}</IonText><br/>
                    <IonText className='wg-500 s-12 grey'>{current_user.region_name}</IonText>
                </IonLabel>

            </IonTitle>
          </IonToolbar>
        <IonContent>
            {/* {
                (my_pack_bottom_tab==="List")?( */}
                <IonGrid>
                    <IonCard className="social-card">
                        <IonRow className="ion-justify-content-center ion-text-center bb-line">
                            <IonCol size="2">
                                <IonText className="s-14 wg-600 color-gray">S.No</IonText>
                            </IonCol>
                            <IonCol>
                            <IonText className="s-14 wg-600 color-gray">Name</IonText>
                            </IonCol>
                            <IonCol>
                            <IonText className="s-14 wg-600 color-gray">Quota</IonText>
                            </IonCol>
                            <IonCol>
                            <IonText className="s-14 wg-600 color-gray">Driving Distance</IonText>
                            </IonCol>
                        </IonRow>
                        {
                            pack_users && pack_users.map((item:any, index:number)=> {
                                return(
                                    <IonRow className="ion-justify-content-center ion-text-center bb-line" key={index}>
                                        <IonCol size="2">
                                            <IonText className="s-14 color-black wg-500">{index+1}</IonText>
                                        </IonCol>
                                        <IonCol  onClick={()=> {window.location.href="/player_profile/"+item.user_id}} >
                                        <IonText className="s-14 color-red wg-600">{item.first_name+' '+item.last_name}</IonText>
                                        </IonCol>
                                        <IonCol>
                                        <IonText className="s-14 color-black wg-600">{item.quota ? item.quota : ''}</IonText>
                                        </IonCol>
                                        <IonCol>
                                        <IonText className="s-14 color-black wg-600">{item.driving_distance  && item.driving_distance.text ?item.driving_distance.text:'' }</IonText>
                                        </IonCol>
                                    </IonRow>
                                )
                            })
                        }
                    </IonCard>
                </IonGrid>
            {/*     ):(
                    <IonGrid className="mt-15">
                        <IonRow className="ion-justify-content-center ion-text-center mt-10">
                            <IonText className="s-15 black wg-bold">Settings</IonText>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center mt-5">
                            <IonCol size="5.5">
                                <IonText className="s-13 wg-bold black">Points Scale</IonText>
                            </IonCol>
                            <IonCol size="0.5">
                            <IonText className="s-13 wg-bold black">:</IonText>
                            </IonCol>
                            <IonCol size="6">
                                <IonText className="s-13">Bogey: 1, Par: 2,Birdie: 4,Eagle or better: 8, *Double bogey: 0.5</IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center mt-5">
                            <IonCol size="5.5">
                                <IonText className="s-13 wg-bold black">Adjustment based on leaderboard position:</IonText>
                            </IonCol>
                            <IonCol size="0.5">
                            <IonText className="s-13 wg-bold black">:</IonText>
                            </IonCol>
                            <IonCol size="6">
                            <IonText className="s-13">80% - 100%</IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center mt-5">
                            <IonCol size="5.5">
                                <IonLabel position="fixed" className="s-13 wg-bold black">Prize pool per Player / Round:</IonLabel>
                            </IonCol>
                            <IonCol size="0.5">
                            <IonText className="s-13 wg-bold black">:</IonText>
                            </IonCol>
                            <IonCol size="6">
                            <IonText className="s-13">50 chips($5)</IonText>
                            </IonCol>

                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center mt-10">
                            <IonText className="s-12 black">*If your pack has any custom configurations, please reach out to pack admin</IonText>
                        </IonRow>
                    </IonGrid>
                )
            } */}
            <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '45px'}}>
                <IonFabButton
                className="main-fab"
                onClick={() => {
                    window.history.back()
                }}
                >
                <IonIcon icon={arrowBackOutline} />
                </IonFabButton>
            </IonFab>
        </IonContent>
        {/* <IonTabBar slot="bottom">
            <IonTabButton tab="list" className={(my_pack_bottom_tab==="List")?'tab-selected color-red':''}>
            <IonIcon icon={list} onClick={()=>{
                setMyPackBottomTab("List")
            }}/>
            <IonLabel onClick={()=>{
                setMyPackBottomTab("List")
            }}>List</IonLabel>
            </IonTabButton>
            <IonTabButton tab="settings" className={(my_pack_bottom_tab==="Settings")?'tab-selected color-red':''}>
            <IonIcon icon={settings} onClick={()=>{
                setMyPackBottomTab("Settings")
            }}/>
            <IonLabel onClick={()=>{
                setMyPackBottomTab("Settings")
            }}>Settings</IonLabel>
            </IonTabButton>
        </IonTabBar> */}
        </IonPage>
    );
};
export default MyPack;
