import {
    IonButton,
    IonButtons,
    IonHeader,
    IonInput,
    IonItem,
    IonLoading,
  IonTitle,
  isPlatform,
  RefresherEventDetail,
  useIonToast,
} from "@ionic/react";
import {
  IonModal,
  IonContent,
  IonPage,
  IonToolbar,
  IonIcon,
  IonLabel,
  IonGrid,
  IonFab,
  IonFabButton,
  IonCard,
  IonRow,
  IonCol,
  IonText,
  IonSelect,
  IonSelectOption
} from "@ionic/react";
import "../../css/locker_room.css";
import {
  useEffect,
  useState,
} from "react";
import _ from "lodash";
import firebase from "../../js/firebase.js";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useParams } from "react-router";
import { getEntry, updateEntry, createEntryWithoutId } from "../../js/firebase_doc_functions";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, signOut, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import PayPal from "../Components/paypal_payment";
import appstore from '../../img/appstore.svg';
import playstore from '../../img/playstore.svg';
import { Browser } from "@capacitor/browser";
import * as utils from "../../js/utils";
const db = getFirestore(firebase);
const auth = getAuth();

const EventDetails: React.FC = () => {
  let scores = [{quota: 36, option: 'Low 70s'}, {quota: 33, option: 'Mid 70s'}, {quota: 30, option: 'High 70s'}, {quota: 27, option: 'Low 80s'}, {quota: 24, option: 'Mid 80s'}, {quota: 21, option: 'High 80s'}, {quota: 18, option: 'Low 90s'}, {quota: 15, option: 'Mid 90s'}, {quota: 12, option: 'High 90s'}, {quota: 9, option: 'Above 100'}] 
  let {id}:any = useParams(); 
  const [tour_id, setTourId] = useState<string>('');
  const [default_url,setDefaultUrl] = useState<any>('https://members.eclub.golf/');
  const [first_name, setFirstName] = useState<any>('');
  const [last_name, setLastName] = useState<any>('');
  const [city, setCity] = useState<any>('');
  const [email, setEmail] = useState<any>('');
  const [password, setPassword] = useState<string>('');
  const [confirm_password, setConfirmPassword] = useState<string>('');
  const [signup_status, setSignupStatus] = useState<any>("New");
  const [email_disabled, setEmailDisabled] = useState<boolean>(false);
  const [company, setCompany] = useState<any>('');
  const [typically_shoots, setTypicallyShoots] = useState<any>('');
  const [showLoading, setShowLoading] = useState(false);
  const [showVerificationLoading, setShowVerificationLoading] = useState(false);
  const [showToast, hideToast] = useIonToast();
  const [paypal_data, setPaypalData] = useState<any>();
  const [set_paypal_payment, setPaypalPayment] = useState(false);
  const [buy_format, setBuyFormat] = useState<string>('');
  const [referrer, setReferrer] = useState<string>('new_tt');
  const [required_data, setRequiredData] = useState<any>();
  const [chips_qty, setChipsQty] = useState<any>();
  const [admin_user, setAdminUser] = useState<any>({});
  const [general_pack, setGeneralPack] = useState<any>({});
  const [user_id, setUserId] = useState<any>();
  const [amount, setAmount] = useState<any>();
  const [tour, setTour] = useState<any>({
    tour_name: "",
    city: "",
    format: "",
    event_dues: 0,
    fixed_courses: "",
    fixed_tees: "",
    holes: 0,
    players: [],
    selected_courses: [],
    selected_tees: [],
    pull_scores_from: "",
    partner_required: "",
    visibility: "",
    youth_on_course: 0,
    status: ""
  });
  function isEmail(useremail:string) {
    var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(useremail);
  }
  const getTourData = async(tour_id:string) => {
    const querySnapshot = await getEntry("EVENTS", tour_id);
    if(querySnapshot.created_at) {
        let doc_item = querySnapshot;
        doc_item.id = tour_id;
        setChipsQty((doc_item.event_dues+doc_item.youth_on_course+doc_item.portal_fees)*10);
        setAmount(doc_item.event_dues+doc_item.youth_on_course+doc_item.portal_fees);
        setTour(doc_item);
    }
  }
  const getAdminUser = async () => {
    var condition = where('is_portal_admin', '==', true);
    const q = query(collection(db, "USER_PROFILE"), condition);

    const querySnapshot = await getDocs(q);
    if(querySnapshot.docs && querySnapshot.docs.length > 0) {
      let doc_item = querySnapshot.docs[0].data();
      doc_item.id = querySnapshot.docs[0].id;
      setAdminUser(doc_item);
    } else {
      setAdminUser({});
    }
  }

  const getGeneralPack = async () => {
    var condition = where('pack_name', '==', "General");
    const q = query(collection(db, "PACKS"), condition);

    const querySnapshot = await getDocs(q);
    if(querySnapshot.docs && querySnapshot.docs.length > 0) {
      let doc_item = querySnapshot.docs[0].data();
      doc_item.id = querySnapshot.docs[0].id;
      setGeneralPack(doc_item);
    } else {
      setGeneralPack({});
    }
  }
  const registerNewUser = async ()=> {
    setShowLoading(true);
    if(first_name.length <=0 || last_name.length <=0 || typically_shoots.length <= 0 || city.length <=0 || email.length <=0 || password.length <=0) {
      setShowLoading(false);
      showToast('Please fill in all details.', 2000);
    } else {
      if (!isEmail(email)) {
        setShowLoading(false);
        alert("Please enter a valid email to signup");
      } else if (password !== confirm_password) {
        setShowLoading(false);
        alert("Password and Confirm Password does not match");
      } else if(isEmail(email) && password === confirm_password){
        var quota = scores.filter((x:any)=> x.option == typically_shoots)[0].quota;
        let referral_id = await utils.randomString(8, '#aA');
        let coupon_code = await utils.randomString(8, "#aA");
        await createUserWithEmailAndPassword(auth, email.toLowerCase().trim(), password)
          .then((userCredential) => {
            // Signed in 
            createEntryWithoutId('USER_PROFILE', 
            {
              'user_id': userCredential.user.uid, 
              'first_name': first_name, 
              'last_name': last_name, 
              'email': email, 
              'city': city,
              'ghin_number': 0, 
              'status': 'Active', 
              'ghin_validated': false, 
              'zip_code': 0, 
              'packs': [{'pack_name': general_pack.pack_name, 'pack_id': general_pack.pack_id}],
              'referral_id': referral_id,
              'auto_validate': true,
              'adjustment_percentage': 80,
              'quota_adjustment_percentage': 75,
              'ppw_exceptional_score_adjustment': 0,
              'ppw_validated': false,
              'tt_validated': false,
              'exceptional_score_adjustment': 0,
              'chips': 0,
              'earnings': 0,
              'total_earnings': 0,
              'region_id': '',
              'region_name': '',
              'tao_validated': false,
              'btb_validated': false,
              'is_portal_admin': false,
              'is_region_admin': false,
              'quota': quota,
            }
            );
            createEntryWithoutId('USER_COUPONS', {
              coupon_code: coupon_code,
              coupon_value: 0,
              ppw_coupon_value: 0,
              ppw_redeemed: false,
              redeemed: false,
              tao_coupon_value: 0,
              tao_redeemed: false,
              tt_coupon_value: 0,
              tt_redeemed: false,
              user_doc_id: userCredential.user.uid,
              user_id: userCredential.user.uid,
              user_name: first_name+' '+last_name,
            });
            let source = {
              id: userCredential.user.uid,
              name: first_name+' '+last_name,
              date: new Date().getTime(),
              image_url: '',
              ghin_number: '',
              email: email,
              city: city,
              region_id: '',
              region_name: '',
              
            };
            createEntryWithoutId("NOTIFICATIONS", {
              type: 'REGISTRATION',
              created_name: first_name+' '+last_name,
              created_by: userCredential.user.uid,
              user_id: admin_user.user_id,
              user_name: 'Portal Admin',
              source: source,
              pack_id: general_pack.pack_id,
              pack_name: general_pack.pack_name,
              is_read: false
            });
            general_pack.players.push({user_name: first_name+' '+last_name,user_id: userCredential.user.uid});
            updateEntry('PACKS', general_pack.id, {players: general_pack.players});
           
            let players = tour.players.filter((x:any)=> 
              x.user_id == userCredential.user.uid,
            );
            if(players.length <= 0) {
              tour.players.push({user_id: userCredential.user.uid, user_name: first_name+' '+last_name});
              setRequiredData({
                event_name: tour.event_name,
                event_city: tour.city,
                format: tour.format,
                first_name: first_name,
                last_name: last_name,
                company: company,
                typically_shoots: typically_shoots.option,
                email: email,
                city: city,
                event_id: tour_id,
                user_id: userCredential.user.uid,
                players: tour.players,
                quota: quota
              });
              const actionCodeSettings = {
                url: default_url,
                handleCodeInApp: true,
              };
              sendEmailVerification(userCredential.user, actionCodeSettings).then(function () {
                signOut(auth);
                setShowLoading(false);
                setSignupStatus("Email sent");
                showToast("An email has been sent with link to verify your account", 3000);
              });
            } else {
              setPaypalPayment(false); 
              setPaypalData({});
              showToast('You are already signed up for this tour.', 2000);
            }
          })
          .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            setShowLoading(false);
            showToast(error.code.toString(), 2000);
          });
      }
    }
  }
  const handleCallback = async(data:any) => {
    console.log("HI", data);
    await updateEntry("EVENTS", required_data.event_id, {players:required_data.players})
    setSignupStatus("Signup done");
    setPaypalPayment(false);
    showToast(
      "You are signed up for event. Please reach us at info@eclub.golf for any questions.", 2000
    );
  }
  const checkEmailVerificationStatus = () => {
    setShowVerificationLoading(true);
    signInWithEmailAndPassword(auth, email.toLowerCase().trim(), password)
      .then(async(result) => {
        if (result.user.emailVerified) {
          setShowVerificationLoading(false);
          setSignupStatus("Email verified");
        } else {
          setShowVerificationLoading(false);
          showToast("Please verify your email.", 2000);
        }
      })
      .catch((error) => {
        console.log("dfsfsd", error);
        setShowVerificationLoading(false);
        var errorCode = error.code;
        var errorMessage = error.message;
        if (error.code === "auth/user-not-found") {
          showToast("Couldn't match a golfer with these details.", 2000);
        } else if (error.code === "auth/too-many-requests") {
          showToast("Too many failed requests. Please try after some time", 2000);
        } else if (error.code === "auth/wrong-password") {
          showToast("Invalid password", 2000);
        } else {
          showToast(error.code.toString(), 2000);
        }
      });
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          if (user.emailVerified) {
            setUserId(user.uid);
          }
        }
      })    
  }
  const showTTPaypalPayment = async(tour:any, chips:any, amount:any) => {
    if(first_name.length <=0 || last_name.length <=0 || email.length <=0 || city.length <=0 ) {
      showToast('Please fill in all details.', 2000);
    } else {
      const q = query(collection(db, "CONFIG"), where('type', '==', "PAYPAL"));
      const querySnapshot = await getDocs(q);
      if(querySnapshot && querySnapshot.docs.length > 0) {
        var obj = {
          "currency": "USD",
          "client-id": querySnapshot.docs[0].data().value,
          "components": "buttons"
        }
        setPaypalData(obj);
        setChipsQty(parseInt(chips));
        setAmount(parseInt(amount));
        setPaypalPayment(true); 
      } else {
        setPaypalPayment(false); 
        setPaypalData({});
        showToast('Error fecthing payment details. Please try again after some time.', 2000);
      }
    }
  }
  const joinTour = async() => {
      if(first_name.length <=0 || last_name.length <=0 || email.length <=0 || city.length <=0 ) {
        showToast('Please fill in all details.', 2000);
      } else {
        setShowLoading(true);
        let obj = {
          tour_name: tour.tour_name,
          tour_city: tour.city,
          format: tour.format,
          first_name: first_name,
          last_name: last_name,
          company: company,
          typically_shoots: typically_shoots.option,
          email: email,
          city: city,
          tour_id: tour_id
        }
        await updateEntry("EVENT_SIGNUPS", tour.id, obj);
        setFirstName('');
        setLastName('');
        setCity('');
        setEmail('');
        setCompany('');
        setShowLoading(false);
        showToast('You will be added to the event in a day. Please reach us at info@eclub.golf for any questions.', 2000);
    }
  }

  useEffect(() => {
    getAdminUser();
    getGeneralPack();
    if (id != null) {
      setTourId(id);
      getTourData(id);
    }
  }, []);
  // console.log("darkmode checking",document.body.classList.contains('dark'))
  return (
    <IonPage>
        <IonToolbar
            mode="ios"
            className={
              isPlatform("ios")
                ? "ios-toolbar-top box-shadow"
                : "toolbar-top box-shadow"
            }
          >
            <IonLabel slot="start" className="s-16 w-800 color-blue" style={{padding: '0px 0px 0px 10px'}}>
            {tour.tour_name}
            </IonLabel>
            <IonLabel slot="end" className="s-16 w-800 color-blue" style={{padding: '0px 0px 0px 10px'}}>
              
            </IonLabel>
          </IonToolbar>
          <IonContent>
            <IonGrid className="mt-15">
            <IonRow className="ion-justify-content-center mt-5">
                    <img
                      src={require(`../../img/eclub.png`).default}
                      width="140"
                      height="50"
                    />
                    </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-10">
                  <IonText className="s-13 wg-bold black">
                    {tour.event_name}
                  </IonText>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center">
                <IonCol size="5.5" className="pad-0 text-align-right">
                  <IonText className="s-13 wg-bold black">Format</IonText>
                </IonCol>
                <IonCol size="0.5"  className="pad-0">
                  <IonText className="s-13 wg-bold black">:</IonText>
                </IonCol>
                <IonCol size="5.5" className="pad-0 text-align-left">
                  <IonText className="s-13">
                    { (tour.peoria_format && tour.netscore_format) ? "Peoria and Netscore" : 
                        (tour.netscore_format ? "Netscore" : "Peoria")
                      }
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center">
                <IonCol size="5.5" className="pad-0 text-align-right">
                  <IonText className="s-13 wg-bold black">Total dues</IonText>
                </IonCol>
                <IonCol size="0.5" className="pad-0">
                  <IonText className="s-13 wg-bold black">:</IonText>
                </IonCol>
                <IonCol size="5.5" className="pad-0 text-align-left">
                 
                    {
                      tour.youth_on_course > 0 ? (
                        <IonText className="s-13">
                          ${tour.event_dues+tour.youth_on_course+tour.portal_fees}
                        </IonText>
                      ) : (
                        <IonText className="s-13">
                          ${tour.event_dues+tour.portal_fees}
                        </IonText>
                      )
                    }
                </IonCol>
              </IonRow>
              {
                  tour.weekly_prize_pool > 0 ? (
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <IonCol size="5.5" className="pad-0 text-align-right">
                        <IonText className="s-13 wg-bold black">Weekly prize pool</IonText>
                        </IonCol>
                        <IonCol size="0.5" className="pad-0">
                        <IonText className="s-13 wg-bold black">:</IonText>
                        </IonCol>
                        <IonCol size="5.5" className="pad-0 text-align-left">
                        <IonText className="s-13">
                            ${tour.weekly_prize_pool}
                        </IonText>
                        </IonCol>
                    </IonRow>
                  ) : (
                      <></>
                  )
              }
              
              {
                tour.fixed_courses == "No" ? (
                  <IonRow className="ion-justify-content-center ion-text-center">
                    <IonCol size="5.5" className="pad-0 text-align-right">
                      <IonText className="s-13 wg-bold black">Courses</IonText>
                    </IonCol>
                    <IonCol size="0.5" className="pad-0">
                      <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="5.5" className="pad-0 text-align-left">
                      <IonText className="s-13">
                        Any
                      </IonText>
                    </IonCol>
                  </IonRow>
                ) : (
                  <IonRow className="ion-justify-content-center ion-text-center">
                    <IonCol size="5.5" className="pad-0 text-align-right">
                      <IonText className="s-13 wg-bold black">Selected courses</IonText>
                    </IonCol>
                    <IonCol size="0.5" className="pad-0">
                      <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="5.5" className="pad-0 text-align-left">
                      {tour.selected_courses && tour.selected_courses.map((item:string, index:number)=> {
                        return (
                          <div  key={index}>
                          <IonText className="s-13">
                            {item.split('-')[0]}
                          </IonText>
                          <br/>
                          </div>
                        )
                      })}
                    </IonCol>
                  </IonRow>
                )
              }
              {
                tour.url && tour.url.length > 0 ? (
                  <IonRow className="ion-justify-content-center ion-text-center">
                    <IonCol size="5.5" className="pad-0 text-align-right">
                      <IonText className="s-13 wg-bold black">Details</IonText>
                    </IonCol>
                    <IonCol size="0.5" className="pad-0">
                      <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="5.5" className="pad-0 text-align-left">
                      <IonText className="s-13 color-red wg-600" onClick={e => {
                        Browser.open({ 'url': tour.url });
                      }}>
                        Show
                      </IonText>
                    </IonCol>
                  </IonRow>
                ) : (
                  <></>
                )
              }
              {
                tour.target_based_on_handicap == "Yes" ? (
                  <IonRow className="ion-justify-content-center ion-text-center">
                    <IonCol size="5.5" className="pad-0 text-align-right">
                      <IonText className="s-13 wg-bold black">Handicap Index<sup className="s-8">TM</sup></IonText>
                    </IonCol>
                    <IonCol size="0.5" className="pad-0">
                      <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="5.5" className="pad-0 text-align-left">
                      <IonText className="s-13">
                        Required
                      </IonText>
                    </IonCol>
                  </IonRow>
                ) : (
                  <></>
                )
              }
            </IonGrid>
            <IonGrid className="grid-1-bg">
                <IonRow className="ion-justify-content-center s-18"> 
                    Signup                     
                </IonRow>
                {
                  (signup_status === "New") ? (
                    <>
                    <IonRow className="ion-justify-content-center mt-5">
                      <IonCol size="6">
                        <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                            <IonInput
                            value={email}                        
                            placeholder="email"
                            type='text'
                            className="s-14 custom-input"
                            min='0'
                            disabled={email_disabled}
                            onIonChange={(e:any) => {                                  
                              setEmail(e.target.value)                                                                                                                                          
                            }}
                            // clearInput
                            ></IonInput>
                        </IonItem>   
                      </IonCol> 
                      <IonCol size="6">
                        <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                          <IonSelect value={typically_shoots} 
                            interface="popover"      
                            name="typically shoots"                      
                            placeholder="typical score" className="login-input wid-100 select-box" 
                            onIonChange={e => setTypicallyShoots(e.detail.value!)} mode="ios">
                            {
                                scores.map((key:any,i:number)=>{
                                return(
                                    <IonSelectOption value={key.option} key={i}>{key.option}</IonSelectOption>
                                )
                                })
                            }  
                            </IonSelect>
                          </IonItem>
                      </IonCol>               
                    </IonRow>
                    
                    <IonRow className="ion-justify-content-center mt-5">
                      <IonCol>
                      <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                          <IonInput
                          value={password}                        
                          placeholder="password"
                          type='password'
                          className="s-14 custom-input"
                          onIonChange={(e:any) => {                                  
                            setPassword(e.target.value)                                                                                                                                          
                          }}
                          // clearInput
                          ></IonInput>
                      </IonItem>   
                      </IonCol>      
                      <IonCol>
                      <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                          <IonInput
                          value={confirm_password}                        
                          placeholder="confirm password"
                          type='password'
                          className="s-14 custom-input"
                          onIonChange={(e:any) => {                                  
                            setConfirmPassword(e.target.value)                                                                                                                                          
                          }}
                          // clearInput
                          ></IonInput>
                      </IonItem>   
                      </IonCol>           
                    </IonRow>

                    <IonRow className="ion-justify-content-center mt-10">  
                      <IonCol size="6">
                        <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                            <IonInput
                            value={first_name}                        
                            placeholder="first name"
                            type='text'
                            className="s-14 custom-input"
                            min='0'
                            onIonChange={(e:any) => {                                  
                                setFirstName(e.target.value)                                                                                                                                          
                            }}
                            // clearInput
                            ></IonInput>
                        </IonItem>    
                      </IonCol>                      
                      <IonCol size="6">
                        <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                            <IonInput
                            value={last_name}                        
                            placeholder="last name"
                            type='text'
                            className="s-14 custom-input"
                            min='0'
                            onIonChange={(e:any) => {                                  
                                setLastName(e.target.value)                                                                                                                                          
                            }}
                            // clearInput
                            ></IonInput>
                        </IonItem>
                      </IonCol>                      
                    </IonRow>
                    <IonRow className="ion-justify-content-center mt-5">  
                      <IonCol size="6">
                          <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                              <IonInput
                              value={city}                        
                              placeholder="city"
                              type='text'
                              className="s-14 custom-input"
                              min='0'
                              onIonChange={(e:any) => {                                  
                                  setCity(e.target.value)                                                                                                                                          
                              }}
                              // clearInput
                              ></IonInput>
                          </IonItem>    
                      </IonCol>                      
                      <IonCol size="6">
                          <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                              <IonInput
                              value={company}                        
                              placeholder="company"
                              type='text'
                              className="s-14 custom-input"
                              min='0'
                              onIonChange={(e:any) => {                                  
                                  setCompany(e.target.value)                                                                                                                                          
                              }}
                              // clearInput
                              ></IonInput>
                          </IonItem>
                      </IonCol>                      
                    </IonRow>
                    </>
                  ) : (signup_status === "Email sent" || signup_status === "Email verified") ? (
                    <>
                    <IonRow className="ion-justify-content-center mt-5">
                      <IonCol>
                        <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px", width: '95%'}}>
                            <IonInput
                            value={email}                        
                            placeholder="email"
                            type='text'
                            className="s-14 custom-input"
                            min='0'
                            disabled={true}
                            onIonChange={(e:any) => {                                  
                              setEmail(e.target.value)                                                                                                                                          
                            }}
                            // clearInput
                            ></IonInput>
                        </IonItem>   
                      </IonCol>           
                    </IonRow>
                    
                    <IonRow className="ion-justify-content-center mt-5">
                      <IonCol>
                      <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                          <IonInput
                          value={password}                        
                          placeholder="password"
                          type='password'
                          className="s-14 custom-input"
                          disabled={true}
                          onIonChange={(e:any) => {                                  
                            setPassword(e.target.value)                                                                                                                                          
                          }}
                          // clearInput
                          ></IonInput>
                      </IonItem>   
                      </IonCol>      
                      <IonCol>
                      <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                          <IonInput
                          value={confirm_password}                        
                          placeholder="confirm password"
                          type='password'
                          disabled={true}
                          className="s-14 custom-input"
                          onIonChange={(e:any) => {                                  
                            setConfirmPassword(e.target.value)                                                                                                                                          
                          }}
                          // clearInput
                          ></IonInput>
                      </IonItem>   
                      </IonCol>           
                    </IonRow>

                    <IonRow className="ion-justify-content-center mt-10">  
                      <IonCol size="6">
                        <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                            <IonInput
                            value={first_name}                        
                            placeholder="first name"
                            type='text'
                            disabled={true}
                            className="s-14 custom-input"
                            min='0'
                            onIonChange={(e:any) => {                                  
                                setFirstName(e.target.value)                                                                                                                                          
                            }}
                            // clearInput
                            ></IonInput>
                        </IonItem>    
                      </IonCol>                      
                      <IonCol size="6">
                        <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                            <IonInput
                            value={last_name}                        
                            placeholder="last name"
                            type='text'
                            className="s-14 custom-input"
                            min='0'
                            disabled={true}
                            onIonChange={(e:any) => {                                  
                                setLastName(e.target.value)                                                                                                                                          
                            }}
                            // clearInput
                            ></IonInput>
                        </IonItem>
                      </IonCol>                      
                    </IonRow>
                    <IonRow className="ion-justify-content-center mt-5">  
                      <IonCol size="6">
                          <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                              <IonInput
                              value={city}                        
                              placeholder="city"
                              type='text'
                              disabled={true}
                              className="s-14 custom-input"
                              min='0'
                              onIonChange={(e:any) => {                                  
                                  setCity(e.target.value)                                                                                                                                          
                              }}
                              // clearInput
                              ></IonInput>
                          </IonItem>    
                      </IonCol>                      
                      <IonCol size="6">
                          <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                              <IonInput
                              value={company}                        
                              placeholder="company"
                              type='text'
                              disabled={true}
                              className="s-14 custom-input"
                              min='0'
                              onIonChange={(e:any) => {                                  
                                  setCompany(e.target.value)                                                                                                                                          
                              }}
                              // clearInput
                              ></IonInput>
                          </IonItem>
                      </IonCol>                      
                    </IonRow>
                    </>
                  ) : (signup_status === "Signup done") ? (
                    <>
                    <IonRow className="ion-justify-content-center mt-10"> 
                      <IonText className="s-15 black wg-bold">Your payment was successfull. 
                      <br/>
                      1. Please verify your email to Login
                      <br/>
                      2. Please login through website or app to participate in the event.
                      <br/>
                      3. Incase of any issues please contact info@eclub.golf
                      </IonText> 
                    </IonRow>
                    <IonRow className="ion-justify-content-center mt-10"> 
                      <img alt="playStore" src={playstore} width="130" height="40" onClick={e=> {
                        Browser.open({ url: 'https://play.google.com/store/apps/details?id=com.edvenswa.eclub' });
                      }}></img>             
                      <img alt="appStore" src={appstore} width="130" height="40" onClick={e=> {
                        Browser.open({ url: 'https://apps.apple.com/app/id1563873534' });
                      }}></img>                      
                    </IonRow>
                    </>
                  ) : (
                    <></>
                  )
                }
                {
                  (signup_status === "New") ? (
                    <IonRow className="ion-justify-content-center ion-text-center mt-15">
                      <IonCol size="3">

                      </IonCol>
                      <IonCol size="6">
                      <button className='button-send white s-15' onClick={e=> {
                          registerNewUser()
                      }}>
                          <IonText className="s-16">Sign up</IonText>
                      </button>
                      </IonCol>
                      <IonCol size="3">

                      </IonCol>
                    </IonRow>
                  ) : (signup_status === "Email sent") ? (
              /*      <IonRow className="ion-justify-content-center ion-text-center mt-15">
                      <IonCol size="3">

                      </IonCol>
                      <IonCol size="6">
                      <button className='button-send white s-15' onClick={e=> {
                          checkEmailVerificationStatus();
                      }}>
                          <IonText className="s-16">Verify</IonText>
                      </button>
                      </IonCol>
                      <IonCol size="3">

                      </IonCol>
                    </IonRow>
                
                  ) : (signup_status === "Email verified") ? (
                */    <IonRow className="ion-justify-content-center ion-text-center mt-15">
                      <IonCol size="3">
                      </IonCol>
                      <IonCol size="6">
                      <button className='button-send white s-15' onClick={e=> {
                          showTTPaypalPayment(tour, (tour.event_dues+tour.youth_on_course+tour.portal_fees)*10,
                          (tour.event_dues+tour.youth_on_course+tour.portal_fees));
                      }}>
                          <IonText className="s-16">Proceed to Pay</IonText>
                      </button>
                      </IonCol>
                      <IonCol size="3">
                      </IonCol>
                    </IonRow>
                  ) : (
                    <></>
                  ) 
                }
            </IonGrid>
            <IonLoading
                isOpen={showLoading}
                message={'Signing up..'}
            />
            <IonLoading
                isOpen={showVerificationLoading}
                message={'Checking..'}
            />
            <IonModal isOpen={set_paypal_payment}>
            <>
            <IonHeader mode="ios">
                <IonToolbar>
                <IonButtons slot="start">
                    {/* <a onClick={()=> setShowModal(false)}>
                    <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                    </a> */}
                </IonButtons>
                <IonTitle>
                  {tour.tour_name} Payment
                </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid className="mt-15">
                    <IonRow className="ion-justify-content-center ion-text-center mt-10">                            
                        <IonCol>
                            <IonLabel position="fixed">Total Price:</IonLabel>                                
                        </IonCol>
                        <IonCol>
                            <IonText>${amount}</IonText>                            
                        </IonCol>

                    </IonRow>      
                    <IonRow className="ion-justify-content-center ion-text-center mt-10">                            
                        <IonCol>
                            <IonText>Total Processing Charges:</IonText>                                
                        </IonCol>
                        <IonCol>
                            <IonText>{((((chips_qty*0.1)*3.49)/100)+0.60).toFixed(2)}</IonText>                            
                        </IonCol>
                    </IonRow>      
                    <IonRow className="ion-justify-content-center ion-text-center mt-10">                            
                        <IonCol>
                            <IonLabel position="fixed">Total Cost:</IonLabel>
                        </IonCol>
                        <IonCol>
                        <IonText>${amount+Number((((amount*3.49)/100)+0.60).toFixed(2))}</IonText>                            
                        </IonCol>

                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-text-center mt-25">

                    </IonRow>
                </IonGrid>
                <PayPalScriptProvider
                    options={paypal_data}
                >
                    <PayPal paypal_data={paypal_data} required_data={required_data} referrer={'events'} chips_qty={chips_qty} amount={amount} currency={'USD'} showSpinner={false} parentCallback={handleCallback} />
                </PayPalScriptProvider>
                <IonRow className="display-flex ion-justify-content-center ion-text-center mt-25">
                    <button className='button-close bg-blue wg-bold' style={{width: "50%", height: "35px", borderRadius: "50px", fontSize: "16px"}} onClick={()=> {
                      setPaypalPayment(false);
                    }}>
                    Close
                    </button>
                </IonRow>
            </IonContent>
            </>        
            </IonModal>
          </IonContent>
    </IonPage>
  );
};

export default EventDetails;
