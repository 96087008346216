import { IonAvatar, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonRow, IonTabBar, IonTabButton, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { add, checkmarkCircle, checkmarkCircleOutline, chevronBackOutline, closeCircle} from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { collection, getDocs, getFirestore, limit, orderBy, query, where } from 'firebase/firestore';
import _ from 'lodash';
import firebase from '../../js/firebase';
import { createEntryWithoutId, updateEntry } from '../../js/firebase_doc_functions';
const db = getFirestore(firebase);


const Orders: React.FC = () => {
    const [orders_bottom_tab,setOrdersBottomTab] = useState<any>("New");
    const [orders, setOrders] = useState<any>();
    const getOrders = async() => {
        const q = query(collection(db, "ORDERS"), where('created_at', '>', 0), orderBy('created_at', 'desc'));
        const querySnapshot = await getDocs(q);
        if(querySnapshot.docs && querySnapshot.docs.length > 0) {
            await Promise.all(querySnapshot.docs.map(async login => {
                var doc_item = login.data();
                doc_item.id = login.id;
                return doc_item;
            }))
            .then(results => {
                var ordersObj = {
                    new_orders: results.filter(order=> order.status == 'Order Placed'),
                    accepted_orders: results.filter(order=> order.status == 'Order Accepted'),
                    completed_orders: results.filter(order=> order.status == 'Order Fulfilled'),
                    rejected_orders: results.filter(order=> order.rejected == true)
                }
                setOrders(ordersObj);
            });
        }
    }

    const acceptOrder = async (order_id:string, user_id:string, product_id:string)=> {     
        
        const user_query = query(collection(db, "USER_PROFILE"), where('user_id', '==', user_id));
        const userQuerySnapshot = await getDocs(user_query);
        var user_data = userQuerySnapshot.docs && userQuerySnapshot.docs.length > 0 ? userQuerySnapshot.docs[0].data() : {};
        user_data.id = userQuerySnapshot.docs && userQuerySnapshot.docs.length > 0 ? userQuerySnapshot.docs[0].id : ''; 

        const product_query = query(collection(db, "PRO_SHOP"), where('product_id', '==', product_id));
        const productQuerySnapshot = await getDocs(product_query);
        var product_data = productQuerySnapshot.docs && productQuerySnapshot.docs.length > 0 ? productQuerySnapshot.docs[0].data() : {};
        product_data.id = productQuerySnapshot.docs && productQuerySnapshot.docs.length > 0 ? productQuerySnapshot.docs[0].id : ''; 

        await updateEntry('ORDERS', order_id, {'status': 'Order Accepted'});
        await createEntryWithoutId("NOTIFICATIONS", {
            'type': 'ORDER_ACCEPTED',
            'created_name': user_data.first_name+' '+user_data.last_name,
            'created_by': user_data.user_id,
            'user_id': user_data.user_id,
            'user_name': user_data.first_name+' '+user_data.last_name,
            'source': {
                'product_id': product_id,
                'product_value': product_data.product_value,
                'product_name': product_data.product_name,
                'product_price': product_data.product_price,
                'product_image': product_data.product_images.image_1,
                'order_date': format(new Date(), "MM/DD/YYYY"),
                'order_value': product_data.product_value,
                'order_id': order_id
            },
            'is_best': false,
            'pack_id': '',
            'pack_name': '',
            'is_read': false
        });
        alert("Order accepted successfully");
    }

    const rejectOrder = async function(order_id:string, user_id:string, value:any, product_id:string) {               
        
        const user_query = query(collection(db, "USER_PROFILE"), where('user_id', '==', user_id));
        const userQuerySnapshot = await getDocs(user_query);
        var user_data = userQuerySnapshot.docs && userQuerySnapshot.docs.length > 0 ? userQuerySnapshot.docs[0].data() : {};
        user_data.id = userQuerySnapshot.docs && userQuerySnapshot.docs.length > 0 ? userQuerySnapshot.docs[0].id : ''; 

        const product_query = query(collection(db, "PRO_SHOP"), where('product_id', '==', product_id));
        const productQuerySnapshot = await getDocs(product_query);
        var product_data = productQuerySnapshot.docs && productQuerySnapshot.docs.length > 0 ? productQuerySnapshot.docs[0].data() : {};
        product_data.id = productQuerySnapshot.docs && productQuerySnapshot.docs.length > 0 ? productQuerySnapshot.docs[0].id : ''; 
            
        await updateEntry('USER_PROFILE', user_data.id, {'earnings': parseInt(user_data.earnings)+parseInt(value)});
        await updateEntry('ORDERS', order_id, {'status': 'Order Rejected', 'rejected': true});
        await createEntryWithoutId('USER_EARNINGS_TRANSACTIONS', {
            'user_id': user_data.user_id,
            'user_name': user_data.first_name+' '+user_data.last_name,
            'product_id': product_id,
            'product_value': product_data.product_value,
            'product_name': product_data.product_name,
            'product_price': product_data.product_price,
            'product_image': product_data.product_images.image_1,
            'order_date': format(new Date(), "MM/DD/YYYY"),
            'order_value': product_data.product_value,
            'type': 'Credit',
            'balance': parseInt(user_data.earnings)+parseInt(value)
        });
        await createEntryWithoutId("NOTIFICATIONS", {
            'type': 'ORDER_REJECTED',
            'created_name': user_data.first_name+' '+user_data.last_name,
            'created_by': user_data.user_data_id,
            'user_data_id': user_data.user_data_id,
            'user_data_name': user_data.first_name+' '+user_data.last_name,
            'source': {
                'product_id': product_id,
                'product_value': product_data.product_value,
                'product_name': product_data.product_name,
                'product_price': product_data.product_price,
                'product_image': product_data.product_images.image_1,
                'order_date': format(new Date(), "MM/DD/YYYY"),
                'order_value': product_data.product_value,
                'order_id': order_id
            },
            'is_best': false,
            'pack_id': '',
            'pack_name': '',
            'is_read': false
        });
        alert("Order rejected successfully");
    }

    const fulfillOrder = async function(order_id:string, user_id:string, product_id:string) {
        const user_query = query(collection(db, "USER_PROFILE"), where('user_id', '==', user_id));
        const userQuerySnapshot = await getDocs(user_query);
        var user_data = userQuerySnapshot.docs && userQuerySnapshot.docs.length > 0 ? userQuerySnapshot.docs[0].data() : {};
        user_data.id = userQuerySnapshot.docs && userQuerySnapshot.docs.length > 0 ? userQuerySnapshot.docs[0].id : ''; 

        const product_query = query(collection(db, "PRO_SHOP"), where('product_id', '==', product_id));
        const productQuerySnapshot = await getDocs(product_query);
        var product_data = productQuerySnapshot.docs && productQuerySnapshot.docs.length > 0 ? productQuerySnapshot.docs[0].data() : {};
        product_data.id = productQuerySnapshot.docs && productQuerySnapshot.docs.length > 0 ? productQuerySnapshot.docs[0].id : ''; 

        await updateEntry('ORDERS', order_id, {'status': 'Order Fulfilled'});
        await createEntryWithoutId("NOTIFICATIONS", {
            'type': 'ORDER_FULFILLED',
            'created_name': user_data.first_name+' '+user_data.last_name,
            'created_by': user_data.user_data_id,
            'user_data_id': user_data.user_data_id,
            'user_data_name': user_data.first_name+' '+user_data.last_name,
            'source': {
                'product_id': product_id,
                'product_value': product_data.product_value,
                'product_name': product_data.product_name,
                'product_price': product_data.product_price,
                'product_image': product_data.product_images.image_1,
                'order_date': format(new Date(), "MM/DD/YYYY"),
                'order_value': product_data.product_value,
                'order_id': order_id
            },
            'is_best': false,
            'pack_id': '',
            'pack_name': '',
            'is_read': false
        });
        alert("Order fulfilled");
    }

    useEffect(() => {
        getOrders();
    }, [])
    return (
        <IonPage>
        <IonHeader>
          <IonToolbar mode="ios">
            <IonButtons slot="start">
                <a onClick={()=> window.history.back()}>
                    <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                </a>
            </IonButtons>
            <IonTitle>
               All Orders
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
            {
                (orders_bottom_tab=="New")?(
                    <IonGrid>
                        {
                            orders && orders.new_orders.map((product: any, index:number) => {
                                return (
                                    <IonCard className="admin-orders-card">
                                        <IonItem lines="none" className="notification-item">
                                            <IonAvatar slot="start" style={{border:"1px solid gray",height:"45px",width:"45px",marginInline:"0px 13px"}}>
                                            <img src={product.product_image}/>
                                            </IonAvatar>
                                            <IonLabel>
                                                <IonText className="s-14 wg-600">{product.product_name}</IonText>
                                                <br/>
                                                <IonText className="s-13 wg-600">Ordered on: </IonText>
                                                <IonText className="s-13 wg-500">{format(new Date(product.order_date), "MM/dd/yyyy")}</IonText>
                                                <IonText className="s-13 wg-600">Price: </IonText>
                                                <IonText className="s-13 wg-500">{product.order_value}</IonText>
                                                <br/>
                                                <IonText className="s-13 wg-600">Ordered by: </IonText>
                                                <IonText className="s-13 wg-500">{product.user_name}</IonText>
                                                <br/>
                                                <IonRow>
                                                    <IonCol onClick={()=> acceptOrder(product.id, product.user_id, product.product_id)}>
                                                        <button className='button-close white tt-upper wg-bold bg-blue'>Accept</button>
                                                    </IonCol>
                                                    <IonCol onClick={()=> rejectOrder(product.id, product.user_id, product.product_value, product.product_id)}>
                                                        <button className='button-close white tt-upper wg-bold bg-red'>Reject</button>
                                                    </IonCol>
                                                </IonRow>
                                            </IonLabel>
                                        </IonItem>
                                    </IonCard>
                                )
                            })
                        }
                    </IonGrid>
    
                ):(orders_bottom_tab=="Accepted")?(
                    <IonGrid>
                        {
                            orders && orders.accepted_orders.map((product: any, index:number) => {
                                return (
                                    <IonCard className="admin-orders-card">
                                        <IonItem lines="none" className="notification-item">
                                        <IonAvatar slot="start" style={{border:"1px solid gray",height:"45px",width:"45px",marginInline:"0px 13px"}}>
                                        <img src={product.product_image}/>
                                        </IonAvatar>
                                        <IonLabel>
                                            <IonText className="s-14 wg-600">{product.product_name}</IonText>
                                            <br/>
                                            <IonText className="s-13 wg-600">Ordered on: </IonText>
                                            <IonText className="s-13 wg-500">{format(new Date(product.order_date), "MM/dd/yyyy")}</IonText>
                                            <IonText className="s-13 wg-600">Price: </IonText>
                                            <IonText className="s-13 wg-500">{product.order_value}</IonText>
                                            <br/>
                                            <IonText className="s-13 wg-600">Ordered by: </IonText>
                                            <IonText className="s-13 wg-500">{product.user_name}</IonText>
                                            <br/>
                                            <IonRow>
                                                <IonCol onClick={()=> fulfillOrder(product.id, product.user_id, product.product_id)}>
                                                    <button className='button-close white tt-upper wg-bold bg-blue'>Fulfilled</button>
                                                </IonCol>
                                                <IonCol onClick={()=> rejectOrder(product.id, product.user_id, product.product_value, product.product_id)}>
                                                    <button className='button-close white tt-upper wg-bold bg-red'>Reject</button>
                                                </IonCol>
                                            </IonRow>
                                        </IonLabel>
                                        </IonItem>
                                    </IonCard>
                                )
                            })
                        }
                    </IonGrid>
                ):(orders_bottom_tab=="Completed")?(
                    <IonGrid>
                        {
                            orders && orders.completed_orders.map((product: any, index:number) => {
                                return (
                                    <IonCard className="admin-orders-card">
                                        <IonItem lines="none" className="notification-item">
                                            <IonAvatar slot="start" style={{border:"1px solid gray",height:"45px",width:"45px",marginInline:"0px 13px"}}>
                                            <img src={product.product_image}/>
                                            </IonAvatar>
                                            <IonLabel>
                                                <IonText className="s-14 wg-600">{product.product_name}</IonText>
                                                <br/>
                                                <IonText className="s-13 wg-600">Ordered on: </IonText>
                                                <IonText className="s-13 wg-500">{format(new Date(product.order_date), "MM/dd/yyyy")}</IonText>
                                                <IonText className="s-13 wg-600">Price: </IonText>
                                                <IonText className="s-13 wg-500">{product.order_value}</IonText>
                                                <br/>
                                                <IonText className="s-13 wg-600">Ordered by: </IonText>
                                                <IonText className="s-13 wg-500">{product.user_name}</IonText>
                                            </IonLabel>
                                        </IonItem>
                                    </IonCard>
                                )
                            })
                        }
                    </IonGrid>
                ):(
                    <IonGrid>
                        {
                            orders && orders.rejected_orders.map((product: any, index:number) => {
                                return (
                                    <IonCard className="admin-orders-card">
                                        <IonItem lines="none" className="notification-item">
                                            <IonAvatar slot="start" style={{border:"1px solid gray",height:"45px",width:"45px",marginInline:"0px 13px"}}>
                                            <img src={product.product_image}/>
                                            </IonAvatar>
                                            <IonLabel>
                                                <IonText className="s-14 wg-600">{product.product_name}</IonText>
                                                <br/>
                                                <IonText className="s-13 wg-600">Ordered on: </IonText>
                                                <IonText className="s-13 wg-500">{format(new Date(product.order_date), "MM/dd/yyyy")}</IonText>
                                                <IonText className="s-13 wg-600">Price: </IonText>
                                                <IonText className="s-13 wg-500">{product.order_value}</IonText>
                                                <br/>
                                                <IonText className="s-13 wg-600">Ordered by: </IonText>
                                                <IonText className="s-13 wg-500">{product.user_name}</IonText>
                                            </IonLabel>
                                        </IonItem>
                                    </IonCard>
                                )
                            })
                        }
                    </IonGrid>
                )
            }

        </IonContent>
        <IonTabBar slot="bottom">
            <IonTabButton tab="new" className={(orders_bottom_tab==="New")?'tab-selected':''}>
                <IonIcon icon={add} onClick={()=>{
                    setOrdersBottomTab("New")
                }}/>
                <IonLabel onClick={()=>{
                    setOrdersBottomTab("New")
                }}>New</IonLabel>
            </IonTabButton>
            <IonTabButton tab="accepted" className={(orders_bottom_tab==="Accepted")?'tab-selected':''}>
                <IonIcon icon={checkmarkCircleOutline} onClick={()=>{
                    setOrdersBottomTab("Accepted")
                }}/>
                <IonLabel onClick={()=>{
                    setOrdersBottomTab("Accepted")
                }}>Accepted</IonLabel>
            </IonTabButton>
            <IonTabButton tab="completed" className={(orders_bottom_tab==="Completed")?'tab-selected':''}>
                <IonIcon icon={checkmarkCircle} onClick={()=>{
                    setOrdersBottomTab("Completed")
                }}/>
                <IonLabel onClick={()=>{
                    setOrdersBottomTab("Completed")
                }}>Completed</IonLabel>
            </IonTabButton>
            <IonTabButton tab="rejected" className={(orders_bottom_tab==="Rejected")?'tab-selected':''}>
                <IonIcon icon={closeCircle} onClick={()=>{
                    setOrdersBottomTab("Rejected")
                }}/>
                <IonLabel onClick={()=>{
                    setOrdersBottomTab("Rejected")
                }}>Rejected</IonLabel>
            </IonTabButton>
        </IonTabBar>

        </IonPage>
    )
};

export default Orders;