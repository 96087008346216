import { IonPage, IonHeader, IonToolbar, IonButtons, IonIcon, IonTitle, IonSegment, IonSegmentButton, IonLabel, IonContent, IonCard, IonCardContent, IonCardHeader, IonCol, IonRow, IonText, IonFab, IonFabButton, IonFabList } from '@ionic/react';
import { getFirestore } from 'firebase/firestore';
import { chevronBackOutline, peopleOutline, add } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import {
    ChatLeftText,
    Heart,
  } from '@styled-icons/bootstrap';    
import {
    Delete
} from '@styled-icons/material-rounded';
import firebase from '../../js/firebase';
const db = getFirestore(firebase);

const Feed: React.FC = () => {
    const [feed_tab,setFeedTab] = useState<any>("Community")
    useEffect(() => {
        
    }, [])
    return (
        <IonPage>
        <IonHeader>
          <IonToolbar mode="ios">
            <IonButtons slot="start">
                <a onClick={()=> window.history.back()}>
                    <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                </a>
            </IonButtons>
            <IonTitle>
               News Feed
            </IonTitle>
          </IonToolbar>
          <IonToolbar mode="ios" className="segment-toolbar">
            <IonSegment onIonChange={(e:any) => {                                
                    setFeedTab(e.detail.value)                                
                    }} value={feed_tab} mode="ios">
                    <IonSegmentButton value="Community" className="max-wid-toolbar" style={{minWidth:"25%"}}>
                    <IonLabel>Community</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="Member Zone" className="max-wid-toolbar" style={{minWidth:"30%"}}>
                    <IonLabel>Member Zone</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="My Packs" className="max-wid-toolbar" style={{minWidth:"25%"}}>
                    <IonLabel>My Packs</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="Region" className="max-wid-toolbar" style={{minWidth:"20%"}}>
                    <IonLabel>Region</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            {
                (feed_tab=="Community")?(
                    <IonCard className="social-card">
                    <IonCardHeader className="card-header">
                      <IonRow className="pd-5">
                        <IonCol size="2">
                          <div>
                            {/* {(feed.user_image == '' || feed.user_image == undefined)?(
                              <IonAvatar slot="start" className="card-avatar">
                                <img src={default_eclub_logo} />
                              </IonAvatar>
                            ):(
                              <IonAvatar slot="start" className="card-avatar">
                                <img src={feed.user_image} />
                              </IonAvatar>
                            )} */}
                          </div>
                        </IonCol>
                        <IonCol size="8" className="user-details">
                          <IonLabel>
                            {/* <h4>{feed.user_name}</h4>
                            <p>{format(feed.created_at, 'MMM dd, yyyy HH:mm')}</p> */}
                          </IonLabel>
                        </IonCol>
                        <IonCol size="2" className="card-options">
                          {/* {
                            (feed.own_content)?(
                              <IonRow onClick={()=> {
                                //   openActionsheet(feed.id, "My Club")
                                  }}>
                                <IonIcon icon={ellipsisVerticalCircleOutline} className="edit-trash"/>
                              </IonRow>
                            ):(
                              <></>
                            )
                          } */}
                        </IonCol>
                      </IonRow>                    
                    </IonCardHeader>
                    <IonCardContent className="card-content">
                      {/* {feed.post_text}
                      {feed.post_url}
                      <div dangerouslySetInnerHTML={{
                          __html: feed.post_content
                        }} style={{marginBottom: '10px'}}>
                      </div>
                      {(feed.post_image == '' || feed.post_image == undefined)?(
                          <></>
                        ):(
                          <img src={feed.post_image} />
                        )
                      } */}
                    </IonCardContent>
                    <IonRow className="display-flex card-footer">
                      <a>
                        <Heart className="card-like-icon"/>
                        <IonLabel onClick={()=>{
                        //   loadLikesData(feed.feed_id);
                        }}>                   
                          <IonText className="card-like-text"> like(s)</IonText>
                        </IonLabel>
                      </a>
                      <a>
                        <Delete className="card-delete-icon"/>
                        <IonLabel onClick={()=>{
                        //   loadLikesData(feed.feed_id);
                        }}>                   
                          <IonText className=""> Delete</IonText>
                        </IonLabel>
                      </a>
                      <a>
                        <ChatLeftText className="card-comment-icon"/>
                        <IonLabel onClick={()=>{
                        //   loadCommentsData(feed.feed_id, "My Club");
                        }}>                       
                        <IonText className="card-comment"> comment(s)</IonText>
                        </IonLabel>
                      </a>
                    </IonRow>
                  </IonCard>

                ):(feed_tab=="Member Zone")?(
                    <IonCard className="social-card">
                    <IonCardHeader className="card-header">
                      <IonRow className="pd-5">
                        <IonCol size="2">
                          <div>
                            {/* {(feed.user_image == '' || feed.user_image == undefined)?(
                              <IonAvatar slot="start" className="card-avatar">
                                <img src={default_eclub_logo} />
                              </IonAvatar>
                            ):(
                              <IonAvatar slot="start" className="card-avatar">
                                <img src={feed.user_image} />
                              </IonAvatar>
                            )} */}
                          </div>
                        </IonCol>
                        <IonCol size="8" className="user-details">
                          <IonLabel>
                            {/* <h4>{feed.user_name}</h4>
                            <p>{format(feed.created_at, 'MMM dd, yyyy HH:mm')}</p> */}
                          </IonLabel>
                        </IonCol>
                        <IonCol size="2" className="card-options">
                          {/* {
                            (feed.own_content)?(
                              <IonRow onClick={()=> {
                                //   openActionsheet(feed.id, "My Club")
                                  }}>
                                <IonIcon icon={ellipsisVerticalCircleOutline} className="edit-trash"/>
                              </IonRow>
                            ):(
                              <></>
                            )
                          } */}
                        </IonCol>
                      </IonRow>                    
                    </IonCardHeader>
                    <IonCardContent className="card-content">
                      {/* {feed.post_text}
                      {feed.post_url}
                      <div dangerouslySetInnerHTML={{
                          __html: feed.post_content
                        }} style={{marginBottom: '10px'}}>
                      </div>
                      {(feed.post_image == '' || feed.post_image == undefined)?(
                          <></>
                        ):(
                          <img src={feed.post_image} />
                        )
                      } */}
                    </IonCardContent>
                    <IonRow className="display-flex card-footer">
                      <a>
                        <Heart className="card-like-icon"/>
                        <IonLabel onClick={()=>{
                        //   loadLikesData(feed.feed_id);
                        }}>                   
                          <IonText className="card-like-text"> like(s)</IonText>
                        </IonLabel>
                      </a>
                      <a>
                        <Delete className="card-delete-icon"/>
                        <IonLabel onClick={()=>{
                        //   loadLikesData(feed.feed_id);
                        }}>                   
                          <IonText className=""> Delete</IonText>
                        </IonLabel>
                      </a>
                      <a>
                        <ChatLeftText className="card-comment-icon"/>
                        <IonLabel onClick={()=>{
                        //   loadCommentsData(feed.feed_id, "My Club");
                        }}>                       
                        <IonText className="card-comment"> comment(s)</IonText>
                        </IonLabel>
                      </a>
                    </IonRow>
                  </IonCard>
                ):(feed_tab=="My Packs")?(
                    <IonCard className="social-card">
                    <IonCardHeader className="card-header">
                      <IonRow className="pd-5">
                        <IonCol size="2">
                          <div>
                            {/* {(feed.user_image == '' || feed.user_image == undefined)?(
                              <IonAvatar slot="start" className="card-avatar">
                                <img src={default_eclub_logo} />
                              </IonAvatar>
                            ):(
                              <IonAvatar slot="start" className="card-avatar">
                                <img src={feed.user_image} />
                              </IonAvatar>
                            )} */}
                          </div>
                        </IonCol>
                        <IonCol size="8" className="user-details">
                          <IonLabel>
                            {/* <h4>{feed.user_name}</h4>
                            <p>{format(feed.created_at, 'MMM dd, yyyy HH:mm')}</p> */}
                          </IonLabel>
                        </IonCol>
                        <IonCol size="2" className="card-options">
                          {/* {
                            (feed.own_content)?(
                              <IonRow onClick={()=> {
                                //   openActionsheet(feed.id, "My Club")
                                  }}>
                                <IonIcon icon={ellipsisVerticalCircleOutline} className="edit-trash"/>
                              </IonRow>
                            ):(
                              <></>
                            )
                          } */}
                        </IonCol>
                      </IonRow>                    
                    </IonCardHeader>
                    <IonCardContent className="card-content">
                      {/* {feed.post_text}
                      {feed.post_url}
                      <div dangerouslySetInnerHTML={{
                          __html: feed.post_content
                        }} style={{marginBottom: '10px'}}>
                      </div>
                      {(feed.post_image == '' || feed.post_image == undefined)?(
                          <></>
                        ):(
                          <img src={feed.post_image} />
                        )
                      } */}
                    </IonCardContent>
                    <IonRow className="display-flex card-footer">
                      <a>
                        <Heart className="card-like-icon"/>
                        <IonLabel onClick={()=>{
                        //   loadLikesData(feed.feed_id);
                        }}>                   
                          <IonText className="card-like-text"> like(s)</IonText>
                        </IonLabel>
                      </a>
                      <a>
                        <Delete className="card-delete-icon"/>
                        <IonLabel onClick={()=>{
                        //   loadLikesData(feed.feed_id);
                        }}>                   
                          <IonText className=""> Delete</IonText>
                        </IonLabel>
                      </a>
                      <a>
                        <ChatLeftText className="card-comment-icon"/>
                        <IonLabel onClick={()=>{
                        //   loadCommentsData(feed.feed_id, "My Club");
                        }}>                       
                        <IonText className="card-comment"> comment(s)</IonText>
                        </IonLabel>
                      </a>
                    </IonRow>
                  </IonCard>
                ):(
                    <IonCard className="social-card">
                    <IonCardHeader className="card-header">
                      <IonRow className="pd-5">
                        <IonCol size="2">
                          <div>
                            {/* {(feed.user_image == '' || feed.user_image == undefined)?(
                              <IonAvatar slot="start" className="card-avatar">
                                <img src={default_eclub_logo} />
                              </IonAvatar>
                            ):(
                              <IonAvatar slot="start" className="card-avatar">
                                <img src={feed.user_image} />
                              </IonAvatar>
                            )} */}
                          </div>
                        </IonCol>
                        <IonCol size="8" className="user-details">
                          <IonLabel>
                            {/* <h4>{feed.user_name}</h4>
                            <p>{format(feed.created_at, 'MMM dd, yyyy HH:mm')}</p> */}
                          </IonLabel>
                        </IonCol>
                        <IonCol size="2" className="card-options">
                          {/* {
                            (feed.own_content)?(
                              <IonRow onClick={()=> {
                                //   openActionsheet(feed.id, "My Club")
                                  }}>
                                <IonIcon icon={ellipsisVerticalCircleOutline} className="edit-trash"/>
                              </IonRow>
                            ):(
                              <></>
                            )
                          } */}
                        </IonCol>
                      </IonRow>                    
                    </IonCardHeader>
                    <IonCardContent className="card-content">
                      {/* {feed.post_text}
                      {feed.post_url}
                      <div dangerouslySetInnerHTML={{
                          __html: feed.post_content
                        }} style={{marginBottom: '10px'}}>
                      </div>
                      {(feed.post_image == '' || feed.post_image == undefined)?(
                          <></>
                        ):(
                          <img src={feed.post_image} />
                        )
                      } */}
                    </IonCardContent>
                    <IonRow className="display-flex card-footer">
                      <a>
                        <Heart className="card-like-icon"/>
                        <IonLabel onClick={()=>{
                        //   loadLikesData(feed.feed_id);
                        }}>                   
                          <IonText className="card-like-text"> like(s)</IonText>
                        </IonLabel>
                      </a>
                      <a>
                        <Delete className="card-delete-icon"/>
                        <IonLabel onClick={()=>{
                        //   loadLikesData(feed.feed_id);
                        }}>                   
                          <IonText className=""> Delete</IonText>
                        </IonLabel>
                      </a>
                      <a>
                        <ChatLeftText className="card-comment-icon"/>
                        <IonLabel onClick={()=>{
                        //   loadCommentsData(feed.feed_id, "My Club");
                        }}>                       
                        <IonText className="card-comment"> comment(s)</IonText>
                        </IonLabel>
                      </a>
                    </IonRow>
                  </IonCard>
                )
            }
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton className="main-fab">
            <IonIcon icon={add} />
          </IonFabButton>
          <IonFabList side="top" className="compete-fab-list">
            <IonFabButton className="pack-post-fab" onClick={()=>{
               window.location.href="/join_pack"
            }}>
              <IonIcon icon={peopleOutline} style={{color:"white"}}/>
              <IonText className="white pl-5">Post to Pack</IonText>
            </IonFabButton>
            <IonFabButton className="community-post-fab" onClick={()=>{
               window.location.href="/create_post"
            }}>
              <IonIcon icon={peopleOutline} style={{color:"white"}}/>
              <IonText className="white pl-5">Post to Community</IonText>
            </IonFabButton>
            <IonFabButton className="member-post-fab" onClick={()=>{
               window.location.href="/create_post"
            }}>
              <IonIcon icon={peopleOutline} style={{color:"white"}}/>
              <IonText className="white pl-5">Post to Member Zone</IonText>
            </IonFabButton>
          </IonFabList>
        </IonFab>

        </IonContent>
        </IonPage>
    )
};

export default Feed;