import { IonGrid, IonRow, IonCol, IonText,useIonToast } from '@ionic/react';
import { useEffect, useState } from 'react';
import { getFirestore, collection, query, where, orderBy, getDocs } from "firebase/firestore";
import firebase from '../../js/firebase.js';

import { getEntry, updateEntry } from '../../js/firebase_doc_functions';
interface Props{
    options: any,
    holes: any,
    front9: any,
    back9: any,
}

const db = getFirestore(firebase);
const Table: React.FC<Props> = (props) => {
    
    let obj = props.options
    const [val,setVal] = useState<any>();
    const front9 = props.front9;
    const back9 = props.back9;
    const holes = props.holes;
    const [front_par_total, setFrontParTotal] = useState<any>();
    const [front_score_total, setFrontScoreTotal] = useState<any>();
    const [front_points_total, setFrontPointsTotal] = useState<any>();
    const [back_par_total, setBackParTotal] = useState<any>();
    const [back_score_total, setBackScoreTotal] = useState<any>();
    const [back_points_total, setBackPointsTotal] = useState<any>();
    const [showToast, hideToast] = useIonToast();
    const setTotalValues = async(data:any)=> {
        let front_pars = 0;
        let front_scores = 0;
        let front_points = 0;
        let back_pars = 0;
        let back_scores = 0;
        let back_points = 0;
        data && data.hole_details.forEach((key:any,i:number)=>{
            if(key.hole_number<=9){
                
                front_pars += key.par
                
                front_scores += key.raw_score
                
                front_points += key.points
                
            } else {
                back_pars += key.par
                
                back_scores += key.raw_score
                
                back_points += key.points
            }
        });
        setFrontParTotal(front_pars);
        setFrontScoreTotal(front_scores);
        setFrontPointsTotal(front_points);
        setBackParTotal(back_pars);
        setBackScoreTotal(back_scores);
        setBackPointsTotal(back_points);
    }
    const attestScore = async (id1: string, id2: string,current_user:any) => {
        const docSnapshot1 = await getEntry("TAO_COMPETITIONS", id1);
        const docSnapshot2 = await getEntry("TAO_COMPETITIONS", id2);
        const user1Query = query(collection(db, "USER_PROFILE"), where("user_id", "==", docSnapshot1.user_id));
        const querySnapshot1 = await getDocs(user1Query);
        if (querySnapshot1.docs && querySnapshot1.docs.length > 0) {
            docSnapshot1.user = querySnapshot1.docs[0].data();
            docSnapshot1.user.id = querySnapshot1.docs[0].id;
        }
        const user2Query = query(collection(db, "USER_PROFILE"), where("user_id", "==", docSnapshot2.user_id));
        const querySnapshot2 = await getDocs(user2Query);
        if (querySnapshot2.docs && querySnapshot2.docs.length > 0) {
            docSnapshot2.user = querySnapshot2.docs[0].data();
            docSnapshot2.user.id = querySnapshot2.docs[0].id;
        }
        if (docSnapshot1.is_score_added && !docSnapshot2.is_score_added) {
            if (docSnapshot2.net_score != undefined) {
                await updateEntry("TAO_COMPETITIONS", id2, { is_score_added: true });
                if (docSnapshot1.net_score > docSnapshot2.net_score) {
                    docSnapshot1.result = "Won";
                    docSnapshot1.round_points = 4;
                    if ((docSnapshot1.net_score - docSnapshot2.net_score) < 3) {
                        docSnapshot1.round_points = docSnapshot1.round_points;
                    } else if ((docSnapshot1.net_score - docSnapshot2.net_score) >= 3 && (docSnapshot1.net_score - docSnapshot2.net_score) <= 5) {
                        docSnapshot1.round_points = docSnapshot1.round_points + 1;
                    } else if ((docSnapshot1.net_score - docSnapshot2.net_score) >= 6 && (docSnapshot1.net_score - docSnapshot2.net_score) <= 9) {
                        docSnapshot1.round_points = docSnapshot1.round_points + 2;
                    } else if ((docSnapshot1.net_score - docSnapshot2.net_score) > 9) {
                        docSnapshot1.round_points = docSnapshot1.round_points + 3;
                    }
                    docSnapshot2.result = "Lost";
                    docSnapshot2.round_points = 0;
                    await updateEntry("USER_PROFILE", docSnapshot1.user.id, { earnings: parseInt(docSnapshot1.user.earnings) + parseInt(docSnapshot1.price) + parseInt(docSnapshot2.price) });
                } else if (docSnapshot2.net_score > docSnapshot1.net_score) {
                    docSnapshot1.result = "Lost";
                    docSnapshot1.round_points = 0;
                    docSnapshot2.result = "Won";
                    docSnapshot2.round_points = 4;
                    if ((docSnapshot2.net_score - docSnapshot1.net_score) < 3) {
                        docSnapshot2.round_points = docSnapshot2.round_points;
                    } else if ((docSnapshot2.net_score - docSnapshot1.net_score) >= 3 && (docSnapshot2.net_score - docSnapshot1.net_score) <= 5) {
                        docSnapshot2.round_points = docSnapshot2.round_points + 1;
                    } else if ((docSnapshot2.net_score - docSnapshot1.net_score) >= 6 && (docSnapshot2.net_score - docSnapshot1.net_score) <= 9) {
                        docSnapshot2.round_points = docSnapshot2.round_points + 2;
                    } else if ((docSnapshot2.net_score - docSnapshot1.net_score) > 9) {
                        docSnapshot2.round_points = docSnapshot2.round_points + 3;
                    }

                    await updateEntry("USER_PROFILE", docSnapshot2.user.id, { earnings: parseInt(docSnapshot2.user.earnings) + parseInt(docSnapshot1.price) + parseInt(docSnapshot2.price) });
                } else if (docSnapshot1.net_score == docSnapshot2.net_score) {
                    docSnapshot1.result = "Even";
                    docSnapshot1.round_points = 2;
                    docSnapshot2.result = "Even";
                    docSnapshot2.round_points = 2;

                    await updateEntry("USER_PROFILE", docSnapshot2.user.id, { earnings: parseInt(docSnapshot2.user.earnings) + parseInt(docSnapshot2.price) });
                    await updateEntry("USER_PROFILE", docSnapshot1.user.id, { earnings: parseInt(docSnapshot1.user.earnings) + parseInt(docSnapshot1.price) });
                }
                await updateEntry("TAO_COMPETITIONS", id1, { "is_score_added": true, result: docSnapshot1.result, round_points: docSnapshot1.round_points, net_score: docSnapshot1.net_score });
                await updateEntry("TAO_COMPETITIONS", id2, { result: docSnapshot2.result, round_points: docSnapshot2.round_points, net_score: docSnapshot2.net_score });
                showToast('Thank you for your attestation.', 2000);
            } else {
                showToast(docSnapshot2.user_name + " didn’t post score yet.", 2000);
            }

        } else if (!docSnapshot1.is_score_added && docSnapshot2.is_score_added) {
            if (docSnapshot2.net_score != undefined) {
                await updateEntry("TAO_COMPETITIONS", id2, { is_score_added: true });
            } else {
                showToast(docSnapshot2.user_name + " didn’t post score yet.", 2000);
            }
        } else if (!docSnapshot1.is_score_added && !docSnapshot2.is_score_added) {
            if (current_user.user_id === docSnapshot1.user_id) {
                if (!docSnapshot2.is_score_added && docSnapshot2.net_score == undefined) {
                    showToast(docSnapshot2.user_name + " didn’t post score yet.", 2000);
                } else if (!docSnapshot2.is_score_added && docSnapshot2.net_score != undefined) {
                    await updateEntry("TAO_COMPETITIONS", id2, { is_score_added: true });
                    showToast('Thank you for your attestation.', 2000);
                }
            }
        }
        setTimeout(()=> window.location.href = "/netscore_challenge_checkins",2000);
       
    }
    useEffect(() => {
        setVal(obj);
        setTotalValues(obj);
    }, [])
    const [val2,setVal2] = useState<any>(["Eagle","Birdie","Par","Bogey","Double","Triple"])
    
    return (
        <>
        <IonGrid className="">
        <IonRow className="ion-justify-content-center ion-text-center mt-10">
          <IonText className="black s-18">{val && val.user_name}</IonText>
        </IonRow>
        
      </IonGrid>

        <IonGrid>
            {
                (holes == 9 && front9 == true) ? (
                <>
                <IonRow className="ion-justify-content-center ion-text-center">
                    <IonText className="wg-600 black">Front 9</IonText>
                </IonRow>
                <IonRow className="points-table-row-color s-14">
                    <IonCol size="2" style={{color:"black"}}>
                        Hole
                    </IonCol>
                    {
                        val && val.hole_details.map((key:any,i:number)=>{
                            // console.log("key",key)
                            if(key.hole_number<=9){
                                return(
                                    <IonCol size="1" style={{color:"black"}} key={i}>
                                        {key.hole_number}
                                    </IonCol>
                                )
                            }
                        })
                    }
                    <IonCol size="1" style={{color:"black"}}>
                        Tot
                    </IonCol>
                </IonRow>    
                {
                    (val && val.format != "3~Intl Peoria") ? (
                        <IonRow className="points-table-row-color s-14">
                            <IonCol size="2" style={{color:"black"}}>
                                Par
                            </IonCol>
                            {
                                val && val.hole_details.map((key:any,i:number)=>{
                                    // console.log("key",key)
                                    if(key.hole_number<=9){
                                        return(
                                            <IonCol size="1" style={{color:"black"}} key={i}>
                                                {key.par}
                                            </IonCol>
                                        )
                                    }
                                })
                            }
                            <IonCol size="1" style={{color:"black", paddingInlineStart: "0",paddingInlineEnd: "0"}}>
                                {front_par_total}
                            </IonCol>
                        </IonRow>    
                    ):(
                        <></>
                    )
                }
                <IonRow className="points-table-row-app-color s-14">
                    <IonCol size="2" style={{color:"white"}}>
                        {(val && val.format == "3~Intl Peoria") ? "Strokes" : "Score" }
                    </IonCol>
                    {
                        val && val.hole_details.map((key:any,i:number)=>{
                            // console.log("key",key)
                            if(key.hole_number<=9){
                                return(
                                    <IonCol size="1" style={{color:"white"}} key={i}>
                                        {
                                            (val.format != "3~Intl Peoria" && key.raw_score==0) ?(
                                                <>
                                                _
                                                </>
                                            ) : (val.format == "3~Intl Peoria" && key.raw_score==4) ? (
                                                <>
                                                _
                                                </>
                                            ):(
                                                <>
                                                 {
                                                        key.raw_score - key.par > 2 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', textDecoration: 'underline', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == 2 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', borderStyle: 'double', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == 1 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', border: '1px solid #fff', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == 0 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', color: '#fff' }}>
                                                                {val.format == "3~Intl Peoria" ? "E" : key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == -1 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px',border: '1px solid #fff' , color: '#fff',borderRadius: '50%' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == -2 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', background: '#fff', color: '#00365f', borderRadius: '50%' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par <= -3 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', background: '#fff', color: '#00365f', borderRadius: '50%' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                        {/* <IonInput
                                            type="number"
                                            value={key.raw_score}                                        
                                            onIonChange={(e) => Edit_row(parseInt(e.detail.value!, 10),key.hole_number)}
                                            ></IonInput> */}
                                    </IonCol>
                                )
                            }
                        })
                    }
                    <IonCol size="1" style={{color:"white"}}>
                        {front_score_total}
                    </IonCol>
                </IonRow>    
                <IonRow className="points-table-row-blank s-14">
                    <IonCol size="2" style={{color:"black"}}>
                        Points
                    </IonCol>
                    {
                        val && val.hole_details.map((key:any,i:number)=>{
                            // console.log("key",key)
                            if(key.hole_number<=9){
                                return(
                                    <IonCol size="1" style={{color:"black",maxWidth: "100% !important",width: "100% !important"}} key={i}>
                                        {key.points}
                                    </IonCol>
                                )
                            }
                        })
                    }
                    <IonCol size="1" style={{color:"black"}}>
                        {front_points_total}
                    </IonCol>
                </IonRow> 
                </>
                ) : (holes == 9 && back9 == true) ? (
                <>
                <IonRow className="ion-justify-content-center ion-text-center mt-10">
                    <IonText className="wg-600 black">Back 9</IonText>
                </IonRow>
                <IonRow className="points-table-row-color s-14">
                    <IonCol size="2" style={{color:"black"}}>
                        Hole
                    </IonCol>
                    {
                        val && val.hole_details.map((key:any,i:number)=>{
                            // console.log("key",key)
                            if(key.hole_number>9){
                                return(
                                    <IonCol size="1" style={{color:"black"}} key={i}>
                                        {key.hole_number}
                                    </IonCol>
                                )
                            }
                        })
                    }
                    <IonCol size="1" style={{color:"black"}}>
                        Tot
                    </IonCol>
                </IonRow>    
                <IonRow className="points-table-row-color s-14">
                    <IonCol size="2" style={{color:"black"}}>
                        Par
                    </IonCol>
                    {
                        val && val.hole_details.map((key:any,i:number)=>{
                            // console.log("key",key)
                            if(key.hole_number>9){
                                return(
                                    <IonCol size="1" style={{color:"black"}} key={i}>
                                        {key.par}
                                    </IonCol>
                                )
                            }
                        })
                    }
                    <IonCol size="1" style={{color:"black"}}>
                        {back_par_total}
                    </IonCol>
                </IonRow>    
                <IonRow className="points-table-row-app-color s-14">
                    <IonCol size="2" style={{color:"white"}}>
                        Score
                    </IonCol>
                    {
                        val && val.hole_details.map((key:any,i:number)=>{
                            // console.log("key",key)
                            if(key.hole_number>9){
                                return(
                                    <IonCol size="1" style={{color:"white"}} key={i}>
                                        {
                                            (key.raw_score==0)?(
                                                <>
                                                _
                                                </>
                                            ):(
                                                <>
                                                  {
                                                        key.raw_score - key.par > 2 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', textDecoration: 'underline', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == 2 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', borderStyle: 'double', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == 1 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', border: '1px solid #fff', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == 0 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == -1 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px',border: '1px solid #fff' , color: '#fff',borderRadius: '50%' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == -2 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', background: '#fff', color: '#00365f', borderRadius: '50%' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par <= -3 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', background: '#fff', color: '#00365f', borderRadius: '50%' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                        {/* <IonInput
                                            type="number"
                                            value={key.raw_score}                                        
                                            onIonChange={(e) => Edit_row(parseInt(e.detail.value!, 10),key.hole_number)}
                                            ></IonInput> */}
                                    </IonCol>
                                )
                            }
                        })
                    }
                    <IonCol size="1" style={{color:"white"}}>
                        {back_score_total}
                    </IonCol>
                </IonRow>    
                <IonRow className="points-table-row-blank s-14">
                    <IonCol size="2" style={{color:"black"}}>
                        Points
                    </IonCol>
                    {
                        val && val.hole_details.map((key:any,i:number)=>{
                            // console.log("key",key)
                            if(key.hole_number>9){
                                return(
                                    <IonCol size="1" style={{color:"black",maxWidth: "100% !important",width: "100% !important"}} key={i}>
                                        {key.points}
                                    </IonCol>
                                )
                            }
                        })
                    }
                    <IonCol size="1" style={{color:"black", paddingInlineStart: "0",paddingInlineEnd: "0"}}>
                        {back_points_total}
                    </IonCol>
                </IonRow>   
                </> 
                ) : (
                    <>
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <IonText className="wg-600 black">Front 9</IonText>
                    </IonRow>
                    <IonRow className="points-table-row-color s-14">
                        <IonCol size="2" style={{color:"black"}}>
                            Hole
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number<=9){
                                    return(
                                        <IonCol size="1" style={{color:"black"}} key={i}>
                                            {key.hole_number}
                                        </IonCol>
                                    )
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"black"}}>
                            Tot
                        </IonCol>
                    </IonRow>    
                    <IonRow className="points-table-row-color s-14">
                        <IonCol size="2" style={{color:"black"}}>
                            Par
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number<=9){
                                    return(
                                        <IonCol size="1" style={{color:"black"}} key={i}>
                                            {key.par}
                                        </IonCol>
                                    )
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"black", paddingInlineStart: "0",paddingInlineEnd: "0"}}>
                            {front_par_total}
                        </IonCol>
                    </IonRow>    
                    <IonRow className="points-table-row-app-color s-14">
                        <IonCol size="2" style={{color:"white"}}>
                            Score
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number<=9){
                                    return(
                                        <IonCol size="1" style={{color:"white"}} key={i}>
                                            {
                                                (key.raw_score==0)?(
                                                    <>
                                                    _
                                                    </>
                                                ):(
                                                    <>
                                                     {
                                                        key.raw_score - key.par > 2 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', textDecoration: 'underline', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == 2 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', borderStyle: 'double', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == 1 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', border: '1px solid #fff', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == 0 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == -1 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px',border: '1px solid #fff' , color: '#fff',borderRadius: '50%' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == -2 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', background: '#fff', color: '#00365f', borderRadius: '50%' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par <= -3 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', background: '#fff', color: '#00365f', borderRadius: '50%' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    </>
                                                )
                                            }
                                            {/* <IonInput
                                                type="number"
                                                value={key.raw_score}                                        
                                                onIonChange={(e) => Edit_row(parseInt(e.detail.value!, 10),key.hole_number)}
                                                ></IonInput> */}
                                        </IonCol>
                                    )
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"white"}}>
                            {front_score_total}
                        </IonCol>
                    </IonRow>    
                    <IonRow className="points-table-row-blank s-14">
                        <IonCol size="2" style={{color:"black"}}>
                            Points
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number<=9){
                                    return(
                                        <IonCol size="1" style={{color:"black",maxWidth: "100% !important",width: "100% !important"}} key={i}>
                                            {key.points}
                                        </IonCol>
                                    )
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"black"}}>
                            {front_points_total}
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-justify-content-center ion-text-center mt-10">
                        <IonText className="wg-600 black">Back 9</IonText>
                    </IonRow>
                    <IonRow className="points-table-row-color s-14">
                        <IonCol size="2" style={{color:"black"}}>
                            Hole
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number>9){
                                    return(
                                        <IonCol size="1" style={{color:"black"}} key={i}>
                                            {key.hole_number}
                                        </IonCol>
                                    )
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"black"}}>
                            Tot
                        </IonCol>
                    </IonRow>    
                    <IonRow className="points-table-row-color s-14">
                        <IonCol size="2" style={{color:"black"}}>
                            Par
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number>9){
                                    return(
                                        <IonCol size="1" style={{color:"black"}} key={i}>
                                            {key.par}
                                        </IonCol>
                                    )
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"black"}}>
                            {back_par_total}
                        </IonCol>
                    </IonRow>    
                    <IonRow className="points-table-row-app-color s-14">
                        <IonCol size="2" style={{color:"white"}}>
                            Score
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number>9){
                                    return(
                                        <IonCol size="1" style={{color:"white"}} key={i}>
                                            {
                                                (key.raw_score==0)?(
                                                    <>
                                                    _
                                                    </>
                                                ):(
                                                    <>
                                                    {
                                                        key.raw_score - key.par > 2 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', textDecoration: 'underline', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == 2 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', borderStyle: 'double', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == 1 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', border: '1px solid #fff', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == 0 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == -1 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px',border: '1px solid #fff' , color: '#fff',borderRadius: '50%' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == -2 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', background: '#fff', color: '#00365f', borderRadius: '50%' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par <= -3 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', background: '#fff', color: '#00365f', borderRadius: '50%' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    </>
                                                )
                                            }
                                            {/* <IonInput
                                                type="number"
                                                value={key.raw_score}                                        
                                                onIonChange={(e) => Edit_row(parseInt(e.detail.value!, 10),key.hole_number)}
                                                ></IonInput> */}
                                        </IonCol>
                                    )
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"white"}}>
                            {back_score_total}
                        </IonCol>
                    </IonRow>    
                    <IonRow className="points-table-row-blank s-14">
                        <IonCol size="2" style={{color:"black"}}>
                            Points
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number>9){
                                    return(
                                        <IonCol size="1" style={{color:"black",maxWidth: "100% !important",width: "100% !important"}} key={i}>
                                            {key.points}
                                        </IonCol>
                                    )
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"black", paddingInlineStart: "0",paddingInlineEnd: "0"}}>
                            {back_points_total}
                        </IonCol>
                    </IonRow>   
                    </> 
                )
            }

            <br/>
            <br/>
            <IonRow className="ion-justify-content-center ion-text-center" style={{width:"85%",margin:"auto",borderBottom:"0.5px  solid black"}}>
               {
                   val2 && val2.map((key:any,i:number)=>{
                       return(
                        <IonCol size="2" key={i}>
                            <IonText className="s-11">{key}</IonText>
                        </IonCol>

                       )
 
                   })
               }
                        <IonCol size="2">
                            <IonText className="black s-14">{val && val.points.eagle}</IonText>
                        </IonCol>
                        <IonCol size="2">
                            <IonText className="black s-14">{val && val.points.birdie}</IonText>
                        </IonCol>
                        <IonCol size="2">
                            <IonText className="black s-14">{val && val.points.par_value}</IonText>
                        </IonCol>
                        <IonCol size="2">
                            <IonText className="black s-14">{val && val.points.bogey}</IonText>
                        </IonCol>
                        <IonCol size="2">
                            <IonText className="black s-14">{val && val.points.double_bogey}</IonText>
                        </IonCol>
                        <IonCol size="2">
                            <IonText className="black s-14">{val && val.points.triple_bogey}</IonText>
                        </IonCol>
                        



            </IonRow>
            <IonRow className="ion-justify-content-center ion-text-center mt-10">
                <IonText className="wg-500 black">Total Points: {val && val.points.total_points ? val.points.total_points : ''}</IonText>
            </IonRow>
            {val && val.competition_relation_id && val.competition_relation_id !=="" && !val.competition.partner_competition.is_score_added 
            ? 
            <IonRow className="ion-justify-content-center ion-text-center mt-10">
            <IonText className="pl-5 s-12 attest-button" onClick={() => attestScore(val.competition.id,val.competition.partner_competition.competition_id,val.current_user)}>Attest</IonText>
            </IonRow>
            : 
            ""}
            

        </IonGrid>
        </>
    );
};

export default Table;