import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonIcon,IonLabel, IonGrid, IonSegment, IonSegmentButton, IonFab, IonFabButton, IonCol, IonItem, IonRow, IonText, IonInput, IonDatetime, IonModal, IonSearchbar, IonList, useIonToast, IonLoading } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import EditTable from '../Components/event_score_table';
import * as utils from '../../js/utils';
import { getEntry, updateEntry, createEntryWithoutId } from "../../js/firebase_doc_functions";
import firebase from '../../js/firebase.js';
import { format } from 'date-fns';

import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import _, { ceil, floor } from 'lodash';
import { useParams } from 'react-router';

const db = getFirestore(firebase);
const season_name =localStorage.getItem("season_name");
const TAOEventAddCheckin: React.FC = () => {
    let {id}:any = useParams(); 
    const [event_id, setEventId] = useState<any>();
    const [event, setEvent] = useState<any>();
    const [event_name, setEventName] = useState<string>('');
    const [showStartLoading,setShowStartLoading]=useState<boolean>(false);
    const [showCourseLoading, setShowCourseLoading] = useState(false);
    const [showTeesLoading, setShowTeesLoading] = useState(false);
    const [current_user, setCurrentUser] = useState<any>([]);
    const [select_state,setSelectState] = useState<any>([])    
    const [course, setCourse] = useState<any>('');
    const [course_id, setCourseId] = useState<any>();
    const [course_list, setCourseList] = useState<any>([]);
    const [tees_list, setTeesList] = useState<any>([]);
    const [all_users, setAllUsers] = useState<any>();
    const [holes, setHoles] = useState<any>("18");
    const [select_tees,setSelectTees] = useState("")
    const [selected_date, setSelectedDate] = useState<string>(format(new Date(), "MM/dd/yyyy"));
    const [showDatePicker, setShowDatePicker] = useState(false);    
    const [selected_time, setSelectedTime] = useState<any>(format(new Date().getTime(), "HH:mm"));
    const [showTimePicker, setShowTimePicker] = useState(false);
    const [selected_player, setSelectedPlayer] = useState<string>('');
    const [selected_player_id, setSelectedPlayerId] = useState<string>('');
    const [teeset_rating_id, setTeesetRatingId] = useState<number>(0);
    const [teeset_rating_name, setTeesetRatingName] = useState<string>('');
    const [teeset_rating_gender, setTeesetRatingGender] = useState<string>('');
    const [slope_rating, setSlopeRating] = useState<number>(0);
    const [course_rating, setCourseRating] = useState<number>(0);
    const [par, setPar] = useState<number>(0);
    const [yards, setYards] = useState<number>(0);
    const [gender, setGender] = useState<string>('');
    const [playing_quota, setPlayingQuota] = useState<number>();
    const [showToast, hideToast] = useIonToast();
    const [ppw_score_modal, setPpwScoreModal] = useState(false);
    const [score_record, setScoreRecord] = useState<any>([]);
    const [edit_hole_details, setEditHoleDetails] = useState<any>([]);
    const [hole_points, setHolePoints] = useState<any>();
    const [available_tees, setAvailableTees] = useState<any>();
    const [searchText,setSearchText] = useState<any>("");
    const [ppw_score_id, setPpwScoreId] = useState<any>();
    const [target_tees, setTargetTees] = useState<any>();
    const [course_display_value, setCourseDisplayValue] = useState<any>();
    const [course_selected, setCourseSelected] = useState<boolean>(false);
    const inputRef = useRef<any>(null);
    const customAlertOptions = {
      header: 'Select State',
      translucent: true
    };
    const customAlertOptions1 = {
        header: 'Select Course',
    };
    const customAlertOptions2 = {
        header: 'Select Tees(Course/Slope/Par)',
    };

    const customAlertOptions3 = {
        header: 'Playing with',
    };

    const getCourses = async(state:string)=> {
        setShowCourseLoading(true);
        let course_list = await utils.getCourseList(state);
        setCourseList(course_list);
        setNewCourses(course_list);
        setShowCourseLoading(false);
        setShowCourseModal(true);
    }

    const getUsers = async() => {
        const q = query(collection(db, "USER_PROFILE"), where('created_at', '>', 0), where('city', '==', current_user.city));
    
        const querySnapshot = await getDocs(q);
        var records = new Array();
        querySnapshot.forEach((doc) => {
            let doc_item = doc.data();
            doc_item.id = doc.id;
            if(doc_item.ppw_validated){
                records.push(doc_item);
            }
        });
        records = records.filter((x:any)=> x.user_id !== current_user.user_id);
        setAllUsers(records);
    }

    const getTees = async(course_id:number, holes:number)=> {
        setShowTeesLoading(true);
        let tees = await utils.getCourseDetails(course_id) || [];
        var tee_sets = new Array();
        let finalTees = current_user.gender === "Female" ? tees.filter((x:any)=> x.Gender === "Female") : tees.filter((x:any)=> x.Gender === "Male");
        let sortedTees =  _.sortBy(finalTees, (e: any) => e.TotalMeters).reverse();
        setAvailableTees(sortedTees);
        finalTees && finalTees.forEach((tee:any)=>{
            if(holes == 18){
                let rating = tee.Ratings.filter((rating:any)=> rating.RatingType == 'Total');
                let pars = tee.Holes.map((hole:any)=> {return hole.Par}),
                par = pars.reduce((a:number, b:number) => a + b, 0);
                if(tee.HolesNumber == 18){
                    tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+'('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')', 
                    'teeValue': tee.TeeSetRatingName+'~'+'('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                  });
                }
            } else if(holes == 9) {
                let rating = tee.Ratings.filter((rating:any)=> rating.RatingType == 'Total');
                let nineHoleRatings = tee.Ratings.filter((rating:any)=> rating.RatingType != 'Total');
                let pars = tee.Holes.map((hole:any)=> {return hole.Par});
                if(tee.HolesNumber == 9){
                  let pars = tee.Holes.map((hole:any)=> {return hole.Par}),
                  par = pars.reduce((a:number, b:number) => a + b, 0);
                  tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+rating[0].RatingType+'~('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')', 
                    'teeValue': tee.TeeSetRatingName+'~'+rating[0].RatingType+'~('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                  });
                  
                } else {
                  for (rating of nineHoleRatings) {
                    var front9Holes = tee.Holes.filter((hole:any)=> hole.Number <= 9);
                    var back9Holes = tee.Holes.filter((hole:any)=> hole.Number > 9);
                    if(rating.RatingType == 'Front'){
                      let pars = front9Holes.map((hole:any)=> {return hole.Par}),
                      par = pars.reduce((a:number, b:number) => a + b, 0);
                      tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')', 
                        'teeValue': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                      });
                      
                    }
                    if(rating.RatingType == 'Back'){
                      let pars = back9Holes.map((hole:any)=> {return hole.Par}),
                      par = pars.reduce((a:number, b:number) => a + b, 0);
                      tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')', 
                        'teeValue': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                      });
                      
                    }
                  }
                }
            } 
        });
        setTeesList(tee_sets);
        setNewTees(tee_sets);
        setShowTeesLoading(false);
        setShowTeesModal(true);
    }

    const getTeeDetails = async(courseid:number, holes:number, tee:string)=> {
        let currentTeeValues = tee.split("~");
        setTeesetRatingId(holes == 18 ? parseInt(currentTeeValues[2]) : parseInt(currentTeeValues[3]));
        setTeesetRatingName(currentTeeValues[0]);
        setTargetTees(currentTeeValues[0]);
        setTeesetRatingGender(currentTeeValues[1]);
        let teeSetValues = holes == 18 ? (currentTeeValues[1].substring(1, currentTeeValues[1].length-1)).split("/") : (currentTeeValues[2].substring(1, currentTeeValues[3].length-1)).split("/");
        setSlopeRating(parseFloat(teeSetValues[1]));
        setCourseRating(parseFloat(teeSetValues[0]));
        setPar(parseInt(teeSetValues[2]));
        setGender(current_user.gender);
        if(playing_quota == 0) {
            let hc = 36 - current_user.quota;
            let phc = hc * parseFloat(teeSetValues[1]) / 113.0;
            phc = phc + (parseFloat(teeSetValues[0]) - parseInt(teeSetValues[2]));
            let pq = 36 - phc;
            if(pq < 9)
                pq = 9;
            if(holes == 18) {
                pq = ceil(pq);
                setPlayingQuota(ceil(pq));
            } else {
                setPlayingQuota(ceil(pq/2));
            }
        }
        
        let tees = await utils.getCourseDetails(courseid) || [];
        tees && tees.forEach((tee:any)=>{
            if(holes == 18 && (tee.TeeSetRatingId == parseInt(currentTeeValues[2]))){
                let yardsData = tee.Holes.map((hole:any)=> {return hole.Length}),
                yards = yardsData.reduce((a:number, b:number) => a + b, 0);
                setEditHoleDetails(tee.Holes);
                setYards(yards);
            } else if(holes == 9 && tee.TeeSetRatingId == parseInt(currentTeeValues[3]) ) {
                let rating = tee.Ratings.filter((rating:any)=> rating.RatingType == 'Total');
                let nineHoleRatings = tee.Ratings.filter((rating:any)=> rating.RatingType != 'Total');
                if(tee.HolesNumber == 9){
                    let yardsData = tee.Holes.map((hole:any)=> {return hole.Length}),
                    yards = yardsData.reduce((a:number, b:number) => a + b, 0);
                    setEditHoleDetails(tee.Holes);
                    setYards(yards);
                } else {
                  for (rating of nineHoleRatings) {
                    var front9Holes = tee.Holes.filter((hole:any)=> hole.Number <= 9);
                    var back9Holes = tee.Holes.filter((hole:any)=> hole.Number > 9);
                    if(rating.RatingType == 'Front'){
                        let yardsData = front9Holes.map((hole:any)=> {return hole.Length}),
                        yards = yardsData.reduce((a:number, b:number) => a + b, 0);
                        setEditHoleDetails(tee.Holes);
                        setYards(yards);
                    }
                    if(rating.RatingType == 'Back'){
                        let yardsData = back9Holes.map((hole:any)=> {return hole.Length}),
                        yards = yardsData.reduce((a:number, b:number) => a + b, 0);
                        setEditHoleDetails(tee.Holes);
                        setYards(yards);
                    }
                  }
                }
            } 
        });
    }

    const createScore = async()=> {
        if(course === "" || select_tees === "" || selected_time === "") {
            showToast('All fields are required', 2000);
        } else {
            let selected_course = course_list ? course_list.filter((x:any)=> x.CourseID == course_id) : [];
            let hole_details = edit_hole_details.map((hole:any)=>{
                hole.raw_score = 0;
                hole.points_type = null;
                hole.points = 0;
                hole.par = hole.Par;
                hole.hole_id = hole.HoleId;
                hole.length = hole.Length;
                hole.hole_number = hole.Number;
                delete hole.Allocation;
                delete hole.HoleId;
                delete hole.Length;
                delete hole.Number;
                delete hole.Par;
                return hole;
            });
            let courseStr = course_selected ? course.split("-") : selected_course[0].FullName.split("-");
            if (courseStr[0] != undefined || courseStr[0] != null)
            setCourseDisplayValue(courseStr[0])
            var score_obj = {
                course_name: course_selected ? course : selected_course[0].FullName,
                competition_date: format(new Date(selected_date),"yyyy-MM-dd"),
                competition_date_timestamp: new Date(selected_date).getTime(),
                tee: teeset_rating_name,
                game_date_time: selected_time,
                user_name: current_user.first_name+' '+current_user.last_name,
                course_id: course_selected ? course_id : selected_course[0].CourseID,
                teeset_id: teeset_rating_id,
                user_id: current_user.user_id,
                quota: playing_quota,
                holes: Number(holes),
                target_tees: target_tees,
                season_name: season_name,
                yards: yards,
                course_rating: course_rating,
                slope_rating: slope_rating,
                gender: current_user.gender,
                hole_details: hole_details,
                score_submitted: false,
                points:{
                    "triple_bogey": 0,
                    "double_bogey": 0,
                    "bogey": 0,
                    "par_value": 0,
                    "birdie": 0,
                    "eagle": 0,
                    "double_eagle": 0,
                    "total_points": 0,
                    "front_par_total": 0,
                    "back_par_total": 0,
                    "front_score_total": 0,
                    "back_score_total": 0,
                    "front_points_total": 0,
                    "back_points_total": 0
                },
                scoreId: '',
                event_id: event_id,
            }
           
            score_obj.scoreId = await createEntryWithoutId("SCORE_RECORDS", score_obj);
         //   let ppw_id = await createEntryWithoutId("PPW_COMPETITIONS", score_obj);
            setShowStartLoading(false);
            setPpwScoreId(score_obj.scoreId);
            setScoreRecord(score_obj);
            setEditHoleDetails(score_obj.hole_details);
            setHolePoints(score_obj.points)
            setPpwScoreModal(true);
            console.log("Score object: "+ ppw_score_id);
        }
    }
  
    const states = [{"state_code":"US-AL","state_name":"Alabama"},
      {"state_code":"US-AK","state_name":"Alaska"},
      {"state_code":"US-AZ","state_name":"Arizona"},
      {"state_code":"US-AR","state_name":"Arkansas"},
      {"state_code":"US-CA","state_name":"California"},
      {"state_code":"US-CO","state_name":"Colorado"},
      {"state_code":"US-CT","state_name":"Connecticut"},
      {"state_code":"US-DE","state_name":"Delaware"},
      {"state_code":"US-DC","state_name":"District Of Columbia"},
      {"state_code":"US-FL","state_name":"Florida"},
      {"state_code":"US-GA","state_name":"Georgia"},
      {"state_code":"US-HI","state_name":"Hawaii"},
      {"state_code":"US-ID","state_name":"Idaho"},
      {"state_code":"US-IL","state_name":"Illinois"},
      {"state_code":"US-IN","state_name":"Indiana"},
      {"state_code":"US-IA","state_name":"Iowa"},
      {"state_code":"US-KS","state_name":"Kansas"},
      {"state_code":"US-KY","state_name":"Kentucky"},
      {"state_code":"US-LA","state_name":"Louisiana"},
      {"state_code":"US-ME","state_name":"Maine"},
      {"state_code":"US-MD","state_name":"Maryland"},
      {"state_code":"US-MA","state_name":"Massachusetts"},
      {"state_code":"US-MI","state_name":"Michigan"},
      {"state_code":"US-MN","state_name":"Minnesota"},
      {"state_code":"US-MS","state_name":"Mississippi"},
      {"state_code":"US-MO","state_name":"Missouri"},
      {"state_code":"US-MT","state_name":"Montana"},
      {"state_code":"US-NE","state_name":"Nebraska"},
      {"state_code":"US-NV","state_name":"Nevada"},
      {"state_code":"US-NH","state_name":"New Hampshire"},
      {"state_code":"US-NJ","state_name":"New Jersey"},
      {"state_code":"US-NM","state_name":"New Mexico"},
      {"state_code":"US-NY","state_name":"New York"},
      {"state_code":"US-NC","state_name":"North Carolina"},
      {"state_code":"US-ND","state_name":"North Dakota"},
      {"state_code":"US-OH","state_name":"Ohio"},
      {"state_code":"US-OK","state_name":"Oklahoma"},
      {"state_code":"US-OR","state_name":"Oregon"},
      {"state_code":"US-PA","state_name":"Pennsylvania"},
      {"state_code":"US-RI","state_name":"Rhode Island"},
      {"state_code":"US-SC","state_name":"South Carolina"},
      {"state_code":"US-SD","state_name":"South Dakota"},
      {"state_code":"US-TN","state_name":"Tennessee"},
      {"state_code":"US-TX","state_name":"Texas"},
      {"state_code":"US-UT","state_name":"Utah"},
      {"state_code":"US-VT","state_name":"Vermont"},
      {"state_code":"US-VA","state_name":"Virginia"},
      {"state_code":"US-WA","state_name":"Washington"},
      {"state_code":"US-WV","state_name":"West Virginia"},
      {"state_code":"US-WI","state_name":"Wisconsin"},
      {"state_code":"US-WY","state_name":"Wyoming"}
    ];
    const [new_states,setNewStates] = useState<any>(states);
    const [new_courses,setNewCourses] = useState<any>(course_list);
    const [new_tees,setNewTees] = useState<any>(tees_list);
    const [new_players,setNewPlayers] = useState<any>(all_users);

    const getEventData = async(id:string, user_id:string) => {
        const querySnapshot = await getEntry("EVENTS", id);
        if(querySnapshot.created_at) {
            let doc_item = querySnapshot;
            doc_item.id = id;
            setEvent(doc_item);
            setEventName(doc_item.event_name);
            if(doc_item.selected_courses && doc_item.selected_courses.length > 0 && doc_item.selected_courses[0].course_id) {
                setCourse(doc_item.selected_courses[0].course_name);
                let c_id = parseInt(doc_item.selected_courses[0].course_id)
                setCourseId(c_id);
                setCourseSelected(true);
                getTees(c_id!, 18);
            } else {
                setCourseSelected(false);
            }
            if(doc_item.players) {
                console.log(doc_item.players);
                console.log(user_id);
                let player = doc_item.players.filter((x:any)=>x.user_id == user_id);
                console.log(player);
                if(player.length > 0 && player[0].user_target) {
                    console.log("Playing Target");
                    setPlayingQuota(parseInt(player[0].user_target));
                }
            }
        }

    }

    useEffect(() => {
        let user = localStorage.getItem('firebase_user');
        setEventId(id);
        if(user != null) {
            console.log(user);
            let cur_user = JSON.parse(user);
            setCurrentUser(cur_user);
            setPlayingQuota(cur_user.quota);
            getEventData(id, cur_user.user_id);
        }
    }, []);

    const [showStateModal, setShowStateModal] = useState(false);
    const [showCourseModal, setShowCourseModal] = useState(false);
    const [showTeesModal, setShowTeesModal] = useState(false);
    const [showPlayersModal, setShowPlayersModal] = useState(false);
    return (
        <IonPage>
             <IonHeader mode="ios">
             <IonToolbar>
                <IonLabel slot="start" className="s-18 w-800 color-blue">Post Score - {event_name} </IonLabel>
            </IonToolbar>
             </IonHeader>
            <IonContent>
                <IonGrid>
                    <>
                        <IonRow className="ion-justify-content-center mt-10 ion-text-center">
                            <IonText className="wg-600 black s-18 myclub-header">{current_user.first_name+' '+current_user.last_name}</IonText>
                        </IonRow>
                        {
                            (!course_selected) ? (
                                <>
                                    <IonRow className="ion-justify-content-center mt-10 select-modal">
                                        <IonInput value={select_state} 
                                            placeholder="state" className="s-14 select-pack-dropdown pl-20" 
                                            mode="ios" onClick={()=>{
                                                setShowStateModal(true)
                                                setNewStates(states)
                                            }}>
                                        </IonInput>
                                    </IonRow>
                                    <IonRow className="ion-justify-content-center mt-10 select-modal">
                                        <IonInput value={course} 
                                            placeholder="course" className="s-14 select-pack-dropdown pl-20" 
                                            mode="ios" onClick={()=>{
                                                setShowCourseModal(true)
                                                setNewCourses(course_list)
                                            }}>
                                        </IonInput>
                                    </IonRow>
                                </>
                            ) : (
                                <>
                                    <IonRow className="ion-justify-content-center mt-10 select-modal">
                                        <IonInput value={course} 
                                            placeholder="course" className="s-14 select-pack-dropdown pl-20" 
                                            mode="ios">
                                        </IonInput>
                                    </IonRow>
                                </>
                            )
                        }
                        <IonRow className="ion-justify-content-center mt-10 select-modal">
                            <IonInput value={select_tees} 
                                // interfaceOptions={customAlertOptions}
                                // interface="popover"                        
                                placeholder="tees" className="s-14 select-pack-dropdown pl-20" 
                                // onIonChange={e => {
                                //     setSelectState(e.detail.value)
                                //     getCourses(e.detail.value!)
                                // }}
                                mode="ios" onClick={()=>{
                                    setShowTeesModal(true)
                                    setNewTees(tees_list)
                                }}>
                                {/* {
                                    (states && states.map((key:any,i:number)=>{
                                        return(
                                            <IonSelectOption value={key.state_code} key={i}>{key.state_name}</IonSelectOption>
                                        )
                                    }))
                                }                                     */}
                            </IonInput>
                            {/* <IonSelect value={select_tees} 
                                interfaceOptions={customAlertOptions2}
                                interface="popover"                        
                                placeholder="Select Tees(Course/Slope/Par)" className="s-14 select-pack-dropdown" 
                                onIonChange={e => {
                                    setSelectTees(e.detail.value)
                                    getTeeDetails(course, holes, e.detail.value)
                                }} mode="ios">
                                {
                                    (tees_list && tees_list.map((key:any,i:number)=>{
                                        return(
                                            <IonSelectOption value={key.teeValue} key={i}>{key.teeName}</IonSelectOption>
                                        )
                                    }))
                                } 
                            </IonSelect> */}
                        </IonRow>
                        {
                            teeset_rating_id && teeset_rating_id > 0 ? (
                                <IonRow className="ion-justify-content-center mt-10 ion-text-center">
                                    <IonText className="black s-16">Playing Target: {playing_quota}</IonText>
                                </IonRow>
                            ) : (
                                <></>
                            )
                        }
                        <IonRow className="ion-justify-content-center mt-10 ion-text-center">
                            <IonText className="black s-12">Date &#38; Tee time</IonText>
                        </IonRow>
                        <IonRow className="ion-justify-content-center mt-5 mb-10" style={{padding:"0px 10px 0px 10px"}}>
                            <IonCol className="pad-0">
                                <IonInput value={selected_date}            
                                    placeholder="date" className="s-14 select-pack-dropdown pl-20" 
                                    mode="ios" 
                                    disabled={true}
                                    onClick={()=> {
                                        //setShowDatePicker(true);
                                    }}>
                                </IonInput>
                                {
                                    (showDatePicker) ? (
                                        <IonDatetime
                                            presentation="date"
                                            size='cover'
                                            onIonChange={(e) => {
                                                setSelectedDate(format(new Date(e.detail.value!), "MM/dd/yyyy"));
                                                setShowDatePicker(false);
                                            }}
                                        ></IonDatetime>
                                    ) : (
                                        <></>
                                    )
                                }
                            </IonCol>
                            <IonCol className="pad-0">
                                <IonInput value={selected_time}            
                                    placeholder="tee time" className="s-14 select-pack-dropdown pl-20" 
                                    mode="ios" 
                                    type="time"
                                    onClick={()=> {
                                        //setShowTimePicker(true);
                                    }}
                                    onIonChange={(e)=>{
                                        setSelectedTime(e.detail.value!);
                                    }
                                    }
                                    style={{height: '39px'}}>
                                </IonInput>
                                {/* {
                                    (showTimePicker) ? (
                                        <IonDatetime
                                            presentation="time"
                                            size='cover'
                                            onIonChange={(e) => {
                                                setSelectedTime(format(new Date(e.detail.value!), "HH:mm"));
                                                setShowTimePicker(false);
                                            }}
                                        ></IonDatetime>
                                    ) : (
                                        <></>
                                    )
                                } */}
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center select-modal">
                            <IonInput value={selected_player} 
                                // interfaceOptions={customAlertOptions}
                                // interface="popover"                        
                                placeholder="Playing with" className="s-14 select-pack-dropdown pl-20" 
                                // onIonChange={e => {
                                //     setSelectState(e.detail.value)
                                //     getCourses(e.detail.value!)
                                // }}
                                mode="ios" onClick={()=>{
                                    setShowPlayersModal(true)
                                    setNewPlayers(all_users)
                                }}>
                                {/* {
                                    (states && states.map((key:any,i:number)=>{
                                        return(
                                            <IonSelectOption value={key.state_code} key={i}>{key.state_name}</IonSelectOption>
                                        )
                                    }))
                                }                                     */}   
                            </IonInput>
                            {/* <IonSelect value={selected_player} 
                                interfaceOptions={customAlertOptions3}
                                interface="popover"                        
                                placeholder="Playing with" className="s-14 select-pack-dropdown" 
                                onIonChange={e => {
                                    setSelectedPlayer(e.detail.value)
                                }} mode="ios">
                                {
                                    (all_users && all_users.map((key:any,i:number)=>{
                                        return(
                                            <IonSelectOption value={key.id} key={i}>{key.first_name+' '+key.last_name}</IonSelectOption>
                                        )
                                    }))
                                } 
                            </IonSelect> */}
                        </IonRow>
                    </>
                    <IonRow className="ion-justify-content-center ion-text-center mt-25">
                        <button className='button-send white s-15 bg button-border-20 ' onClick={()=>{setShowStartLoading(true);
                            createScore();}}>                            
                            <IonText className="wg-bold">Start</IonText>
                        </button>
                    </IonRow>
                </IonGrid>
                <IonModal isOpen={ppw_score_modal} className='my-custom-class'>
                    <>
                        <IonHeader>
                            <IonToolbar>
                            <IonLabel slot="start" className="s-1 w-600 color-blue">{course_display_value} - {score_record.tee}</IonLabel>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <EditTable options={score_record} options1={edit_hole_details} options2={hole_points}/>
                            <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button">
                                <IonFabButton
                                className="main-fab"
                                onClick={() => {
                                    window.location.href="/noticeboard"
                                }}
                                >
                                <IonIcon icon={arrowBackOutline} />
                                </IonFabButton>
                            </IonFab>
                        </IonContent>
                    </>
                </IonModal>
                <IonModal isOpen={showStateModal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true} 
                onDidDismiss={() => setShowStateModal(false)}
                initialBreakpoint={0.5} onDidPresent={()=> {setTimeout(() => inputRef.current.setFocus(), 100)}} mode="md">
                    <>
                   
                    <IonSearchbar ref={(ref) => inputRef.current = ref} onIonChange={(e:any) => {
                        if(String(e.detail.value!).length>0){
                            let glo = new RegExp(String(e.detail.value!).toLowerCase());
                            let new_city_arr = new Array() 
                            states.map((item:any)=>{
                                let real_item = item
                                item = item.state_name.toLowerCase()

                                if(glo.test(item)){
                                    new_city_arr.push(real_item)
                                }
                            })    
                            setNewStates(new_city_arr)                                    
                        }
                        else{
                            setNewStates(states)
                        }
                        }} mode="ios"></IonSearchbar>

                    <IonContent className='content-height'>
                        <IonGrid>
                            <IonList>
                                    {
                                    (new_states && new_states.map((key:any,i:number)=>{
                                        return(
                                                <IonItem key={i}>
                                                    <IonLabel onClick={()=>{
                                                        setSelectState(key.state_name)
                                                        getCourses(key.state_code)
                                                        setShowStateModal(false)
                                                    }}>
                                                    {key.state_name}
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        }))
                                    }                                    
                                
                            </IonList>
                        </IonGrid>
                    </IonContent>
                    </>
                </IonModal>
                <IonModal isOpen={showCourseModal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true} 
                onDidDismiss={() => setShowCourseModal(false)}
                initialBreakpoint={0.5} onDidPresent={()=> {setTimeout(() => inputRef.current.setFocus(), 100)}} mode="md">
                    <>
                   
                    <IonSearchbar ref={(ref) => inputRef.current = ref} onIonChange={(e:any) => {
                                if(String(e.detail.value!).length>0){
                                    let glo = new RegExp(String(e.detail.value!).toLowerCase());
                                    let new_course_arr = new Array() 
                                    course_list.map((item:any)=>{
                                        let real_item = item
                                        item = item.FullName.toLowerCase()

                                        if(glo.test(item)){
                                            new_course_arr.push(real_item)
                                        }
                                    })    
                                    setNewCourses(new_course_arr)                                    
                                }
                                else{
                                    setNewCourses(course_list)
                                }
                                }} mode="ios"></IonSearchbar>

                    <IonContent className='content-height'>
                        <IonGrid>
                            <IonList>
                                    {
                                    (new_courses && new_courses.map((key:any,i:number)=>{
                                        return(
                                                <IonItem key={i}>
                                                    <IonLabel onClick={()=>{
                                                        setCourse(key.FullName)
                                                        setCourseId(key.CourseID)
                                                        getTees(key.CourseID!, 18)
                                                        getUsers()
                                                        setShowCourseModal(false)
                                                    }}>
                                                    {key.FullName}
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        }))
                                    }                                    
                                
                            </IonList>
                        </IonGrid>
                    </IonContent>
                    </>
                </IonModal>
                <IonModal isOpen={showTeesModal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true} 
                onDidDismiss={() => setShowTeesModal(false)}
                initialBreakpoint={0.5} mode="md">
                    <>

                        <IonSearchbar ref={(ref) => inputRef.current = ref} onIonChange={(e:any) => {
                            if(String(e.detail.value!).length>0){
                                let glo = new RegExp(String(e.detail.value!).toLowerCase());
                                let new_tees_arr = new Array() 
                                tees_list.map((item:any)=>{
                                    let real_item = item
                                    item = item.teeName.toLowerCase()

                                    if(glo.test(item)){
                                        new_tees_arr.push(real_item)
                                    }
                                })    
                                setNewTees(new_tees_arr)                                    
                            }
                            else{
                                setNewTees(tees_list)
                            }
                            }} mode="ios">
                        </IonSearchbar>

                        <IonContent className='content-height'>
                            <IonGrid>
                                <IonList>
                                    {
                                        (new_tees && new_tees.map((key:any,i:number)=>{
                                            return(
                                                <IonItem key={i}>
                                                    <IonLabel onClick={()=>{
                                                        setSelectTees(key.teeValue)
                                                        getTeeDetails(course_id, holes, key.teeValue)
                                                        setShowTeesModal(false)
                                                    }}>
                                                    {key.teeName}
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        }))
                                    }                                    
                                    
                                </IonList>
                            </IonGrid>
                        </IonContent>
                    </>
                </IonModal>
                <IonModal isOpen={showPlayersModal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true} 
                onDidDismiss={() => setShowPlayersModal(false)}
                initialBreakpoint={0.5} onDidPresent={()=> {setTimeout(() => inputRef.current.setFocus(), 100)}} mode="md">
                    <>

                        <IonSearchbar ref={(ref) => inputRef.current = ref} onIonChange={(e:any) => {
                            if(String(e.detail.value!).length>0){
                                let glo = new RegExp(String(e.detail.value!).toLowerCase());
                                let new_players_arr = new Array() 
                                all_users.map((item:any)=>{
                                    let real_item = item
                                    item = item.first_name.toLowerCase()+' '+item.last_name.toLowerCase()

                                    if(glo.test(item)){
                                        new_players_arr.push(real_item)
                                    }
                                })    
                                setNewPlayers(new_players_arr)                                    
                            }
                            else{
                                setNewPlayers(all_users)
                            }
                            }} mode="ios">
                        </IonSearchbar>

                        <IonContent className='content-height'>
                            <IonGrid>
                                <IonList>
                                    {
                                        (new_players && new_players.map((key:any,i:number)=>{
                                            return(
                                                <IonItem key={i}>
                                                    <IonLabel onClick={()=>{
                                                        setSelectedPlayer(key.first_name+' '+key.last_name)
                                                        setSelectedPlayerId(key.id)
                                                        setShowPlayersModal(false)
                                                    }}>
                                                    {key.first_name+' '+key.last_name}
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        }))
                                    }                                    
                                    
                                </IonList>
                            </IonGrid>
                        </IonContent>
                    </>
                </IonModal>
                <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button">
                    <IonFabButton
                    className="main-fab"
                    onClick={() => {
                        window.location.href = "/noticeboard";
                    }}
                    >
                    <IonIcon icon={arrowBackOutline} />
                    </IonFabButton>
                </IonFab>
                <IonLoading
                    isOpen={showCourseLoading}
                    message={'loading courses..'}
                />
                <IonLoading
                    isOpen={showTeesLoading}
                    message={'loading tees..'}
                />
                 <IonLoading
                    isOpen={showStartLoading}
                    message={'creating score card..'}
                />
            </IonContent>
        </IonPage>
    );
};
export default TAOEventAddCheckin;