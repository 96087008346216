import firebase from './firebase.js';
import { getFirestore, collection, doc, getDoc, addDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { is } from 'date-fns/locale';
const db = getFirestore(firebase);

export const getEntry = async (table, id) => {
    const docRef = doc(db, table, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        return docSnap.data();
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        return {error: "Error"}
    }
}

export const createEntryWithoutId = async (table, row) => {
    row.created_at = new Date().getTime();
    row.updated_at = new Date().getTime();
    const docRef = await addDoc(collection(db, table), row);
    return docRef.id;
}

export const createEntryWithId = async (table, id, row) => {
    row.created_at = new Date().getTime();
    row.updated_at = new Date().getTime();
    const docRef = await doc(collection(db, table, id), row);
    return docRef.id;
}

export const updateEntry = async (table, id, row) => {
    row.updated_at = new Date().getTime();
    const collctionRef = doc(db, table, id);
    const docRef = await updateDoc(collctionRef, row);
}
export const deleteEntry = async (table, id) => {
    await deleteDoc(doc(db, table, id));
}

