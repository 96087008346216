import _ from 'lodash';
import { format } from 'date-fns';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonLabel, IonText, IonIcon, IonRow, IonGrid, IonFab, IonFabButton } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import { Link } from 'react-router-dom';
const ConnectAbout: React.FC = () => {
    return (
        <IonPage>
            <IonHeader mode="ios">
                <IonToolbar>
                    {/* <IonButtons slot="start">
                    <a onClick={()=> window.location.href="/leagues"}>
                        <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                    </a>
                    </IonButtons> */}
                    <IonTitle>
                    <IonLabel>
                        <IonText>Connect</IonText>
                    </IonLabel>
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid className="vh-15">
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <p>Connect pages help in our mission it to foster a vibrant community of recreational yet competitive golfers. You can connect, post and share with golfers in your city.</p>
                    </IonRow>
                </IonGrid>
                <IonGrid className="">
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <IonText className="s-18"><b>Community</b></IonText>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <p>All golfers in your city/region</p>
                    </IonRow>
                </IonGrid>
                <IonGrid className="">
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <IonText className="s-18"><b>My Club</b></IonText>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <p>Golfers that belong to a club, must have valid Handicap Index<sup>&#8482;</sup> from that club. <Link to="/profile">Validate</Link></p>
                        {/* <p>Want to get H.I.<sup>&#8482;</sup> from one of our member clubs? <Link to="/get_handicap">Click here</Link></p> */}
                    </IonRow>
                </IonGrid>
                <IonGrid className="">
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <IonText className="s-18"><b>My Packs</b></IonText>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <p>Pack is simply a group of golfers who play with each other often and/or able to attest each other’s scores. You can join one of the active packs or create your own pack here.</p>
                    </IonRow>
                </IonGrid>
                <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '15px'}}>
                        <IonFabButton
                        className="main-fab"
                        onClick={() => {
                            window.location.href="/locker_room"
                        }}
                        >
                        <IonIcon icon={arrowBackOutline} />
                        </IonFabButton>
                    </IonFab>
            </IonContent>
        </IonPage>
    );
}
export default ConnectAbout;