import { useState} from "react";
import {  IonGrid,IonText,IonRow} from '@ionic/react';
import { format, nextSunday, startOfWeek, subDays } from 'date-fns';
interface Props {
    record: {}
}
const NSCLeaderBoardsTable: React.FC<Props> = (props) => {
    let obj: any = {}
    obj = props.record;
    const [val, setVal] = useState<any>(obj);
    return (
        <>
            <IonGrid>
                <IonRow className="ion-justify-content-center ion-text-center mt-10">
                    <IonText className="black s-18" style={{ fontSize: '21px', fontWeight: 900, paddingTop: '20px' }}>{val.userName}</IonText>
                </IonRow>
                <IonRow className='won-tied-box'>
                    <div className="won-tied-header">
                        <div className="won-tied-headings">
                            <p className="heading">Won-Tied-Lost</p>
                        </div>
                        <div className="won-tied-headings">
                            <p className="heading">Season Total</p>
                        </div>
                    </div>
                    <div className="won-tied-content">
                        <div className="won-tied-headings">
                            <p className="game-count">{val.wonCount}-{val.tiedCount}-{val.lostCount}</p>
                        </div>
                        <div className="won-tied-headings">
                            <p className="game-points">{val.seasonalPoints}</p>
                        </div>
                    </div>
                </IonRow>
                <div className="scoring-record-box">
                    <div className="scoring-record-header">
                        <p className="heading week-heading-color">Scoring Record</p>
                    </div>
                    <div className="week-header">
                        <div className="score-week-align">
                            <p className="score-heading week-heading-color">Week Ending</p>
                        </div>
                        <div className="score-description">
                            <div className="score-card-content-align">
                                <p className="score-heading-color">Matches</p>
                            </div>
                            <div className="score-card-content-align">
                                <p className="score-heading-color">Margins</p>
                            </div>
                            <div className="score-card-content-align">
                                <p className="score-heading-color">Field</p>
                            </div>
                            <div className="score-card-content-align">
                                <p className="week-heading-color">Total</p>
                            </div>
                        </div>
                    </div>
                    {obj && obj.playersList && obj.playersList.length > 0 ?
                        obj.playersList.map((e: any, key: number) => (
                            <div className="week-header" key={key}>
                                <div className="score-week-align">
                                    <p className="playing-count">{format(new Date(e.competition_date), "MM/dd")}-{e.player.split(" ")[1]}</p>
                                </div>
                                <div className="score-description">
                                    <div className="score-card-content-align">
                                        <p className="playing-count">{e.matchPoints}</p>
                                    </div>
                                    <div className="score-card-content-align">
                                        <p className="playing-count">{e.marginPoints}</p>
                                    </div>
                                    <div className="score-card-content-align">
                                        <p className="playing-count">2</p>
                                    </div>
                                    <div className="score-card-content-align">
                                        <p className="game-points">{e.totalPoints}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                        : ""
                    }
                </div>
                <IonRow>
                    <p className="score-footer"><span className="match-heading">Matches:</span> Win: 4 pts;   Tie:2 pts;   Loss: 0 pts</p>
                    <p className="score-footer"><span className="match-heading">Margins:</span> 3 to 5: 1 pts;   6 to 9: 2 pts;   10 or above: 3 pts</p>
                    <p className="score-footer"><span className="match-heading">Field:</span> 2 pts for each unique competitor, cap of 8 pts per season</p>
                </IonRow>
            </IonGrid>
        </>
    )
}

export default NSCLeaderBoardsTable;