import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonIcon, IonGrid, IonFab, IonFabButton, IonCard,  IonCol, IonRow, IonText } from '@ionic/react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { format } from 'date-fns';
import { collection, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore';
import { arrowBackOutline } from 'ionicons/icons';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import firebase from '../../js/firebase';
import PayPal from '../Components/paypal_payment';

const db = getFirestore(firebase);

const Earnings: React.FC = () => {
    const [current_user, setCurentUser] = useState<any>();
    const [earnings, setEarnings] = useState<any>();
    const [transactions, setTransactions] = useState<any>([]);
    const getTransactions = async(user:any)=> {
        const q = query(
            collection(db, "USER_EARNINGS_TRANSACTIONS"),
            where("created_at", ">", 0),
            where("user_id", "==", user.user_id)
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot.docs && querySnapshot.docs.length > 0) {
            await Promise.all(
                querySnapshot.docs.map(async (documentSnapshot) => {
                    var doc_item = documentSnapshot.data();
                    doc_item.id = documentSnapshot.id;
                    return doc_item;
                })
            ).then((results) => {
                setTransactions(_.sortBy(results, (e: any) => e.created_at).reverse());
            });
        }
    }

    useEffect(() => {
        let user = localStorage.getItem('firebase_user');
        if(user != null) {
          setCurentUser(JSON.parse(user));
          setEarnings(JSON.parse(user).earnings);
          getTransactions(JSON.parse(user));
        }
    }, []);
    return (
        <IonPage>
            <IonHeader>
            <IonToolbar mode="ios">
                {/* <IonButtons slot="start">
                    <a onClick={()=> window.history.back()}>
                        <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                    </a>
                </IonButtons> */}
                <IonTitle>
                    Earnings ({earnings} chips)
                </IonTitle>
            </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow className="ion-justify-content-center ion-text-center mt-10">
                    </IonRow>
                    <IonCard className="social-card">
                        <IonRow className="ion-justify-content-center ion-text-center bb-line">
                            <IonCol size="2">
                                <IonText className="s-14 wg-600 color-dark-gray">SNo</IonText>
                            </IonCol>
                            <IonCol size="3">
                                <IonText className="s-14 wg-600 color-dark-gray">Date</IonText>
                            </IonCol>
                            <IonCol size="4">
                                <IonText className="s-14 wg-600 color-dark-gray">Debit/Credit</IonText>
                                </IonCol>
                                <IonCol size="3">
                                <IonText className="s-14 wg-600 color-dark-gray">Balance</IonText>
                            </IonCol>
                        </IonRow>
                        {
                            (transactions.length > 0)?(
                                <>
                                {
                                    transactions.map((record: any, index: number) => {
                                        return(
                                            <IonRow className="ion-justify-content-center ion-text-center bb-line" key={index}>
                                                <IonCol size="2">
                                                    <IonText className="s-14 color-black wg-500">{index+1}</IonText>
                                                </IonCol>
                                                <IonCol size="3">
                                                    <IonText className="s-14 color-black wg-500">{format(record.created_at, "MM/dd")}</IonText>
                                                </IonCol>
                                                {
                                                    record.type=="Credit" ? (
                                                        <IonCol size="4">
                                                            <IonText color="primary">+</IonText>
                                                            <IonText className="s-14 color-black wg-500">{record.order_value}</IonText>
                                                        </IonCol>
                                                    ) : record.type=="Debit" ? (
                                                        <IonCol size="4">
                                                            <IonText color="danger">-</IonText>
                                                            <IonText className="s-14 color-black wg-500">{record.order_value}</IonText>
                                                        </IonCol>
                                                    ):(
                                                      <></>  
                                                    )
                                                }
                                                <IonCol size="3">
                                                    <IonText className="s-14 color-black wg-500">{record.balance}</IonText>
                                                </IonCol>
                                            </IonRow>
                                        )
                                    })
                                }
                                </>
                            ):(
                                <></>
                            )
                        }
                    </IonCard>
                </IonGrid>
                <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '45px'}}>
                    <IonFabButton
                    className="main-fab"
                    onClick={() => {
                        window.history.back()
                    }}
                    >
                    <IonIcon icon={arrowBackOutline} />
                    </IonFabButton>
                </IonFab>
            </IonContent>
        </IonPage>
    );
};
export default Earnings;