import {
    IonButton,
    IonContent,
    IonPage,
    IonToolbar,
    IonIcon,
    IonLabel,
    IonText,
    IonGrid,
    IonCard,
    IonRow,
    IonCol,
    IonFab,
    IonFabButton,
    IonModal,
    IonTitle,
    IonLoading,
  } from "@ionic/react";
  import "../../css/eclubhouse.css";
  import main_logo from "../../img/eclub.golf.png"
  import { isPlatform } from "@ionic/react";
  import { useEffect, useState } from "react";
  import _ from "lodash";
  import { format } from "date-fns";
  import firebase from "../../js/firebase.js";
  import {
    getFirestore,
    collection,
    query,
    where,
    getDocs,
    orderBy,
  } from "firebase/firestore";
  import { arrowBackOutline } from "ionicons/icons";
  import {
    CheckmarkCircle
  } from '@styled-icons/ionicons-solid';
import { updateEntry } from "../../js/firebase_doc_functions";
  
  const db = getFirestore(firebase);
  
  const ManageTours: React.FC = () => {
    const [current_user, setCurrentUser] = useState<any>([]);
    const [tours, setTours] = useState<any>([]);
    const [showLoading, setShowLoading] = useState(false);
    const [tour, setTour] = useState<any>({
        tour_name: "",
        city: "",
        format: "",
        season_prize_pool: 0,
        weekly_prize_pool: 0,
        fixed_courses: "",
        fixed_tees: "",
        holes: 0,
        selected_courses: [],
        selected_tees: [],
        pull_scores_from: "",
        partner_required: "",
        visibility: "",
        youth_on_course: 0,
        status: "",
        created_at:0,
        created_by:''
    });
    const [show_tour_modal, setShowTourModal] = useState<boolean>(false);
  
    const getTours = async() => {
      const q = query(collection(db, "TOUR_SETTINGS"), where('created_at', '>', 0));
      const querySnapshot = await getDocs(q);
      await Promise.all(querySnapshot.docs.map(async competition => {
        var doc_item = competition.data();
        doc_item.id = competition.id;
        return doc_item;
      }))
      .then(results => {
        setTours(_.sortBy(results, (e: any) => e.created_at).reverse())
      });
    }
    const getTourData = async (tour_id:string) => {
        let tourData = tours.filter((x:any)=> x.id == tour_id);
        if(tourData.length > 0) {
          setTour(tourData[0]);
          setShowTourModal(true);
        } 
    }
    const approveTour = async(id:string) => {
        setShowLoading(true);
        await updateEntry("TOUR_SETTINGS", id, {status: "Approved"});
        getTours();
        setShowLoading(false);
        setShowTourModal(false);
    }
    useEffect(() => {
        let user = localStorage.getItem("firebase_user");
        if (user != null) {
            setCurrentUser(JSON.parse(user));
            getTours();
        }
    }, []);
    // console.log("darkmode checking",document.body.classList.contains('dark'))
    return (
      <IonPage>
        <IonToolbar
          mode="ios"
          className={isPlatform("ios") ? "ios-toolbar-top" : "toolbar-top"}
        >
          <IonLabel slot="start" className="s-18 w-800 color-blue" style={{padding: '0px 0px 0px 10px'}}>Tours</IonLabel>
        </IonToolbar>
        <IonContent>
          <IonGrid className="pad-0">
            <IonRow>
            {
                tours && tours.map((item: any, index:number) => {
                return (
                    <IonCol size="6" key={index} onClick={e=> {
                        getTourData(item.id);
                    }}>
                        <IonCard className="pro-shop-cards">
                            <IonRow className="ion-justify-content-center">
                            <img src={main_logo} alt={item.pack_name} className="tours-pic" />
                            </IonRow>
                            <IonRow className="ion-text-center ion-justify-content-center"> 
                                <IonButton className="pro-button">
                                    {item.tour_name}
                                </IonButton>
                            </IonRow>
                            <IonRow className="ion-text-center ion-justify-content-center">
                                <IonText className="s-14 black wg-600 color-blue">{(item.format || '').split('~')[1]}</IonText>
                            </IonRow>
                            <IonRow className="ion-text-center ion-justify-content-center">
                                {
                                    item.status === "Approved" ? (
                                        <IonText className="s-12 black wg-600 color-blue"><CheckmarkCircle size="18" />{item.status}</IonText>
                                    ) : (
                                        <IonText className="s-12 black wg-600 color-red">{item.status}</IonText>
                                    )
                                }
                            </IonRow>
                        </IonCard>
                    </IonCol>
                )
                })
            }
            </IonRow>
          </IonGrid>
          <IonModal isOpen={show_tour_modal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true} 
            onDidDismiss={() => setShowTourModal(false)} mode="md">
            <IonToolbar
                mode="ios"
                className={
                isPlatform("ios")
                    ? "ios-toolbar-top box-shadow"
                    : "toolbar-top box-shadow"
                }
            >
                <IonTitle>
                <IonLabel>
                    <IonText className="wg-bold s-16">
                    {tour.tour_name}
                    </IonText>
                    <br />
                </IonLabel>
                </IonTitle>
            </IonToolbar>
            <IonContent>
                <IonGrid className="mt-15">
                <IonRow className="ion-justify-content-center ion-text-center mt-10">
                    <IonText className="s-15 black wg-bold">Settings</IonText>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center mt-5">
                    <IonCol size="5.5">
                    <IonText className="s-13 wg-bold black">Format</IonText>
                    </IonCol>
                    <IonCol size="0.5">
                    <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="6">
                    <IonText className="s-13">
                        {(tour.format || '').split('~')[1]}
                    </IonText>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center mt-5">
                    <IonCol size="5.5">
                    <IonText className="s-13 wg-bold black">Partner required</IonText>
                    </IonCol>
                    <IonCol size="0.5">
                    <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="6">
                    <IonText className="s-13">
                        {tour.partner_required}
                    </IonText>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center mt-5">
                    <IonCol size="5.5">
                    <IonText className="s-13 wg-bold black">Season prize pool</IonText>
                    </IonCol>
                    <IonCol size="0.5">
                    <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="6">
                    <IonText className="s-13">
                        {tour.season_prize_pool}
                    </IonText>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center mt-5">
                    <IonCol size="5.5">
                    <IonText className="s-13 wg-bold black">Youth on course</IonText>
                    </IonCol>
                    <IonCol size="0.5">
                    <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="6">
                    <IonText className="s-13">
                        {tour.youth_on_course}
                    </IonText>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center mt-5">
                    <IonCol size="5.5">
                    <IonText className="s-13 wg-bold black">Weekly prize pool</IonText>
                    </IonCol>
                    <IonCol size="0.5">
                    <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="6">
                    <IonText className="s-13">
                        {tour.weekly_prize_pool}
                    </IonText>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center mt-5">
                    <IonCol size="5.5">
                    <IonText className="s-13 wg-bold black">Pull scores from</IonText>
                    </IonCol>
                    <IonCol size="0.5">
                    <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="6">
                    <IonText className="s-13">
                        {tour.pull_scores_from}
                    </IonText>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center mt-5">
                    <IonCol size="5.5">
                    <IonText className="s-13 wg-bold black">Limited courses</IonText>
                    </IonCol>
                    <IonCol size="0.5">
                    <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="6">
                    <IonText className="s-13">
                        {tour.fixed_courses}
                    </IonText>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center mt-5">
                    <IonCol size="5.5">
                    <IonText className="s-13 wg-bold black">Created Time</IonText>
                    </IonCol>
                    <IonCol size="0.5">
                    <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    {console.log("tours",tour)}
                    <IonCol size="6">
                    <IonText className="s-13">
                    {format(new Date(tour.created_at), 'MM/dd/yyyy')}
                    </IonText>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center mt-5">
                    <IonCol size="5.5">
                    <IonText className="s-13 wg-bold black">Created By</IonText>
                    </IonCol>
                    <IonCol size="0.5">
                    <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    {console.log("tours",tour)}
                    <IonCol size="6">
                    <IonText className="s-13">
                    {tour.created_by}
                    </IonText>
                    </IonCol>
                </IonRow>
                {
                    tour.fixed_course == "Yes" ? (
                        <IonRow className="ion-justify-content-center ion-text-center mt-5">
                            <IonCol size="5.5">
                            <IonText className="s-13 wg-bold black">Selected courses</IonText>
                            </IonCol>
                            <IonCol size="0.5">
                            <IonText className="s-13 wg-bold black">:</IonText>
                            </IonCol>
                            <IonCol size="6">
                            {tour.selected_courses && tour.selected_courses.map((item:string, index:number)=> {
                                return (
                                <div  key={index}>
                                <IonText className="s-13">
                                    {item}
                                </IonText>
                                <br/>
                                </div>
                                )
                            })}
                            </IonCol>
                        </IonRow>
                    ) : (
                        <></>
                    )
                }
                {
                    tour.status === "Pending Approval" ? (
                        <IonRow className="ion-justify-content-center ion-text-center mt-15">
                            <IonCol size="3">

                            </IonCol>
                            <IonCol size="6">
                                <IonButton className="pro-button" onClick={e=> {
                                    approveTour(tour.id);
                                }}>
                                    Approve
                                </IonButton>
                            </IonCol>
                            <IonCol size="3">

                            </IonCol>
                        </IonRow>
                    ) : (
                        <></>
                    )
                }
                </IonGrid>
                <IonFab
                vertical="bottom"
                horizontal="end"
                slot="fixed"
                className="back-fab-button"
                style={{ marginBottom: "45px" }}
                >
                <IonFabButton
                    className="main-fab"
                    onClick={() => {
                    setShowTourModal(false);
                    }}
                >
                <IonIcon icon={arrowBackOutline} />
              </IonFabButton>
            </IonFab>
            <IonLoading
                isOpen={showLoading}
                message={'Updating..'}
            />
          </IonContent>
        </IonModal>
          <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '45px'}}>
              <IonFabButton
              className="main-fab"
              onClick={() => {
                window.location.href="/admin_home"
              }}
              >
              <IonIcon icon={arrowBackOutline} />
              </IonFabButton>
          </IonFab>
        </IonContent>
      </IonPage>
    );
  };
  
  export default ManageTours;
  