import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonIcon,IonLabel, IonText, IonButtons, IonRow, IonCol, IonItem, IonModal, isPlatform, IonList, IonRadioGroup, IonListHeader, IonRadio, IonGrid} from '@ionic/react';
import { useEffect,useState } from 'react';
import PayPal from '../Components/paypal_payment';
import firebase from '../../js/firebase.js';
import { getFirestore, collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { chevronBackOutline } from 'ionicons/icons';

const db = getFirestore(firebase);

const Handicap: React.FC = () => {
    const [current_user, setCurrentUser] = useState<any>([]);
    const [set_paypal_payment, setPaypalPayment] = useState(false);
    const [clubs, setClubs] = useState<any>([]);
    const [selected_club, setSelectedClub] = useState<any>();
    const [paypal_data, setPaypalData] = useState<any>();
    const [required_data, setRequiredData] = useState<any>();
    const [handicap_data, setHandicapData] = useState<any>({});

    const getPaypalConfig = async()=> {
        const q = query(collection(db, "CONFIG"), where('type', '==', "PAYPAL"));
        const querySnapshot = await getDocs(q);
        if(querySnapshot && querySnapshot.docs.length > 0) {
            var obj = {
                "currency": "USD",
                "client-id": querySnapshot.docs[0].data().value,
                "components": "buttons"
            }
            setPaypalData(obj);
        } else {
            setPaypalData({});
        }
    }
    const handleCallback = async()=> {
    
    }
    
    const getClubs = async()=> {
        const q = query(collection(db, "CLUBS"), where('created_at', '>', 0), orderBy('created_at', 'desc'));
        const querySnapshot = await getDocs(q);
        if(querySnapshot && querySnapshot.docs.length > 0) {
            await Promise.all(querySnapshot.docs.map(async club => {
                let doc_item = club.data();
                doc_item.id = club.id;
                return doc_item;
            })).then(results=> {
                setClubs(results);
            })
        }
    }

    const buyHandicap = async(club_name:string)=> {
        let selectedClub = clubs.filter((x:any)=> x.club_name == club_name);
        if(selectedClub.length > 0) {
            setHandicapData(selectedClub[0]);
        }
    }

    useEffect(() => {
        let user = localStorage.getItem('firebase_user');
        if(user != null) {
          setCurrentUser(JSON.parse(user));
          getPaypalConfig();
          getClubs();
        }
    }, [])

    return(
        <IonPage>
            <IonToolbar mode="ios" className={isPlatform('ios') ? 'ios-toolbar-top box-shadow': 'toolbar-top box-shadow' }>
                <IonButtons slot="start">
                    <a onClick={()=> window.location.href="/locker_room"}>
                        <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                    </a>
                </IonButtons>
                <IonTitle>
                    Get Handicap Index<sup>&#8482;</sup>
                </IonTitle>
            </IonToolbar>
            <IonContent>
                <IonRow className="ion-justify-content-center ion-text-center mt-15">
                    <IonText className="s-14">If you don’t have a handicap yet and<br/>if you are from one of the regions below;<br/>you could purchase from one of our member clubs.</IonText>
                </IonRow>
                <IonList>
                    <IonRadioGroup value={selected_club} onIonChange={(e) => {
                        setSelectedClub(e.detail.value)
                        buyHandicap(e.detail.value);
                    }}>

                        <IonListHeader className="ion-justify-content-center ion-text-center">
                            <IonLabel>Our Member clubs</IonLabel>
                        </IonListHeader>
                        {
                            clubs && clubs.map((club: any, index:number) => {
                                return(
                                    <IonItem key={index}>
                                        <IonLabel>{club.club_name}<br/><span className='s-12'>({club.assoc_name})</span></IonLabel>
                                        <IonRadio slot="end" value={club.club_name} />
                                    </IonItem>
                                )
                            })
                        }
                    </IonRadioGroup>
                </IonList>   
                <IonRow className="ion-justify-content-center ion-text-center mt-15">
                    <button className='button-close bg-blue wg-bold' style={{width: "50%", height: "35px", borderRadius: "50px", fontSize: "16px"}} 
                    onClick={()=> setPaypalPayment(true)}>
                        Buy Handicap Index<sup>&#8482;</sup>
                    </button>
                </IonRow>
            </IonContent>
            <IonModal isOpen={set_paypal_payment}>
                <>
                <IonHeader mode="ios">
                    <IonToolbar>
                    <IonButtons slot="start">
                        {/* <a onClick={()=> setShowModal(false)}>
                        <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                        </a> */}
                    </IonButtons>
                    <IonTitle>
                       Handicap Payment
                    </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonGrid className="mt-15">
                        <IonRow className="ion-justify-content-center ion-text-center mt-10">                            
                            <IonCol>
                                <IonLabel position="fixed">Name:</IonLabel>                                
                            </IonCol>
                            <IonCol>
                                <IonText>{current_user.first_name+' '+current_user.last_name}</IonText>                            
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center mt-10">                            
                            <IonCol>
                                <IonLabel position="fixed">Club name:</IonLabel>                                
                            </IonCol>
                            <IonCol>
                                <IonText>{handicap_data.club_name}</IonText>                            
                            </IonCol>

                        </IonRow>  
                        <IonRow className="ion-justify-content-center ion-text-center mt-10">                            
                            <IonCol>
                                <IonLabel position="fixed">Assoc name:</IonLabel>                                
                            </IonCol>
                            <IonCol>
                                <IonText>{handicap_data.assoc_name}</IonText>                            
                            </IonCol>
                        </IonRow>      
                        <IonRow className="ion-justify-content-center ion-text-center mt-10">                            
                            <IonCol>
                                <IonText>Registration fee:</IonText>                                
                            </IonCol>
                            <IonCol>
                                <IonText>${handicap_data.registration_fee}</IonText>                            
                            </IonCol>
                        </IonRow> 
                        <IonRow className="ion-justify-content-center ion-text-center mt-10">                            
                            <IonCol>
                                <IonText>Total Processing Charges:</IonText>                                
                            </IonCol>
                            <IonCol>
                                <IonText>{(((handicap_data.registration_fee*3.49)/100)+0.60).toFixed(2)}</IonText>                            
                            </IonCol>
                        </IonRow>         
                        <IonRow className="ion-justify-content-center ion-text-center mt-10">                            
                            <IonCol>
                                <IonLabel position="fixed">Total Cost:</IonLabel>
                            </IonCol>
                            <IonCol>
                            <IonText>{handicap_data.registration_fee+Number((((handicap_data.registration_fee*3.49)/100)+0.60).toFixed(2))}</IonText>                            
                            </IonCol>

                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center mt-25">

                        </IonRow>
                    </IonGrid>
                    <PayPalScriptProvider
                        options={paypal_data}
                    >
                        <PayPal paypal_data={paypal_data} required_data={required_data} referrer={'handicap'} chips_qty={0} amount={0} currency={'USD'} showSpinner={false} parentCallback={handleCallback}/>
                    </PayPalScriptProvider>
                    <IonRow className="display-flex ion-justify-content-center ion-text-center mt-25">
                        <button className='button-close bg-blue wg-bold' style={{width: "50%", height: "35px", borderRadius: "50px", fontSize: "16px"}} onClick={()=> {
                            setPaypalPayment(false)
                        }}>
                        Close
                        </button>
                    </IonRow>
                </IonContent>
                </>        
            </IonModal>
        </IonPage>
    );
}

export default Handicap;
