import { IonContent, IonTitle, IonToolbar, IonIcon,IonLabel,IonButtons, IonGrid, IonSegment, IonSegmentButton, isPlatform } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const Match_Invites: React.FC = () => {
    const history = useHistory();
    const [invites, setInvites] = useState<any>("Packs");   

    return (
        <>        
          <IonToolbar className={isPlatform('ios') ? 'ios-toolbar-top box-shadow': 'toolbar-top box-shadow' }>
            <IonButtons slot="start">
                <a onClick={()=> history.goBack()}>
                  <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                  </a>

              </IonButtons>
            <IonTitle>
              Invites
            </IonTitle>
          </IonToolbar>
          <IonToolbar mode="ios" className="segment-toolbar">
            <IonSegment onIonChange={e => {
                console.log('invites selected', e.detail.value)
                setInvites(e.detail.value)
            }} value={invites} mode="ios">
            <IonSegmentButton value="Packs">
                <IonLabel>Packs</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="Events">
                <IonLabel>Events</IonLabel>
            </IonSegmentButton>
            </IonSegment>
        </IonToolbar>


        <IonContent>
            <IonGrid>
            </IonGrid>
        </IonContent>
        </>
    );
};
export default Match_Invites;

