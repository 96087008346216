import default_profile_logo from "../../img/fewbats.svg";
import {
  IonRefresher,
  IonRefresherContent,
  IonButton,
  IonContent,
  IonPage,
  IonToolbar,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonText,
  IonButtons,
  IonGrid,
  IonSegment,
  IonSegmentButton,
  IonCard,
  IonRow,
  IonCol,
  IonMenuToggle,
  IonFab,
  IonFabButton,
  IonHeader,
  IonInput,
  IonItem,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTitle,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import "../../css/eclubhouse.css";
import { isPlatform } from "@ionic/react";
import {
  arrowBackOutline,
  notificationsOutline, paperPlane, peopleOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import _ from "lodash";
import { format } from "date-fns";
import { RefresherEventDetail } from "@ionic/core";
import {
  Chatbubbles,
  Golf,
  Calendar,
  Grid,
} from '@styled-icons/ionicons-outline';
import {
  Gift
} from '@styled-icons/ionicons-solid';
import { useHistory } from "react-router-dom";
import firebase from "../../js/firebase.js";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { createEntryWithoutId } from "../../js/firebase_doc_functions";
import BottomBar from "../Components/bottom_toolbar";

const db = getFirestore(firebase);

const Proshop: React.FC = () => {
  const [current_user, setCurrentUser] = useState<any>([]);
  const history = useHistory();
  const [proshop_tab_value, setProshopTabValue] = useState<any>("Gift Cards");
  const [yoc_data, setYocData] = useState<any>([]);
  const [giftcard_data, setGiftcardData] = useState<any>([]);
  const [merchandise_data, setMerchandiseData] = useState<any>([]);
  const [showJoinPackModal, setShowJoinPackModal] = useState(false);
  const [selected_pack_id, setSelectedPackId] = useState("");
  const [url, setUrl] = useState<string>("");
  const [notifications, setNotifications] = useState<boolean>(false);
  const [selected_pack, setSelectedPack] = useState<any>([]);
  const [packs_list, setPacksList] = useState<any>([]);
  const [presentToast, dismissToast] = useIonToast();
  const [pack_message, setPackMessage] = useState<string>();
  const [presentAlert, dismissAlert] = useIonAlert();
  const [pack_city, setPackCity] = useState<string>('');

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    console.log("Begin async operation");
    setTimeout(() => {
      console.log("Async operation has ended");
      window.location.href = "/leagues/formats";
      event.detail.complete();
    }, 2000);
  }
  const customAlertOptions = {
    header: "Select Pack",
  };

  const customAlertOptions1 = {
    header: "Select city",
  };
  const getNotificationCounts = async (user_id: string) => {
    const q = query(collection(db, "CHECKIN_NOTIFICATIONS"), where('created_at', '>', 0), where('user_id', '==', user_id));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
      querySnapshot.docs.forEach((notifications)=>{
        const notification = notifications.data();
        if(!notification.match_status && !(notification.match_status=== 'Declined' || notification.match_status=== 'Accepted')){
          setNotifications(true);
        }
      });
    }
  }
  const allPacksList = async (user_packs: any) => {
    const q = query(
      collection(db, "PACKS"),
      where("pack_id", "!=", "kuFOI2OPKCuhCwrewzBqDI8w71IE")
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs && querySnapshot.docs.length > 0) {
      await Promise.all(
        querySnapshot.docs.map(async (pack) => {
          var doc_item = pack.data();
          doc_item.id = pack.id;
          return doc_item;
        })
      ).then((results) => {
        let new_packs = _.differenceBy(results, user_packs, "pack_id");
        console.log("curr", new_packs);
        setPacksList(new_packs);
      });
    } else {
      console.log("No packs available");
    }
  };
  const [filtered_packs, setFilteredPacks] = useState<any>([]);
  const filterPacks = async (city:string) => {
    if(city === "Any") {
      setFilteredPacks(packs_list);
    } else {
      let filtered_packs = packs_list.filter((x:any)=> x.city === city);
      setFilteredPacks(filtered_packs);
    }
  }
  const setPack = async (pack_id: string) => {
    let filtered_pack = packs_list.filter((x: any) => x.pack_id == pack_id);
    if (filtered_pack.length > 0) {
      setSelectedPack(filtered_pack[0]);
    }
  };
  const sendJoinRequest = async () => {
    if (selected_pack && selected_pack.pack_name != null) {
      var obj = {
        created_name: current_user.first_name + " " + current_user.last_name,
        created_by: current_user.user_id,
        type: "JOIN_REQUEST",
        description: pack_message,
        source: {
          user_name: current_user.first_name + " " + current_user.last_name,
          user_id: current_user.user_id,
        },
        is_read: false,
        pack_id: selected_pack.pack_id,
        pack_name: selected_pack.pack_name,
        user_id: selected_pack.pack_lead_id,
      };
      await createEntryWithoutId("PACK_NOTIFICATIONS", obj);
      presentToast("Request sent", 1800);
    }
  };
  const getProShopData = async(type:string) => {
    var user = localStorage.getItem('firebase_user');
    if(user != null) {
      var current_user = JSON.parse(user);
      const q = query(collection(db, "PRO_SHOP"), where('created_at', '>', 0), where('region_id', '==', 'qESHBZOkJ2NStsXeErL5WtlNQoal'), where('type', '==', type), orderBy('created_at', 'desc'));
      const querySnapshot = await getDocs(q);
      await Promise.all(querySnapshot.docs.map(async competition => {
        var doc_item = competition.data();
        doc_item.id = competition.id;
        return doc_item;
      }))
      .then(results => {
        if(type == "Gift Cards") {
          setGiftcardData(results);
        }
        if(type == "Merchandise") {
          setMerchandiseData(results);
        }
        if(type == "Donations") {
          setYocData(results);
        }
      });
    }
  }
  useEffect(() => {
    let user = localStorage.getItem("firebase_user");
    if (user != null) {
      setCurrentUser(JSON.parse(user));
      getProShopData('Gift Cards');
      getNotificationCounts(JSON.parse(user).user_id);
    }

  }, []);
  // console.log("darkmode checking",document.body.classList.contains('dark'))
  return (
    <IonPage>
      <IonToolbar
        mode="ios"
        className={isPlatform("ios") ? "ios-toolbar-top" : "toolbar-top"}
      >
        {/* <IonButtons slot="start" className="slot-start-padding">
          {current_user.logo_url ? (
            <a href="/profile" className="pd-r-10">
              <img src={current_user.logo_url} className="pro_pic" />
            </a>
          ) : (
            <a href="/profile" className="pd-r-10">
              <img src={default_profile_logo} className="pro_pic" />
            </a>
          )}
        </IonButtons> */}
        <IonLabel slot="start" className="s-18 w-800 color-blue" style={{padding: '0px 0px 0px 10px'}}>{proshop_tab_value}</IonLabel>
        {/* <IonTitle>
          <img src={main_logo} className="main_logo" />
        </IonTitle> */}
        <IonButtons slot="end" className="slot-end-padding" onClick={()=>{
          allPacksList(current_user.packs);
          setShowJoinPackModal(true);
        }}>
          <IonIcon icon={peopleOutline} className="s-30 color-blue"/>
        </IonButtons>
        <IonButtons slot="end" style={{padding: '0px 5px 0px 10px'}} onClick={()=>window.location.href="/notifications"}>
          <IonIcon icon={notificationsOutline} className="s-30 color-blue"/>
          {
            notifications ? (
              <span className='notification-dot'></span>
            ) : (
              <></>
            )
          }
        </IonButtons>
        {/* <IonButtons slot="end" style={{padding: '0px 5px 0px 10px'}}>
          {current_user.logo_url ? (
            <a href="/profile" className="pd-r-10">
              <img src={current_user.logo_url} className="pro_pic" />
            </a>
          ) : (
            <a href="/profile" className="pd-r-10">
              <img src={default_profile_logo} className="pro_pic" />
            </a>
          )}
        </IonButtons> */}
      </IonToolbar>
      <IonContent>
        <IonGrid style={{height: '100%'}}>
          {proshop_tab_value == "Gift Cards" ? (
            <IonGrid>
              <IonRow>
              {
                giftcard_data.map((product: any, index:number) => {
                  return (
                    <IonCol size="6" key={index}>
                    <IonCard className="pro-shop-cards">
                      <IonRow className="ion-justify-content-center">
                      <img src={product.product_images.image_1} alt={product.product_name} className="pro-shop-pic" />
                      </IonRow>
                      <IonRow className="ion-text-center ion-justify-content-center">
                          <IonText className="s-14 black wg-600 color-blue">{product.product_name}</IonText>
                      </IonRow>
                      <IonRow className="ion-text-center ion-justify-content-center">
                          <IonText className="s-12 black wg-600 color-blue">{product.product_description}</IonText>
                      </IonRow>
                      <IonRow className="ion-text-center ion-justify-content-center">
                        <IonText className="pro-shop-button pointer">
                          {product.product_value} credits
                        </IonText>
                      </IonRow>
                    </IonCard>
                    </IonCol>
                    // <IonCard className="social-card" key={index}>
                    //   <IonRow className="ion-justify-content-center">
                    //     <IonCol size="4" className="pad-5">
                    //       <>
                    //         <div className="item-avatar" style={{background:`url(${product.product_images.image_1}) no-repeat center center`, backgroundSize: 'contain', width:'100px', height:'100px', borderRadius:'5px'}}>
                    //         </div>
                    //       </>
                    //     </IonCol>
                    //     <IonCol size="8" className="pad-5">
                    //       <IonRow className="pb-10">
                    //         <IonText className="s-16 black wg-600">{product.product_name}</IonText>
                    //       </IonRow>
                    //       <IonRow>
                    //         <IonText className="s-14 black pb-10">
                    //           {product.product_description}
                    //         </IonText>
                    //       </IonRow>
                    //       <IonRow>
                    //         <IonText className="s-12 black pb-10">
                    //           {product.product_details}
                    //         </IonText>
                    //       </IonRow>
                    //       <IonRow className="display-flex justify-content-space-between ">
                    //         <IonText className="s-12 pt-10">
                    //           {product.product_value} credits
                    //         </IonText>
                    //         <IonButton className="pro-button">
                    //           <ShoppingCart className="pro-shop-icon"/>
                    //           Redeem
                    //         </IonButton>
                    //       </IonRow>
                    //     </IonCol>
                    //   </IonRow>
                    // </IonCard>
                  )
                })
              }
              </IonRow>
            </IonGrid>
          ) : proshop_tab_value == "Merchandise" ? (
            <IonGrid>
              <IonRow>
              {
                merchandise_data.map((product: any, index:number) => {
                  return (
                    <IonCol size="6" key={index}>
                      <IonCard className="pro-shop-cards">
                        <IonRow className="ion-justify-content-center">
                        <img src={product.product_images.image_1} alt={product.product_name} className="pro-shop-pic" />
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonText className="s-14 black wg-600 color-blue">{product.product_name}</IonText>
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonText className="s-12 black wg-600 color-blue">{product.product_description}</IonText>
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center">
                          <IonText className="pro-shop-button pointer">
                            {product.product_value} credits
                          </IonText>
                        </IonRow>
                      </IonCard>
                    </IonCol>
                  )
                })
              }
              </IonRow>
            </IonGrid>
          ) : proshop_tab_value == "Donations" ? (
            <IonGrid>
              <IonRow>
              {
                yoc_data.map((product: any, index:number) => {
                  return (
                    <IonCol size="6" key={index}>
                      <IonCard className="pro-shop-cards">
                        <IonRow className="ion-justify-content-center">
                        <img src={product.product_images.image_1} alt={product.product_name} className="pro-shop-pic" />
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonText className="s-14 black wg-600 color-blue">{product.product_name}</IonText>
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonText className="s-12 black wg-600 color-blue">{product.product_description}</IonText>
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center">
                          <IonText className="pro-shop-button pointer">
                            {product.product_value} credits
                          </IonText>
                        </IonRow>
                      </IonCard>
                    </IonCol>
                  )
                })
              }
              </IonRow>
            </IonGrid>
          ) : (
            <></>
          )}
        </IonGrid>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {/* <IonLabel>
          <p className="text"> Your email is <br></br> </p>
          <p><a href="/home">{email}</a></p>
          <p><a href="https://centrallinksgolf.org" target="_blank">CLG</a></p>
        </IonLabel><br></br> */}
        <IonModal
          isOpen={showJoinPackModal}
          showBackdrop={true}
          className="join-pack-modal"
          backdropDismiss={true}
          initialBreakpoint={0.7}
          onDidDismiss={() => setShowJoinPackModal(false)} mode="md"
        >
          <IonHeader mode="ios">
            <IonToolbar>
              <IonTitle>Join a Pack</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid className="">
              {selected_pack && selected_pack.pack_name ? (
                <>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <img src={selected_pack.logo_url} width="80" height="80" />
                    <br />
                  </IonRow>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <IonText>Pack name: {selected_pack.pack_name}</IonText>
                  </IonRow>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <IonText>City: {selected_pack.city}</IonText>
                  </IonRow>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <IonText>Pack Type: {selected_pack.pack_type}</IonText>
                  </IonRow>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <IonText>Description: {selected_pack.description}</IonText>
                    <br />
                  </IonRow>
                </>
              ) : (
                <></>
              )}
              <IonRow className="ion-justify-content-center mt-10">
                <IonSelect value={pack_city}
                  interfaceOptions={customAlertOptions1}
                  interface="popover"
                  placeholder="Select a City" className="s-14 select-pack-dropdown" onIonChange={e => {
                    setPackCity(e.detail.value)
                    filterPacks(e.detail.value)
                  }} mode="ios">
                  <IonSelectOption value="Any">Any</IonSelectOption>
                  <IonSelectOption value="Kansas City">Kansas City Metro</IonSelectOption>
                  <IonSelectOption value="St. Louis">St. Louis, MO</IonSelectOption>
                  <IonSelectOption value="Des Moines">Des Moines, IA</IonSelectOption>
                  <IonSelectOption value="Wichita">Wichita, KS</IonSelectOption>
                  <IonSelectOption value="Salt Lake City">Salt Lake City, UT</IonSelectOption>
                  <IonSelectOption value="Topeka">Topeka, KS</IonSelectOption>
                  <IonSelectOption value="Salina">Salina, KS</IonSelectOption>
                  <IonSelectOption value="Manhattan">Manhattan, KS</IonSelectOption>
                  <IonSelectOption value="Hutchinson">Hutchinson, KS</IonSelectOption>
                  <IonSelectOption value="Lawrence">Lawrence, KS</IonSelectOption>
                  <IonSelectOption value="Leavenworth">Leavenworth, KS</IonSelectOption>
                  <IonSelectOption value="Denver">Denver, CO</IonSelectOption>
                </IonSelect>
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10">
                <IonSelect
                  value={selected_pack_id}
                  interfaceOptions={customAlertOptions}
                  interface="popover"
                  placeholder="Select Pack"
                  className="s-14 select-pack-dropdown"
                  onIonChange={(e) => {
                    setSelectedPackId(e.detail.value);
                    setPack(e.detail.value);
                  }}
                  mode="ios"
                >
                  {filtered_packs &&
                    filtered_packs.map((pack: any, index: number) => {
                      return (
                        <IonSelectOption value={pack.pack_id} key={index}>
                          {pack.pack_name}
                        </IonSelectOption>
                      );
                    })}
                </IonSelect>
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10">
                <IonItem
                  lines="none"
                  className="select-pack-dropdown"
                  style={{ height: "43px" }}
                >
                  <IonInput
                    value={pack_message}
                    placeholder="Message"
                    onIonChange={(e) => setPackMessage(e.detail.value!)}
                    clearInput
                  ></IonInput>
                </IonItem>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-25">
                <button
                  className="button-send white s-15"
                  onClick={sendJoinRequest}
                >
                  <IonIcon icon={paperPlane} className="white" />
                  <IonText className="pl-5 send-request">Send Request</IonText>
                </button>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-25">
                <IonText>Or you can <span className="create-button-outline color-blue" onClick={()=>{
                  window.location.href="/create_pack"
                }}>create</span> your own pack</IonText>
              </IonRow>
            </IonGrid>
            <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '45px'}}>
                <IonFabButton
                className="main-fab"
                onClick={() => {
                  setShowJoinPackModal(false)
                }}
                >
                <IonIcon icon={arrowBackOutline} />
                </IonFabButton>
            </IonFab>
          </IonContent>
        </IonModal>
      </IonContent>
      <IonToolbar mode="ios" className="segment-toolbar" slot="bottom">
        <IonSegment
          onIonChange={(e) => {
            setProshopTabValue(e.detail.value);
            if (e.detail.value == "Gift Cards") {
              getProShopData("Gift Cards");
            } else if (e.detail.value == "Merchandise") {
              getProShopData("Merchandise");
            } else if(e.detail.value == "Donations") {
              getProShopData("Donations");
            }
          }}
          value={proshop_tab_value}
          mode="ios"
          className="new-segment"
        >
          <IonSegmentButton value="Gift Cards" className="new-segment-button">
            <IonLabel className="new-segment-button-label">Gift Cards</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="Merchandise" className="new-segment-button">
            <IonLabel className="new-segment-button-label">Merchandise</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="Donations" className="new-segment-button">
            <IonLabel className="new-segment-button-label">Donations</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </IonToolbar>
      <BottomBar cur_page="proshop"/>
    </IonPage>
  );
};

export default Proshop;
