import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonTabBar, IonTabButton, IonIcon,IonLabel,IonButtons, IonGrid, IonFab, IonFabButton, IonCard, IonCol, IonRow, IonText, IonModal, IonSelect, IonSelectOption } from '@ionic/react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { format } from 'date-fns';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { addCircleSharp, arrowBackOutline, receiptSharp } from 'ionicons/icons';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import firebase from '../../js/firebase';
import PayPal from '../Components/paypal_payment';

const db = getFirestore(firebase);

const Wallet: React.FC = () => {
    const [wallet,setWallet]= useState<any>();
    const [current_user, setCurentUser] = useState<any>();
    const [chips_value,setChipsValue]= useState<any>(0);
    const [required_data, setRequiredData] = useState<any>();
    const [set_paypal_payment, setPaypalPayment] = useState(false);
    const customAlertOptions = {
        header: 'Select Chips',
        translucent: true
    };
    const [paypal_data, setPaypalData] = useState<any>();

    const chips = [{"price":"50","value":50},
      {"price":"100","value":100},
      {"price":"150","value":150},
      {"price":"200","value":200},
      {"price":"250","value":250},
      {"price":"300","value":300},
      {"price":"350","value":350},
      {"price":"400","value":400},
      {"price":"450","value":450},
      {"price":"500","value":500},
    ];

    const getPaypalConfig = async()=> {
        const q = query(collection(db, "CONFIG"), where('type', '==', "PAYPAL"));
        const querySnapshot = await getDocs(q);
        if(querySnapshot && querySnapshot.docs.length > 0) {
            var obj = {
                "currency": "USD",
                "client-id": querySnapshot.docs[0].data().value,
                "components": "buttons"
            }
            setPaypalData(obj);
        } else {
            setPaypalData({});
        }
    }
    const handleCallback = async()=> {
    
    }
    const [transactions, setTransactions] = useState<any>([]);
    const getTransactions = async(user:any)=> {
        const q = query(
            collection(db, "CHIPS_TRANSACTION_LOGS"),
            where("created_at", ">", 0),
            where("user_id", "==", user.user_id)
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot.docs && querySnapshot.docs.length > 0) {
            await Promise.all(
                querySnapshot.docs.map(async (documentSnapshot) => {
                    var doc_item = documentSnapshot.data();
                    doc_item.id = documentSnapshot.id;
                    return doc_item;
                })
            ).then((results) => {
                setTransactions(_.sortBy(results, (e: any) => e.created_at).reverse());
            });
        }
    }

    useEffect(() => {
        let user = localStorage.getItem('firebase_user');
        if(user != null) {
          setCurentUser(JSON.parse(user));
          setWallet(JSON.parse(user).chips);
          getPaypalConfig();
          getTransactions(JSON.parse(user));
        }
    }, []);
    const [wallet_bottom_tab,setWalletBottomTab] = useState<any>("Balance");
    return (
        <IonPage>
        <IonHeader>
          <IonToolbar mode="ios">
            {/* <IonButtons slot="start">
                <a onClick={()=> window.history.back()}>
                    <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                </a>
            </IonButtons> */}
            <IonTitle>
                Wallet ({wallet} chips)
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
            {
                (wallet_bottom_tab==="Balance")?(
                    <IonGrid>
                        <IonRow className="ion-justify-content-center ion-text-center mt-10">
                            {/* <IonItem lines="none" style={{margin:"auto"}}>
                            <img src={ require(`../../img/casino.png`).default}  width="70" height="70"/>
                            <IonLabel>Balance: {wallet} chips</IonLabel>
                            </IonItem> */}
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center mt-25">
                            <IonText className="s-18 wg-600 myclub-header">
                                Add Balance
                            </IonText>
                        </IonRow>
                        <IonRow className="ion-justify-content-center mt-10">
                            <IonSelect value={chips_value} 
                                interfaceOptions={customAlertOptions}
                                interface="popover"                        
                                placeholder="Chip count" className="s-14 select-pack-dropdown" 
                                onIonChange={e => {
                                    setChipsValue(e.detail.value)
                                }} mode="ios">
                                {
                                    (chips && chips.map((key:any,i:number)=>{
                                        return(
                                            <IonSelectOption value={key.value} key={i}>{key.price}</IonSelectOption>
                                        )
                                    }))
                                }                                    
                                
                            </IonSelect>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center mt-10">
                            <IonCol>
                            <IonText className="s-16">
                                Chip Value<br/>
                            </IonText>
                            <IonText className="s-16 wg-600">{chips_value}</IonText>
                            <IonText className="s-16 pl-5">*</IonText>
                            <IonText className="s-16 pl-5">$0.1</IonText>
                            <IonText className="s-16 pl-5">=</IonText>
                            <IonText className="s-16 wg-600">${chips_value*0.1}</IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center mt-25">
                            <button className='button-send white s-15' onClick={()=>{
                                if(chips_value>0){
                                    setPaypalPayment(true)
                                }
                            }}>                            
                                <IonText className="tt-upper">Proceed to pay</IonText>
                            </button>
                        </IonRow>
                </IonGrid>
                ):(
                <IonGrid>
                    <IonRow className="ion-justify-content-center ion-text-center mt-10">
                        {/* <IonItem lines="none" style={{margin:"auto"}}>
                        <img src={ require(`../../img/casino.png`).default}  width="70" height="70"/>
                        <IonLabel>Balance: {wallet} chips</IonLabel>
                        </IonItem> */}
                    </IonRow>
                    <IonCard className="social-card">
                        <IonRow className="ion-justify-content-center ion-text-center bb-line">
                            <IonCol size="2">
                                <IonText className="s-14 wg-600 color-dark-gray">SNo</IonText>
                            </IonCol>
                            <IonCol size="3">
                                <IonText className="s-14 wg-600 color-dark-gray">Date</IonText>
                            </IonCol>
                            <IonCol size="4">
                                <IonText className="s-14 wg-600 color-dark-gray">Debit/Credit</IonText>
                                </IonCol>
                                <IonCol size="3">
                                <IonText className="s-14 wg-600 color-dark-gray">Balance</IonText>
                            </IonCol>
                        </IonRow>
                        {
                            (transactions.length > 0)?(
                                <>
                                {
                                    transactions.map((record: any, index: number) => {
                                        return(
                                            <IonRow className="ion-justify-content-center ion-text-center bb-line" key={index}>
                                                <IonCol size="2">
                                                    <IonText className="s-14 color-black wg-500">{index+1}</IonText>
                                                </IonCol>
                                                <IonCol size="3">
                                                    <IonText className="s-14 color-black wg-500">{format(new Date(record.date), "MM/dd")}</IonText>
                                                </IonCol>
                                                {
                                                    record.type=="Credit" ? (
                                                        <IonCol size="4">
                                                            <IonText color="primary">+</IonText>
                                                            <IonText className="s-14 color-black wg-500">{record.credit_amount}</IonText>
                                                        </IonCol>
                                                    ) : record.type=="Debit" ? (
                                                        <IonCol size="4">
                                                             <IonText color="danger">-</IonText>
                                                            <IonText className="s-14 color-black wg-500">{record.debit_amount}</IonText>
                                                        </IonCol>
                                                    ): record.type="Referral Bonus" ? (
                                                        <IonCol size="4">
                                                            <IonText color="primary">+</IonText>
                                                            <IonText className="s-14 color-black wg-500">{record.credit_amount}(Referral)</IonText>
                                                        </IonCol>
                                                    ): (
                                                        <IonCol size="4">
                                                            <IonText color="primary">+</IonText>
                                                            <IonText className="s-14 color-black wg-500">{record.credit_amount}({record.type})</IonText>
                                                        </IonCol>
                                                    )
                                                }
                                                <IonCol size="3">
                                                    <IonText className="s-14 color-black wg-500">{record.balance}</IonText>
                                                </IonCol>
                                            </IonRow>
                                        )
                                    })
                                }
                                </>
                            ):(
                                <></>
                            )
                        }
                    </IonCard>
                </IonGrid>
                )
            }
                    
            <IonModal isOpen={set_paypal_payment}>
                <>
                <IonHeader mode="ios">
                    <IonToolbar>
                    <IonButtons slot="start">
                        {/* <a onClick={()=> setShowModal(false)}>
                        <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                        </a> */}
                    </IonButtons>
                    <IonTitle>
                        Payment
                    </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonGrid className="mt-15">
                        <IonRow className="ion-justify-content-center ion-text-center mt-10">                            
                            <IonCol>
                                <IonLabel position="fixed">Total Chips:</IonLabel>                                
                            </IonCol>
                            <IonCol>
                                <IonText>{chips_value}</IonText>                            
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center mt-10">                            
                            <IonCol>
                                <IonLabel position="fixed">Total Price:</IonLabel>                                
                            </IonCol>
                            <IonCol>
                                <IonText>${chips_value*0.1}</IonText>                            
                            </IonCol>

                        </IonRow>      
                        <IonRow className="ion-justify-content-center ion-text-center mt-10">                            
                            <IonCol>
                                <IonText>Total Processing Charges:</IonText>                                
                            </IonCol>
                            <IonCol>
                                <IonText>{((((chips_value*0.1)*3.49)/100)+0.60).toFixed(2)}</IonText>                            
                            </IonCol>
                        </IonRow>      
                        <IonRow className="ion-justify-content-center ion-text-center mt-10">                            
                            <IonCol>
                                <IonLabel position="fixed">Total Cost:</IonLabel>
                            </IonCol>
                            <IonCol>
                            <IonText>{(chips_value*0.1)+Number(((((chips_value*0.1)*3.49)/100)+0.60).toFixed(2))}</IonText>                            
                            </IonCol>

                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center mt-25">

                        </IonRow>
                    </IonGrid>
                    <PayPalScriptProvider
                        options={paypal_data}
                    >
                        <PayPal paypal_data={paypal_data} required_data={required_data} referrer={'chips'} chips_qty={chips_value} amount={0} currency={'USD'} showSpinner={false} parentCallback={handleCallback}/>
                    </PayPalScriptProvider>
                    <IonRow className="display-flex ion-justify-content-center ion-text-center mt-25">
                        <button className='button-close bg-blue wg-bold' style={{width: "50%", height: "35px", borderRadius: "50px", fontSize: "16px"}} onClick={()=> setPaypalPayment(false)}>
                        Close
                        </button>
                    </IonRow>
                </IonContent>
                </>        
            </IonModal>
            <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '45px'}}>
                <IonFabButton
                className="main-fab"
                onClick={() => {
                    window.history.back()
                }}
                >
                <IonIcon icon={arrowBackOutline} />
                </IonFabButton>
            </IonFab>
        </IonContent>
        <IonTabBar slot="bottom">
            <IonTabButton tab="balance" className={(wallet_bottom_tab==="Balance")?'tab-selected color-red':''}>
            <IonIcon icon={addCircleSharp} onClick={()=>{
                setWalletBottomTab("Balance")
            }}/>
            <IonLabel onClick={()=>{
                setWalletBottomTab("Balance")
            }}>Balance</IonLabel>
            </IonTabButton>
            <IonTabButton tab="transactions" className={(wallet_bottom_tab==="Transactions")?'tab-selected color-red':''}>
            <IonIcon icon={receiptSharp} onClick={()=>{
                setWalletBottomTab("Transactions")
            }}/>
            <IonLabel onClick={()=>{
                setWalletBottomTab("Transactions")
            }}>Transactions</IonLabel>
            </IonTabButton>
        </IonTabBar>
        </IonPage>
    );
};
export default Wallet;