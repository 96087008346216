import { IonContent, IonPage, IonGrid,  IonCol, IonItem, IonRow, IonText, IonInput, IonSelect, IonSelectOption, IonRange } from '@ionic/react';
import { useEffect, useState } from 'react';
import { updateEntry } from '../../js/firebase_doc_functions';
import firebase from '../../js/firebase.js';
import {
    Golf
} from '@styled-icons/ionicons-solid';
import { getFirestore} from "firebase/firestore";

const db = getFirestore(firebase);

const Questions: React.FC = () => {
    const [current_user, setCurrentUser] = useState<any>({});
    const [zipcode,setZipCode] = useState<any>("");
    const [avg_putts,setAvgPutts] = useState<any>(90);
    const [driving_distance, setDrivingDistance] = useState<string>('Select distance');
    const [tee,setTee] = useState<any>("Middle");
    const [gender, setGender] = useState<any>("Male");
    const [tee_value, setTeeValue] = useState<any>(3);

    const customAlertOptions2 = {
        header: 'Select score',
      };
    const customAlertOptions1 = {
    header: 'Select gender',
    translucent: true    
    };
    const customAlertOptions3 = {
        header: 'Total driving distance',
        translucent: true    
    };
    //let putts = [65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108];
    let putts = [{quota: 36, option: 'Low 70s'}, {quota: 33, option: 'Mid 70s'}, {quota: 30, option: 'High 70s'}, {quota: 27, option: 'Low 80s'}, {quota: 24, option: 'Mid 80s'}, {quota: 21, option: 'High 80s'}, {quota: 18, option: 'Low 90s'}, {quota: 15, option: 'Mid 90s'}, {quota: 12, option: 'High 90s'}, {quota: 9, option: 'Above 100'}] 
    let drive_lengths = [{low: 0, high: 179, text: '179 yds or less'}, {low: 180, high: 209, text: '180 to 209 yds'}, {low: 200, high: 219, text: '210 to 239 yds'}, {low: 220, high: 239, text: '240 to 269 yds'}, {low: 260, high: 300, text: '270 yds or more'}]
    
    const addNewUserDetails= async()=> {
        var quota = putts.filter((x:any)=> x.option == avg_putts)[0].quota;

        // if(avg_putts < 72) {
        //     quota = 36;
        // } else if(avg_putts > 99) {
        //     quota = 27;
        // } else if(avg_putts < 99 && avg_putts > 72) {
        //     let net_score = avg_putts-72;
        //     quota = 36-net_score;
        // }
        let drive_length = drive_lengths.filter((x:any)=> x.text == driving_distance);
        let user = current_user;
        user.preferred_tee = tee;
        user.zip_code = zipcode;
        user.beginning_quota = quota;
        user.quota = quota;
        user.gender = gender;
        user.avg_putts = putts.filter((x:any)=> x.option == avg_putts)[0].option;
        user.driving_distance = drive_length[0];
        localStorage.setItem('firebase_user', JSON.stringify(user));
        await updateEntry("USER_PROFILE", user.id, {preferred_tee: tee, beginning_quota: user.beginning_quota, quota: quota, gender: gender, zip_code: parseInt(zipcode), driving_distance: drive_length[0]});
        setTimeout(() => window.location.href="/leagues/formats", 1000);
    }
    const handleChange = (tees:any)=>{
        switch(tees){
            case "179 yds or less":{
                setTee('Front');
                break;
            }
            case '180 to 209 yds':{
                setTee('Middle Front');
                break;
            }
            case '210 to 239 yds':{
                setTee('Middle');
                break;
            }
            case '240 to 269 yds':{
                setTee('Middle Back');
                break;
            }
            case '270 yds or more':{
                setTee('Back');
                break;
            }
            default:{
                return
            }
        }
    }

    useEffect(() => {
        let user = localStorage.getItem('firebase_user');
        if(user != null) {
            setCurrentUser(JSON.parse(user));
        }
    }, [])
    return (
        <IonPage>
            <IonContent>
                <IonGrid className="grid-1-bg h-100">
                    <IonRow className="ion-justify-content-center vh-12 s-20 wg-600"> 
                        Welcome to eClubgolf                     
                    </IonRow>
                    <br/>
                    <IonRow className="ion-justify-content-center s-16"> 
                        We need a few details here..                       
                    </IonRow>
                    <br/>
                    {/* <IonText className="s-13 black pl-20">What is your zipcode?</IonText>
                    <IonRow className="ion-justify-content-center mt-5">                        
                        <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                        <IonInput
                        value={zipcode}                        
                        placeholder="ZipCode"
                        type='number'
                        className="s-14 custom-input"
                        min='0'
                        onIonChange={(e:any) => {    
                            e.target.value = String(e.target.value).slice(0,5)                                  
                            setZipCode(e.target.value)                                                                                                                                          
                        }}
                        // clearInput
                        ></IonInput>
                        </IonItem>
                    </IonRow>
                    <br/> */}
                    <IonText className="s-13 black pl-20">Your gender?</IonText>
                    <IonRow className="ion-justify-content-center mt-5">                    
                        <IonSelect value={gender} 
                        interfaceOptions={customAlertOptions1}
                        interface="popover"                        
                        placeholder="Select gender" className="s-14 select-pack-dropdown" 
                        onIonChange={e => setGender(e.detail.value!)} mode="ios">
                            <IonSelectOption value="Male">Male</IonSelectOption>
                            <IonSelectOption value="Female">Female</IonSelectOption>
                            <IonSelectOption value="Other">Prefer not to say</IonSelectOption>
                        </IonSelect>
                    </IonRow>
                    <br/>
                    <IonText className="s-13 black pl-20">What is your total driving distance?</IonText>
                    <IonRow className="ion-justify-content-center mt-5">                    
                        <IonSelect value={driving_distance} 
                        interfaceOptions={customAlertOptions3}
                        interface="popover"      
                        name="distance"                      
                        placeholder="Driving distance" className="s-14 select-pack-dropdown" 
                        onIonChange={(e) => {
                            setDrivingDistance(e.detail.value!); 
                            handleChange(e.detail.value!)}} mode="ios">
                            {
                                drive_lengths.map((key:any,i:number)=>{
                                return(
                                    <IonSelectOption value={key.text} key={i}>{key.text}</IonSelectOption>
                                )
                                })
                            }  
                        </IonSelect>
                    </IonRow>
                    <br/>
                    <IonText className="s-13 black pl-20">What do you typically shot over 18 holes?</IonText>
                    <IonRow className="ion-justify-content-center mt-5">                    
                        <IonSelect value={avg_putts} 
                        interfaceOptions={customAlertOptions2}
                        interface="popover"      
                        name="holes"                      
                        placeholder="Select score" className="s-14 select-pack-dropdown" 
                        onIonChange={e => setAvgPutts(e.detail.value!)} mode="ios">
                            {
                                putts.map((key:any,i:number)=>{
                                return(
                                    <IonSelectOption value={key.option} key={i}>{key.option}</IonSelectOption>
                                )
                                })
                            }  
                        </IonSelect>
                    </IonRow>
                    <br/>
                    {/* <IonText className="s-13 black pl-20">What tees do you generallly prefer?</IonText>
                    <IonRange min={1} max={5} step={1} snaps={true} color="transparent" value={tee_value} className="custom-ion-range-slider" 
                        onIonChange={(e)=>{
                            setTeeValue(e.detail.value);
                            if(e.detail.value == 1) {
                                setTee("Back");
                            }
                            if(e.detail.value == 2) {
                                setTee("Middle Back");
                            }
                            if(e.detail.value == 3) {
                                setTee("Middle");
                            }
                            if(e.detail.value == 4) {
                                setTee("Middle Front");
                            }
                            if(e.detail.value == 5) {
                                setTee("Front");
                            }
                        }}>
                        <IonText slot="start" className="s-12">Back</IonText>
                        <IonText slot="end" className="s-12">Front</IonText>
                    </IonRange>
                    <IonRow className="ion-text-center ion-justify-content-center">
                        <IonText className="s-12">Preferred tees: <b>{tee}</b></IonText>
                    </IonRow> */}
                
                    <IonRow className="ion-justify-content-center ion-text-center mt-25">
                        <button className='button-send white s-15 bg' onClick={()=>{
                            addNewUserDetails()
                        }}>                            
                            <IonText className="wg-bold">Get Started</IonText>
                        </button>
                    </IonRow>

                </IonGrid> 
            </IonContent>

        </IonPage>
    );
};
export default Questions;