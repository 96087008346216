import default_profile_logo from "../../img/fewbats.svg";
import Table from '../Components/table';
import {
  IonRange,
  isPlatform,
} from "@ionic/react";
import {
  IonModal,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonLabel,
  IonButtons,
  IonGrid,
  IonFab,
  IonFabButton,
  IonCard,
  IonCardContent,
  IonRow,
  IonCol,
  IonText,
} from "@ionic/react";
import "../../css/locker_room.css";
import {
  informationCircleOutline,
  chevronDownOutline,
  chevronUpOutline,
  arrowBackOutline,
  checkmarkCircle,
  closeCircle
} from "ionicons/icons";
import {
  useEffect,
  useState,
} from "react";
import _ from "lodash";
import { format } from "date-fns";
import firebase from "../../js/firebase.js";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useParams } from "react-router";
const db = getFirestore(firebase);

const PlayerProfile: React.FC = () => {
  let {id}:any = useParams(); 
  const [current_user, setCurrentUser] = useState<any>({});
  const [best_round, setBestRound] = useState<any>([]);
  const [user_id, setUserId] = useState<string>('');
  const [tee_value, setTeeValue] = useState<any>();
  const [front_holes, setFrontHoles] = useState<boolean>(true);
  const [back_holes, setBackHoles] = useState<boolean>(true);
  const [best_rounds, setBestRounds] = useState<any>([]);
  const [all_rounds, setAllRounds] = useState<any>([]);
  const [other_rounds, setOtherRounds] = useState<any>([]);
  const [profile_data, setProfileData] = useState<any>({});
  const [latest_round, setLatestRound] = useState<any>({});
  const getUserData = async(user_id: string)=> {
    const userQuery = query(
      collection(db, "USER_PROFILE"),
      where("user_id", "==", user_id)
    );
    const userQuerySnapshot = await getDocs(
      userQuery
    );
    if(userQuerySnapshot.docs && userQuerySnapshot.docs.length > 0) {
      let user = userQuerySnapshot.docs[0].data();
      user.id = userQuerySnapshot.docs[0].id;
      if(user.preferred_tee && user.preferred_tee == "Back") {
        setTeeValue(1);
      }
      if(user.preferred_tee && user.preferred_tee == "Middle Back") {
        setTeeValue(2);
      }
      if(user.preferred_tee && user.preferred_tee == "Middle") {
        setTeeValue(3);
      }
      if(user.preferred_tee && user.preferred_tee == "Middle Front") {
        setTeeValue(4);
      }
      if(user.preferred_tee && user.preferred_tee == "Front") {
        setTeeValue(5);
      }
      setCurrentUser(user);
    } 
  }
  const getProfileData = async (user_id: string) => {
    const roundsClassificationQuery = query(
      collection(db, "CONFIG"),
      where("type", "==", "ROUNDS_CLASSIFICATION")
    );
    const roundsClassificationSnapshot = await getDocs(
      roundsClassificationQuery
    );
    const scoresQuery = query(
      collection(db, "SCORE_RECORDS"),
      where("created_at", ">", 0),
      where("user_id", "==", user_id),
      where("score_submitted", "==", true)
    );
    const scoresSnapshot = await getDocs(scoresQuery);
    await Promise.all(
      scoresSnapshot.docs.map(async (competition) => {
        var doc_item = competition.data();
        doc_item.id = competition.id;
        let courseStr = doc_item.course_name.split("-");
        if (courseStr[0] != undefined || courseStr[0] != null)
          doc_item.course_name = courseStr[0];
        if (courseStr[1] != undefined || courseStr[1] != null)
          doc_item.course_sub_name = courseStr[1];
        if (doc_item.tee) {
          let teeset = doc_item.tee.split("~");
          doc_item.tee =
            doc_item.holes == 18 ? teeset[0] : teeset[0] + "~" + teeset[1];
        }
        if (doc_item.points) {
          //doc_item.points.birdie = doc_item.points.birdie + doc_item.points.eagle + doc_item.points.double_eagle;
          doc_item.total_points = doc_item.points.total_points;
        } else {
          doc_item.points = {
            triple_bogey: 0,
            double_bogey: 0,
            bogey: 0,
            par_value: 0,
            birdie: 0,
            eagle: 0,
            double_eagle: 0,
            total_points: 0,
          };
          doc_item.total_points = 0;
        }
        return doc_item;
      })
    ).then((results) => {
      if (results.length > 0) {
        let roundsClassification =
          roundsClassificationSnapshot.docs &&
          roundsClassificationSnapshot.docs.length > 0
            ? roundsClassificationSnapshot.docs[0].data()
            : new Array();
        var classificationSetup =
          results.length > 10
            ? roundsClassification.rounds.filter(
                (round: any) => round.count == 10
              )
            : roundsClassification.rounds.filter(
                (round: any) => round.count == results.length
              );
        var latestRound = _.sortBy(
          results,
          (e) => e.competition_date_timestamp
        ).reverse()[0];
        var sortedRecords = _.sortBy(results, (e) => e.total_points).reverse();
        var bestRecords = new Array();
        var otherRecords = new Array();
        //var allRecords = new Array();
        const allRecords = _.sortBy(sortedRecords, (e: any) => e.competition_date_timestamp).reverse();
        setAllRounds(allRecords);
        bestRecords = sortedRecords
          .map((record, idx) => {
            if (idx < classificationSetup[0].best) {
              record.best = true;
              return record;
            }
          })
          .filter((record) => record);
        otherRecords = sortedRecords
          .map((record, idx) => {
            if (
              idx > classificationSetup[0].best &&
              idx <= classificationSetup[0].count
            ) {
              record.best = false;
              return record;
            }
          })
          .filter((record) => record);
        var bestRound = bestRecords[0] || new Array();
        let bestRoundStrokesData = bestRound.hole_details.map((rec: any) => {
          return rec.raw_score;
        });
        let birdieBogeyParTotal =
          bestRound.points.birdie +
          bestRound.points.par_value +
          bestRound.points.bogey;
        var bestRoundTableData = {
          strokes: bestRoundStrokesData.reduce(
            (a: number, b: number) => a + b,
            0
          ),
          total_points: bestRound.points.total_points,
          birdie: bestRound.points.birdie,
          par: bestRound.points.par_value,
          bogey: bestRound.points.bogey,
          double_bogey: bestRound.points.double_bogey,
          triple_bogey: bestRound.points.triple_bogey,
          others: 18 - birdieBogeyParTotal,
        };
        var bestRoundsTableData = {
          strokes: 0,
          total_points: 0,
          birdie: 0,
          par: 0,
          bogey: 0,
          double_bogey: 0,
          triple_bogey: 0,
          others: 0,
          count: classificationSetup[0].best,
        };
        var otherRoundsTableData = {
          strokes: 0,
          total_points: 0,
          birdie: 0,
          par: 0,
          bogey: 0,
          double_bogey: 0,
          triple_bogey: 0,
          others: 0,
          count: classificationSetup[0].other,
        };
        let bestRoundsPointsData = bestRecords.map((record: any) => {
          if (record.holes == 9) {
            record.total_points = record.total_points * 2;
            return record.total_points;
          } else {
            return record.total_points;
          }
        });
        bestRoundsTableData.total_points = Math.round(
          bestRoundsPointsData.reduce((a, b) => a + b, 0) / bestRecords.length
        );
        let bestRoundsStrokesData = new Array();
        bestRecords.map((record: any) => {
          let rawScoresData = record.hole_details.map((rec: any) => {
            return rec.raw_score;
          });
          bestRoundsStrokesData = bestRoundsStrokesData.concat(rawScoresData);
          return record;
        });
        bestRoundsTableData.strokes = Math.floor(
          bestRoundsStrokesData.reduce((a, b) => a + b, 0) / bestRecords.length
        );
        let bestRoundsBirdieData = bestRecords.map((record: any) => {
          return record.points.birdie;
        });
        bestRoundsTableData.birdie = Math.round(
          bestRoundsBirdieData.reduce((a, b) => a + b, 0) / bestRecords.length
        );
        let bestRoundsParData = bestRecords.map((record: any) => {
          return record.points.par_value;
        });
        bestRoundsTableData.par = Math.round(
          bestRoundsParData.reduce((a, b) => a + b, 0) / bestRecords.length
        );
        let bestRoundsBogeyData = bestRecords.map((record: any) => {
          return record.points.bogey;
        });
        bestRoundsTableData.bogey = Math.round(
          bestRoundsBogeyData.reduce((a, b) => a + b, 0) / bestRecords.length
        );
        let bestBirdieBogeyParTotal =
          bestRoundsTableData.birdie +
          bestRoundsTableData.par +
          bestRoundsTableData.bogey;
        bestRoundsTableData.others =
          bestBirdieBogeyParTotal > 0 ? 18 - bestBirdieBogeyParTotal : 0;

        let otherRoundsPointsData = otherRecords.map((record: any) => {
          if (record.holes == 9) {
            //record.total_points = record.total_points * 2;
            return record.total_points;
          } else {
            return record.total_points;
          }
        });
        otherRoundsTableData.total_points = Math.round(
          otherRoundsPointsData.reduce((a, b) => a + b, 0) / otherRecords.length
        );
        let otherRoundsStrokesData = new Array();
        otherRecords.map((record: any) => {
          let rawScoresData = record.hole_details.map((rec: any) => {
            return rec.raw_score;
          });
          otherRoundsStrokesData = otherRoundsStrokesData.concat(rawScoresData);
          return record;
        });
        otherRoundsTableData.strokes = Math.floor(
          otherRoundsStrokesData.reduce((a, b) => a + b, 0) /
            otherRecords.length
        );
        let otherRoundsBirdieData = otherRecords.map((record: any) => {
          return record.points.birdie;
        });
        otherRoundsTableData.birdie = Math.round(
          otherRoundsBirdieData.reduce((a, b) => a + b, 0) / otherRecords.length
        );
        let otherRoundsParData = otherRecords.map((record: any) => {
          return record.points.par_value;
        });
        otherRoundsTableData.par = Math.round(
          otherRoundsParData.reduce((a, b) => a + b, 0) / otherRecords.length
        );
        let otherRoundsBogeyData = otherRecords.map((record: any) => {
          return record.points.bogey;
        });
        otherRoundsTableData.bogey = Math.round(
          otherRoundsBogeyData.reduce((a, b) => a + b, 0) / otherRecords.length
        );
        let othersBirdieBogeyParTotal =
          otherRoundsTableData.birdie +
          otherRoundsTableData.par +
          otherRoundsTableData.bogey;
        otherRoundsTableData.others =
          othersBirdieBogeyParTotal > 0 ? 18 - othersBirdieBogeyParTotal : 0;

        var allStrokes = Math.floor(
          (bestRoundsTableData.strokes > 0
            ? bestRoundsTableData.strokes * 0.7
            : 0) +
            (otherRoundsTableData.strokes > 0
              ? otherRoundsTableData.strokes * 0.3
              : 0)
        );
        var allPoints = Math.floor(
          (bestRoundsTableData.total_points > 0
            ? bestRoundsTableData.total_points * 0.7
            : 0) +
            (otherRoundsTableData.total_points > 0
              ? otherRoundsTableData.total_points * 0.3
              : 0)
        );
        //updateEntry("USER_PROFILE", user.id, {"quota": allPoints, "strokes": allStrokes});
        // user.quota = allPoints;
        // setCurrentUser(user);
        // localStorage.setItem('firebase_user', JSON.stringify(user));
        let allRounds = [...bestRecords, ...otherRecords];
        allRounds = _.sortBy(allRounds, (e: any) => e.competition_date_timestamp).reverse();
        setBestRound(bestRoundTableData);
        setBestRounds(bestRoundsTableData);
        setOtherRounds(otherRoundsTableData);
        setProfileData({
          all_strokes: allStrokes,
          all_points: allPoints,
        });
        setLatestRound({
          date: format(new Date(latestRound.competition_date), "MM/dd"),
          total_points: latestRound.points.total_points,
        });
        //setAllRounds(allRounds);
      } else {
        setBestRound({
          strokes: 0,
          total_points: 0,
          birdie: 0,
          par: 0,
          bogey: 0,
          double_bogey: 0,
          triple_bogey: 0,
          others: 0,
        });
        setBestRounds({
          strokes: 0,
          total_points: 0,
          birdie: 0,
          par: 0,
          bogey: 0,
          double_bogey: 0,
          triple_bogey: 0,
          others: 0,
          count: 0,
        });
        setOtherRounds({
          strokes: 0,
          total_points: 0,
          birdie: 0,
          par: 0,
          bogey: 0,
          double_bogey: 0,
          triple_bogey: 0,
          others: 0,
          count: 0,
        });
        setProfileData({
          all_strokes: 0,
          all_points: 0,
        });
        setLatestRound({
          date: format(new Date(), "MM/dd"),
          total_points: 0,
        });
        setAllRounds([]);
      }
    });
  };
  
  const toggleCard = (header:any)=>{
    all_rounds && all_rounds.forEach((key:any,i:number)=>{
      if(key.id == header){
        key.toggle = !key.toggle
      }
    })
    // console.log(header,cards)
    setAllRounds(Array.from(new Set([...all_rounds])))
  }
  const [score_modal,setScoreModal] = useState<any>(false)
  const [pending_scores, setPendingScores] = useState<any>([]);
  const [pending_scores_modal, setPendingScoresModal] = useState<any>(false);
  const [showQuotaModal, setShowQuotaModal] = useState<any>(false);
  const [score_record, setScoreRecord] = useState<any>([]);
  const validateScores = async (id: any) => {
    const scoresQuery = query(
      collection(db, "SCORE_RECORDS"),
      where("created_at", ">", 0),
      where("user_id", "==", id),
      where("score_submitted", "==", false)
    );
    const scoresSnapshot = await getDocs(scoresQuery);
    if (scoresSnapshot.docs.length > 0) {
      await Promise.all(
        scoresSnapshot.docs.map(async (competition) => {
          var doc_item = competition.data();
          doc_item.id = competition.id;
          let courseStr = doc_item.course_name.split("-");
          if (courseStr[0] != undefined || courseStr[0] != null)
            doc_item.course_name = courseStr[0];
          if (courseStr[1] != undefined || courseStr[1] != null)
            doc_item.course_sub_name = courseStr[1];
          return doc_item;
        })
      ).then((results) => {
        console.log(
          "pending scores",
          _.sortBy(results, (e: any) => e.created_at).reverse()
        );
        setPendingScores(_.sortBy(results, (e: any) => e.created_at).reverse());
        setPendingScoresModal(true);
      });
    } else {
      window.location.href = "/create_score";
    }
  };
  useEffect(() => {
    console.log("id", id);
    if (id != null) {
      setUserId(id);
      getUserData(id);
      getProfileData(id);
    }
  }, []);
  return (
    <IonPage>
      <IonToolbar
        mode="ios"
        className={isPlatform("ios") ? "ios-toolbar-top" : "toolbar-top"}
      >
        <IonButtons slot="start" className="slot-start-padding">
          {current_user.logo_url ? (
            <a href="/profile" className="pd-r-10">
              <img src={current_user.logo_url} className="pro_pic" />
            </a>
          ) : (
            <a href="/profile" className="pd-r-10">
              <img src={default_profile_logo} className="pro_pic" />
            </a>
          )}
        </IonButtons>
        <IonLabel slot="start" className="s-18 w-800 color-blue" style={{padding: '0px 0px 0px 10px'}}>
          {current_user.first_name + " " + current_user.last_name}
        </IonLabel>
      </IonToolbar>
      <IonContent>
        <IonGrid style={{padding: '0px'}}>
          <IonRow className="ion-justify-content-center ion-text-center row-8">
            <IonCol size="4">
              <IonCard
                className="bg card"
                onClick={() => {
                  // getScores("filtered")
                  setShowQuotaModal(true);
                }}
              >
                <div className="mt-10">
                  <IonText className="s-18 white wg-600">
                    {current_user.quota}
                  </IonText>{" "}
                  <br />
                  <IonText className="s-11 white mrl-10">Quota</IonText>
                  <IonIcon
                    icon={informationCircleOutline}
                    className="white s-15 wg-600 inf-icon"
                  />
                </div>
              </IonCard>
            </IonCol>
            <IonCol className="margin-auto profile-text" size="7">
                    <IonRange min={1} max={5} step={1} color="transparent" value={tee_value} className="custom-profile-ion-range-slider" disabled={true}>
                        <IonText slot="start" className="s-12 wg-600 gray range-left">&#124;</IonText>
                        <IonText slot="end" className="s-12 wg-600 gray range-right">&#124;</IonText>
                    </IonRange>
                    <IonRow className="ion-text-center ion-justify-content-center">
                        <IonText className="s-12">Suggested tees: <b>{current_user.preferred_tee}</b></IonText>
                    </IonRow>
                  </IonCol>
          </IonRow>
         {/*  <IonRow className="display-flex ion-justify-content-center">
            <IonText className="s-14">
              Handicap Index<sup>&#8482;</sup>: {current_user.handicap_index}
            </IonText>
          </IonRow> */}
          <IonRow className="display-flex ion-justify-content-center ion-text-center mt-5">
            {
                (current_user.driving_distance != undefined && current_user.driving_distance.text)?(
                    <IonCol>
                        <IonText className='s-12'>Driving Distance:&nbsp;&nbsp;{current_user.driving_distance.text}</IonText>
                    </IonCol>
                ):(
                    <></>
                )
            }
          </IonRow>
        </IonGrid>
        <IonGrid className="grid-2 mt-20">
          <IonCard className="stats-card bg ion-justify-content-center ion-text-center">
          </IonCard>
          <br />
          {
            all_rounds && all_rounds.map((key:any,i:number)=>{
              return(
                <IonCard className="social-card score-summary-card" key={i}>
                  <IonRow className="pad-ltb">
                      <IonCol size="8" className="pad-0">
                          <IonText className="color-blue s-15 wg-600">
                              {key.course_name}
                          </IonText>
                          <br/>   
                          <IonText className="s-12 wg-500">{key.tee}&nbsp;<span className="s-10">{key.course_rating}/{key.slope_rating}/{key.par}</span></IonText>
                      </IonCol>
                      <IonCol size="3" className="pad-0 ion-text-center">
                          <IonText className="color-blue s-15 wg-600">
                              {key.points.total_points}
                          </IonText>
                          <IonText className="s-12 wg-500">/{key.quota}</IonText><br/>
                          <IonText className="s-12 wg-500">Score/Target</IonText>
                      </IonCol>
                      {
                      (!key.toggle)?(
                          <IonCol size="1" className="pad-0" onClick={()=>{
                          toggleCard(key.id)
                          }}>
                          <IonIcon icon={chevronDownOutline} style={{fontSize:"18px",marginTop:"5px"}}/>
                      </IonCol>  
                      ):(
                          <IonCol size="1" className="pad-0" onClick={()=>{
                          toggleCard(key.id)
                          }}>
                          <IonIcon icon={chevronUpOutline} style={{fontSize:"18px",marginTop:"5px"}}/>
                      </IonCol>
                      )
                      }
                  </IonRow>
                  {
                    (key.toggle)?(
                        <IonCardContent style={{paddingTop:"0px"}}>
                        <IonRow className="ion-justify-content-center ion-text-center" style={{width:"90%",margin:"auto"}}>
                        <IonCol size="2">
                            <IonText className="s-11">Eagle</IonText><br/>
                            <IonText className="black s-14">{key.points.eagle}</IonText>
                        </IonCol>
                        <IonCol size="2">
                            <IonText className="s-11">Birdie</IonText><br/>
                            <IonText className="black s-14">{key.points.birdie}</IonText>
                        </IonCol>
                        <IonCol size="2">
                            <IonText className="s-11">Par</IonText><br/>
                            <IonText className="black s-14">{key.points.par_value}</IonText>
                        </IonCol>
                        <IonCol size="2">
                            <IonText className="s-11">Bogey</IonText><br/>
                            <IonText className="black s-14">{key.points.bogey}</IonText>
                        </IonCol>
                        <IonCol size="2">
                            <IonText className="s-11">Double</IonText><br/>
                            <IonText className="black s-14">{key.points.double_bogey}</IonText>
                        </IonCol>
                        <IonCol size="2">
                            <IonText className="s-11">Triple</IonText><br/>
                            <IonText className="black s-14">{key.points.triple_bogey}</IonText>
                        </IonCol>
                        </IonRow>
                        {/* <IonRow className="fr mt-5 mb-5">
                        <IonText className="s-13 color-blue" onClick={()=>{
                            var score_record = {
                                hole_details: _.sortBy(key.hole_details, e => e.hole_number),
                                points: key.points,
                                total_points: key.total_points,
                                user_name: key.user_name,
                                course_name: key.course_name,
                                tee: key.tee,
                                holes: key.holes,
                                date: format(new Date(key.competition_date_timestamp), "MM/dd/yyyy"),
                            };
                            // console.log("score record",score_record)
                            setScoreRecord(score_record)
                            setScoreModal(true)
                            
                        }}>View Scorecard</IonText><IonIcon size="19" icon={chevronForwardOutline} className="color-blue"/>
                        </IonRow> */}
                    </IonCardContent>
                    ):(
                        <>
                        </>
                    )
                  }
                </IonCard>
              )
            })
          }
        </IonGrid>
        <IonModal
          isOpen={showQuotaModal}
          showBackdrop={true}
          className="quota-modal"
          backdropDismiss={true}
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowQuotaModal(false)}
          mode="md"
        >
          <IonHeader mode="ios">
            <IonToolbar>
              <IonTitle>Quota</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid className="pending-height">
              <IonCard className="social-card">
                  <IonRow className="ion-justify-content-center ion-text-center bb-line">
                      <IonCol size="6">
                          <IonText className="s-14 wg-600 color-dark-gray">Type</IonText>
                      </IonCol>
                      <IonCol size="6">
                          <IonText className="s-14 wg-600 color-dark-gray">Point Scale</IonText>
                      </IonCol>
                      
                  </IonRow>
                  <IonRow className="ion-justify-content-center ion-text-center bb-line">
                      <IonCol size="6">
                          <IonText className="s-14 color-black wg-500">Birdie+</IonText>
                      </IonCol>
                      <IonCol size="6">
                          <IonText className="s-14 color-black wg-500">4 points</IonText>
                      </IonCol>
                  </IonRow>
                  <IonRow className="ion-justify-content-center ion-text-center bb-line">
                      <IonCol size="6">
                          <IonText className="s-14 color-black wg-500">Par</IonText>
                      </IonCol>
                      <IonCol size="6">
                          <IonText className="s-14 color-black wg-500">2 points</IonText>
                      </IonCol>
                  </IonRow>
                  <IonRow className="ion-justify-content-center ion-text-center bb-line">
                      <IonCol size="6">
                          <IonText className="s-14 color-black wg-500">Bogie</IonText>
                      </IonCol>
                      <IonCol size="6">
                          <IonText className="s-14 color-black wg-500">1 point</IonText>
                      </IonCol>
                  </IonRow>
                  <IonRow className="ion-justify-content-center ion-text-center bb-line">
                      <IonCol size="6">
                          <IonText className="s-14 color-black wg-500">Double</IonText>
                      </IonCol>
                      <IonCol size="6">
                          <IonText className="s-14 color-black wg-500">0 points</IonText>
                      </IonCol>
                  </IonRow>
                  <IonRow className="ion-justify-content-center ion-text-center bb-line">
                      <IonCol size="6">
                          <IonText className="s-14 color-black wg-500">Triple-</IonText>
                      </IonCol>
                      <IonCol size="6">
                          <IonText className="s-14 color-black wg-500">-1 points</IonText>
                      </IonCol>
                  </IonRow>
              </IonCard>
              <IonRow className="ion-justify-content-center ion-text-center mt-25">
                  <IonText className="s-14">
                      Quota is an indicative target based on past points performance.<br/><br/>
                      {/* It is calculated from weighted average of recent scores - <IonText className="color-blue s-14 wg-600">70% to best scores </IonText>
                      and<IonText className="color-red s-14 pl-5 wg-600">30% to other scores</IonText> */}
                  </IonText>                                                                
              </IonRow>
            </IonGrid>
            <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '45px'}}>
                <IonFabButton
                className="main-fab"
                onClick={() => {
                  setShowQuotaModal(false)
                }}
                >
                <IonIcon icon={arrowBackOutline} />
                </IonFabButton>
            </IonFab>
          </IonContent>
        </IonModal>
        <IonModal isOpen={score_modal} className='my-custom-class'>
          <>
            <IonHeader>
                <IonToolbar>
                    {/* <IonButtons slot="start">
                        <a onClick={()=> setScoreModal(false)}>
                            <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                        </a>
                    </IonButtons> */}
                    <IonTitle>
                        Points
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <Table options={score_record} holes={18} front9={front_holes} back9={back_holes}/>
                <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '15px'}}>
                    <IonFabButton
                    className="main-fab"
                    onClick={() => {
                        setScoreModal(false)
                    }}
                    >
                    <IonIcon icon={arrowBackOutline} />
                    </IonFabButton>
                </IonFab>
            </IonContent>
          </>
        </IonModal>
        <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '45px'}}>
          <IonFabButton
            className="main-fab"
            onClick={() => {
                window.history.back()
            }}
            >
            <IonIcon icon={arrowBackOutline} />
          </IonFabButton>
        </IonFab>
      </IonContent>
      {/* <IonToolbar slot="bottom" className="profile-toolbar">
        <IonRow>
          <IonCol size="6" className="color-blue ion-text-start" style={{marginTop: '20px'}}>
            <IonLabel className="w-600">Participates In</IonLabel>
          </IonCol>
          <IonCol size="6" className="color-blue ion-text-start">
            <IonRow>
              {
                (current_user.ppw_validated) ? (
                  <IonIcon icon={checkmarkCircle} size="25"/>
                ) : (
                  <IonIcon icon={closeCircle} size="25"/>
                )
              }
              <IonLabel>Play.Post.Win.</IonLabel>
            </IonRow>
            <IonRow>
              <IonIcon icon={closeCircle} size="25"/>
              <IonLabel>NetScore Challenge</IonLabel>
            </IonRow>
            <IonRow>
              {
                (current_user.tt_validated) ? (
                  <IonIcon icon={checkmarkCircle} size="25"/>
                ) : (
                  <IonIcon icon={closeCircle} size="25"/>
                )
              }
              <IonLabel>TwilighTOUR</IonLabel>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonToolbar> */}
    </IonPage>
  );
};

export default PlayerProfile;
