import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonLabel,
  IonGrid,
  IonFab,
  IonFabButton,
  IonCol,
  IonItem,
  IonRow,
  IonText,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonDatetime,
  IonChip,
  IonModal,
  IonSearchbar,
  IonList,
  IonLoading,
} from "@ionic/react";
import { arrowBackOutline, closeCircle } from "ionicons/icons";
import { useEffect, useState } from "react";
import * as utils from "../../js/utils";
import { format } from "date-fns";
import _ from "lodash";
import { createEntryWithoutId } from "../../js/firebase_doc_functions";
import firebase from "../../js/firebase.js";

import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

const db = getFirestore(firebase);

const CreateTeeTime: React.FC = () => {
  const [select_state, setSelectState] = useState("");
  const [user_packs, setUserPacks] = useState<any>();
  const [all_packs, setAllPacks] = useState<any>();
  const [all_players, setAllPlayers] = useState<any>([]);
  const [current_user, setCurrentUser] = useState<any>();
  const [course, setCourse] = useState<any>();
  const [course_id, setCourseId] = useState<any>();
  const [course_list, setCourseList] = useState<any>([]);
  const [event_date, setEventDate] = useState<string>(format(new Date(), "MM/dd/yyyy"));
  const [confirm_by, setConfirmBy] = useState<string>("");
  const [spots, setSpots] = useState<any>("");
  const [description, setDescription] = useState<string>("");
  const [showLoading, setShowLoading] = useState(false);

  const [publish_to, setPublishTo] = useState<string>("");
  const [pack, setPack] = useState<string>("");

  const [selected_player, setSelectedPlayer] = useState<any>("");
  const [selected_player_id, setSelectedPlayerId] = useState<any>("");
  const [selected_players_list, setSelectedPlayersList] = useState<any>([]);
  const [showDatePicker1, setShowDatePicker1] = useState(false);
  const [showDatePicker2, setShowDatePicker2] = useState(false);
  const getCourses = async (state: string) => {
    let course_list = await utils.getCourseList(state);
    setCourseList(course_list);
  };

  const getPacks = async () => {
    var condition = where("created_at", ">", 0);
    const q = query(collection(db, "PACKS"), condition);

    const querySnapshot = await getDocs(q);
    var records = new Array();
    querySnapshot.forEach((doc) => {
      let doc_item = doc.data();
      doc_item.id = doc.id;
      records.push(doc_item);
    });
    setAllPacks(records);
  };

  const getUsers = async (user_id: string) => {
    var condition = where("created_at", ">", 0);
    const q = query(collection(db, "USER_PROFILE"), condition);

    const querySnapshot = await getDocs(q);
    var records = new Array();
    records = querySnapshot.docs
      .map((doc) => {
        let doc_item = doc.data();
        doc_item.id = doc.id;
        return doc_item;
      })
      .filter((doc: any) => doc.user_id != user_id);
    setAllPlayers(records);
  };

  const add_players = (name: any) => {
    if (!selected_players_list.includes(name)) {
      selected_players_list.push(name);
      setSelectedPlayersList(Array.from(new Set([...selected_players_list])));
    }
  };
  const del_players = (name: any) => {
    if (selected_players_list.includes(name)) {
      setSelectedPlayersList(
        selected_players_list.filter((ele: any) => {
          return ele != name;
        })
      );
    }
  };

  const shareTeeTime = async () => {
    setShowLoading(true);
    let selected_course = course_list.filter(
      (x: any) => x.CourseID == course_id
    );
    let event_id = await utils.randomString(28, "#aA");
    if (publish_to == "Pack" && pack.length > 0) {
      let selected_pack = all_packs.filter((x: any) => x.pack_id == pack);
      let players = selected_pack[0].players
        .map((x: any) => {
          if (x.user_id != current_user.user_id) {
            return { user_id: x.user_id, user_name: x.user_name };
          } else {
            return;
          }
        })
        .filter((x: any) => x);
      var pack_obj = {
        course_name: selected_course[0].FullName,
        course_id: selected_course[0].CourseID,
        event_date: new Date(event_date).getTime(),
        confirm_by: new Date(confirm_by).getTime(),
        pack_id: selected_pack[0].pack_id,
        pack_name: selected_pack[0].pack_name,
        available_signup_spots: spots,
        event_id: event_id,
        accepted_users: [
          {
            user_id: current_user.user_id,
            user_name: current_user.first_name + " " + current_user.last_name
          },
        ],
        rejected_users: [],
        pending_users: players,
        publish_to: publish_to,
        city: current_user.city || "",
        user_name: current_user.first_name + " " + current_user.last_name,
        user_id: current_user.user_id,
        description: description,
        is_active:true
      };
      await createEntryWithoutId("TEE_TIMES", pack_obj);
      await Promise.all(
        players.map(async (player: any) => {
          await createEntryWithoutId("TEE_TIME_NOTIFICATIONS", {
            event_date: pack_obj.event_date,
            event_id: pack_obj.event_id,
            course_id: pack_obj.course_id,
            course_name: pack_obj.course_name,
            user_id: player.user_id,
            user_name: player.user_name,
            pack_id: selected_pack[0].pack_id,
            pack_name: selected_pack[0].pack_name,
            created_by: current_user.user_id,
            created_name:
              current_user.first_name + " " + current_user.last_name,
            type: pack_obj.publish_to,
          });
        })
      ).then((results) => {
        setShowLoading(false);
        window.location.href = "/leagues/formats";
      });
    } else if (publish_to == "Players" && selected_players_list.length > 0) {
      let players_list = new Array();
      for (var i = 0; i < selected_players_list.length; i++) {
        let userRecord =
          all_players.find(
            (rec: any) => rec.user_id == selected_players_list[i]
          ) || {};
        if (userRecord && userRecord.user_id == undefined) {
          console.log("Some players are not present");
          return false;
        } else if (userRecord.user_id != current_user.user_id) {
          players_list.push({
            first_name: userRecord.first_name,
            last_name: userRecord.last_name,
            user_id: userRecord.user_id,
          });
        }
      }
      var player_obj = {
        course_name: selected_course[0].FullName,
        course_id: selected_course[0].CourseID,
        event_date: new Date(event_date).getTime(),
        confirm_by: new Date(confirm_by).getTime(),
        pack_id: current_user.packs[0].pack_id,
        pack_name: current_user.packs[0].pack_name,
        available_signup_spots: spots,
        event_id: event_id,
        accepted_users: [
          {
            user_id: current_user.user_id,
            user_name: current_user.first_name + " " + current_user.last_name
          },
        ],
        rejected_users: [],
        pending_users: players_list,
        publish_to: publish_to,
        city: current_user.city || "",
        user_id: current_user.user_id,
        description: description,
        user_name: current_user.first_name + " " + current_user.last_name,
        is_active:true
      };
      await createEntryWithoutId("TEE_TIMES", player_obj);
      await Promise.all(
        players_list.map(async (player: any) => {
          await createEntryWithoutId("TEE_TIME_NOTIFICATIONS", {
            event_date: player_obj.event_date,
            event_id: player_obj.event_id,
            course_id: player_obj.course_id,
            course_name: player_obj.course_name,
            user_id: player.user_id,
            user_name: player.first_name + " " + player.last_name,
            pack_id: "",
            pack_name: "",
            created_by: current_user.user_id,
            created_name:
              current_user.first_name + " " + current_user.last_name,
            type: player_obj.publish_to,
          });
        })
      ).then((results) => {
        setShowLoading(false);
        window.location.href = "/leagues/formats";
      });
    } else if (publish_to == "Community") {
      let city_players = all_players
        .map((x: any) => {
          if (
            x.city == current_user.city &&
            x.user_id != current_user.user_id
          ) {
            return {
              user_id: x.user_id,
              first_name: x.first_name,
              last_name: x.last_name,
            };
          } else {
            return;
          }
        })
        .filter((x: any) => x);
      var city_obj = {
        course_name: selected_course[0].FullName,
        course_id: selected_course[0].CourseID,
        event_date: new Date(event_date).getTime(),
        confirm_by: new Date(confirm_by).getTime(),
        pack_id: "",
        pack_name: "",
        available_signup_spots: spots,
        event_id: event_id,
        accepted_users: [
          {
            user_id: current_user.user_id,
            user_name: current_user.first_name + " " + current_user.last_name
          },
        ],
        rejected_users: [],
        pending_users: city_players,
        publish_to: publish_to,
        city: current_user.city || "",
        user_id: current_user.user_id,
        user_name: current_user.first_name + " " + current_user.last_name,
        description: description,
        is_active: true,
      };
      await createEntryWithoutId("TEE_TIMES", city_obj);
      await Promise.all(
        city_players.map(async (city_player: any) => {
          await createEntryWithoutId("TEE_TIME_NOTIFICATIONS", {
            event_date: city_obj.event_date,
            event_id: city_obj.event_id,
            course_id: city_obj.course_id,
            course_name: city_obj.course_name,
            user_id: city_player.user_id,
            user_name: city_player.first_name + " " + city_player.last_name,
            pack_id: "",
            pack_name: "",
            created_by: current_user.user_id,
            created_name:
              current_user.first_name + " " + current_user.last_name,
            type: city_obj.publish_to,
          });
          return city_player;
        })
      ).then((results) => {
        setShowLoading(false);
        window.location.href = "/leagues/formats";
      });
    }
  };

  const customAlertOptions = {
    header: "Select State",
    translucent: true,
  };
  const customAlertOptions1 = {
    header: "Select one",
  };

  const customAlertOptions2 = {
    header: "Publish to",
  };

  const states = [
    { state_code: "US-AL", state_name: "Alabama" },
    { state_code: "US-AK", state_name: "Alaska" },
    { state_code: "US-AZ", state_name: "Arizona" },
    { state_code: "US-AR", state_name: "Arkansas" },
    { state_code: "US-CA", state_name: "California" },
    { state_code: "US-CO", state_name: "Colorado" },
    { state_code: "US-CT", state_name: "Connecticut" },
    { state_code: "US-DE", state_name: "Delaware" },
    { state_code: "US-DC", state_name: "District Of Columbia" },
    { state_code: "US-FL", state_name: "Florida" },
    { state_code: "US-GA", state_name: "Georgia" },
    { state_code: "US-HI", state_name: "Hawaii" },
    { state_code: "US-ID", state_name: "Idaho" },
    { state_code: "US-IL", state_name: "Illinois" },
    { state_code: "US-IN", state_name: "Indiana" },
    { state_code: "US-IA", state_name: "Iowa" },
    { state_code: "US-KS", state_name: "Kansas" },
    { state_code: "US-KY", state_name: "Kentucky" },
    { state_code: "US-LA", state_name: "Louisiana" },
    { state_code: "US-ME", state_name: "Maine" },
    { state_code: "US-MD", state_name: "Maryland" },
    { state_code: "US-MA", state_name: "Massachusetts" },
    { state_code: "US-MI", state_name: "Michigan" },
    { state_code: "US-MN", state_name: "Minnesota" },
    { state_code: "US-MS", state_name: "Mississippi" },
    { state_code: "US-MO", state_name: "Missouri" },
    { state_code: "US-MT", state_name: "Montana" },
    { state_code: "US-NE", state_name: "Nebraska" },
    { state_code: "US-NV", state_name: "Nevada" },
    { state_code: "US-NH", state_name: "New Hampshire" },
    { state_code: "US-NJ", state_name: "New Jersey" },
    { state_code: "US-NM", state_name: "New Mexico" },
    { state_code: "US-NY", state_name: "New York" },
    { state_code: "US-NC", state_name: "North Carolina" },
    { state_code: "US-ND", state_name: "North Dakota" },
    { state_code: "US-OH", state_name: "Ohio" },
    { state_code: "US-OK", state_name: "Oklahoma" },
    { state_code: "US-OR", state_name: "Oregon" },
    { state_code: "US-PA", state_name: "Pennsylvania" },
    { state_code: "US-RI", state_name: "Rhode Island" },
    { state_code: "US-SC", state_name: "South Carolina" },
    { state_code: "US-SD", state_name: "South Dakota" },
    { state_code: "US-TN", state_name: "Tennessee" },
    { state_code: "US-TX", state_name: "Texas" },
    { state_code: "US-UT", state_name: "Utah" },
    { state_code: "US-VT", state_name: "Vermont" },
    { state_code: "US-VA", state_name: "Virginia" },
    { state_code: "US-WA", state_name: "Washington" },
    { state_code: "US-WV", state_name: "West Virginia" },
    { state_code: "US-WI", state_name: "Wisconsin" },
    { state_code: "US-WY", state_name: "Wyoming" },
  ];
  const [show1, setShow1] = useState<any>(true);
  const [show2, setShow2] = useState<any>(false);
  const [show3, setShow3] = useState<any>(false);
  const [new_states, setNewStates] = useState<any>(states);
  const [new_courses, setNewCourses] = useState<any>(course_list);
  const [new_players, setNewPlayers] = useState<any>(all_players);
  const [showStateModal, setShowStateModal] = useState(false);
  const [showCourseModal, setShowCourseModal] = useState(false);
  const [showPlayersModal, setShowPlayersModal] = useState(false);
  useEffect(() => {
    let user = localStorage.getItem("firebase_user");
    if (user != null) {
      setCurrentUser(JSON.parse(user));
      setUserPacks(JSON.parse(user).packs);
      getUsers(JSON.parse(user).user_id);
      getPacks();
    }
  }, []);
  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar>
          <IonTitle>Share Tee Time</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid
          style={{ paddingLeft: "8%", margin: "auto", maxWidth: "345px" }}
        >
          <IonRow
            style={{
              borderBottom: "1px solid gray",
              width: "80%",
              margin: "35px 10px 0px 15px",
            }}
            className="ion-justify-content-center ion-text-center"
          ></IonRow>
          <IonRow
            className="ion-justify-content-center ion-text-start pl-10"
            style={{ marginTop: "-9%" }}
          >
            <IonCol size="4">
              <div
                className={
                  show1 || show2 || show3 ? "circle bg-green" : "circle"
                }
              >
                1
              </div>
              <IonText className="pl-5">Date</IonText>
            </IonCol>
            <IonCol size="4">
              <div className={show2 || show3 ? "circle bg-green" : "circle"}>
                2
              </div>
              <IonText>Details</IonText>
            </IonCol>
            <IonCol size="4">
              <div className={show3 ? "circle bg-green" : "circle"}>3</div>
              <IonText>Players</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid>
          {show1 ? (
            <>
              <IonRow className="ion-justify-content-center mt-25 mb-10 ion-text-center">
                {/* <IonText className="wg-600 black s-18 myclub-header">Create Event</IonText> */}
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10 select-modal">
                <IonInput
                  value={select_state}
                  placeholder="Select State"
                  className="s-14 select-pack-dropdown pl-20"
                  mode="ios"
                  onClick={() => {
                    setShowStateModal(true);
                    setNewStates(states);
                  }}
                ></IonInput>
                {/* <IonSelect value={select_state} 
                        interfaceOptions={customAlertOptions}
                        interface="popover"                        
                        placeholder="Select State" className="s-14 select-pack-dropdown" 
                        onIonChange={e => {
                          setSelectState(e.detail.value); 
                          getCourses(e.detail.value)}} mode="ios">
                        {
                          (states && states.map((key:any,i:number)=>{
                            return(
                              <IonSelectOption value={key.state_code} key={i}>{key.state_name}</IonSelectOption>
                            )
                          }))
                        }  
                    </IonSelect> */}
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10 select-modal">
                <IonInput
                  value={course}
                  placeholder="Select Course"
                  className="s-14 select-pack-dropdown pl-20"
                  mode="ios"
                  onClick={() => {
                    setShowCourseModal(true);
                    setNewCourses(course_list);
                  }}
                ></IonInput>
                {/* <IonSelect value={course} 
                      interfaceOptions={customAlertOptions1}
                      interface="popover"                        
                      placeholder="Select Course" className="s-14 select-pack-dropdown" 
                      onIonChange={e => setCourse(e.detail.value!)} mode="ios">
                      {
                        (course_list && course_list.map((key:any,i:number)=>{
                          return(
                            <IonSelectOption value={key.CourseID} key={i}>{key.FullName}</IonSelectOption>
                          )
                        }))
                      }  
                    </IonSelect> */}
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10 mb-10 select-modal">
                <IonInput value={event_date}            
                    placeholder="Select date" className="s-14 select-pack-dropdown pl-20" 
                    mode="ios" 
                    onClick={()=> {
                        setShowDatePicker1(true);
                    }}>
                </IonInput>
                {
                    (showDatePicker1) ? (
                        <IonDatetime
                            presentation="date"
                            size='cover'
                            onIonChange={(e) => {
                                setEventDate(format(new Date(e.detail.value!), "MM/dd/yyyy"));
                                setShowDatePicker1(false);
                            }}
                        ></IonDatetime>
                    ) : (
                        <></>
                    )
                }
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10">
                <button
                  className="button-close bg-blue white wg-bold"
                  onClick={() => {
                    setShow2(true);
                    setShow1(false);
                    setShow3(false);
                  }}
                >
                  Next
                </button>
              </IonRow>
            </>
          ) : show2 ? (
            <>
              <IonRow className="ion-justify-content-center mt-25 mb-10 ion-text-center">
                {/* <IonText className="wg-600 black s-18 myclub-header">Create Event2</IonText> */}
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10 mb-10">
               <IonInput value={confirm_by}            
                  placeholder="Confirm by" className="s-14 select-pack-dropdown pl-20" 
                  mode="ios" 
                  onClick={()=> {
                      setShowDatePicker2(true);
                  }}>
                </IonInput>
                {
                    (showDatePicker2) ? (
                        <IonDatetime
                            presentation="date"
                            size='cover'
                            onIonChange={(e) => {
                                setConfirmBy(format(new Date(e.detail.value!), "MM/dd/yyyy"));
                                setShowDatePicker2(false);
                            }}
                        ></IonDatetime>
                    ) : (
                        <></>
                    )
                }
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10">
                <IonItem
                  lines="none"
                  className="select-pack-dropdown"
                  style={{ height: "40px" }}
                >
                  <IonInput
                    value={spots}
                    placeholder="No of Spots"
                    type="number"
                    className="s-14 custom-input"
                    onIonChange={(e: any) => {
                      setSpots(e.target.value);
                    }}
                    // clearInput
                  ></IonInput>
                </IonItem>
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10 mb-10">
                <IonItem
                  lines="none"
                  className="select-pack-dropdown"
                  style={{ height: "40px" }}
                >
                  <IonInput
                    style={{ paddingTop: "5px" }}
                    value={description}
                    className="s-14 custom-input"
                    placeholder="Tee time and description"
                    onIonChange={(e) => setDescription(e.detail.value!)}
                    // clearInput
                  ></IonInput>
                </IonItem>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-10">
                <IonCol>
                  <button
                    className="button-close bg-red white wg-bold"
                    onClick={() => {
                      setShow2(false);
                      setShow1(true);
                      setShow3(false);
                    }}
                  >
                    Back
                  </button>
                </IonCol>
                <IonCol>
                  <button
                    className="button-close bg-blue white wg-bold"
                    onClick={() => {
                      setShow2(false);
                      setShow1(false);
                      setShow3(true);
                    }}
                  >
                    Next
                  </button>
                </IonCol>
              </IonRow>
            </>
          ) : show3 ? (
            <>
              <IonRow className="ion-justify-content-center mt-25 mb-10 ion-text-center">
                {/* <IonText className="wg-600 black s-18 myclub-header">Create Event3</IonText> */}
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10 mb-10">
                <IonSelect
                  value={publish_to}
                  interfaceOptions={customAlertOptions2}
                  interface="popover"
                  placeholder="Publish to"
                  className="s-14 select-pack-dropdown"
                  onIonChange={(e) => setPublishTo(e.detail.value)}
                  mode="ios"
                >
                  <IonSelectOption value="Pack">Pack</IonSelectOption>
                  <IonSelectOption value="Community">Community</IonSelectOption>
                  <IonSelectOption value="Players">
                    Selected Players
                  </IonSelectOption>
                </IonSelect>
              </IonRow>
              {publish_to == "Pack" ? (
                <IonRow className="ion-justify-content-center mt-10 mb-10">
                  <IonSelect
                    value={pack}
                    interfaceOptions={customAlertOptions1}
                    interface="popover"
                    placeholder="Select Pack"
                    className="s-14 select-pack-dropdown"
                    onIonChange={(e) => setPack(e.detail.value!)}
                    mode="ios"
                  >
                    {user_packs &&
                      user_packs.map((key: any, i: number) => {
                        return (
                          <IonSelectOption value={key.pack_id} key={i}>
                            {key.pack_name}
                          </IonSelectOption>
                        );
                      })}
                  </IonSelect>
                </IonRow>
              ) : publish_to == "Players" ? (
                <>
                  <IonRow className="ion-justify-content-center mt-10 mb-10">
                    <IonCol size="9">
                      <IonInput
                        value={selected_player}
                        placeholder="Playing with"
                        className="s-14 select-pack-dropdown pl-20"
                        mode="ios"
                        onClick={() => {
                          setShowPlayersModal(true);
                          setNewPlayers(all_players);
                        }}
                      ></IonInput>
                      {/* <IonSelect value={selected_player} 
                              interfaceOptions={customAlertOptions1}
                              interface="popover"                        
                              placeholder="Select Player" className="s-14 select-pack-dropdown" 
                              onIonChange={e => {
                                setSelectedPlayer(e.detail.value!)
                              }} mode="ios">
                              {
                                (all_players && all_players.map((key:any,i:number)=>{
                                  return(
                                    <IonSelectOption value={key.user_id} key={i}>{key.first_name+' '+key.last_name}</IonSelectOption>
                                  )
                                }))
                              }  
                            </IonSelect> */}
                    </IonCol>
                    <IonCol size="3">
                      <button
                        className="add-button bg-blue white tt-upper wg-bold"
                        onClick={() => {
                          if (selected_player.length > 0) {
                            add_players(selected_player);
                          }
                        }}
                      >
                        add
                      </button>
                    </IonCol>
                  </IonRow>
                  <IonRow className="pl-15">
                    {selected_players_list &&
                      selected_players_list.map((key: any, i: number) => {
                        return (
                          <IonChip key={i}>
                            <IonLabel>{key}</IonLabel>
                            <IonIcon
                              icon={closeCircle}
                              onClick={() => {
                                del_players(key);
                              }}
                            />
                          </IonChip>
                        );
                      })}
                  </IonRow>
                </>
              ) : (
                <></>
              )}
              <IonRow className="ion-justify-content-center ion-text-center ion-mt-10">
                <IonCol>
                  <button
                    className="button-close bg-red white wg-bold"
                    onClick={() => {
                      setShow2(true);
                      setShow1(false);
                      setShow3(false);
                    }}
                  >
                    Back
                  </button>
                </IonCol>
                <IonCol>
                  <button
                    className="button-close bg-blue white wg-bold"
                    onClick={() => {
                      shareTeeTime();
                    }}
                  >
                    Finish
                  </button>
                </IonCol>
              </IonRow>
            </>
          ) : (
            <>
              <p>Success</p>
            </>
          )}
        </IonGrid>
        <IonModal
          isOpen={showStateModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true} 
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowStateModal(false)} mode="md"
        >
          <>
            <IonSearchbar
              onIonChange={(e: any) => {
                console.log(e.detail.value!);
                if (String(e.detail.value!).length > 0) {
                  let glo = new RegExp(String(e.detail.value!).toLowerCase());
                  let new_city_arr = new Array();
                  states.map((item: any) => {
                    let real_item = item;
                    item = item.state_name.toLowerCase();

                    if (glo.test(item)) {
                      console.log("item", real_item);
                      new_city_arr.push(real_item);
                    }
                  });
                  console.log(new_city_arr);
                  setNewStates(new_city_arr);
                } else {
                  setNewStates(states);
                }
              }} mode="ios"
            ></IonSearchbar>

            <IonContent>
              <IonGrid>
                <IonList>
                  {new_states &&
                    new_states.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              console.log(key.state_code);
                              setSelectState(key.state_name);
                              getCourses(key.state_code);
                              setShowStateModal(false);
                            }}
                          >
                            {key.state_name}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showCourseModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true} 
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowCourseModal(false)} mode="md"
        >
          <>
            <IonSearchbar
              onIonChange={(e: any) => {
                console.log(e.detail.value!);
                if (String(e.detail.value!).length > 0) {
                  let glo = new RegExp(String(e.detail.value!).toLowerCase());
                  let new_course_arr = new Array();
                  course_list.map((item: any) => {
                    console.log("a", item);
                    let real_item = item;
                    item = item.FullName.toLowerCase();

                    if (glo.test(item)) {
                      console.log("item", real_item);
                      new_course_arr.push(real_item);
                    }
                  });
                  console.log(new_course_arr);
                  setNewCourses(new_course_arr);
                } else {
                  setNewCourses(course_list);
                }
              }} mode="ios"
            ></IonSearchbar>

            <IonContent>
              <IonGrid>
                <IonList>
                  {new_courses &&
                    new_courses.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              console.log(key.CourseID);
                              setCourse(key.FullName);
                              setCourseId(key.CourseID);
                              setShowCourseModal(false);
                            }}
                          >
                            {key.FullName}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showPlayersModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true} 
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowPlayersModal(false)} mode="md"
        >
          <>
            <IonSearchbar
              onIonChange={(e: any) => {
                console.log(e.detail.value!);
                if (String(e.detail.value!).length > 0) {
                  let glo = new RegExp(String(e.detail.value!).toLowerCase());
                  let new_players_arr = new Array();
                  all_players.map((item: any) => {
                    console.log("a", item);
                    let real_item = item;
                    item =
                      item.first_name.toLowerCase() +
                      " " +
                      item.last_name.toLowerCase();

                    if (glo.test(item)) {
                      console.log("item", real_item);
                      new_players_arr.push(real_item);
                    }
                  });
                  console.log(new_players_arr);
                  setNewPlayers(new_players_arr);
                } else {
                  setNewPlayers(all_players);
                }
              }} mode="ios"
            ></IonSearchbar>

            <IonContent>
              <IonGrid>
                <IonList>
                  {new_players &&
                    new_players.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              console.log(key);
                              setSelectedPlayer(
                                key.first_name + " " + key.last_name
                              );
                              //setSelectedPlayerId(key.id)
                              setShowPlayersModal(false);
                            }}
                          >
                            {key.first_name + " " + key.last_name}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button">
            <IonFabButton
              className="main-fab"
              onClick={() => {
                window.location.href = "/leagues/formats";
              }}
            >
              <IonIcon icon={arrowBackOutline} />
            </IonFabButton>
          </IonFab>
        <IonLoading isOpen={showLoading} message={"Kicking off.."} />
      </IonContent>
    </IonPage>
  );
};
export default CreateTeeTime;
