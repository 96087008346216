import { IonGrid, IonRow, IonCol, IonText, IonModal, IonIcon, IonItem } from '@ionic/react';
import { arrowBackCircle, arrowBackCircleOutline, arrowDownCircle, arrowDownCircleOutline, arrowForwardCircle, arrowForwardCircleOutline, arrowUpCircle, arrowUpCircleOutline, checkmarkCircle, checkmarkCircleOutline, chevronBackOutline, chevronForwardOutline, closeCircle, closeCircleOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { updateEntry } from '../../js/firebase_doc_functions';
import { format } from "date-fns";

interface Props{
    options:  {},
    options1:[],
    options2:{},
    holes: any,
    front9: any,
    back9: any,
}

const EditTable: React.FC<Props> = (props) => {
    // updateEntry('SCORE_RECORDS', uid.id, {'hole_details': score});
    let obj = props.options;
    let obj1 = props.options1;
    let obj2 = props.options2;
    const front9 = props.front9;
    const back9 = props.back9;
    const holes = props.holes;
    const [val,setVal] = useState<any>()
    const [current_user,setCurrentUser] = useState<any>([]);
    // console.log(obj,obj1)
    const [score,setScore] = useState<any>()
    const [points,setPoints] = useState<any>();
    const [hole_number,setHoleNumber]= useState<any>(0);
    const [fairway_hit,setFairwayHit]= useState<any>();
    const [gir,setGir]= useState<boolean>();
    const [putts,setPutts]= useState<any>();
    const [par,setPar] = useState<any>(0)    
    const [submitted_data, setSubmittedData] = useState<any>({
        triple_bogey: 0,
        double_bogey: 0,
        bogey: 0,
        par_value: 0,
        birdie: 0,
        double_eagle: 0,
        eagle: 0,
        total_points: 0
    } )
    useEffect(() => {
        let user = localStorage.getItem('firebase_user');
        if(user != null) {
            setCurrentUser(JSON.parse(user));
            setVal(obj);
            setScore(obj1);
            setPoints(obj2); 
            if(holes == 18) {
                setHoleNumber(0);
            } else {
                if(front9) {
                    setHoleNumber(0);
                } else if(back9) {
                    setHoleNumber(9);
                }
            }           
        }
    }, [])
    useEffect(() => {
        preShowResults();
    },[current_user])
    
    // setScore(val && val.hole_details)

    const setScoreRecord = async()=> {
        await updateEntry('SCORE_RECORDS', val.id, {'hole_details': val.hole_details,'score_submitted':true,'points': points});//score_submitted: (18 holes true or false)
        // await updateEntry('SCORE_RECORDS', val.id, {'points': points});
        window.location.href="/leagues/scores"
    }
    const preShowResults = async()=>{
        var obj = {
            triple_bogey: 0,
            double_bogey: 0,
            bogey: 0,
            par_value: 0,
            birdie: 0,
            double_eagle: 0,
            eagle: 0,
            total_points: 0,
            front_par_total: 0,
            back_par_total: 0,
            front_score_total: 0,
            back_score_total: 0,
            front_points_total: 0,
            back_points_total: 0
        }
        score && score.forEach((key:any,index:number)=>{
            if(key.raw_score > 0) {
            if((key.raw_score-key.par) > 2) {
                obj.triple_bogey+=1
                obj.total_points += 0
            }
            if((key.raw_score-key.par) == 2) {
                obj.double_bogey+=1
                obj.total_points += 0
            }
            if((key.raw_score-key.par) == 1) {
                obj.bogey+=1
                obj.total_points += 1
            }
            if((key.raw_score-key.par) == 0) {
                obj.par_value+=1
                obj.total_points += 2
            }
            if((key.raw_score-key.par) == -1) {
                obj.birdie+=1
                obj.total_points += 3
            }
            if((key.raw_score-key.par) == -2) {
                obj.eagle +=1
                obj.total_points += 5
            }
            if((key.raw_score-key.par) == -3) {
                obj.double_eagle+=1
                obj.total_points += 5
            }
        }
            if(score.length <= 9) {
                if(score[0].hole_number > 9) {
                    setHoleNumber(10)
                    obj.back_score_total +=  key.raw_score;
                    obj.back_par_total += key.par;
                } else {
                    setHoleNumber(1)
                    obj.front_score_total +=  key.raw_score;
                    obj.front_par_total += key.par;
                }
                
                setPar(Number(score[0].par))
                if(key.raw_score==0){
                    setScoreChange(Number(score[0].par))
                }else{
                    setScoreChange(Number(score[0].raw_score))
                }  
            } else {
                obj.back_score_total +=  key.hole_number >9 ? key.raw_score: 0;
                obj.front_score_total +=  key.hole_number <=9 ? key.raw_score: 0;
                obj.back_par_total += key.hole_number >9 ? key.par : 0;
                obj.front_par_total += key.hole_number<=9 ? key.par : 0;
                obj.back_points_total += key.hole_number > 9 ? key.points : 0;
                obj.front_points_total += key.hole_number <= 9 ? key.points : 0;
                setHoleNumber(1)
                setPar(Number(score[0].par))
                if(score[0].raw_score==0){
                    setScoreChange(Number(score[0].par))
                }else{
                    setScoreChange(Number(score[0].raw_score))
                } 
            }
        });
        setSubmittedData(obj); 
        setPoints(obj);   
        setShowModal(true);                 
    }
    
    const editRow = async (value:any,hole_num:any)=>{
        var obj = {
            triple_bogey: 0,
            double_bogey: 0,
            bogey: 0,
            par_value: 0,
            birdie: 0,
            double_eagle: 0,
            eagle: 0,
            total_points: 0,
            front_par_total: 0,
            back_par_total: 0,
            front_score_total: 0,
            back_score_total: 0,
            front_points_total: 0,
            back_points_total: 0
        }
        score && score.forEach((key:any,index:number)=>{
            if(key.hole_number==hole_num){
                key.raw_score = value
                // key.points = key.raw_score-key.par
                if((key.raw_score-key.par) > 2) {
                    key.points_type="triple_bogey";
                    key.points= 0;
                }
                if((key.raw_score-key.par) == 2) {
                    key.points_type="double_bogey";
                    key.points= 0;
                }
                if((key.raw_score-key.par) == 1) {
                    key.points_type="bogey";
                    key.points= 1;
                    //obj.bogey+=1
                }
                if((key.raw_score-key.par) == 0) {
                    key.points_type="par_value";
                    key.points= 2;
                }
                if((key.raw_score-key.par) == -1) {
                    key.points_type="birdie";
                    key.points= 3;
                }
                if((key.raw_score-key.par) == -2) {
                    key.points_type="eagle";
                    key.points= 5;
                }
                if((key.raw_score-key.par) == -3) {
                    key.points_type="double_eagle";
                    key.points= 5;
                }
            }
            if(key.raw_score > 0) {
                if((key.raw_score-key.par) > 2) {
                    obj.triple_bogey+=1
                    obj.total_points += 0
                }
                if((key.raw_score-key.par) == 2) {
                    obj.double_bogey+=1
                    obj.total_points += 0
                }
                if((key.raw_score-key.par) == 1) {
                    obj.bogey+=1
                    obj.total_points += 1
                }
                if((key.raw_score-key.par) == 0) {
                    obj.par_value+=1
                    obj.total_points += 2
                }
                if((key.raw_score-key.par) == -1) {
                    obj.birdie+=1
                    obj.total_points += 3
                }
                if((key.raw_score-key.par) == -2) {
                    obj.eagle +=1
                    obj.total_points += 5
                }
                if((key.raw_score-key.par) == -3) {
                    obj.double_eagle+=1
                    obj.total_points += 5
                }
            }
            if(score.length <= 9) {
                if(score[0].hole_number > 9) {
                    obj.back_score_total +=  key.raw_score;
                    obj.back_par_total += key.par;
                    obj.back_points_total += key.points;
                } else {
                    obj.front_score_total +=  key.raw_score;
                    obj.front_par_total += key.par;
                    obj.front_points_total += key.points;
                }                              
            } else {
                obj.back_score_total +=  key.hole_number >9 ? key.raw_score: 0;
                obj.front_score_total +=  key.hole_number <=9 ? key.raw_score: 0;
                obj.back_par_total += key.hole_number >9 ? key.par : 0;
                obj.front_par_total += key.hole_number<=9 ? key.par : 0;
                obj.back_points_total += key.hole_number > 9 ? key.points : 0;
                obj.front_points_total += key.hole_number <= 9 ? key.points : 0;
            }
        });
        setSubmittedData(obj); 
        setPoints(obj);            
        setScore(Array.from(new Set([...score])));
    }
    
    const [val2,setVal2] = useState<any>(["Eagle","Birdie","Par","Bogey","Double","Triple"])
    const [showModal, setShowModal] = useState(true);
    const [score_change,setScoreChange] = useState<any>(0)
    const post_score_func = (total_holes:any)=>{
        let count = 0
        val && val.hole_details.forEach((key:any,i:number)=>{
            if(key.raw_score!=0){
                count+=1
            }
        });
        if(count == Number(total_holes)){
            return true;
        }else{
            return false;
        }
    }
    const Show_respective_Holes = (par_value:any)=>{
        let values = [-2,-1,0,1,2,3];
        let new_holes = new Array()
        let real_par = par_value;
        values.map((key:any,i:number)=>{
            if((real_par+key)>0 &&(real_par+key)<9){
                new_holes.push(Number((real_par+key)))
            } 
        })
        
        // console.log("new holes",new_holes)
        return new_holes;

    }
    
    return (
        <>
        <IonGrid className="">
            {/* <IonRow className="ion-justify-content-center ion-text-center mt-10">
                <IonText className="black s-18">{val && val.user_name}</IonText>
            </IonRow> */}
            {/* <IonRow className="ion-justify-content-center ion-text-center">
                <IonText className="black s-16">{val && val.course_name}</IonText>
            </IonRow>
            <IonRow className="ion-justify-content-center ion-text-center">
            <IonText className="black s-16">{val && val.tee} tees - </IonText>
            <IonText className="black s-16">{val && val.competition_date}</IonText>
            </IonRow> */}
            <IonRow className="ion-justify-content-center ion-text-center" style={{width:"90%",margin:"auto",borderBottom:"0.5px  solid black"}}>
                {
                    val2 && val2.map((key:any,i:number)=>{
                       return(
                            <IonCol size="2" key={i}>
                                <IonText className="s-11">{key}:</IonText>
                                {
                                    key === "Eagle" ? (
                                        <IonText className="black s-11">{submitted_data && submitted_data.eagle}</IonText>
                                    ) : key === "Birdie" ? (
                                        <IonText className="black s-11">{submitted_data && submitted_data.birdie}</IonText>
                                    ) : key === "Par" ? (
                                        <IonText className="black s-11">{submitted_data && submitted_data.par_value}</IonText>
                                    ) : key === "Bogey" ? (
                                        <IonText className="black s-11">{submitted_data && submitted_data.bogey}</IonText>
                                    ) : key === "Double" ? (
                                        <IonText className="black s-11">{submitted_data && submitted_data.double_bogey}</IonText>
                                    ) : key === "Triple" ? (
                                        <IonText className="black s-11">{submitted_data && submitted_data.triple_bogey}</IonText>
                                    ) : (
                                        <></>
                                    )
                                }
                            </IonCol>
                       )
                    })
                }
            </IonRow>
            <IonRow className="ion-justify-content-center ion-text-center mt-10">
                <IonCol>
                <IonText className="ion-text-start wg-500 black">Total Points: {submitted_data && submitted_data.total_points}</IonText>
                </IonCol>
                <IonCol>
                <IonText className="ion-text-end wg-500 black"> {val && val.competition_date_timestamp?format(new Date(val.competition_date_timestamp),"MM/dd/yyyy"):''} &nbsp; {val && val.game_date_time?val.game_date_time:''}</IonText>
            </IonCol>
            </IonRow>
        </IonGrid>
        <IonGrid>
            {
                (holes == 9 && front9 == true) ? (
                <>
                <IonRow className="ion-justify-content-center ion-text-center">
                    <IonText className="wg-600 black">Front 9</IonText>
                </IonRow>
                <IonRow className="points-table-row-color s-14">
                    <IonCol size="2" style={{color:"black"}}>
                        Hole
                    </IonCol>
                    {
                        val && val.hole_details.map((key:any,i:number)=>{
                            // console.log("key",key)
                            if(key.hole_number<=9){
                                return(
                                    <IonCol size="1" style={{color:"black"}} key={i}>
                                        {key.hole_number}
                                    </IonCol>
                                )
                            }
                        })
                    }
                    <IonCol size="1" style={{color:"black"}}>
                        Tot
                    </IonCol>
                </IonRow>    
                <IonRow className="points-table-row-color s-14">
                    <IonCol size="2" style={{color:"black"}}>
                        Par
                    </IonCol>
                    {
                        val && val.hole_details.map((key:any,i:number)=>{
                            // console.log("key",key)
                            if(key.hole_number<=9){
                                return(
                                    <IonCol size="1" style={{color:"black"}} key={i}>
                                        {key.par}
                                    </IonCol>
                                )
                            }
                        })
                    }
                    <IonCol size="1" style={{color:"black", paddingInlineStart: "0",paddingInlineEnd: "0"}}>
                        {submitted_data.front_par_total}
                    </IonCol>
                </IonRow>    
                <IonRow className="points-table-row-app-color s-14">
                    <IonCol size="2" style={{color:"white"}}>
                        Score
                    </IonCol>
                    {
                        val && val.hole_details.map((key:any,i:number)=>{
                            // console.log("key",key)
                            if(key.hole_number<=9){
                                return(
                                    <IonCol size="1" style={{color:"white"}} key={i} onClick={()=>{
                                        setHoleNumber(Number(key.hole_number))
                                        setPar(Number(key.par))
                                        if(key.raw_score==0){
                                            setScoreChange(Number(key.par))
                                        }else{
                                            setScoreChange(Number(key.raw_score))
                                        }                                    
                                        setShowModal(true)
                                    }}>
                                        {
                                            (key.raw_score==0)?(
                                                <>
                                                _
                                                </>
                                            ):(
                                                <>{key.raw_score}</>
                                            )
                                        }
                                        {/* <IonInput
                                            type="number"
                                            value={key.raw_score}                                        
                                            onIonChange={(e) => Edit_row(parseInt(e.detail.value!, 10),key.hole_number)}
                                            ></IonInput> */}
                                    </IonCol>
                                )
                            }
                        })
                    }
                    <IonCol size="1" style={{color:"white"}}>
                        {submitted_data.front_score_total}
                    </IonCol>
                </IonRow>    
                <IonRow className="points-table-row-blank s-14">
                    <IonCol size="2" style={{color:"black"}}>
                        Points
                    </IonCol>
                    {
                        val && val.hole_details.map((key:any,i:number)=>{
                            // console.log("key",key)
                            if(key.hole_number<=9){
                                return(
                                    <IonCol size="1" style={{color:"black",maxWidth: "100% !important",width: "100% !important"}} key={i}>
                                        {key.points}
                                    </IonCol>
                                )
                            }
                        })
                    }
                    <IonCol size="1" style={{color:"black"}}>
                        {submitted_data.front_points_total}
                    </IonCol>
                </IonRow> 
                </>
                ) : (holes == 9 && back9 == true) ? (
                <>
                <IonRow className="ion-justify-content-center ion-text-center mt-10">
                    <IonText className="wg-600 black">Back 9</IonText>
                </IonRow>
                <IonRow className="points-table-row-color s-14">
                    <IonCol size="2" style={{color:"black"}}>
                        Hole
                    </IonCol>
                    {
                        val && val.hole_details.map((key:any,i:number)=>{
                            // console.log("key",key)
                            if(key.hole_number>9){
                                return(
                                    <IonCol size="1" style={{color:"black"}} key={i}>
                                        {key.hole_number}
                                    </IonCol>
                                )
                            }
                        })
                    }
                    <IonCol size="1" style={{color:"black"}}>
                        Tot
                    </IonCol>
                </IonRow>    
                <IonRow className="points-table-row-color s-14">
                    <IonCol size="2" style={{color:"black"}}>
                        Par
                    </IonCol>
                    {
                        val && val.hole_details.map((key:any,i:number)=>{
                            // console.log("key",key)
                            if(key.hole_number>9){
                                return(
                                    <IonCol size="1" style={{color:"black"}} key={i}>
                                        {key.par}
                                    </IonCol>
                                )
                            }
                        })
                    }
                    <IonCol size="1" style={{color:"black"}}>
                        {submitted_data.back_par_total}
                    </IonCol>
                </IonRow>    
                <IonRow className="points-table-row-app-color s-14">
                    <IonCol size="2" style={{color:"white"}}>
                        Score
                    </IonCol>
                    {
                        val && val.hole_details.map((key:any,i:number)=>{
                            // console.log("key",key)
                            if(key.hole_number>9){
                                return(
                                    <IonCol size="1" style={{color:"white"}} onClick={()=>{
                                        setHoleNumber(Number(key.hole_number))
                                        setPar(Number(key.par))
                                        if(key.raw_score==0){
                                            setScoreChange(Number(key.par))
                                        }else{
                                            setScoreChange(Number(key.raw_score))
                                        }                                    
                                        setShowModal(true)
                                    }} key={i}>
                                        {
                                            (key.raw_score==0)?(
                                                <>
                                                _
                                                </>
                                            ):(
                                                <>{key.raw_score}</>
                                            )
                                        }
                                        {/* <IonInput
                                            type="number"
                                            value={key.raw_score}                                        
                                            onIonChange={(e) => Edit_row(parseInt(e.detail.value!, 10),key.hole_number)}
                                            ></IonInput> */}
                                    </IonCol>
                                )
                            }
                        })
                    }
                    <IonCol size="1" style={{color:"white"}}>
                        {submitted_data.back_score_total}
                    </IonCol>
                </IonRow>    
                <IonRow className="points-table-row-blank s-14">
                    <IonCol size="2" style={{color:"black"}}>
                        Points
                    </IonCol>
                    {
                        val && val.hole_details.map((key:any,i:number)=>{
                            // console.log("key",key)
                            if(key.hole_number>9){
                                return(
                                    <IonCol size="1" style={{color:"black",maxWidth: "100% !important",width: "100% !important"}} key={i}>
                                        {key.points}
                                    </IonCol>
                                )
                            }
                        })
                    }
                    <IonCol size="1" style={{color:"black", paddingInlineStart: "0",paddingInlineEnd: "0"}}>
                        {submitted_data.back_points_total}
                    </IonCol>
                </IonRow>   
                </> 
                ) : (
                    <>
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <IonText className="wg-600 black">Front 9</IonText>
                    </IonRow>
                    <IonRow className="points-table-row-color s-14">
                        <IonCol size="2" style={{color:"black"}}>
                            Hole
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number<=9){
                                    return(
                                        <IonCol size="1" style={{color:"black"}} key={i}>
                                            {key.hole_number}
                                        </IonCol>
                                    )
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"black"}}>
                            Tot
                        </IonCol>
                    </IonRow>    
                    <IonRow className="points-table-row-color s-14">
                        <IonCol size="2" style={{color:"black"}}>
                            Par
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number<=9){
                                    return(
                                        <IonCol size="1" style={{color:"black"}} key={i}>
                                            {key.par}
                                        </IonCol>
                                    )
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"black", paddingInlineStart: "0",paddingInlineEnd: "0"}}>
                            {submitted_data.front_par_total}
                        </IonCol>
                    </IonRow>    
                    <IonRow className="points-table-row-app-color s-14">
                        <IonCol size="2" style={{color:"white"}}>
                            Score
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number<=9){
                                    return(
                                        <IonCol size="1" style={{color:"white"}} key={i} onClick={()=>{
                                            setHoleNumber(Number(key.hole_number))
                                            setPar(Number(key.par))
                                            if(key.raw_score==0){
                                                setScoreChange(Number(key.par))
                                            }else{
                                                setScoreChange(Number(key.raw_score))
                                            }                                    
                                            setShowModal(true)
                                        }}>
                                            {
                                                (key.raw_score==0)?(
                                                    <>
                                                    _
                                                    </>
                                                ):(
                                                    <>{key.raw_score}</>
                                                )
                                            }
                                            {/* <IonInput
                                                type="number"
                                                value={key.raw_score}                                        
                                                onIonChange={(e) => Edit_row(parseInt(e.detail.value!, 10),key.hole_number)}
                                                ></IonInput> */}
                                        </IonCol>
                                    )
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"white"}}>
                            {submitted_data.front_score_total}
                        </IonCol>
                    </IonRow>    
                    <IonRow className="points-table-row-blank s-14">
                        <IonCol size="2" style={{color:"black"}}>
                            Points
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number<=9){
                                    return(
                                        <IonCol size="1" style={{color:"black",maxWidth: "100% !important",width: "100% !important"}} key={i}>
                                            {key.points}
                                        </IonCol>
                                    )
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"black"}}>
                            {submitted_data.front_points_total}
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-justify-content-center ion-text-center mt-10">
                        <IonText className="wg-600 black">Back 9</IonText>
                    </IonRow>
                    <IonRow className="points-table-row-color s-14">
                        <IonCol size="2" style={{color:"black"}}>
                            Hole
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number>9){
                                    return(
                                        <IonCol size="1" style={{color:"black"}} key={i}>
                                            {key.hole_number}
                                        </IonCol>
                                    )
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"black"}}>
                            Tot
                        </IonCol>
                    </IonRow>    
                    <IonRow className="points-table-row-color s-14">
                        <IonCol size="2" style={{color:"black"}}>
                            Par
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number>9){
                                    return(
                                        <IonCol size="1" style={{color:"black"}} key={i}>
                                            {key.par}
                                        </IonCol>
                                    )
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"black"}}>
                            {submitted_data.back_par_total}
                        </IonCol>
                    </IonRow>    
                    <IonRow className="points-table-row-app-color s-14">
                        <IonCol size="2" style={{color:"white"}}>
                            Score
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number>9){
                                    return(
                                        <IonCol size="1" style={{color:"white"}} onClick={()=>{
                                            setHoleNumber(Number(key.hole_number))
                                            setPar(Number(key.par))
                                            if(key.raw_score==0){
                                                setScoreChange(Number(key.par))
                                            }else{
                                                setScoreChange(Number(key.raw_score))
                                            }                                    
                                            setShowModal(true)
                                        }} key={i}>
                                            {
                                                (key.raw_score==0)?(
                                                    <>
                                                    _
                                                    </>
                                                ):(
                                                    <>{key.raw_score}</>
                                                )
                                            }
                                            {/* <IonInput
                                                type="number"
                                                value={key.raw_score}                                        
                                                onIonChange={(e) => Edit_row(parseInt(e.detail.value!, 10),key.hole_number)}
                                                ></IonInput> */}
                                        </IonCol>
                                    )
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"white"}}>
                            {submitted_data.back_score_total}
                        </IonCol>
                    </IonRow>    
                    <IonRow className="points-table-row-blank s-14">
                        <IonCol size="2" style={{color:"black"}}>
                            Points
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number>9){
                                    return(
                                        <IonCol size="1" style={{color:"black",maxWidth: "100% !important",width: "100% !important"}} key={i}>
                                            {key.points}
                                        </IonCol>
                                    )
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"black", paddingInlineStart: "0",paddingInlineEnd: "0"}}>
                            {submitted_data.back_points_total}
                        </IonCol>
                    </IonRow>   
                    </> 
                )
            }
            <br/>
            <br/>
        </IonGrid>
        <IonModal isOpen={showModal} className='my-custom-class1' showBackdrop={true} backdropDismiss={true}
          initialBreakpoint={0.45} onDidDismiss={() => setShowModal(false)}>
            <div>
                <IonGrid>
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <IonCol size="4" className="">
                            {
                                (hole_number==1 || (holes == 9 && hole_number == 10))?(
                                    <>
                                    </>
                                ):(
                                    <IonItem lines="none" onClick={()=>{
                                    val && val.hole_details.map((key:any,i:number)=>{
                                        if(key.hole_number == (hole_number-1)){
                                                editRow(score_change,hole_number)
                                                setHoleNumber(Number(key.hole_number))
                                                setPar(Number(key.par))
                                                if(key.raw_score==0){
                                                    setScoreChange(Number(key.par))
                                                }else{
                                                    setScoreChange(Number(key.raw_score))
                                                }                                                
                                                setShowModal(true)    
                                        }
                                    }) 
                                    }} className="hole-button">
                                        <IonIcon icon={chevronBackOutline} className="color-blue"/>
                                        <IonText className="s-16 wg-600 color-blue"> # {hole_number-1}</IonText>
                                    </IonItem>
                                )
                            }
                        </IonCol>
                        <IonCol size="4">
                            <IonText className="s-16 wg-600 color-blue"> # {hole_number}</IonText>
                        </IonCol>
                        <IonCol size="4">
                            {
                                ((holes == 9 && front9 == true && hole_number == 9) || (holes == 9 && back9 == true && hole_number == 18) || (holes == 18 && hole_number==18))?(
                                    <IonItem lines="none" onClick={()=>{
                                        val && val.hole_details.map((key:any,i:number)=>{
                                            if(key.hole_number == (hole_number)){
                                                editRow(score_change,hole_number)
                                                setPar(Number(key.par))
                                                setHoleNumber(Number(key.hole_number))
                                                if(key.raw_score==0){
                                                    setScoreChange(Number(key.par))
                                                }else{
                                                    setScoreChange(Number(key.raw_score))
                                                }                                                
                                                setShowModal(true)    
                                            }
                                        }) 
                                        // console.log("val",val)
                                        updateEntry('SCORE_RECORDS', val.id, {'hole_details': val.hole_details});
                                        updateEntry('SCORE_RECORDS', val.id, {'points': points});
        
                                    }} className="hole-button">
                                        <IonText className="s-16 wg-600 color-blue">Next</IonText>
                                    </IonItem>
                                ):(
                                    <IonItem lines="none" onClick={()=>{
                                        val && val.hole_details.map((key:any,i:number)=>{
                                            if(key.hole_number == (hole_number+1)){
                                                editRow(score_change,hole_number)
                                                setHoleNumber(Number(key.hole_number))
                                                setPar(Number(key.par))
                                                if(key.raw_score==0){
                                                    setScoreChange(Number(key.par))
                                                }else{
                                                    setScoreChange(Number(key.raw_score))
                                                }                                                
                                                setShowModal(true)    
                                            }
                                        }) 
                                        // console.log("val",val)
                                        updateEntry('SCORE_RECORDS', val.id, {'hole_details': val.hole_details});
                                        updateEntry('SCORE_RECORDS', val.id, {'points': points});
        
                                    }} className="hole-button">
                                        <IonText className="s-16 wg-600 color-blue"># {hole_number+1}</IonText><IonIcon icon={chevronForwardOutline} className="color-blue"/>
                                    </IonItem>
                                )
                            }
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-text-center mt-10 mb-10">
                        {
                            Show_respective_Holes(par).map((key:any,i:number)=>{
                                return(
                                    <IonCol size="2" onClick={()=>{
                                        setScoreChange(key)
                                    }} key={i}>
                                    <div className={(score_change==key)?'div edit-table-circle':'edit-table-circle'}>
                                        <IonText>{key}</IonText>
                                        </div>
                                    </IonCol>
            
                                )
                            })
                        }
                        {/* <IonCol size="2.4" onClick={()=>{
                            setScoreChange(2)
                        }}>
                        <div className={(score_change==2)?'div':''}>2</div>
                        </IonCol>
                        <IonCol size="2.4" onClick={()=>{
                            setScoreChange(3)
                        }}>
                        <div className={(score_change==3)?'div':''}>3</div>
                        </IonCol>
                        <IonCol size="2.4" onClick={()=>{
                            setScoreChange(4)
                        }}>
                        <div className={(score_change==4)?'div':''}>4</div>
                        </IonCol>
                        <IonCol size="2.4" onClick={()=>{
                            setScoreChange(5)
                        }}>
                        <div className={(score_change==5)?'div':''}>5</div>
                        </IonCol> */}
                    </IonRow>
                    
                    {/* <IonRow className="ion-justify-content-center ion-text-center mt-10">
                        <IonCol size="2.4" onClick={()=>{
                            setScoreChange(6)
                        }}>
                        <div className={(score_change==6)?'div':''}>6</div>
                        </IonCol>
                        <IonCol size="2.4" onClick={()=>{
                            setScoreChange(7)
                        }}>
                        <div className={(score_change==7)?'div':''}>7</div>
                        </IonCol>
                        <IonCol size="2.4" onClick={()=>{
                            setScoreChange(8)
                        }}>
                        <div className={(score_change==8)?'div':''}>8</div>
                        </IonCol>
                        <IonCol size="2.4">
                        </IonCol>
                        <IonCol size="2.4">                        
                        </IonCol>
                    </IonRow> */}
                    {/* <IonRow className="ion-justify-content-center ion-text-center">
                        <IonText className="s-16 wg-600 color-blue">Total Points: {submitted_data.total_points}</IonText>
                    </IonRow> */}
                    <IonRow className="ion-text-center mt-25 display-flex justify-content-space-evenly">
                        {
                            (post_score_func(holes))?(
                                <>
                                <IonRow className="ion-justify-content-center ion-text-center">
                                    <IonCol>
                                    <button className='finish-button hole-button' onClick={()=>{
                                        setScoreRecord()
                                    }}>
                                        <IonText className="s-16 wg-600 color-blue">Post Score</IonText>
                                    </button>

                                    </IonCol>
                                    <IonCol>
                                    <button className='finish-button hole-button' onClick={() => setShowModal(false)}>
                                    <IonText className="s-16 wg-600 color-red">Close</IonText>
                                    </button>
                                        
                                    </IonCol>
                                </IonRow>
                                </>
                            ):(
                                <>
                                    <button className='finish-button hole-button' onClick={() => setShowModal(false)}>
                                        <IonText className="s-16 wg-600 color-red">Close</IonText>
                                    </button>
                                </>
                            )
                        }
                    </IonRow>
                </IonGrid>
            {/* <p>This is modal content</p> */}
            {/* <IonButton onClick={() => setShowModal(false)}>Close Modal</IonButton> */}
            </div>
        </IonModal>
        </>
    );
};

export default EditTable;