import { IonContent, IonPage, IonTitle, IonToolbar, IonIcon, IonGrid, IonCol, IonRow, IonText, IonSelect, IonSelectOption, isPlatform, IonInput, IonFab, IonFabButton } from '@ionic/react';
import { useEffect, useState } from 'react';
import * as utils from '../../js/utils';
import { useQuill } from 'react-quilljs';
// or const { useQuill } = require('react-quilljs');
import 'quill/dist/quill.snow.css'; // Add css for snow theme
// or import 'quill/dist/quill.bubble.css'; // Add css for bubble theme
import { arrowBackOutline } from 'ionicons/icons'
import { createEntryWithoutId } from '../../js/firebase_doc_functions';

const CreatePost: React.FC = () => {
    const [content, setContent] = useState<string>('');
    const [packs,setPacks] = useState<any>([]);
    const [publish_to,setPackName] = useState<any>(() => {
      if(localStorage.getItem("tab_value") == "Community"){
        return 'Community';
      }
      if(localStorage.getItem("tab_value") == "My_Packs"){
        var curPack = localStorage.getItem("current_pack")
        var cp = JSON.parse(curPack || "")
        return 'Pack~'+cp["pack_id"]+"~"+cp["pack_name"]
      }
    });
    const [current_pack, setCurrentPack] = useState<any>();
    const [current_user, setCurentUser] = useState<any>();
    const [url, setUrl] = useState<string>('');
    const theme = 'snow';


    // const theme = 'bubble';
    const modules = {
      toolbar: [
        ['bold', 'italic', 'underline'],
        ['image'],
      ],
    };
    const placeholder = 'About';
    const formats = ['bold', 'italic', 'underline','image'];

    const { quill, quillRef } = useQuill({ theme, modules, formats, placeholder });


    const postFeed = async()=> {
      console.log("a", publish_to);
      if (content.length == 0) {
        // do something
        console.log(
          "Text cannot be empty!"
        );
      }
      if(publish_to.length == 0) {
        console.log(
          "share to is required"
        );
      }
      let feed_id = await utils.randomString(28, '#aA');
      if(publish_to.split('~')[0] == 'Pack'){
        var obj = {
          visibility: 'Pack',
          pack_id: publish_to.split('~')[1],
          pack_name: publish_to.split('~')[2],
          user_id: current_user.user_id,
          user_name: current_user.first_name+' '+current_user.last_name,
          user_image: current_user.logo_url != undefined ? current_user.logo_url : null,
          city: current_user.city,
          feed_id: feed_id,
       //   region_id: current_pack.region_id,
        //  region_name: current_pack.region_name,
          post_content: content,
          post_text: '',
          url: url,
        }
        await createEntryWithoutId("NEWS_FEED", obj);
      } else if(publish_to == 'Community'){
        var obj = {
          visibility: 'Community',
          pack_id: current_pack.pack_id,
          pack_name: current_pack.pack_name,
          user_id: current_user.user_id,
          user_name: current_user.first_name+' '+current_user.last_name,
          user_image: current_user.logo_url != undefined ? current_user.logo_url : null,
          city: current_user.city,
          feed_id: feed_id,
        //  region_id: current_pack.region_id,
        //  region_name: current_pack.region_name,
          post_content: content,
          post_text: '',
          url: url,
        }
        await createEntryWithoutId("NEWS_FEED", obj);
      } else if(publish_to == "MyClub"){
        var obj = {
          visibility: 'MyClub',
          pack_id: current_pack.pack_id,
          pack_name: current_pack.pack_name,
        //  region_id: current_pack.region_id,
        //  region_name: current_pack.region_name,
          user_id: current_user.user_id,
          user_name: current_user.first_name+' '+current_user.last_name,
          user_image: current_user.logo_url != undefined ? current_user.logo_url : null,
          city: current_user.city,
          feed_id: feed_id,
          post_content: content,
          post_text: '',
          url: url,
        }
        await createEntryWithoutId("NEWS_FEED", obj);
      }
      window.location.href="/locker_room";
    }

    // console.log(quill);    // undefined > Quill Object
    // console.log(quillRef); // { current: undefined } > { current: Quill Editor Reference }
    useEffect(() => {
        if (quill) {
          quill.on('text-change', (delta:any, oldDelta:any, source:any) => {
            // console.log('Text change!');
            // console.log("quill text",quill.getText()); // Get text only
            // console.log("delta contents",quill.getContents()); // Get delta contents
            // console.log("Get innerHTML using quill",quill.root.innerHTML); // Get innerHTML using quill
            setContent(quill.root.innerHTML);
            // console.log("Get innerHTML using quillRef",quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
          });
        }
        let user = localStorage.getItem('firebase_user');
        if(user != null) {
          setPacks(JSON.parse(user).packs);
          setCurentUser(JSON.parse(user));
        }
        let current_pack = localStorage.getItem('current_pack');
        if(current_pack != null) {
          setCurrentPack(JSON.parse(current_pack));
          console.log(current_pack)
        }
      }, [quill]);
      const customAlertOptions = {
        header: "Share with"
      };

    return (
        <IonPage>
          <IonToolbar mode="ios" className={isPlatform('ios') ? 'ios-toolbar-top box-shadow': 'toolbar-top box-shadow' }>
            {/* <IonButtons slot="start">
              <a onClick={()=> window.location.href="/locker_room"}>
                <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
              </a>
            </IonButtons> */}
            <IonTitle>
              Post
            </IonTitle>
          </IonToolbar>
        <IonContent>
          <IonGrid className="mt-25">
            <div style={{ width: '95%', height: 230,margin:"auto" }}>
              <div ref={quillRef} style={{ width: '100%', height: 180,margin:"auto" }}/>
            </div>
            <IonRow className="ion-justify-content-center mt-10">
            <IonCol>
              <IonInput
                value={url}
                placeholder="Add URL"
                className="s-14 select-dropdown pl-20"
                mode="ios"
                onIonChange={(e) => {
                  setUrl(e.detail.value!);
                }}
              >
              </IonInput>
              </IonCol>
            </IonRow>
            <IonRow className="mt-10">
                <IonCol>
                    <IonText className="s-16 black wg-600">Share with:</IonText>
                </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                  <IonSelect value={publish_to}
                    interface='popover'
                    placeholder="Share with" className="s-14 wid-100 select-dropdown" onIonChange={e => setPackName(e.detail.value)} mode="md">
                      <IonSelectOption value="Community">Community</IonSelectOption>
                      <IonSelectOption value="MyClub">My Club</IonSelectOption>
                      {
                        (packs && packs.map((pack: any, index:number) => {
                          return(
                            <IonSelectOption value={'Pack~'+pack.pack_id+'~'+pack.pack_name} key={index}>{pack.pack_name}</IonSelectOption>
                          )
                        }))
                      }
                  </IonSelect>

                  {/* <select value={publish_to} placeholder="Share with">
                    <option value="Community">Community</option>
                    <option value="MyClub">MyClub</option>
                    {
                        (packs && packs.map((pack: any, index:number) => {
                          return(
                            <option value={'Pack~'+pack.pack_id+'~'+pack.pack_name} key={index}>{pack.pack_name}</option>
                          )
                        }))
                      }
                  </select> */}
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center ion-text-center">
              <IonCol>
                <button className='button-close bg-red white tt-upper wg-bold' onClick={()=> window.location.href="/locker_room"}>
                  Close
                </button>
              </IonCol>
              <IonCol>
                {
                  (content.length > 0)?(
                    <button className='button-close white tt-upper wg-bold bg-blue' onClick={postFeed}>
                      Share
                    </button>
                  ):(
                    <button className='button-close white tt-upper wg-bold bg disabled-button'>
                      Share
                    </button>
                  )
                }
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button">
              <IonFabButton
              className="main-fab"
              onClick={() => {
                  window.location.href = "/locker_room";
              }}
              >
              <IonIcon icon={arrowBackOutline} />
              </IonFabButton>
          </IonFab>
        </IonContent>
      </IonPage>
    );
};
export default CreatePost;
