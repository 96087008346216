import { IonGrid, IonRow, IonCol, IonText } from '@ionic/react';
import { useEffect, useState } from 'react';


interface Props{
    options:  {},
}

const TtTable: React.FC<Props> = (props) => {
    
    let obj = props.options
    const [val,setVal] = useState<any>('');
    const [front_par_total, setFrontParTotal] = useState<any>();
    const [front_score_total, setFrontScoreTotal] = useState<any>();
    const [front_points_total, setFrontPointsTotal] = useState<any>();
    const [back_par_total, setBackParTotal] = useState<any>();
    const [back_score_total, setBackScoreTotal] = useState<any>();
    const [back_points_total, setBackPointsTotal] = useState<any>();
    // console.log(obj)
    const setTotalValues = async(data:any)=> {
        let front_pars = 0;
        let front_scores = 0;
        let front_points = 0;
        let back_pars = 0;
        let back_scores = 0;
        let back_points = 0;
        data && data.hole_details.forEach((key:any,i:number)=>{
            if(key.hole_number<=9){
                
                front_pars += key.par
                
                front_scores += key.raw_score
                
                front_points += key.points
                
            } else {
                back_pars += key.par
                
                back_scores += key.raw_score
                
                back_points += key.points
            }
        });
        setFrontParTotal(front_pars);
        setFrontScoreTotal(front_scores);
        setFrontPointsTotal(front_points);
        setBackParTotal(back_pars);
        setBackScoreTotal(back_scores);
        setBackPointsTotal(back_points);
    }
    useEffect(() => {
        setVal(obj);
        setTotalValues(obj);
    }, [])
    const [val2,setVal2] = useState<any>(["Eagle","Birdie","Par","Bogey","Double","Triple"])
    
    return (
        <>
        <IonGrid className="">
        <IonRow className="ion-justify-content-center ion-text-center mt-10">
          <IonText className="black s-18">{val && val.user_name}</IonText>
        </IonRow>
        <IonRow className="ion-justify-content-center ion-text-center">
          <IonText className="black s-16">{val && val.date} &nbsp; {val.game_date_time}</IonText>
        </IonRow>
        
      </IonGrid>

        <IonGrid>
            {
                (val.holes == 9 && val.hole_details[0].hole_number <=9 ) || val.holes == 18 ? (
                    <>
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <IonText className="wg-600 black">Front 9</IonText>
                    </IonRow>
                    <IonRow className="points-table-row-color">
                        <IonCol size="2" style={{color:"black"}}>
                            Hole
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number<=9){
                                    return(
                                        <IonCol size="1" style={{color:"black"}} key={i}>
                                            {key.hole_number}
                                        </IonCol>
                
                                        )
                
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"black"}}>
                            Tot
                        </IonCol>
                    </IonRow>    
                    <IonRow className="points-table-row-color">
                        <IonCol size="2" style={{color:"black"}}>
                            Par
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                
                                if(key.hole_number<=9){
                                    return(
                                        <IonCol size="1" style={{color:"black"}} key={i}>
                                            {key.par}
                                        </IonCol>
                
                                        )
                
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"black", paddingInlineStart: "0",paddingInlineEnd: "0"}}>
                            {front_par_total}
                        </IonCol>
                    </IonRow>    
                    <IonRow className="points-table-row-app-color">
                        <IonCol size="2" style={{color:"white"}}>
                            Score
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                if(key.hole_number<=9){
                                    return(
                                        <IonCol size="1" style={{color:"white"}} key={i}>
                                            {key.raw_score}
                                        </IonCol>
                
                                        )
                
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"white"}}>
                            {front_score_total}
                        </IonCol>
                    </IonRow>    
                    <IonRow className="points-table-row-blank">
                        <IonCol size="2" style={{color:"black"}}>
                            Points
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number<=9){
                                    return(
                                        <IonCol size="1" style={{color:"black"}} key={i}>
                                            {key.points}
                                        </IonCol>
                                    )
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"black"}}>
                            {front_points_total}
                        </IonCol>
                    </IonRow> 
                    </>
                ) : (
                    <>
                    <IonRow className="ion-justify-content-center ion-text-center mt-10">
                        <IonText className="wg-600 black">Back 9</IonText>
                    </IonRow>
                    <IonRow className="points-table-row-color">
                        <IonCol size="2" style={{color:"black"}}>
                            Hole
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number>9){
                                    return(
                                        <IonCol size="1" style={{color:"black"}} key={i}>
                                            {key.hole_number}
                                        </IonCol>
                                    )
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"black"}}>
                            Tot
                        </IonCol>
                    </IonRow>    
                    <IonRow className="points-table-row-color">
                        <IonCol size="2" style={{color:"black"}}>
                            Par
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number>9){
                                    return(
                                        <IonCol size="1" style={{color:"black"}} key={i}>
                                            {key.par}
                                        </IonCol>
                                        )
                
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"black"}}>
                            {back_par_total}
                        </IonCol>
                    </IonRow>    
                    <IonRow className="points-table-row-app-color">
                        <IonCol size="2" style={{color:"white"}}>
                            Score
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                //console.log("key",key)
                                if(key.hole_number>9){
                                    return(
                                        <IonCol size="1" style={{color:"white"}} key={i}>
                                            {key.raw_score}
                                        </IonCol>
                
                                        )
                
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"white"}}>
                            {back_score_total}
                        </IonCol>
                    </IonRow>    
                    <IonRow className="points-table-row-blank">
                        <IonCol size="2" style={{color:"black"}}>
                            Points
                        </IonCol>
                        {
                            val && val.hole_details.map((key:any,i:number)=>{
                                // console.log("key",key)
                                if(key.hole_number>9){
                                    return(
                                        <IonCol size="1" style={{color:"black"}} key={i}>
                                            {key.points}
                                        </IonCol>
                
                                        )
                
                                }
                            })
                        }
                        <IonCol size="1" style={{color:"black", paddingInlineStart: "0",paddingInlineEnd: "0"}}>
                            {back_points_total}
                        </IonCol>
                    </IonRow>    
                    </>
                )
            }

            <br/>
            <br/>
            <IonRow className="ion-justify-content-center ion-text-center" style={{width:"85%",margin:"auto",borderBottom:"0.5px  solid black"}}>
               {
                   val2 && val2.map((key:any,i:number)=>{
                       return(
                        <IonCol size="2" key={i}>
                            <IonText className="s-11">{key}</IonText>
                        </IonCol>

                       )
 
                   })
               }
                        <IonCol size="2">
                            <IonText className="black s-14">{val && val.points.eagle}</IonText>
                        </IonCol>
                        <IonCol size="2">
                            <IonText className="black s-14">{val && val.points.birdie}</IonText>
                        </IonCol>
                        <IonCol size="2">
                            <IonText className="black s-14">{val && val.points.par_value}</IonText>
                        </IonCol>
                        <IonCol size="2">
                            <IonText className="black s-14">{val && val.points.bogey}</IonText>
                        </IonCol>
                        <IonCol size="2">
                            <IonText className="black s-14">{val && val.points.double_bogey}</IonText>
                        </IonCol>
                        <IonCol size="2">
                            <IonText className="black s-14">{val && val.points.triple_bogey}</IonText>
                        </IonCol>
                        



            </IonRow>
            <IonRow className="ion-justify-content-center ion-text-center mt-10">
                <IonText className="wg-500 black">Total Points: {val && val.total_points}</IonText>
            </IonRow>



        </IonGrid>
        </>
    );
};

export default TtTable;