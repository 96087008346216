import {
  IonContent,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonLabel,
  IonGrid,
  IonFab,
  IonFabButton,
  IonCol,
  IonItem,
  IonRow,
  IonText,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonDatetime,
  IonModal,
  IonSearchbar,
  IonList,
  isPlatform,
} from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import _ from "lodash";
import { createEntryWithoutId, updateEntry } from "../../js/firebase_doc_functions";
import firebase from "../../js/firebase.js";
import { useQuill } from 'react-quilljs';
// or const { useQuill } = require('react-quilljs');
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { format } from "date-fns";

const db = getFirestore(firebase);

const CreateEvent: React.FC = () => {
  const [current_user, setCurrentUser] = useState<any>({});
  const [content, setContent] = useState<string>('');
  const [event_name, setEventName] = useState<string>("");
  const [rounds, setRounds] = useState<string>("");
  const [selected_date, setSelectedDate] = useState<string>(format(new Date(), "MM/dd/yyyy"));
  const [city, setCity] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [event_format, setEventFormat] = useState<string>("");
  const [days_of_week, setDaysOfWeek] = useState<string>("");
  const [age, setAge] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [publish_to, setPublishTo] = useState<string>("");
  const [organized_by, setOrganizedBy] = useState<string>("");
  const [event_types, setEventTypes] = useState<any>([]);
  const [region, setRegion] = useState<string>("");
  const [regions, setRegions] = useState<any>([]);
  const [event_type, setEventType] = useState<string>("");
  const theme = 'snow';
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['image'],
    ],
  };
  const placeholder = 'About';
  const formats = ['bold', 'italic', 'underline','image'];

  const { quill, quillRef } = useQuill({ theme, modules, formats, placeholder });
  const [cities, setCities] = useState<any>([]);
  const getConfig=async()=> {
    let typesQuery = query(collection(db, "CONFIG"), where('type', '==', "EVENT_TYPES"));
    const typesQuerySnapshot = await getDocs(typesQuery);
    let doc_item_e = typesQuerySnapshot.docs[0].data();
    doc_item_e.id = typesQuerySnapshot.docs[0].id;
    setEventTypes(doc_item_e);
    let citiesQuery = query(collection(db, "CONFIG"), where('type', '==', "EVENT_CITIES"));
    const citiesQuerySnapshot = await getDocs(citiesQuery);
    let doc_item_c = citiesQuerySnapshot.docs[0].data();
    doc_item_c.id = citiesQuerySnapshot.docs[0].id;
    setCities(doc_item_c);
    let regionsQuery = query(collection(db, "REGIONS"), where('created_at', '>', 0));
    const regionsQuerySnapshot = await getDocs(regionsQuery);
    await Promise.all(
      regionsQuerySnapshot.docs.map(async (region) => {
        var doc_item = region.data();
        doc_item.id = region.id;
        return doc_item;
      })
    ).then((results) => {
      setRegions(results);
    });
  }
  const updateEventType = async(type:string)=> {
    let present = event_types.types.filter((x:string)=> x.toLowerCase() == type.toLowerCase()).length > 0 ? true : false;
    if(!present) {
      let new_types = [...event_types.types, type];
      await updateEntry("CONFIG", event_types.id, {types: new_types});
    } 
  }
  const updateEventCities = async(city:string)=> {
    let present = cities.cities.filter((x:string)=> x.toLowerCase() == city.toLowerCase()).length > 0 ? true : false;
    if(!present) {
      let new_cities = [...cities.cities, city];
      await updateEntry("CONFIG", cities.id, {cities: new_cities});
    } 
  }
  const createEvent = async()=> {
    let region_data = regions.filter((x:any)=> x.region_name == region);
    var obj = {
      event_name: event_name,
      city: city,
      rounds: Number(rounds),
      url: url,
      format: event_format,
      days_of_week: days_of_week,
      age: age,
      gender: gender,
      organized_by: organized_by,
      event_type: event_type,
      event_date: selected_date,
      event_description: content,
      event_date_timestamp: new Date(selected_date).getTime(),
      region_id: region_data[0].region_id,
      region_name: region,
    }
    await updateEventType(event_type);
    await updateEventCities(city);
    if(publish_to == "Events") {
      await createEntryWithoutId("EVENTS", obj);
    } else {
      await createEntryWithoutId("CHAMPIONSHIPS", obj);
    }
    window.location.href="/admin_events";
  }
  
  const [new_types, setNewTypes] = useState<any>(event_types);
  const [new_cities, setNewCities] = useState<any>(cities);
  const [new_regions, setNewRegions] = useState<any>(regions);
  const [showCityModal, setShowCityModal] = useState(false);
  const [showEventTypeModal, setShowEventTypeModal] = useState(false);
  const [showRegionModal, setShowRegionModal] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta:any, oldDelta:any, source:any) => {
        // console.log('Text change!');
        // console.log("quill text",quill.getText()); // Get text only
        // console.log("delta contents",quill.getContents()); // Get delta contents
        // console.log("Get innerHTML using quill",quill.root.innerHTML); // Get innerHTML using quill
        setContent(quill.root.innerHTML);
        // console.log("Get innerHTML using quillRef",quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
      });
    }
    let user = localStorage.getItem("firebase_user");
    if (user != null) {
      setCurrentUser(JSON.parse(user));
      getConfig();
    }
  }, [quill]);
  return (
    <IonPage>
      <IonToolbar
        mode="ios"
        className={
          isPlatform("ios")
            ? "ios-toolbar-top box-shadow"
            : "toolbar-top box-shadow"
        }
      >
        {/* <IonButtons slot="start">
          <a onClick={() => (window.location.href = "/admin_events")}>
            <IonIcon className="well-back-icon" icon={chevronBackOutline} />
          </a>
        </IonButtons> */}
        <IonTitle>
          <IonLabel>
            <IonText>Create Event</IonText>
          </IonLabel>
        </IonTitle>
      </IonToolbar>
      <IonContent>
        <IonGrid>
          <>
            <IonRow className="ion-justify-content-center mt-10 select-modal">
              <IonInput
                value={event_type}
                placeholder="Event type"
                className="s-14 select-pack-dropdown pl-20"
                mode="ios"
                onIonChange={(e) => {
                  setEventType(e.detail.value!);
                }}
              >
              </IonInput>
            </IonRow>
            <IonRow className="ion-justify-content-center mt-10 select-modal">
              <IonInput
                value={region}
                placeholder="Region"
                className="s-14 select-pack-dropdown pl-20"
                mode="ios"
                onClick={() => {
                  setShowRegionModal(true);
                  setRegion(region);
                }}
              >
              </IonInput>
            </IonRow>
            <IonRow className="ion-justify-content-center mt-10 select-modal">
              <IonInput
                value={city}
                placeholder="City"
                className="s-14 select-pack-dropdown pl-20"
                mode="ios"
                onIonChange={(e) => {
                  setCity(e.detail.value!);
                }}
              >
              </IonInput>
            </IonRow>
            <IonRow className="ion-justify-content-center mt-10 select-modal">
              <IonInput
                value={event_name}
                placeholder="Event name"
                className="s-14 select-pack-dropdown pl-20"
                mode="ios"
                onIonChange={(e) => {
                  setEventName(e.detail.value!);
                }}
              >
              </IonInput>
            </IonRow>
            <IonRow
              className="ion-justify-content-center mt-10 select-modal"
              style={{ padding: "0px 10px 0px 10px" }}
            >
              <IonCol className="pad-0">
                <IonInput
                  value={event_format}
                  placeholder="Event format"
                  className="s-14 select-pack-dropdown pl-20"
                  mode="ios"
                  onIonChange={(e) => {
                    setEventFormat(e.detail.value!);
                  }}
                >
                </IonInput>
              </IonCol>
              <IonCol className="pad-0">
                <IonInput
                  value={rounds}
                  placeholder="Rounds"
                  className="s-14 select-pack-dropdown pl-20"
                  mode="ios"
                  onIonChange={(e) => {
                    setRounds(e.detail.value!);
                  }}
                >
                </IonInput>
              </IonCol>
            </IonRow>
            <IonRow
              className="ion-justify-content-center mt-10 select-modal"
              style={{ padding: "0px 10px 0px 10px" }}
            >
              <IonCol className="pad-0">
                <IonInput value={selected_date}            
                  placeholder="Select date" className="s-14 select-pack-dropdown pl-20" 
                  mode="ios" 
                  onClick={()=> {
                    setShowDatePicker(true);
                  }}>
                </IonInput>
                {
                    (showDatePicker) ? (
                        <IonDatetime
                            presentation="date"
                            size='cover'
                            onIonChange={(e) => {
                                setSelectedDate(format(new Date(e.detail.value!), "MM/dd/yyyy"));
                                setShowDatePicker(false);
                            }}
                        ></IonDatetime>
                    ) : (
                        <></>
                    )
                }
              </IonCol>
              <IonCol className="pad-0">
                <IonInput
                  value={days_of_week}
                  placeholder="Days of week"
                  className="s-14 select-pack-dropdown pl-20"
                  mode="ios"
                  onIonChange={(e) => {
                    setDaysOfWeek(e.detail.value!);
                  }}
                >
                </IonInput>
              </IonCol>
            </IonRow>
            <IonRow
              className="ion-justify-content-center mt-10 select-modal"
            >
                <IonInput
                  value={age}
                  placeholder="Age/Gender"
                  className="s-14 select-pack-dropdown pl-20"
                  mode="ios"
                  onIonChange={(e) => {
                    setAge(e.detail.value!);
                  }}
                >
                </IonInput>
            </IonRow>
            <IonRow
              className="ion-justify-content-center mt-10 select-modal"
            >
              <IonInput
                value={organized_by}
                placeholder="Organized by"
                className="s-14 select-pack-dropdown pl-20"
                mode="ios"
                onIonChange={(e) => {
                  setOrganizedBy(e.detail.value!);
                }}
              >
              </IonInput>
            </IonRow> 
            <IonRow
              className="ion-justify-content-center mt-10 select-modal"
            >
              <IonInput
                value={url}
                placeholder="Add URL"
                className="s-14 select-pack-dropdown pl-20"
                mode="ios"
                onIonChange={(e) => {
                  setUrl(e.detail.value!);
                }}
              >
              </IonInput>
            </IonRow>
            <IonRow
              className="ion-justify-content-center mt-10 select-modal"
            >
              <IonSelect value={publish_to} interface="action-sheet"
              placeholder="Publish to" className="s-14 select-dropdown pl-20" onIonChange={e => setPublishTo(e.detail.value)} mode="ios">
                <IonSelectOption value="Events">Events</IonSelectOption>
                <IonSelectOption value="Championships">Championships</IonSelectOption>
              </IonSelect>
            </IonRow> 
            <div style={{ width: '89%', height: 230, margin:"10px 0px 0px 20px" }}>
              <div ref={quillRef} style={{ width: '100%', height: 180,margin:"auto" }}/>
            </div>
          </>
          <IonRow className="ion-justify-content-center ion-text-center mt-25">
            <button className="button-send white s-15 bg" onClick={createEvent}>
              <IonText className="wg-bold">Create</IonText>
            </button>
          </IonRow>
        </IonGrid>
        {/* <IonModal
          isOpen={showEventTypeModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          onDidDismiss={() => setShowEventTypeModal(false)}
          mode="md"
        >
          <>
            <IonRow
              className="ion-justify-content-center ion-text-center relative-row"
              onClick={() => setShowEventTypeModal(false)}
            >
              <IonCol size="4" className=""></IonCol>
              <IonCol size="4">
                <img src={require(`../../img/dash-modal.png`).default} />
              </IonCol>
              <IonCol size="4"></IonCol>
            </IonRow>
            <IonContent>
              <IonGrid>
                <IonList>
                  {event_types &&
                    event_types.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              setEventType(key.value);
                              setShowEventTypeModal(false);
                            }}
                          >
                            {key.text}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal> */}
        {/* <IonModal
          isOpen={showCityModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          onDidDismiss={() => setShowCityModal(false)}
          mode="md"
        >
          <>
            <IonRow
              className="ion-justify-content-center ion-text-center relative-row"
              onClick={() => setShowCityModal(false)}
            >
              <IonCol size="4" className=""></IonCol>
              <IonCol size="4">
                <img src={require(`../../img/dash-modal.png`).default} />
              </IonCol>
              <IonCol size="4"></IonCol>
            </IonRow>
            <IonSearchbar onIonChange={(e: any) => {
              console.log(e.detail.value!);
              if (String(e.detail.value!).length > 0) {
                let glo = new RegExp(String(e.detail.value!).toLowerCase());
                let new_city_arr = new Array();
                cities.map((item: any) => {
                  let real_item = item;
                  item = item.state_name.toLowerCase();

                  if (glo.test(item)) {
                    console.log("item", real_item);
                    new_city_arr.push(real_item);
                  }
                });
                console.log(new_city_arr);
                setNewCities(new_city_arr);
              } else {
                setNewCities(cities);
              }
              }} mode="ios"
              ></IonSearchbar>
            <IonContent>
              <IonGrid>
                <IonList>
                  {cities &&
                    cities.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              setCity(key.state_name);
                              setShowCityModal(false);
                            }}
                          >
                            {key.state_name}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal> */}
        <IonModal
          isOpen={showRegionModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowRegionModal(false)}
          mode="md"
        >
          <>
            <IonSearchbar onIonChange={(e: any) => {
              console.log(e.detail.value!);
              if (String(e.detail.value!).length > 0) {
                let glo = new RegExp(String(e.detail.value!).toLowerCase());
                let new_city_arr = new Array();
                regions.map((item: any) => {
                  let real_item = item;
                  item = item.state_name.toLowerCase();
                  if (glo.test(item)) {
                    console.log("item", real_item);
                    new_city_arr.push(real_item);
                  }
                });
                console.log(new_city_arr);
                setNewRegions(new_city_arr);
              } else {
                setNewRegions(regions);
              }
              }} mode="ios"
              ></IonSearchbar>
            <IonContent>
              <IonGrid>
                <IonList>
                  {regions &&
                    regions.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              setRegion(key.region_name);
                              setShowRegionModal(false);
                            }}
                          >
                            {key.region_name}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '45px'}}>
          <IonFabButton
          className="main-fab"
          onClick={() => {
            window.location.href = "/admin_events"
          }}
          >
          <IonIcon icon={arrowBackOutline} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};
export default CreateEvent;
