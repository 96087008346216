import { useEffect, useState } from 'react';
import { useQuill } from 'react-quilljs';
// or const { useQuill } = require('react-quilljs');
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import { arrowBackOutline } from 'ionicons/icons';
// or import 'quill/dist/quill.bubble.css'; // Add css for bubble theme
import { useHistory } from 'react-router-dom';
import { createEntryWithoutId } from '../../js/firebase_doc_functions';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import firebase from '../../js/firebase';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonRow, IonItem, IonLabel, IonInput, IonCol, IonFab, IonFabButton, IonIcon } from '@ionic/react';

const db = getFirestore(firebase);

const ReportAbuse: React.FC = () => {
    const [content, setContent] = useState<string>('');
    const [text, setText] = useState<string>();
    const [current_user, setCurrentUser] = useState<any>();
    const history = useHistory();
    const theme = 'snow';
    // const theme = 'bubble';
    const modules = {
      toolbar: [
        ['bold', 'italic', 'underline'],
        ['link','image'],
      ],
    };
    const placeholder = 'Tell us what is not right';
    const formats = ['bold', 'italic', 'underline','link','image'];
  
    const { quill, quillRef } = useQuill({ theme, modules, formats, placeholder });

    const submitReport = async()=> {
      var condition = where('is_portal_admin', '==', true);
      const q = query(collection(db, "USER_PROFILE"), condition);

      const querySnapshot = await getDocs(q);
      if(querySnapshot.docs && querySnapshot.docs.length > 0) {
        let doc_item = querySnapshot.docs[0].data();
        doc_item.id = querySnapshot.docs[0].id;
        let source ={
          id: current_user.user_id,
          name: current_user.first_name+" "+current_user.last_name,
          date: new Date().getTime(),
          image_url: '',
          ghin_number: current_user.ghin_number,
          email: current_user.email,
          city: current_user.city,
          region_id: current_user.region_id,
          region_name: current_user.region_name,
        }
        await createEntryWithoutId('USER_CONTENT_REPORTS',{
          'created_name': current_user.first_name+" "+current_user.last_name,
          'created_by': current_user.user_id,
          'user_id': doc_item.user_id,
          'user_name': 'Portal Admin',
          'source': source,
          'is_read': false,
          'content': content,
          'subject': text
        });
        alert("Your report has been submitted");
      }
    }
    
    useEffect(() => {
        if (quill) {
          quill.on('text-change', (delta:any, oldDelta:any, source:any) => {
            // console.log('Text change!');
            // console.log("quill text",quill.getText()); // Get text only
            // console.log("delta contents",quill.getContents()); // Get delta contents
            // console.log("Get innerHTML using quill",quill.root.innerHTML); // Get innerHTML using quill
            setContent(quill.root.innerHTML);
            // console.log("Get innerHTML using quillRef",quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
          });
        }
        let user = localStorage.getItem('firebase_user');
        if(user != null) {
          setCurrentUser(JSON.parse(user));
        }
      }, [quill]);

    return (
        <IonPage>
            <IonHeader>
            <IonToolbar>
              {/* <IonButtons slot="start">
                <a onClick={()=> window.history.back()}>
                  <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                </a>
              </IonButtons> */}
              <IonTitle>
                Report Abuse
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            
            <IonGrid className="mt-25">
                <IonRow>
                    <IonItem lines="none">
                        <IonLabel position="fixed">Subject:</IonLabel>
                        <IonInput value={text}
                        placeholder="type subject"
                        onIonChange={(e) => setText(e.detail.value!)}
                        >
                        </IonInput>
                    </IonItem>
                </IonRow>
                <IonRow className="mt-25">

                </IonRow>
                <div style={{ width: '90%', height: 230,margin:"auto" }}>
                    <div ref={quillRef} style={{ width: '100%', height: 180,margin:"auto" }}/>
                </div>   

              <IonRow className="ion-justify-content-center ion-text-center">
                  <IonCol>
                    <button className='button-close bg white tt-upper wg-bold' 
                    onClick={()=> {submitReport()}}>
                      Submit
                    </button>
                  </IonCol>
                </IonRow>
            </IonGrid>
            <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '45px'}}>
                <IonFabButton
                className="main-fab"
                onClick={() => {
                    window.history.back()
                }}
                >
                <IonIcon icon={arrowBackOutline} />
                </IonFabButton>
            </IonFab>
          </IonContent>

        </IonPage>
    );
};
export default ReportAbuse;