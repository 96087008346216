import { collection, query } from '@firebase/firestore';
import { IonContent, IonPage, IonTitle, IonToolbar, IonLabel, IonButtons, IonGrid, IonSegment, IonSegmentButton, IonText, isPlatform, IonDatetime, IonModal, IonCol, IonRow, IonFab, IonFabButton, IonIcon, IonCard, IonHeader } from '@ionic/react';
import { format, nextSunday,startOfWeek,subDays } from 'date-fns';
import { getDocs, getFirestore, where } from 'firebase/firestore';
import {  getEntry } from '../../js/firebase_doc_functions';
import { arrowBackOutline } from 'ionicons/icons';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import firebase from '../../js/firebase';
import ScoreTable from '../Components/score_table';
import { useParams } from 'react-router';
const db = getFirestore(firebase);

const TwilightTourOneOnOneLeaderboards: React.FC = () => {
    const {id}:any = useParams();
    const [tour_name,setTourName]= useState<any>();
    const [current_user, setCurrentUser] = useState<any>([]);
    const [users, setUsers] = useState<any>("Players");
    const [open, setOpen] = useState<boolean>(false);
    const [selected_date, setSelectedDate] = useState<any>(format(startOfWeek(new Date(), { weekStartsOn: 0 }), "yyyy-MM-dd"));
    const [min_date, setMinDate] = useState<string>('2019-01-01');
    const [max_date, setMaxDate] = useState<string>(format(new Date(), "yyyy-mm-dd"));
    const [showLeaderboardPicker, setShowLeaderboardPicker] = useState<boolean>(false);
    const [leaderboards_tab_value, setLeaderboardsTabValue] = useState<string>('Weekly');
    const [weekly_leaderboard_data, setWeeklyLeaderboardData] = useState<any>([]);
    const [score_record, setScoreRecord] = useState<any>([]);
    const [score_modal, setScoreModal] = useState<any>(false)
    const [season_leaderboard_users_data, setSeasonLeaderboardUsersData] = useState<any>([]);
    const [season_leaderboard_others_data, setSeasonLeaderboardOthersData] = useState<any>([]);
    const [season_name, setSeasonName] = useState<string>('');
    const getLeaderBoards = async (leaderboards_tab_value: string, date_to: any) => {
        const querySnapshot = await getEntry("TOUR_SETTINGS", id);
        if(querySnapshot.created_at) {
            let doc_item = querySnapshot;
            doc_item.id = id;
            setTourName(doc_item.tour_name);
        }    
        if (leaderboards_tab_value == 'Weekly') {
            const q = query(collection(db, "TT_WEEKLY_ONE_ON_ONE_LEADERBOARDS"), where('created_at', '>', 0), where('to_date', '==', date_to));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.docs && querySnapshot.docs.length > 0) {
                await Promise.all(
                    querySnapshot.docs.map((item: any) => {
                        var doc_item = item.data();
                        doc_item.id = item.id;
                        setSeasonName(doc_item.season_name);
                        return doc_item;
                    })
                ).then((results) => {
                    //setTeeTimes(_.sortBy(results, (e: any) => e.created_at).reverse());
                    let records = JSON.parse(JSON.stringify(results));
                    
                    if (records.length > 0) {
                        records = records[0].records.map((record: any) => {
                            record.player_one = record.players[0].user_name.split(' ');
                            record.player_two = record.players[1].user_name.split(' ');
                            record.player_id_one = record.players[0].user_id;
                            record.player_id_two = record.players[1].user_id;
                            record.net_score_one = record.individual_net_scores[0];
                            record.net_score_two = record.individual_net_scores[1];
                            record.quota_one = record.individual_quotas[0];
                            record.quota_two = record.individual_quotas[1];
                            record.points_one = record.individual_points[0];
                            record.points_two = record.individual_points[1];
                            record.competition_id_one = record.ids[0];
                            record.competition_id_two = record.ids[1];
                            record.margin = record.margin;
                            if (record.winner.user_id == record.player_id_one) {
                                record.player_id_one_highlight = true;
                            } else if (record.winner.user_id == record.player_id_two) {
                                record.player_id_two_highlight = true
                            }
                            return record;
                        });
                        setSeasonName(' - ' + results[0].season_name);
                    } else {
                        setSeasonName('');
                        records = [];
                    }
                    setWeeklyLeaderboardData(records);
                });
            } else {
                setSeasonName('');
                setWeeklyLeaderboardData([]);
            }
        } else if (leaderboards_tab_value == 'Season') {
            const q = query(collection(db, "TT_SEASON_ONE_ON_ONE_LEADERBOARDS"), where('to_date', '==', date_to));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.docs && querySnapshot.docs.length > 0) {
                await Promise.all(
                    querySnapshot.docs.map((item: any) => {
                        var doc_item = item.data();
                        doc_item.id = item.id;
                        return doc_item;
                    })
                ).then((results) => {
                    let records = JSON.parse(JSON.stringify(results));
                    records = _.sortBy(records, e => e.created_at).reverse();
                    let leaderboardUsers = records[0].users.map((user: any) => {
                        user.first_name = user.user_name.split(' ')[0];
                        user.last_name = user.user_name.split(' ')[1];
                        return user;
                    });
                    let otherUsers = records[0].other_users.map((user: any) => {
                        user.first_name = user.user_name.split(' ')[0];
                        user.last_name = user.user_name.split(' ')[1];
                        return user;
                    });
                    if (records[0] && records[0].users) {
                        setSeasonName(' - ' + results[0].season_name);
                        setSeasonLeaderboardUsersData(leaderboardUsers);
                        setSeasonLeaderboardOthersData(otherUsers);
                    } else {
                        setSeasonName('');
                        setSeasonLeaderboardUsersData([]);
                        setSeasonLeaderboardOthersData([]);
                    }
                });
            } else {
                setSeasonName('');
                setSeasonLeaderboardUsersData([]);
                setSeasonLeaderboardOthersData([]);
            }
        }
    }
    const showScoreRecord = async (competition_id: string) => {
        const q = query(collection(db, "TAO_COMPETITION_SCORES_LOG"), where('competition_id', '==', competition_id));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.docs && querySnapshot.docs.length > 0) {
            let doc_item = querySnapshot.docs[0].data();
            doc_item.id = querySnapshot.docs[0].id;
            var score_record = {
                hole_details: _.sortBy(doc_item.hole_details, e => e.hole_number),
                points: doc_item.points,
                total_points: doc_item.total_points,
                user_name: doc_item.user_name,
                course_name: doc_item.course_name,
                tee: doc_item.tee,
                holes: doc_item.holes,
                date: format(new Date(doc_item.played_at).getTime(), "MM/dd/yyyy"),
            };
            // console.log("score record",score_record)
            setScoreRecord(score_record)
            setScoreModal(true)
        }
    }
    useEffect(() => {
        let user = localStorage.getItem('firebase_user');
        if (user != null) {
            setCurrentUser(JSON.parse(user));
            let to_date = startOfWeek(new Date(), { weekStartsOn: 0 });
            //let from_date = subDays(to_date, 6);
            //to_date = format(to_date, 'yyyy-MM-dd');
            //from_date = format(from_date, 'yyyy-MM-dd');
            getLeaderBoards("Weekly",format(to_date,'yyyy-MM-dd'));
        }
    }, [])
    return (
        <IonPage>
            <IonToolbar mode="ios" className={isPlatform('ios') ? 'ios-toolbar-top box-shadow' : 'toolbar-top box-shadow'}>
                <IonLabel slot="start" className="s-16 w-800 color-blue" style={{ padding: '0px 0px 0px 10px' }}>
                    {tour_name} {season_name}
                </IonLabel>
                <IonRow slot="end" style={{ flexDirection: 'column', textAlign: 'center' }} onClick={() => {
                    setShowLeaderboardPicker(true);
                }}>
                    <IonText className="s-12">
                        Week ending:
                    </IonText>
                    <IonText className="color-blue s-14" >{selected_date}
                    </IonText>
                </IonRow>
            </IonToolbar>
            <IonContent>
                <>
                    {
                        leaderboards_tab_value == "Weekly" ? (
                            <IonGrid>
                                <IonRow className="ion-justify-content-center ion-text-center bb-line">
                                    <IonCol size="2.5">
                                        <IonText className="s-11 wg-600">Player 1</IonText>
                                    </IonCol>
                                    <IonCol size="2.5">
                                        <IonText className="s-11 wg-600">Net Score<br /><span className='s-10'>Score/Target</span></IonText>
                                    </IonCol>
                                    <IonCol size="2.5">
                                        <IonText className="s-11 wg-600">Player 2</IonText>
                                    </IonCol>
                                    <IonCol size="2.5">
                                        <IonText className="s-11 wg-600">Net Score<br /><span className='s-10'>Score/Target</span></IonText>
                                    </IonCol>
                                    <IonCol size="2">
                                        <IonText className="s-11 wg-600">Margin</IonText>
                                    </IonCol>
                                </IonRow>
                                {
                                    weekly_leaderboard_data && weekly_leaderboard_data.map((item: any, index: number) => {
                                        return (
                                            <IonRow className="ion-justify-content-center ion-text-center bb-line" key={index}>
                                                {
                                                    (item.player_id_one_highlight) ? (
                                                        <>
                                                            <IonCol size="2.5" onClick={() => {
                                                                showScoreRecord(item.competition_id_one);
                                                            }}>
                                                                <IonText className="s-11 wg-600 color-green">{item.player_one[0]}<br />{item.player_one[1]}</IonText>
                                                            </IonCol>
                                                            <IonCol size="2.5">
                                                                <IonText className="s-11 wg-600">{item.net_score_one}<br /><span className='s-10'>({item.points_one.total_points}/{item.quota_one})</span></IonText>
                                                            </IonCol>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <IonCol size="2.5" onClick={() => {
                                                                showScoreRecord(item.competition_id_two);
                                                            }}>
                                                                <IonText className="s-11 wg-600 color-green">{item.player_two[0]}<br />{item.player_two[1]}</IonText>
                                                            </IonCol>
                                                            <IonCol size="2.5">
                                                                <IonText className="s-11 wg-600">{item.net_score_two}<br /><span className='s-10'>({item.points_two.total_points}/{item.quota_two})</span></IonText>
                                                            </IonCol>
                                                        </>
                                                    )
                                                }
                                                {
                                                    (item.player_id_one_highlight) ? (
                                                        <>
                                                            <IonCol size="2.5" onClick={() => {
                                                                showScoreRecord(item.competition_id_two);
                                                            }}>
                                                                <IonText className="s-11 wg-600 color-red">{item.player_two[0]}<br />{item.player_two[1]}</IonText>
                                                            </IonCol>
                                                            <IonCol size="2.5">
                                                                <IonText className="s-11 wg-600">{item.net_score_two}<br /><span className='s-10'>({item.points_two.total_points}/{item.quota_two})</span></IonText>
                                                            </IonCol>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <IonCol size="2.5" onClick={() => {
                                                                showScoreRecord(item.competition_id_one);
                                                            }}>
                                                                <IonText className="s-11 wg-600 color-red">{item.player_one[0]}<br />{item.player_one[1]}</IonText>
                                                            </IonCol>
                                                            <IonCol size="2.5">
                                                                <IonText className="s-11 wg-600">{item.net_score_one}<br /><span className='s-10'>({item.points_one.total_points}/{item.quota_one})</span></IonText>
                                                            </IonCol>
                                                        </>
                                                    )
                                                }
                                                <IonCol size="2">
                                                    <IonText className="s-11 wg-600">{item.margin}</IonText>
                                                </IonCol>
                                            </IonRow>
                                        )
                                    })
                                }
                            </IonGrid>
                        ) : leaderboards_tab_value == "Season" ? (
                            <IonGrid>
                                <IonRow className='ion-justify-content-center ion-text-center'>
                                    <IonText className="s-14">
                                        <sup>*</sup>Note: Total points are based on best 6 finishes
                                    </IonText>
                                </IonRow>
                                <IonRow className="ion-justify-content-center ion-text-center bb-line">
                                    <IonCol size="1">
                                        <IonText className="s-11 wg-600">Pos</IonText>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonText className="s-11 wg-600">Player</IonText>
                                    </IonCol>
                                    <IonCol size="2.5">
                                        <IonText className="s-11 wg-600">W-T-L</IonText>
                                    </IonCol>
                                    <IonCol size="2.5">
                                        <IonText className="s-11 wg-600">Total<br />Points</IonText>
                                    </IonCol>
                                    <IonCol size="2">
                                        <IonText className="s-11 wg-600">No. of <br /> Weeks</IonText>
                                    </IonCol>
                                </IonRow>
                                {
                                    season_leaderboard_users_data && season_leaderboard_users_data.map((user: any, index: number) => {
                                        return (
                                            <IonRow className="ion-justify-content-center ion-text-center bb-line" key={index}>
                                                <IonCol size="1">
                                                    <IonText className="s-11 wg-600">{index + 1}</IonText>
                                                </IonCol>
                                                <IonCol size="4">
                                                    <IonText className="s-11 wg-600">{user.first_name}<br />{user.last_name}</IonText>
                                                </IonCol>
                                                <IonCol size="2.5">
                                                    <IonText className="s-11 wg-600">{user.wonCount} - {user.tiedCount} - {user.lostCount}</IonText>
                                                </IonCol>
                                                <IonCol size="2.5">
                                                    <IonText className="s-11 wg-600 color-blue">{user.seasonal_points}</IonText>
                                                </IonCol>
                                                <IonCol size="2">
                                                    <IonText className="s-11 wg-600">{user.competitions_length}</IonText>
                                                </IonCol>
                                            </IonRow>
                                        )
                                    })
                                }
                            </IonGrid>
                        ) : (
                            <></>
                        )
                    }
                </>
                <IonModal isOpen={showLeaderboardPicker} className='my-custom-class2'
                    initialBreakpoint={0.5} showBackdrop={true} backdropDismiss={true}
                    onDidDismiss={() => setShowLeaderboardPicker(false)} mode="md">
                    <IonContent>
                        <IonDatetime
                            presentation="date"
                            size='cover'
                            onIonChange={(e) => {
                                setSelectedDate(format(startOfWeek(new Date(e.detail.value!), { weekStartsOn: 0 }), "yyyy-MM-dd"));
                                getLeaderBoards(leaderboards_tab_value, format(startOfWeek(new Date(e.detail.value!), { weekStartsOn: 0 }), "yyyy-MM-dd"))
                                setShowLeaderboardPicker(false);
                            }}
                        ></IonDatetime>
                    </IonContent>
                </IonModal>
                <IonModal isOpen={score_modal} className='my-custom-class'>
                    <>
                        <IonHeader>
                            <IonToolbar>
                                {/* <IonButtons slot="start">
                                <a onClick={()=> setScoreModal(false)}>
                                    <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                                </a>
                            </IonButtons> */}
                                <IonTitle>
                                    Points
                                </IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <ScoreTable options={score_record} />
                            <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{ marginBottom: '45px' }}>
                                <IonFabButton
                                    className="main-fab"
                                    onClick={() => {
                                        setScoreModal(false)
                                    }}
                                >
                                    <IonIcon icon={arrowBackOutline} />
                                </IonFabButton>
                            </IonFab>
                        </IonContent>
                    </>
                </IonModal>
                <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{ marginBottom: '45px' }}>
                    <IonFabButton
                        className="main-fab"
                        onClick={() => {
                            window.location.href = "/leagues/formats";
                        }}
                    >
                        <IonIcon icon={arrowBackOutline} />
                    </IonFabButton>
                </IonFab>
            </IonContent>
            <IonToolbar mode="ios" className="single-segment-toolbar" slot="bottom">
                <IonSegment
                    onIonChange={(e) => {
                        console.log("tab1 Segment selected", e.detail.value);
                        setLeaderboardsTabValue(e.detail.value!);
                        if (e.detail.value == "Weekly") {
                            getLeaderBoards("Weekly", selected_date);
                        } else if (e.detail.value == "Season") {
                            getLeaderBoards("Season", selected_date);
                        }
                    }}
                    value={leaderboards_tab_value}
                    mode="ios"
                    className="new-segment"
                >
                    <IonSegmentButton value="Weekly" className="new-segment-button">
                        <IonLabel className="new-segment-button-label">Weekly</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="Season" className="new-segment-button">
                        <IonLabel className="new-segment-button-label">Season</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
            </IonToolbar>
        </IonPage>
    );
};
export default TwilightTourOneOnOneLeaderboards;
