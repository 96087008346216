import Table from '../Components/table'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonIcon, IonLabel, IonButtons, IonGrid, IonCard, IonCardContent, IonCardHeader, IonCol, IonRow, IonText, IonBackButton, IonModal, isPlatform, IonFab, IonFabButton, useIonToast } from '@ionic/react';
import { useEffect, useState } from 'react';
import EditTable from '../Components/nsc_score_table';
import { format } from 'date-fns';
import _ from 'lodash';
import firebase from '../../js/firebase.js';

import { getFirestore, collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { arrowBackOutline, chevronBackOutline } from 'ionicons/icons';
import { getEntry, updateEntry } from '../../js/firebase_doc_functions';

const db = getFirestore(firebase);
const season_name =localStorage.getItem("season_name");
const NetScoreChallengeCheckins: React.FC = () => {
    const [tao_checkins, setTaoCheckins] = useState<any>([]);
    const [current_user, setCurrentUser] = useState<any>([]);
    const [score_record, setScoreRecord] = useState<any>([]);
    const [tao_score_modal, setTaoScoreModal] = useState(false);
    const [post_score_modal, setPostScoreModal] = useState(false);
    const [front_holes, setFrontHoles] = useState<boolean>(true);
    const [back_holes, setBackHoles] = useState<boolean>(true);
    const [edit_hole_details, setEditHoleDetails] = useState<any>([]);
    const [hole_points, setHolePoints] = useState<any>();
    const [course_display_value, setCourseDisplayValue] = useState<any>();
    const [score_id, setScoreId] = useState<any>();
    const [showToast, hideToast] = useIonToast();
    const [nsc_score_id, setNscScoreId] = useState<any>();
    const getTaoCheckins = async () => {
        var user = localStorage.getItem('firebase_user');
        if (user != null) {
            var current_user = JSON.parse(user);
            const q = query(collection(db, "TAO_COMPETITIONS"), where('created_at', '>', 0), where('user_id', '==', current_user.user_id), where('season_name', '==',season_name ), where('scheduled', '==', true), orderBy('created_at', 'desc'));
            const querySnapshot = await getDocs(q);
            await Promise.all(querySnapshot.docs.map(async competition => {
                var doc_item = competition.data();
                doc_item.id = competition.id;
                let courseStr = (doc_item.course_name).split("-");
                if (courseStr[0] != undefined || courseStr[0] != null) doc_item.course_name = courseStr[0];
                if (courseStr[1] != undefined || courseStr[1] != null) doc_item.course_sub_name = courseStr[1];
                if (doc_item.tee) {
                    let teeset = doc_item.tee.split("~");
                    doc_item.tee = teeset[0];
                }
                doc_item.price = parseInt(doc_item.price);
                if (!doc_item.handicap_index) doc_item.handicap_index = "Not Available";
                if (!doc_item.quota) doc_item.quota = "Not Available";
                // let differenceTime = getTeeTimeDiffHours(doc_item.game_date_time);
                // if(differenceTime < 3 || differenceTime > 8 || doc_item.competition_date != format(new Date(), "YYYY-MM-DD")){
                //     doc_item.is_time_low = true;
                // } else {
                //     doc_item.is_time_low = false;
                // }
                doc_item.partner_id = doc_item.individual_players[0].user_id;
                doc_item.partner_name = doc_item.individual_players[0].user_name;
                if (doc_item.partner_accepted) {
                    const sq = query(collection(db, "TAO_COMPETITIONS"), where('user_id', '==', doc_item.partner_id), where('competition_relation_id', '==', doc_item.competition_relation_id));
                    const partnerSnapshot = await getDocs(sq);
                    if (partnerSnapshot.docs && partnerSnapshot.docs.length > 0) {
                        let partner_doc = partnerSnapshot.docs[0].data();
                        partner_doc.id = partnerSnapshot.docs[0].id;
                        let teeset = partner_doc.tee.split("~");
                        doc_item.partner_competition = {
                            points: partner_doc.points == undefined ? {} : partner_doc.points,
                            competition_id: partner_doc.id,
                            quota: partner_doc.quota,
                            course_id: partner_doc.course_id,
                            result: partner_doc.result,
                            user_id: partner_doc.user_id,
                            net_score: partner_doc.net_score,
                            tee: teeset[0],
                            teeset_id: partner_doc.teeset_id,
                            is_score_added: partner_doc.is_score_added,
                            score_id: partner_doc.score_id
                        };
                    }
                } else {
                    doc_item.partner_competition = {
                        points: {},
                        competition_id: '',
                        quota: 0,
                        course_id: doc_item.course_id,
                        tee: '',
                        teeset_id: '',
                        is_score_added: false,
                        score_id: ''
                    }
                }
                if (doc_item.partner_competition.result == "Won") {
                    doc_item.margin = doc_item.partner_competition.net_score - doc_item.net_score;
                } else if (doc_item.result == "Won") {
                    doc_item.margin = doc_item.net_score - doc_item.partner_competition.net_score;
                }
                doc_item.is_completed = false;
                if (doc_item.result == "Won") {
                    doc_item.winner = doc_item.user_name;
                    doc_item.is_completed = true;
                } else if (doc_item.result == "Lost") {
                    doc_item.winner = doc_item.partner_name;
                    doc_item.is_completed = true;
                } else if (doc_item.result == "Even") {
                    doc_item.winner = "Tied";
                    doc_item.is_completed = true;
                }
                doc_item.date = format(new Date(doc_item.competition_date_timestamp), "MM/dd/yyyy");
                return doc_item;
            }))
                .then(results => {
                    setTaoCheckins(_.sortBy(results, (e) => e.created_at).reverse());
                });
        }
    }

    const getScoreCardData = async (score_id: string, player_id: string,competition:any) => {
        const docSnapshot1 = await getEntry("SCORE_RECORDS", score_id);
        if (docSnapshot1.points) {
            var points = {
                "triple_bogey": 0,
                "double_bogey": 0,
                "bogey": 0,
                "par_value": 0,
                "birdie": 0,
                "eagle": 0,
                "double_eagle": 0,
            };
            var total_points = 0;
            await Promise.all(docSnapshot1.hole_details.map((hole: any) => {
                if ((hole.raw_score - hole.par) > 2) {
                    points.triple_bogey++;
                    total_points += 0;
                    hole.hole_point = hole.raw_score - hole.par;
                }
                if ((hole.raw_score - hole.par) == 2) {
                    points.double_bogey++;
                    total_points += 0;
                    hole.hole_point = hole.raw_score - hole.par;
                }
                if ((hole.raw_score - hole.par) == 1) {
                    points.bogey++;
                    total_points += 1;
                    hole.hole_point = hole.raw_score - hole.par;
                }
                if ((hole.raw_score - hole.par) == 0) {
                    points.par_value++;
                    total_points += 2;
                    hole.hole_point = hole.raw_score - hole.par;
                }
                if ((hole.raw_score - hole.par) == -1) {
                    points.birdie++;
                    total_points += 3;
                    hole.hole_point = hole.raw_score - hole.par;
                }
                if ((hole.raw_score - hole.par) == -2) {
                    points.eagle++;
                    total_points += 5;
                    hole.hole_point = hole.raw_score - hole.par;
                }
                if ((hole.raw_score - hole.par) == -3) {
                    points.double_eagle++;
                    total_points += 5;
                    hole.hole_point = hole.raw_score - hole.par;
                }
            }))
                .then(results => {
                    let courseStr = docSnapshot1.course_name.split("-");
                    if (courseStr[0] != undefined || courseStr[0] != null)
                        docSnapshot1.course_name = courseStr[0];
                    var score_record = {
                        hole_details: _.sortBy(docSnapshot1.hole_details, e => e.hole_number),
                        points: points,
                        total_points: total_points,
                        user_name: docSnapshot1.user_name,
                        course_name: docSnapshot1.course_name,
                        tee: docSnapshot1.tee,
                        holes: docSnapshot1.holes,
                        date: format(new Date(docSnapshot1.competition_date_timestamp), "MM/dd/yyyy"),
                        game_date_time: docSnapshot1.game_date_time,
                        competition_relation_id:docSnapshot1.competition_relation_id,
                        current_user:current_user,
                        competition:competition
                    };
                    setScoreRecord(score_record);
                    setTaoScoreModal(true);
                });
        } else {
            console.log("Score card not available");
        }
    }

    const attestScore = async (id1: string, id2: string) => {
        const docSnapshot1 = await getEntry("TAO_COMPETITIONS", id1);
        const docSnapshot2 = await getEntry("TAO_COMPETITIONS", id2);
        const user1Query = query(collection(db, "USER_PROFILE"), where("user_id", "==", docSnapshot1.user_id));
        const querySnapshot1 = await getDocs(user1Query);
        if (querySnapshot1.docs && querySnapshot1.docs.length > 0) {
            docSnapshot1.user = querySnapshot1.docs[0].data();
            docSnapshot1.user.id = querySnapshot1.docs[0].id;
        }
        const user2Query = query(collection(db, "USER_PROFILE"), where("user_id", "==", docSnapshot2.user_id));
        const querySnapshot2 = await getDocs(user2Query);
        if (querySnapshot2.docs && querySnapshot2.docs.length > 0) {
            docSnapshot2.user = querySnapshot2.docs[0].data();
            docSnapshot2.user.id = querySnapshot2.docs[0].id;
        }
        if (docSnapshot1.is_score_added && !docSnapshot2.is_score_added) {
            if (docSnapshot2.net_score != undefined) {
                await updateEntry("TAO_COMPETITIONS", id2, { is_score_added: true });
                if (docSnapshot1.net_score > docSnapshot2.net_score) {
                    docSnapshot1.result = "Won";
                    docSnapshot1.round_points = 4;
                    if ((docSnapshot1.net_score - docSnapshot2.net_score) < 3) {
                        docSnapshot1.round_points = docSnapshot1.round_points;
                    } else if ((docSnapshot1.net_score - docSnapshot2.net_score) >= 3 && (docSnapshot1.net_score - docSnapshot2.net_score) <= 5) {
                        docSnapshot1.round_points = docSnapshot1.round_points + 1;
                    } else if ((docSnapshot1.net_score - docSnapshot2.net_score) >= 6 && (docSnapshot1.net_score - docSnapshot2.net_score) <= 9) {
                        docSnapshot1.round_points = docSnapshot1.round_points + 2;
                    } else if ((docSnapshot1.net_score - docSnapshot2.net_score) > 9) {
                        docSnapshot1.round_points = docSnapshot1.round_points + 3;
                    }
                    docSnapshot2.result = "Lost";
                    docSnapshot2.round_points = 0;
                    await updateEntry("USER_PROFILE", docSnapshot1.user.id, { earnings: parseInt(docSnapshot1.user.earnings) + parseInt(docSnapshot1.price) + parseInt(docSnapshot2.price) });
                } else if (docSnapshot2.net_score > docSnapshot1.net_score) {
                    docSnapshot1.result = "Lost";
                    docSnapshot1.round_points = 0;
                    docSnapshot2.result = "Won";
                    docSnapshot2.round_points = 4;
                    if ((docSnapshot2.net_score - docSnapshot1.net_score) < 3) {
                        docSnapshot2.round_points = docSnapshot2.round_points;
                    } else if ((docSnapshot2.net_score - docSnapshot1.net_score) >= 3 && (docSnapshot2.net_score - docSnapshot1.net_score) <= 5) {
                        docSnapshot2.round_points = docSnapshot2.round_points + 1;
                    } else if ((docSnapshot2.net_score - docSnapshot1.net_score) >= 6 && (docSnapshot2.net_score - docSnapshot1.net_score) <= 9) {
                        docSnapshot2.round_points = docSnapshot2.round_points + 2;
                    } else if ((docSnapshot2.net_score - docSnapshot1.net_score) > 9) {
                        docSnapshot2.round_points = docSnapshot2.round_points + 3;
                    }

                    await updateEntry("USER_PROFILE", docSnapshot2.user.id, { earnings: parseInt(docSnapshot2.user.earnings) + parseInt(docSnapshot1.price) + parseInt(docSnapshot2.price) });
                } else if (docSnapshot1.net_score == docSnapshot2.net_score) {
                    docSnapshot1.result = "Even";
                    docSnapshot1.round_points = 2;
                    docSnapshot2.result = "Even";
                    docSnapshot2.round_points = 2;

                    await updateEntry("USER_PROFILE", docSnapshot2.user.id, { earnings: parseInt(docSnapshot2.user.earnings) + parseInt(docSnapshot2.price) });
                    await updateEntry("USER_PROFILE", docSnapshot1.user.id, { earnings: parseInt(docSnapshot1.user.earnings) + parseInt(docSnapshot1.price) });
                }
                await updateEntry("TAO_COMPETITIONS", id1, { "is_score_added": true, result: docSnapshot1.result, round_points: docSnapshot1.round_points, net_score: docSnapshot1.net_score });
                await updateEntry("TAO_COMPETITIONS", id2, { result: docSnapshot2.result, round_points: docSnapshot2.round_points, net_score: docSnapshot2.net_score });
                showToast('Thank you for your attestation.', 2000);
            } else {
                showToast(docSnapshot2.user_name + " didn’t post score yet.", 2000);
            }

        } else if (!docSnapshot1.is_score_added && docSnapshot2.is_score_added) {
            if (docSnapshot2.net_score != undefined) {
                await updateEntry("TAO_COMPETITIONS", id2, { is_score_added: true });
            } else {
                showToast(docSnapshot2.user_name + " didn’t post score yet.", 2000);
            }
        } else if (!docSnapshot1.is_score_added && !docSnapshot2.is_score_added) {
            if (current_user.user_id === docSnapshot1.user_id) {
                if (!docSnapshot2.is_score_added && docSnapshot2.net_score == undefined) {
                    showToast(docSnapshot2.user_name + " didn’t post score yet.", 2000);
                } else if (!docSnapshot2.is_score_added && docSnapshot2.net_score != undefined) {
                    await updateEntry("TAO_COMPETITIONS", id2, { is_score_added: true });
                    showToast('Thank you for your attestation.', 2000);
                }
            }
        }
        getTaoCheckins();
    }

    const postScore = async (id: string) => {
        const docSnapshot = await getEntry("TAO_COMPETITIONS", id);
        const scoreDocSnapshot = await getEntry("SCORE_RECORDS", docSnapshot.score_id);
        if (docSnapshot.course_name) {
            let doc_item = docSnapshot;
            doc_item.id = id;
            doc_item.hole_details = scoreDocSnapshot.hole_details;
            let courseStr = doc_item.course_name.split("-");
            if (courseStr[0] != undefined || courseStr[0] != null)
                setCourseDisplayValue(courseStr[0])
            if (courseStr[1] != undefined || courseStr[1] != null)
                setCourseDisplayValue(courseStr[1]);
            setScoreRecord(doc_item);
            setEditHoleDetails(scoreDocSnapshot.hole_details);
            setHolePoints(doc_item.points);
            setScoreId(doc_item.score_id);
            setNscScoreId(id);
            setPostScoreModal(true);
        }
    }

    const handleCallback = async (data: any) => {
        setPostScoreModal(false);
    }

    useEffect(() => {
        let user = localStorage.getItem('firebase_user');
        if (user != null) {
            setCurrentUser(JSON.parse(user));
            getTaoCheckins();
        }
    }, []);

    return (
        <IonPage>
            <IonToolbar mode="ios" className={isPlatform('ios') ? 'ios-toolbar-top box-shadow' : 'toolbar-top box-shadow'}>
                {/* <IonButtons slot="start">
                    <IonBackButton defaultHref="/" />
                </IonButtons> */}
                <IonLabel slot="start" className="s-18 w-800 color-blue" style={{ padding: '0px 0px 0px 10px' }}>
                    My Matches<span className='s-12'> - Net Score Challenge</span>
                </IonLabel>
                {/* <IonButtons slot="end">
                    <IonLabel>
                        <IonText className="color wg-500 s-14">Schedule</IonText>
                    </IonLabel>
                </IonButtons> */}
            </IonToolbar>
            <IonContent>
                <IonGrid className="grid-1-bg h-100">
                    {
                        tao_checkins.map((checkin: any, index: number) => {
                            return (
                                <IonCard className="social-card" key={index}>
                                    <IonCardHeader style={{ padding: '0px 0px 0px 10px' }}>
                                        <IonRow style={{ borderBottom: "1px solid #d3d3d361" }}>
                                            <IonCol size="9" className="nsc-scores-list">
                                                <IonCardContent style={{ padding: '3px 0px 3px 0px' }}>
                                                    <IonText className="black s-17 wg-600">{checkin.course_name}</IonText>
                                                    <br />
                                                    <IonText className="black s-12">{checkin.date} {checkin.time ? checkin.time : ''}</IonText>
                                                    <IonText className="pl-5 black s-12">{checkin.price} chips</IonText>
                                                    <br />
                                                    <IonText className="black s-12">{checkin.user_name}</IonText>
                                                    <IonText className="pl-5 black s-12"><i>{checkin.tee}</i></IonText>
                                                    <IonText className="pl-5 wg-500 s-12">Target:</IonText>
                                                    <IonText className="pl-5 s-12 black">{checkin.quota}</IonText>
                                                    {
                                                        checkin.is_score_added && checkin.net_score != undefined ? (
                                                            <>
                                                                <IonText className="pl-5 color wg-500 underline color-red s-12" onClick={() => getScoreCardData(checkin.score_id, checkin.user_id,checkin)}>Net score:</IonText>
                                                                <IonText className="pl-5 s-12 black">{checkin.net_score}</IonText>
                                                            </>
                                                        ) : !checkin.is_score_added && checkin.net_score != undefined ? (
                                                            <>
                                                                <IonText className="pl-5 color wg-500 underline color-red s-12" onClick={() => getScoreCardData(checkin.score_id, checkin.user_id,checkin)}>Net score:</IonText>
                                                                <IonText className="pl-5 s-12 black">{checkin.net_score}</IonText>
                                                                <br />
                                                                <IonText className="pl-5 s-12 color-red">Attestation pending</IonText>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <IonText className="pl-5 color wg-500 attest-button s-12" onClick={() => postScore(checkin.id)}>Post score</IonText>
                                                            </>
                                                        )
                                                    }

                                                </IonCardContent>
                                            </IonCol>
                                            <IonCol size="3" className="nsc-result">
                                                {
                                                    checkin.is_score_added && checkin.partner_competition.is_score_added ? (
                                                        <IonCardContent style={{ padding: '3px 0px 1.5px 0px', textAlign: "center" }}>
                                                            {
                                                                checkin.result && checkin.result !== "Even" && checkin.result !== "Tied" ?
                                                                    <div>
                                                                        <IonText className="black s-12" style={{ fontWeight: "900" }}>{checkin.result}</IonText><br />
                                                                        <IonText className="black s-12">Margin: {checkin.margin}</IonText>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <IonText className="black s-12" style={{ fontWeight: "900" }}>{checkin.winner}</IonText><br />
                                                                    </div>
                                                            }
                                                            {/* <IonText className="black s-13 wg-600">Won by:</IonText><br/> */}

                                                        </IonCardContent>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </IonCol>
                                        </IonRow>
                                    </IonCardHeader>
                                    <IonCardContent style={{ padding: '3px 0px 6px 0px' }}>
                                        <IonRow className="">
                                            {
                                                checkin.partner_accepted === false ? (
                                                    <IonCol>
                                                        <IonText className="pl-5 black s-12">vs</IonText>
                                                        <IonText className="pl-5 black s-12">{checkin.partner_name} (<span className='color-red'>Acceptance pending</span>)</IonText>
                                                    </IonCol>
                                                ) : (
                                                    <IonCol>
                                                        <IonText className="pl-5 black s-12">vs</IonText>
                                                        <IonText className="pl-5 black s-12">{checkin.partner_name} - <i>{checkin.partner_competition.tee}</i></IonText>
                                                        <IonText className="pl-5 wg-500 s-12">Target:</IonText>
                                                        <IonText className="pl-5 s-12 black">{checkin.partner_competition.quota}</IonText>
                                                        {
                                                            checkin.partner_competition.net_score !== undefined ? (
                                                                <>
                                                                    {
                                                                        checkin.partner_competition.is_score_added ? (
                                                                            <>
                                                                                <IonText className="pl-5 color wg-500 underline color-red s-12" onClick={() => getScoreCardData(checkin.partner_competition.score_id, checkin.partner_competition.user_id,checkin)}>Net score:</IonText>
                                                                                <IonText className="pl-5 s-12 black">{checkin.partner_competition.net_score}</IonText>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <IonText className="pl-5 color wg-500 underline color-red s-12" >Net score:</IonText>
                                                                                <IonText className="pl-5 s-12 black">{checkin.partner_competition.net_score}</IonText>
                                                                                <IonText className="pl-5 s-12 attest-button" onClick={() => getScoreCardData(checkin.partner_competition.score_id, checkin.partner_competition.user_id,checkin)}>Attest</IonText>
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <IonText className="pl-5 s-12 attest-button" onClick={() => getScoreCardData(checkin.partner_competition.score_id, checkin.partner_competition.user_id,checkin)}>Attest</IonText>
                                                                </>
                                                            )
                                                        }

                                                    </IonCol>
                                                )
                                            }

                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            )
                        })
                    }
                </IonGrid>
                <IonModal isOpen={tao_score_modal} className='my-custom-class' onDidDismiss={() => { setTaoScoreModal(false); }}>
                    <>
                        <IonHeader>
                            <IonToolbar>

                                <IonLabel slot="start" className="s-1 w-600 color-blue">{score_record.course_name} - {score_record.tee}</IonLabel>

                            </IonToolbar>
                        </IonHeader>
                        <IonContent>

                            <Table options={score_record} holes={18} front9={front_holes} back9={back_holes} />
                            <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button">
                                <IonFabButton
                                    className="main-fab"
                                    onClick={() => {
                                        setTaoScoreModal(false)
                                    }}
                                >
                                    <IonIcon icon={arrowBackOutline} />
                                </IonFabButton>
                            </IonFab>
                        </IonContent>
                    </>
                </IonModal>
                <IonModal isOpen={post_score_modal} className='my-custom-class' onDidDismiss={() => { setPostScoreModal(false); }}>
                    <>
                        <IonHeader>
                            <IonToolbar>
                                <IonLabel slot="start" className="s-1 w-600 color-blue">{course_display_value} - {score_record.tee}</IonLabel>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <EditTable options={score_record} options1={edit_hole_details} options2={hole_points} score_id={score_id} nsc_score_id={nsc_score_id} parentCallback={handleCallback} />
                            <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button">
                                <IonFabButton
                                    className="main-fab"
                                    onClick={() => {
                                        setPostScoreModal(false);
                                    }}
                                >
                                    <IonIcon icon={arrowBackOutline} />
                                </IonFabButton>
                            </IonFab>
                        </IonContent>
                    </>
                </IonModal>
                <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{ marginBottom: '45px' }}>
                    <IonFabButton
                        className="main-fab"
                        onClick={() => {
                            window.location.href = "/leagues/formats"
                        }}
                    >
                        <IonIcon icon={arrowBackOutline} />
                    </IonFabButton>
                </IonFab>
            </IonContent>
        </IonPage>
    );
};
export default NetScoreChallengeCheckins;