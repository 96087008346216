import '../../css/noticeboard.css';
import { IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonLoading, IonModal, IonRow, IonText, IonTitle, IonToolbar, isPlatform } from '@ionic/react';
import Table from '../Components/table'
import { useEffect,useState } from 'react';
import _ from 'lodash';
import { format } from 'date-fns';
import firebase from '../../js/firebase.js';
import default_profile_logo from "../../img/fewbats.svg";

import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { chevronDownOutline, chevronUpOutline, chevronForwardOutline, chevronBackOutline, arrowBackOutline } from 'ionicons/icons';

const db = getFirestore(firebase);

const Quota: React.FC = () => {
    const [current_user, setCurrentUser] = useState<any>([]);
    const [front_holes, setFrontHoles] = useState<boolean>(true);
    const [back_holes, setBackHoles] = useState<boolean>(true);
    const [score_records, setScoreRecords] = useState<any>([]);
    const [records_query, setRecordsQuery] = useState<any>('Last 10 Rounds');
    const getScores = async(type:string) => {
        setShowLoading(true)
        let user = localStorage.getItem('firebase_user');
        let current_user1;
        if(user != null) {
            current_user1 = JSON.parse(user)
        }
        const roundsClassificationQuery = query(collection(db, "CONFIG"), where('type', '==', "ROUNDS_CLASSIFICATION"));
        const roundsClassificationSnapshot = await getDocs(roundsClassificationQuery);
        const scoresQuery = query(collection(db, "SCORE_RECORDS"), where('created_at', '>', 0), where('user_id', '==', current_user1.user_id));
        const scoresSnapshot = await getDocs(scoresQuery);
        await Promise.all(scoresSnapshot.docs.map(async competition => {
          var doc_item = competition.data();
          doc_item.id = competition.id;
          let courseStr = (doc_item.course_name).split("-");
          if(courseStr[0] != undefined || courseStr[0] != null ) doc_item.course_name = courseStr[0];
          if(courseStr[1] != undefined || courseStr[1] != null ) doc_item.course_sub_name = courseStr[1];
          if(doc_item.tee) {
            let teeset = doc_item.tee.split("~");
            doc_item.tee = doc_item.holes == 18 ? teeset[0] : teeset[0]+'~'+teeset[1]
          }
          if(doc_item.points) {
            doc_item.points.birdie = doc_item.points.birdie+doc_item.points.eagle+doc_item.points.double_eagle;
            doc_item.total_points = doc_item.points.total_points;
          } else {
            doc_item.points = {
              "triple_bogey": 0,
              "double_bogey": 0,
              "bogey": 0,
              "par_value": 0,
              "birdie": 0,
              "eagle": 0,
              "double_eagle": 0,
              "total_points": 0 
            };
            doc_item.total_points = 0;
          }
          return doc_item;
        }))
        .then(results => {
          if(results.length > 0) {
            let roundsClassification = (roundsClassificationSnapshot.docs && roundsClassificationSnapshot.docs.length > 0) ? roundsClassificationSnapshot.docs[0].data() : new Array();
            var classificationSetup = results.length > 10 ? roundsClassification.rounds.filter((round:any)=> round.count == 10) : roundsClassification.rounds.filter((round:any)=> round.count == results.length);
            var sortedRecords = _.sortBy(results, e => e.total_points).reverse();
            sortedRecords.forEach(function(record, idx) {
              record.toggle = false;
              if(idx < classificationSetup[0].best) {
                record.best = true;
                record.other = false;
              } else if(idx > classificationSetup[0].best && idx <= classificationSetup[0].other) {
                record.best = false;
                record.other = false;
              } else {
                record.other = true;
              }
            });
            sortedRecords = _.sortBy(sortedRecords, e => e.created_at).reverse();
            console.log(sortedRecords)
            if(type == "all") {
              setScoreRecords(sortedRecords);
              
            } else {
              let filteredRecords = _.takeRight(sortedRecords.filter(x=> !x.other), 10);
              setScoreRecords(filteredRecords);
              
            }
            setShowLoading(false)
          } else {
            setShowLoading(false)
          }
        });
      }
      const toggleCard = (header:any)=>{
        score_records && score_records.forEach((key:any,i:number)=>{
          if(key.id == header){
            key.toggle = !key.toggle
          }
        })
        // console.log(header,cards)
        setScoreRecords(Array.from(new Set([...score_records])))
      }
    const [show_more,setShowMore] = useState<any>(false)
    const [showLoading, setShowLoading] = useState(false);
    const [score_modal,setScoreModal] = useState<any>(false)
    const [score_record, setScoreRecord] = useState<any>([]);
    useEffect(() => {
    let user = localStorage.getItem('firebase_user');
    if(user != null) {
        setCurrentUser(JSON.parse(user));
        getScores("filtered")
    }
    }, [])
    
    return(
        <>
        <IonHeader mode="ios">
          <IonToolbar>
            <IonButtons slot="start" style={{ padding: "5px 5px 0px 6px" }}>
              {current_user.logo_url ? (
                <>
                <a href="/profile">
                <img src={current_user.logo_url} className="pro_pic" />
                </a>
                
                </>
              ) : (
                <>
                <a href="/profile">
                  <img src={default_profile_logo} className="pro_pic" />
                </a>
                
                </>
              )}
            </IonButtons>
            <IonText className="s-16">{current_user.first_name+' '+current_user.last_name}</IonText>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid>
            <IonRow className="ion-justify-content-center ion-text-center">
              <IonCol size="4" className="pad-0">
                <IonCard className="card">
                  <div className="mt-15">
                    <IonText className="s-18 black wg-600">{current_user.quota}</IonText> <br/>
                    <IonText className="s-13 black">Quota</IonText>
                  </div>
                </IonCard>
              </IonCol>
              <IonCol size="3" className="pad-0">
                <IonText className="s-11 black">
                <br/>
                <br/>                
                  Bogey: 1pt
                </IonText>
              </IonCol>
              <IonCol size="3" className="pad-0">
                <IonText className="s-11 black">
                  <i>Points scale</i>
                </IonText><br/>
                <IonText className="s-11 black">
                  Birdie+: 4 pts
                </IonText><br/>
                <IonText className="s-11 black">
                  Double: 0 pt
                </IonText><br/>
                <IonText className="s-11 black">
                  Triple: -1 pt
                </IonText><br/>
              </IonCol>
              <IonCol size="2" className="pad-0">
                <IonText className="s-11 black">
                <br/>
                <br/>
                  Par: 2 pts
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center ion-text-start mt-25">
              <IonText className="s-14">
                Quota is an indicative target based on past points performance.<br/><br/>
                {/* It is calculated from weighted average of recent scores - <IonText className="color-blue s-14 wg-600">70% to best scores </IonText>
                and<IonText className="color-red s-14 pl-5 wg-600">30% to other scores</IonText> */}
              </IonText>                                                                
            </IonRow>
          </IonGrid>
          <IonGrid className="grid-2">
              <IonCard className="last-rounds bg ion-justify-content-center ion-text-center pt-3">
                <IonText className="s-16 white">{records_query}</IonText>
              </IonCard>
          </IonGrid>
          <IonContent>
            
              {
                score_records && score_records.map((key:any,i:number)=>{
                  return(
                    <IonCard className="social-card score-summary-card" key={i}>
                      <IonRow className="pad-ltb">
                        <IonCol size="8" className="pad-0">
                          {
                            (key.other)?(
                              <IonText className="s-15 wg-600">
                                {key.course_name}
                              </IonText>
                            ):(!key.other && key.best)?(
                              <IonText className="color-red s-15 wg-600">
                                {key.course_name}
                              </IonText>
                            ):(!key.other && !key.best)?(
                              <IonText className="color-blue s-15 wg-600">
                                {key.course_name}
                              </IonText>
                            ):(
                              <></>
                            )
                          }  
                          <br/>   
                          <IonText className="s-12 wg-500">{format(new Date(key.competition_date), 'MM/dd/yyyy')} &nbsp;{key.tee}&nbsp;<span className="s-10">{key.course_rating}/{key.slope_rating}/{key.par}</span></IonText>
                        </IonCol>
                        <IonCol size="3" className="pad-0 ion-text-center">
                          {
                            (key.other)?(
                              <IonText className="s-15 wg-600">
                                {key.total_points}
                              </IonText>
                            ):(!key.other && key.best)?(
                              <IonText className="color-red s-15 wg-600">
                                {key.total_points}
                              </IonText>
                            ):(!key.other && !key.best)?(
                              <IonText className="color-blue s-15 wg-600">
                                {key.total_points}
                              </IonText>
                            ):(
                              <></>
                            )
                          }
                          <IonText className="s-12 wg-500">/{key.quota}</IonText><br/>
                          <IonText className="s-12 wg-500">Score/Quota</IonText>
                        </IonCol>
                        {
                          (!key.toggle)?(
                            <IonCol size="1" className="pad-0" onClick={()=>{
                              toggleCard(key.id)
                            }}>
                            <IonIcon icon={chevronDownOutline} style={{fontSize:"18px",marginTop:"5px"}}/>
                          </IonCol>  
                          ):(
                            <IonCol size="1" className="pad-0" onClick={()=>{
                              toggleCard(key.id)
                            }}>
                            <IonIcon icon={chevronUpOutline} style={{fontSize:"18px",marginTop:"5px"}}/>
                          </IonCol>
                          )
                        }
                      </IonRow>
                    {
                      (key.toggle)?(
                        <IonCardContent style={{paddingTop:"0px"}}>
                        <IonRow className="ion-justify-content-center ion-text-center" style={{width:"90%",margin:"auto",borderBottom:"0.5px  solid black"}}>
                          <IonCol size="2">
                            <IonText className="s-11">Eagle</IonText><br/>
                            <IonText className="black s-14">{key.points.eagle}</IonText>
                          </IonCol>
                          <IonCol size="2">
                            <IonText className="s-11">Birdie</IonText><br/>
                            <IonText className="black s-14">{key.points.birdie}</IonText>
                          </IonCol>
                          <IonCol size="2">
                            <IonText className="s-11">Par</IonText><br/>
                            <IonText className="black s-14">{key.points.par_value}</IonText>
                          </IonCol>
                          <IonCol size="2">
                            <IonText className="s-11">Bogey</IonText><br/>
                            <IonText className="black s-14">{key.points.bogey}</IonText>
                          </IonCol>
                          <IonCol size="2">
                            <IonText className="s-11">Double</IonText><br/>
                            <IonText className="black s-14">{key.points.double_bogey}</IonText>
                          </IonCol>
                          <IonCol size="2">
                            <IonText className="s-11">Triple</IonText><br/>
                            <IonText className="black s-14">{key.points.triple_bogey}</IonText>
                          </IonCol>
                        </IonRow>
                        <IonRow className="fr mt-5 mb-5">
                          <IonText className="s-13 color-blue" onClick={()=>{
                              var score_record = {
                                  hole_details: _.sortBy(key.hole_details, e => e.hole_number),
                                  points: key.points,
                                  total_points: key.total_points,
                                  user_name: key.user_name,
                                  course_name: key.course_name,
                                  tee: key.tee,
                                  holes: key.holes,
                                  date: format(new Date(key.competition_date_timestamp), "MM/dd/yyyy"),
                              };
                              // console.log("score record",score_record)
                              setScoreRecord(score_record)
                              setScoreModal(true)
                              
                          }}>View Scorecard</IonText><IonIcon size="19" icon={chevronForwardOutline} className="color-blue"/>
                        </IonRow>
                      </IonCardContent>
                      ):(
                        <>
                        </>
                      )
                    }
                    </IonCard>
                  )
                })
              }
              {
                  (show_more)?(
                    <IonRow className="ion-justify-content-center ion-text-center">
                      <IonText className="s-14 gray" onClick={()=>{
                          getScores("last");
                          setRecordsQuery("Last 10 Rounds");
                          setShowMore(false);
                      }}>
                          Show less
                      </IonText>
                    </IonRow>
      
                  ):(
                    <IonRow className="ion-justify-content-center ion-text-center" style={{marginBottom: '55%'}}>
                      <IonText className="s-14 gray" onClick={()=>{
                          getScores("all");
                          setRecordsQuery("All Rounds");
                          setShowMore(true);
                      }}>
                          Show more
                      </IonText>
                    </IonRow>
      
                  )
              }
          </IonContent>
          <IonModal isOpen={score_modal} className='my-custom-class'>
                    <>
                        <IonHeader>
                            <IonToolbar>
                                <IonButtons slot="start">
                                    <a onClick={()=> setScoreModal(false)}>
                                        <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                                    </a>
                                </IonButtons>
                                <IonTitle>
                                    Points
                                </IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <Table options={score_record} holes={18} front9={front_holes} back9={back_holes}/>
                        </IonContent>
                    </>
          </IonModal>
          <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '55px'}}>
            <IonFabButton
              className="main-fab"
              onClick={() => {
                window.location.href = "/leagues/formats";
              }}
            >
              <IonIcon icon={arrowBackOutline} />
            </IonFabButton>
          </IonFab>
          <IonLoading
            isOpen={showLoading}
            message={'Please wait..'}
          />
        </IonContent>

        </>
    )
}
export default Quota;