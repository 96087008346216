import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonIcon,IonLabel, IonGrid, IonSegment, IonSegmentButton, IonFab, IonFabButton, IonCol, IonItem, IonRow, IonText, IonInput, IonDatetime, IonModal, IonSearchbar, IonList, useIonToast, IonLoading } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import EditTable from '../Components/edit_table'
import * as utils from '../../js/utils';
import { createEntryWithoutId } from '../../js/firebase_doc_functions';
import firebase from '../../js/firebase.js';
import { format } from 'date-fns';

import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import _ from 'lodash';

const db = getFirestore(firebase);

const CreateScore: React.FC = () => {
    const [current_user, setCurrentUser] = useState<any>([]);
    const [select_state,setSelectState] = useState<any>([])    
    const [course, setCourse] = useState<any>();
    const [course_id, setCourseId] = useState<any>();
    const [showCourseLoading, setShowCourseLoading] = useState(false);
    const [showTeesLoading, setShowTeesLoading] = useState(false);
    const [showStartLoading,setShowStartLoading]=useState(false);
    const [course_list, setCourseList] = useState<any>([]);
    const [front_holes, setFrontHoles] = useState<boolean>(false);
    const [back_holes, setBackHoles] = useState<boolean>(false);
    const [tees_list, setTeesList] = useState<any>([]);
    const [all_users, setAllUsers] = useState<any>();
    const [holes, setHoles] = useState<any>("18");
    const [select_tees,setSelectTees] = useState("");
    const [available_tees, setAvailableTees] = useState<any>();
    const [selected_date, setSelectedDate] = useState<string>(format(new Date(), "MM/dd/yyyy"));
    const [showDatePicker, setShowDatePicker] = useState(false);    
    const [selected_time, setSelectedTime] = useState<string>('');
    const [showTimePicker, setShowTimePicker] = useState(false);
    const [selected_player, setSelectedPlayer] = useState<string>('');
    const [selected_player_id, setSelectedPlayerId] = useState<string>('');
    const [teeset_rating_id, setTeesetRatingId] = useState<number>();
    const [teeset_rating_name, setTeesetRatingName] = useState<string>('');
    const [teeset_rating_gender, setTeesetRatingGender] = useState<string>('');
    const [showToast, hideToast] = useIonToast();
    const [slope_rating, setSlopeRating] = useState<number>();
    const [course_rating, setCourseRating] = useState<number>();
    const [tee_type, setTeetype] = useState<any>('');
    const [par, setPar] = useState<number>();
    const [yards, setYards] = useState<number>();
    const [gender, setGender] = useState<string>('');
    const [ppw_score_modal, setPpwScoreModal] = useState(false);
    const [playing_quota, setPlayingQuota] = useState<number>(0);
    const [score_record, setScoreRecord] = useState<any>([]);
    const [edit_hole_details, setEditHoleDetails] = useState<any>([]);
    const [target_tees, setTargetTees] = useState<any>();
    const [hole_points, setHolePoints] = useState<any>();
    const [searchText,setSearchText] = useState<any>("");
    const inputRef = useRef<any>(null);
    const customAlertOptions = {
      header: 'Select State',
      translucent: true
    };
    const customAlertOptions1 = {
        header: 'Select Course',
    };
    const customAlertOptions2 = {
        header: 'Select Tees(Course/Slope/Par)',
    };

    const customAlertOptions3 = {
        header: 'Playing with',
    };

    const getCourses = async(state:string)=> {
        setShowCourseLoading(true);
        let course_list = await utils.getCourseList(state);
        setCourseList(course_list);
        setNewCourses(course_list);
        setShowCourseLoading(false);
        setShowCourseModal(true);
    }

    const getUsers = async() => {
        const q = query(collection(db, "USER_PROFILE"), where('created_at', '>', 0), where('city', '==', current_user.city));
    
        const querySnapshot = await getDocs(q);
        var records = new Array();
        querySnapshot.forEach((doc) => {
            let doc_item = doc.data();
            doc_item.id = doc.id;
            records.push(doc_item);
        });
        records = records.filter((x:any)=> x.user_id != current_user.user_id);
        setAllUsers(records);
    }

    const getTees = async(course_id:number, holes:number)=> {
        if(course_id > 0) {
            setShowTeesLoading(true);
            let tees = await utils.getCourseDetails(course_id) || [];
            var tee_sets = new Array();
            let finalTees = current_user.gender === "Male" ? tees.filter((x:any)=> x.Gender === "Male") : tees.filter((x:any)=> x.Gender === "Female");
            let sortedTees =  _.sortBy(finalTees, (e: any) => e.TotalMeters).reverse();
            setAvailableTees(sortedTees);
            sortedTees && sortedTees.forEach((tee:any)=>{
                if(holes == 18){
                    let rating = tee.Ratings.filter((rating:any)=> rating.RatingType == 'Total');
                    let pars = tee.Holes.map((hole:any)=> {return hole.Par}),
                    par = pars.reduce((a:number, b:number) => a + b, 0);
                    if(tee.HolesNumber == 18){
                        tee_sets.push({'teeName': tee.TeeSetRatingName+'~('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')', 
                        'teeValue': tee.TeeSetRatingName+'~('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                    });
                    }
                } else if(holes == 9) {
                    let rating = tee.Ratings.filter((rating:any)=> rating.RatingType == 'Total');
                    let nineHoleRatings = tee.Ratings.filter((rating:any)=> rating.RatingType != 'Total');
                    let pars = tee.Holes.map((hole:any)=> {return hole.Par});
                    if(tee.HolesNumber == 9){
                    let pars = tee.Holes.map((hole:any)=> {return hole.Par}),
                    par = pars.reduce((a:number, b:number) => a + b, 0);
                    tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+rating[0].RatingType+'~('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')', 
                        'teeValue': tee.TeeSetRatingName+'~'+rating[0].RatingType+'~('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                    });
                    
                    } else {
                    for (rating of nineHoleRatings) {
                        var front9Holes = tee.Holes.filter((hole:any)=> hole.Number <= 9);
                        var back9Holes = tee.Holes.filter((hole:any)=> hole.Number > 9);
                        if(rating.RatingType == 'Front'){
                        let pars = front9Holes.map((hole:any)=> {return hole.Par}),
                        par = pars.reduce((a:number, b:number) => a + b, 0);
                        tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')', 
                            'teeValue': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                        });
                        
                        }
                        if(rating.RatingType == 'Back'){
                        let pars = back9Holes.map((hole:any)=> {return hole.Par}),
                        par = pars.reduce((a:number, b:number) => a + b, 0);
                        tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')', 
                            'teeValue': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                        });
                        
                        }
                    }
                    }
                } 
            });
            setTeesList(tee_sets);
            setNewTees(tee_sets);
            setShowTeesLoading(false);
            //setShowTeesModal(true);
        } else {
            showToast("Please select a state and course.", 2000);
        }
    }

    const getTeeDetails = async(courseid:number, holes:number, tee:string)=> {
        let currentTeeValues = tee.split("~");
        setTeesetRatingId(holes == 18 ? parseInt(currentTeeValues[2]) : parseInt(currentTeeValues[3]));
        setTeesetRatingName(currentTeeValues[0]);
        setTeesetRatingGender(currentTeeValues[1]);
        let teeSetValues = holes == 18 ? (currentTeeValues[1].substring(1, currentTeeValues[1].length-1)).split("/") : (currentTeeValues[2].substring(1, currentTeeValues[2].length-1)).split("/");
        setSlopeRating(parseInt(teeSetValues[1]));
        setCourseRating(parseFloat(teeSetValues[0]));
        setPar(parseInt(teeSetValues[2]));
        setGender(current_user.gender);
        let tees = await utils.getCourseDetails(courseid) || [];
        if(holes == 18) {
            if(available_tees.length >= 5) {
                available_tees.forEach((x:any, idx:number)=> {
                    if(current_user.preferred_tee === "Back") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Back'); 
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota+2);
                            setTargetTees('Middle Back');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota+4);
                            setTargetTees('Middle');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota+4);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 4 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota+4);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Middle Back") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-2); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Middle Back');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota+2);
                            setTargetTees('Back');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota+4);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 4 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota+4);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Middle") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-4); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-2);
                            setTargetTees('Middle Back');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Middle');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota+2);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 4 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota+4);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Middle Front") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-4); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-4);
                            setTargetTees('Middle Back');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-2);
                            setTargetTees('Back');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 4 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota+2);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Front") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-4); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-4);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-4);
                            setTargetTees('Middle');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-2);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 4 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Middle');
                        }
                    }
                })
            }
            if(available_tees.length === 4) {
                available_tees.forEach((x:any, idx:number)=> {
                    if(current_user.preferred_tee === "Back") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-2);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-4);
                            setTargetTees('Middle Back');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-4);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Middle Back") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-2); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Middle Back');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota+2);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota+4);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Middle") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-2); 
                            setTargetTees('Middle Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Middle');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota+2);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota+4);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Middle Front") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-4); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-2);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota+2);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Front") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-4); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-4);
                            setTargetTees('Middle Back');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-2);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Front');
                        }
                    }
                })
            }
            if(available_tees.length === 3) {
                available_tees.forEach((x:any, idx:number)=> {
                    if(current_user.preferred_tee === "Back") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Back'); 
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-2);
                            setTargetTees('Middle');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-4);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Middle Back") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-2); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Middle');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota+2);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Middle") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-2); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Middle');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota+2);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Middle Front") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-4); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-2);
                            setTargetTees('Middle');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Front") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-4); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota-2);
                            setTargetTees('Middle');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[2])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Front');
                        }
                    }
                })
            }
        } else {
            if(available_tees.length >= 5) {
                available_tees.forEach((x:any, idx:number)=> {
                    if(current_user.preferred_tee === "Back") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Back'); 
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota+2);
                            setTargetTees('Middle Back');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota+4);
                            setTargetTees('Middle');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota+4);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 4 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota+4);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Middle Back") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-2); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Middle Back');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota+2);
                            setTargetTees('Back');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota+4);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 4 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota+4);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Middle") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-4); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-2);
                            setTargetTees('Middle Back');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Middle');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota+2);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 4 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota+4);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Middle Front") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-4); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-4);
                            setTargetTees('Middle Back');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-2);
                            setTargetTees('Back');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 4 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota+2);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Front") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-4); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-4);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-4);
                            setTargetTees('Middle');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-2);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 4 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Middle');
                        }
                    }
                })
            }
            if(available_tees.length === 4) {
                available_tees.forEach((x:any, idx:number)=> {
                    if(current_user.preferred_tee === "Back") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-2);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-4);
                            setTargetTees('Middle Back');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-4);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Middle Back") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-2); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Middle Back');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota+2);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota+4);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Middle") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-2); 
                            setTargetTees('Middle Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Middle');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota+2);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota+4);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Middle Front") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-4); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-2);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota+2);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Front") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-4); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-4);
                            setTargetTees('Middle Back');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-2);
                            setTargetTees('Middle Front');
                        }
                        if(idx === 3 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Front');
                        }
                    }
                })
            }
            if(available_tees.length === 3) {
                available_tees.forEach((x:any, idx:number)=> {
                    if(current_user.preferred_tee === "Back") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Back'); 
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-2);
                            setTargetTees('Middle');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-4);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Middle Back") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-2); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Middle');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota+2);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Middle") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-2); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Middle');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota+2);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Middle Front") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-4); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-2);
                            setTargetTees('Middle');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Front');
                        }
                    }
                    if(current_user.preferred_tee === "Front") {
                        if(idx === 0 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-4); 
                            setTargetTees('Back');
                        }
                        if(idx === 1 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota-2);
                            setTargetTees('Middle');
                        }
                        if(idx === 2 && x.TeeSetRatingId === parseInt(currentTeeValues[3])) {
                            setPlayingQuota(current_user.quota);
                            setTargetTees('Front');
                        }
                    }
                })
            }
        }
        tees && tees.forEach((tee:any)=>{
            if(holes == 18){
                let yardsData = tee.Holes.map((hole:any)=> {return hole.Length}),
                yards = yardsData.reduce((a:number, b:number) => a + b, 0);
                setEditHoleDetails(tee.Holes);
                setYards(yards);
            } else if(holes == 9) {
                let rating = tee.Ratings.filter((rating:any)=> rating.RatingType == 'Total');
                let nineHoleRatings = tee.Ratings.filter((rating:any)=> rating.RatingType != 'Total');
                if(tee.HolesNumber == 9){
                    let yardsData = tee.Holes.map((hole:any)=> {return hole.Length}),
                    yards = yardsData.reduce((a:number, b:number) => a + b, 0);
                    setEditHoleDetails(tee.Holes);
                    setYards(yards);
                } else {
                  for (rating of nineHoleRatings) {
                    var front9Holes = tee.Holes.filter((hole:any)=> hole.Number <= 9);
                    var back9Holes = tee.Holes.filter((hole:any)=> hole.Number > 9);
                    if(rating.RatingType == 'Front'){
                        let yardsData = front9Holes.map((hole:any)=> {return hole.Length}),
                        yards = yardsData.reduce((a:number, b:number) => a + b, 0);
                        if(rating.RatingType == currentTeeValues[1] && tee.TeeSetRatingId == currentTeeValues[3]) {
                            setEditHoleDetails(front9Holes);
                            setTeetype("Front");
                        }
                        setYards(yards);
                    }
                    if(rating.RatingType == 'Back'){
                        let yardsData = back9Holes.map((hole:any)=> {return hole.Length}),
                        yards = yardsData.reduce((a:number, b:number) => a + b, 0);
                        if(rating.RatingType == currentTeeValues[1] && tee.TeeSetRatingId == currentTeeValues[3]) {
                            setEditHoleDetails(back9Holes);
                            setTeetype("Back");
                        }
                        setYards(yards);
                    }
                  }
                }
            } 
        });
    }

    const createScore = async()=> {
        if(course === "" || select_tees === "" || selected_time === "") {
            showToast('All fields are required', 2000);
        } else {
            let selected_course = course_list.filter((x:any)=> x.CourseID == course_id);
            let hole_details = edit_hole_details.map((hole:any)=>{
                hole.raw_score = 0;
                hole.points_type = null;
                hole.points = 0;
                hole.par = hole.Par;
                hole.allocation = hole.Allocation;
                hole.hole_id = hole.HoleId;
                hole.length = hole.Length;
                hole.hole_number = hole.Number;
                delete hole.Allocation;
                delete hole.HoleId;
                delete hole.Length;
                delete hole.Number;
                delete hole.Par;
                return hole;
            });
            
            var score_obj = {
                course_name: selected_course[0].FullName,
                competition_date: format(new Date(selected_date),"yyyy-MM-dd"),
                competition_date_timestamp: new Date(selected_date).getTime(),
                tee: teeset_rating_name,
                game_date_time: selected_time,
                user_name: current_user.first_name+' '+current_user.last_name,
                course_id: selected_course[0].CourseID,
                teeset_id: teeset_rating_id,
                user_id: current_user.user_id,
                quota: holes == 18 ? playing_quota : playing_quota/2,
                holes: Number(holes),
                tee_type: tee_type,
                yards: yards,
                par: par,
                course_rating: course_rating,
                slope_rating: slope_rating,
                gender: gender,
                hole_details: hole_details,
                score_submitted: false,
                points:{
                    "triple_bogey": 0,
                    "double_bogey": 0,
                    "bogey": 0,
                    "par_value": 0,
                    "birdie": 0,
                    "eagle": 0,
                    "double_eagle": 0,
                    "total_points": 0,
                    "front_par_total": 0,
                    "back_par_total": 0,
                    "front_score_total": 0,
                    "back_score_total": 0,
                    "front_points_total": 0,
                    "back_points_total": 0
                },
                id: ''
            }
            // console.log("score_obj",score_obj)
            score_obj.id = await createEntryWithoutId("SCORE_RECORDS", score_obj);
            // console.log("score_obj",score_obj)
            if(score_obj.holes == 9) {
                setFrontHoles(tee_type == "Front" ? true : false);
                setBackHoles(tee_type == "Back" ? true : false);
            }
            setHoles(score_obj.holes);
            setScoreRecord(score_obj);
            setEditHoleDetails(score_obj.hole_details);
            setHolePoints(score_obj.points);
            setShowStartLoading(false);
            setPpwScoreModal(true);
        }
    }
  
    const states = [{"state_code":"US-AL","state_name":"Alabama"},
      {"state_code":"US-AK","state_name":"Alaska"},
      {"state_code":"US-AZ","state_name":"Arizona"},
      {"state_code":"US-AR","state_name":"Arkansas"},
      {"state_code":"US-CA","state_name":"California"},
      {"state_code":"US-CO","state_name":"Colorado"},
      {"state_code":"US-CT","state_name":"Connecticut"},
      {"state_code":"US-DE","state_name":"Delaware"},
      {"state_code":"US-DC","state_name":"District Of Columbia"},
      {"state_code":"US-FL","state_name":"Florida"},
      {"state_code":"US-GA","state_name":"Georgia"},
      {"state_code":"US-HI","state_name":"Hawaii"},
      {"state_code":"US-ID","state_name":"Idaho"},
      {"state_code":"US-IL","state_name":"Illinois"},
      {"state_code":"US-IN","state_name":"Indiana"},
      {"state_code":"US-IA","state_name":"Iowa"},
      {"state_code":"US-KS","state_name":"Kansas"},
      {"state_code":"US-KY","state_name":"Kentucky"},
      {"state_code":"US-LA","state_name":"Louisiana"},
      {"state_code":"US-ME","state_name":"Maine"},
      {"state_code":"US-MD","state_name":"Maryland"},
      {"state_code":"US-MA","state_name":"Massachusetts"},
      {"state_code":"US-MI","state_name":"Michigan"},
      {"state_code":"US-MN","state_name":"Minnesota"},
      {"state_code":"US-MS","state_name":"Mississippi"},
      {"state_code":"US-MO","state_name":"Missouri"},
      {"state_code":"US-MT","state_name":"Montana"},
      {"state_code":"US-NE","state_name":"Nebraska"},
      {"state_code":"US-NV","state_name":"Nevada"},
      {"state_code":"US-NH","state_name":"New Hampshire"},
      {"state_code":"US-NJ","state_name":"New Jersey"},
      {"state_code":"US-NM","state_name":"New Mexico"},
      {"state_code":"US-NY","state_name":"New York"},
      {"state_code":"US-NC","state_name":"North Carolina"},
      {"state_code":"US-ND","state_name":"North Dakota"},
      {"state_code":"US-OH","state_name":"Ohio"},
      {"state_code":"US-OK","state_name":"Oklahoma"},
      {"state_code":"US-OR","state_name":"Oregon"},
      {"state_code":"US-PA","state_name":"Pennsylvania"},
      {"state_code":"US-RI","state_name":"Rhode Island"},
      {"state_code":"US-SC","state_name":"South Carolina"},
      {"state_code":"US-SD","state_name":"South Dakota"},
      {"state_code":"US-TN","state_name":"Tennessee"},
      {"state_code":"US-TX","state_name":"Texas"},
      {"state_code":"US-UT","state_name":"Utah"},
      {"state_code":"US-VT","state_name":"Vermont"},
      {"state_code":"US-VA","state_name":"Virginia"},
      {"state_code":"US-WA","state_name":"Washington"},
      {"state_code":"US-WV","state_name":"West Virginia"},
      {"state_code":"US-WI","state_name":"Wisconsin"},
      {"state_code":"US-WY","state_name":"Wyoming"}
    ];
    const [new_states,setNewStates] = useState<any>(states);
    const [new_courses,setNewCourses] = useState<any>(course_list);
    const [new_tees,setNewTees] = useState<any>(tees_list);
    const [new_players,setNewPlayers] = useState<any>(all_users);

    useEffect(() => {
        let user = localStorage.getItem('firebase_user');
        if(user != null) {
          setCurrentUser(JSON.parse(user));
          //setPlayingQuota(JSON.parse(user).quota);
        }
    }, []);
    const [showStateModal, setShowStateModal] = useState(false);
    const [showCourseModal, setShowCourseModal] = useState(false);
    const [showTeesModal, setShowTeesModal] = useState(false);
    const [showPlayersModal, setShowPlayersModal] = useState(false);
    return (
        <IonPage>
             <IonHeader mode="ios">
             <IonToolbar>
                <IonTitle>
                    <IonLabel>
                        <IonText>Post Score</IonText>
                    </IonLabel>
                </IonTitle>
            </IonToolbar>
             </IonHeader>
            <IonContent>
                <IonGrid>
                    <>
                        <IonRow className="ion-justify-content-center mt-10 ion-text-center">
                            <IonText className="wg-600 black s-18 myclub-header">{current_user.first_name+' '+current_user.last_name}</IonText>
                        </IonRow>
                        <IonRow className="ion-justify-content-center mt-10 select-modal">
                            <IonInput value={select_state} 
                                // interfaceOptions={customAlertOptions}
                                // interface="popover"                        
                                placeholder="select state" className="s-14 select-pack-dropdown pl-20" 
                                // onIonChange={e => {
                                //     setSelectState(e.detail.value)
                                //     getCourses(e.detail.value!)
                                // }}
                                mode="ios" onClick={()=>{
                                    setShowStateModal(true)
                                    setNewStates(states)
                                }}>
                                {/* {
                                    (states && states.map((key:any,i:number)=>{
                                        return(
                                            <IonSelectOption value={key.state_code} key={i}>{key.state_name}</IonSelectOption>
                                        )
                                    }))
                                }                                     */}
                            </IonInput>
                        </IonRow>
                        <IonRow className="ion-justify-content-center mt-10 select-modal">
                            <IonInput value={course} 
                                // interfaceOptions={customAlertOptions}
                                // interface="popover"                        
                                placeholder="select course" className="s-14 select-pack-dropdown pl-20" 
                                // onIonChange={e => {
                                //     setSelectState(e.detail.value)
                                //     getCourses(e.detail.value!)
                                // }}
                                mode="ios" onClick={()=>{
                                    setShowCourseModal(true)
                                    setNewCourses(course_list)
                                }}>
                                {/* {
                                    (states && states.map((key:any,i:number)=>{
                                        return(
                                            <IonSelectOption value={key.state_code} key={i}>{key.state_name}</IonSelectOption>
                                        )
                                    }))
                                }                                     */}
                            </IonInput>
                                {/* <IonSelect value={course} 
                                    interfaceOptions={customAlertOptions1}
                                    interface="popover"                        
                                    placeholder="Select Course" className="s-14 select-pack-dropdown" 
                                    onIonChange={e => {
                                        setCourse(e.detail.value!)
                                        getTees(e.detail.value!, 18)
                                        getUsers()
                                    }} mode="ios">
                                    {
                                        (course_list && course_list.map((key:any,i:number)=>{
                                        return(
                                            <IonSelectOption value={key.CourseID} key={i}>{key.FullName}</IonSelectOption>
                                        )
                                        }))
                                    }  
                                </IonSelect> */}
                        </IonRow>

                        <IonRow className="ion-padding">
                            <IonSegment onIonChange={(e:any) => {                                
                                setHoles(e.detail.value)
                                setSelectTees('');
                                setPlayingQuota(0)
                                getTees(course_id, e.detail.value)
                               
                                } } value={holes} mode="ios" className="new-segment">
                                <IonSegmentButton value="18" className="new-segment-button">
                                <IonLabel className="new-segment-button-label">18 Holes</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="9" className="new-segment-button">
                                <IonLabel className="new-segment-button-label">9 Holes</IonLabel>
                                </IonSegmentButton>
                            </IonSegment>
                        </IonRow>
                        <IonRow className="ion-justify-content-center select-modal">
                            <IonInput value={select_tees} 
                                // interfaceOptions={customAlertOptions}
                                // interface="popover"                        
                                placeholder="select tees" className="s-14 select-pack-dropdown pl-20" 
                                // onIonChange={e => {
                                //     setSelectState(e.detail.value)
                                //     getCourses(e.detail.value!)
                                // }}
                                mode="ios" onClick={()=>{
                                    setShowTeesModal(true)
                                    setNewTees(tees_list)
                                }}>
                                {/* {
                                    (states && states.map((key:any,i:number)=>{
                                        return(
                                            <IonSelectOption value={key.state_code} key={i}>{key.state_name}</IonSelectOption>
                                        )
                                    }))
                                }                                     */}
                            </IonInput>
                            {/* <IonSelect value={select_tees} 
                                interfaceOptions={customAlertOptions2}
                                interface="popover"                        
                                placeholder="Select Tees(Course/Slope/Par)" className="s-14 select-pack-dropdown" 
                                onIonChange={e => {
                                    setSelectTees(e.detail.value)
                                    getTeeDetails(course, holes, e.detail.value)
                                }} mode="ios">
                                {
                                    (tees_list && tees_list.map((key:any,i:number)=>{
                                        return(
                                            <IonSelectOption value={key.teeValue} key={i}>{key.teeName}</IonSelectOption>
                                        )
                                    }))
                                } 
                            </IonSelect> */}
                        </IonRow>
                        {
                          select_tees!=='' &&  target_tees && target_tees.length > 0  ? (
                                <IonRow className="ion-justify-content-center mt-10 ion-text-center">
                                    <IonText className="black s-15 myclub-header">Playing Target: {holes == 18 ? playing_quota : playing_quota/2}</IonText>
                                </IonRow>
                            ) : (
                                <></>
                            )
                        }
                        <IonRow className="ion-justify-content-center mt-10 ion-text-center">
                            <IonText className="black s-12">Date &#38; Tee time</IonText>
                        </IonRow>
                        <IonRow className="ion-justify-content-center mt-5 mb-10" style={{padding:"0px 10px 0px 10px"}}>
                            <IonCol className="pad-0">
                                <IonInput value={selected_date}            
                                    placeholder="Select date" className="s-14 select-pack-dropdown pl-20" 
                                    mode="ios" 
                                    disabled={true}
                                    onClick={()=> {
                                        //setShowDatePicker(true);
                                    }}>
                                </IonInput>
                                {
                                    (showDatePicker) ? (
                                        <IonDatetime
                                            presentation="date"
                                            size='cover'
                                            onIonChange={(e) => {
                                                setSelectedDate(format(new Date(e.detail.value!), "MM/dd/yyyy"));
                                                setShowDatePicker(false);
                                            }}
                                        ></IonDatetime>
                                    ) : (
                                        <></>
                                    )
                                }
                            </IonCol>
                            <IonCol className="pad-0">
                                <IonInput value={selected_time}            
                                    placeholder="tee time" className="s-14 select-pack-dropdown pl-20" 
                                    mode="ios" 
                                    type="time"
                                    onClick={()=> {
                                        //setShowTimePicker(true);
                                    }}
                                    onIonChange={(e)=>{
                                        setSelectedTime(e.detail.value!);
                                    }
                                    }
                                    style={{height: '39px'}}>
                                </IonInput>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center select-modal">
                            <IonInput value={selected_player} 
                                // interfaceOptions={customAlertOptions}
                                // interface="popover"                        
                                placeholder="Playing with" className="s-14 select-pack-dropdown pl-20" 
                                // onIonChange={e => {
                                //     setSelectState(e.detail.value)
                                //     getCourses(e.detail.value!)
                                // }}
                                mode="ios" onClick={()=>{
                                    setShowPlayersModal(true)
                                    setNewPlayers(all_users)
                                }}>
                                {/* {
                                    (states && states.map((key:any,i:number)=>{
                                        return(
                                            <IonSelectOption value={key.state_code} key={i}>{key.state_name}</IonSelectOption>
                                        )
                                    }))
                                }                                     */}   
                            </IonInput>
                            {/* <IonSelect value={selected_player} 
                                interfaceOptions={customAlertOptions3}
                                interface="popover"                        
                                placeholder="Playing with" className="s-14 select-pack-dropdown" 
                                onIonChange={e => {
                                    setSelectedPlayer(e.detail.value)
                                }} mode="ios">
                                {
                                    (all_users && all_users.map((key:any,i:number)=>{
                                        return(
                                            <IonSelectOption value={key.id} key={i}>{key.first_name+' '+key.last_name}</IonSelectOption>
                                        )
                                    }))
                                } 
                            </IonSelect> */}
                        </IonRow>
                    </>
                    <IonRow className="ion-justify-content-center ion-text-center mt-25">
                        <button className='button-send white s-15 bg button-border-20 ' onClick={()=>{setShowStartLoading(true);createScore();}}>                            
                            <IonText className="wg-bold">Start</IonText>
                        </button>
                    </IonRow>
                </IonGrid>
                <IonModal isOpen={ppw_score_modal} className='my-custom-class'>
                    <>
                        <IonHeader>
                            <IonToolbar>
                                {/* <IonButtons slot="start">
                                    <a onClick={()=> window.location.href="/leagues"}>
                                        <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                                    </a>
                                </IonButtons> */}
                                <IonTitle>
                                    Points
                                </IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <EditTable options={score_record} options1={edit_hole_details} options2={hole_points} holes={holes} front9={front_holes} back9={back_holes}/>
                            <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button">
                                <IonFabButton
                                className="main-fab"
                                onClick={() => {
                                    window.location.href="/leagues/formats"
                                }}
                                >
                                <IonIcon icon={arrowBackOutline} />
                                </IonFabButton>
                            </IonFab>
                        </IonContent>
                    </>
                </IonModal>
                <IonModal isOpen={showStateModal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true} 
                onDidDismiss={() => setShowStateModal(false)}
                initialBreakpoint={0.5} onDidPresent={()=> {setTimeout(() => inputRef.current.setFocus(), 100)}} mode="md">
                    <>
                   
                    <IonSearchbar ref={(ref) => inputRef.current = ref} onIonChange={(e:any) => {
                                
                                if(String(e.detail.value!).length>0){
                                    let glo = new RegExp(String(e.detail.value!).toLowerCase());
                                    let new_city_arr = new Array() 
                                    states.map((item:any)=>{
                                        let real_item = item
                                        item = item.state_name.toLowerCase()

                                        if(glo.test(item)){
                                            new_city_arr.push(real_item)
                                        }
                                    })                      
                                    setNewStates(new_city_arr)                                    
                                }
                                else{
                                    setNewStates(states)
                                }
                                }} mode="ios"></IonSearchbar>

                    <IonContent className='content-height'>
                        <IonGrid>
                            <IonList>
                                    {
                                    (new_states && new_states.map((key:any,i:number)=>{
                                        return(
                                                <IonItem key={i}>
                                                    <IonLabel onClick={()=>{
                                                        setSelectState(key.state_name)
                                                        getCourses(key.state_code)
                                                        setShowStateModal(false)

                                                    }}>
                                                    {key.state_name}
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        }))
                                    }                                    
                                
                            </IonList>
                        </IonGrid>
                    </IonContent>
                    </>
                </IonModal>
                <IonModal isOpen={showCourseModal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true} 
                onDidDismiss={() => setShowCourseModal(false)}
                initialBreakpoint={0.5} onDidPresent={()=> {setTimeout(() => inputRef.current.setFocus(), 100)}} mode="md">
                    <>
                   
                    <IonSearchbar ref={(ref) => inputRef.current = ref} onIonChange={(e:any) => {
                                
                                if(String(e.detail.value!).length>0){
                                    let glo = new RegExp(String(e.detail.value!).toLowerCase());
                                    let new_course_arr = new Array() 
                                    course_list.map((item:any)=>{
                                        let real_item = item
                                        item = item.FullName.toLowerCase()

                                        if(glo.test(item)){
                                            new_course_arr.push(real_item)
                                        }
                                    })                       
                                    setNewCourses(new_course_arr)                                    
                                }
                                else{
                                    setNewCourses(course_list)
                                }
                                }} mode="ios"></IonSearchbar>

                    <IonContent className='content-height'>
                        <IonGrid>
                            <IonList>
                                    {
                                    (new_courses && new_courses.map((key:any,i:number)=>{
                                        return(
                                                <IonItem key={i}>
                                                    <IonLabel onClick={()=>{
                                                        setCourse(key.FullName)
                                                        setCourseId(key.CourseID)
                                                        getTees(key.CourseID!, parseInt(holes))
                                                        getUsers()
                                                        setShowCourseModal(false)
                                                    }}>
                                                    {key.FullName}
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        }))
                                    }                                    
                                
                            </IonList>
                        </IonGrid>
                    </IonContent>
                    </>
                </IonModal>
                <IonModal isOpen={showTeesModal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true} 
                onDidDismiss={() => setShowTeesModal(false)}
                initialBreakpoint={0.5} onDidPresent={()=> {setTimeout(() => inputRef.current.setFocus(), 100)}} mode="md">
                    <>

                        <IonSearchbar ref={(ref) => inputRef.current = ref} onIonChange={(e:any) => {
                            
                            if(String(e.detail.value!).length>0){
                                let glo = new RegExp(String(e.detail.value!).toLowerCase());
                                let new_tees_arr = new Array() 
                                tees_list.map((item:any)=>{
                                    let real_item = item
                                    item = item.teeName.toLowerCase()

                                    if(glo.test(item)){
                                        new_tees_arr.push(real_item)
                                    }
                                })                           
                                setNewTees(new_tees_arr)                                    
                            }
                            else{
                                setNewTees(tees_list)
                            }
                            }} mode="ios">
                        </IonSearchbar>

                        <IonContent className='content-height'>
                            <IonGrid>
                                <IonList>
                                    {
                                        (new_tees && new_tees.map((key:any,i:number)=>{
                                            return(
                                                <IonItem key={i}>
                                                    <IonLabel onClick={()=>{
                                                        setSelectTees(key.teeValue)
                                                        getTeeDetails(course_id, holes, key.teeValue)
                                                        setShowTeesModal(false)
                                                    }}>
                                                    {key.teeName}
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        }))
                                    }                                    
                                    
                                </IonList>
                            </IonGrid>
                        </IonContent>
                    </>
                </IonModal>
                <IonModal isOpen={showPlayersModal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true} 
                onDidDismiss={() => setShowPlayersModal(false)}
                initialBreakpoint={0.5} onDidPresent={()=> {setTimeout(() => inputRef.current.setFocus(), 100)}} mode="md">
                    <>

                        <IonSearchbar ref={(ref) => inputRef.current = ref} onIonChange={(e:any) => {
                            
                            if(String(e.detail.value!).length>0){
                                let glo = new RegExp(String(e.detail.value!).toLowerCase());
                                let new_players_arr = new Array() 
                                all_users.map((item:any)=>{
                                    let real_item = item
                                    item = item.first_name.toLowerCase()+' '+item.last_name.toLowerCase()

                                    if(glo.test(item)){
                                        new_players_arr.push(real_item)
                                    }
                                })                         
                                setNewPlayers(new_players_arr)                                    
                            }
                            else{
                                setNewPlayers(all_users)
                            }
                            }} mode="ios">
                        </IonSearchbar>

                        <IonContent className='content-height'>
                            <IonGrid>
                                <IonList>
                                    {
                                        (new_players && new_players.map((key:any,i:number)=>{
                                            return(
                                                <IonItem key={i}>
                                                    <IonLabel onClick={()=>{
                                                        console.log(key)
                                                        setSelectedPlayer(key.first_name+' '+key.last_name)
                                                        setSelectedPlayerId(key.id)
                                                        setShowPlayersModal(false)
                                                    }}>
                                                    {key.first_name+' '+key.last_name}
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        }))
                                    }                                    
                                    
                                </IonList>
                            </IonGrid>
                        </IonContent>
                    </>
                </IonModal>
                <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button">
                    <IonFabButton
                    className="main-fab"
                    onClick={() => {
                        window.location.href = "/leagues/formats";
                    }}
                    >
                    <IonIcon icon={arrowBackOutline} />
                    </IonFabButton>
                </IonFab>
                <IonLoading
                    isOpen={showCourseLoading}
                    message={'loading courses..'}
                />
                <IonLoading
                    isOpen={showTeesLoading}
                    message={'loading tees..'}
                />
                <IonLoading
                    isOpen={showStartLoading}
                    message={'Creating Score Card..'}
                />
            </IonContent>
        </IonPage>
    );
};
export default CreateScore;