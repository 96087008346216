import {
  IonButton,
  IonInput,
  IonMenuToggle,
  IonRange,
  IonSelect,
  IonSelectOption,
  IonSlide,
  IonSlides,
  isPlatform,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import {
  IonModal,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonButtons,
  IonSegment,
  IonSegmentButton,
  IonGrid,
  IonFab,
  IonFabButton,
  IonFabList,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonAvatar,
  IonItem,
  IonRow,
  IonCol,
  IonText,
  useIonActionSheet,
} from "@ionic/react";
import { arrowBackOutline, copyOutline, informationCircleOutline, } from 'ionicons/icons';
import {
    LockPassword
} from '@styled-icons/remix-line';
import { useState, useRef, useEffect } from 'react';
import default_profile_logo from "../../img/fewbats.svg";
import _ from 'lodash';
import firebase from '../../js/firebase';
import { getAuth, updatePassword } from 'firebase/auth';
import * as utils from '../../js/utils';
import { deleteEntry, updateEntry } from '../../js/firebase_doc_functions';
import { Clipboard } from '@capacitor/clipboard';
import Table from '../Components/table';
import Quill from '../Components/get_quill';
import PayPal from '../Components/paypal_payment';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { Checkmark, CheckmarkDone, TrashBin } from "@styled-icons/ionicons-outline";
import { CalendarEdit } from "@styled-icons/fluentui-system-regular";
import { NoAccounts } from "@styled-icons/material-rounded";
import { ChatLeftText } from "@styled-icons/bootstrap";


import "../../css/locker_room.css";
import {
  add,
  create,
  trash,
  duplicateOutline,
  peopleOutline,
  notificationsOutline,
  chevronDownOutline,
  chevronUpOutline,
  chevronForwardOutline,
  addCircleOutline,
  paperPlane,
  closeOutline
} from "ionicons/icons";
import {
  Chatbubbles,
  Calendar,
  Gift,
  Grid,
} from '@styled-icons/ionicons-outline';
import {
  Golf
} from '@styled-icons/ionicons-solid';
import { format } from "date-fns";
import EditTable from "../Components/edit_table";


import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  getDocs,
  deleteDoc,
  orderBy
} from "firebase/firestore";
import {
  createEntryWithoutId,
} from "../../js/firebase_doc_functions";
import { Browser } from "@capacitor/browser";
import Chart from "react-apexcharts";
import PpwScoreTable from "../Components/ppw_score_table";
import { useParams } from "react-router";

const db = getFirestore(firebase);
const auth = getAuth();

const Profile: React.FC = () => {
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const [firstname,setFirstName] = useState<string>('');
    const [lastname,setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [user_image, setUserImage] = useState<any>("");
    const [zip,setZip] = useState<string>('');
    const [referral_id, setReferralID] = useState<string>('');
    const [ghin_validate, setGHINVal] = useState<string>('');
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [password, setPassword] = useState<string>('');
    const [confirm_password, setConfirmPassword] = useState<string>('');
    const [selected_pack, setSelectedPack] = useState<string>('');
    const [selected_club, setSelectedClub] = useState<string>('');
    const [current_pack, setCurrentPack] = useState<any>([]);
    const [current_club, setCurrentClub] = useState<any>([]);
    const [current_user, setCurrentUser] = useState<any>([]);
    const [all_packs, setAllPacks] = useState<any>([]);
    const [all_clubs, setAllClubs] = useState<any>([]);
    //const [tee_value, setTeeValue] = useState<number>();
    const [tee_value,setTeeValue] = useState<any>(() => {
      let user = localStorage.getItem('firebase_user');
      let pack = localStorage.getItem('current_pack');
      if(user != null) {
        if (JSON.parse(user).preferred_tee && JSON.parse(user).preferred_tee == "Back") {
          return 1
        }
        if (JSON.parse(user).preferred_tee && JSON.parse(user).preferred_tee == "Middle Back") {
          return 2
        }
        if (JSON.parse(user).preferred_tee && JSON.parse(user).preferred_tee == "Middle") {
          return 3
        }
        if (JSON.parse(user).preferred_tee && JSON.parse(user).preferred_tee == "Middle Front") {
          return 4
        }
        if (JSON.parse(user).preferred_tee && JSON.parse(user).preferred_tee == "Front") {
          return 5
        }
    }});
    const [showLoading, setShowLoading] = useState(false);
    const [showToast, hideToast] = useIonToast();
    const [showQuotaModal, setShowQuotaModal] = useState<any>(false);


    const handleClick = () => {
        hiddenFileInput.current!.click()
        // console.log('hi',refInput.current)
    }
    const copyReferralId = async (rfid:string) => {
        await Clipboard.write({
          string: rfid
        });
        showToast('Copied to clipboard!', 2000);
    };

    const getAllPacks = async()=> {
        const q = query(collection(db, "PACKS"), where('pack_id', '!=', 'kuFOI2OPKCuhCwrewzBqDI8w71IE'));
        const querySnapshot = await getDocs(q);
        if(querySnapshot.docs && querySnapshot.docs.length > 0) {
            await Promise.all(querySnapshot.docs.map(async pack => {
                var doc_item = pack.data();
                doc_item.id = pack.id;
                return doc_item;
            }))
            .then(results=> {
                setAllPacks(results);
            });
        } else {
            console.log("No packs available");
        }
    }
    const changePack = async(pack_id:string)=> {
        console.log()
        let pack = all_packs.filter((pack:any)=> pack.pack_id == pack_id);
        if(pack.length > 0) {
            localStorage.setItem('current_pack',
              JSON.stringify({
                'pack_name': pack[0].pack_name,
                'pack_id': pack[0].pack_id,
                'region_name': pack[0].region_name,
                'region_id': pack[0].region_id,
                'pack_type': pack[0].pack_type,
                'pack_lead_id': pack[0].pack_lead_id,
                'city': pack[0].city
              })
            );
            setCurrentPack({
                'pack_name': pack[0].pack_name,
                'pack_id': pack[0].pack_id,
                'region_name': pack[0].region_name,
                'region_id': pack[0].region_id,
                'pack_type': pack[0].pack_type,
                'pack_lead_id': pack[0].pack_lead_id,
                'city': pack[0].city
            })
        }
    }
    const changeClub = async(club_id:string)=> {
        console.log()
        let club = all_clubs.filter((club:any)=> club.club_id == club_id);
        if(club.length > 0) {
            localStorage.setItem('current_club',
              JSON.stringify({
                'club_id': club[0].club_id,
                'club_name': club[0].club_name,
                "membership_rev_date": club[0].membership_rev_date
              })
            );
            setCurrentClub({
                'club_id': club[0].club_id,
                'club_name': club[0].club_name,
                "membership_rev_date": club[0].membership_rev_date
            })
        }
    }
    const changePassword=async()=> {
        if (password == "" || confirm_password == "") {
            alert("Password and Confirm Password fields are required.");
        } else if (password.length < 6 || confirm_password.length < 6) {
            alert("Password and Confirm Password should not be less than 6 characters.");
        } else if (password != confirm_password) {
            alert("Password and Confirm Password does not match.");
        } else {
            const user = auth.currentUser;
            if(user != null) {
                updatePassword(user, password).then(() => {
                    // Update successful.
                    setShowModal2(false);
                    showToast('Password changed', 2000);

                }).catch((error) => {
                    // An error ocurred
                    // ...
                    setShowModal2(false);
                    showToast('Session expired. Please relogin to change password', 2000);
                });
            }
        }
    }

    const validateGHIN = async()=> {
        let response = await utils.getGolferData(ghin_validate, current_user.last_name);
        if(response != "error") {
            let golfers = response || {golfers:[{"association_id": "","association_name": "","club_name": "","club_id":0, "rev_date": ""}]};
            var obj = new Array();

            for(var i=0; i<golfers.golfers.length;i++) {
                obj.push({
                    "association_id": golfers.golfers[i].association_id,
                    "association_name": golfers.golfers[i].association_name,
                    "club_name": golfers.golfers[i].club_name,
                    "club_id": golfers.golfers[i].club_id,
                    "membership_rev_date": golfers.golfers[i].rev_date
                })
            }
            setAllClubs(obj);
            setCurrentClub(obj[0]);
            current_user.club_details = obj;
            localStorage.setItem("firebase_user", JSON.stringify(current_user));
            await updateEntry("USER_PROFILE", current_user.id, {club_details: obj});
            setShowModal1(false);
        }
    }

    const [profile_pic,setProfilePic] = useState<any>("")
    const handleChange = async(event:any) => {
        const fileRec = event.target.files[0]
        const final = (fileRec.name)
        console.log(fileRec)
        console.log(final)
        console.log('entered')
        // const data = new FormData()
        // data.append('file', fileUploaded)
        if (fileRec && [".png", ".jpeg", ".jpg"].indexOf(fileRec.name.substring(fileRec.name.lastIndexOf('.'))) < 0) {
            alert("Select a valid file format");
        } else {
            var reader = new FileReader();
            reader.onload = function (e:any) {
                // console.log('entered1',e.target.result)
                setProfilePic(e.target.result)
            };
            reader.readAsDataURL(fileRec);
        }
    }

    const withdrawRequest = async()=> {
      const userQuery1 = query(
        collection(db, "ACCOUNT_DELETION_REQUESTS"),
        where("user_id", "==", current_user.user_id),
        where("status", "==", "Pending")
      );
      const userSnapshot1 = await getDocs(userQuery1);
      if(userSnapshot1.docs && userSnapshot1.docs.length > 0) {
        presentAlert({
          header: 'Withdraw request!',
          message: 'Your request for deletion of account will be removed!',
          buttons: [
            'Cancel',
            { text: 'Ok', handler: async (d) => {
              await deleteEntry("ACCOUNT_DELETION_REQUESTS", userSnapshot1.docs[0].id);
              setShowModal3(false);
            }}
          ]
        })
      } else {
        setShowModal3(false);
      }
    }

    const deleteAccount = async()=> {
      const userQuery1 = query(
        collection(db, "ACCOUNT_DELETION_REQUESTS"),
        where("user_id", "==", current_user.user_id),
        where("status", "==", "Pending")
      );
      const userSnapshot1 = await getDocs(userQuery1);
      if(userSnapshot1.docs && userSnapshot1.docs.length > 0) {
        presentAlert({
          header: 'Duplicate request!',
          message: 'Your account deletion request is already in progress!',
          buttons: [
            { text: 'Ok', handler: async (d) => {
              
            }}
          ]
        })
      } else {
        const userQuery2 = query(
          collection(db, "USER_PROFILE"),
          where("user_id", "==", current_user.user_id),
          where("status", "==", "Active")
        );
        const userSnapshot2 = await getDocs(userQuery2);
        if(userSnapshot2.docs && userSnapshot2.docs.length > 0) {
          await createEntryWithoutId("ACCOUNT_DELETION_REQUESTS", {user_id: current_user.user_id, email: current_user.email, user_name: current_user.first_name+' '+current_user.last_name, doc_id: current_user.id, status: "Pending"})
          setShowModal3(false);
          presentAlert({
            header: 'Request submitted!',
            message: 'Your account deletion request has been submitted to us, your account will be deleted in 3 business days!',
            buttons: [
              { text: 'Ok', handler: async (d) => {
                
              }}
            ]
          })
        }
      }
    }

    //-----------------------------------//

      const { tab }: any = useParams();
      const [leagues_tab_value, setLeaguesTabValue] = useState<string>(tab && tab === "scores" ? "Scores" : "Leagues");
      const [present, dismiss] = useIonActionSheet();
      const [handicap, setHandicap] = useState<string>("N/A");
      const [best_round, setBestRound] = useState<any>([]);
      const [best_rounds, setBestRounds] = useState<any>([]);
      const [comments, setComments] = useState<any>([]);
      const [all_rounds, setAllRounds] = useState<any>([]);
      const [other_rounds, setOtherRounds] = useState<any>([]);
      const [showCommentsModal, setShowCommentsModal] = useState(false);
      const [profile_data, setProfileData] = useState<any>({});
      const [latest_round, setLatestRound] = useState<any>({});
      const [state_values, setStateValues] = useState<any>([]);
      const [state2_values, setState2Values] = useState<any>([]);
      const [pack_city, setPackCity] = useState<string>('');
      const [showJoinPackModal, setShowJoinPackModal] = useState(false);
      const [selected_pack_id, setSelectedPackId] = useState("");
      const [url, setUrl] = useState<string>("");
      const [notifications, setNotifications] = useState<boolean>(false);
      const [front_holes, setFrontHoles] = useState<boolean>(false);
      const [back_holes, setBackHoles] = useState<boolean>(false);
      const [packs_list, setPacksList] = useState<any>([]);
      const [presentToast, dismissToast] = useIonToast();
      const [chips_qty, setChipsQty] = useState<any>(250);
      const [amount, setAmount] = useState<any>(25);
      const [pack_message, setPackMessage] = useState<string>();
      const [presentAlert, dismissAlert] = useIonAlert();
      const [content, setContent] = useState<any>('');
      const [key_date, setKeyDate] = useState<number>();
      const [required_data, setRequiredData] = useState<any>();
      const [val2, setVal2] = useState<any>([
        "Eagles",
        "Birds",
        "Par",
        "Bogey",
        "Double",
        "Triple",
      ]);
      const [paypal_data, setPaypalData] = useState<any>();
      const [set_paypal_payment, setPaypalPayment] = useState(false);
      const [buy_format, setBuyFormat] = useState<string>('');
      const [referrer, setReferrer] = useState<string>('');

      const getProfileData = async (user: any) => {
        console.log("getProfileData")
        const roundsClassificationQuery = query(
          collection(db, "CONFIG"),
          where("type", "==", "ROUNDS_CLASSIFICATION")
        );
        const roundsClassificationSnapshot = await getDocs(
          roundsClassificationQuery
        );
        const scoresQuery = query(
          collection(db, "SCORE_RECORDS"),
          where("created_at", ">", 0),
          where("user_id", "==", user.user_id),
          where("score_submitted", "==", true)
        );
        const scoresSnapshot = await getDocs(scoresQuery);
        console.log(scoresSnapshot)
        await Promise.all(
          scoresSnapshot.docs.map(async (competition) => {
            var doc_item = competition.data();
            doc_item.id = competition.id;
            let courseStr = doc_item.course_name.split("-");
            if (courseStr[0] != undefined || courseStr[0] != null)
              doc_item.course_name = courseStr[0];
            if (courseStr[1] != undefined || courseStr[1] != null)
              doc_item.course_sub_name = courseStr[1];
            if (doc_item.tee) {
              let teeset = doc_item.tee.split("~");
              doc_item.tee =
                doc_item.holes == 18 ? teeset[0] : teeset[0] + "~" + doc_item.tee_type;
            }
            if (doc_item.points) {
              //doc_item.points.birdie =  doc_item.points.birdie +  doc_item.points.eagle + doc_item.points.double_eagle;
              doc_item.total_points = doc_item.points.total_points;
            } else {
              doc_item.points = {
                triple_bogey: 0,
                double_bogey: 0,
                bogey: 0,
                par_value: 0,
                birdie: 0,
                eagle: 0,
                double_eagle: 0,
                total_points: 0,
              };
              doc_item.total_points = 0;
            }
            return doc_item;
            console.log(doc_item)
          })
        ).then((results) => {
          console.log(results.length)
          if (results.length > 0) {
            let roundsClassification = roundsClassificationSnapshot.docs && roundsClassificationSnapshot.docs.length > 0 ? roundsClassificationSnapshot.docs[0].data() : new Array();
            var classificationSetup = results.length > 10 ? roundsClassification.rounds.filter((round: any) => round.count == 10) : roundsClassification.rounds.filter((round: any) => round.count == results.length);
            var latestRound = _.sortBy(results, (e) => e.competition_date_timestamp).reverse()[0];
            var sortedRecords = _.sortBy(results, (e) => e.total_points).reverse();
            const allRecords = _.sortBy(sortedRecords, (e: any) => e.competition_date_timestamp).reverse();
            setAllRounds(allRecords);
            var bestRecords = new Array();
            var otherRecords = new Array();
            bestRecords = sortedRecords.map((record, idx) => {
              if (idx < classificationSetup[0].best) {
                record.best = true;
                return record;
              }
            })
              .filter((record) => record);
            otherRecords = sortedRecords
              .map((record, idx) => {
                if (
                  idx > classificationSetup[0].best &&
                  idx <= classificationSetup[0].count
                ) {
                  record.best = false;
                  return record;
                }
              })
              .filter((record) => record);
            var bestRound = bestRecords[0] || new Array();
            let bestRoundStrokesData = bestRound.hole_details.map((rec: any) => {
              return rec.raw_score;
            });
            let birdieBogeyParTotal =
              bestRound.points.birdie +
              bestRound.points.par_value +
              bestRound.points.bogey;
            var bestRoundTableData = {
              strokes: bestRoundStrokesData.reduce(
                (a: number, b: number) => a + b,
                0
              ),
              total_points: bestRound.points.total_points,
              birdie: bestRound.points.birdie,
              par: bestRound.points.par_value,
              bogey: bestRound.points.bogey,
              double_bogey: bestRound.points.double_bogey,
              triple_bogey: bestRound.points.triple_bogey,
              others: 18 - birdieBogeyParTotal,
            };
            var bestRoundsTableData = {
              strokes: 0,
              total_points: 0,
              birdie: 0,
              par: 0,
              bogey: 0,
              double_bogey: 0,
              triple_bogey: 0,
              others: 0,
              count: classificationSetup[0].best,
            };
            var otherRoundsTableData = {
              strokes: 0,
              total_points: 0,
              birdie: 0,
              par: 0,
              bogey: 0,
              double_bogey: 0,
              triple_bogey: 0,
              others: 0,
              count: classificationSetup[0].other,
            };
            let bestRoundsPointsData = bestRecords.map((record: any) => {
              if (record.holes == 9) {
                record.total_points = record.total_points * 2;
                return record.total_points;
              } else {
                return record.total_points;
              }
            });
            bestRoundsTableData.total_points = Math.round(
              bestRoundsPointsData.reduce((a, b) => a + b, 0) / bestRecords.length
            );
            let bestRoundsStrokesData = new Array();
            bestRecords.map((record: any) => {
              let rawScoresData = record.hole_details.map((rec: any) => {
                return rec.raw_score;
              });
              bestRoundsStrokesData = bestRoundsStrokesData.concat(rawScoresData);
              return record;
            });
            bestRoundsTableData.strokes = Math.floor(
              bestRoundsStrokesData.reduce((a, b) => a + b, 0) / bestRecords.length
            );
            let bestRoundsBirdieData = bestRecords.map((record: any) => {
              return record.points.birdie;
            });
            bestRoundsTableData.birdie = Math.round(
              bestRoundsBirdieData.reduce((a, b) => a + b, 0) / bestRecords.length
            );
            let bestRoundsParData = bestRecords.map((record: any) => {
              return record.points.par_value;
            });
            bestRoundsTableData.par = Math.round(
              bestRoundsParData.reduce((a, b) => a + b, 0) / bestRecords.length
            );
            let bestRoundsBogeyData = bestRecords.map((record: any) => {
              return record.points.bogey;
            });
            bestRoundsTableData.bogey = Math.round(
              bestRoundsBogeyData.reduce((a, b) => a + b, 0) / bestRecords.length
            );
            let bestBirdieBogeyParTotal =
              bestRoundsTableData.birdie +
              bestRoundsTableData.par +
              bestRoundsTableData.bogey;
            bestRoundsTableData.others =
              bestBirdieBogeyParTotal > 0 ? 18 - bestBirdieBogeyParTotal : 0;

            let otherRoundsPointsData = otherRecords.map((record: any) => {
              if (record.holes == 9) {
                //record.total_points = record.total_points * 2;
                return record.total_points;
              } else {
                return record.total_points;
              }
            });
            otherRoundsTableData.total_points = Math.round(
              otherRoundsPointsData.reduce((a, b) => a + b, 0) / otherRecords.length
            );
            let otherRoundsStrokesData = new Array();
            otherRecords.map((record: any) => {
              let rawScoresData = record.hole_details.map((rec: any) => {
                return rec.raw_score;
              });
              otherRoundsStrokesData = otherRoundsStrokesData.concat(rawScoresData);
              return record;
            });
            otherRoundsTableData.strokes = Math.floor(
              otherRoundsStrokesData.reduce((a, b) => a + b, 0) /
              otherRecords.length
            );
            let otherRoundsBirdieData = otherRecords.map((record: any) => {
              return record.points.birdie;
            });
            otherRoundsTableData.birdie = Math.round(
              otherRoundsBirdieData.reduce((a, b) => a + b, 0) / otherRecords.length
            );
            let otherRoundsParData = otherRecords.map((record: any) => {
              return record.points.par_value;
            });
            otherRoundsTableData.par = Math.round(
              otherRoundsParData.reduce((a, b) => a + b, 0) / otherRecords.length
            );
            let otherRoundsBogeyData = otherRecords.map((record: any) => {
              return record.points.bogey;
            });
            otherRoundsTableData.bogey = Math.round(
              otherRoundsBogeyData.reduce((a, b) => a + b, 0) / otherRecords.length
            );
            let othersBirdieBogeyParTotal =
              otherRoundsTableData.birdie +
              otherRoundsTableData.par +
              otherRoundsTableData.bogey;
            otherRoundsTableData.others =
              othersBirdieBogeyParTotal > 0 ? 18 - othersBirdieBogeyParTotal : 0;

            var allStrokes = Math.floor(
              (bestRoundsTableData.strokes > 0
                ? bestRoundsTableData.strokes * 0.7
                : 0) +
              (otherRoundsTableData.strokes > 0
                ? otherRoundsTableData.strokes * 0.3
                : 0)
            );
            var allPoints = Math.floor(
              (bestRoundsTableData.total_points > 0
                ? bestRoundsTableData.total_points * 0.7
                : 0) +
              (otherRoundsTableData.total_points > 0
                ? otherRoundsTableData.total_points * 0.3
                : 0)
            );
            //updateEntry("USER_PROFILE", user.id, {"quota": allPoints, "strokes": allStrokes});
            // user.quota = allPoints;
            // setCurrentUser(user);
            // localStorage.setItem('firebase_user', JSON.stringify(user));
            let allRounds = [...bestRecords, ...otherRecords];
            allRounds = _.sortBy(allRounds, (e: any) => e.competition_date_timestamp).reverse();
            setBestRound(bestRoundTableData);
            setBestRounds(bestRoundsTableData);
            setOtherRounds(otherRoundsTableData);
            setProfileData({
              all_strokes: allStrokes,
              all_points: allPoints,
            });
            setLatestRound({
              date: format(new Date(latestRound.competition_date), "MM/dd"),
              total_points: latestRound.points.total_points,
            });

          } else {
            setBestRound({
              strokes: 0,
              total_points: 0,
              birdie: 0,
              par: 0,
              bogey: 0,
              double_bogey: 0,
              triple_bogey: 0,
              others: 0,
            });
            setBestRounds({
              strokes: 0,
              total_points: 0,
              birdie: 0,
              par: 0,
              bogey: 0,
              double_bogey: 0,
              triple_bogey: 0,
              others: 0,
              count: 0,
            });
            setOtherRounds({
              strokes: 0,
              total_points: 0,
              birdie: 0,
              par: 0,
              bogey: 0,
              double_bogey: 0,
              triple_bogey: 0,
              others: 0,
              count: 0,
            });
            setProfileData({
              all_strokes: 0,
              all_points: 0,
            });
            setLatestRound({
              date: format(new Date(), "MM/dd"),
              total_points: 0,
            });
            setAllRounds([]);
          }
        });
      };



      const handleCallback = async () => {
        if (referrer === "ppw") {
          await updateEntry("USER_PROFILE", current_user.id, { ppw_validated: true });
          current_user.ppw_validated = true;
          localStorage.setItem('firebase_user', JSON.stringify(current_user));
          setPaypalPayment(false);
          showToast('Successfully signed up for Play.Post.Win.', 2000);
        } else if (referrer === "netscore") {
          await updateEntry("USER_PROFILE", current_user.id, { netscore_validated: true });
          current_user.netscore_validated = true;
          localStorage.setItem('firebase_user', JSON.stringify(current_user));
          setPaypalPayment(false);
          showToast('Successfully signed up for Net Score Challenge.', 2000);
        } else if (referrer === "tt") {
          if (tour && tour.id.length > 0) {
            let players = tour.players.filter((x: any) =>
              x.user_id == current_user.user_id,
            );
            if (players.length <= 0) {
              tour.players.push({ user_id: current_user.user_id, user_name: current_user.first_name + ' ' + current_user.last_name });
              await updateEntry("TOUR_SETTINGS", tour.id, { players: tour.players })
              await createEntryWithoutId("TOUR_SIGNUPS", {
                tour_name: tour.tour_name,
                tour_city: tour.city,
                format: tour.format,
                first_name: current_user.first_name,
                last_name: current_user.last_name,
                company: "",
                email: current_user.email,
                city: current_user.city,
                tour_id: tour.id,
                user_id: current_user.user_id,
                players: tour.players
              });
              getTourData(tour.id);
              setPaypalPayment(false);
              setShowTourModal(false);
              showToast('Successfully signed up for tour', 2000);
            } else {
              setPaypalPayment(false);
              setShowTourModal(false);
              showToast('Error!', 2000);
            }
          } else {
            showToast('Error fecthing tour details. Please try again after some time.', 2000);
          }
        }
      }
      const getNotificationCounts = async (user_id: string) => {
        const q = query(collection(db, "CHECKIN_NOTIFICATIONS"), where('created_at', '>', 0), where('user_id', '==', user_id));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.docs.length > 0) {
          querySnapshot.docs.forEach((notifications) => {
            const notification = notifications.data();
            if (!notification.match_status && !(notification.match_status === 'Declined' || notification.match_status === 'Accepted')) {
              setNotifications(true);
            }
          });
        }
      }

      const openActionsheet = async (league: string) => {
        if (league == "ppw") {
          setBuyFormat('Play.Post.Win.');
          setReferrer('ppw');
          if (current_user.ppw_validated) {
            present({
              header: "Play.Post.Win.",
              buttons: [
                // {
                //   text: "Scores",
                //   handler: () => {
                //     console.log("scores");
                //     window.location.href = "/ppw_checkins";
                //   },
                // },
                {
                  text: "About",
                  handler: async () => {
                    await Browser.open({ url: "https://eclub.golf/ppw.html" });
                  },
                },
                {
                  text: "Leaderboards",
                  handler: async () => {
                    window.location.href = "/ppw_leaderboards"
                  },
                },
                {
                  text: "Post score",
                  handler: async () => {
                    fetchPPWScores(current_user.user_id);
                    //window.location.href="/add_ppw_checkin"
                  },
                },
                {
                  text: "Close",
                  role: "cancel",
                },
              ],
            });
          } else {
            present({
              header: "Play.Post.Win.",
              buttons: [
                {
                  text: "About",
                  handler: async () => {
                    await Browser.open({ url: "https://eclub.golf/ppw.html" });
                  },
                },
                {
                  text: "Register",
                  handler: async () => {
                    const q = query(collection(db, "CONFIG"), where('type', '==', "PAYPAL"));
                    const querySnapshot = await getDocs(q);
                    if (querySnapshot && querySnapshot.docs.length > 0) {
                      var obj = {
                        "currency": "USD",
                        "client-id": querySnapshot.docs[0].data().value,
                        "components": "buttons"
                      }
                      setPaypalData(obj);
                      setPaypalPayment(true);
                    } else {
                      setPaypalData({});
                    }
                  },
                },
                {
                  text: "Close",
                  role: "cancel",
                },
              ],
            });
          }
        } else if (league == "net_score") {
          setBuyFormat('Net Score Challenge');
          setReferrer('netscore');
          if (current_user.netscore_validated) {
            present({
              header: "Net Score Challenge",
              buttons: [
                {
                  text: "About",
                  handler: async () => {
                    await Browser.open({ url: "https://eclub.golf/netscore.html" });
                  },
                },
                {
                  text: "Leaderboards",
                  handler: async () => {
                    window.location.href = "/netscore_challenge_leaderboards";
                  },
                },
                {
                  text: "Schedule",
                  handler: async () => {
                    window.location.href = "/netscore_challenge_checkins";
                  },
                },
                {
                  text: "Set a match",
                  handler: async () => {
                    window.location.href = "/netscore_challenge_create_checkin";
                  },
                },
                {
                  text: "Close",
                  role: "cancel",
                },
              ],
            });
          } else {
            present({
              header: "Net Score Challenge",
              buttons: [
                {
                  text: "About",
                  handler: async () => {
                    await Browser.open({ url: "https://eclub.golf/netscore.html" });
                  },
                },
                {
                  text: "Register",
                  handler: async () => {
                    const q = query(collection(db, "CONFIG"), where('type', '==', "PAYPAL"));
                    const querySnapshot = await getDocs(q);
                    if (querySnapshot && querySnapshot.docs.length > 0) {
                      var obj = {
                        "currency": "USD",
                        "client-id": querySnapshot.docs[0].data().value,
                        "components": "buttons"
                      }
                      setPaypalData(obj);
                      setPaypalPayment(true);
                    } else {
                      setPaypalData({});
                    }
                  }
                },
                {
                  text: "Close",
                  role: "cancel",
                },
              ],
            });
          }
        } else if (league == "tt") {
          setBuyFormat('TwilightTOUR');
          setReferrer('tt');
          if (current_user.tt_validated) {
            present({
              header: "TwilighTOUR",
              buttons: [
                {
                  text: "About",
                  handler: async () => {
                    await Browser.open({ url: "https://eclub.golf/tt.html" });
                  },
                },
                {
                  text: "Leaderboards(Coming soon!)",
                  handler: () => {
                  },
                },

                {
                  text: "Check in",
                  handler: async () => {
                    //window.location.href = "/twilightour_create_checkin";
                  },
                },
                {
                  text: "Close",
                  role: "cancel",
                },
              ],
            });
          } else {
            present({
              header: "TwilighTour",
              buttons: [
                {
                  text: "About",
                  handler: async () => {
                    await Browser.open({ url: "https://eclub.golf/tt.html" });
                  },
                },
                {
                  text: "Register(Coming soon!)",
                  handler: async () => {
                    const q = query(collection(db, "CONFIG"), where('type', '==', "PAYPAL"));
                    const querySnapshot = await getDocs(q);
                    if (querySnapshot && querySnapshot.docs.length > 0) {
                      var obj = {
                        "currency": "USD",
                        "client-id": querySnapshot.docs[0].data().value,
                        "components": "buttons"
                      }
                      setPaypalData(obj);
                      setPaypalPayment(true);
                    } else {
                      setPaypalData({});
                    }
                  },
                },
                {
                  text: "Close",
                  role: "cancel",
                },
              ],
            });
          }
        }
      };

      const openTourActionsheet = async (tour_id: string) => {

        let tourData = tours.filter((x: any) => x.id == tour_id);
        if (tourData.length > 0) {
          setTour(tourData[0]);
          let players = tourData[0].players.filter((x: any) =>
            x.user_id == current_user.user_id
          );
          if (players.length <= 0) {
            present({
              header: tourData[0].tour_name,
              buttons: [
                {
                  text: "Register",
                  handler: async () => {
                    setReferrer('tt');
                    getTourData(tour_id);
                  },
                },
                {
                  text: "Close",
                  role: "cancel",
                },
              ],
            });
          } else {
            if (tourData[0].format === "2~1:1 Matches") {
              present({
                header: tourData[0].tour_name,
                buttons: [
                  {
                    text: "About",
                    handler: async () => {
                      getTourData(tour_id);
                    },
                  },
                  {
                    text: "Leaderboards",
                    handler: async () => {
                      window.location.href = "/twilightour_one_on_oneleaderboards/" + tour_id;
                    },
                  },
                  {
                    text: "Schedule",
                    handler: async () => {
                      window.location.href = "/twilightour_checkins/" + tour_id;
                    },
                  },
                  {
                    text: "Set a match",
                    handler: async () => {
                      window.location.href = "/twilightour_create_checkin/" + tour_id
                    },
                  },
                  {
                    text: "Close",
                    role: "cancel",
                  },
                ],
              });
            } else if (tourData[0].format === "3~Weekly pack leaders") {

            } else if (tourData[0].format === '1~Peoria based') {
              present({
                header: tourData[0].tour_name,
                buttons: [
                  {
                    text: "About",
                    handler: async () => {
                      getTourData(tour_id);
                    },
                  },
                  {
                    text: "Leaderboards",
                    handler: async () => {
                      window.location.href = "/twilightour_leaderboards/" + tour_id;
                    },
                  },
                  {
                    text: "Post score",
                    handler: async () => {
                      validateTtScores(current_user.user_id, "1~Peoria based", tour_id);
                      //window.location.href="/twilightour_create_checkin/"+tour_id
                    },
                  },
                  {
                    text: "Close",
                    role: "cancel",
                  },
                ],
              });
            }
          }
        }
      };
      const showTTPaypalPayment = async (tour: any, chips: any, amount: any) => {
        const q = query(collection(db, "CONFIG"), where('type', '==', "PAYPAL"));
        const querySnapshot = await getDocs(q);
        if (querySnapshot && querySnapshot.docs.length > 0) {
          var obj = {
            "currency": "USD",
            "client-id": querySnapshot.docs[0].data().value,
            "components": "buttons"
          }
          setRequiredData(tour);
          setPaypalData(obj);
          setChipsQty(parseInt(chips) + 100);
          setAmount(parseInt(amount) + 10);
          setPaypalPayment(true);
        } else {
          setPaypalPayment(false);
          setPaypalData({});
          showToast('Error fecthing payment details. Please try again after some time.', 2000);
        }
      }

      const getUserDeatilsFromGHIN = async (user: any) => {
        let ghin_number = user.ghin_number.length > 0 && user.ghin_number !== 0 ? user.ghin_number : 0;
        let last_name = user.last_name.length > 0 ? user.last_name : "";
        let response = await utils.getGolferData(ghin_number, last_name);
        if (response != "error") {
          let golfers = response || { golfers: [{ handicap_index: 0 }] };
          await updateEntry("USER_PROFILE", user.id, {
            handicap_index: golfers.golfers[0] !== undefined ? golfers.golfers[0].handicap_index : 'N/A',
          });
          setHandicap(golfers.golfers[0] !== undefined ? golfers.golfers[0].handicap_index.toString() : 'N/A');
        }
      };
      const allPacksList = async (user_packs: any) => {
        const q = query(
          collection(db, "PACKS"),
          where("pack_id", "!=", "kuFOI2OPKCuhCwrewzBqDI8w71IE")
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot.docs && querySnapshot.docs.length > 0) {
          await Promise.all(
            querySnapshot.docs.map(async (pack) => {
              var doc_item = pack.data();
              doc_item.id = pack.id;
              return doc_item;
            })
          ).then((results) => {
            let new_packs = _.differenceBy(results, user_packs, "pack_id");
            console.log("curr", new_packs);
            setPacksList(new_packs);
          });
        } else {
          console.log("No packs available");
        }
      };
      const [filtered_packs, setFilteredPacks] = useState<any>([]);
      const filterPacks = async (city: string) => {
        if (city === "Any") {
          setFilteredPacks(packs_list);
        } else {
          let filtered_packs = packs_list.filter((x: any) => x.city === city);
          setFilteredPacks(filtered_packs);
        }
      }
      const setPack = async (pack_id: string) => {
        let filtered_pack = packs_list.filter((x: any) => x.pack_id == pack_id);
        if (filtered_pack.length > 0) {
          setSelectedPack(filtered_pack[0]);
        }
      };

      const getGraphData = async (
        holes: number,
        rounds: number,
        user_id: string
      ) => {
        var competition_dates = new Array();
        var competition_points = new Array();
        var competition_scores_birdie = new Array();
        var competition_scores_par = new Array();
        var competition_scores_bogey = new Array();
        var competition_scores_others = new Array();
        var competition_quotas = new Array();

        const scoresQuery = query(
          collection(db, "SCORE_RECORDS"),
          where("created_at", ">", 0),
          where("user_id", "==", user_id || current_user.user_id),
          where("holes", "==", holes),
          where("score_submitted", "==", true)
        );
        const scoresSnapshot = await getDocs(scoresQuery);
        await Promise.all(
          scoresSnapshot.docs.map(async (competition) => {
            var doc_item = competition.data();
            if (doc_item.points) {
              doc_item.total_points = doc_item.points.total_points;
              return doc_item;
            } else {
              return;
            }
          })
        ).then((results) => {
          let filteredResults = results.filter((res) => res);
          var bestRecords = _.sortBy(
            filteredResults,
            (e: any) => e.total_points
          ).reverse();
          bestRecords = _.take(bestRecords, 3);
          bestRecords = _.sortBy(
            bestRecords,
            (e: any) => e.competition_date_timestamp
          ).reverse();
          var recentRecords = _.sortBy(
            filteredResults,
            (e: any) => e.competition_date_timestamp
          ).reverse();
          recentRecords = _.takeRight(recentRecords, 10);

          if (rounds == 3) {
            let state2values;
            bestRecords &&
              bestRecords.forEach((record: any, i) => {
                competition_dates.push(
                  record.competition_date.slice(5, record.competition_date.length)
                );
                competition_points.push(
                  record.points ? record.points.total_points : 0
                );
                competition_quotas.push(record.quota ? record.quota : 0);
                competition_scores_birdie.push(
                  record.points
                    ? record.points.birdie +
                    record.points.eagle +
                    record.points.double_eagle
                    : 0
                );
                competition_scores_par.push(
                  record.points ? record.points.par_value : 0
                );
                competition_scores_bogey.push(
                  record.points ? record.points.bogey : 0
                );
                competition_scores_others.push(
                  record.points
                    ? record.points.double_bogey + record.points.triple_bogey
                    : 0
                );
                if (i == bestRecords.length - 1) {
                  var birdie = Math.round(
                    competition_scores_birdie.reduce((a, b) => a + b, 0) /
                    competition_scores_birdie.length
                  );
                  var par = Math.round(
                    competition_scores_par.reduce((a, b) => a + b, 0) /
                    competition_scores_par.length
                  );
                  var bogey = Math.round(
                    competition_scores_bogey.reduce((a, b) => a + b, 0) /
                    competition_scores_bogey.length
                  );
                  var other =
                    holes == 18
                      ? 18 - (birdie + par + bogey)
                      : 9 - (birdie + par + bogey);
                  state2values = new Array();
                  state2values.push(birdie);
                  state2values.push(par);
                  state2values.push(bogey);
                  state2values.push(other);
                  setState2Values(state2values);
                }
              });
            let stateValues = new Array();
            stateValues.push(competition_dates.reverse());
            stateValues.push(competition_points.reverse());
            stateValues.push(competition_quotas.reverse());
            setStateValues(stateValues);
            state = {
              options: {
                chart: {
                  height: 310,
                  width: 400,
                  id: "line",
                  toolbar: {
                    show: false,
                  },
                },
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    dataLabels: {
                      position: "bottom", // top, center, bottom
                    },
                  },
                },
                curve: "smooth",
                legend: {
                  show: true,
                  fontSize: "15px",
                  type: "string",
                },
                markers: {
                  radius: 4,
                },
                stroke: {
                  width: [0, 4],
                },
                dataLabels: {
                  enabled: true,
                },
                xaxis: {
                  show: true,
                  categories: stateValues[0],
                  labels: {
                    rotate: -45,
                    rotateAlways: true,
                    hideOverlappingLabels: false,
                  },
                },
                yaxis: {
                  show: false,
                },
                // yaxis: [{
                //   title: {
                //     text: '',
                //   },

                // }, {
                //   opposite: true,
                //   title: {
                //     text: ''
                //   }
                // }],
              },
              series: [
                {
                  name: "Score",
                  type: "column",
                  data: stateValues[1],
                },
                {
                  name: "Target",
                  type: "line",
                  data: stateValues[2],
                },
              ],
            };
            state2 = {
              series: state2values,
              options: {
                chart: {
                  width: 320,
                },
                labels: ["Birdie+", "Par", "Bogey", "Other"],
                dataLabels: {
                  formatter: function (val: any, opts: any) {
                    return opts.w.config.series[opts.seriesIndex];
                  },
                },
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      chart: {
                        width: 300,
                      },
                      legend: {
                        position: "top",
                      },
                    },
                  },
                ],
              },
            };
          }
          if (rounds == 10) {
            let state2values;
            recentRecords &&
              recentRecords.forEach((record: any, i) => {
                competition_dates.push(
                  record.competition_date.slice(5, record.competition_date.length)
                );
                competition_points.push(
                  record.points ? record.points.total_points : 0
                );
                competition_quotas.push(record.quota ? record.quota : 0);
                competition_scores_birdie.push(
                  record.points
                    ? record.points.birdie +
                    record.points.eagle +
                    record.points.double_eagle
                    : 0
                );
                competition_scores_par.push(
                  record.points ? record.points.par_value : 0
                );
                competition_scores_bogey.push(
                  record.points ? record.points.bogey : 0
                );
                competition_scores_others.push(
                  record.points
                    ? record.points.double_bogey + record.points.triple_bogey
                    : 0
                );
                if (i == recentRecords.length - 1) {
                  var birdie = Math.round(
                    competition_scores_birdie.reduce((a, b) => a + b, 0) /
                    competition_scores_birdie.length
                  );
                  var par = Math.round(
                    competition_scores_par.reduce((a, b) => a + b, 0) /
                    competition_scores_par.length
                  );
                  var bogey = Math.round(
                    competition_scores_bogey.reduce((a, b) => a + b, 0) /
                    competition_scores_bogey.length
                  );
                  var other =
                    holes == 18
                      ? 18 - (birdie + par + bogey)
                      : 9 - (birdie + par + bogey);
                  state2values = new Array();
                  state2values.push(birdie);
                  state2values.push(par);
                  state2values.push(bogey);
                  state2values.push(other);
                  setState2Values(state2values);
                }
              });
            let stateValues = new Array();
            stateValues.push(competition_dates.reverse());
            stateValues.push(competition_points.reverse());
            stateValues.push(competition_quotas.reverse());
            setStateValues(stateValues);
            state = {
              options: {
                chart: {
                  height: 310,
                  width: 400,
                  id: "line",
                  toolbar: {
                    show: false,
                  },
                },
                plotOptions: {
                  bar: {
                    borderRadius: 5,
                    dataLabels: {
                      position: "bottom", // top, center, bottom
                    },
                  },
                },
                curve: "smooth",
                legend: {
                  show: true,
                  fontSize: "15px",
                  type: "string",
                },
                markers: {
                  radius: 4,
                },
                stroke: {
                  width: [0, 4],
                },
                dataLabels: {
                  enabled: true,
                  // offsetY: -5,
                  // offsetX: -2
                },
                xaxis: {
                  show: true,
                  categories: stateValues[0],
                  labels: {
                    rotate: -45,
                    rotateAlways: true,
                    hideOverlappingLabels: false,
                  },
                },
                yaxis: {
                  show: false,
                },
                // yaxis: [{
                //   title: {
                //     text: '',
                //   },

                // }, {
                //   opposite: true,
                //   title: {
                //     text: ''
                //   }
                // }],
              },
              series: [
                {
                  name: "Score",
                  type: "column",
                  data: stateValues[1],
                },
                {
                  name: "Target",
                  type: "line",
                  data: stateValues[2],
                },
              ],
            };
            state2 = {
              series: state2values,
              options: {
                chart: {
                  width: 320,
                },
                labels: ["Birdie+", "Par", "Bogey", "Other"],
                dataLabels: {
                  formatter: function (val: any, opts: any) {
                    return opts.w.config.series[opts.seriesIndex];
                  },
                },
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      chart: {
                        width: 300,
                      },
                      legend: {
                        position: "top",
                      },
                    },
                  },
                ],
              },
            };
          }
        });
      };
      const deletePendingScore = async (id: string) => {
        await deleteDoc(doc(db, "SCORE_RECORDS", id));
        let newPendingScores = pending_scores.filter((x: any) => x.id != id);
        setPendingScores(newPendingScores);
      };
      const deleteTTPendingScore = async (id: string) => {
        await deleteDoc(doc(db, "SCORE_RECORDS", id));
        let newPendingScores = tt_pending_scores.filter((x: any) => x.id != id);
        setTtPendingScores(newPendingScores);
      };
      const deletePPWPendingScore = async (id: string, scoreId: string) => {
        await deleteDoc(doc(db, "PPW_COMPETITIONS", id));
        await deleteDoc(doc(db, "SCORE_RECORDS", scoreId));
        let newPPWPendingScores = ppwScoredCard.filter((x: any) => x.id != id);
        setPpwScoreCard(newPPWPendingScores);
      };
      const toggleCard = (header: any) => {
        all_rounds && all_rounds.forEach((key: any, i: number) => {
          if (key.id == header) {
            key.toggle = !key.toggle
          }
        })
        // console.log(header,cards)
        setAllRounds(Array.from(new Set([...all_rounds])))
      }
      const [score_modal, setScoreModal] = useState<any>(false)
      const [pending_scores, setPendingScores] = useState<any>([]);
      const [pending_scores_modal, setPendingScoresModal] = useState<any>(false);
      const [tt_pending_scores, setTtPendingScores] = useState<any>([]);
      const [tt_pending_scores_modal, setTtPendingScoresModal] = useState<any>(false);
      const [ppwScoredCard, setPpwScoreCard] = useState<any>(false);
      const [ppwScoreCardModal, setPpwScoreCardModal] = useState<any>(false);
      const [pendingPPWScores, setPendingPpwScores] = useState<any>(false);
      const [ppwScoreId, setPPWScoreId] = useState<String>('');
      const [show_scores_modal, setShowScoresModal] = useState(false);
      const [score_record, setScoreRecord] = useState<any>([]);
      const [edit_hole_details, setEditHoleDetails] = useState<any>([]);
      const [hole_points, setHolePoints] = useState<any>();
      const [holes, setHoles] = useState<any>();
      const [tours, setTours] = useState<any>();
      const [tour_id, setTourId] = useState<any>();
      const [tour, setTour] = useState<any>({
        tour_name: "",
        city: "",
        format: "",
        season_prize_pool: 0,
        weekly_prize_pool: 0,
        fixed_courses: "",
        fixed_tees: "",
        holes: 0,
        players: [],
        state: '',
        selected_courses: [],
        selected_tees: [],
        pull_scores_from: "",
        partner_required: "",
        visibility: "",
        youth_on_course: 0,
        id: "",
        status: ""
      });
      const [show_tour_modal, setShowTourModal] = useState<boolean>(false);
      const validateScores = async (id: any) => {
        const scoresQuery = query(
          collection(db, "SCORE_RECORDS"),
          where("created_at", ">", 0),
          where("user_id", "==", id || current_user.user_id),
          where("score_submitted", "==", false)
        );
        const scoresSnapshot = await getDocs(scoresQuery);
        if (scoresSnapshot.docs.length > 0) {
          await Promise.all(
            scoresSnapshot.docs.map(async (competition) => {
              var doc_item = competition.data();
              doc_item.id = competition.id;
              let courseStr = doc_item.course_name.split("-");
              if (courseStr[0] != undefined || courseStr[0] != null)
                doc_item.course_name = courseStr[0];
              if (courseStr[1] != undefined || courseStr[1] != null)
                doc_item.course_sub_name = courseStr[1];
              return doc_item;
            })
          ).then((results) => {
            setPendingScores(_.sortBy(results, (e: any) => e.created_at).reverse());
            setPendingScoresModal(true);
          });
        } else {
          window.location.href = "/create_score";
        }
      };

      const validateTtScores = async (id: any, type: any, tour_id: any) => {
        setTourId(tour_id);
        const scoresQuery = query(
          collection(db, "SCORE_RECORDS"),
          where("created_at", ">", 0),
          where("user_id", "==", id || current_user.user_id),
          where("score_submitted", "==", false),
          where("format", "==", type)
        );
        const scoresSnapshot = await getDocs(scoresQuery);
        if (scoresSnapshot.docs.length > 0) {
          await Promise.all(
            scoresSnapshot.docs.map(async (competition) => {
              var doc_item = competition.data();
              doc_item.id = competition.id;
              let courseStr = doc_item.course_name.split("-");
              if (courseStr[0] != undefined || courseStr[0] != null)
                doc_item.course_name = courseStr[0];
              if (courseStr[1] != undefined || courseStr[1] != null)
                doc_item.course_sub_name = courseStr[1];
              return doc_item;
            })
          ).then((results) => {
            console.log(
              "pending scores",
              _.sortBy(results, (e: any) => e.created_at).reverse()
            );
            setTtPendingScores(_.sortBy(results, (e: any) => e.created_at).reverse());
            setTtPendingScoresModal(true);
          });
        } else {
          window.location.href = "/twilightour_create_checkin/" + tour_id;
        }
      };
      const fetchPPWScores = async (id: any) => {
        const ppwQuery = query(collection(db, "PPW_COMPETITIONS"), where('user_id', '==', id), where('created_at', '>', 0), where("score_submitted", "==", false), orderBy('created_at', 'desc'));
        const ppwSnapshot = await getDocs(ppwQuery);
        if (ppwSnapshot.docs.length > 0) {
          await Promise.all(ppwSnapshot.docs.map(async competition => {
            var doc_item = competition.data();
            doc_item.id = competition.id;
            //doc_item.ppwScoreId = doc_item.scoreId;

            return doc_item;
            //doc_item.date = format(new Date(doc_item.played_at), "MM-dd-yyyy");
          })
          ).then((results) => {
            setPpwScoreCard(_.sortBy(results, (e: any) => e.created_at).reverse());
            setPendingPpwScores(true);
            //setPpwScoreCardModal(true);
          })
        } else {
          window.location.href = "/add_ppw_checkin";
        }
      };
      const getTours = async (user_id: string) => {
        const q = query(collection(db, "TOUR_SETTINGS"), where('created_at', '>', 0), where('status', '==', 'Approved'));
        const querySnapshot = await getDocs(q);
        await Promise.all(querySnapshot.docs.map(async competition => {
          var doc_item = competition.data();
          doc_item.id = competition.id;
          let players = doc_item.players || [];
          if (players.filter((x: any) => x.user_id === user_id).length <= 0) {
            doc_item.joined = false;
          } else {
            doc_item.joined = true;
          }
          return doc_item;
        }))
          .then(results => {
            setTours(_.sortBy(results, (e: any) => e.created_at).reverse());
          });
      }
      const getTourData = async (tour_id: string) => {
        let tourData = tours.filter((x: any) => x.id == tour_id);
        if (tourData.length > 0) {
          setTour(tourData[0]);
          setShowTourModal(true);
        }
      }

      let state = {
        options: {
          chart: {
            height: 310,
            width: 400,
            id: "line",
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 5,
              dataLabels: {
                position: "bottom", // top, center, bottom
              },
            },
          },
          legend: {
            show: true,
            fontSize: "15px",
            type: "string",
          },
          markers: {
            radius: 4,
          },
          curve: "smooth",
          stroke: {
            width: [0, 4],
          },
          dataLabels: {
            enabled: true,
            // offsetY: -5,
            // offsetX: -2
          },
          xaxis: {
            show: true,
            categories: state_values[0],
            labels: {
              rotate: -45,
              rotateAlways: true,
              hideOverlappingLabels: false,
            },
          },
          yaxis: {
            show: false,
          },
          // yaxis: [{
          //   title: {
          //     text: '',
          //   },

          // }, {
          //   opposite: true,
          //   title: {
          //     text: ''
          //   }
          // }],
        },
        series: [
          {
            name: "Score",
            type: "column",
            data: state_values[1],
          },
          {
            name: "Target",
            type: "line",
            data: state_values[2],
          },
        ],
      };
      let state2 = {
        series: state2_values,
        options: {
          chart: {
            width: 320,
          },
          labels: ["Birdie+", "Par", "Bogey", "Other"],
          dataLabels: {
            formatter: function (val: any, opts: any) {
              return opts.w.config.series[opts.seriesIndex];
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 300,
                },
                legend: {
                  position: "top",
                },
              },
            },
          ],
        },
      };
      const slideOpts = {
        initialSlide: 0,
        speed: 400,
      };
      const [hole, setHole] = useState<any>("18");
      const [rounds, setRounds] = useState<any>("10");
      const customAlertOptions = {
        header: "Holes",
      };
      const customAlertOptions1 = {
        header: "Rounds",
      };
      const customAlertOptions2 = {
        header: "Select pack",
      };
      const customAlertOptions3 = {
        header: "Select city",
      };

      const [tee_times, setTeeTimes] = useState<any>([]);
      const [current_teetime_id, setCurrentTeeTimeId] = useState<any>();
      const [teetime_data, setTeetimeData] = useState<any>([]);
      const [show_teetime_modal, setShowTeetimeModal] = useState(false);

      const acceptTeeTime = async (id: string) => {
        let teetime = tee_times.filter((x: any) => x.id == id);
        if (teetime.length > 0) {
          teetime[0].accepted_users = [
            ...teetime[0].accepted_users,
            ...[
              {
                user_id: current_user.user_id,
                user_name: current_user.first_name + " " + current_user.last_name,
              },
            ],
          ];
          updateEntry("TEE_TIMES", id, {
            accepted_users: teetime[0].accepted_users,
            pending_users: teetime[0].pending_users.filter(
              (user: any) => user.user_id != current_user.user_id
            ),
          });
          presentToast("Sucessfully accepted this tee time.", 2000);
          getTeeTimes();
        }
      };

      const declineTeeTime = async (id: string) => {
        let teetime = tee_times.filter((x: any) => x.id == id);
        if (teetime.length > 0) {
          teetime[0].rejected_users = [...teetime[0].rejected_users,...[
              {
                user_id: current_user.user_id,
                user_name: current_user.first_name + " " + current_user.last_name,
              },
            ],
          ];
          updateEntry("TEE_TIMES", id, {
            rejected_users: teetime[0].rejected_users,
            pending_users: teetime[0].pending_users.filter(
              (user: any) => user.user_id != current_user.user_id
            ),
          });
          presentToast("Sucessfully declined this tee time.", 2000);
          getTeeTimes();
        }
      };

      const openEditTeeTime = async (id: string) => {

      };

      const deleteTeeTime = async (id: string) => {
        await updateEntry("TEE_TIMES", id, { is_active: false });
        let newTeeTimes = tee_times.filter((x: any) => x.id != id);
        setTeeTimes(newTeeTimes);
      };

      const getTeeTimes = async () => {
        const teetimesQuery = query(
          collection(db, "TEE_TIMES"),
          where("created_at", ">", 0),
          where("is_active", "==", true)
        );
        const teetimesSnapshot = await getDocs(teetimesQuery);
        console.log(teetimesSnapshot.docs.length);
        await Promise.all(
          teetimesSnapshot.docs.map(async (teetime) => {
            var doc_item = teetime.data();
            doc_item.id = teetime.id;
            let pendingUser = doc_item.pending_users.filter(
              (user: any) => user.user_id == current_user.user_id
            );
            let acceptedUser = doc_item.accepted_users.filter(
              (user: any) => user.user_id == current_user.user_id
            );
            let rejectedUser = doc_item.rejected_users.filter(
              (user: any) => user.user_id == current_user.user_id
            );
            let courseStr = doc_item.course_name.split("-");
            if (courseStr[0] != undefined || courseStr[0] != null)
              doc_item.course_name = courseStr[0];
            if (courseStr[1] != undefined || courseStr[1] != null)
              doc_item.course_sub_name = courseStr[1];
            doc_item.remaining_signup_spots =
              parseInt(doc_item.available_signup_spots) -
              doc_item.accepted_users.length;
            const commentsQuery = query(
              collection(db, "TEE_TIME_COMMENTS"),
              where("teetime_id", "==", doc_item.id)
            );
            const querySnapshot = await getDocs(commentsQuery);
            doc_item.comments = querySnapshot.docs.length;
            doc_item.commented =
              querySnapshot.docs.filter(
                (doc) => current_user.user_id == doc.data().user_id
              ).length > 0
                ? true
                : false;
            if (doc_item.user_id == current_user.user_id) {
              doc_item.own_event = true;
            } else {
              doc_item.own_event = false;
            }
            if (pendingUser.length > 0) {
              doc_item.responded = false;
              return doc_item;
            } else {
              doc_item.responded = true;
              if (acceptedUser.length > 0) {
                doc_item.response = true;
                return doc_item;
              }
              if (rejectedUser.length > 0) {
                doc_item.response = false;
                return doc_item;
              }
              return doc_item;
            }
          })
        ).then((results) => {
          setTeeTimes(_.sortBy(results, (e: any) => e.created_at).reverse());
        });
      };

      const getTeetimeData = async (teetime_id: string) => {
        let teetimeData = tee_times.filter((x: any) => x.id == teetime_id);
        if (teetimeData.length > 0) {
          setTeetimeData(teetimeData[0]);
          setShowTeetimeModal(true);
        }
      };

      const loadTeeCommentsData = async (teetime_id: string) => {
        const q = query(collection(db, "TEE_TIME_COMMENTS"), where('teetime_id', '==', teetime_id));
        const querySnapshot = await getDocs(q);
        if (querySnapshot && querySnapshot.docs.length > 0) {
          let comments = querySnapshot.docs.map(function (documentSnapshot) {
            var doc_item = documentSnapshot.data();
            doc_item.id = documentSnapshot.id;
            if (doc_item.user_id == current_user.user_id) {
              doc_item.own_comment = true;
            }
            return doc_item;
          });
          comments = _.sortBy(comments, e => e.created_at).reverse();
          setComments(comments);
          setCurrentTeeTimeId(teetime_id);
          setShowCommentsModal(true);
        } else {
          setComments([]);
          setCurrentTeeTimeId(teetime_id);
          setShowCommentsModal(true);
        }
      };

      const makeTeeComment = async () => {
        var comment = {
          user_id: current_user.user_id,
          user_name: current_user.first_name + ' ' + current_user.last_name,
          user_image: current_user.logo_url != undefined ? current_user.logo_url : null,
          city: current_user.city,
          comment: content,
          teetime_id: current_teetime_id,
          created_at: new Date().getTime(),
          updated_at: new Date().getTime()
        }
        await createEntryWithoutId('TEE_TIME_COMMENTS', comment);
        let newComments = comments;
        newComments.push(comment);
        setComments(_.sortBy(comments, (e: any) => e.created_at).reverse());
        setKeyDate(new Date().getTime());
      };


    //-----------------------------------//


    var quota_mapping;
    if(current_user.quota <= 10){
      quota_mapping = "Shoots above 100"
      console.log(quota_mapping)
    }
    else if(current_user.quota >= 11 && current_user.quota <= 13){
      quota_mapping = "Shoots high 90s"
      console.log(quota_mapping)
    }
    else if(current_user.quota >= 14 && current_user.quota <= 16){
      quota_mapping = "Shoots mid 90s"
      console.log(quota_mapping)
    }
    else if(current_user.quota >= 17 && current_user.quota <= 19){
      quota_mapping = "Shoots low 90s"
      console.log(quota_mapping)
    }
    else if(current_user.quota >= 20 && current_user.quota <= 22){
      quota_mapping = "Shoots high 80s"
      console.log(quota_mapping)
    }
    else if(current_user.quota >= 23 && current_user.quota <= 25){
      quota_mapping = "Shoots mid 80s"
      console.log(quota_mapping)
    }
    else if(current_user.quota >= 26 && current_user.quota <= 28){
      quota_mapping = "Shoots low 80s"
      console.log(quota_mapping)
    }
    else if(current_user.quota >= 29 && current_user.quota <= 31){
      quota_mapping = "Shoots high 70s"
      console.log(quota_mapping)
    }
    else if(current_user.quota >= 32 && current_user.quota <= 34){
      quota_mapping = "Shoots mid 70s"
      console.log(quota_mapping)
    }
    else if(current_user.quota >= 35 && current_user.quota <= 37){
      quota_mapping = "Shoots par"
      console.log(quota_mapping)
    }
    else if(current_user.quota >= 38){
      quota_mapping = "Shoots under par"
      console.log(quota_mapping)
    }





    useEffect(() => {
        utils.usgaAdminLogin();
        let user = localStorage.getItem('firebase_user');
        let pack = localStorage.getItem('current_pack');
        if(user != null) {
          if (JSON.parse(user).preferred_tee && JSON.parse(user).preferred_tee == "Back") {
            setTeeValue(1);
          }
          if (JSON.parse(user).preferred_tee && JSON.parse(user).preferred_tee == "Middle Back") {
            setTeeValue(2);
          }
          if (JSON.parse(user).preferred_tee && JSON.parse(user).preferred_tee == "Middle") {
            setTeeValue(3);
          }
          if (JSON.parse(user).preferred_tee && JSON.parse(user).preferred_tee == "Middle Front") {
            setTeeValue(4);
          }
          if (JSON.parse(user).preferred_tee && JSON.parse(user).preferred_tee == "Front") {
            setTeeValue(5);
          }
          setCurrentUser(JSON.parse(user));
          getProfileData(JSON.parse(user));
          setAllClubs(JSON.parse(user).club_details != undefined ? JSON.parse(user).club_details : []);
          setCurrentClub(JSON.parse(user).club_details != undefined ?  JSON.parse(user).club_details[0]: {});
          setUserImage(JSON.parse(user).logo_url);
        }
        let current_pack = localStorage.getItem('current_pack');
        if (current_pack != null) {
          setCurrentPack(JSON.parse(current_pack));
        }
        console.log(all_rounds)


    }, [tee_value])
    return (
        <IonPage>
            <IonToolbar mode="ios" className={isPlatform('ios') ? 'ios-toolbar-top box-shadow': 'toolbar-top box-shadow'}>
                <IonButtons slot="start" style={{padding: '3px 0px 0px 6px'}} onClick={()=>{
                  setShowModal3(true)
                }}>
                  <NoAccounts size="26" className='color-red'></NoAccounts>
                </IonButtons>
                <IonTitle>
                    <IonLabel>
                        <IonText>Profile</IonText>
                    </IonLabel>
                </IonTitle>
                <IonButtons slot="end" style={{padding: '2px 6px 0px 0px'}} onClick={()=>{
                  setShowModal2(true)
                }}>
                  <LockPassword size="26" className='color-blue'></LockPassword>
                </IonButtons>
            </IonToolbar>
            <IonContent>
                <IonGrid>
                    <IonRow className="mt-10">
                        <IonCol size="3" style={{transform: 'translateY(15%)'}}>
                            <IonRow className="justify-content-center ion-text-center mb-10">
                                <>
                                <input ref={hiddenFileInput} style={{display:'none'}} type="file" onChange={handleChange}

                                />
                                </>

                                <IonAvatar className="profile-avatar pointer">
                                {
                                    (user_image)?(
                                        <div>
                                        <a href="/profile"><img src={user_image} className="pro-pic"/></a><br/>
                                        <IonText className="s-12 black wg-600">{current_user.first_name+' '+current_user.last_name}</IonText>
                                        </div>
                                    ):(
                                        <div>
                                        <a href="/profile"><img src={default_profile_logo} className="pro-pic" /></a><br/>
                                        <IonText className="s-12 black wg-600">{current_user.first_name+' '+current_user.last_name}</IonText>
                                        </div>
                                    )
                                }
                                </IonAvatar>
                            </IonRow>
                            <IonRow className="ion-justify-content-center ion-text-center mt-10" style={{padding:"0px 10px 0px 15px"}}>
                                <IonCol className="" style={{padding:"10px 10px 10px 10px"}}>

                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol size="9" className="ion-justify-content-center ion-text-center">
                            <IonCard className="profile-cards mt-10">
                                <IonRow className="ion-justify-content-center">
                                    <IonCol>
                                    <IonText className="s-12 black wg-500"><b>Email: </b></IonText><IonText className="s-12 black wg-500"> {current_user.email}</IonText>
                                    </IonCol>
                                </IonRow>

                                {/* {
                                    (current_user.ghin_validated)?(
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol>
                                                <IonText className="s-12 black wg-500"><b>GHIN: </b></IonText><IonText className="s-12 black wg-500">{current_user.ghin_number}</IonText>
                                            </IonCol>
                                        </IonRow>
                                    ):(
                                        <></>
                                    )
                                } */}
                                <IonRow className="ion-justify-content-center">
                                    <IonCol>
                                    <IonText className="s-12 black wg-500"><b>Referral Id: </b></IonText><IonText className="s-12 color-blue wg-500" onClick={()=>copyReferralId(current_user.referral_id)}>{current_user.referral_id}</IonText><IonIcon size="13" icon={copyOutline} />
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center ion-text-center profile-text mt-10">
                                    <IonText className="pl-5 s-12">
                                       <b>City: </b>{current_user.city}
                                    </IonText>
                                    <IonText className="pl-5 s-12">
                                        <b>Zip: </b>{current_user.zip_code}
                                    </IonText>
                                </IonRow>
                                {/* <IonRow className="ion-justify-content-center ion-text-center profile-text mt-10">
                                    <IonText className="myclub-header pl-5 s-12">
                                        Play.Post.Win: {current_user.ppw_validated ? "Yes" : "No"}
                                    </IonText>
                                    <IonText className="myclub-header pl-5 s-12">
                                        The AmateurOpen: {current_user.tao_validated ? "Yes" : "No"}
                                    </IonText>
                                </IonRow> */}
                                <IonRow className="ion-justify-content-center ion-text-center">
                                    {/* {
                                        (!current_user.ghin_validated || current_user.club_details == undefined)?(
                                            <IonCol>
                                                <IonText className='s-12'>Handicap Index<sup>TM</sup>&nbsp;&nbsp;</IonText>
                                                <span className='create-button-outline color-blue' onClick={()=>{
                                                    setShowModal1(true)
                                                }}>
                                                    Validate
                                                </span>
                                            </IonCol>
                                        ):(
                                            <></>
                                        )
                                    } */}
                                    {/* <IonCol>
                                        <button className='profile-button bg-red white wg-bold' onClick={()=>{
                                            setShowModal2(true)
                                        }}>
                                            Change Password
                                        </button>
                                    </IonCol> */}
                                </IonRow>

                                <IonRow className="ion-justify-content-center ion-text-center mt-10">
                                </IonRow>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <br/><br/>
                <IonGrid style={{ padding: '0px' }}>
                  <div className="score-header">
                    <div className="user-quota-align">
                      <div className="userName-align">
                      <br></br>
                        <IonText className="wg-600 s-18 profile-text" style={{ padding: '10px 0px' }}>
                        </IonText>
                      </div>
                      <div>
                        <IonCard
                          className="bg card"
                          onClick={() => {
                            // getScores("filtered")
                            setShowQuotaModal(true);
                          }}
                        >
                          <div className="quota-text-align">
                            <div>
                              <IonText className="s-18 white wg-600">
                                {current_user.quota}
                              </IonText>{" "}
                              <br></br>
                              <IonText className="s-11 white mrl-10">Quota</IonText>
                              <IonIcon
                                icon={informationCircleOutline}
                                className="white s-15 wg-600 inf-icon"
                              />
                            </div>
                          </div>
                        </IonCard>
                      </div>
                    </div>
                    <div className="user-driving-align">
                      <div className="driving-distance-align">

                      </div>
                      <div>
                      <IonCol className="margin-auto profile-text"  size="15">
                      <br></br>
                      <IonRow className="ion-text-center ion-justify-content-center">
                      <IonText className="s-20"><b>{quota_mapping}</b></IonText>
                      <br></br>
                      </IonRow>
                      </IonCol>
                      <IonRow className="ion-text-center ion-justify-content-center">
                      {
                        (current_user.driving_distance != undefined && current_user.driving_distance.text) ? (

                          <IonText className='s-12'>Driving Distance:&nbsp;&nbsp;{current_user.driving_distance.text}</IonText>

                        ) : (
                          <></>
                        )
                      }
                      </IonRow>
                      </div>
                    </div>
                  </div>

                  {/* <div>
                      {
                      (current_user.driving_distance != undefined && current_user.driving_distance.text) ? (

                          <IonText className='s-12'>Driving Distance:&nbsp;&nbsp;{current_user.driving_distance.text}</IonText>

                      ) : (
                        <></>
                      )
                    }
                      </div> */}

                  {/* <IonRow className="ion-justify-content-center ion-text-center row-8">
                    <IonCol size="5">
                      <IonCard
                        className="bg card"
                        onClick={() => {
                          // getScores("filtered")
                          setShowQuotaModal(true);
                        }}
                      >
                        <div className="mt-10">
                          <IonText className="s-18 white wg-600">
                            {current_user.quota}
                          </IonText>{" "}
                          <br />
                          <IonText className="s-11 white mrl-10">Quota</IonText>
                          <IonIcon
                            icon={informationCircleOutline}
                            className="white s-15 wg-600 inf-icon"
                          />
                        </div>
                      </IonCard>
                    </IonCol>
                    <IonCol className="margin-auto profile-text" size="7">
                      <IonRange min={1} max={5} step={1} color="transparent" value={tee_value} className="custom-profile-ion-range-slider" disabled={true}>
                        <IonText slot="start" className="s-12 wg-600 gray range-left">&#124;</IonText>
                        <IonText slot="end" className="s-12 wg-600 gray range-right">&#124;</IonText>
                      </IonRange>
                      <IonRow className="ion-text-center ion-justify-content-center">
                        <IonText className="s-12">Preferred tees: <b>{current_user.preferred_tee}</b></IonText>
                      </IonRow>
                    </IonCol>
                  </IonRow> */}
                  {/* <IonRow className="display-flex ion-justify-content-center">
                    {
                      (handicap == "N/A") ? (
                        <IonText className="s-14" onClick={() => { window.location.href = "/profile" }}>
                          Handicap Index<sup>&#8482;</sup>: <span className='create-button-outline color-blue'>Validate</span>
                        </IonText>
                      ) : (
                        <IonText className="s-14">
                          Handicap Index<sup>&#8482;</sup>: {handicap}
                        </IonText>
                      )
                    }
                  </IonRow> */}
                </IonGrid>
                <IonModal
                  isOpen={showQuotaModal}
                  showBackdrop={true}
                  className="my-custom-class2"
                  backdropDismiss={true}
                  onDidDismiss={() => setShowQuotaModal(false)}
                  mode="md"
                >
                  <IonHeader mode="ios">
                    <IonToolbar>
                      <IonTitle>Quota</IonTitle>
                    </IonToolbar>
                  </IonHeader>
                  <IonContent>
                    <IonGrid className="pending-height">
                      {/* <IonRow className="ion-justify-content-center ion-text-center">
                            <IonCol size="4" className="pad-0">
                                <IonText className="s-11 black">
                                <br/>
                                <br/>
                                Bogey: 1pt
                                </IonText>
                            </IonCol>
                            <IonCol size="4" className="pad-0">
                                <IonText className="s-11 black">
                                <i>Points scale</i>
                                </IonText><br/>
                                <IonText className="s-11 black">
                                Birdie+: 4 pts
                                </IonText><br/>
                                <IonText className="s-11 black">
                                Double: 0.5 pt
                                </IonText><br/>
                                <IonText className="s-11 black">
                                Triple: -0.5 pt
                                </IonText><br/>
                            </IonCol>
                            <IonCol size="4" className="pad-0">
                                <IonText className="s-11 black">
                                <br/>
                                <br/>
                                Par: 2 pts
                                </IonText>
                            </IonCol>
                        </IonRow> */}
                      <IonCard className="social-card">
                        <IonRow className="ion-justify-content-center ion-text-center bb-line">
                          <IonCol size="6">
                            <IonText className="s-14 wg-600 color-dark-gray">Score</IonText>
                          </IonCol>
                          <IonCol size="6">
                            <IonText className="s-14 wg-600 color-dark-gray">Point Scale</IonText>
                          </IonCol>

                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center bb-line">
                          <IonCol size="6">
                            <IonText className="s-14 color-black wg-500">Birdie+</IonText>
                          </IonCol>
                          <IonCol size="6">
                            <IonText className="s-14 color-black wg-500">4 points</IonText>
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center bb-line">
                          <IonCol size="6">
                            <IonText className="s-14 color-black wg-500">Par</IonText>
                          </IonCol>
                          <IonCol size="6">
                            <IonText className="s-14 color-black wg-500">2 points</IonText>
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center bb-line">
                          <IonCol size="6">
                            <IonText className="s-14 color-black wg-500">Bogey</IonText>
                          </IonCol>
                          <IonCol size="6">
                            <IonText className="s-14 color-black wg-500">1 point</IonText>
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center bb-line">
                          <IonCol size="6">
                            <IonText className="s-14 color-black wg-500">Double</IonText>
                          </IonCol>
                          <IonCol size="6">
                            <IonText className="s-14 color-black wg-500">0 points</IonText>
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center bb-line">
                          <IonCol size="6">
                            <IonText className="s-14 color-black wg-500">Triple-</IonText>
                          </IonCol>
                          <IonCol size="6">
                            <IonText className="s-14 color-black wg-500">-1 points</IonText>
                          </IonCol>
                        </IonRow>
                      </IonCard>
                      <IonRow className="ion-justify-content-center ion-text-center mt-25">
                        <IonText className="s-14">
                          Quota is an indicative target based on past points performance.<br /><br />
                          Quota is reset every Monday to reflect<br />player's best scoring ability week after week<br /><br />
                          It is increased by 1 for every 2 points scored above target<br />and decreased by 1 for every 4 points scored below target
                        </IonText>
                      </IonRow>
                    </IonGrid>
                    <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button">
                      <IonFabButton
                        className="main-fab"
                        onClick={() => {
                          setShowQuotaModal(false);
                        }}
                      >
                        <IonIcon icon={arrowBackOutline} />
                      </IonFabButton>
                    </IonFab>
                  </IonContent>
                </IonModal>
                {/* <IonGrid>
                    {
                        (all_clubs && all_clubs.length > 0)?(
                            <>
                            <IonRow className="mt-10">
                                <IonCol>
                                    <IonText className="s-16 black wg-600">My club</IonText>
                                </IonCol>
                            </IonRow>
                            <IonCard className="profile-cards mt-10" style={{height: 'auto', maxHeight: '200px', overflow: 'auto', boxShadow: '0px 0px 4px 0px #c7c7c7'}}>
                                <IonList mode="ios">
                                    <IonRadioGroup value={selected_club} onIonChange={(e) => {
                                        setSelectedClub(e.detail.value)
                                        changeClub(e.detail.value);
                                    }}>
                                        <IonItem className="s-14">
                                            <IonLabel style={{color: '#00365f', fontWeight: '800'}}>{current_club.club_name}<br/><span className='s-12'>({current_club.membership_rev_date})</span></IonLabel>
                                            <IonRadio slot="end" value={current_club.club_id}/>
                                        </IonItem>
                                        {
                                            all_clubs.club_details && all_clubs.club_details.map((club: any, index:number) => {
                                                return(
                                                    <>
                                                    {
                                                        (current_club.club_id == club.club_id)?(
                                                            <></>
                                                        ):(
                                                            <IonItem key={index} className="s-14">
                                                                <IonLabel>{club.club_name}<br/><span className='s-12'>({club.membership_rev_date})</span></IonLabel>
                                                                <IonRadio slot="end" value={club.club_id} />
                                                            </IonItem>
                                                        )
                                                    }
                                                    </>
                                                )
                                            })
                                        }
                                    </IonRadioGroup>
                                </IonList>
                            </IonCard>
                            </>
                        ):(
                            <></>
                        )
                    }
                    <IonRow className="mt-10">
                        <IonCol>
                            <IonText className="s-16 black wg-600">My pack</IonText>
                        </IonCol>
                    </IonRow>
                    <IonCard className="profile-cards mt-10" style={{height: 'auto', overflow: 'auto', boxShadow: '0px 0px 4px 0px #c7c7c7'}}>
                        <IonList mode="ios">
                            <IonRadioGroup value={selected_pack} onIonChange={(e) => {
                                setSelectedPack(e.detail.value)
                                changePack(e.detail.value);
                            }}>
                                <IonItem className="s-14">
                                    <IonLabel style={{color: '#00365f', fontWeight: '800'}}>{current_pack.pack_name}</IonLabel>
                                    <IonRadio slot="end" value={current_pack.pack_id}/>
                                </IonItem>
                                {
                                    current_user.packs && current_user.packs.map((pack: any, index:number) => {
                                        return(
                                            <div key={index}>
                                            {
                                                (current_pack.pack_id == pack.pack_id)?(
                                                    <></>
                                                ):(
                                                    <IonItem className="s-14">
                                                        <IonLabel>{pack.pack_name}</IonLabel>
                                                        <IonRadio slot="end" value={pack.pack_id} />
                                                    </IonItem>
                                                )
                                            }
                                            </div>
                                        )
                                    })
                                }
                            </IonRadioGroup>
                        </IonList>
                    </IonCard>

                </IonGrid> */}
                <IonGrid className="grid-2 mt-20">
                  <IonCard className="stats-card bg ion-justify-content-center ion-text-center">
                  </IonCard>
                  {
                    all_rounds && all_rounds.map((key: any, i: number) => {
                      return (
                        <IonCard className="social-card score-summary-card" key={i}>
                          <IonRow className="pad-ltb">
                            <IonCol size="8" className="pad-0">
                              <IonText className="color-blue s-15 wg-600">
                                {key.course_name}
                              </IonText>
                              <br />
                              <IonText className="s-12 wg-500">{format(new Date(key.competition_date_timestamp), 'MM/dd/yyyy')} &nbsp;{key.game_date_time} &nbsp;{key.tee}&nbsp;<span className="s-10">{key.course_rating}/{key.slope_rating}/{key.par}</span></IonText>
                            </IonCol>
                            <IonCol size="3" className="pad-0 ion-text-center">
                              <IonText className="color-blue s-15 wg-600">
                                {key.points.total_points}
                              </IonText>
                              <IonText className="s-12 wg-500">/{key.quota}</IonText><br />
                              <IonText className="s-12 wg-500">Score/Target</IonText>
                            </IonCol>
                            {
                              (!key.toggle) ? (
                                <IonCol size="1" className="pad-0" onClick={() => {
                                  toggleCard(key.id)
                                }}>
                                  <IonIcon icon={chevronDownOutline} style={{ fontSize: "18px", marginTop: "5px" }} />
                                </IonCol>
                              ) : (
                                <IonCol size="1" className="pad-0" onClick={() => {
                                  toggleCard(key.id)
                                }}>
                                  <IonIcon icon={chevronUpOutline} style={{ fontSize: "18px", marginTop: "5px" }} />
                                </IonCol>
                              )
                            }
                          </IonRow>
                          {
                            (key.toggle) ? (
                              <IonCardContent style={{ paddingTop: "0px" }}>
                                <IonRow className="ion-justify-content-center ion-text-center" style={{ width: "90%", margin: "auto", borderBottom: "0.5px  solid black" }}>
                                  <IonCol size="2">
                                    <IonText className="s-11">Eagle</IonText><br />
                                    <IonText className="black s-14">{key.points.eagle}</IonText>
                                  </IonCol>
                                  <IonCol size="2">
                                    <IonText className="s-11">Birdie</IonText><br />
                                    <IonText className="black s-14">{key.points.birdie}</IonText>
                                  </IonCol>
                                  <IonCol size="2">
                                    <IonText className="s-11">Par</IonText><br />
                                    <IonText className="black s-14">{key.points.par_value}</IonText>
                                  </IonCol>
                                  <IonCol size="2">
                                    <IonText className="s-11">Bogey</IonText><br />
                                    <IonText className="black s-14">{key.points.bogey}</IonText>
                                  </IonCol>
                                  <IonCol size="2">
                                    <IonText className="s-11">Double</IonText><br />
                                    <IonText className="black s-14">{key.points.double_bogey}</IonText>
                                  </IonCol>
                                  <IonCol size="2">
                                    <IonText className="s-11">Triple</IonText><br />
                                    <IonText className="black s-14">{key.points.triple_bogey}</IonText>
                                  </IonCol>
                                </IonRow>
                                <IonRow className="fr mt-5 mb-5">
                                  <IonText className="s-13 color-blue" onClick={() => {
                                    var score_record = {
                                      hole_details: _.sortBy(key.hole_details, e => e.hole_number),
                                      points: key.points,
                                      total_points: key.total_points,
                                      user_name: key.user_name,
                                      course_name: key.course_name,
                                      tee: key.tee,
                                      holes: key.holes,
                                      format: key.format,
                                      date: format(new Date(key.competition_date_timestamp), "MM/dd/yyyy"),
                                      game_date_time: key.game_date_time
                                    };
                                    setScoreRecord(score_record)
                                    if (key.holes == 9) {
                                      setFrontHoles(key.tee_type == "Front" ? true : false);
                                      setBackHoles(key.tee_type == "Back" ? true : false);
                                    }
                                    setHoles(key.holes);
                                    setEditHoleDetails(score_record.hole_details);
                                    setHolePoints(score_record.points)
                                    setScoreModal(true)
                                  }}>View Scorecard</IonText><IonIcon size="19" icon={chevronForwardOutline} className="color-blue" />
                                </IonRow>
                              </IonCardContent>
                            ) : (
                              <>
                              </>
                            )
                          }
                        </IonCard>
                      )
                    })
                  }
                </IonGrid>
                <IonModal isOpen={show_scores_modal} className="my-custom-class">
                  <>
                    <IonHeader>
                      <IonToolbar>
                        <IonLabel slot="start" className="s-16 w-600 color-blue">{score_record.course_name} - {score_record.tee}</IonLabel>
                      </IonToolbar>
                    </IonHeader>
                    <IonContent>
                      <EditTable
                        options={score_record}
                        options1={edit_hole_details}
                        options2={hole_points}
                        holes={holes}
                        front9={front_holes}
                        back9={back_holes}
                      />
                      <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{ marginBottom: '15px' }}>
                        <IonFabButton
                          className="main-fab"
                          onClick={() => {
                            setShowScoresModal(false)
                          }}
                        >
                          <IonIcon icon={arrowBackOutline} />
                        </IonFabButton>
                      </IonFab>
                    </IonContent>
                  </>
                </IonModal>

                <IonModal isOpen={score_modal} className='my-custom-class' onDidDismiss={() => { setScoreModal(false); }}>
                  <>
                    <IonHeader>
                      <IonToolbar>
                        <IonLabel slot="start" className="s-16 w-600 color-blue">{score_record.course_name} - {score_record.tee}</IonLabel>
                      </IonToolbar>
                    </IonHeader>
                    <IonContent>
                      <Table options={score_record} holes={holes} front9={front_holes} back9={back_holes} />
                      <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{ marginBottom: '15px' }}>
                        <IonFabButton
                          className="main-fab"
                          onClick={() => {
                            setScoreModal(false)
                          }}
                        >
                          <IonIcon icon={arrowBackOutline} />
                        </IonFabButton>
                      </IonFab>
                    </IonContent>
                  </>
                </IonModal>



                <IonModal
                isOpen={showModal1}
                className='profile-modal'
                swipeToClose={true}
                onDidDismiss={() => setShowModal1(false)}>
                <>
                <IonContent>
                <IonGrid className="">
                        <IonRow className="ion-justify-content-center mt-10" style={{padding:"0px 10px 0px 15px"}}>
                            <IonText className="s-15 black wg-600">GHIN Validation</IonText>
                        </IonRow>
                        <IonRow className="ion-justify-content-center mt-10" style={{padding:"0px 10px 0px 15px"}}>
                            <IonItem className="select-dropdown" lines="none">
                                <IonInput type="number" value={ghin_validate} onIonChange={(e: any)=> {setGHINVal(e.target.value)}}
                                className="s-14 custom-input" placeholder="GHIN"></IonInput>
                            </IonItem>
                        </IonRow>
                        <IonRow className="ion-justify-content-center mt-10" style={{padding:"0px 10px 0px 15px"}}>
                            <button className='button-close bg-red white wg-bold' onClick={()=>{
                                setShowModal1(false);
                            }}>
                            Close
                            </button>
                            <button className='button-close bg-blue white wg-bold' onClick={()=>{
                                validateGHIN()
                            }}>
                                Validate
                            </button>
                        </IonRow>
                </IonGrid>
                </IonContent>
                </>
                </IonModal>
                <IonModal
                isOpen={showModal2}
                className='profile-modal-password'
                swipeToClose={true}
                initialBreakpoint={0.3}
                onDidDismiss={() => setShowModal2(false)}>
                <>
                <IonContent>
                <IonGrid className="">
                    <IonRow className="ion-justify-content-center mt-10" style={{padding:"0px 10px 0px 15px"}}>
                        <IonText className="s-15 black wg-600">Change Password</IonText>
                    </IonRow>

                    <IonRow className="ion-justify-content-center mt-10">
                    <IonItem className="select-pack-dropdown" lines="none">
                        <IonInput type="password" value={password} onIonChange={(e: any)=> {setPassword(e.target.value)}}
                        className="s-14 custom-input" placeholder="password"></IonInput>
                    </IonItem>
                    </IonRow>
                    <IonRow className="ion-justify-content-center mt-10">
                    <IonItem className="select-pack-dropdown" lines="none">
                        <IonInput type="password" value={confirm_password} onIonChange={(e: any)=> {setConfirmPassword(e.target.value)}}
                        className="s-14 custom-input" placeholder="confirm password"></IonInput>
                    </IonItem>
                    </IonRow>
                    <IonRow className="justify-content-space-evenly mt-10">
                        <button className='button-close bg-red white wg-bold' onClick={()=>{
                            setShowModal2(false);
                        }}>
                        Close
                        </button>
                        <button className='button-close bg-blue white wg-bold' onClick={()=>{
                            changePassword();
                        }}>
                        Change
                        </button>

                    </IonRow>


                </IonGrid>
                </IonContent>
                </>
                </IonModal>
                <IonModal
                isOpen={showModal3}
                className='profile-modal-password'
                swipeToClose={true}
                initialBreakpoint={0.6}
                onDidDismiss={() => setShowModal3(false)}>
                <>
                <IonContent>
                <IonGrid className="">
                    <IonRow className="ion-justify-content-center mt-10" style={{padding:"0px 10px 0px 15px"}}>
                        <IonText className="s-16 black wg-600">Delete your account</IonText>
                    </IonRow>

                    <IonRow className="ion-justify-content-center mt-10" style={{padding: "0px 6px 0px 6px"}}>
                      <IonText className="s-14 black wg-400">Oh no! Why are we here? It's sad to see you go.</IonText>
                    </IonRow>
                    <IonRow className="ion-justify-content-center mt-10" style={{padding: "0px 6px 0px 6px"}}>
                      <IonText className="s-14 black wg-400">What's next?</IonText>
                    </IonRow>
                    <IonRow className="ion-justify-content-center mt-10" style={{padding: "0px 6px 0px 6px"}}>
                      <IonText className="s-14 black wg-400">1. We are here to make eClubgolf better day by day and we need people like you to be part of it.</IonText>
                    </IonRow>
                    <IonRow className="ion-justify-content-center mt-10" style={{padding: "0px 6px 0px 6px"}}>
                      <IonText className="s-14 black wg-400">2. Alternatively you could write to us at info@eclub.golf if you see there is something wrong or feel we could do better.</IonText>
                    </IonRow>
                    <IonRow className="ion-justify-content-center mt-10" style={{padding: "0px 6px 0px 6px"}}>
                      <IonText className="s-14 black wg-400">3. If you wish to proceed with account deletion anyway, please make sure you have used your balances and earnings. You will not be able to recover once your account is deleted.</IonText>
                    </IonRow>
                    <IonRow className="ion-justify-content-center mt-10" style={{padding: "0px 6px 0px 6px"}}>
                      <IonText className="s-14 black wg-400">4. When you click on Delete anyway, a request will be submitted to us and we will process it in 3 business days.</IonText>
                    </IonRow>
                    <IonRow className="ion-justify-content-center mt-10" style={{padding: "0px 6px 0px 6px"}}>
                      <IonText className="s-14 black wg-400">5. In case you accidentally deleted your account or wish to stay back, you could still withdraw your request within 3 business days.</IonText>
                    </IonRow>
                    <IonRow className="justify-content-space-evenly mt-10">
                        <button className='button-stay bg-blue white wg-bold' onClick={()=>{
                            withdrawRequest();
                        }}>
                        I'd stay!
                        </button>
                        <button className='button-delete bg-red white wg-bold' onClick={()=>{
                            deleteAccount();
                        }}>
                        Delete anyway!
                        </button>

                    </IonRow>


                </IonGrid>
                </IonContent>
                </>
                </IonModal>
                <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '45px'}}>
                    <IonFabButton
                    className="main-fab"
                    onClick={() => {
                        window.history.back()
                    }}
                    >
                    <IonIcon icon={arrowBackOutline} />
                    </IonFabButton>
                </IonFab>
            </IonContent>

        </IonPage>
    );
};
export default Profile;
