import axios from "axios";
import firebase from './firebase.js';
import xml2js from 'xml2js';
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";

const db = getFirestore(firebase);

export const randomString = async(length, chars)=> {
    var mask = '';
    if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
    if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (chars.indexOf('#') > -1) mask += '0123456789';
    if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
    var result = '';
    for (var i = length; i > 0; --i) result += mask[Math.round(Math.random() * (mask.length - 1))];
    return result;
}

export const usgaAdminLogin = async () => {
    // const usga_config_query = query(collection(db, "CONFIG"), where("type", "==", "USGA_API_PRODUCTION"));
    // const querySnapshot = await getDocs(usga_config_query);
    // if(querySnapshot.docs && querySnapshot.docs.length > 0) {
    //     localStorage.setItem('usga_url', querySnapshot.docs[0].data().url);
    //     var data = JSON.stringify({
    //         "user": {
    //             "email": querySnapshot.docs[0].data().email,
    //             "password": querySnapshot.docs[0].data().password,
    //             "remember_me": "false"
    //         }
    //     });
    //     var config = {
    //         method: 'post',
    //         url: querySnapshot.docs[0].data().url+'users/login.json',
    //         headers: { 
    //           'Content-Type': 'application/json',
    //         },
    //         data : data
    //     };
    //     axios(config)
    //     .then(function (response) {
    //         localStorage.setItem('token', response.data.token);
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //     });
    // } else {
    //     console.log("No config data");
    // }
}

export const getGolferData = async (ghinNumber, lastName) => {
    let ghin_number = ghinNumber.length > 0 ? ghinNumber : JSON.parse(localStorage.getItem('firebase_user')).ghin_number;
    let last_name = lastName.length > 0 ? lastName : JSON.parse(localStorage.getItem('firebase_user')).last_name;
    var config = {
        method: 'GET',
        url: localStorage.getItem('usga_url') != null ? localStorage.getItem('usga_url')+"golfers.json?per_page=100&"+
        "page=1&"+
        "from_ghin=true&"+
        "golfer_id="+ghin_number+"&"+
        "last_name="+last_name+"&"+
        "global_search=true&"+
        "status=Active&"+
        "order=ASC" : '',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token')
        }
    };
    let data;
    await axios(config)
    .then(function (response) {
        data = response.data.golfers && response.data.golfers.length > 0 ? response.data : {golfers:[]};
    })
    .catch(function (error) {
        data = "error";
    });
    return data;
}

export const getCourseList = async(state)=> {

    // var config = {
    //     method: 'GET',
    //     url: localStorage.getItem('usga_url') != null ? localStorage.getItem('usga_url')+"courses/search.json?country=USA&state="+state : '',
    //     headers: { 
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer '+localStorage.getItem('token')
    //     }
    // };
    // let data;
    // await axios(config)
    // .then(function (response) {
    //     data = response.data.courses;
    // })
    // .catch(function (error) {
    //     console.log(error);
    // });
    let data;
    const q = query(collection(db, "COURSES"), where('State', '==', state));
    const querySnapshot = await getDocs(q);
    if(querySnapshot.docs && querySnapshot.docs.length > 0) {
        await Promise.all(querySnapshot.docs.map(async pack => {
            var doc_item = pack.data();
            doc_item.id = pack.id;
            return doc_item;
        }))
        .then(results=> {
            data = results;
        });
    } else {
        console.log("No courses available");
    }
    return data;
}

export const getCourseDetails = async(course_id : number)=> {
    // var config = {
    //     method: 'get',
    //     url: localStorage.getItem('usga_url') != null ? localStorage.getItem('usga_url')+"courses/"+course_id+"/tee_set_ratings.json?number_of_holes=18" : '',
    //     headers: { 
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer '+localStorage.getItem('token')
    //     }
    // };
    // let data;
    // await axios(config)
    // .then(function (response) {
    //     data = response.data.TeeSets;
    // })
    // .catch(function (error) {
    //     data = {"error": error};
    // });
    var condition = where("CourseId", "==", course_id);
    const q = query(collection(db, "COURSE DETAILS"), condition);

    const querySnapshot = await getDocs(q);
    var tees = [];
    if(querySnapshot.docs && querySnapshot.docs.length > 0) {
        tees = querySnapshot.docs[0].data().TeeSets;
    }
    return tees;
}

export const getEspnNewsFeed = async()=> {
    var config = {
        method: 'get',
        url: 'https://www.espn.com/espn/rss/golf/news',
        headers: { 
            'Content-Type': 'application/xml',
        }
    };
    let data;
    await axios(config)
    .then(function (response) {
        var parser = xml2js.Parser(
            {
               trim: true,
               explicitArray: true
            });
        var jsonObj = parser.parseString(response.data, function (err, result) {
            console.log(result.rss.channel);
            let postsData = result.rss.channel[0].item;
            data = postsData;
        });
    })
    .catch(function (error) {
        data = {"error": error};
    });
    return data;
}

export const makePaypalPayments = async (data) => {
}

export const getGhinData = async (data) => {
}

export const validateGhin = async (data) => {
}

export const makeGhinPayment = async (data) => {
}

export const createAppNotifications = async (data) => {
}

export const createPushNotifications = async (data) => {
}