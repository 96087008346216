import { IonContent, IonPage, IonTitle, IonToolbar, IonIcon, IonButtons, IonGrid, useIonToast, IonItem, IonRow, IonText, IonSelect, IonSelectOption, IonInput, isPlatform } from '@ionic/react';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { chevronBackOutline, paperPlane } from 'ionicons/icons';
import firebase from '../../js/firebase.js';
import { createEntryWithoutId } from '../../js/firebase_doc_functions';

import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";

const db = getFirestore(firebase);


const JoinPack: React.FC = () => {
    const [selected_pack_id,setSelectedPackId] = useState("");
    const [selected_pack,setSelectedPack] = useState<any>({pack_name: "N/A",
    pack_description: "N/A",
    pack_type: "N/A"});
    const [current_pack,setCurrentPack] = useState<any>();
    const [current_user,setCurrentUser] = useState<any>([]);
    const [packs_list,setPacksList] = useState<any>([]);
    const [present, dismiss] = useIonToast();
    const customAlertOptions = {
        header: 'Select Pack',
    };
    const [pack_message, setPackMessage] = useState<string>();

    const allPacksList = async(user_packs:any)=> {
        const q = query(collection(db, "PACKS"), where('pack_id', '!=', 'kuFOI2OPKCuhCwrewzBqDI8w71IE'));
        const querySnapshot = await getDocs(q);
        if(querySnapshot.docs && querySnapshot.docs.length > 0) {
            await Promise.all(querySnapshot.docs.map(async pack => {
                var doc_item = pack.data();
                doc_item.id = pack.id;
                return doc_item;
            }))
            .then(results=> {
                let new_packs = _.differenceBy(results, user_packs, 'pack_id');
                console.log("curr", new_packs);
                setPacksList(new_packs);
            });
        } else {
            console.log("No packs available");
        }
    }

    const setPack = async(pack_id:string)=> {
        let filtered_pack = packs_list.filter((x:any)=> x.pack_id == pack_id);
        if(filtered_pack.length > 0) {
            setSelectedPack(filtered_pack[0]);
        }
    }

    const sendJoinRequest = async()=> {
        if(selected_pack && selected_pack.pack_name != null) {
            var obj = {
                'created_name': current_user.first_name+' '+current_user.last_name,
                'created_by': current_user.user_id,
                'type': 'JOIN_REQUEST',
                'description': pack_message,
                'source': {
                    'user_name': current_user.first_name+' '+current_user.last_name,
                    'user_id': current_user.user_id,
                },
                'is_read': false,
                'pack_id': selected_pack.pack_id,
                'pack_name': selected_pack.pack_name,
                'user_id': selected_pack.pack_lead_id
            }
            await createEntryWithoutId("PACK_NOTIFICATIONS", obj);
            present('Request sent', 1800);
        }
    }

    useEffect(()=> {
        let user = localStorage.getItem('firebase_user');
        let pack = localStorage.getItem('current_pack');
        if(user != null) {
            setCurrentUser(JSON.parse(user));
            allPacksList(JSON.parse(user).packs);
        }
        if(pack!=null) {
            setCurrentPack(JSON.parse(pack));
        }
    }, [])
    return (
        <IonPage>
            <IonToolbar mode="ios" className={isPlatform('ios') ? 'ios-toolbar-top box-shadow': 'toolbar-top box-shadow' }>
                <IonButtons slot="start">
                    <a onClick={()=> window.location.href="/admin_feed"}>
                        <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                    </a>
                </IonButtons>
                <IonTitle>
                    Join a Pack
                </IonTitle>
            </IonToolbar>
            <IonContent>
                <IonGrid className="">
                    <IonRow className="display-flex ion-justify-content-center ion-text-center">
                        <img src={selected_pack.logo_url}  width="80" height="80"/><br/>
                    </IonRow>
                    <IonRow className="display-flex ion-justify-content-center ion-text-center">
                        <IonText>Pack City: {selected_pack.pack_name}</IonText>
                    </IonRow>
                    // <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    //     <IonText>Pack Type: {selected_pack.pack_type}</IonText>
                    // </IonRow>
                    <IonRow className="display-flex ion-justify-content-center ion-text-center">
                        <IonText>Description: {selected_pack.description}</IonText><br/>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-text-center mt-10">
                        <IonText className="s-18 wg-600 black">Select a Pack to send a join request.</IonText>
                    </IonRow>
                    <IonRow className="ion-justify-content-center mt-10">
                        <IonSelect value={selected_pack_id}
                            interfaceOptions={customAlertOptions}
                            interface="popover"
                            placeholder="Select Pack" className="s-14 select-pack-dropdown" onIonChange={e => {setSelectedPackId(e.detail.value); setPack(e.detail.value)}} mode="ios">
                                {
                                    (packs_list && packs_list.map((pack: any, index:number) => {
                                        return(
                                            <IonSelectOption value={pack.pack_id} key={index}>{pack.pack_name}</IonSelectOption>
                                        )
                                    }))
                                }
                        </IonSelect>
                    </IonRow>
                    <IonRow className="ion-justify-content-center mt-10">
                        <IonItem lines="none" className="select-pack-dropdown" style={{height: "43px"}}>
                            <IonInput
                            value={pack_message}
                            placeholder="Message"
                            onIonChange={(e) => setPackMessage(e.detail.value!)}
                            clearInput
                            ></IonInput>
                        </IonItem>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-text-center mt-25">
                        <button className='button-send white s-15' onClick={sendJoinRequest}>
                            <IonIcon icon={paperPlane} className="white"/>
                            <IonText className="pl-5 send-request">Send Request</IonText>
                        </button>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-text-center mt-25">
                        <IonText>Or you can create your own pack <span className="create-button-outline color-blue" onClick={()=>{
                        window.location.href="/create_pack"
                        }}>here</span></IonText>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};
export default JoinPack;
