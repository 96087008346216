import { format, addDays, subDays, startOfWeek, minutesToHours } from 'date-fns';
import _ from 'lodash';
//import firebase_functions from './firebase_doc_functions';
import { updateEntry, createEntryWithoutId, getEntry } from './firebase_doc_functions';
import firebase from "../js/firebase";

//import { getFirestore, collection, getDocs } from "firebase/firestore";
import {
    getFirestore,
    collection,
    query,
    where,
    doc,
    getDocs,
    deleteDoc,
    orderBy
} from "firebase/firestore";
import { async } from '@firebase/util';
const db = getFirestore(firebase);
const season_name = localStorage.getItem("season_name");
export const postScoresByAdmin = async () => {
}

export const postRevisionScoresByAdmin = async () => {
}

const setDataStoreObject = async () => {
    const configQuery = query(collection(db, "CONFIG"), where("type", "==", "POINTS_CALCULATION"));
    const configSnapShot = await getDocs(configQuery);
    if (configSnapShot && configSnapShot.docs.length > 0) {
        let document = configSnapShot.docs[0].data();
        document.id = configSnapShot.docs[0].id;
        return document;
    }
}

export const generateAmateurOpenLeaderboard = async () => {
    let to_date = startOfWeek(new Date(), { weekStartsOn: 0 });
    let from_date = subDays(to_date, 6);
    to_date = format(to_date, 'yyyy-MM-dd');
    from_date = format(from_date, 'yyyy-MM-dd');
    let configData = await setDataStoreObject();
    const leaderboard_query = query(collection(db, "TAO_WEEKLY_LEADERBOARDS"), where("to_date", "==", to_date), where('holes', '==', 18));
    const leaderboardQuerySnapshot = await getDocs(leaderboard_query);

    if (leaderboardQuerySnapshot.docs && leaderboardQuerySnapshot.docs.length > 0) {
        throw new Error("Leaderboards are already generated for the week");
    } else {
        const competitions_query = query(collection(db, "TAO_COMPETITIONS"), where("competition_date_timestamp", ">=", new Date(from_date).getTime()), where("competition_date_timestamp", "<=", new Date(to_date).getTime()));
        const competitionsQuerySnapshot = await getDocs(competitions_query);
        var competition_records = [];
        if (competitionsQuerySnapshot.docs && competitionsQuerySnapshot.docs.length > 0) {
            competitionsQuerySnapshot.docs.forEach(function (documentSnapshot) {
                var doc_item = documentSnapshot.data();
                doc_item.id = documentSnapshot.id;
                competition_records.push(doc_item);
            });
        }
        if (competition_records.length > 0) {
            console.log("Checkin Records:::::1", competition_records);
            let userGroupedRecords = _.groupBy(competition_records, 'user_id');
            console.log("Checkin Records Users:::::1", userGroupedRecords);
            let checkedCompetitions = {};
            for (var key in userGroupedRecords) {
                let sortedUserMatchRecords = _.sortBy(userGroupedRecords[key], e => e.created_at);
                let weeklyRecords = sortedUserMatchRecords.filter(rec => rec.is_score_added == true);
                var player_list_records = [];
                const player_list_query = query(collection(db, "TAO_PLAYER_COMPETITIORS_LIST"), where("user_id", "==", key),where("season_name","==",season_name));
                const playerListQuerySnapshot = await getDocs(player_list_query);
                if (playerListQuerySnapshot.docs && playerListQuerySnapshot.docs.length > 0) {
                    playerListQuerySnapshot.docs.forEach(function (documentSnapshot) {
                        var doc_item = documentSnapshot.data();
                        doc_item.id = documentSnapshot.id;
                        player_list_records.push(doc_item);
                    });
                }
                if (weeklyRecords.length > 0 && player_list_records.length > 0) {
                    for (var i = 0; i < weeklyRecords.length; i++) {
                        let previousCompetitorsList = player_list_records[0].all_competitors;
                        let isOldCompetitor = previousCompetitorsList.filter(comp => comp.user_id == weeklyRecords[i].individual_players[0].user_id).length > 0 ? false : true;
                        if (isOldCompetitor) {
                            weeklyRecords[i].valid_unique_competition = isOldCompetitor;
                        }
                        if (weeklyRecords.length == i + 1) {
                            checkedCompetitions[key] = weeklyRecords;
                        }
                    }
                } else if (weeklyRecords.length > 0 && player_list_records.length == 0) {
                    for (var i = 0; i < weeklyRecords.length; i++) {
                        weeklyRecords[i].valid_unique_competition = true;
                        if (weeklyRecords.length == i + 1) {
                            checkedCompetitions[key] = weeklyRecords;
                        }
                    }
                }
            };

            let resultCompetitions = [];
            Object.keys(checkedCompetitions).forEach(key => {
                for (var i = 0; i < checkedCompetitions[key].length; i++) {
                    let firstCompetition = checkedCompetitions[key][i];
                    let secondCompetition = {};
                    if (checkedCompetitions[firstCompetition.individual_players[0].user_id].length > 0) {
                        let secondUser = checkedCompetitions[firstCompetition.individual_players[0].user_id][0];
                        if (secondUser.competition_relation_id === firstCompetition.competition_relation_id) {
                            secondCompetition = secondUser;
                        }
                    }
                    //secondCompetition = checkedCompetitions.filter(rec => rec.competition_relation_id == firstCompetition.competition_relation_id && firstCompetition.individual_players[0].user_id == rec.user_id);
                    if (!_.isEmpty(secondCompetition)) {
                        var net_scores = [firstCompetition.net_score, secondCompetition.net_score];
                        var margin = (net_scores.reduce((a, b) => {
                            return a > b ? a - b : b - a
                        }));
                        var winner = firstCompetition.result == 'Won' ? { user_id: firstCompetition.user_id, user_name: firstCompetition.user_name } : { user_id: secondCompetition.user_id, user_name: secondCompetition.user_name };
                        var tied = firstCompetition.result == 'Even' ? true : false;
                        let matchRecord = {
                            "individual_net_scores": [firstCompetition.net_score, secondCompetition.net_score || 0],
                            "players": [
                                {
                                    "user_id": firstCompetition.user_id,
                                    "user_name": firstCompetition.user_name
                                },
                                {
                                    "user_id": firstCompetition.individual_players[0].user_id,
                                    "user_name": firstCompetition.individual_players[0].user_name
                                }],
                            "individual_points": [firstCompetition.points, secondCompetition.points],
                            "round_points": [],
                            "individual_quotas": [firstCompetition.quota, secondCompetition.quota],
                            "results": [],
                            "competition_date": firstCompetition.competition_date,
                            "competition_relation_id": firstCompetition.competition_relation_id,
                            "competition_date_timestamp": firstCompetition.competition_date_timestamp,
                            "ids": [firstCompetition.id, secondCompetition.id || ''],
                            "user_id": firstCompetition.user_id,
                            "user_name": firstCompetition.user_name,
                            "margin": margin,
                            "winner": winner,
                            "abandoned_match": true
                        }
                        if (firstCompetition.valid_unique_competition == true) {
                            updateEntry('TAO_COMPETITIONS', firstCompetition.id, { is_picked: true, is_unique: true });
                        } else {
                            if (i == 0) {
                                updateEntry('TAO_COMPETITIONS', firstCompetition.id, { is_picked: true });
                            }
                        }
                        resultCompetitions.push(matchRecord);
                    } else {
                        var net_scores = [firstCompetition.net_score, secondCompetition.net_score];
                        var margin = (net_scores.reduce((a, b) => a > b ? a - b : b - a));
                        var winner = firstCompetition.result == 'Won' ? { user_id: firstCompetition.user_id, user_name: firstCompetition.user_name } : { user_id: secondCompetition.user_id, user_name: secondCompetition.user_name };
                        var tied = firstCompetition.result == 'Even' ? true : false;
                        if (firstCompetition.valid_unique_competition == true) {
                            updateEntry('TAO_COMPETITIONS', firstCompetition.id, { is_picked: true, is_unique: true });
                        } else {
                            if (i == 0) {
                                updateEntry('TAO_COMPETITIONS', firstCompetition.id, { is_picked: true });
                            }
                        }
                        if (winner.user_id == firstCompetition.user_id) {
                            let matchRecord = {
                                "individual_net_scores": [firstCompetition.net_score, secondCompetition.net_score],
                                "players": [
                                    {
                                        "user_id": firstCompetition.user_id,
                                        "user_name": firstCompetition.user_name
                                    },
                                    {
                                        "user_id": secondCompetition.user_id,
                                        "user_name": secondCompetition.user_name
                                    }],
                                "individual_points": [firstCompetition.points, secondCompetition.points],
                                "round_points": [firstCompetition.round_points, secondCompetition.round_points],
                                "individual_quotas": [firstCompetition.quota, secondCompetition.quota],
                                "results": tied == true ? ["Tied", "Tied"] : ["Won", "Lost"],
                                "competition_date": firstCompetition.competition_date,
                                "competition_relation_id": firstCompetition.competition_relation_id,
                                "competition_date_timestamp": firstCompetition.competition_date_timestamp,
                                "ids": [firstCompetition.id, secondCompetition.id],
                                "user_id": firstCompetition.user_id,
                                "user_name": firstCompetition.user_name,
                                "margin": margin,
                                "winner": tied == true ? '' : winner,
                            }
                            resultCompetitions.push(matchRecord);
                        } else {
                            let matchRecord = {
                                "individual_net_scores": [secondCompetition.net_score, firstCompetition.net_score],
                                "players": [
                                    {
                                        "user_id": secondCompetition.user_id,
                                        "user_name": secondCompetition.user_name
                                    },
                                    {
                                        "user_id": firstCompetition.user_id,
                                        "user_name": firstCompetition.user_name
                                    }],
                                "individual_points": [secondCompetition.points, firstCompetition.points],
                                "round_points": [secondCompetition.round_points, firstCompetition.round_points],
                                "individual_quotas": [secondCompetition.quota, firstCompetition.quota],
                                "results": tied == true ? ["Tied", "Tied"] : ["Won", "Lost"],
                                "competition_date": secondCompetition.competition_date,
                                "competition_relation_id": secondCompetition.competition_relation_id,
                                "competition_date_timestamp": secondCompetition.competition_date_timestamp,
                                "ids": [secondCompetition.id, firstCompetition.id],
                                "user_id": secondCompetition.user_id,
                                "user_name": secondCompetition.user_name,
                                "margin": margin,
                                "winner": tied == true ? '' : winner,
                            }
                            resultCompetitions.push(matchRecord);
                        }
                    }
                }
            });

            console.log("Checkin Records:::::111111", resultCompetitions);

            let groupedMatchRecords = _.groupBy(resultCompetitions, 'competition_relation_id');
            //var groupedLeaderboardRecords = _.orderBy(matchRecords, ['margin'], ['desc']);
            var taoLeaderboardRecords = [];
            var count = 0;
            console.log("Season_name: "+season_name);
            const player_list_query1 = query(collection(db, "TAO_PLAYER_COMPETITIORS_LIST"),where("season_name","==",season_name));
            let playerListQuerySnapshot1 = await getDocs(player_list_query1);
            await new Promise((resolve) => {
                Object.keys(groupedMatchRecords).forEach(async (key) => {
                    try {
                        let competitiorsList = {};
                        let player1 = groupedMatchRecords[key][0].players[0];
                        let player2 = groupedMatchRecords[key][0].players[1];
                        playerListQuerySnapshot1.docs.forEach((playerList) => {
                            if (playerList.data().user_id === player1.user_id) {
                                competitiorsList = playerList.data();
                                competitiorsList.id = playerList.id;
                            }
                        });
                        console.log(competitiorsList);
                        if (!_.isEmpty(competitiorsList)) {
                            let existing_competitor = competitiorsList.all_competitors.filter(competitor => competitor.user_id == player2.user_id);
                            if (existing_competitor.length == 0) {
                                competitiorsList.all_competitors.push({ "user_id": player2.user_id, "user_name": player2.user_name });
                                competitiorsList.unique_competitor_points = competitiorsList.unique_competitor_points < 8 ? competitiorsList.unique_competitor_points + 2 : 8;
                                updateEntry('TAO_PLAYER_COMPETITIORS_LIST', competitiorsList.id, { all_competitors: competitiorsList.all_competitors, unique_competitor_points: competitiorsList.unique_competitor_points });
                            }
                        }
                        else {
                            createEntryWithoutId('TAO_PLAYER_COMPETITIORS_LIST', {
                                "user_id": player1.user_id,
                                "user_name": player1.user_name,
                                "all_competitors": [{
                                    "user_id": player2.user_id,
                                    "user_name": player2.user_name
                                }],
                                "season_name":season_name,
                                "season":5,
                                "unique_competitor_points": 2,
                            });
                        }
                        const player_list_query2 = query(collection(db, "TAO_PLAYER_COMPETITIORS_LIST"), where('user_id', '==', player2.user_id),where("season_name","==",season_name));
                        const playerListQuerySnapshot2 = await getDocs(player_list_query2);
                        if (playerListQuerySnapshot2 && playerListQuerySnapshot2.docs.length > 0) {
                            playerListQuerySnapshot2.docs.map(function (documentSnapshot) {
                                var doc_item = documentSnapshot.data();
                                doc_item.id = documentSnapshot.id;
                                    let existing_competitor = doc_item.all_competitors.filter(competitor => competitor.user_id == player1.user_id);
                                    if (existing_competitor.length == 0) {
                                        doc_item.all_competitors.push({ "user_id": player1.user_id, "user_name": player1.user_name })
                                        doc_item.unique_competitor_points = doc_item.unique_competitor_points < 8 ? doc_item.unique_competitor_points + 2 : 8;
                                        updateEntry('TAO_PLAYER_COMPETITIORS_LIST', doc_item.id, { all_competitors: doc_item.all_competitors, unique_competitor_points: doc_item.unique_competitor_points })
                                    }
                            });
                        } else {
                            createEntryWithoutId('TAO_PLAYER_COMPETITIORS_LIST', {
                                "user_id": player2.user_id,
                                "user_name": player2.user_name,
                                "all_competitors": [{
                                    "user_id": player1.user_id,
                                    "user_name": player1.user_name
                                }],
                                "unique_competitor_points": 2,
                                "season_name":season_name,
                                "season":5
                            });
                        }
                        taoLeaderboardRecords.push(groupedMatchRecords[key][0]);
                    } catch (e) {
                        console.log(e);
                        console.log("Leaderboard creation failed");
                    } finally {
                        count += 1;
                        if (count == Object.keys(groupedMatchRecords).length) {
                            if (taoLeaderboardRecords.length > 0) {
                                taoLeaderboardRecords = _.orderBy(taoLeaderboardRecords, ['margin'], ['desc']);
                                console.log("1 Scores log filtered:::::", taoLeaderboardRecords);
                                if (taoLeaderboardRecords.length > 0) {
                                    createEntryWithoutId("TAO_WEEKLY_LEADERBOARDS", {
                                        type: "INDIVIDUAL",
                                        season_type: "WEEKLY",
                                        holes: 18,
                                        to_date: to_date,
                                        from_date: from_date,
                                        records: taoLeaderboardRecords,
                                        season: 5,
                                        season_name: season_name,
                                        is_admin: false,
                                        is_active: true
                                    });
                                }
                                console.log("Leaderboard created successfully");
                            } else {
                                console.log("No records to create Leaderboard");
                            }
                            resolve();
                        }
                    }
                });
            });
        } else {
            throw new Error("No competition records available for the week")
        }
    }
}

export const generateAmateurOpenSeasonLeaderboard = async () => {
    let to_date = format(startOfWeek(new Date(), { weekStartsOn: 0 }), 'yyyy-MM-dd'),
    from_date = '2022-04-17';
    let seasonal_record = {
        type: "INDIVIDUAL",
        season_type: "SEASONAL",
        to_date: to_date,
        from_date: from_date,
        users: [],
        other_users: [],
        holes: 18,
        season: 5,
        season_name: season_name,
        is_admin: false,
        is_active: true
    };
    const competitions_query = query(collection(db, "TAO_COMPETITIONS"), where("competition_date_timestamp", ">=", new Date(from_date).getTime()), where("competition_date_timestamp", "<=", new Date(to_date).getTime()), where('is_picked', '==', true));
    const competitionsQuerySnapshot = await getDocs(competitions_query);
    var competition_records = [];
    if (competitionsQuerySnapshot.docs && competitionsQuerySnapshot.docs.length > 0) {
        competitionsQuerySnapshot.docs.forEach(function (documentSnapshot) {
            var doc_item = documentSnapshot.data();
            doc_item.id = documentSnapshot.id;
            competition_records.push(doc_item);
        });
    }
    if (competition_records.length > 0) {
        console.log("Checkin Records:::::1", competition_records);
        let userGroupedRecords = _.groupBy(competition_records, 'user_id');
        console.log("Checkin Records Users:::::1", userGroupedRecords);
        let resultCompetitions = [];
        Object.keys(userGroupedRecords).forEach(key => {
            var newUser = {
                "user_id": userGroupedRecords[key][0].user_id,
                "user_name": userGroupedRecords[key][0].user_name,
                "seasonal_points": 0,
                "competitions_length": userGroupedRecords[key].length,
                "picked_competitions": [],
                "unpicked_competitions": [],
                "wonCount": userGroupedRecords[key].filter(rec => rec.result == "Won").length,
                "lostCount": userGroupedRecords[key].filter(rec => rec.result == "Lost").length,
                "tiedCount": userGroupedRecords[key].filter(rec => rec.result == "Even").length
            };
            if (userGroupedRecords[key].length >= 6) {
                let recs = _.sortBy(userGroupedRecords[key], e => e.round_points).reverse();
                recs.length = 6;
                var length = 6;
                recs.forEach(userRec => {
                    newUser.seasonal_points += parseInt(userRec.round_points);
                    newUser.picked_competitions.push({
                        'competition_id': userRec.id,
                        'user_id': userRec.individual_players[0].user_id,
                        'user_name': userRec.individual_players[0].user_name
                    });
                });

            } else {
                let recs = _.sortBy(userGroupedRecords[key], e => e.round_points).reverse();
                recs.forEach(userRec => {
                    newUser.seasonal_points += parseInt(userRec.round_points);
                    newUser.picked_competitions.push({
                        'competition_id': userRec.id,
                        'user_id': userRec.individual_players[0].user_id,
                        'user_name': userRec.individual_players[0].user_name
                    });
                });
            }

            resultCompetitions.push(newUser);
        });

        var taoSeasonLeaderboardRecords = [];
        for (var user of resultCompetitions) {
            const player_list_query = query(collection(db, "TAO_PLAYER_COMPETITIORS_LIST"), where('user_id', '==', user.user_id), where("season_name", "==", season_name));
            const playerListQuerySnapshot = await getDocs(player_list_query);
            if (playerListQuerySnapshot.docs && playerListQuerySnapshot.docs.length > 0) {
                var doc_item = playerListQuerySnapshot.docs[0].data();
                user.seasonal_points += doc_item.unique_competitor_points;
                taoSeasonLeaderboardRecords.push(user);
            }
        }
        //seasonal_record.other_users = taoSeasonLeaderboardRecords.filter((user) => {user.competitions_length < 6});
        //seasonal_record.users = taoSeasonLeaderboardRecords.filter(user => user.competitions_length >= 6);
        //seasonal_record.users = taoSeasonLeaderboardRecords;
        seasonal_record.users = _.orderBy(taoSeasonLeaderboardRecords, ['seasonal_points'], ['desc']);

        let rank_index = 0;
        let current_index = 0;
        let previous_points = 0;
        for (var user of seasonal_record.users) {
            if (user.seasonal_points == previous_points) {
                if (rank_index == 0) {
                    seasonal_record.users[current_index].position = rank_index + 1;
                } else {
                    seasonal_record.users[current_index].position = "T" + rank_index;
                    seasonal_record.users[current_index - 1].position = "T" + rank_index;
                }
            } else {
                previous_points = user.seasonal_points;
                seasonal_record.users[current_index].position = current_index + 1;
                rank_index = current_index + 1;
            }
            current_index++;
        }


        await createEntryWithoutId("TAO_SEASON_LEADERBOARDS", seasonal_record);

        console.log("Season leaderboard generated successfully");
    } else {
        throw new Error("No competitions available for the week");
    }
}

export const generatePlayPostWinLeaderboard = async () => {
    let to_date = startOfWeek(new Date(), { weekStartsOn: 0 });
    let from_date = subDays(to_date, 6);
    to_date = format(to_date, 'yyyy-MM-dd');
    from_date = format(from_date, 'yyyy-MM-dd');
    let configData = await setDataStoreObject();
    const ppw_leaderboard_query = query(collection(db, "WEEKLY_PPW_LEADERBOARDS"), where("season_type", "==", 'WEEKLY'), where("to_date", "==", to_date));
    const ppwLeaderboardQuerySnapshot = await getDocs(ppw_leaderboard_query);

    let ppwLeaderboard = ppwLeaderboardQuerySnapshot.docs && ppwLeaderboardQuerySnapshot.docs.length > 0 ? true : false;
    if (ppwLeaderboard) {
        throw new Error("Leaderboard is already created for this week!");
    } else {
        var randomHolesFront = _.sampleSize([1, 2, 3, 4, 5, 6, 7, 8, 9], 6);
        var randomHolesBack = _.sampleSize([10, 11, 12, 13, 14, 15, 16, 17, 18], 6);
        var randomHoles = (randomHolesFront.concat(randomHolesBack)).sort((a, b) => b - a);
        const ppw_users_query = query(collection(db, "USER_PROFILE"), where("ppw_validated", "==", true));
        const ppwUsersQuerySnapshot = await getDocs(ppw_users_query);
        var results = [];
        if (ppwUsersQuerySnapshot.docs && ppwUsersQuerySnapshot.docs.length > 0) {
            ppwUsersQuerySnapshot.docs.forEach(function (documentSnapshot) {
                var doc_item = documentSnapshot.data();
                results.push(doc_item);
            });
        }

        if (results.length > 0) {
            await Promise.all(results.map(async user => {
                user.scores = [];
                const getScores = query(collection(db, "SCORE_RECORDS"), where("user_id", "==", user.user_id), where('competition_date_timestamp', '>=', new Date(from_date).getTime()), where('competition_date_timestamp', '<=', new Date(to_date).getTime()), where('holes', '==', 18));
                const scoresQuerySnapshot = await getDocs(getScores);
                if (scoresQuerySnapshot.docs && scoresQuerySnapshot.docs.length > 0) {
                    for (var i = 0; i < scoresQuerySnapshot.docs.length; i++) {
                        let score_item = scoresQuerySnapshot.docs[i].data();
                        score_item.id = scoresQuerySnapshot.docs[i].id;
                        console.log("score_item", score_item);
                        if (score_item.score_submitted && score_item.hole_details.length == 18) {
                            var selected_hole_points = {
                                "triple_bogey": 0,
                                "double_bogey": 0,
                                "bogey": 0,
                                "par_value": 0,
                                "birdie": 0,
                                "eagle": 0,
                                "double_eagle": 0,
                            };

                            var all_hole_points = {
                                "triple_bogey": 0,
                                "double_bogey": 0,
                                "bogey": 0,
                                "par_value": 0,
                                "birdie": 0,
                                "eagle": 0,
                                "double_eagle": 0,
                            };

                            var total_points = 0;
                            var peoria_points = 0;//peoria target
                            var par_value = 0

                            score_item.leaderboard_holes = score_item.hole_details.map(hole => {
                                if (randomHoles.indexOf(hole.hole_number) > -1) {
                                    if ((hole.raw_score - hole.par) > 2) {
                                        hole.points = configData.triple_bogeys;
                                        hole.points_type = "triple_bogey";
                                        all_hole_points.triple_bogey++;
                                        selected_hole_points.triple_bogey++;
                                    } else if ((hole.raw_score - hole.par) == 2) {
                                        hole.points = configData.double_bogeys;
                                        hole.points_type = "double_bogey";
                                        all_hole_points.double_bogey++;
                                        selected_hole_points.double_bogey++;
                                    } else if ((hole.raw_score - hole.par) == 1) {
                                        hole.points = configData.bogeys;
                                        hole.points_type = "bogey";
                                        all_hole_points.bogey++;
                                        selected_hole_points.bogey++;
                                    } else if ((hole.raw_score - hole.par) == 0) {
                                        hole.points = configData.pars;
                                        hole.points_type = "par_value";
                                        all_hole_points.par_value++;
                                        selected_hole_points.par_value++;
                                    } else if ((hole.raw_score - hole.par) == -1) {
                                        hole.points = configData.birdies;
                                        hole.points_type = "birdie";
                                        all_hole_points.birdie++;
                                        selected_hole_points.birdie++;
                                    } else if ((hole.raw_score - hole.par) == -2) {
                                        hole.points = configData.eagles;
                                        hole.points_type = "eagle";
                                        all_hole_points.eagle++;
                                        selected_hole_points.eagle++;
                                    } else if ((hole.raw_score - hole.par) == -3) {
                                        hole.points = configData.eagles;
                                        hole.points_type = "double_eagle";
                                        all_hole_points.double_eagle++;
                                        selected_hole_points.double_eagle++;
                                    }

                                    total_points += hole.points;
                                    peoria_points += hole.points;
                                    hole.is_picked = true;
                                    par_value += hole.par;
                                    return hole;
                                } else {
                                    if ((hole.raw_score - hole.par) > 2) {
                                        hole.points = configData.triple_bogeys;
                                        hole.points_type = "triple_bogey";
                                        all_hole_points.triple_bogey++;
                                    } else if ((hole.raw_score - hole.par) == 2) {
                                        hole.points = configData.double_bogeys;
                                        hole.points_type = "double_bogey";
                                        all_hole_points.double_bogey++;
                                    } else if ((hole.raw_score - hole.par) == 1) {
                                        hole.points = configData.bogeys;
                                        hole.points_type = "bogey";
                                        all_hole_points.bogey++;
                                    } else if ((hole.raw_score - hole.par) == 0) {
                                        hole.points = configData.pars;
                                        hole.points_type = "par_value";
                                        all_hole_points.par_value++;
                                    } else if ((hole.raw_score - hole.par) == -1) {
                                        hole.points = configData.birdies;
                                        hole.points_type = "birdie";
                                        all_hole_points.birdie++;
                                    } else if ((hole.raw_score - hole.par) == -2) {
                                        hole.points = configData.eagles;
                                        hole.points_type = "eagle";
                                        all_hole_points.eagle++;
                                    } else if ((hole.raw_score - hole.par) == -3) {
                                        hole.points = configData.eagles;
                                        hole.points_type = "double_eagle";
                                        all_hole_points.double_eagle++;
                                    }

                                    total_points += hole.points;
                                    hole.is_picked = false;
                                    par_value += hole.par;
                                    return hole;
                                }
                            });
                            let peoria_quota = Math.floor(peoria_points * 1.5);//peoria target
                            let finalTarget = peoria_quota > score_item.quota ? peoria_quota : score_item.quota;
                            score_item.net_score = Math.floor(total_points - peoria_quota);
                            score_item.hole_details = score_item.leaderboard_holes;

                            score_item.total_points = Number(total_points);
                            score_item.peoria_points = Number(peoria_points);
                            score_item.peoria_quota = Number(peoria_quota);
                            //createEntryWithoutId('PPW_COMPETITIONS', score_item);
                            user.scores.push(score_item);

                        }

                    }
                }
                return user;
            }))
                .then(results => {
                    let leaderboard_users = results.map(user => {
                        if (user && user.scores.length > 0) {
                            let scores = _.orderBy(user.scores, ['net_score'], ['desc']);
                            let userObj = {
                                "slope_rating": scores[0].slope_rating,
                                "course_rating": scores[0].course_rating,
                                "hole_details": scores[0].hole_details,
                                "course_handicap": scores[0].course_handicap ? scores[0].course_handicap : "",
                                "competition_quota": scores[0].quota,
                                "total_points": Number(scores[0].total_points),
                                "peoria_points": Number(scores[0].peoria_points),
                                "peoria_quota": Number(scores[0].peoria_quota),
                                "quota": scores[0].quota,
                                "net_score": Number(scores[0].net_score),
                                "first_name": user.first_name,
                                "last_name": user.last_name,
                                "ghin_number": user.ghin_number,
                                "city": user.city,
                                "course_display_value": scores[0].course_name,
                                "handicap_index": user.handicap_index,
                                "ppw_exceptional_score_adjustment": user.ppw_exceptional_score_adjustment,
                                "user_id": scores[0].user_id,
                                "played_at": scores[0].competition_date,
                                "tee_name": scores[0].tee,
                                "points": scores[0].points,
                                "holes": scores[0].holes
                            }
                            return userObj;
                        }
                    }).filter(user => user);

                    let leaderboard_records = _.orderBy(leaderboard_users, ['net_score'], ['desc']);
                    if (leaderboard_records.length > 2) {

                        let current_index = 0;
                        for (var user of leaderboard_records) {
                            leaderboard_records[current_index].index = current_index;
                            leaderboard_records[current_index].seasonal_points = Math.round(((((-(leaderboard_records.length) * 100) - (leaderboard_records.length)) / leaderboard_records.length) * current_index) + (leaderboard_records.length * 100));
                            current_index++;
                        };

                        var rawQuotient = leaderboard_records.length / 3;
                        var remainder = rawQuotient % 1;
                        var quotient = rawQuotient - remainder;
                        let medal_user_set = leaderboard_records.slice(0, quotient);

                        let rank_index = 0;
                        current_index = 0;
                        let previous_points = 0;
                        let net_score_idx = 0;

                        for (var record of leaderboard_records) {
                            if (record.net_score == previous_points) {
                                if (rank_index == 0) {
                                    leaderboard_records[current_index].position = rank_index + 1;
                                    leaderboard_records[current_index].medal_type = net_score_idx + 1;
                                } else {
                                    if (net_score_idx < 3) {
                                        leaderboard_records[current_index].medal_type = net_score_idx;
                                        leaderboard_records[current_index - 1].medal_type = net_score_idx;
                                    }
                                    leaderboard_records[current_index].position = "T" + rank_index;
                                    leaderboard_records[current_index - 1].position = "T" + rank_index;
                                }
                            } else {
                                previous_points = record.net_score;
                                if (net_score_idx < 3) {
                                    leaderboard_records[current_index].medal_type = net_score_idx + 1;
                                    net_score_idx = net_score_idx + 1;
                                } else {
                                    leaderboard_records[current_index].medal_type = 0;
                                }

                                leaderboard_records[current_index].position = current_index + 1;
                                rank_index = current_index + 1;
                            }
                            current_index++;
                        }

                        let individualPointsRecords = _.groupBy(leaderboard_records, 'position');
                        Object.keys(individualPointsRecords).forEach(key => {
                            let rec_lg = individualPointsRecords[key].length;
                            let seasonal_points = (individualPointsRecords[key].map(e => e.seasonal_points).reduce((a, b) => a + b, 0)) / rec_lg;
                            individualPointsRecords[key].forEach(individual_user => {
                                leaderboard_records[individual_user.index].seasonal_points = seasonal_points.toFixed(0);
                            });
                        });

                        if (leaderboard_records.length) {
                            createEntryWithoutId("WEEKLY_PPW_LEADERBOARDS", {
                                type: "PPW",
                                season_type: "WEEKLY",
                                holes: 18,
                                to_date: to_date,
                                from_date: from_date,
                                records: leaderboard_records,
                                season: 5,
                                season_name: season_name,
                                is_admin: false,
                                is_active: true,
                                leaderboard_generated_timestamp: new Date(to_date).getTime()
                            });
                        }
                        console.log("Leaderboard created successfully");
                    } else {
                        throw new Error("Minumum 3 players are needed to generate a leaderboard.");

                    }
                });
        }
        else {
            throw new Error("No users found for play post win");
        }

    }
}

export const generatePlayPostWinSeasonLeaderboard = async () => {
    let to_date = format(startOfWeek(new Date(), { weekStartsOn: 0 }), 'yyyy-MM-dd'),
        from_date = '2022-04-17';
    let weekly_count = 0;

    const ppw_leaderboard_query = query(collection(db, "WEEKLY_PPW_LEADERBOARDS"), where("season_type", "==", 'WEEKLY'), where("leaderboard_generated_timestamp", ">=", new Date(from_date).getTime()), where("leaderboard_generated_timestamp", "<=", new Date(to_date).getTime()));
    const ppwLeaderboardQuerySnapshot = await getDocs(ppw_leaderboard_query);
    var ppw_leaderboard_records = [];
    if (ppwLeaderboardQuerySnapshot.docs && ppwLeaderboardQuerySnapshot.docs.length > 0) {
        ppwLeaderboardQuerySnapshot.docs.forEach(function (documentSnapshot) {
            var doc_item = documentSnapshot.data();
            doc_item.id = documentSnapshot.id;
            ppw_leaderboard_records.push(doc_item);
        });
    }

    let seasonal_record = {
        type: "PPW",
        season_type: "SEASONAL",
        to_date: to_date,
        from_date: from_date,
        users: [],
        other_users: [],
        season: 5,
        season_name: season_name,
        is_admin: false,
        is_active: true
    }, users = []


    ppw_leaderboard_records.forEach(record => {
        users = [...record.records, ...users];
    });
    users = _.groupBy(users, 'user_id');
    Object.keys(users).forEach((key, index) => {
        let userRecs = users[key], length = users[key].length;
        let user = userRecs[0];
        user = {
            "index": index,
            "position": index + 1,
            "earnings": 0,
            "seasonal_points": 0,
            "total_seasonal_points": 0,
            "net_score": user.net_score.toString(),
            "played_at": user.played_at,
            "course_display_value": user.course_display_value,
            "tee_name": user.tee_name,
            "season_type": "SEASONAL",
            "user_id": user.user_id,
            "user_name": user.first_name + ' ' + user.last_name,
            "city": user.city || '',
            "ghin_number": user.ghin_number,
            "handicap_index": user.handicap_index || '',
            "quota": user.quota || 0,
            "medals": {
                "gold": 0,
                "silver": 0,
                "bronze": 0
            },
            "competitions_length": length
        };

        if (userRecs.length >= 8) {
            let recs = _.sortBy(userRecs, e => parseInt(e.seasonal_points)).reverse();
            recs.length = 8;
            length = 8;
            let pointsRecs = recs;
            pointsRecs.forEach(userRec => {
                user.seasonal_points += parseInt(userRec.seasonal_points);
            });
            userRecs.forEach(userRec => {
                userRec.earnings = userRec.earnings || 0;
                user.earnings += userRec.earnings;
            });
        } else {
            length = users[key].length;
            userRecs.forEach(userRec => {
                userRec.earnings = userRec.earnings || 0;
                user.earnings += userRec.earnings;
                user.seasonal_points += parseInt(userRec.seasonal_points);
            });
        }

        userRecs.forEach(userRec => {
            if (userRec.medal_type == 1) {
                user.medals.gold = user.medals.gold + 1;
            }
            if (userRec.medal_type == 2) {
                user.medals.silver = user.medals.silver + 1;
            }
            if (userRec.medal_type == 3) {
                user.medals.bronze = user.medals.bronze + 1;
            }
        });
        user.total_seasonal_points = parseInt(user.seasonal_points);
        user.competitions_length = length;
        seasonal_record.users.push(user);
    });

    seasonal_record.users = _.orderBy(seasonal_record.users, ['total_seasonal_points'], ['desc']);

    let rank_index = 0;
    let current_index = 0;
    let previous_points = 0;
    for (var user of seasonal_record.users) {
        if (user.total_seasonal_points == previous_points) {
            if (rank_index == 0) {
                seasonal_record.users[current_index].position = rank_index + 1;
            } else {
                seasonal_record.users[current_index].position = "T" + rank_index;
                seasonal_record.users[current_index - 1].position = "T" + rank_index;
            }
        } else {
            previous_points = user.total_seasonal_points;
            seasonal_record.users[current_index].position = current_index + 1;
            rank_index = current_index + 1;
        }
        current_index++;
    }

    await createEntryWithoutId("PPW_SEASON_LEADERBOARDS", seasonal_record);

    console.log("Season leaderboard generated successfully");
}

export const weeklyQuotaAdjustment = async () => {
    var to_date = format(startOfWeek(new Date(), { weekStartsOn: 0 }), 'YYYY-MM-DD'),
        from_date = format(subDays(format(startOfWeek(new Date(), { weekStartsOn: 0 }), 'YYYY-MM-DD'), 6), 'YYYY-MM-DD');

    const ppw_users_query = query(collection(db, "USER_PROFILE"));
    const ppwUsersQuerySnapshot = await getDocs(ppw_users_query);
    var results = [];
    if (ppwUsersQuerySnapshot.docs && ppwUsersQuerySnapshot.docs.length > 0) {
        ppwUsersQuerySnapshot.docs.forEach(function (documentSnapshot) {
            var doc_item = documentSnapshot.data();
            results.push(doc_item);
        });
    }
    if (results.length > 0) {
        await Promise.all(results.map(async user => {
            const getScores = query(collection(db, "SCORE_RECORDS"), where("user_id", "==", user.user_id), where('competition_date_timestamp', '>', from_date), where('competition_date_timestamp', '<', to_date));
            const scoresQuerySnapshot = await getDocs(getScores);
            if (scoresQuerySnapshot.docs && scoresQuerySnapshot.docs.length > 0) {
                for (var i = 0; i < scoresQuerySnapshot.docs.length; i++) {
                    let score_item = scoresQuerySnapshot.docs[i].data();
                    user.scores.push(score_item);
                }
            }
        }))
            .then(async results => {
                let leaderboard_users = results.map(async user => {
                    if (user.scores.length > 0) {
                        let scores = _.orderBy(user.scores, ['net_score'], ['desc']);
                        let quota = user.quota
                        if (scores[0].net_score > 0) {
                            quota = user.quota + ((scores[0].net_score / 2) >> 0);
                        } else {
                            quota = user.quota + ((scores[0].net_score / 4) >> 0);
                        }
                        await updateEntry("USER_PROFILE", user.id, { quota: quota });
                        return user;
                    }
                }).filter(user => user);
            });
    }
    else {
        console.log("No users found");
    }

}

export const generateTTPeoriaLeaderBoards = async (type) => {
    console.log(type);
    let to_date = startOfWeek(new Date(), { weekStartsOn: 0 });
    let from_date = subDays(to_date, 6);
    let q_to_date = format(to_date, 'yyyy-MM-dd 24:00:00');
    to_date = format(to_date, 'yyyy-MM-dd');
    from_date = format(from_date, 'yyyy-MM-dd');
    
    console.log("StartDate: "+ from_date);
    console.log(new Date(from_date).getTime())
    console.log("End Date: "+to_date);
    console.log(new Date(to_date).getTime());


    const tt_leaderboard_query = query(collection(db, "TT_WEEKLY_PEORIA_LEADERBOARDS"), where("season_type", "==", 'WEEKLY'), where("to_date", "==", to_date), where("tour_id", "==", type.id));
    const ttLeaderboardQuerySnapshot = await getDocs(tt_leaderboard_query);

    let ttLeaderboard = ttLeaderboardQuerySnapshot.docs && ttLeaderboardQuerySnapshot.docs.length > 0 ? true : false;
    if (ttLeaderboard) {
        throw new Error("Leaderboard is already created for this week!");
    }

    let configData = await setDataStoreObject();
    const tt_users_query = query(collection(db, "USER_PROFILE"), where("tt_validated", "==", true));
    const ttUsersQuerySnapshot = await getDocs(tt_users_query);
    var randomHolesFront = _.sampleSize([1, 2, 3, 4, 5, 6, 7, 8, 9], 6);
    var randomHolesBack = _.sampleSize([10, 11, 12, 13, 14, 15, 16, 17, 18], 6);
    var results = [];
    if (ttUsersQuerySnapshot.docs && ttUsersQuerySnapshot.docs.length > 0) {
        ttUsersQuerySnapshot.docs.forEach(function (documentSnapshot) {
            var doc_item = documentSnapshot.data();
            results.push(doc_item);
        });
    }
    console.log("Results length: "+results.length);
    if (results.length > 0 && (type.holes) === 9) {
        await Promise.all(results.map(async (user) => {
            user.scores = [];
            const getScores = query(collection(db, "SCORE_RECORDS"), where("user_id", "==", user.user_id), where('competition_date_timestamp', '>=', new Date(from_date).getTime()), where('competition_date_timestamp', '<=', new Date(q_to_date).getTime()), where('tour_id', '==', type.id), where('holes', '==', 9), where('score_submitted', '==', true));
            const scoresQuerySnapshot = await getDocs(getScores);
            if (scoresQuerySnapshot.docs && scoresQuerySnapshot.docs.length > 0) {
                for (var i = 0; i < scoresQuerySnapshot.docs.length; i++) {
                    let score_item = scoresQuerySnapshot.docs[i].data();
                    score_item.id = scoresQuerySnapshot.docs[i].id;
                    if (score_item.score_submitted && score_item.hole_details.length == 9) {
                        var selected_hole_points = {
                            "triple_bogey": 0,
                            "double_bogey": 0,
                            "bogey": 0,
                            "par_value": 0,
                            "birdie": 0,
                            "eagle": 0,
                            "double_eagle": 0,
                        };

                        var all_hole_points = {
                            "triple_bogey": 0,
                            "double_bogey": 0,
                            "bogey": 0,
                            "par_value": 0,
                            "birdie": 0,
                            "eagle": 0,
                            "double_eagle": 0,
                        };

                        var total_points = 0;
                        var peoria_points = 0;//peoria target
                        var par_value = 0

                        score_item.leaderboard_holes = score_item.hole_details.map(hole => {
                            if (score_item.tee_type === "Front" && randomHolesFront.indexOf(hole.hole_number) > -1) {
                                if ((hole.raw_score - hole.par) > 2) {
                                    hole.points = configData.triple_bogeys;
                                    hole.points_type = "triple_bogey";
                                    all_hole_points.triple_bogey++;
                                    selected_hole_points.triple_bogey++;
                                } else if ((hole.raw_score - hole.par) == 2) {
                                    hole.points = configData.double_bogeys;
                                    hole.points_type = "double_bogey";
                                    all_hole_points.double_bogey++;
                                    selected_hole_points.double_bogey++;
                                } else if ((hole.raw_score - hole.par) == 1) {
                                    hole.points = configData.bogeys;
                                    hole.points_type = "bogey";
                                    all_hole_points.bogey++;
                                    selected_hole_points.bogey++;
                                } else if ((hole.raw_score - hole.par) == 0) {
                                    hole.points = configData.pars;
                                    hole.points_type = "par_value";
                                    all_hole_points.par_value++;
                                    selected_hole_points.par_value++;
                                } else if ((hole.raw_score - hole.par) == -1) {
                                    hole.points = configData.birdies;
                                    hole.points_type = "birdie";
                                    all_hole_points.birdie++;
                                    selected_hole_points.birdie++;
                                } else if ((hole.raw_score - hole.par) == -2) {
                                    hole.points = configData.eagles;
                                    hole.points_type = "eagle";
                                    all_hole_points.eagle++;
                                    selected_hole_points.eagle++;
                                } else if ((hole.raw_score - hole.par) <= -3) {
                                    hole.points = configData.eagles;
                                    hole.points_type = "double_eagle";
                                    all_hole_points.double_eagle++;
                                    selected_hole_points.double_eagle++;
                                }

                                total_points += hole.points;
                                peoria_points += hole.points;
                                hole.is_picked = true;
                                par_value += hole.par;
                                return hole;
                            }
                            else if (score_item.tee_type === "Back" && randomHolesBack.indexOf(hole.hole_number) > -1) {
                                if ((hole.raw_score - hole.par) > 2) {
                                    hole.points = configData.triple_bogeys;
                                    hole.points_type = "triple_bogey";
                                    all_hole_points.triple_bogey++;
                                    selected_hole_points.triple_bogey++;
                                } else if ((hole.raw_score - hole.par) == 2) {
                                    hole.points = configData.double_bogeys;
                                    hole.points_type = "double_bogey";
                                    all_hole_points.double_bogey++;
                                    selected_hole_points.double_bogey++;
                                } else if ((hole.raw_score - hole.par) == 1) {
                                    hole.points = configData.bogeys;
                                    hole.points_type = "bogey";
                                    all_hole_points.bogey++;
                                    selected_hole_points.bogey++;
                                } else if ((hole.raw_score - hole.par) == 0) {
                                    hole.points = configData.pars;
                                    hole.points_type = "par_value";
                                    all_hole_points.par_value++;
                                    selected_hole_points.par_value++;
                                } else if ((hole.raw_score - hole.par) == -1) {
                                    hole.points = configData.birdies;
                                    hole.points_type = "birdie";
                                    all_hole_points.birdie++;
                                    selected_hole_points.birdie++;
                                } else if ((hole.raw_score - hole.par) == -2) {
                                    hole.points = configData.eagles;
                                    hole.points_type = "eagle";
                                    all_hole_points.eagle++;
                                    selected_hole_points.eagle++;
                                } else if ((hole.raw_score - hole.par) <= -3) {
                                    hole.points = configData.eagles;
                                    hole.points_type = "double_eagle";
                                    all_hole_points.double_eagle++;
                                    selected_hole_points.double_eagle++;
                                }

                                total_points += hole.points;
                                peoria_points += hole.points;
                                hole.is_picked = true;
                                par_value += hole.par;
                                return hole;
                            }
                            else {
                                if ((hole.raw_score - hole.par) > 2) {
                                    hole.points = configData.triple_bogeys;
                                    hole.points_type = "triple_bogey";
                                    all_hole_points.triple_bogey++;
                                } else if ((hole.raw_score - hole.par) == 2) {
                                    hole.points = configData.double_bogeys;
                                    hole.points_type = "double_bogey";
                                    all_hole_points.double_bogey++;
                                } else if ((hole.raw_score - hole.par) == 1) {
                                    hole.points = configData.bogeys;
                                    hole.points_type = "bogey";
                                    all_hole_points.bogey++;
                                } else if ((hole.raw_score - hole.par) == 0) {
                                    hole.points = configData.pars;
                                    hole.points_type = "par_value";
                                    all_hole_points.par_value++;
                                } else if ((hole.raw_score - hole.par) == -1) {
                                    hole.points = configData.birdies;
                                    hole.points_type = "birdie";
                                    all_hole_points.birdie++;
                                } else if ((hole.raw_score - hole.par) == -2) {
                                    hole.points = configData.eagles;
                                    hole.points_type = "eagle";
                                    all_hole_points.eagle++;
                                } else if ((hole.raw_score - hole.par) <= -3) {
                                    hole.points = configData.eagles;
                                    hole.points_type = "double_eagle";
                                    all_hole_points.double_eagle++;
                                }

                                total_points += hole.points;
                                hole.is_picked = false;
                                par_value += hole.par;
                                return hole;
                            }
                        });
                        let peoria_quota = Math.floor(peoria_points * 1.5);//peoria target
                        let play_target = Math.ceil(score_item.quota / 2);
                        let finalTarget = peoria_quota > play_target ? peoria_quota : play_target;
                        score_item.net_score = Math.floor(total_points - finalTarget);
                        score_item.hole_details = score_item.leaderboard_holes;
                        score_item.total_points = Number(total_points);
                        score_item.peoria_points = Number(peoria_points);
                        score_item.peoria_quota = Number(peoria_quota);
                        //createEntryWithoutId('PPW_COMPETITIONS', score_item);
                        user.scores.push(score_item);

                    }
                }
            }
            return user;
        }))
            .then((users) => {
                let leaderboard_users = users.map(user => {
                    if (user && user.scores.length > 0) {
                        let scores = _.orderBy(user.scores, ['net_score'], ['desc']);
                        let userObj = {
                            "slope_rating": scores[0].slope_rating,
                            "course_rating": scores[0].course_rating,
                            "hole_details": scores[0].hole_details,
                            "par": scores[0].par,
                            "course_handicap": scores[0].course_handicap ? scores[0].course_handicap : "",
                            "competition_quota": scores[0].quota,
                            "total_points": Number(scores[0].total_points),
                            "peoria_points": Number(scores[0].peoria_points),
                            "peoria_quota": Number(scores[0].peoria_quota),
                            "quota": scores[0].quota,
                            "net_score": Number(scores[0].net_score),
                            "first_name": user.first_name,
                            "last_name": user.last_name,
                            "ghin_number": user.ghin_number,
                            "city": user.city,
                            "course_display_value": scores[0].course_name,
                            "handicap_index": user.handicap_index,
                            "ppw_exceptional_score_adjustment": user.ppw_exceptional_score_adjustment,
                            "user_id": scores[0].user_id,
                            "played_at": scores[0].competition_date,
                            "tee_name": scores[0].tee,
                            "tee_type": scores[0].tee_type,
                            "points": scores[0].points,
                        }
                        return userObj;
                    }
                }).filter(user => user);

                let leaderboard_records = _.orderBy(leaderboard_users, ['net_score'], ['desc']);
                if (leaderboard_records.length > 2) {

                    let current_index = 0;
                    for (var user of leaderboard_records) {
                        leaderboard_records[current_index].index = current_index;
                        leaderboard_records[current_index].seasonal_points = Math.round(((((-(leaderboard_records.length) * 100) - (leaderboard_records.length)) / leaderboard_records.length) * current_index) + (leaderboard_records.length * 100));
                        current_index++;
                    };

                    var rawQuotient = leaderboard_records.length / 3;
                    var remainder = rawQuotient % 1;
                    var quotient = rawQuotient - remainder;
                    let medal_user_set = leaderboard_records.slice(0, quotient);

                    let rank_index = 0;
                    current_index = 0;
                    let previous_points = 0;
                    let net_score_idx = 0;

                    for (var record of leaderboard_records) {
                        if (record.net_score == previous_points) {
                            if (rank_index == 0) {
                                leaderboard_records[current_index].position = rank_index + 1;
                                leaderboard_records[current_index].medal_type = net_score_idx + 1;
                            } else {
                                if (net_score_idx < 3) {
                                    leaderboard_records[current_index].medal_type = net_score_idx;
                                    leaderboard_records[current_index - 1].medal_type = net_score_idx;
                                }
                                leaderboard_records[current_index].position = "T" + rank_index;
                                leaderboard_records[current_index - 1].position = "T" + rank_index;
                            }
                        } else {
                            previous_points = record.net_score;
                            if (net_score_idx < 3) {
                                leaderboard_records[current_index].medal_type = net_score_idx + 1;
                                net_score_idx = net_score_idx + 1;
                            } else {
                                leaderboard_records[current_index].medal_type = 0;
                            }

                            leaderboard_records[current_index].position = current_index + 1;
                            rank_index = current_index + 1;
                        }
                        current_index++;
                    }

                    let individualPointsRecords = _.groupBy(leaderboard_records, 'position');
                    Object.keys(individualPointsRecords).forEach(key => {
                        let rec_lg = individualPointsRecords[key].length;
                        let seasonal_points = (individualPointsRecords[key].map(e => e.seasonal_points).reduce((a, b) => a + b, 0)) / rec_lg;
                        individualPointsRecords[key].forEach(individual_user => {
                            leaderboard_records[individual_user.index].seasonal_points = seasonal_points.toFixed(0);
                        });
                    });

                    if (leaderboard_records.length) {
                        createEntryWithoutId("TT_WEEKLY_PEORIA_LEADERBOARDS", {
                            format: type.format,
                            tour_name: type.tour_name,
                            tour_id: type.id,
                            season_type: "WEEKLY",
                            holes: type.holes,
                            to_date: to_date,
                            from_date: from_date,
                            records: leaderboard_records,
                            season: 5,
                            season_name: season_name,
                            is_admin: false,
                            is_active: true,
                            leaderboard_generated_timestamp: new Date(to_date).getTime()
                        });
                    }
                    console.log("Leaderboard created successfully");
                } else {
                    throw new Error("Minumum 3 players are needed to generate a leaderboard.");
                }
            });
    }
    else if (results.length > 0 && Number(type.holes) === 18) {
        console.log("In 18 hole logic");
        var randomHoles = (randomHolesFront.concat(randomHolesBack)).sort((a, b) => b - a);
        await Promise.all(results.map(async user => {
            user.scores = [];
        //    console.log("Pulling scores for: "+user.user_id + " "+user);
            const getScores = query(collection(db, "SCORE_RECORDS"), where("user_id", "==", user.user_id), where('competition_date_timestamp', '>=', new Date(from_date).getTime()), where('competition_date_timestamp', '<=', new Date(to_date).getTime()), where('tour_id', '==', type.id), where('holes', '==', 18), where('score_submitted', '==', true));
         //   const getScores = query(collection(db, "SCORE_RECORDS"), where("user_id", "==", user.user_id),  where('tour_id', '==', type.id), where('holes', '==', 18), where('score_submitted', '==', true));
            const scoresQuerySnapshot = await getDocs(getScores);
            if (scoresQuerySnapshot.docs && scoresQuerySnapshot.docs.length > 0) {
                for (var i = 0; i < scoresQuerySnapshot.docs.length; i++) {
                    let score_item = scoresQuerySnapshot.docs[i].data();
                    score_item.id = scoresQuerySnapshot.docs[i].id;
                    console.log("score_item", score_item);
                    if (score_item.score_submitted && score_item.hole_details.length == 18) {
                        var selected_hole_points = {
                            "triple_bogey": 0,
                            "double_bogey": 0,
                            "bogey": 0,
                            "par_value": 0,
                            "birdie": 0,
                            "eagle": 0,
                            "double_eagle": 0,
                        };

                        var all_hole_points = {
                            "triple_bogey": 0,
                            "double_bogey": 0,
                            "bogey": 0,
                            "par_value": 0,
                            "birdie": 0,
                            "eagle": 0,
                            "double_eagle": 0,
                        };

                        var total_points = 0;
                        var peoria_points = 0;//peoria target
                        var par_value = 0

                        score_item.leaderboard_holes = score_item.hole_details.map(hole => {
                            if (randomHoles.indexOf(hole.hole_number) > -1) {
                                if ((hole.raw_score - hole.par) > 2) {
                                    hole.points = configData.triple_bogeys;
                                    hole.points_type = "triple_bogey";
                                    all_hole_points.triple_bogey++;
                                    selected_hole_points.triple_bogey++;
                                } else if ((hole.raw_score - hole.par) == 2) {
                                    hole.points = configData.double_bogeys;
                                    hole.points_type = "double_bogey";
                                    all_hole_points.double_bogey++;
                                    selected_hole_points.double_bogey++;
                                } else if ((hole.raw_score - hole.par) == 1) {
                                    hole.points = configData.bogeys;
                                    hole.points_type = "bogey";
                                    all_hole_points.bogey++;
                                    selected_hole_points.bogey++;
                                } else if ((hole.raw_score - hole.par) == 0) {
                                    hole.points = configData.pars;
                                    hole.points_type = "par_value";
                                    all_hole_points.par_value++;
                                    selected_hole_points.par_value++;
                                } else if ((hole.raw_score - hole.par) == -1) {
                                    hole.points = configData.birdies;
                                    hole.points_type = "birdie";
                                    all_hole_points.birdie++;
                                    selected_hole_points.birdie++;
                                } else if ((hole.raw_score - hole.par) == -2) {
                                    hole.points = configData.eagles;
                                    hole.points_type = "eagle";
                                    all_hole_points.eagle++;
                                    selected_hole_points.eagle++;
                                } else if ((hole.raw_score - hole.par) == -3) {
                                    hole.points = configData.eagles;
                                    hole.points_type = "double_eagle";
                                    all_hole_points.double_eagle++;
                                    selected_hole_points.double_eagle++;
                                }

                                total_points += hole.points;
                                peoria_points += hole.points;
                                hole.is_picked = true;
                                par_value += hole.par;
                                return hole;
                            } else {
                                if ((hole.raw_score - hole.par) > 2) {
                                    hole.points = configData.triple_bogeys;
                                    hole.points_type = "triple_bogey";
                                    all_hole_points.triple_bogey++;
                                } else if ((hole.raw_score - hole.par) == 2) {
                                    hole.points = configData.double_bogeys;
                                    hole.points_type = "double_bogey";
                                    all_hole_points.double_bogey++;
                                } else if ((hole.raw_score - hole.par) == 1) {
                                    hole.points = configData.bogeys;
                                    hole.points_type = "bogey";
                                    all_hole_points.bogey++;
                                } else if ((hole.raw_score - hole.par) == 0) {
                                    hole.points = configData.pars;
                                    hole.points_type = "par_value";
                                    all_hole_points.par_value++;
                                } else if ((hole.raw_score - hole.par) == -1) {
                                    hole.points = configData.birdies;
                                    hole.points_type = "birdie";
                                    all_hole_points.birdie++;
                                } else if ((hole.raw_score - hole.par) == -2) {
                                    hole.points = configData.eagles;
                                    hole.points_type = "eagle";
                                    all_hole_points.eagle++;
                                } else if ((hole.raw_score - hole.par) == -3) {
                                    hole.points = configData.eagles;
                                    hole.points_type = "double_eagle";
                                    all_hole_points.double_eagle++;
                                }

                                total_points += hole.points;
                                hole.is_picked = false;
                                par_value += hole.par;
                                return hole;
                            }
                        });
                        let peoria_quota = Math.floor(peoria_points * 1.5);//peoria target
                        let finalTarget = peoria_quota > score_item.quota ? peoria_quota : score_item.quota;
                        score_item.net_score = Math.floor(total_points - peoria_quota);
                        score_item.hole_details = score_item.leaderboard_holes;

                        score_item.total_points = Number(total_points);
                        score_item.peoria_points = Number(peoria_points);
                        score_item.peoria_quota = Number(peoria_quota);
                        //createEntryWithoutId('PPW_COMPETITIONS', score_item);
                        user.scores.push(score_item);

                    }

                }
            }
            return user;
        }))
            .then(results => {
                let leaderboard_users = results.map(user => {
                    if (user && user.scores.length > 0) {
                        let scores = _.orderBy(user.scores, ['net_score'], ['desc']);
                        let userObj = {
                            "slope_rating": scores[0].slope_rating,
                            "course_rating": scores[0].course_rating,
                            "hole_details": scores[0].hole_details,
                            "par": scores[0].par,
                            "course_handicap": scores[0].course_handicap ? scores[0].course_handicap : "",
                            "competition_quota": scores[0].quota,
                            "total_points": Number(scores[0].total_points),
                            "peoria_points": Number(scores[0].peoria_points),
                            "peoria_quota": Number(scores[0].peoria_quota),
                            "quota": scores[0].quota,
                            "net_score": Number(scores[0].net_score),
                            "first_name": user.first_name,
                            "last_name": user.last_name,
                            "ghin_number": user.ghin_number,
                            "city": user.city,
                            "course_display_value": scores[0].course_name,
                            "handicap_index": user.handicap_index,
                            "ppw_exceptional_score_adjustment": user.ppw_exceptional_score_adjustment,
                            "user_id": scores[0].user_id,
                            "played_at": scores[0].competition_date,
                            "tee_name": scores[0].tee,
                            "tee_type": scores[0].tee_type,
                            "points": scores[0].points,
                        }
                        return userObj;
                    }
                }).filter(user => user);

                let leaderboard_records = _.orderBy(leaderboard_users, ['net_score'], ['desc']);
                if (leaderboard_records.length > 2) {

                    let current_index = 0;
                    for (var user of leaderboard_records) {
                        leaderboard_records[current_index].index = current_index;
                        leaderboard_records[current_index].seasonal_points = Math.round(((((-(leaderboard_records.length) * 100) - (leaderboard_records.length)) / leaderboard_records.length) * current_index) + (leaderboard_records.length * 100));
                        current_index++;
                    };

                    var rawQuotient = leaderboard_records.length / 3;
                    var remainder = rawQuotient % 1;
                    var quotient = rawQuotient - remainder;
                    let medal_user_set = leaderboard_records.slice(0, quotient);

                    let rank_index = 0;
                    current_index = 0;
                    let previous_points = 0;
                    let net_score_idx = 0;

                    for (var record of leaderboard_records) {
                        if (record.net_score == previous_points) {
                            if (rank_index == 0) {
                                leaderboard_records[current_index].position = rank_index + 1;
                                leaderboard_records[current_index].medal_type = net_score_idx + 1;
                            } else {
                                if (net_score_idx < 3) {
                                    leaderboard_records[current_index].medal_type = net_score_idx;
                                    leaderboard_records[current_index - 1].medal_type = net_score_idx;
                                }
                                leaderboard_records[current_index].position = "T" + rank_index;
                                leaderboard_records[current_index - 1].position = "T" + rank_index;
                            }
                        } else {
                            previous_points = record.net_score;
                            if (net_score_idx < 3) {
                                leaderboard_records[current_index].medal_type = net_score_idx + 1;
                                net_score_idx = net_score_idx + 1;
                            } else {
                                leaderboard_records[current_index].medal_type = 0;
                            }

                            leaderboard_records[current_index].position = current_index + 1;
                            rank_index = current_index + 1;
                        }
                        current_index++;
                    }

                    let individualPointsRecords = _.groupBy(leaderboard_records, 'position');
                    Object.keys(individualPointsRecords).forEach(key => {
                        let rec_lg = individualPointsRecords[key].length;
                        let seasonal_points = (individualPointsRecords[key].map(e => e.seasonal_points).reduce((a, b) => a + b, 0)) / rec_lg;
                        individualPointsRecords[key].forEach(individual_user => {
                            leaderboard_records[individual_user.index].seasonal_points = seasonal_points.toFixed(0);
                        });
                    });

                    if (leaderboard_records.length) {
                        createEntryWithoutId("TT_WEEKLY_PEORIA_LEADERBOARDS", {
                            format: type.format,
                            tour_name: type.tour_name,
                            tour_id: type.id,
                            season_type: "WEEKLY",
                            holes: type.holes,
                            to_date: to_date,
                            from_date: from_date,
                            records: leaderboard_records,
                            season: 5,
                            season_name: season_name,
                            is_admin: false,
                            is_active: true,
                            leaderboard_generated_timestamp: new Date(to_date).getTime()
                        });
                    }
                    console.log("Leaderboard created successfully");
                } else {
                    throw new Error("Minumum 3 players are needed to generate a leaderboard.");

                }
            });

    }
    else {
        throw new Error("No users found");
    }
}
export const generateTTPeoriaSeasonlLeaderBoards = async (tour) => {
    let to_date = format(startOfWeek(new Date(), { weekStartsOn: 0 }), 'yyyy-MM-dd'),
        from_date = '2022-09-12';
    let weekly_count = 8;

    if(tour.from_date && tour.from_date.length > 0)
        from_date = tour.from_date;

    const tt_leaderboard_query = query(collection(db, "TT_WEEKLY_PEORIA_LEADERBOARDS"), where("season_type", "==", 'WEEKLY'), where("leaderboard_generated_timestamp", ">=", new Date(from_date).getTime()), where("leaderboard_generated_timestamp", "<=", new Date(to_date).getTime()), where("tour_id", '==', tour.id));
    const ttLeaderboardQuerySnapshot = await getDocs(tt_leaderboard_query);
    var tt_leaderboard_records = [];
    if (ttLeaderboardQuerySnapshot.docs && ttLeaderboardQuerySnapshot.docs.length > 0) {
        ttLeaderboardQuerySnapshot.docs.forEach(function (documentSnapshot) {
            var doc_item = documentSnapshot.data();
            doc_item.id = documentSnapshot.id;
            tt_leaderboard_records.push(doc_item);
        });
    }

    if(tour.weekly_count && tour.weekly_count > 0)
        weekly_count = tour.weekly_count;

    let seasonal_record = {
        type: "Peroia",
        season_type: "SEASONAL",
        to_date: to_date,
        from_date: from_date,
        users: [],
        other_users: [],
        season: 5,
        season_name: season_name,
        is_admin: false,
        is_active: true,
        tour_id: tour.id
    }, users = []


    tt_leaderboard_records.forEach(record => {
        users = [...record.records, ...users];
    });
    users = _.groupBy(users, 'user_id');
    Object.keys(users).forEach((key, index) => {
        let userRecs = users[key], length = users[key].length;
        let user = userRecs[0];
        user = {
            "index": index,
            "position": index + 1,
            "earnings": 0,
            "seasonal_points": 0,
            "total_seasonal_points": 0,
            "net_score": user.net_score.toString(),
            "played_at": user.played_at,
            "course_display_value": user.course_display_value,
            "tee_name": user.tee_name,
            "season_type": "SEASONAL",
            "user_id": user.user_id,
            "user_name": user.first_name + ' ' + user.last_name,
            "city": user.city || '',
            "ghin_number": user.ghin_number,
            "handicap_index": user.handicap_index || '',
            "quota": user.quota || 0,
            "medals": {
                "gold": 0,
                "silver": 0,
                "bronze": 0
            },
            "competitions_length": length
        };

        if (userRecs.length >= weekly_count) {
            let recs = _.sortBy(userRecs, e => parseInt(e.seasonal_points)).reverse();
            recs.length = weekly_count;
            length = weekly_count;
            let pointsRecs = recs;
            pointsRecs.forEach(userRec => {
                user.seasonal_points += parseInt(userRec.seasonal_points);
            });
            userRecs.forEach(userRec => {
                userRec.earnings = userRec.earnings || 0;
                user.earnings += userRec.earnings;
            });
        } else {
            length = users[key].length;
            userRecs.forEach(userRec => {
                userRec.earnings = userRec.earnings || 0;
                user.earnings += userRec.earnings;
                user.seasonal_points += parseInt(userRec.seasonal_points);
            });
        }

        userRecs.forEach(userRec => {
            if (userRec.medal_type == 1) {
                user.medals.gold = user.medals.gold + 1;
            }
            if (userRec.medal_type == 2) {
                user.medals.silver = user.medals.silver + 1;
            }
            if (userRec.medal_type == 3) {
                user.medals.bronze = user.medals.bronze + 1;
            }
        });
        user.total_seasonal_points = parseInt(user.seasonal_points);
        user.competitions_length = length;
        seasonal_record.users.push(user);
    });

    seasonal_record.users = _.orderBy(seasonal_record.users, ['total_seasonal_points'], ['desc']);

    let rank_index = 0;
    let current_index = 0;
    let previous_points = 0;
    for (var user of seasonal_record.users) {
        if (user.total_seasonal_points == previous_points) {
            if (rank_index == 0) {
                seasonal_record.users[current_index].position = rank_index + 1;
            } else {
                seasonal_record.users[current_index].position = "T" + rank_index;
                seasonal_record.users[current_index - 1].position = "T" + rank_index;
            }
        } else {
            previous_points = user.total_seasonal_points;
            seasonal_record.users[current_index].position = current_index + 1;
            rank_index = current_index + 1;
        }
        current_index++;
    }

    await createEntryWithoutId("TT_SEASON_PEORIA_LEADERBOARDS", seasonal_record);

    console.log("Season leaderboard generated successfully");
}

export const generateTTOneOnOneLeaderBoards = async (tour) => {
    let to_date = startOfWeek(new Date(), { weekStartsOn: 0 });
    let from_date = subDays(to_date, 6);
   // let to_date = new Date();
   // let from_date = startOfWeek(new Date(), { weekStartsOn: 0 });
    to_date = format(to_date, 'yyyy-MM-dd');
    from_date = format(from_date, 'yyyy-MM-dd');
    let configData = await setDataStoreObject();
    const tt_leaderboard_query = query(collection(db, "TT_WEEKLY_ONE_ON_ONE_LEADERBOARDS"), where("season_type", "==", 'WEEKLY'), where("to_date", "==", to_date));
    const ttLeaderboardQuerySnapshot = await getDocs(tt_leaderboard_query);

    let ttLeaderboard = ttLeaderboardQuerySnapshot.docs && ttLeaderboardQuerySnapshot.docs.length > 0 ? true : false;
    if (ttLeaderboard) {
        throw new Error("Leaderboard is already created for this week!");
    }
    else  {
        const competitions_query = query(collection(db, "TT_COMPETITIONS"), where("competition_date_timestamp", ">=", new Date(from_date).getTime()), where("competition_date_timestamp", "<=", new Date(to_date).getTime()),where("tour_id","==",tour.id), where("result", "in", ["Won", "Lost", "Tied"]));
        const competitionsQuerySnapshot = await getDocs(competitions_query);
        var competition_records = [];
        if (competitionsQuerySnapshot.docs && competitionsQuerySnapshot.docs.length > 0) {
            competitionsQuerySnapshot.docs.forEach(function (documentSnapshot) {
                var doc_item = documentSnapshot.data();
                doc_item.id = documentSnapshot.id;
                competition_records.push(doc_item);
            });
        }
        if (competition_records.length > 0) {
            console.log("Checkin Records:::::1", competition_records);
            let userGroupedRecords = _.groupBy(competition_records, 'user_id');
            console.log("Checkin Records Users:::::1", userGroupedRecords);
            let checkedCompetitions = {};
            for (var key in userGroupedRecords) {
                let sortedUserMatchRecords = _.sortBy(userGroupedRecords[key], e => e.created_at);
                let weeklyRecords = sortedUserMatchRecords.filter(rec => rec.is_score_added == true);
                var player_list_records = [];
                const player_list_query = query(collection(db, "TT_PLAYER_COMPETITIORS_LIST"), where("user_id", "==", key));
                const playerListQuerySnapshot = await getDocs(player_list_query);
                if (playerListQuerySnapshot.docs && playerListQuerySnapshot.docs.length > 0) {
                    playerListQuerySnapshot.docs.forEach(function (documentSnapshot) {
                        var doc_item = documentSnapshot.data();
                        doc_item.id = documentSnapshot.id;
                        player_list_records.push(doc_item);
                    });
                }
                if (weeklyRecords.length > 0 && player_list_records.length > 0) {
                    for (var i = 0; i < weeklyRecords.length; i++) {
                        let previousCompetitorsList = player_list_records[0].all_competitors;
                        let isOldCompetitor = previousCompetitorsList.filter(comp => comp.user_id == weeklyRecords[i].individual_players[0].user_id).length > 0 ? false : true;
                        if (isOldCompetitor) {
                            weeklyRecords[i].valid_unique_competition = isOldCompetitor;
                        }
                        if (weeklyRecords.length == i + 1) {
                            checkedCompetitions[key] = weeklyRecords;
                        }
                    }
                } else if (weeklyRecords.length > 0 && player_list_records.length == 0) {
                    for (var i = 0; i < weeklyRecords.length; i++) {
                        weeklyRecords[i].valid_unique_competition = true;
                        if (weeklyRecords.length == i + 1) {
                            checkedCompetitions[key] = weeklyRecords;
                        }
                    }
                }
            };

            let resultCompetitions = [];
            Object.keys(checkedCompetitions).forEach(key => {
                for (var i = 0; i < checkedCompetitions[key].length; i++) {
                    let firstCompetition = checkedCompetitions[key][i];
                    let secondCompetition = {};
                    if (checkedCompetitions[firstCompetition.individual_players[0].user_id].length > 0) {
                        let secondUser = checkedCompetitions[firstCompetition.individual_players[0].user_id][0];
                        if (secondUser.competition_relation_id === firstCompetition.competition_relation_id) {
                            secondCompetition = secondUser;
                        }
                    }
                    //secondCompetition = checkedCompetitions.filter(rec => rec.competition_relation_id == firstCompetition.competition_relation_id && firstCompetition.individual_players[0].user_id == rec.user_id);
                    if (secondCompetition) {
                        var net_scores = [firstCompetition.net_score, secondCompetition.net_score];
                        var margin = (net_scores.reduce((a, b) => {
                            return a > b ? a - b : b - a
                        }));
                        var winner = firstCompetition.result == 'Won' ? { user_id: firstCompetition.user_id, user_name: firstCompetition.user_name } : { user_id: secondCompetition.user_id, user_name: secondCompetition.user_name };
                        var tied = firstCompetition.result == 'Even' ? true : false;
                        let matchRecord = {
                            "individual_net_scores": [firstCompetition.net_score, secondCompetition.net_score || 0],
                            "players": [
                                {
                                    "user_id": firstCompetition.user_id,
                                    "user_name": firstCompetition.user_name
                                },
                                {
                                    "user_id": firstCompetition.individual_players[0].user_id,
                                    "user_name": firstCompetition.individual_players[0].user_name
                                }],
                            "individual_points": [firstCompetition.points, secondCompetition.points],
                            "round_points": [],
                            "individual_quotas": [firstCompetition.quota, secondCompetition.quota],
                            "results": [],
                            "competition_date": firstCompetition.competition_date,
                            "competition_relation_id": firstCompetition.competition_relation_id,
                            "competition_date_timestamp": firstCompetition.competition_date_timestamp,
                            "ids": [firstCompetition.id, secondCompetition.id || ''],
                            "user_id": firstCompetition.user_id,
                            "user_name": firstCompetition.user_name,
                            "margin": margin,
                            "winner": winner,
                            "abandoned_match": true
                        }
                        resultCompetitions.push(matchRecord);
                    } else {
                        var net_scores = [firstCompetition.net_score, secondCompetition.net_score];
                        var margin = (net_scores.reduce((a, b) => a > b ? a - b : b - a));
                        var winner = firstCompetition.result == 'Won' ? { user_id: firstCompetition.user_id, user_name: firstCompetition.user_name } : { user_id: secondCompetition.user_id, user_name: secondCompetition.user_name };
                        var tied = firstCompetition.result == 'Even' ? true : false;
                        if (firstCompetition.valid_unique_competition == true) {
                            updateEntry('TT_COMPETITIONS', firstCompetition.id, { is_picked: true, is_unique: true });
                        } else {
                            if (i == 0) {
                                updateEntry('TT_COMPETITIONS', firstCompetition.id, { is_picked: true });
                            }
                        }
                        if (winner.user_id == firstCompetition.user_id) {
                            let matchRecord = {
                                "individual_net_scores": [firstCompetition.net_score, secondCompetition.net_score],
                                "players": [
                                    {
                                        "user_id": firstCompetition.user_id,
                                        "user_name": firstCompetition.user_name
                                    },
                                    {
                                        "user_id": secondCompetition.user_id,
                                        "user_name": secondCompetition.user_name
                                    }],
                                "individual_points": [firstCompetition.points, secondCompetition.points],
                                "round_points": [firstCompetition.round_points, secondCompetition.round_points],
                                "individual_quotas": [firstCompetition.quota, secondCompetition.quota],
                                "results": tied == true ? ["Tied", "Tied"] : ["Won", "Lost"],
                                "competition_date": firstCompetition.competition_date,
                                "competition_relation_id": firstCompetition.competition_relation_id,
                                "competition_date_timestamp": firstCompetition.competition_date_timestamp,
                                "ids": [firstCompetition.id, secondCompetition.id],
                                "user_id": firstCompetition.user_id,
                                "user_name": firstCompetition.user_name,
                                "margin": margin,
                                "winner": tied == true ? '' : winner,
                            }
                            resultCompetitions.push(matchRecord);
                        } else {
                            let matchRecord = {
                                "individual_net_scores": [secondCompetition.net_score, firstCompetition.net_score],
                                "players": [
                                    {
                                        "user_id": secondCompetition.user_id,
                                        "user_name": secondCompetition.user_name
                                    },
                                    {
                                        "user_id": firstCompetition.user_id,
                                        "user_name": firstCompetition.user_name
                                    }],
                                "individual_points": [secondCompetition.points, firstCompetition.points],
                                "round_points": [secondCompetition.round_points, firstCompetition.round_points],
                                "individual_quotas": [secondCompetition.quota, firstCompetition.quota],
                                "results": tied == true ? ["Tied", "Tied"] : ["Won", "Lost"],
                                "competition_date": secondCompetition.competition_date,
                                "competition_relation_id": secondCompetition.competition_relation_id,
                                "competition_date_timestamp": secondCompetition.competition_date_timestamp,
                                "ids": [secondCompetition.id, firstCompetition.id],
                                "user_id": secondCompetition.user_id,
                                "user_name": secondCompetition.user_name,
                                "margin": margin,
                                "winner": tied == true ? '' : winner,
                            }
                            resultCompetitions.push(matchRecord);
                        }
                    }
                }
            });

            console.log("Checkin Records:::::111111", resultCompetitions);

            let groupedMatchRecords = _.groupBy(resultCompetitions, 'competition_relation_id');
            //var groupedLeaderboardRecords = _.orderBy(matchRecords, ['margin'], ['desc']);
            var taoLeaderboardRecords = [];
            var count = 0;
         //   const player_list_query1 = query(collection(db, "TT_PLAYER_COMPETITIORS_LIST"),where("season_name","==",season_name));
            const player_list_query1 = query(collection(db, "TT_PLAYER_COMPETITIORS_LIST"),where("season_name","==",season_name), where("tour_id", "==", tour.id));
            let playerListQuerySnapshot1 = await getDocs(player_list_query1);
            await new Promise((resolve) => {
                Object.keys(groupedMatchRecords).forEach(async (key) => {
                    try {
                        let competitiorsList = {};
                        let player1 = groupedMatchRecords[key][0].players[0];
                        let player2 = groupedMatchRecords[key][0].players[1];
                        if(playerListQuerySnapshot1 && playerListQuerySnapshot1.docs.length > 0) {
                            playerListQuerySnapshot1.docs.forEach((playerList) => {
                                if (playerList.data().user_id === player1.user_id) {
                                    competitiorsList = playerList.data();
                                    competitiorsList.id = playerList.id;
                                }
                            });
                            console.log(competitiorsList);
                            if (competitiorsList && competitiorsList.all_competitors) {
                                let existing_competitor = competitiorsList.all_competitors.filter(competitor => competitor.user_id == player2.user_id);
                                if (existing_competitor.length == 0) {
                                    competitiorsList.all_competitors.push({ "user_id": player2.user_id, "user_name": player2.user_name });
                                    competitiorsList.unique_competitor_points = competitiorsList.unique_competitor_points < 8 ? competitiorsList.unique_competitor_points + 2 : 8;
                                    updateEntry('TT_PLAYER_COMPETITIORS_LIST', competitiorsList.id, { all_competitors: competitiorsList.all_competitors, unique_competitor_points: competitiorsList.unique_competitor_points });
                                }
                            }
                        }
                        else {
                            createEntryWithoutId('TT_PLAYER_COMPETITIORS_LIST', {
                                "user_id": player1.user_id,
                                "user_name": player1.user_name,
                                "all_competitors": [{
                                    "user_id": player2.user_id,
                                    "user_name": player2.user_name
                                }],
                                "unique_competitor_points": 2,
                                "tour_id":tour.id,
                                "season_name":season_name
                            });
                        }
                        const player_list_query2 = query(collection(db, "TT_PLAYER_COMPETITIORS_LIST"), where('user_id', '==', player2.user_id));
                        const playerListQuerySnapshot2 = await getDocs(player_list_query2);
                        if (playerListQuerySnapshot2 && playerListQuerySnapshot2.docs.length > 0) {
                            playerListQuerySnapshot2.docs.map(function (documentSnapshot) {
                                var doc_item = documentSnapshot.data();
                                doc_item.id = documentSnapshot.id;
                                let existing_competitor = doc_item.all_competitors.filter(competitor => competitor.user_id == player1.user_id);
                                if (existing_competitor.length == 0) {
                                    doc_item.all_competitors.push({ "user_id": player1.user_id, "user_name": player1.user_name })
                                    doc_item.unique_competitor_points = doc_item.unique_competitor_points < 8 ? doc_item.unique_competitor_points + 2 : 8;
                                    updateEntry('TT_PLAYER_COMPETITIORS_LIST', doc_item.id, { all_competitors: doc_item.all_competitors, unique_competitor_points: doc_item.unique_competitor_points })
                                }
                            });
                        } else {
                            createEntryWithoutId('TT_PLAYER_COMPETITIORS_LIST', {
                                "user_id": player2.user_id,
                                "user_name": player2.user_name,
                                "all_competitors": [{
                                    "user_id": player1.user_id,
                                    "user_name": player1.user_name
                                }],
                                "unique_competitor_points": 2,
                                "tour_id":tour.id,
                                "season_name":season_name
                            });
                        }
                        taoLeaderboardRecords.push(groupedMatchRecords[key][0]);
                    } catch (e) {
                        console.log(e);
                        console.log("Leaderboard creation failed");
                    } finally {
                        count += 1;
                        if (count == Object.keys(groupedMatchRecords).length) {
                            if (taoLeaderboardRecords.length > 0) {
                                taoLeaderboardRecords = _.orderBy(taoLeaderboardRecords, ['margin'], ['desc']);
                                console.log("1 Scores log filtered:::::", taoLeaderboardRecords);
                                if (taoLeaderboardRecords.length > 0) {
                                    createEntryWithoutId("TT_WEEKLY_ONE_ON_ONE_LEADERBOARDS", {
                                        type: "INDIVIDUAL",
                                        season_type: "WEEKLY",
                                        holes: 18,
                                        to_date: to_date,
                                        from_date: from_date,
                                        records: taoLeaderboardRecords,
                                        season: 5,
                                        season_name: season_name,
                                        is_admin: false,
                                        is_active: true
                                    });
                                }
                                console.log("Leaderboard created successfully");
                            } else {
                                console.log("No records to create Leaderboard");
                            }
                            resolve();
                        }
                    }
                });
            });
        } else {
            console.log("No competition records available for the week")
        }
    }    
}

export const generateTTOneOnOneSeasonlLeaderBoards = async (tour) => {
    let to_date = format(startOfWeek(new Date(), { weekStartsOn: 0 }), 'yyyy-MM-dd'),
    from_date = '2022-07-04';
    let seasonal_record = {
        type: "INDIVIDUAL",
        season_type: "SEASONAL",
        to_date: to_date,
        from_date: from_date,
        users: [],
        other_users: [],
        holes: 18,
        season: 5,
        tour_id: tour.id,
        season_name: season_name,
        is_admin: false,
        is_active: true
    };
    const competitions_query = query(collection(db, "TT_COMPETITIONS"), where("competition_date_timestamp", ">=", new Date(from_date).getTime()), where("competition_date_timestamp", "<=", new Date(to_date).getTime()),where("tour_id","==",tour.id), where("result", "in", ["Won", "Lost", "Tied"]));
        const competitionsQuerySnapshot = await getDocs(competitions_query);
    var competition_records = [];
    if (competitionsQuerySnapshot.docs && competitionsQuerySnapshot.docs.length > 0) {
        competitionsQuerySnapshot.docs.forEach(function (documentSnapshot) {
            var doc_item = documentSnapshot.data();
            doc_item.id = documentSnapshot.id;
            competition_records.push(doc_item);
        });
    }
    if (competition_records.length > 0) {
        console.log("Checkin Records:::::1", competition_records);
        let userGroupedRecords = _.groupBy(competition_records, 'user_id');
        console.log("Checkin Records Users:::::1", userGroupedRecords);
        let resultCompetitions = [];
        Object.keys(userGroupedRecords).forEach(key => {
            var newUser = {
                "user_id": userGroupedRecords[key][0].user_id,
                "user_name": userGroupedRecords[key][0].user_name,
                "seasonal_points": 0,
                "competitions_length": userGroupedRecords[key].length,
                "picked_competitions": [],
                "unpicked_competitions": [],
                "wonCount": userGroupedRecords[key].filter(rec => rec.result == "Won").length,
                "lostCount": userGroupedRecords[key].filter(rec => rec.result == "Lost").length,
                "tiedCount": userGroupedRecords[key].filter(rec => rec.result == "Tied").length
            };
            if (userGroupedRecords[key].length >= 6) {
                let recs = _.sortBy(userGroupedRecords[key], e => e.round_points).reverse();
                recs.length = 6;
                var length = 6;
                recs.forEach(userRec => {
                    newUser.seasonal_points += parseInt(userRec.round_points);
                    newUser.picked_competitions.push({
                        'competition_id': userRec.id,
                        'user_id': userRec.individual_players[0].user_id,
                        'user_name': userRec.individual_players[0].user_name
                    });
                });

            } else {
                let recs = _.sortBy(userGroupedRecords[key], e => e.round_points).reverse();
                recs.forEach(userRec => {
                    newUser.seasonal_points += parseInt(userRec.round_points);
                    newUser.picked_competitions.push({
                        'competition_id': userRec.id,
                        'user_id': userRec.individual_players[0].user_id,
                        'user_name': userRec.individual_players[0].user_name
                    });
                });
            }

            resultCompetitions.push(newUser);
        });

        var taoSeasonLeaderboardRecords = [];
        for (var user of resultCompetitions) {
            const player_list_query = query(collection(db, "TT_PLAYER_COMPETITIORS_LIST"), where('user_id', '==', user.user_id), where("season_name", "==",season_name),where("tour_id","==",tour.id));
            const playerListQuerySnapshot = await getDocs(player_list_query);
            if (playerListQuerySnapshot.docs && playerListQuerySnapshot.docs.length > 0) {
                var doc_item = playerListQuerySnapshot.docs[0].data();
                user.seasonal_points += doc_item.unique_competitor_points;
                taoSeasonLeaderboardRecords.push(user);
            }
        }
        //seasonal_record.other_users = taoSeasonLeaderboardRecords.filter((user) => {user.competitions_length < 6});
        //seasonal_record.users = taoSeasonLeaderboardRecords.filter(user => user.competitions_length >= 6);
        //seasonal_record.users = taoSeasonLeaderboardRecords;
        seasonal_record.users = _.orderBy(taoSeasonLeaderboardRecords, ['seasonal_points'], ['desc']);

        let rank_index = 0;
        let current_index = 0;
        let previous_points = 0;
        for (var user of seasonal_record.users) {
            if (user.seasonal_points == previous_points) {
                if (rank_index == 0) {
                    seasonal_record.users[current_index].position = rank_index + 1;
                } else {
                    seasonal_record.users[current_index].position = "T" + rank_index;
                    seasonal_record.users[current_index - 1].position = "T" + rank_index;
                }
            } else {
                previous_points = user.seasonal_points;
                seasonal_record.users[current_index].position = current_index + 1;
                rank_index = current_index + 1;
            }
            current_index++;
        }


        await createEntryWithoutId("TT_SEASON_ONE_ON_ONE_LEADERBOARDS", seasonal_record);

        console.log("Season leaderboard generated successfully");
    } else {
        throw new Error("No competitions available for the week");
    }
}

