import default_profile_logo from "../../img/fewbats.svg";
import {
  IonRefresher,
  IonRefresherContent,
  IonButton,
  IonContent,
  IonPage,
  IonToolbar,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonText,
  IonButtons,
  IonGrid,
  IonSegment,
  IonSegmentButton,
  IonCard,
  IonRow,
  IonCol,
  IonMenuToggle,
  IonHeader,
  IonTitle,
  IonFab,
  IonFabButton,
  IonModal,
} from "@ionic/react";
import "../../css/eclubhouse.css";
import { isPlatform } from "@ionic/react";
import {
    arrowBackOutline,
    chevronBackOutline,
  notificationsOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import _ from "lodash";
import { format } from "date-fns";
import { RefresherEventDetail } from "@ionic/core";
import {
  Chatbubbles,
  Golf,
  Calendar,
  Grid,
} from '@styled-icons/ionicons-outline';
import {
  Gift
} from '@styled-icons/ionicons-solid';
import { useHistory } from "react-router-dom";
import firebase from "../../js/firebase.js";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  orderBy,
} from "firebase/firestore";

const db = getFirestore(firebase);

const ManageProShop: React.FC = () => {
  const [current_user, setCurrentUser] = useState<any>([]);
  const history = useHistory();
  const [proshop_tab_value, setProshopTabValue] = useState<any>("Gift Cards");
  const [yoc_data, setYocData] = useState<any>([]);
  const [giftcard_data, setGiftcardData] = useState<any>([]);
  const [merchandise_data, setMerchandiseData] = useState<any>([]);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    console.log("Begin async operation");
    setTimeout(() => {
      console.log("Async operation has ended");
      window.location.href = "/leagues";
      event.detail.complete();
    }, 2000);
  }
  const getProShopData = async(type:string) => {
    var user = localStorage.getItem('firebase_user');
    if(user != null) {
      var current_user = JSON.parse(user);
      const q = query(collection(db, "PRO_SHOP"), where('created_at', '>', 0), where('type', '==', type), orderBy('created_at', 'desc'));
      const querySnapshot = await getDocs(q);
      await Promise.all(querySnapshot.docs.map(async competition => {
        var doc_item = competition.data();
        doc_item.id = competition.id;
        return doc_item;
      }))
      .then(results => {
        if(type == "Gift Cards") {
          setGiftcardData(results);
        }
        if(type == "Merchandise") {
          setMerchandiseData(results);
        }
        if(type == "Donations") {
          setYocData(results);
        }
      });
    }
  }
  const getItemData = async()=> {

  }
  useEffect(() => {
    let user = localStorage.getItem("firebase_user");
    if (user != null) {
      setCurrentUser(JSON.parse(user));
      getProShopData('Gift Cards');
    }

  }, []);
  // console.log("darkmode checking",document.body.classList.contains('dark'))
  return (
    <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
                <a onClick={()=> history.goBack()}>
                  <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                  </a>
              </IonButtons>
            <IonTitle>
                Pro shop
            </IonTitle>
          </IonToolbar>
        </IonHeader>
      <IonContent>
        <IonGrid className="pad-0">
          {proshop_tab_value == "Gift Cards" ? (
            <IonGrid>
              <IonRow>
              {
                giftcard_data.map((product: any, index:number) => {
                  return (
                    <IonCol size="12" key={index} onClick={()=> {
                        setShowEditModal(true);
                    }}>
                    <IonCard className="pro-shop-cards">
                      <IonRow className="ion-justify-content-center">
                      <img src={product.product_images.image_1} alt={product.product_name} className="pro-shop-pic" />
                      </IonRow>
                      <IonRow className="ion-text-center ion-justify-content-center">
                          <IonText className="s-14 black wg-600 color-blue">{product.product_name}</IonText>
                      </IonRow>
                      <IonRow className="ion-text-center ion-justify-content-center">
                          <IonText className="s-12 black wg-600 color-blue">{product.product_description}</IonText>
                      </IonRow>
                      <IonRow className="ion-text-center ion-justify-content-center"> 
                          <IonButton className="pro-button">
                            {product.product_value} credits
                          </IonButton>
                      </IonRow>
                    </IonCard>
                    </IonCol>
                  )
                })
              }
              </IonRow>
            </IonGrid>
          ) : proshop_tab_value == "Merchandise" ? (
            <IonGrid>
              <IonRow>
              {
                merchandise_data.map((product: any, index:number) => {
                  return (
                    <IonCol size="6" key={index} onClick={()=> {
                        setShowEditModal(true);
                    }}>
                      <IonCard className="pro-shop-cards">
                        <IonRow className="ion-justify-content-center">
                        <img src={product.product_images.image_1} alt={product.product_name} className="pro-shop-pic" />
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonText className="s-14 black wg-600 color-blue">{product.product_name}</IonText>
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonText className="s-12 black wg-600 color-blue">{product.product_description}</IonText>
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center"> 
                            <IonButton className="pro-button">
                              {product.product_value} credits
                            </IonButton>
                        </IonRow>
                      </IonCard>
                    </IonCol>
                  )
                })
              }
              </IonRow>
            </IonGrid>
          ) : proshop_tab_value == "Donations" ? (
            <IonGrid>
              <IonRow>
              {
                yoc_data.map((product: any, index:number) => {
                  return (
                    <IonCol size="6" key={index} onClick={()=> {
                        setShowEditModal(true);
                    }}>
                      <IonCard className="pro-shop-cards">
                        <IonRow className="ion-justify-content-center">
                        <img src={product.product_images.image_1} alt={product.product_name} className="pro-shop-pic" />
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonText className="s-14 black wg-600 color-blue">{product.product_name}</IonText>
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonText className="s-12 black wg-600 color-blue">{product.product_description}</IonText>
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center"> 
                            <IonButton className="pro-button">
                              {product.product_value} credits
                            </IonButton>
                        </IonRow>
                      </IonCard>
                    </IonCol>
                  )
                })
              }
              </IonRow>
            </IonGrid>
          ) : (
            <></>
          )}
        </IonGrid>
        <IonModal
          isOpen={showEditModal}
          showBackdrop={true}
          className="my-custom-class2"
          backdropDismiss={true}
          onDidDismiss={() => setShowEditModal(false)}
          mode="md"
        >
          <IonHeader mode="ios">
            <IonToolbar>
              <IonTitle>Edit item</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid className="pending-height">
                <IonRow className="ion-text-center ion-justify-content-center vh-25">
                    <IonText className="s-16 black wg-600 color-blue">Coming soon!</IonText>
                </IonRow>
            </IonGrid>
            <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button">
                <IonFabButton
                className="main-fab"
                onClick={() => {
                    setShowEditModal(false);
                }}
                >
                <IonIcon icon={arrowBackOutline} />
                </IonFabButton>
            </IonFab>
          </IonContent>
        </IonModal>
      </IonContent>
      <IonToolbar mode="ios" className="single-segment-toolbar" slot="bottom">
        <IonSegment
          onIonChange={(e) => {
            setProshopTabValue(e.detail.value);
            if (e.detail.value == "Gift Cards") {
              getProShopData("Gift Cards");
            } else if (e.detail.value == "Merchandise") {
              getProShopData("Merchandise");
            } else if(e.detail.value == "Donations") {
              getProShopData("Donations");
            }
          }}
          value={proshop_tab_value}
          mode="ios"
          className="new-segment"
        >
          <IonSegmentButton value="Gift Cards" className="new-segment-button">
            <IonLabel className="new-segment-button-label">Gift Cards</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="Merchandise" className="new-segment-button">
            <IonLabel className="new-segment-button-label">Merchandise</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="Donations" className="new-segment-button">
            <IonLabel className="new-segment-button-label">Donations</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </IonToolbar>
    </IonPage>
  );
};

export default ManageProShop;
