import logo from "../../img/eclub.png";
import "../../css/landing.css";
import {
  IonText,
  IonGrid,
  IonRow,
  isPlatform,
  useIonAlert,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { RefresherEventDetail } from "@ionic/core";
import { App } from "@capacitor/app";
import { Redirect, Route, useHistory } from "react-router-dom";
import firebase from "../../js/firebase.js";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
const db = getFirestore(firebase);

const Landing: React.FC = () => {
  const [showToast1, setShowToast1] = useState(false);
  const [current_app_version, setCurrentAppVersion] = useState<string>();
  const [deployed_app_version, setDeployedAppVersion] = useState<string>();
  const [present] = useIonAlert();
  const history = useHistory();

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    console.log("Begin async operation");

    setTimeout(() => {
      console.log("Async operation has ended");
      event.detail.complete();
      window.location.href = "/";
    }, 2000);
  }
  const getAppDetails = async () => {
    // let app_info = "2.0.30";
    // const q = query(collection(db, "CONFIG"), where("type", "==", "APP_VERSION"));
    // const querySnapshot = await getDocs(q);
    // if(querySnapshot.docs && querySnapshot.docs.length > 0) {
    //   console.log("app_info", app_info);
    //   console.log("device_info", querySnapshot.docs[0].data().value);
    //   let current_deployed_version = querySnapshot.docs[0].data().value;
    //   if(app_info !== current_deployed_version) {
    //     setCurrentAppVersion(app_info);
    //     setDeployedAppVersion(current_deployed_version);
    //     showAlert();
    //   }
    // }
  };
  const showAlert = () => {
    present({
      cssClass: "my-css",
      header: "Update your app",
      message: "You are using an older version; please upgrade to v2.0.30",
      buttons: [
        "Cancel",
        { text: "Ok", handler: (d) => console.log("ok pressed") },
      ],
      onDidDismiss: (e) => console.log("did dismiss"),
    });
  };
  useEffect(() => {
    let user = localStorage.getItem("firebase_user");
    if (user != null) {
      window.location.href = "/leagues/formats";
    }
    if (
      isPlatform("ios") ||
      isPlatform("iphone") ||
      isPlatform("android") ||
      isPlatform("ipad")
    ) {
      getAppDetails();
    }
  }, []);

  return (
    <>
      <IonGrid className="background-image ion-padding pad-l custpd-0">
        <IonRow className="vh-5">
          <img src={logo} className="logo" />
        </IonRow>
        <div className="h-land" >
          <IonRow className="ion-justify-content-center ion-text-center ion-text-blue ">
            <IonText className="color-blue">Already have an account?</IonText>
          </IonRow>

          <IonRow className="mrt-10 mt-10">
            <a
              href="/login"
              className="full-width"
              style={{ textDecoration: "none" }}
            >
              <button className="family-done-button-1 family-send">
                <IonText className="family-next-2">Sign In</IonText>
              </button>
            </a>
          </IonRow>
          <IonRow className="ion-justify-content-center ion-text-center ion-text-blue mt-10">
            <IonText className="color-blue">Don't have an account?</IonText>
          </IonRow>
          <IonRow className="mt-10">
            <a
              href="/register"
              className="full-width"
              style={{ textDecoration: "none" }}
            >
              <button className="family-done-button-1 family-resend">
                <IonText className="family-next-1">Sign Up</IonText>
              </button>
            </a>
          </IonRow>
        </div>
      </IonGrid>
    </>
  );
};

export default Landing;
