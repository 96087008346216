import logo from "../../img/eclub.png";
import "../../css/login.css";
import firebase from "../../js/firebase.js";
import {
  useIonToast,
  IonContent,
  IonItem,
  IonInput,
  IonRippleEffect,
  IonButton,
  IonText,
  IonGrid,
  IonRow,
  IonLoading,
  isPlatform,
  IonModal,
  useIonAlert,
  IonCol,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { RefresherEventDetail } from "@ionic/core";
import { useHistory } from "react-router-dom";
import * as utils from "../../js/utils";
import { createEntryWithoutId } from "../../js/firebase_doc_functions";

import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

const auth = getAuth();
const db = getFirestore(firebase);

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [reset_email, setResetEmail] = useState<string>("");
  const [password, setPassword] = useState("");
  const [current_pack, setCurrentPack] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showToast, hideToast] = useIonToast();
  const history = useHistory();

  const [presentAlert, dismissAlert] = useIonAlert();

  function isEmail(useremail: string) {
    var regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(useremail);
  }

  const Login = async () => {
    if (!isEmail(email)) {
      showToast("Please enter a valid email to login", 2000);
    } else if (password.length < 6) {
      showToast("Invalid password", 2000);
    } else {
      setShowLoading(true);
      signInWithEmailAndPassword(auth, email.toLowerCase().trim(), password)
        .then(async (result) => {
          if (result.user.emailVerified) {
            localStorage.setItem("user_id", result.user.uid);
          } else {
            console.log("You need to verify your email address.");
            setShowLoading(false);
            showToast("Please verify your email", 2000);
          }
        })
        .catch((error) => {
          console.log("dfsfsd", error);
          setShowLoading(false);
          var errorCode = error.code;
          var errorMessage = error.message;
          if (error.code === "auth/user-not-found") {
            showToast("Couldn't match a golfer with these details.", 2000);
          } else if (error.code === "auth/too-many-requests") {
            showToast(
              "Too many failed requests. Please try after some time",
              2000
            );
          } else if (error.code === "auth/wrong-password") {
            showToast("Invalid password", 2000);
          } else {
            showToast(error.code.toString(), 2000);
          }
        });

      onAuthStateChanged(auth, async (user) => {
        if (user) {
          if (user.emailVerified) {
            const uid = user.uid;
            const q = query(
              collection(db, "USER_PROFILE"),
              where("user_id", "==", uid)
            );
            const querySnapshot = await getDocs(q);
            if (querySnapshot.docs && querySnapshot.docs.length > 0) {
              const admin_query = query(
                collection(db, "USER_PROFILE"),
                where("is_portal_admin", "==", true)
              );
              const adminQuerySnapshot = await getDocs(admin_query);
              var admin =
                adminQuerySnapshot.docs && adminQuerySnapshot.docs.length > 0
                  ? adminQuerySnapshot.docs[0].data()
                  : {};
              admin.id =
                adminQuerySnapshot.docs && adminQuerySnapshot.docs.length > 0
                  ? adminQuerySnapshot.docs[0].id
                  : "";

              let user = querySnapshot.docs[0].data();
              user.id = querySnapshot.docs[0].id; //{email:user.email,fcm_token:token,id:user.id}

              localStorage.setItem("firebase_user", JSON.stringify(user));
              const seasonQuerySnapshot = await getDocs(collection(db,"SEASONS_LIST"));
              seasonQuerySnapshot.docs.forEach((doc) => {
                localStorage.setItem("season_name", doc.data().season_name);
              });
              if (user.is_portal_admin) {
                window.location.href = "/admin_home";
              } else {
                if (
                  user.preferred_tee  != undefined &&
                  user.driving_distance  != undefined &&
                  user.gender != undefined &&
                  user.beginning_quota != undefined 
                ) {
                  let source = {
                    id: user.user_id,
                    name: user.first_name + " " + user.last_name,
                    date: new Date().getTime(),
                    image_url: "",
                    ghin_number: user.ghin_number,
                    email: user.email,
                  };
                  await createEntryWithoutId("LOGIN_NOTIFICATIONS", {
                    type: "USER_LOGIN",
                    created_name: user.first_name + " " + user.last_name,
                    created_by: user.user_id,
                    user_id: admin.user_id,
                    user_name: "eClub Admin",
                    source: source,
                    is_read: false,
                  });
                  if (current_pack != null) {
                    //if(current_pack.pack_id != 'kuFOI2OPKCuhCwrewzBqDI8w71IE') $$('.current-eclub').attr('style', 'display: flex !important');
                    //if(current_pack.pack_id == 'kuFOI2OPKCuhCwrewzBqDI8w71IE') $$('.current-eclub').attr('style', 'display: none !important');
                    const packs_query = query(
                      collection(db, "PACKS"),
                      where("pack_id", "==", current_pack.pack_id)
                    );
                    const querySnapshot = await getDocs(packs_query);
                   
                    if (querySnapshot.docs && querySnapshot.docs.length > 0) {
                      localStorage.setItem(
                        "current_pack",
                        JSON.stringify({
                          pack_name: querySnapshot.docs[0].data().pack_name,
                          pack_id: querySnapshot.docs[0].data().pack_id,
                          region_name: querySnapshot.docs[0].data().region_name,
                          region_id: querySnapshot.docs[0].data().region_id,
                          pack_type: querySnapshot.docs[0].data().pack_type,
                          pack_lead_id:
                            querySnapshot.docs[0].data().pack_lead_id,
                        })
                      );
                      setShowLoading(false);
                      window.location.href = "/leagues/formats";
                    } else {
                      localStorage.clear();
                    }
                  } else {
                    //if(user.packs[0].pack_id != 'kuFOI2OPKCuhCwrewzBqDI8w71IE') $$('.current-eclub').attr('style', 'display: flex !important');
                    //if(user.packs[0].pack_id == 'kuFOI2OPKCuhCwrewzBqDI8w71IE') $$('.current-eclub').attr('style', 'display: none !important');
                    const packs_query = query(
                      collection(db, "PACKS"),
                      where("pack_id", "==", user.packs[0].pack_id)
                    );
                    const seasonQuerySnapshot = await getDocs(collection(db,"SEASONS_LIST"));
                    seasonQuerySnapshot.docs.forEach((doc) => {
                      localStorage.setItem("season_name", doc.data().season_name);
                    });
                    
                    const querySnapshot = await getDocs(packs_query);
                    if (querySnapshot.docs && querySnapshot.docs.length > 0) {
                      localStorage.setItem(
                        "current_pack",
                        JSON.stringify({
                          pack_name: querySnapshot.docs[0].data().pack_name,
                          pack_id: querySnapshot.docs[0].data().pack_id,
                          region_name: querySnapshot.docs[0].data().region_name,
                          region_id: querySnapshot.docs[0].data().region_id,
                          pack_type: querySnapshot.docs[0].data().pack_type,
                          pack_lead_id:
                            querySnapshot.docs[0].data().pack_lead_id,
                          city: querySnapshot.docs[0].data().city,
                        })
                      );
                      setShowLoading(false);
                      window.location.href = "/leagues/formats";
                    }
                  }
                } else {
                  let source = {
                    id: user.user_id,
                    name: user.first_name + " " + user.last_name,
                    date: new Date().getTime(),
                    image_url: "",
                    ghin_number: user.ghin_number,
                    email: user.email,
                  };
                  await createEntryWithoutId("LOGIN_NOTIFICATIONS", {
                    type: "USER_LOGIN",
                    created_name: user.first_name + " " + user.last_name,
                    created_by: user.user_id,
                    user_id: admin.user_id,
                    user_name: "eClub Admin",
                    source: source,
                    is_read: false,
                  });
                  if (current_pack != null) {
                    //if(current_pack.pack_id != 'kuFOI2OPKCuhCwrewzBqDI8w71IE') $$('.current-eclub').attr('style', 'display: flex !important');
                    //if(current_pack.pack_id == 'kuFOI2OPKCuhCwrewzBqDI8w71IE') $$('.current-eclub').attr('style', 'display: none !important');
                    const packs_query = query(
                      collection(db, "PACKS"),
                      where("pack_id", "==", current_pack.pack_id)
                    );
                    const querySnapshot = await getDocs(packs_query);
                    if (querySnapshot.docs && querySnapshot.docs.length > 0) {
                      localStorage.setItem(
                        "current_pack",
                        JSON.stringify({
                          pack_name: querySnapshot.docs[0].data().pack_name,
                          pack_id: querySnapshot.docs[0].data().pack_id,
                          region_name: querySnapshot.docs[0].data().region_name,
                          region_id: querySnapshot.docs[0].data().region_id,
                          pack_type: querySnapshot.docs[0].data().pack_type,
                          pack_lead_id:
                            querySnapshot.docs[0].data().pack_lead_id,
                        })
                      );
                      setShowLoading(false);
                      window.location.href = "/questions";
                    } else {
                      localStorage.clear();
                    }
                  } else {
                    //if(user.packs[0].pack_id != 'kuFOI2OPKCuhCwrewzBqDI8w71IE') $$('.current-eclub').attr('style', 'display: flex !important');
                    //if(user.packs[0].pack_id == 'kuFOI2OPKCuhCwrewzBqDI8w71IE') $$('.current-eclub').attr('style', 'display: none !important');
                    const packs_query = query(
                      collection(db, "PACKS"),
                      where("pack_id", "==", user.packs[0].pack_id)
                    );
                    const querySnapshot = await getDocs(packs_query);
                    if (querySnapshot.docs && querySnapshot.docs.length > 0) {
                      localStorage.setItem(
                        "current_pack",
                        JSON.stringify({
                          pack_name: querySnapshot.docs[0].data().pack_name,
                          pack_id: querySnapshot.docs[0].data().pack_id,
                          region_name: querySnapshot.docs[0].data().region_name,
                          region_id: querySnapshot.docs[0].data().region_id,
                          pack_type: querySnapshot.docs[0].data().pack_type,
                          pack_lead_id:
                            querySnapshot.docs[0].data().pack_lead_id,
                          city: querySnapshot.docs[0].data().city,
                        })
                      );
                      setShowLoading(false);
                      window.location.href = "/questions";
                    }
                  }
                }
                // else if(user.ghin_validated){
                //   window.location.href="/questions"
                // }

                // updateEntry('USER_LOGIN', user.id, {zip_code:98760,preferred_tees:"longest",avg_putts:72});
              }
            } else {
              setShowLoading(false);
              console.log(
                "Your account could not be found. Please reach us out at info@eclub.golf"
              );
            }
          } else {
            setShowLoading(false);
            console.log("You need to verify your email address.");
            showToast("Please verify your email", 2000);
          }
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
        } else {
          // User is signed out
          // ...
          console.log("Signed out");
        }
      });
    }
  };
  const sendResetEmail = async () => {
    const q = query(
      collection(db, "USER_PROFILE"),
      where("email", "==", reset_email)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs && querySnapshot.docs.length > 0) {
      await sendPasswordResetEmail(auth, reset_email)
        .then(() => {
          // Password reset email sent!
          // ..
          showToast("Password reset link has been sent to your email", 2000);
          setResetEmail("");
          setShowModal(false);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ..
          setResetEmail("");
          setShowModal(false);
        });
    } else {
      showToast("We couldn't find an account associated with this email", 2000);
    }
  };

  useEffect(() => {
    let user = localStorage.getItem("firebase_user");
    if (user != null) {
      if (
        JSON.parse(user).preferred_tee == undefined ||
        JSON.parse(user).driving_distance == undefined ||
        JSON.parse(user).quota == undefined ||
        JSON.parse(user).gender == undefined
      ) {
        window.location.href = "/questions";
      } else {
        window.location.href = "/leagues/formats";
      }
    }
    let current_pack = localStorage.getItem("current_pack");
    if (current_pack != null) {
      setCurrentPack(current_pack);
    }
    utils.usgaAdminLogin();
  }, []);
  return (
    <>
      <IonGrid className="background-image ion-padding pad-l custpd-0">
        <IonRow className="vh-5">
          <img src={logo} className="logo" />
        </IonRow>
        <div className="h-login">
          <IonRow className="transparent ">
            <IonItem
              lines="none"
              className="login-fields item-bg margin-0 custWid-300"
              style={{ height: "40px" }}
            >
              <IonInput
                value={email}
                onIonChange={(e: any) => {
                  setEmail(e.target.value);
                  if (e.key === "Enter") {
                    Login();
                  }
                }}
                className="login-input"
                placeholder="Email"
              ></IonInput>
            </IonItem>
          </IonRow>
          <IonRow className="transparent mt-5">
            <IonItem
              lines="none"
              className="login-fields item-bg margin-0 custWid-300"
              style={{ height: "40px" }}
            >
              <IonInput
                type="password"
                value={password}
                onIonChange={(e: any) => {
                  setPassword(e.target.value);
                  if (e.key === "Enter") {
                    Login();
                  }
                }}
                className="login-input"
                placeholder="Password"
              ></IonInput>
            </IonItem>
          </IonRow>
          <div className="mt-10 ion-text-center disp-cont">
            <a
              onClick={() => {
                Login();
              }}
              className="full-width"
              style={{ textDecoration: "none" }}
            >
              <IonButton className="signin-button" color="#00365f">
                <IonRippleEffect></IonRippleEffect>
                <IonText className="family-next-2 margin-0">Sign In</IonText>
              </IonButton>
            </a>
          </div>
          {/* <div className="display-flex">
              <IonItem className="no-padding" lines="none">
                <IonInput 
                value={email} 
                onKeyUp={(e: any)=> {
                  setEmail(e.target.value);
                  if(e.key === "Enter") {
                    Login();
                  }
                }} 
                className="emailInput" 
                placeholder="Email">
                </IonInput>
              </IonItem>
            </div>
            <div className="mt-10 display-flex">
              <IonItem className="no-padding" lines="none">
                <IonInput 
                type="password" 
                value={password} 
                onKeyUp={(e: any)=> {
                  setPassword(e.target.value)
                  if(e.key === "Enter") {
                    Login();
                  }
                }} 
                className="emailInput" 
                placeholder="Password">
                </IonInput>
              </IonItem>
            </div> */}
          {/* <div className="mt-10 h-login display-flex">
              <a onClick={()=>{Login()}} className="full-width" style={{textDecoration: "none"}}>
                <IonButton className="signin-button" color="#00365f">
                  <IonRippleEffect></IonRippleEffect>
                    <IonText className="family-next-2">
                      Sign In
                    </IonText>    
                </IonButton>
              </a>
            </div> */}

          {/* <IonButton size="small" className="ion-activatable ripple-parent1" onClick={Login}>
                Login
              <IonRippleEffect></IonRippleEffect>
            </IonButton> */}
          <div className="mt-10 flex-cust">
            <IonRow className="ion-justify-content-center ion-text-center mt-10">
              <IonText
                className="color-blue"
                onClick={() => setShowModal(true)}
              >
                Reset Password
              </IonText>
            </IonRow>
            <IonRow className="ion-justify-content-center ion-text-center mt-10">
              <IonText className="color-black">Don't have an account? </IonText>
              <IonText
                className="pl-5 color-blue"
                onClick={() => {
                  window.location.href = "/register";
                }}
              >
                {" "}
                Sign up
              </IonText>
            </IonRow>
          </div>
        </div>
      </IonGrid>
      <IonModal
        isOpen={showModal}
        className="profile-modal-password"
        swipeToClose={true}
        breakpoints={[0.1, 0.4]}
        initialBreakpoint={0.4}
        onDidDismiss={() => setShowModal(false)}
      >
        <>
          <IonContent>
            <IonGrid className="">
              <IonRow
                className="ion-justify-content-center mt-10"
                style={{ padding: "0px 10px 0px 15px" }}
              >
                <IonText className="s-15 black wg-600">Reset password</IonText>
              </IonRow>

              <IonRow className="ion-justify-content-center mt-10">
                <IonItem className="select-pack-dropdown" lines="none">
                  <IonInput
                    type="text"
                    value={reset_email}
                    onKeyUp={(e: any) => {
                      setResetEmail(e.target.value);
                    }}
                    className="s-14 custom-input"
                    placeholder="email"
                  ></IonInput>
                </IonItem>
              </IonRow>
              <IonRow
                className="ion-justify-content-center mt-10"
                style={{ padding: "0px 10px 0px 15px" }}
              >
                <button
                  className="button-close bg-red white wg-bold"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Close
                </button>
                <button
                  className="button-close bg-blue white wg-bold"
                  onClick={() => {
                    if (reset_email.length == 0) {
                      showToast("Please enter a valid email", 1000);
                    } else {
                      presentAlert({
                        header: "Reset password",
                        message:
                          "Are you sure to proceed with resetting your password?",
                        buttons: [
                          "Cancel",
                          {
                            text: "Yes",
                            handler: () => {
                              sendResetEmail();
                            },
                          },
                        ],
                      });
                    }
                  }}
                >
                  Send Link
                </button>
              </IonRow>
            </IonGrid>
          </IonContent>
        </>
      </IonModal>
      <IonLoading isOpen={showLoading} message={"Logging In.."} />
    </>
  );
};

export default Login;
