import { IonButtons, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonPage, IonRow, IonSegment, IonSegmentButton, IonSelect, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import firebase from '../../js/firebase';
const db = getFirestore(firebase);


const Users: React.FC = () => {
    const [users,setUsers] = useState<any>("Players")
    const [club_name,setClubName] = useState<any>("")
    const [assoc_name,setAssocName] = useState<any>("Central Links Golf")
    const [all_users, setAllUsers] = useState<any>();
    const [pack_admins,setPackAdmins] = useState<any>();

    const getUsers = async() => {
        var condition = where('created_at', '>', 0);
        const q = query(collection(db, "USER_PROFILE"), condition);
    
        const querySnapshot = await getDocs(q);
        var records = new Array();
        let i=1;
        querySnapshot.forEach((doc) => {
            let doc_item = doc.data();
            doc_item.id = doc.id;
            doc_item.number = i
            if(doc_item.tao_validated && doc_item.ghin_validated) {
                doc_item.tier = 'Pro';
            }
            if(!doc_item.tao_validated && doc_item.ghin_validated) {
                doc_item.tier = 'Standard';
            }
            if(!doc_item.tao_validated && !doc_item.ghin_validated) {
                doc_item.tier = 'Basic';
            }
            records.push(doc_item);
            i+=1;
        });
        setAllUsers(records);
    }
    const getPackAdmins = async() => {
        var condition = where('created_at', '>', 0);
        const q = query(collection(db, "PACKS"), condition);
    
        const querySnapshot = await getDocs(q);
        var records = new Array();
        let i=1;
        querySnapshot.forEach((doc) => {
            let doc_item = doc.data();
            records.push({'s_no':i,'user_name': doc_item.user_name, 'city': doc_item.city, 'pack_name': doc_item.pack_name});
            i+=1
        });
        setPackAdmins(records);
    }
    
    useEffect(() => {
        getUsers();
        getPackAdmins();
    }, []);
    // console.log("users",all_users)
    // console.log("packs",pack_admins)
    const slideOpts = {
        initialSlide: 0,
        speed: 400
      };
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const handleClick = () => {
        hiddenFileInput.current!.click()
        // console.log('hi',refInput.current)
    }
    const processCSV = (str:any, delim=',') => {
        console.log("str",str)
        const headers = str.slice(0,str.indexOf('\n')).split(delim);
        const rows = str.slice(str.indexOf('\n')+1).split('\n');

        const newArray = rows.map( (row:any) => {
            const values = row.split(delim);
            const eachObject = headers.reduce((obj:any, header:any, i:any) => {
                obj[header] = values[i];
                return obj;
            }, {})
            return eachObject;
        })

        console.log(newArray)
    }
    const customAlertOptions1 = {
        header: 'Select Club',
      };
      const columns = [
        {
            name: 'S.No',
            selector: (row:any) => row.number,
            width:"60px"
        },
        {
            name: 'Player',
            selector: (row:any) => row.first_name+" "+row.last_name,
            sortable: true,
            width:"150px"
        },
        {
            name: 'Email',
            selector: (row:any) => row.email,
            width:"210px"
        },
        {
            name: 'GHIN',
            selector: (row:any) => row.ghin_number,
        },
        {
            name: 'City',
            selector: (row:any) => row.city,
        },
        {
            name: 'Tier',
            selector: (row:any) => row.tier,
        },

    ];
    const columns1 = [
        {
            name: 'S.No',
            selector: (row:any) => row.s_no,
            width:"60px"
        },
        {
            name: 'Player',
            selector: (row:any) => row.user_name,
            sortable: true,
            width:"150px"
        },
        {
            name: 'Pack',
            selector: (row:any) => row.pack_name,
            width:"180px"
        },
        {
            name: 'City',
            selector: (row:any) => row.city,
        },
    ];
    
    const data = [
        {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        },
    ]
  
    return (
        <IonPage>
        <IonHeader>
          <IonToolbar mode="ios">
            <IonButtons slot="start">
                <a onClick={()=> window.history.back()}>
                    <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                </a>
            </IonButtons>
            <IonTitle>
               All Users
            </IonTitle>
          </IonToolbar>
          <IonToolbar mode="ios" className="segment-toolbar">
            <IonSegment onIonChange={(e:any) => {                                
                    setUsers(e.detail.value)                                
                    }} value={users} mode="ios">
                    <IonSegmentButton value="Players" className="max-wid-toolbar" style={{minWidth:"50%"}}>
                    <IonLabel>Players</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="Pack Admins" className="max-wid-toolbar" style={{minWidth:"50%"}}>
                    <IonLabel>Pack Admins</IonLabel>
                    </IonSegmentButton>
                    {/* <IonSegmentButton value="Region Admins" className="max-wid-toolbar" style={{minWidth:"30%"}}>
                    <IonLabel>Region Admins</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="Upload" className="max-wid-toolbar" style={{minWidth:"20%"}}>
                    <IonLabel>Upload</IonLabel>
                    </IonSegmentButton> */}
                </IonSegment>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            {
                (users=="Players")?(
                    <DataTable
                        columns={columns}
                        data={all_users}
                        pagination
                    />
                    // <></>
                //     <IonGrid>
                //     <IonSlides options={slideOpts} scrollbar={true} style={{overflowX:"auto"}}>
                //     <br/><br/><br/>
                //         <IonSlide key={0}>
                //             <IonCard className="users-card">
                //             <IonRow className="ion-justify-content-center ion-text-center bb-line">
                //                 <IonCol size="2">
                //                     <IonText className="s-14 wg-600 color-gray">S.No</IonText>
                //                 </IonCol>
                //                 <IonCol size="3">
                //                     <IonText className="s-14 wg-600 color-gray">Player</IonText>
                //                 </IonCol>
                //                 <IonCol size="7">
                //                 <IonText className="s-14 wg-600 color-gray">Email</IonText>
                //                 </IonCol>
                //             </IonRow>
                //             <IonRow className="ion-justify-content-center ion-text-center bb-line">
                //                 <IonCol size="2">
                //                     <IonText className="s-14 color-black wg-500">1</IonText>
                //                 </IonCol>
                //                 <IonCol size="3">
                //                     <IonText className="s-14 color-black wg-500">Michael Buckhouse</IonText>
                //                 </IonCol>
                //                 <IonCol size="7">
                //                 <IonText className="s-14 color-black wg-600">whatmeworry78@yahoo.com</IonText>
                //                 </IonCol>
                //             </IonRow>
                //             </IonCard>
                //         </IonSlide>
                //         <IonSlide key={1}>
                //         <IonCard className="users-card">
                //             <IonRow className="ion-justify-content-center ion-text-center bb-line">
                //                 <IonCol size="3">
                //                     <IonText className="s-14 wg-600 color-gray">GHIN</IonText>
                //                 </IonCol>
                //                 <IonCol size="6">
                //                     <IonText className="s-14 wg-600 color-gray">City</IonText>
                //                 </IonCol>
                //                 <IonCol size="3">
                //                 <IonText className="s-14 wg-600 color-gray">Tier</IonText>
                //                 </IonCol>
                //             </IonRow>
                //             <IonRow className="ion-justify-content-center ion-text-center bb-line">
                //                 <IonCol size="3">
                //                     <IonText className="s-14 color-black wg-500">1118246</IonText>
                //                     <IonText className="s-14 color-black wg-500" style={{visibility:"hidden"}}>1118246</IonText>
                //                 </IonCol>
                //                 <IonCol size="6">
                //                     <IonText className="s-14 color-black wg-500">Kansas city
                //                     </IonText>
                //                 </IonCol>
                //                 <IonCol size="3">
                //                 <IonText className="s-14 color-black wg-600">Standard</IonText>
                //                 </IonCol>
                //             </IonRow>
                //             </IonCard>
                //         </IonSlide>
                //     </IonSlides>
                // </IonGrid>
    
                ):(users == "Pack Admins")?(
                    <DataTable
                        columns={columns1}
                        data={pack_admins}
                        pagination
                    />
                    // <></>
                    // <IonGrid>
                    //     <IonCard className="users-card">
                    //         <IonRow className="ion-justify-content-center ion-text-center bb-line">
                    //             <IonCol size="2">
                    //                 <IonText className="s-14 wg-600 color-gray">S.No</IonText>
                    //             </IonCol>
                    //             <IonCol size="3">
                    //                 <IonText className="s-14 wg-600 color-gray">Player</IonText>
                    //             </IonCol>
                    //             <IonCol size="4">
                    //             <IonText className="s-14 wg-600 color-gray">Pack</IonText>
                    //             </IonCol>
                    //             <IonCol size="3">
                    //             <IonText className="s-14 wg-600 color-gray">City</IonText>
                    //             </IonCol>
                    //         </IonRow>
                    //         <IonRow className="ion-justify-content-center ion-text-center bb-line">
                    //             <IonCol size="2">
                    //                 <IonText className="s-13 color-black wg-500">1</IonText>
                    //             </IonCol>
                    //             <IonCol size="3">
                    //                 <IonText className="s-13 color-black wg-500">Michael Buckhouse</IonText>
                    //             </IonCol>
                    //             <IonCol size="4">
                    //             <IonText className="s-13 color-black wg-500">OP Golf Bros</IonText>
                    //             </IonCol>
                    //             <IonCol size="3">
                    //             <IonText className="s-13 color-black wg-500">Kansas City</IonText>
                    //             </IonCol>
                    //         </IonRow>
                    //         </IonCard>

                    // </IonGrid>
                ):(users == "Region Admins")?(
                    <IonGrid>
                        <IonCard className="users-card">
                            <IonRow className="ion-justify-content-center ion-text-center bb-line">
                                <IonCol size="1.5">
                                    <IonText className="s-14 wg-600 color-gray">S.No</IonText>
                                </IonCol>
                                <IonCol size="3">
                                    <IonText className="s-14 wg-600 color-gray">Player</IonText>
                                </IonCol>
                                <IonCol size="4.5">
                                <IonText className="s-14 wg-600 color-gray">Email</IonText>
                                </IonCol>
                                <IonCol size="3">
                                <IonText className="s-14 wg-600 color-gray">Region</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center ion-text-center bb-line">
                                <IonCol size="1.5">
                                    <IonText className="s-13 color-black wg-500">1</IonText>
                                </IonCol>
                                <IonCol size="3">
                                    <IonText className="s-13 color-black wg-500">Michael Buckhouse</IonText>
                                </IonCol>
                                <IonCol size="4.5">
                                <IonText className="s-13 color-black wg-500">whatmeworry78@yahoo.com</IonText>
                                </IonCol>
                                <IonCol size="3">
                                <IonText className="s-13 color-black wg-500">Cental Links Golf</IonText>
                                </IonCol>
                            </IonRow>
                            </IonCard>

                    </IonGrid>
                ):(
                    <IonGrid>
                        <IonCard>
                            <IonRow className="ion-justify-content-center ion-text-center">
                                <IonText className="s-18 wg-bold black">
                                    Only CSV/Excel files are accepted
                                </IonText>
                            </IonRow>
                            <IonRow className="ion-justify-content-center mt-10">
                                <IonSelect value={club_name} 
                                interfaceOptions={customAlertOptions1}
                                interface="popover"                        
                                placeholder="Club Name" className="s-14 select-pack-dropdown" 
                                onIonChange={e => setClubName(e.detail.value!)} mode="ios">
                                {/* {
                                    (course_list && course_list.map((key:any,i:number)=>{
                                    return(
                                        <IonSelectOption value={key.CourseID} key={i}>{key.FullName}</IonSelectOption>
                                    )
                                    }))
                                }   */}
                                </IonSelect>
                            </IonRow>


                            <IonRow className="ion-justify-content-center mt-10 mb-10">
                                <IonItem lines="none" className="select-pack-dropdown" style={{height: "40px"}}>
                                    <IonInput
                                    style={{paddingTop: '5px'}}
                                    value={assoc_name}  
                                    className="s-14 custom-input"                      
                                    placeholder="Central Links Golf"
                                    disabled={true}
                                    onIonChange={(e) => setAssocName(e.detail.value!)}
                                    // clearInput
                                    ></IonInput>
                                </IonItem>
                            </IonRow>
                        </IonCard>
                    </IonGrid>
                )
            }
        </IonContent>
        </IonPage>
    )
};

export default Users;