import { collection, query } from '@firebase/firestore';
import { IonContent, IonPage, IonTitle, IonToolbar, IonLabel, IonButtons, IonGrid, IonSegment, IonSegmentButton, IonText, isPlatform, IonDatetime, IonModal, IonCol, IonRow, IonFab, IonFabButton, IonIcon, IonCard, IonHeader } from '@ionic/react';
import { format, nextSunday, startOfWeek } from 'date-fns';
import { getDocs, getFirestore, where } from 'firebase/firestore';
import { arrowBackOutline } from 'ionicons/icons';
import _ from 'lodash';
import { useParams } from "react-router";
import { useEffect, useState } from 'react';
import bronze_medal from '../../img/bronze.png';
import silver_medal from '../../img/silver.png';
import gold_medal from '../../img/gold.png';
import firebase from '../../js/firebase';
import ScoreTable from '../Components/nine_holes_score_table';
import Table from '../Components/score_table';
import NSScoreTable from '../Components/table';

const db = getFirestore(firebase);
const EventLeaderboard: React.FC = () => {
    let { id }: any = useParams();
    const [current_user, setCurrentUser] = useState<any>([]);
    const [users, setUsers] = useState<any>("Players");
    const [score_record, setScoreRecord] = useState<any>([]);
    const [score_format, setScoreFormat] = useState<string>('');
    const [score_modal, setScoreModal] = useState<any>(false)
    const [peoria_leaderboard_data, setPeoriaLeaderboardData] = useState<any>([]);
    const [netscore_leaderboard_data, setNetscoreLeaderboardData] = useState<any>([]);
    const [leaderboards_tab_value, setLeaderboardsTabValue] = useState<string>('Netscore');
    const [showLeaderboardPicker, setShowLeaderboardPicker] = useState<boolean>(false);
    const [season_name, setSeasonName] = useState<string>('');
    const [tour_name, setTourName] = useState<string>('');
    const [holes, setHoles] = useState<number>(0);
    const [selected_date, setSelectedDate] = useState<any>(format(startOfWeek(new Date(), { weekStartsOn: 0 }), "yyyy-MM-dd"));
    const getLeaderBoards = async (leaderboards_tab_value: string, date_to: string) => {
        if (leaderboards_tab_value == 'Peoria') {
            const q = query(collection(db, "EVENT_PEORIA_LEADERBOARDS"), where("event_id", "==", id));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.docs && querySnapshot.docs.length > 0) {
                await Promise.all(
                    querySnapshot.docs.map((item: any) => {
                        var doc_item = item.data();
                        doc_item.id = item.id;
                        setTourName(doc_item.event_name);
                        setHoles(doc_item.holes);
                        return doc_item;
                    })
                ).then((results) => {
                    //setTeeTimes(_.sortBy(results, (e: any) => e.created_at).reverse());
                    let records = JSON.parse(JSON.stringify(results));
                    if (records.length > 0) {
                        records = _.orderBy(records[0].records, ['net_score'], ['desc']);
                        records = records.map((record: any) => {
                            if (record.medal_type == undefined || record.medal_type > 3) {
                                record.medal_type = 0
                            }
                            if (record.net_score == 0) {
                                record.net_score = 'E';
                            }
                            return record;
                        });
                    } else {
                        records = [];
                    }
                    setPeoriaLeaderboardData(records);
                });
            } else {
                setPeoriaLeaderboardData([]);
            }
        } else if (leaderboards_tab_value == 'Netscore') {
            const q = query(collection(db, "EVENT_NS_LEADERBOARDS"), where("event_id", "==", id));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.docs && querySnapshot.docs.length > 0) {
                await Promise.all(
                    querySnapshot.docs.map((item: any) => {
                        var doc_item = item.data();
                        doc_item.id = item.id;
                        return doc_item;
                    })
                ).then((results) => {
                    let records = JSON.parse(JSON.stringify(results));
                    if (records.length > 0) {
                        records = _.orderBy(records[0].records, ['net_score'], ['desc']);
                        records = records.map((record: any) => {
                            if (record.medal_type == undefined || record.medal_type > 3) {
                                record.medal_type = 0
                            }
                            if (record.net_score == 0) {
                                record.net_score = 'E';
                            }
                            return record;
                        });
                    } else {
                        records = [];
                    }
                    setNetscoreLeaderboardData(records);
                });
            } else {
                setNetscoreLeaderboardData([]);
            }
        }
    }
    const showScoreRecord = async (idx: number) => {
        let record = netscore_leaderboard_data[idx] || {};
        console.log(record);
        record.date = record.played_at;
        let courseStr = record.course_name.split("-");
        if (courseStr[0] != undefined || courseStr[0] != null)
            record.course_name = courseStr[0];
        if (courseStr[1] != undefined || courseStr[1] != null)
            record.course_sub_name = courseStr[1];
        if (record.user_name) {
            // console.log("score record",score_record)
            setScoreRecord(record)
            setScoreFormat("Netscore");
            setScoreModal(true)
        }
    }
    const showPeoriaScoreRecord = async (idx: number) => {
        let record = peoria_leaderboard_data[idx] || {};
        console.log(record);
        record.date = record.played_at;
        let courseStr = record.course_name.split("-");
        if (courseStr[0] != undefined || courseStr[0] != null)
            record.course_name = courseStr[0];
        if (courseStr[1] != undefined || courseStr[1] != null)
            record.course_sub_name = courseStr[1];
        if (record.user_name) {
            // console.log("score record",score_record)
            setScoreRecord(record)
            setScoreFormat("Peoria");
            setScoreModal(true)
        }
    }
    useEffect(() => {
        let user = localStorage.getItem('firebase_user');
        if (user != null) {
            setCurrentUser(JSON.parse(user));
            getLeaderBoards("Netscore", format(startOfWeek(new Date(), { weekStartsOn: 0 }), "yyyy-MM-dd"))
        }
       
    }, [])
    return (
        <IonPage>
            <IonToolbar
            mode="ios"
            className={
              isPlatform("ios")
                ? "ios-toolbar-top box-shadow"
                : "toolbar-top box-shadow"
            }
          >
            <IonLabel slot="start" className="s-16 w-800 color-blue" style={{padding: '0px 0px 0px 10px'}}>
                Leaderboards
            </IonLabel>
            <IonLabel slot="end" className="s-16 w-800 color-blue" style={{padding: '0px 0px 0px 10px'}}>
              
            </IonLabel>
          </IonToolbar>
          <IonContent>
                <>
                    {
                        leaderboards_tab_value == "Peoria" ? (
                            <IonGrid>
                                <IonRow className="ion-justify-content-center ion-text-center bb-line">
                                    <IonCol size="1">
                                        <IonText className="s-11 wg-600">Pos</IonText>
                                    </IonCol>
                                    <IonCol size="5">
                                        <IonText className="s-11 wg-600">Player</IonText>
                                    </IonCol>
                                    <IonCol size="2">
                                        <IonText className="s-11 wg-600">Peoria<br/>Target</IonText>
                                    </IonCol>
                                    <IonCol size="2">
                                        <IonText className="s-11 wg-600">Total<br/>Score</IonText>
                                    </IonCol>
                                    <IonCol size="2">
                                        <IonText className="s-11 wg-600">Net<br/>Score</IonText>
                                    </IonCol>
                                </IonRow>
                                {
                                    peoria_leaderboard_data && peoria_leaderboard_data.map((item: any, index: number) => {
                                        return (
                                            <IonRow className="ion-justify-content-center ion-text-center bb-line" key={index}>
                                                <IonCol size="1">
                                                    <IonText className="s-12 wg-600">{item.position}</IonText>
                                                </IonCol>
                                                <IonCol size="5">
                                                    <IonText className="s-12 wg-600 color-red" onClick={() => { showPeoriaScoreRecord(index) }}>{item.user_name}</IonText>
                                                </IonCol>
                                                <IonCol size="2">
                                                    <IonText className="s-12 wg-600">{item.peoria_quota}</IonText>
                                                </IonCol>
                                                <IonCol size="2">
                                                    <IonText className="s-12 wg-600">{item.points.total_points}</IonText>
                                                </IonCol>
                                                <IonCol size="2">
                                                    <IonText className="s-12 wg-600">{item.net_score}</IonText>
                                                </IonCol>
                                            </IonRow>
                                        )
                                    })
                                }
                            </IonGrid>
                        ) : leaderboards_tab_value == "Netscore" ? (
                            <IonGrid>
                                <IonRow className="ion-justify-content-center ion-text-center bb-line">
                                    <IonCol size="1">
                                        <IonText className="s-11 wg-600">Pos</IonText>
                                    </IonCol>
                                    <IonCol size="5">
                                        <IonText className="s-11 wg-600">Player</IonText>
                                    </IonCol>
                                    <IonCol size="2">
                                        <IonText className="s-11 wg-600">Target</IonText>
                                    </IonCol>
                                    <IonCol size="2">
                                        <IonText className="s-11 wg-600">Score</IonText>
                                    </IonCol>
                                    <IonCol size="2">
                                        <IonText className="s-11 wg-600">Net<br/>Score</IonText>
                                    </IonCol>
                                </IonRow>
                                {
                                    netscore_leaderboard_data && netscore_leaderboard_data.map((item: any, index: number) => {
                                        return (
                                            <IonRow className="ion-justify-content-center ion-text-center bb-line" key={index}>
                                                <IonCol size="1">
                                                    <IonText className="s-12 wg-600">{item.position}</IonText>
                                                </IonCol>
                                                <IonCol size="5">
                                                    <IonText className="s-12 wg-600 color-red" onClick={() => { showScoreRecord(index) }}>{item.user_name}</IonText>
                                                </IonCol>
                                                <IonCol size="2">
                                                    <IonText className="s-12 wg-600">{item.quota}</IonText>
                                                </IonCol>
                                                <IonCol size="2">
                                                    <IonText className="s-12 wg-600">{item.points.total_points}</IonText>
                                                </IonCol>
                                                <IonCol size="2">
                                                    <IonText className="s-12 wg-600 color-blue">{item.net_score}</IonText>
                                                </IonCol>
                                            </IonRow>
                                        )
                                    })
                                }
                            </IonGrid>
                        ) : (
                            <></>
                        )
                    }
                </>
                {holes && holes === 9 ?
                    <>
                        <IonModal isOpen={score_modal} className='my-custom-class' onDidDismiss={() => setScoreModal(false)}>
                            <>
                                <IonHeader>
                                    <IonToolbar>
                                        {/* <IonButtons slot="start">
                            <a onClick={()=> setScoreModal(false)}>
                                <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                            </a>
                        </IonButtons> */}
                                        <IonLabel slot="start" className="s-16 w-600 color-blue">
                                            {score_record.course_name}-{score_record.tee_name}
                                        </IonLabel>
                                    </IonToolbar>
                                </IonHeader>
                                <IonContent>
                                    <ScoreTable options={score_record} holes={holes} />
                                    <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{ marginBottom: '45px' }}>
                                        <IonFabButton
                                            className="main-fab"
                                            onClick={() => {
                                                setScoreModal(false)
                                            }}
                                        >
                                            <IonIcon icon={arrowBackOutline} />
                                        </IonFabButton>
                                    </IonFab>
                                </IonContent>
                            </>
                        </IonModal>
                        <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{ marginBottom: '25px' }}>
                            <IonFabButton
                                className="main-fab"
                                onClick={() => {
                                    window.location.href = "/noticeboard";
                                }}
                            >
                                <IonIcon icon={arrowBackOutline} />
                            </IonFabButton>
                        </IonFab>
                    </> :
                    <>
                        <IonModal isOpen={score_modal} className='my-custom-class' onDidDismiss={() => setScoreModal(false)}>
                            <>
                                <IonHeader>
                                    <IonToolbar>
                                        {/* <IonButtons slot="start">
                            <a onClick={()=> setScoreModal(false)}>
                                <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                            </a>
                        </IonButtons> */}
                                        <IonLabel slot="start" className="s-16 w-600 color-blue">
                                            {score_record.course_name}-{score_record.tee_name}
                                        </IonLabel>
                                    </IonToolbar>
                                </IonHeader>
                                <IonContent>
                                    { 
                                        score_format == 'Peoria' ? (
                                            <Table options={score_record} />
                                        ) : (
                                            <NSScoreTable options={score_record} holes={18} front9={false} back9={false}  />
                                        )
                                    }
                                    <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{ marginBottom: '45px' }}>
                                        <IonFabButton
                                            className="main-fab"
                                            onClick={() => {
                                                setScoreModal(false)
                                            }}
                                        >
                                            <IonIcon icon={arrowBackOutline} />
                                        </IonFabButton>
                                    </IonFab>
                                </IonContent>
                            </>
                        </IonModal>
                        <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{ marginBottom: '25px' }}>
                            <IonFabButton
                                className="main-fab"
                                onClick={() => {
                                    window.location.href = "/noticeboard";
                                }}
                            >
                                <IonIcon icon={arrowBackOutline} />
                            </IonFabButton>
                        </IonFab>
                    </>
                }

            </IonContent>
            <IonToolbar mode="ios" className="single-segment-toolbar" slot="bottom">
                <IonSegment
                    onIonChange={(e) => {
                        console.log("tab1 Segment selected", e.detail.value);
                        setLeaderboardsTabValue(e.detail.value!);
                        if (e.detail.value == "Peoria") {
                            getLeaderBoards("Peoria", selected_date);
                        } else if (e.detail.value == "Netscore") {
                            getLeaderBoards("Netscore", selected_date);
                        }
                    }}
                    value={leaderboards_tab_value}
                    mode="ios"
                    className="new-segment"
                >
                    <IonSegmentButton value="Peoria" className="new-segment-button">
                        <IonLabel className="new-segment-button-label">Peoria</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="Netscore" className="new-segment-button">
                        <IonLabel className="new-segment-button-label">Netscore</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
            </IonToolbar>
        </IonPage>
    );
};
export default EventLeaderboard;