import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import { useEffect } from 'react';

interface Props{
    onQuill: any,
    content: string,
}

const Quill: React.FC<Props> = (props)  => {
    const theme = 'snow';
    // const theme = 'bubble';
    const modules = {
      toolbar: [
        ['bold', 'italic', 'underline'],
        ['image'],
      ],
    };
    const placeholder = 'About';
    const formats = ['bold', 'italic', 'underline','image'];
    
    const { quill, quillRef } = useQuill({ theme, modules, formats, placeholder });

    useEffect(() => {
        if (quill) {
          quill.on('text-change', (delta:any, oldDelta:any, source:any) => {
            props.onQuill(quill.root.innerHTML);
          });
          if(props.content.length > 0) {
            quill.root.innerHTML = props.content;
          }
        }
    }, [quill]);
    return(
        <>
            <div style={{ width: '90%', height: 230,margin:"auto" }}>
            <div ref={quillRef} style={{ width: '100%', height: 180,margin:"auto" }}/>
            </div>  
        </>
    )
}
export default Quill;