import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
    PayPalButtons, usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { createEntryWithoutId, updateEntry } from '../../js/firebase_doc_functions';
import { useIonToast } from '@ionic/react';

interface Props{
    paypal_data:  {},
    referrer: string,
    required_data: {},
    amount: number,
    chips_qty: number,
    currency: "USD",
    showSpinner: false,
    parentCallback: any
}
const PayPal: React.FC<Props> = (props)  => {
    const [amount, setAmount] = useState<number>(props.chips_qty*0.1);
    const [total_price, setTotalPrice] = useState<number>((props.chips_qty*0.1)+Number(((((props.chips_qty*0.1)*3.49)/100)+0.60).toFixed(2)));
    const [chip_qty, setChipQty] = useState<number>(props.chips_qty);
    const [paypal_data, setPaypalData] = useState<any>(props.paypal_data);
    const [show_spinner, setShowSpinner] = useState<any>(props.showSpinner);
    const [referrer, setReferrer] = useState<any>(props.referrer);
    const [showToast, hideToast] = useIonToast();
    const [required_data, setRequiredData] = useState<any>(props.required_data);
    const [currency, setCurrency] = useState<string>(props.currency);
    const layout_style = {"layout": "vertical", "shape": "pill", "color": "blue", "label": "paypal"};
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const [current_user, setCurrentUser] = useState<any>();
    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: paypal_data,
        });
        let user = localStorage.getItem('firebase_user');
        if(user!= null) {
            setCurrentUser(JSON.parse(user));
        }
    }, ["USD", props.showSpinner]);
    return (<>
        { (show_spinner && isPending) && <div className="spinner" /> }
            <PayPalButtons
                style={{"layout":"vertical", "shape": "pill", "color": "blue", "label": "paypal"}}
                disabled={false}
                forceReRender={[total_price.toString(), "USD", layout_style]}
                fundingSource={undefined}
                className='paypal-width'
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: "USD",
                                        value: total_price.toString(),
                                    },
                                },
                            ],
                        })
                        .then((orderId) => {
                            // Your code here after create the order
                            return orderId;
                        });
                }}
                onApprove={function (data:any, actions:any) {
                    return actions.order.capture().then(async function (details:any) {
                        if(referrer == 'chips') {
                            await updateEntry("USER_PROFILE", current_user.id, {"chips": current_user.chips+chip_qty});
                            await createEntryWithoutId("CHIPS_TRANSACTION_LOGS", {
                                "user_id": current_user.user_id,
                                "type": 'Credit',
                                "date": format(new Date(), "MM/DD/YYYY"),
                                "status": 'Success',
                                "credit_amount": chip_qty,
                                "balance": current_user.chips+chip_qty
                            });
                            await createEntryWithoutId("TRANSACTION_LOGS", {
                                "user_id": current_user.user_id,
                                "chip_price": amount,
                                "coupon_code": '',
                                "type": 'CHIPS_PAYMENT',
                                "status": 'Success',
                                "log": details
                            });
                            current_user.chips = current_user.chips+chip_qty;
                            localStorage.setItem("firebase_user", JSON.stringify(current_user));
                            alert(
                                "<b>"+chip_qty+"</b> chips are added to your balance successfully."
                            );
                            window.location.href = window.location.pathname;
                        } else if(referrer == 'ppw') {
                            await createEntryWithoutId("TRANSACTION_LOGS", {
                                "user_id": current_user.user_id,
                                "chip_price": amount,
                                "coupon_code": '',
                                "type": 'PPW_PAYMENT',
                                "status": 'Success',
                                "log": details
                            });
                            props.parentCallback("Done");
                        } else if(referrer == 'tt') {
                            await createEntryWithoutId("TRANSACTION_LOGS", {
                                "user_id": current_user.user_id,
                                "chip_price": amount,
                                "coupon_code": '',
                                "type": 'TT_PAYMENT',
                                "status": 'Success',
                                "log": details,
                                "tour": required_data.tour_name || ''
                            });
                            props.parentCallback("Done");
                        } else if(referrer == 'new_tt') {
                            await createEntryWithoutId("TRANSACTION_LOGS", {
                                "user_id": current_user.user_id,
                                "email": current_user.email,
                                "chip_price": amount,
                                "coupon_code": '',
                                "type": 'TT_PAYMENT',
                                "status": 'Success',
                                "log": details,
                                "tour": required_data.tour_name || ''
                            });
                            props.parentCallback("Done");
                        } else if(referrer == 'netscore') {
                            await createEntryWithoutId("TRANSACTION_LOGS", {
                                "user_id": current_user.user_id,
                                "email": current_user.email,
                                "chip_price": amount,
                                "coupon_code": '',
                                "type": 'NETSCORE_PAYMENT',
                                "status": 'Success',
                                "log": details
                            });
                            props.parentCallback("Done");
                        } else if(referrer == 'events') {
                            await createEntryWithoutId("TRANSACTION_LOGS", {
                                "user_id": current_user.user_id,
                                "chip_price": amount,
                                "coupon_code": '',
                                "type": 'EVENT_PAYMENT',
                                "status": 'Success',
                                "log": details,
                                "tour": required_data.event_id || ''
                            });
                            props.parentCallback("Done");
                        } else if(referrer == 'tao') {
                            await updateEntry("USER_PROFILE", current_user.id, {"tao_validated": true});
                            await createEntryWithoutId("TRANSACTION_LOGS", {
                                "user_id": current_user.user_id,
                                "chip_price": amount,
                                "coupon_code": '',
                                "type": 'TAO_PAYMENT',
                                "status": 'Success',
                                "log": details
                            });
                            window.location.href = "/leagues/formats";
                        }
                    });
                }}
            />
        </>
    );
}

export default PayPal;
