import { IonGrid, IonRow, IonCol, IonText } from '@ionic/react';
import { key } from 'ionicons/icons';
import { useEffect, useState } from 'react';


interface Props {
    options: {},
    holes: number,
    tour_format: {}
}

const NineHolesScoreTable: React.FC<Props> = (props) => {

    let obj = props.options;
    const holes = props.holes;
    let t_format = props.tour_format;
    const [val, setVal] = useState<any>(obj);
    const [tour_format, setTourFormat] = useState<string>(t_format);
    const [front_par_total, setFrontParTotal] = useState<any>();
    const [front_score_total, setFrontScoreTotal] = useState<any>();
    const [front_points_total, setFrontPointsTotal] = useState<any>();
    const [back_par_total, setBackParTotal] = useState<any>();
    const [back_score_total, setBackScoreTotal] = useState<any>();
    const [back_points_total, setBackPointsTotal] = useState<any>();
    const [random_six_hole_points, setRandomSixHolePoints] = useState<number>(0);
    const [finalTarget,setFinalTarget] = useState<number>(0);
    const [finalScore,setFinalScore] = useState<number>(0);
    //console.log("obj",obj)
    const setTotalValues = async (data: any) => {
        let front_pars = 0;
        let front_scores = 0;
        let front_points = 0;
        let back_pars = 0;
        let back_scores = 0;
        let back_points = 0;
        data && data.hole_details.forEach((key: any, i: number) => {
            if (key.hole_number <= 9) {

                front_pars += key.par

                front_scores += key.raw_score

                front_points += key.points

            } else {
                back_pars += key.par

                back_scores += key.raw_score

                back_points += key.points
            }
        });
        setFrontParTotal(front_pars);
        setFrontScoreTotal(front_scores);
        setFrontPointsTotal(front_points);
        setBackParTotal(back_pars);
        setBackScoreTotal(back_scores);
        setBackPointsTotal(back_points);
    }
    const calculateRandomHolePoints = () => {
        let sixHolePoints = 0;
        if (val && val.hole_details && val.hole_details.length > 0) {
            val.hole_details.forEach((hole: any) => {
                if (hole.is_picked) {
                    sixHolePoints += hole.points;
                }
            });
            setRandomSixHolePoints(sixHolePoints);
            setFinalScore(val.net_score);
            setFinalTarget(Math.floor(sixHolePoints * 1.5) > Math.ceil(val.quota/2) ? Math.floor(sixHolePoints * 1.5) : Math.ceil(val.quota/2));
        }
    }
    useEffect(() => {
        if (val) {
            console.log(val);
            //generateRandomNumbers();
            calculateRandomHolePoints();
            setTotalValues(obj);
        }
    }, [val && val.first_name]);
    const [val2, setVal2] = useState<any>(["Eagle", "Birdie", "Par", "Bogey", "Double", "Triple"])

    return (
        <>
            <IonGrid className="">
                <IonRow className="ion-justify-content-center ion-text-center mt-10">
                    <IonText className="black s-18">{val && val.user_name}</IonText>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center">
                    <IonText className="black s-16">{val && val.date}</IonText>
                </IonRow>

            </IonGrid>

            <IonGrid>
                {
                    (holes && holes == 9 && val.tee_type == "Front") ? (
                        <>
                            <IonRow className="ion-justify-content-center ion-text-center">
                                <IonText className="wg-600 black">Front 9</IonText>
                            </IonRow>
                            <IonRow className="points-table-row-color">
                                <IonCol size="2" style={{ color: "black" }}>
                                    Hole
                                </IonCol>
                                {
                                    val && val.hole_details.map((key: any, i: number) => {
                                        // console.log("key",key)
                                        if (key.hole_number <= 9) {
                                            return (
                                                <IonCol size="1" style={{ color: "black" }} key={i}>
                                                    {key.hole_number}
                                                </IonCol>

                                            )

                                        }
                                    })
                                }
                                <IonCol size="1" style={{ color: "black" }}>
                                    Tot
                                </IonCol>
                            </IonRow>
                            {
                                (tour_format && tour_format != "3~Intl Peoria") ? (
                                    <>
                                        <IonRow className="points-table-row-color">
                                            <IonCol size="2" style={{ color: "black" }}>
                                                Par
                                            </IonCol>
                                            {
                                                val && val.hole_details.map((key: any, i: number) => {
                                                    // console.log("key",key)
                                                    if (key.hole_number <= 9) {
                                                        return (
                                                            <IonCol size="1" style={{ color: "black" }} key={i}>
                                                                {key.par}
                                                            </IonCol>

                                                        )

                                                    }
                                                })
                                            }
                                            <IonCol size="1" style={{ color: "black", paddingInlineStart: "0", paddingInlineEnd: "0" }}>
                                                {front_par_total}
                                            </IonCol>
                                        </IonRow>
                                    </>
                                ):(
                                    <></>
                                )
                            }
                            <IonRow className="points-table-row-app-color">
                                <IonCol size="2" style={{ color: "white" }}>
                                    Score
                                </IonCol>
                                {
                                    val && val.hole_details.map((key: any, i: number) => {
                                        // console.log("key",key)
                                        if (key.hole_number <= 9) {
                                            return (
                                                <IonCol size="1" key={i}>
                                                    {
                                                        key.raw_score - key.par > 2 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', textDecoration: 'underline', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == 2 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', borderStyle: 'double', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == 1 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', border: '1px solid #fff', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == 0 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', color: '#fff' }}>
                                                                {tour_format=="3~Intl Peoria" ? "E" : key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == -1 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', background: 'white', color: '#00365f' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == -2 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', background: '#fff', color: '#00365f', borderRadius: '50%' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == -3 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', background: '#fff', color: '#00365f', borderRadius: '50%' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </IonCol>
                                            )

                                        }
                                    })
                                }
                                <IonCol size="1" style={{ color: "white" }}>
                                    {front_score_total}
                                </IonCol>
                            </IonRow>
                            <IonRow className="points-table-row-blank">
                                <IonCol size="2" style={{ color: "black" }}>
                                    Points
                                </IonCol>
                                {
                                    val && val.hole_details.map((key: any, i: number) => {
                                        // console.log("key",key)
                                        if (key.hole_number <= 9) {
                                            return (
                                                <IonCol size="1" style={{ color: "black" }} key={i}>
                                                    {key && key.is_picked ?
                                                        <IonText style={{ padding: '1px 5px 1px 5px', background: 'red', color: '#fff' }}>
                                                            {key.points}
                                                        </IonText>
                                                        :
                                                        <IonText>
                                                            {key.points}
                                                        </IonText>
                                                    }

                                                </IonCol>
                                            )
                                        }
                                    })
                                }
                                <IonCol size="1" style={{ color: "black" }}>
                                    {front_points_total}
                                </IonCol>
                            </IonRow>
                        </>
                    ) : (
                        <>
                            <IonRow className="ion-justify-content-center ion-text-center mt-10">
                                <IonText className="wg-600 black">Back 9</IonText>
                            </IonRow>
                            <IonRow className="points-table-row-color">
                                <IonCol size="2" style={{ color: "black" }}>
                                    Hole
                                </IonCol>
                                {
                                    val && val.hole_details.map((key: any, i: number) => {
                                        // console.log("key",key)
                                        if (key.hole_number > 9) {
                                            return (
                                                <IonCol size="1" style={{ color: "black" }} key={i}>
                                                    {key.hole_number}
                                                </IonCol>
                                            )
                                        }
                                    })
                                }
                                <IonCol size="1" style={{ color: "black" }}>
                                    Tot
                                </IonCol>
                            </IonRow>
                            <IonRow className="points-table-row-color">
                                <IonCol size="2" style={{ color: "black" }}>
                                    Par
                                </IonCol>
                                {
                                    val && val.hole_details.map((key: any, i: number) => {
                                        // console.log("key",key)
                                        if (key.hole_number > 9) {
                                            return (
                                                <IonCol size="1" style={{ color: "black" }} key={i}>
                                                    {key.par}
                                                </IonCol>
                                            )

                                        }
                                    })
                                }
                                <IonCol size="1" style={{ color: "black" }}>
                                    {back_par_total}
                                </IonCol>
                            </IonRow>
                            <IonRow className="points-table-row-app-color">
                                <IonCol size="2" style={{ color: "white" }}>
                                    Score
                                </IonCol>
                                {}
                                {
                                    val && val.hole_details.map((key: any, i: number) => {
                                        if (key.hole_number >= 9) {
                                            return (
                                                <IonCol size="1" key={i}>
                                                    {
                                                        key.raw_score - key.par > 2 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', textDecoration: 'underline', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == 2 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', borderStyle: 'double', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == 1 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', border: '1px solid #fff', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == 0 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', color: '#fff' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == -1 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', background: 'white', color: '#00365f' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == -2 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', background: '#fff', color: '#00365f', borderRadius: '50%' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : key.raw_score - key.par == -3 ? (
                                                            <IonText style={{ padding: '1px 5px 1px 5px', background: '#fff', color: '#00365f', borderRadius: '50%' }}>
                                                                {key.raw_score}
                                                            </IonText>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </IonCol>

                                            )

                                        }
                                    })
                                }
                                <IonCol size="1" style={{ color: "white" }}>
                                    {back_score_total}
                                </IonCol>
                            </IonRow>
                            <IonRow className="points-table-row-blank">
                                <IonCol size="2" style={{ color: "black" }}>
                                    Points
                                </IonCol>
                                {
                                    val && val.hole_details.map((key: any, i: number) => {
                                        // console.log("key",key)
                                        if (key.hole_number > 9) {
                                            return (
                                                <IonCol size="1" style={{ color: "black" }} key={i}>
                                                    {key && key.is_picked ?
                                                        <IonText style={{ padding: '1px 5px 1px 5px', background: 'red', color: '#fff' }}>
                                                            {key.points}
                                                        </IonText>
                                                        :
                                                        <IonText>
                                                            {key.points}
                                                        </IonText>
                                                    }

                                                </IonCol>

                                            )

                                        }
                                    })
                                }
                                <IonCol size="1" style={{ color: "black", paddingInlineStart: "0", paddingInlineEnd: "0" }}>
                                    {back_points_total}
                                </IonCol>
                            </IonRow>
                        </>
                    )
                }

                <br />
                <br />
                <IonRow className="ion-justify-content-center ion-text-center" style={{ width: "85%", margin: "auto", borderBottom: "0.5px  solid black" }}>
                    {
                        val2 && val2.map((key: any, i: number) => {
                            return (
                                <IonCol size="2" key={i}>
                                    <IonText className="s-11">{key}</IonText>
                                </IonCol>

                            )

                        })
                    }
                    <IonCol size="2">
                        <IonText className="black s-14">{val && val.points.eagle}</IonText>
                    </IonCol>
                    <IonCol size="2">
                        <IonText className="black s-14">{val && val.points.birdie}</IonText>
                    </IonCol>
                    <IonCol size="2">
                        <IonText className="black s-14">{val && val.points.par_value}</IonText>
                    </IonCol>
                    <IonCol size="2">
                        <IonText className="black s-14">{val && val.points.bogey}</IonText>
                    </IonCol>
                    <IonCol size="2">
                        <IonText className="black s-14">{val && val.points.double_bogey}</IonText>
                    </IonCol>
                    <IonCol size="2">
                        <IonText className="black s-14">{val && val.points.triple_bogey}</IonText>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center mt-10">
                    <IonText className="wg-500 black">Total Points: {val && val.points.total_points}</IonText>
                </IonRow>
            </IonGrid>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonText>Points from 6 random holes: <span style={{ color: 'red' }}>{random_six_hole_points}</span> </IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonText>Random target: <span style={{ color: 'red' }}>{Math.floor(random_six_hole_points * 1.5)}</span> ({random_six_hole_points} * 1.5, rounded down)</IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonText>Points from all 9 holes: <span style={{ color: 'red' }}>{val.points.total_points}</span></IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonText>Net points over Random target: <span style={{ color: 'red' }}>{finalScore}</span>  ({val.points.total_points} - {finalTarget})</IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonText style={{'font-size': '12px'}}>Random target subjet to a minimum of 50% of Playing target
                        <br/>
                        Playing target: <span style={{ fontWeight: 900 }}> {val.quota} </span> ; 50% of Playing target: <span style={{ fontWeight: 900 }}> {Math.ceil(val.quota/2)} </span>
                        </IonText>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </>
    );
};

export default NineHolesScoreTable;