import { IonPage, IonContent, IonGrid, IonRow, IonText, IonItem, IonInput, IonSelect, IonSelectOption, IonRange, IonCol, IonLabel, IonList, IonModal, IonSearchbar, IonSegment, IonSegmentButton, IonFab, IonFabButton, IonIcon, IonChip, IonLoading, useIonToast, IonCheckbox } from '@ionic/react';
import { getFirestore } from 'firebase/firestore';
import { arrowBackOutline, closeCircle } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import firebase from '../../js/firebase';
import { createEntryWithoutId } from '../../js/firebase_doc_functions';
import * as utils from '../../js/utils';
const db = getFirestore(firebase);

const TourSettings: React.FC = () => {
    const [show1, setShow1] = useState<any>(true);
    const [show2, setShow2] = useState<any>(false);
    const [show3, setShow3] = useState<any>(false);
    const [current_user, setCurrentUser] = useState<any>({});
    const [current_pack, setCurrentPack] = useState<any>({});
    const [format, setFormat] = useState<any>("1~Peoria based");
    const [format_value, setFormatValue] = useState<any>("Peoria based");
    const [season_prize_pool, setSeasonPrizePool] = useState<string>("10");
    const [youth_on_course, setYouthOnCourse] = useState<string>("0");
    const [weekly_prize_pool, setWeeklyPrizePool] = useState<string>("0");
    const [fixed_courses, setFixedCourses] = useState<string>("No");
    const [fixed_tees, setFixedTees] = useState<string>("No");
    const [course_options, setCourseOptions] = useState<any>([]);
    const [tee_options, setTeeOptions] = useState<any>([]);
    const [course, setCourse] = useState<any>();
    const [course_id, setCourseId] = useState<any>();
    const [course_list, setCourseList] = useState<any>([]);
    const [selected_course, setSelectedCourse] = useState<any>([]);
    const [selected_courses, setSelectedCourses] = useState<any>([]);
    const [selected_tee, setSelectedTee] = useState<any>([]);
    const [tees_list, setTeesList] = useState<any>([]);
    const [holes, setHoles] = useState<any>("18");
    const [url, setUrl] = useState<any>("");
    const [select_tees,setSelectTees] = useState("");
    const [selected_tees,setSelectedTees] = useState<any>([]);
    const [select_state,setSelectState] = useState<any>([]);
    const [state_code,setStateCode] = useState<any>('');
    const [teeset_rating_id, setTeesetRatingId] = useState<number>();
    const [teeset_rating_name, setTeesetRatingName] = useState<string>('');
    const [teeset_rating_gender, setTeesetRatingGender] = useState<string>('');
    const [slope_rating, setSlopeRating] = useState<number>();
    const [course_rating, setCourseRating] = useState<number>();
    const [par, setPar] = useState<number>();
    const [yards, setYards] = useState<number>();
    const [gender, setGender] = useState<string>('');
    const [showStateModal, setShowStateModal] = useState(false);
    const [showCourseModal, setShowCourseModal] = useState(false);
    const [showTeesModal, setShowTeesModal] = useState(false);
    const [tee,setTee] = useState<any>("Middle");
    const [tee_value, setTeeValue] = useState<any>(3);
    const [pull_scores_from, setPullScoresFrom] = useState<any>('eClubgolf');
    const [partner_required, setPartnerRequired] = useState<any>('No');
    const [notes, setNotes] = useState<any>('');
    const [visibility, setVisibility] = useState<string>('Private');
    const [tour_name, setTourName] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [showLoading, setShowLoading] = useState(false);
    const [showToast, hideToast] = useIonToast();
    const [target_based_on_handicap, setTargetBasedOnHandicap] = useState<any>('No');
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [description, setDescription] = useState<string>('');

    const customAlertOptions = {
        header: 'Select State',
        translucent: true
    };
    const customAlertOptions1 = {
        header: 'Select Course',
    };
    const customAlertOptions2 = {
        header: 'Select Tees(Course/Slope/Par)',
    };

    const getCourses = async(state:string)=> {
        let course_list = await utils.getCourseList(state);
        setCourseList(course_list);
    }

    const getTees = async(course_id:number, holes:number)=> {
        let tees = await utils.getCourseDetails(course_id) || [];
        var tee_sets = new Array();
        tees && tees.forEach((tee:any)=>{
            if(holes == 18){
                let rating = tee.Ratings.filter((rating:any)=> rating.RatingType == 'Total');
                let pars = tee.Holes.map((hole:any)=> {return hole.Par}),
                par = pars.reduce((a:number, b:number) => a + b, 0);
                if(tee.HolesNumber == 18){
                    tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+tee.Gender+'~('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')',
                    'teeValue': tee.TeeSetRatingName+'~'+tee.Gender+'~('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                  });
                }
            } else if(holes == 9) {
                let rating = tee.Ratings.filter((rating:any)=> rating.RatingType == 'Total');
                let nineHoleRatings = tee.Ratings.filter((rating:any)=> rating.RatingType != 'Total');
                let pars = tee.Holes.map((hole:any)=> {return hole.Par});
                if(tee.HolesNumber == 9){
                  let pars = tee.Holes.map((hole:any)=> {return hole.Par}),
                  par = pars.reduce((a:number, b:number) => a + b, 0);
                  tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+tee.Gender+'~'+rating[0].RatingType+'~('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')',
                    'teeValue': tee.TeeSetRatingName+'~'+tee.Gender+'~'+rating[0].RatingType+'~('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                  });

                } else {
                  for (rating of nineHoleRatings) {
                    var front9Holes = tee.Holes.filter((hole:any)=> hole.Number <= 9);
                    var back9Holes = tee.Holes.filter((hole:any)=> hole.Number > 9);
                    if(rating.RatingType == 'Front'){
                      let pars = front9Holes.map((hole:any)=> {return hole.Par}),
                      par = pars.reduce((a:number, b:number) => a + b, 0);
                      tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+tee.Gender+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')',
                        'teeValue': tee.TeeSetRatingName+'~'+tee.Gender+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                      });

                    }
                    if(rating.RatingType == 'Back'){
                      let pars = back9Holes.map((hole:any)=> {return hole.Par}),
                      par = pars.reduce((a:number, b:number) => a + b, 0);
                      tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+tee.Gender+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')',
                        'teeValue': tee.TeeSetRatingName+'~'+tee.Gender+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                      });

                    }
                  }
                }
            }
        });
        setTeesList(tee_sets);
    }

    const getTeeDetails = async(courseid:number, holes:number, tee:string)=> {
        let currentTeeValues = tee.split("~");
        setTeesetRatingId(holes == 18 ? parseInt(currentTeeValues[3]) : parseInt(currentTeeValues[4]));
        setTeesetRatingName(currentTeeValues[0]);
        setTeesetRatingGender(currentTeeValues[1]);
        let teeSetValues = holes == 18 ? (currentTeeValues[2].substring(1, currentTeeValues[2].length-1)).split("/") : (currentTeeValues[3].substring(1, currentTeeValues[3].length-1)).split("/");
        setSlopeRating(parseFloat(teeSetValues[1]));
        setCourseRating(parseInt(teeSetValues[0]));
        setPar(parseInt(teeSetValues[2]));
        setGender(currentTeeValues[1]);
        let tees = await utils.getCourseDetails(courseid) || [];
        tees && tees.forEach((tee:any)=>{
            if(holes == 18){
                let yardsData = tee.Holes.map((hole:any)=> {return hole.Length}),
                yards = yardsData.reduce((a:number, b:number) => a + b, 0);
                setYards(yards);
            } else if(holes == 9) {
                let rating = tee.Ratings.filter((rating:any)=> rating.RatingType == 'Total');
                let nineHoleRatings = tee.Ratings.filter((rating:any)=> rating.RatingType != 'Total');
                if(tee.HolesNumber == 9){
                    let yardsData = tee.Holes.map((hole:any)=> {return hole.Length}),
                    yards = yardsData.reduce((a:number, b:number) => a + b, 0);
                } else {
                  for (rating of nineHoleRatings) {
                    var front9Holes = tee.Holes.filter((hole:any)=> hole.Number <= 9);
                    var back9Holes = tee.Holes.filter((hole:any)=> hole.Number > 9);
                    if(rating.RatingType == 'Front'){
                        let yardsData = front9Holes.map((hole:any)=> {return hole.Length}),
                        yards = yardsData.reduce((a:number, b:number) => a + b, 0);
                        setYards(yards);
                    }
                    if(rating.RatingType == 'Back'){
                        let yardsData = back9Holes.map((hole:any)=> {return hole.Length}),
                        yards = yardsData.reduce((a:number, b:number) => a + b, 0);
                        setYards(yards);
                    }
                  }
                }
            }
        });
    }

    const states = [{"state_code":"US-AL","state_name":"Alabama"},
      {"state_code":"US-AK","state_name":"Alaska"},
      {"state_code":"US-AZ","state_name":"Arizona"},
      {"state_code":"US-AR","state_name":"Arkansas"},
      {"state_code":"US-CA","state_name":"California"},
      {"state_code":"US-CO","state_name":"Colorado"},
      {"state_code":"US-CT","state_name":"Connecticut"},
      {"state_code":"US-DE","state_name":"Delaware"},
      {"state_code":"US-DC","state_name":"District Of Columbia"},
      {"state_code":"US-FL","state_name":"Florida"},
      {"state_code":"US-GA","state_name":"Georgia"},
      {"state_code":"US-HI","state_name":"Hawaii"},
      {"state_code":"US-ID","state_name":"Idaho"},
      {"state_code":"US-IL","state_name":"Illinois"},
      {"state_code":"US-IN","state_name":"Indiana"},
      {"state_code":"US-IA","state_name":"Iowa"},
      {"state_code":"US-KS","state_name":"Kansas"},
      {"state_code":"US-KY","state_name":"Kentucky"},
      {"state_code":"US-LA","state_name":"Louisiana"},
      {"state_code":"US-ME","state_name":"Maine"},
      {"state_code":"US-MD","state_name":"Maryland"},
      {"state_code":"US-MA","state_name":"Massachusetts"},
      {"state_code":"US-MI","state_name":"Michigan"},
      {"state_code":"US-MN","state_name":"Minnesota"},
      {"state_code":"US-MS","state_name":"Mississippi"},
      {"state_code":"US-MO","state_name":"Missouri"},
      {"state_code":"US-MT","state_name":"Montana"},
      {"state_code":"US-NE","state_name":"Nebraska"},
      {"state_code":"US-NV","state_name":"Nevada"},
      {"state_code":"US-NH","state_name":"New Hampshire"},
      {"state_code":"US-NJ","state_name":"New Jersey"},
      {"state_code":"US-NM","state_name":"New Mexico"},
      {"state_code":"US-NY","state_name":"New York"},
      {"state_code":"US-NC","state_name":"North Carolina"},
      {"state_code":"US-ND","state_name":"North Dakota"},
      {"state_code":"US-OH","state_name":"Ohio"},
      {"state_code":"US-OK","state_name":"Oklahoma"},
      {"state_code":"US-OR","state_name":"Oregon"},
      {"state_code":"US-PA","state_name":"Pennsylvania"},
      {"state_code":"US-RI","state_name":"Rhode Island"},
      {"state_code":"US-SC","state_name":"South Carolina"},
      {"state_code":"US-SD","state_name":"South Dakota"},
      {"state_code":"US-TN","state_name":"Tennessee"},
      {"state_code":"US-TX","state_name":"Texas"},
      {"state_code":"US-UT","state_name":"Utah"},
      {"state_code":"US-VT","state_name":"Vermont"},
      {"state_code":"US-VA","state_name":"Virginia"},
      {"state_code":"US-WA","state_name":"Washington"},
      {"state_code":"US-WV","state_name":"West Virginia"},
      {"state_code":"US-WI","state_name":"Wisconsin"},
      {"state_code":"US-WY","state_name":"Wyoming"}
    ];
    const [new_states,setNewStates] = useState<any>(states);
    const [new_courses,setNewCourses] = useState<any>(course_list);
    const [new_tees,setNewTees] = useState<any>(tees_list);
    const [showFormatModal, setShowFormatModal] = useState<boolean>(false);

    const add_tees = (name: any) => {
        if (!tees_list.includes(name)) {
            tees_list.push(name);
            setSelectedTees(Array.from(new Set([...tees_list])));
            setSelectedTee("");
        }
    };
    const del_tees = (name: any) => {
        if (tees_list.includes(name)) {
            setSelectedTees(
                tees_list.filter((ele: any) => {
              return ele != name;
            })
          );
        }
    };

    const add_courses = (name: any) => {
        console.log("df, name", name)
        if (!selected_courses.includes(name)) {
            selected_courses.push(name);
            setSelectedCourses(Array.from(new Set([...selected_courses])));
            setSelectedCourse("");
        }
    };
    const del_courses = (name: any) => {
        if (selected_courses.includes(name)) {
            setSelectedCourses(
                selected_courses.filter((ele: any) => {
              return ele != name;
            })
          );
        }
    };

    const createSetting = async() => {
        setShowLoading(true);
        console.log('dfsfsf', select_state);
        var obj = {
            tour_name: tour_name,
            city: city,
            format: format,
            season_prize_pool: parseInt(season_prize_pool),
            weekly_prize_pool: parseInt(weekly_prize_pool),
            fixed_courses: fixed_courses,
            fixed_tees: fixed_tees,
            holes: holes,
            selected_courses: selected_courses,
            selected_tees: [],
            url: url,
            state: state_code,
            pull_scores_from: 'eClubgolf',
            partner_required: partner_required,
            visibility: visibility,
            players: [],
            youth_on_course: parseInt(youth_on_course),
            status: "Pending Approval",
            description: description,
            target_based_on_handicap: target_based_on_handicap,
            created_by: current_user.first_name+' '+current_user.last_name,
        }

        await createEntryWithoutId("TOUR_SETTINGS", obj);
        setShowLoading(false);
        showToast('Your tour will be setup in a day. Please reach us at info@eclub.golf for any questions.', 2000);
        window.location.href="/leagues/formats";
    }

    useEffect(() => {
        let user = localStorage.getItem('firebase_user');
        if(user != null) {
            setCurrentUser(JSON.parse(user));
        }
        let current_pack = localStorage.getItem('current_pack');
        if(current_pack != null) {
            setCurrentPack(JSON.parse(current_pack));
        }
    }, [])
    return (
        <IonPage>
            <IonContent>

            <IonGrid
              style={{ paddingLeft: "8%", margin: "auto", maxWidth: "345px" }}
            >
              <IonRow
                style={{
                  borderBottom: "1px solid gray",
                  width: "80%",
                  margin: "35px 10px 0px 15px",
                }}
                className="ion-justify-content-center ion-text-center"
              ></IonRow>
              <IonRow
                className="ion-justify-content-center ion-text-start pl-10"
                style={{ marginTop: "-9%" }}
              >
                <IonCol size="4">
                  <div
                    className={
                      show1 || show2 || show3 ? "circle bg-green" : "circle"
                    }
                  >
                    1
                  </div>
                  <IonText className="pl-5">Settings</IonText>
                </IonCol>
                <IonCol size="4">
                  <div className={show2 || show3 ? "circle bg-green" : "circle"}>
                    2
                  </div>
                  <IonText>Format</IonText>
                </IonCol>
                <IonCol size="4">
                  <div className={show3 ? "circle bg-green" : "circle"}>3</div>
                  <IonText>Additional</IonText>
                </IonCol>
              </IonRow>
            </IonGrid>

                <IonGrid>
                  {show1 ? (
                    <>
                    <IonText className="s-13 black pl-20">Tour name and city</IonText>
                    <IonRow className="ion-justify-content-center mt-5">
                        <IonCol size="6">
                            <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                                <IonInput
                                value={tour_name}
                                placeholder="tour name"
                                type='text'
                                className="s-14 custom-input"
                                min='0'
                                onIonChange={(e:any) => {
                                    setTourName(e.target.value)
                                }}
                                // clearInput
                                ></IonInput>
                            </IonItem>
                        </IonCol>
                        <IonCol size="6">
                            <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                                <IonInput
                                value={city}
                                placeholder="city"
                                type='text'
                                className="s-14 custom-input"
                                min='0'
                                onIonChange={(e:any) => {
                                    setCity(e.target.value)
                                }}
                                // clearInput
                                ></IonInput>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <br/>
                    <IonText className="s-13 black pl-20">Add description</IonText>
                    <IonRow className="ion-justify-content-center mt-5">
                        <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                            <IonInput
                            value={description}
                            placeholder=""
                            type='text'
                            className="s-14 custom-input"
                            min='0'
                            onIonChange={(e:any) => {
                                setDescription(e.target.value)
                            }}
                            // clearInput
                            ></IonInput>
                        </IonItem>
                    </IonRow>
                      <IonRow className="ion-justify-content-center mt-10">
                        <button
                          className="button-close bg-blue white wg-bold"
                          onClick={() => {
                            setShow2(true);
                            setShow1(false);
                            setShow3(false);
                          }}
                        >
                          Next
                        </button>
                      </IonRow>
                    </>
                  ) : show2 ? (
                    <>
                    <IonText className="s-13 black pl-20">Limit participation to your own packs?</IonText>
                    <IonRow className="ion-justify-content-center mt-5 input-margin">
                        <IonSegment onIonChange={(e:any) => {
                            setVisibility(e.detail.value)
                            //getTees(course_id, e.detail.value)
                            }} value={visibility} mode="ios" className="new-segment">
                            <IonSegmentButton value="Open to all" className="new-segment-button">
                            <IonLabel className="new-segment-button-label">Open to all</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="Private" className="new-segment-button">
                            <IonLabel className="new-segment-button-label">Private</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </IonRow>
                    <br/>
                    <IonRow className="ion-justify-content-center mt-5 input-margin">
                        <IonSegment onIonChange={(e:any) => {
                            setHoles(e.detail.value)
                            //getTees(course_id, e.detail.value)
                            }} value={holes} mode="ios" className="new-segment">
                            <IonSegmentButton value="18" className="new-segment-button">
                            <IonLabel className="new-segment-button-label">18 Holes</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="9" className="new-segment-button">
                            <IonLabel className="new-segment-button-label">9 Holes</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </IonRow>
                    <br/>
                    <IonText className="s-13 black pl-20">Select Format</IonText>
                    <IonRow className="ion-justify-content-center mt-5 select-modal">
                        <IonInput value={format_value}
                            placeholder="select format" className="s-14 select-pack-dropdown pl-20"
                            mode="ios" onClick={()=>{
                                setShowFormatModal(true);
                            }}>
                        </IonInput>
                    </IonRow>
                    <br/>
                    {
                        format_value === "1:1 Matches" || format_value === "" ? (
                            <>
                            </>
                        ) : (
                            <>
                            <IonText className="s-13 black pl-20">Partner required</IonText>
                            <IonRow className="ion-justify-content-center mt-5 input-margin">
                                <IonSegment onIonChange={(e:any) => {
                                    setPartnerRequired(e.detail.value)
                                    //getTees(course_id, e.detail.value)
                                    }} value={partner_required} mode="ios" className="new-segment">
                                    <IonSegmentButton value="Yes" className="new-segment-button">
                                    <IonLabel className="new-segment-button-label">Yes</IonLabel>
                                    </IonSegmentButton>
                                    <IonSegmentButton value="No" className="new-segment-button">
                                    <IonLabel className="new-segment-button-label">No</IonLabel>
                                    </IonSegmentButton>
                                </IonSegment>
                            </IonRow>
                            <br/>
                            </>
                        )
                    }
                      <IonRow className="ion-justify-content-center ion-text-center mt-10">
                        <IonCol>
                          <button
                            className="button-close bg-red white wg-bold"
                            onClick={() => {
                              setShow2(false);
                              setShow1(true);
                              setShow3(false);
                            }}
                          >
                            Back
                          </button>
                        </IonCol>
                        <IonCol>
                          <button
                            className="button-close bg-blue white wg-bold"
                            onClick={() => {
                              setShow2(false);
                              setShow1(false);
                              setShow3(true);
                            }}
                          >
                            Next
                          </button>
                        </IonCol>
                      </IonRow>
                    </>
                  ) : show3 ? (
                    <>
                    <IonRow className="ion-justify-content-center mt-5">
                        <IonCol size="6">
                            <IonText className="s-13 black pl-20">Season dues</IonText>
                            <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                                <IonSelect value={season_prize_pool}
                                    interface="popover"
                                    placeholder="Select dollars" className="login-input wid-100 select-box" onIonChange={(e:any) => {setSeasonPrizePool(e.target.value)}} mode="ios">
                                    <IonSelectOption value="10">10</IonSelectOption>
                                    <IonSelectOption value="20">20</IonSelectOption>
                                    <IonSelectOption value="30">30</IonSelectOption>
                                    <IonSelectOption value="40">40</IonSelectOption>
                                    <IonSelectOption value="50">50</IonSelectOption>
                                    <IonSelectOption value="60">60</IonSelectOption>
                                    <IonSelectOption value="70">70</IonSelectOption>
                                    <IonSelectOption value="80">80</IonSelectOption>
                                    <IonSelectOption value="90">90</IonSelectOption>
                                    <IonSelectOption value="100">100</IonSelectOption>
                                    <IonSelectOption value="125">125</IonSelectOption>
                                    <IonSelectOption value="150">150</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        </IonCol>
                        <IonCol size="6">
                            <IonText className="s-13 black pl-20">Youth on Course donation</IonText>
                            <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                                <IonSelect value={youth_on_course}
                                    interface="popover"
                                    placeholder="Select dollars" className="login-input wid-100 select-box" onIonChange={(e:any) => {setYouthOnCourse(e.target.value)}} mode="ios">
                                    <IonSelectOption value="0">0</IonSelectOption>
                                    <IonSelectOption value="5">5</IonSelectOption>
                                    <IonSelectOption value="10">10</IonSelectOption>
                                    <IonSelectOption value="15">15</IonSelectOption>
                                    <IonSelectOption value="20">20</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        </IonCol>
                        <IonText className="s-13 black pl-20">Total: ${parseInt(season_prize_pool)+10+parseInt(youth_on_course)} (Includes $10 signup dues)</IonText>
                    </IonRow>
                    <br/>
                    {
                        format !== "2~1:1 Matches" ? (
                            <>
                            <IonRow className="ion-justify-content-center mt-5">
                                <IonCol size="6">
                                    <IonText className="s-13 black pl-20">Weekly prize pool</IonText>
                                    <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                                        <IonInput
                                            value={weekly_prize_pool}
                                            placeholder="weekly prize pool"
                                            type='number'
                                            className="s-14 custom-input"
                                            min='0'
                                            onIonChange={(e:any) => {
                                                setWeeklyPrizePool(e.target.value)
                                            }}
                                            // clearInput
                                            >

                                        </IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol size="6">
                                    <IonText className="s-13 black pl-20">Details link</IonText>
                                    <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                                        <IonInput
                                        value={url}
                                        placeholder="external url"
                                        type='text'
                                        className="s-14 custom-input"
                                        onIonChange={(e:any) => {
                                            setDescription(e.target.value)
                                        }}
                                        // clearInput
                                        ></IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <br/>
                            </>
                        ) : (
                            <>
                            <IonText className="s-13 black pl-20">Details</IonText>
                            <IonRow className="ion-justify-content-center mt-5">
                                <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                                    <IonInput
                                    value={url}
                                    placeholder="external url"
                                    type='number'
                                    className="s-14 custom-input"
                                    onIonChange={(e:any) => {
                                        setWeeklyPrizePool(e.target.value)
                                    }}
                                    // clearInput
                                    ></IonInput>
                                </IonItem>
                            </IonRow>
                            <br/>
                            </>
                        )
                    }
                    {/* <IonText className="s-13 black pl-20">Pull scores from</IonText>
                    <IonRow className="ion-justify-content-center mt-5 input-margin">
                        <IonSegment onIonChange={(e:any) => {
                            setPullScoresFrom(e.detail.value)
                            //getTees(course_id, e.detail.value)
                            }} value={pull_scores_from} mode="ios" className="new-segment">
                            <IonSegmentButton value="GHIN" className="new-segment-button">
                            <IonLabel className="new-segment-button-label">GHIN</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="eClubgolf" className="new-segment-button">
                            <IonLabel className="new-segment-button-label">eClubgolf</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </IonRow>
                    <br/> */}
                    <IonText className="s-13 black pl-20">Limit to selected courses</IonText>
                    <IonRow className="ion-justify-content-center mt-5 input-margin">
                        <IonSegment onIonChange={(e:any) => {
                            setFixedCourses(e.detail.value)
                            //getTees(course_id, e.detail.value)
                            }} value={fixed_courses} mode="ios" className="new-segment">
                            <IonSegmentButton value="Yes" className="new-segment-button">
                            <IonLabel className="new-segment-button-label">Yes</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="No" className="new-segment-button">
                            <IonLabel className="new-segment-button-label">No</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </IonRow>
                    <br/>
                    {
                        fixed_courses === "Yes" ? (
                            <>
                            <IonRow className="ion-justify-content-center mt-10 s-13">
                                Select courses
                            </IonRow>
                            <IonRow className="ion-justify-content-center mt-5 input-margin">
                                <IonInput value={select_state}
                                    placeholder="state" className="s-14 select-pack-dropdown pl-20"
                                    mode="ios" onClick={()=>{
                                        setShowStateModal(true)
                                        setNewStates(states)
                                    }}>
                                </IonInput>
                            </IonRow>
                            <br/>
                            <IonRow className="ion-justify-content-center mb-10">
                                <IonCol size="9">
                                    <IonInput value={selected_course}
                                        placeholder="course" className="s-14 select-pack-dropdown pl-20"
                                        mode="ios" onClick={()=>{
                                            setShowCourseModal(true)
                                            setNewCourses(course_list)
                                        }}>
                                    </IonInput>
                                </IonCol>
                                <IonCol size="3">
                                {
                                    (selected_course.length > 0 || selected_course.length <= 5)?(
                                        <button
                                            className="add-button bg white tt-upper wg-bold"
                                            onClick={() => {
                                            if (selected_course.length > 0) {
                                                add_courses(selected_course);
                                            }
                                            }}
                                        >
                                            add
                                        </button>
                                    ):(
                                    <button
                                        className="add-button bg white tt-upper wg-bold disabled-button"
                                    >
                                        add
                                    </button>
                                    )
                                }
                                </IonCol>
                            </IonRow>
                            <IonRow className="pl-15">
                                {selected_courses &&
                                selected_courses.map((key: any, i: number) => {
                                    return (
                                    <IonChip key={i}>
                                        <IonLabel>{key}</IonLabel>
                                        <IonIcon
                                        icon={closeCircle}
                                        onClick={() => {
                                            del_courses(key);
                                        }}
                                        />
                                    </IonChip>
                                    );
                                })}
                            </IonRow>
                            <br/>
                            </>
                        ) : (
                            <>
                            </>
                        )
                    }
                    {/* <IonRow className="ion-justify-content-center mt-5">
                        <IonItem lines="none" className="item-bg" style={{height: "40px", border: 'none'}}>
                        <IonText className="s-13 black">Target based on Handicap Index<sup className='s-8'>TM</sup></IonText><IonCheckbox slot="end" value={target_based_on_handicap} checked={isChecked} onIonChange={e=> {
                            if(isChecked) {
                                setTargetBasedOnHandicap("No");
                                setIsChecked(false);
                            } else {
                                setTargetBasedOnHandicap("Yes");
                                setIsChecked(true);
                            }
                        }}/>
                        </IonItem>
                    </IonRow>
                    <br/> */}
                    <IonText className="s-13 black pl-20">Add notes</IonText>
                    <IonRow className="ion-justify-content-center mt-5">
                        <IonItem lines="none" className="select-pack-dropdown item-bg" style={{height: "40px"}}>
                            <IonInput
                            value={notes}
                            placeholder=""
                            type='text'
                            className="s-14 custom-input"
                            min='0'
                            onIonChange={(e:any) => {
                                setNotes(e.target.value)
                            }}
                            // clearInput
                            ></IonInput>
                        </IonItem>
                    </IonRow>
                      <IonRow className="ion-justify-content-center ion-text-center ion-mt-10">
                        <IonCol>
                          <button
                            className="button-close bg-red white wg-bold"
                            onClick={() => {
                              setShow2(true);
                              setShow1(false);
                              setShow3(false);
                            }}
                          >
                            Back
                          </button>
                        </IonCol>
                        <IonCol>
                          <button
                            className="button-close bg-blue white wg-bold"
                            onClick={() => {
                              createSetting();
                            }}
                          >
                            Finish
                          </button>
                        </IonCol>
                      </IonRow>
                    </>
                  ) : (
                    <>
                      <p>Success</p>
                    </>
                  )}
                </IonGrid>




















                <IonModal isOpen={showFormatModal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true}
                onDidDismiss={() => setShowFormatModal(false)}
                initialBreakpoint={0.5} mode="md">
                    <>
                    <IonContent>
                        <IonGrid>
                            <IonList>
                                <IonItem>
                                    <IonLabel onClick={()=>{
                                        setFormat("1~Peoria based");
                                        setFormatValue("Peoria based");
                                        setShowFormatModal(false);
                                    }}>
                                        Peoria based
                                    </IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonLabel onClick={()=>{
                                        setFormat("2~1:1 Matches");
                                        setFormatValue("1:1 Matches");
                                        setShowFormatModal(false);
                                    }}>
                                        1:1 Matches
                                    </IonLabel>
                                </IonItem>
                                {/* <IonItem>
                                    <IonLabel onClick={()=>{
                                        setFormat("3~Weekly pack leaders");
                                        setFormatValue("Weekly pack leaders");
                                        setShowFormatModal(false);
                                    }}>
                                       Weekly pack leaders
                                    </IonLabel>
                                </IonItem> */}
                            </IonList>
                        </IonGrid>
                    </IonContent>
                    </>
                </IonModal>
                <IonModal isOpen={showStateModal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true}
                onDidDismiss={() => setShowStateModal(false)}
                initialBreakpoint={0.5} mode="md">
                    <>
                    <IonSearchbar onIonChange={(e:any) => {
                                console.log(e.detail.value!)
                                if(String(e.detail.value!).length>0){
                                    let glo = new RegExp(String(e.detail.value!).toLowerCase());
                                    let new_city_arr = new Array()
                                    states.map((item:any)=>{
                                        let real_item = item
                                        item = item.state_name.toLowerCase()

                                        if(glo.test(item)){
                                            console.log("item",real_item)
                                            new_city_arr.push(real_item)
                                        }
                                    })
                                    console.log(new_city_arr)
                                    setNewStates(new_city_arr)
                                }
                                else{
                                    setNewStates(states)
                                }
                                }} mode="ios"></IonSearchbar>

                    <IonContent>
                        <IonGrid>
                            <IonList>
                                    {
                                    (new_states && new_states.map((key:any,i:number)=>{
                                        return(
                                                <IonItem key={i}>
                                                    <IonLabel onClick={()=>{
                                                        console.log(key.state_code)
                                                        setSelectState(key.state_name)
                                                        setStateCode(key.state_code);
                                                        getCourses(key.state_code)
                                                        setShowStateModal(false)

                                                    }}>
                                                    {key.state_name}
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        }))
                                    }

                            </IonList>
                        </IonGrid>
                    </IonContent>
                    </>
                </IonModal>
                <IonModal isOpen={showCourseModal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true}
                onDidDismiss={() => setShowCourseModal(false)}
                initialBreakpoint={0.5} mode="md">
                    <>
                    <IonSearchbar onIonChange={(e:any) => {
                                console.log(e.detail.value!)
                                if(String(e.detail.value!).length>0){
                                    let glo = new RegExp(String(e.detail.value!).toLowerCase());
                                    let new_course_arr = new Array()
                                    course_list.map((item:any)=>{
                                        console.log("a",item);
                                        let real_item = item
                                        item = item.FullName.toLowerCase()

                                        if(glo.test(item)){
                                            console.log("item",real_item)
                                            new_course_arr.push(real_item)
                                        }
                                    })
                                    console.log(new_course_arr)
                                    setNewCourses(new_course_arr)
                                }
                                else{
                                    setNewCourses(course_list)
                                }
                                }} mode="ios"></IonSearchbar>

                    <IonContent>
                        <IonGrid>
                            <IonList>
                                    {
                                    (new_courses && new_courses.map((key:any,i:number)=>{
                                        return(
                                                <IonItem key={i}>
                                                    <IonLabel onClick={()=>{
                                                        console.log(key.CourseID);
                                                        setCourse(key.FullName);
                                                        setCourseId(key.CourseID);
                                                        setSelectedCourse(key.FullName);
                                                        setShowCourseModal(false)
                                                    }}>
                                                    {key.FullName}
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        }))
                                    }

                            </IonList>
                        </IonGrid>
                    </IonContent>
                    </>
                </IonModal>
                <IonModal isOpen={showTeesModal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true}
                onDidDismiss={() => setShowTeesModal(false)}
                initialBreakpoint={0.5} mode="md">
                    <>
                        <IonSearchbar onIonChange={(e:any) => {
                            console.log(e.detail.value!)
                            if(String(e.detail.value!).length>0){
                                let glo = new RegExp(String(e.detail.value!).toLowerCase());
                                let new_tees_arr = new Array()
                                tees_list.map((item:any)=>{
                                    console.log("a",item);
                                    let real_item = item
                                    item = item.teeName.toLowerCase()

                                    if(glo.test(item)){
                                        console.log("item",real_item)
                                        new_tees_arr.push(real_item)
                                    }
                                })
                                console.log(new_tees_arr)
                                setNewTees(new_tees_arr)
                            }
                            else{
                                setNewTees(tees_list)
                            }
                            }} mode="ios">
                        </IonSearchbar>

                        <IonContent>
                            <IonGrid>
                                <IonList>
                                    {
                                        (new_tees && new_tees.map((key:any,i:number)=>{
                                            return(
                                                <IonItem key={i}>
                                                    <IonLabel onClick={()=>{
                                                        console.log(key)
                                                        setSelectTees(key.teeValue)
                                                        getTeeDetails(course_id, holes, key.teeValue)
                                                        setShowTeesModal(false)
                                                    }}>
                                                    {key.teeName}
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        }))
                                    }
                                </IonList>
                            </IonGrid>
                        </IonContent>
                    </>
                </IonModal>
                <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button">
                    <IonFabButton
                    className="main-fab"
                    onClick={() => {
                        window.location.href="/leagues/formats";
                    }}
                    >
                    <IonIcon icon={arrowBackOutline} />
                    </IonFabButton>
                </IonFab>
                <IonLoading
                    isOpen={showLoading}
                    message={'Submitting..'}
                />
            </IonContent>
        </IonPage>
    )
};

export default TourSettings;
