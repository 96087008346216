import { useEffect, useState } from 'react';
import { generatePlayPostWinSeasonLeaderboard, generatePlayPostWinLeaderboard, generateAmateurOpenSeasonLeaderboard, generateAmateurOpenLeaderboard, generateTTPeoriaLeaderBoards, generateTTOneOnOneLeaderBoards,generateTTPeoriaSeasonlLeaderBoards,generateTTOneOnOneSeasonlLeaderBoards } from '../../js/admin_functions';
import { IonPage, IonContent, IonButton, IonGrid, IonRow, IonCol, IonHeader, IonToolbar, IonButtons, IonIcon, IonTitle, IonLoading } from '@ionic/react';
import { chevronBackOutline, peopleOutline, add } from 'ionicons/icons';
import { useIonToast } from '@ionic/react';
import firebase from "../../js/firebase";
import loadash from "lodash";

import {
    getFirestore,
    collection,
    query,
    where,
    doc,
    getDocs,
    deleteDoc,
    orderBy
} from "firebase/firestore";
import { type } from 'os';
const db = getFirestore(firebase);

const AdminLeaderboards: React.FC = () => {
    const [showToast, hideToast] = useIonToast();
    const [twilightTours, setTwilightTours] = useState<any>([]);
    const [showStartLoading, setShowStartLoading] = useState<boolean>(false);
    const getTwilightTourData = async () => {
        const q = query(collection(db, "TOUR_SETTINGS"), where('created_at', '>', 0), where('status', '==', 'Approved'));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.docs && querySnapshot.docs.length > 0) {
            await Promise.all(
                querySnapshot.docs.map((item: any) => {
                    var doc_item = item.data();
                    doc_item.id = item.id;
                    return doc_item;
                })
            ).then((results) => {
                setTwilightTours(loadash.sortBy(results, (e: any) => e.created_at).reverse());
            });
        };
    }
    const handleClickPPW = (type: any) => {
        if (type === "weekly") {
            setShowStartLoading(true);
            generatePlayPostWinLeaderboard()
                .then((leaderboards: any) => {
                    setShowStartLoading(false);
                    showToast('PPW weekly leadboards generated successfully', 2000);
                })
                .catch((error) => {
                    setShowStartLoading(false);
                    showToast(error.message, 2000);
                    console.log(error);
                });
        }
        else if (type === "season") {
            setShowStartLoading(true);
            generatePlayPostWinSeasonLeaderboard()
                .then((leaderboards: any) => {
                    setShowStartLoading(false);
                    showToast('PPW season leadboards generated successfully', 2000);
                })
                .catch((error) => {
                    setShowStartLoading(false);
                    showToast(error.message, 2000);
                    console.log(error);
                });
        }
        else {
            showToast('something went wrong ', 2000);
        }
    }

    const handleClickNSC = (type: any) => {
        if (type === "weekly") {
            setShowStartLoading(true);
            generateAmateurOpenLeaderboard()
                .then((leaderboards: any) => {
                    setShowStartLoading(false);
                    showToast('NSC weekly leadboards generated successfully', 2000);
                })
                .catch((error) => {
                    setShowStartLoading(false);
                    showToast(error.message, 2000);
                    console.log(error);
                });
        }
        else if (type === "season") {
            setShowStartLoading(true);
            generateAmateurOpenSeasonLeaderboard()
                .then((leaderboards: any) => {
                    setShowStartLoading(false);
                    showToast('NSC season leadboards generated successfully', 2000);
                })
                .catch((error) => {
                    setShowStartLoading(false);
                    showToast(error.message, 2000);
                    console.log(error);
                });
        }
        else {
            showToast('something went wrong ', 2000);
        }
    }
    const handleClickTTWeekly = (tour: any,) => {
        if (tour.format === "1~Peoria based" || tour.format ==="3~Intl Peoria") {
            setShowStartLoading(true);
            generateTTPeoriaLeaderBoards(tour)
                .then((leaderboards: any) => {
                    setShowStartLoading(false);
                    showToast(`${tour.tour_name} weekly leadboards generated successfully`, 2000);
                })
                .catch((error: any) => {
                    setShowStartLoading(false);
                    showToast(error.message, 2000);
                    console.log(error);
                });
        }
        else if (tour.format === "2~1:1 Matches") {
            setShowStartLoading(true);
            generateTTOneOnOneLeaderBoards(tour)
                .then((leaderboards: any) => {
                    setShowStartLoading(false);
                    showToast(`${tour.tour_name} weekly leadboards generated successfully`, 2000);
                })
                .catch((error: any) => {
                    setShowStartLoading(false);
                    showToast(error.message, 2000);
                    console.log(error);
                });
        }
        else {
            showToast("something went wrong", 2000);
        }   
    }
    const handleClickTTSeasonal = (tour:any)=>{
        if (tour.format === "1~Peoria based" || tour.format === "3~Intl Peoria") {
            setShowStartLoading(true);
            generateTTPeoriaSeasonlLeaderBoards(tour)
                .then((leaderboards: any) => {
                    setShowStartLoading(false);
                    showToast(`${tour.tour_name} season leadboards generated successfully`, 2000);
                })
                .catch((error: any) => {
                    setShowStartLoading(false);
                    showToast(error.message, 2000);
                    console.log(error);
                });
        }
        else if (tour.format === "2~1:1 Matches") {
            setShowStartLoading(true);
            generateTTOneOnOneSeasonlLeaderBoards(tour)
                .then((leaderboards: any) => {
                    setShowStartLoading(false);
                    showToast(`${tour.tour_name} season leadboards generated successfully`, 2000);
                })
                .catch((error: any) => {
                    setShowStartLoading(false);
                    showToast(error.message, 2000);
                    console.log(error);
                });
        }
        else {
            showToast("LeaderBoards are not generated for Twilight Tour", 2000);
        }
    }
    useEffect(() => {
        getTwilightTourData();
    }, [twilightTours && twilightTours.length > 0]);
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <a onClick={() => window.history.back()}>
                            <IonIcon className="well-back-icon" icon={chevronBackOutline} />
                        </a>
                    </IonButtons>
                    <IonTitle>
                        News Feed
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonButton onClick={() => { handleClickPPW("weekly") }}>Generate Play Post Win weekly leaderboards</IonButton>
                        </IonCol>
                        <IonCol>
                            <IonButton onClick={() => { handleClickPPW("season") }}>Generate Play Post Win seasonal leaderboards</IonButton>
                        </IonCol>
                        <IonCol>
                            <IonButton onClick={() => { handleClickNSC("weekly") }}>Generate Net Score Challange weekly leaderboards</IonButton>
                        </IonCol>
                        <IonCol>
                            <IonButton onClick={() => { handleClickNSC("season") }}>Generate Net Score Challange seasonal leaderboards</IonButton>
                        </IonCol>
                        {twilightTours && twilightTours.length > 0 ?
                            twilightTours.map((tour: any, index: number) => (
                                <div key={index}>
                                    <IonCol>
                                        <IonButton onClick={() => { handleClickTTWeekly(tour) }}>Generate weekly leaderboards for {tour.tour_name}</IonButton>
                                    </IonCol>
                                    <IonCol>
                                        <IonButton onClick={() => { handleClickTTSeasonal(tour) }}>Generate seasonal leaderboards for {tour.tour_name}</IonButton>
                                    </IonCol>
                                </div>
                            ))
                            : ''}
                    </IonRow>
                </IonGrid>
                <IonLoading
                    isOpen={showStartLoading}
                    message={'creating Leaderboards..'}
                />
            </IonContent>
        </IonPage>
    )
};

export default AdminLeaderboards;