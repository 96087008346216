import logo from "../img/eclub.png"
import {
  IonBadge,
    IonCol,
    IonContent,
    IonFab,
    IonFabButton,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonModal,
    IonNote,
    IonRow,
    IonSearchbar,
    IonText,
    IonToggle,
  } from '@ionic/react';
  
  import React, { useEffect, useState } from 'react';
  import { useLocation } from 'react-router-dom';
  import { cube, exit, logIn, notifications, people, rocket, wallet, warning, cash, moon, sunny, arrowBackOutline, closeOutline} from 'ionicons/icons';
  import './Menu.css';
  import default_user_logo from "../img/default.png";

  import { getAuth, signOut } from "firebase/auth";
  import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
  import firebase from "../js/firebase";
 
  const db = getFirestore(firebase);
  const auth = getAuth(); 
  
  interface AppPage {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
    id: number;
  }
  
  const appPages: AppPage[] = [
    {
      title: 'Profile',
      url: '/profile',
      iosIcon: wallet,
      mdIcon: wallet,
      id: 1
    },
    {
      title: 'Wallet',
      url: '/wallet',
      iosIcon: wallet,
      mdIcon: wallet,
      id: 1
    },
    {
      title: 'My Pack',
      url: '/my_pack',
      iosIcon: people,
      mdIcon: people,
      id: 2
    },
    // {
    //   title: 'Match Invites',
    //   url: '/match_invites',
    //   iosIcon: golf,
    //   mdIcon: golf,
    //   id: 3
    // },
    {
      title: 'Notifications',
      url: '/notifications',
      iosIcon: notifications,
      mdIcon: notifications,
      id: 4
    },
    {
      title: 'Earnings',
      url: '/earnings',
      iosIcon: cash,
      mdIcon: cash,
      id: 5
    },
    {
      title: 'Report Abuse',
      url: '/report_abuse',
      iosIcon: warning,
      mdIcon: warning,
      id: 6
    },
    {
      title: 'Sign Out',
      url: '/login',
      iosIcon: exit,
      mdIcon: exit,
      id: 7
    }
    
  ];
  const adminPages: AppPage[] = [
    {
      title: 'Orders',
      url: '/admin_orders',
      iosIcon: cube,
      mdIcon: cube,
      id: 1
    },
    {
      title: 'Users',
      url: '/admin_users',
      iosIcon: people,
      mdIcon: people,
      id: 2
    },
    {
      title: 'User Logins',
      url: '/admin_user_logins',
      iosIcon: logIn,
      mdIcon: logIn,
      id: 3
    },
    {
      title: 'User Reports',
      url: '/admin_user_reports',
      iosIcon: rocket,
      mdIcon: rocket,
      id: 4
    },
    {
      title: 'Sign Out',
      url: '/login',
      iosIcon: exit,
      mdIcon: exit,
      id: 5
    }
  ];
  
  const Menu: React.FC = () => {
    const location = useLocation();
    const [current_pack, setCurrentPack] = useState<any>([]);
    const [current_user, setCurrentUser] = useState<any>([]);
    const [all_packs, setAllPacks] = useState<any>([]);
    const [set_show_pack_selection, setShowPackSelection] = useState<boolean>(false);
    const [new_packs,setNewPacks] = useState<any>([]);
    const [packs,setPacks] = useState<any>([]);
    const [pack_id,setPackId] = useState<string>('');
    const getAllPacks = async()=> {
      const q = query(collection(db, "PACKS"), where('pack_id', '!=', 'kuFOI2OPKCuhCwrewzBqDI8w71IE'));              
      const querySnapshot = await getDocs(q);
      if(querySnapshot.docs && querySnapshot.docs.length > 0) {
          await Promise.all(querySnapshot.docs.map(async pack => {
              var doc_item = pack.data();
              doc_item.id = pack.id;
              return doc_item;
          }))
          .then(results=> {
              setAllPacks(results);
          });
      } else {
          console.log("No packs available");
      }
    }
    const changePack = async(pack_id:string)=> {
      console.log()
      let pack = all_packs.filter((pack:any)=> pack.pack_id == pack_id);
      if(pack.length > 0) {
        localStorage.setItem('current_pack', 
          JSON.stringify({
            'pack_name': pack[0].pack_name,
            'pack_id': pack[0].pack_id,
            'region_name': pack[0].region_name,
            'region_id': pack[0].region_id,
            'pack_type': pack[0].pack_type,
            'pack_lead_id': pack[0].pack_lead_id,
            'city': pack[0].city
          })
        );
        setCurrentPack({
            'pack_name': pack[0].pack_name,
            'pack_id': pack[0].pack_id,
            'region_name': pack[0].region_name,
            'region_id': pack[0].region_id,
            'pack_type': pack[0].pack_type,
            'pack_lead_id': pack[0].pack_lead_id,
            'city': pack[0].city
        })
      }
    }
    
    const LogOut = ()=> {
      signOut(auth).then(() => {
        // Sign-out successful.
        localStorage.clear()
        console.log('logout successfully')
        window.location.href = '/'  
      }).catch((error) => {
        // An error happened.
      });
    }
    const [mode,setMode] = useState<any>(false)
    const toggleDarkModeHandler = (event:any)=> {      
      if(event.target.checked){
        document.body.classList.toggle("dark");
        setMode(true)
      }else{
        document.body.classList.toggle("dark");
        setMode(false)
      }
      
      if(document.body.classList.contains('dark')){
        localStorage.setItem("dark","true")
      }else{
        localStorage.removeItem("dark")
      }   
      //window.location.href=window.location.pathname
      
    }
    useEffect(() => {
      let user = localStorage.getItem('firebase_user');
      if(user != null) {
        setCurrentUser(JSON.parse(user));
        getAllPacks();
        setNewPacks(JSON.parse(user).packs);
        setPacks(JSON.parse(user).packs)
      }
      let current_pack = localStorage.getItem('current_pack');
      if(current_pack != null) {
        setCurrentPack(JSON.parse(current_pack));
      }
      let mode = localStorage.getItem("dark")
      if(mode!=null){        
        console.log("menu")
        setMode(true)
      }        
    },[])    
    return (
      <IonMenu contentId="main" side="end" type="overlay">
        <div className="ion-justify-content-center ion-text-center vh-8">
          <img src={logo} className="menu-logo"/>
        </div>
        <IonContent>
          <IonList id="inbox-list">
            {
              (current_user.is_portal_admin)?(
                <>
                <IonNote className="s-13 black">Admin</IonNote>
                {adminPages.map((appPage, index) => {
                  return (
                    <div key={index}>
                    {
                      (appPage.title!=="Sign Out")?(
                        <IonMenuToggle autoHide={false}>
                          <IonItem className="pointer" routerDirection="none" lines="none" detail={false} onClick={()=>{
                            window.location.href=`${appPage.url}`
                          }}>
                            <IonIcon slot="end" icon={appPage.iosIcon}/>
                            {
                              (appPage.title==="Notifications" || appPage.title==="Match Invites")?(
                                  <IonBadge color="medium" className="menu_badge">0</IonBadge>
                              ):(
                                <>
                                </>
                              )
                            }
                            <IonLabel>{appPage.title}</IonLabel>                      
                          </IonItem>
                        </IonMenuToggle>
                      ):(
                        <>
                          {/* <IonItem lines="none">
                            <IonLabel>Dark Mode</IonLabel>
                            <IonIcon size="small" icon={sunny} />
                            <IonToggle
                              name="darkMode"   
                              checked={mode}                           
                              onIonChange={toggleDarkModeHandler}
                            />
                            <IonIcon size="small" icon={moon} />
                          </IonItem> */}
                        
                          <IonMenuToggle autoHide={false}>
                            <IonItem className="pointer" lines="none" detail={false} onClick={LogOut}>
                              <IonIcon slot="end" icon={appPage.iosIcon} />
                              <IonLabel>{appPage.title}</IonLabel>
                            </IonItem>
                          </IonMenuToggle>
                        </>
                      )
                    }
                    </div>  
                  );
                })}
                <br/>
                <br/>
                <IonRow className="ion-justify-content-center text-align-center">
                  <IonItem className="pointer" lines="none" >
                    <IonText className="s-12">Version 2.7</IonText>
                  </IonItem>
                </IonRow>
                </>
              ):(
                <>
                <IonNote className="s-14 black">Pack:</IonNote>
                <IonNote className="s-14 color-red wg-600 pointer" onClick={()=> {
                  setShowPackSelection(true);
                }}>{current_pack.pack_name}</IonNote>
                {appPages.map((appPage, index) => {
                  return (
                    <div key={index}>
                    {
                      (appPage.title!=="Sign Out")?(
                        <IonMenuToggle autoHide={false}>
                          <IonItem className="pointer" routerDirection="none" lines="none" detail={false}onClick={()=>{
                            window.location.href=`${appPage.url}`
                          }}>
                            <>
                               {
                                 appPage.title == "Profile" ? (
                                   <>
                                    {
                                      (current_user.logo_url)?(
                                        <a href="/profile" slot="end"><img src={current_user.logo_url} className="menu-pic "/></a>
                                      ):(
                                        <a href="/profile" slot="end"><img src={default_user_logo} className="menu-pic"/></a>
                                      )
                                    }
                                  </>
                                 ) : (
                                  <>
                                  <IonIcon slot="end" icon={appPage.iosIcon}/>
                                  {
                                    (appPage.title==="Notifications" || appPage.title==="Match Invites")?(
                                        <IonBadge color="medium" className="menu_badge">0</IonBadge>
                                    ):(
                                      <>
                                      </>
                                    )
                                  }
                                  </>
                                 )
                               }
                              </>
                            <IonLabel>{appPage.title}</IonLabel>                      
                          </IonItem>
                        </IonMenuToggle>
                      ):(
                        <>
                          {/* <IonItem lines="none">
                            <IonLabel>Dark Mode</IonLabel>
                            <IonIcon size="small" icon={sunny} />
                            <IonToggle
                              name="darkMode"
                              checked={mode}      
                              onIonChange={toggleDarkModeHandler}
                            />
                            <IonIcon size="small" icon={moon} />
                          </IonItem> */}
                        
                          <IonMenuToggle autoHide={false}>
                            <IonItem className="pointer" lines="none" detail={false} onClick={LogOut}>
                              <IonIcon slot="end" icon={appPage.iosIcon} />
                              <IonLabel>{appPage.title}</IonLabel>
                            </IonItem>
                          </IonMenuToggle>
                        </>
                      )
                    }
                    </div>  
                  );
                })}
                <br/>
                <br/>
                <IonRow className="ion-justify-content-center text-align-center">
                  <IonItem lines="none" >
                    <IonText className="s-12">Version 2.7</IonText>
                  </IonItem>
                </IonRow>
              </>
              )
            }
            {/* <IonListHeader>Inbox</IonListHeader> */}
          </IonList> 
          <IonModal isOpen={set_show_pack_selection} className='my-custom-class2' showBackdrop={true} backdropDismiss={true} 
              onDidDismiss={() => setShowPackSelection(false)} breakpoints={[0.1, 0.7]}
              initialBreakpoint={0.7} mode="md">
                  <>
                  <IonSearchbar onIonChange={(e:any) => {
                      console.log(e.detail.value!)
                      if(String(e.detail.value!).length>0){
                          let glo = new RegExp(String(e.detail.value!).toLowerCase());
                          let new_pack_arr = new Array() 
                          packs.map((item:any)=>{
                              let real_item = item
                              item = item.state_name.toLowerCase()

                              if(glo.test(item)){
                                  console.log("item",real_item)
                                  new_pack_arr.push(real_item)
                              }
                          })    
                          console.log(new_pack_arr)                            
                          setNewPacks(new_pack_arr)                                    
                      }
                      else{
                          setNewPacks(packs)
                      }
                      }} mode="ios"></IonSearchbar>

                  <IonContent>
                      <IonGrid>
                          <IonList>
                            {
                            (new_packs && new_packs.map((key:any,i:number)=>{
                              return(
                                <IonItem key={i}>
                                    <IonLabel onClick={()=>{
                                        setPackId(key.pack_id);
                                        changePack(key.pack_id);
                                        setShowPackSelection(false)

                                    }}>
                                    {key.pack_name}
                                    </IonLabel>
                                </IonItem>
                              )
                            }))
                            }                                    
                              
                          </IonList>
                      </IonGrid>
                      <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '45px'}}>
                        <IonFabButton
                        className="main-fab"
                        onClick={() => {
                          setShowPackSelection(false)
                        }}
                        >
                        <IonIcon icon={closeOutline} />
                        </IonFabButton>
                    </IonFab>
                  </IonContent>
                  </>
              </IonModal>         
        </IonContent>
      </IonMenu>
    );
  };
  
  export default Menu;