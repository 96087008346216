import {
  IonContent,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonLabel,
  IonButtons,
  IonButton,
  IonGrid,
  IonFab,
  IonFabButton,
  IonCard,
  IonCol,
  IonItem,
  IonRow,
  IonText,
  IonInput,
  IonList,
  IonModal,
  IonSearchbar,
  useIonActionSheet,
  useIonAlert,
  IonDatetime,
  IonHeader,
} from "@ionic/react";
import "../../css/eclubhouse.css";
import {
  arrowBackOutline,
  calendar,
  chevronBackOutline,
  ellipsisVerticalCircleOutline,
} from "ionicons/icons";
import { isPlatform } from "@ionic/react";
import { Browser } from "@capacitor/browser";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import _ from "lodash";
import firebase from "../../js/firebase.js";
import Quill from "../Components/get_quill";
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { endOfMonth, startOfMonth } from "date-fns/esm";
import { updateEntry, createEntryWithoutId, getEntry } from "../../js/firebase_doc_functions";

const db = getFirestore(firebase);

const Events: React.FC = () => {
  const [events_data, setEventsData] = useState<any>([]);
  const [presentIonSheet, dismissIonsheet] = useIonActionSheet();
  const [presentAlert, dismissAlert] = useIonAlert();
  const [events_master_data, setEventsMasterData] = useState<any>([]);
  const [current_user, setCurrentUser] = useState<any>([]);
  const [event_type, setEventType] = useState<any>("All Types");
  const [event_month, setEventMonth] = useState<any>("2022");
  const [event_city, setEventCity] = useState<string>("All Cities");
  const [showEventTypeModal, setShowEventTypeModal] = useState<boolean>(false);
  const [regions, setRegions] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [showEventMonthModal, setShowEventMonthModal] = useState<boolean>(false);
  const [event_types, setEventTypes] = useState<any>([]);
  const [showEventCityModal, setShowEventCityModal] = useState(false);
  const [showEventRegionModal, setShowEventRegionModal] = useState(false);
  const [showEventEditModal, setShowEventEditModal] = useState(false);
  const [event_region, setEventRegion] = useState<string>("All Regions");
  const [new_types, setNewTypes] = useState<any>([]);
  const [new_cities, setNewCities] = useState<any>(cities);
  const [new_regions, setNewRegions] = useState<any>(regions);
  const [event_name, setEventName] = useState<string>("");
  const [rounds, setRounds] = useState<string>("");
  const [selected_date, setSelectedDate] = useState<string>(format(new Date(), "MM/dd/yyyy"));
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [url, setUrl] = useState<string>("");
  const [event_format, setEventFormat] = useState<string>("");
  const [days_of_week, setDaysOfWeek] = useState<string>("");
  const [age, setAge] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [publish_to, setPublishTo] = useState<string>("");
  const [organized_by, setOrganizedBy] = useState<string>("");
  const [content, setContent] = useState<string>('');
  const [event_id, setEventId] = useState<string>('');

  const [months, setMonths] = useState<any>([
    { text: "2022", value: "2022" },
    { text: "January", value: 1 },
    { text: "February", value: 2 },
    { text: "March", value: 3 },
    { text: "April", value: 4 },
    { text: "May", value: 5 },
    { text: "June", value: 6 },
    { text: "July", value: 7 },
    { text: "August", value: 8 },
    { text: "September", value: 9 },
    { text: "October", value: 10 },
    { text: "November", value: 11 },
    { text: "December", value: 12 },
  ]);

  const getConfig = async () => {
    let typesQuery = query(
      collection(db, "CONFIG"),
      where("type", "==", "EVENT_TYPES")
    );
    const typesQuerySnapshot = await getDocs(typesQuery);
    setEventTypes(typesQuerySnapshot.docs[0].data().types);
    setNewTypes(typesQuerySnapshot.docs[0].data().types);
    
    let citiesQuery = query(
      collection(db, "CONFIG"),
      where("type", "==", "EVENT_CITIES")
    );
    const citiesQuerySnapshot = await getDocs(citiesQuery);
    setCities(citiesQuerySnapshot.docs[0].data().cities);
    setNewCities(citiesQuerySnapshot.docs[0].data().cities);

    let regionsQuery = query(
      collection(db, "REGIONS"),
      where("created_at", ">", 0)
    );
    const regionsQuerySnapshot = await getDocs(regionsQuery);
    await Promise.all(
      regionsQuerySnapshot.docs.map(async (region) => {
        var doc_item = region.data();
        doc_item.id = region.id;
        return doc_item;
      })
    ).then((results) => {
      setRegions(results);
    });
  };
  const onQuillChange = (quillData: any) => {
    setContent(quillData);
  };
  const updateEvent = async() => {
    var obj = {
      event_name: event_name,
      rounds: Number(rounds),
      url: url,
      format: event_format,
      days_of_week: days_of_week,
      age: age,
      gender: gender,
      organized_by: organized_by,
      event_date: selected_date,
      event_description: content,
      event_date_timestamp: new Date(selected_date).getTime(),
    }
    await updateEntry("EVENTS", event_id, obj);
    getEvents();
    setShowEventEditModal(false);
  }

  const approveEvent = async(id:string) => {
    console.log("Approving Event: "+id);
    await updateEntry("EVENTS", id, {status: "Approved"});
    getEvents();
  }

  const setDataStoreObject = async () => {
    const configQuery = query(collection(db, "CONFIG"), where("type", "==", "POINTS_CALCULATION"));
    const configSnapShot = await getDocs(configQuery);
    if (configSnapShot && configSnapShot.docs.length > 0) {
        let document = configSnapShot.docs[0].data();
        document.id = configSnapShot.docs[0].id;
        return document;
    }
    return {};
  }

  const generateLeaderboard = async (event_id:any) => {
    const querySnapshot = await getEntry("EVENTS", event_id);
    if(!querySnapshot.created_at) {
        console.log("Event not found for Creating Leaderboard");
        return;
    }
    let doc_item = querySnapshot;
    doc_item.id = event_id;
    
    let curr_event = doc_item;

    if(curr_event.peoria_format) {
      console.log("Generating Peoria Leaderboard");
      generateEventPeoriaLeaderBoard(curr_event);
    }
    if(curr_event.netscore_format) {
      console.log("Generating Netscore Leaderboard");
      generateEventNetscoreLeaderBoard(curr_event);
    }

  };
  
  const generateEventNetscoreLeaderBoard = async (curr_event:any) => {
    console.log("genPLDR");
    console.log(curr_event);
    const event_leaderboard_query = query(collection(db, "EVENT_NS_LEADERBOARDS"), where("event_id", "==", curr_event.id));
    const eventLeaderboardQuerySnapshot = await getDocs(event_leaderboard_query);

    let eventLeaderboard = eventLeaderboardQuerySnapshot.docs && eventLeaderboardQuerySnapshot.docs.length > 0 ? true : false;
    if (eventLeaderboard) {
      console.log("Leaderboard is already created for this event");
      return;
  }
    let configData = await setDataStoreObject();
    var results = [];
    
    const getScores = query(collection(db, "SCORE_RECORDS"), where('event_id', '==', curr_event.id), where('holes', '==', 18));
    const scoresQuerySnapshot = await getDocs(getScores);
    if (scoresQuerySnapshot.docs && scoresQuerySnapshot.docs.length > 0) {
      for (var i = 0; i < scoresQuerySnapshot.docs.length; i++) {
        let score_item = scoresQuerySnapshot.docs[i].data();
        score_item.id = scoresQuerySnapshot.docs[i].id;
        console.log("score_item", score_item);
        if (score_item.score_submitted) {
          score_item.net_score = score_item.points.total_points - score_item.quota;
          results.push(score_item);
        }
      }
    }

    let leaderboard_records = _.orderBy(results, ['net_score'], ['desc']);
    
    if (leaderboard_records.length > 2) {

        let current_index = 0;
        let rank_index = 0;
        let previous_points = 0;
        let net_score_idx = 0;

        for (var record of leaderboard_records) {
            if (record.net_score == previous_points) {
                if (rank_index == 0) {
                    leaderboard_records[current_index].position = rank_index + 1;
                    leaderboard_records[current_index].medal_type = net_score_idx + 1;
                } else {
                    if (net_score_idx < 3) {
                        leaderboard_records[current_index].medal_type = net_score_idx;
                        leaderboard_records[current_index - 1].medal_type = net_score_idx;
                    }
                    leaderboard_records[current_index].position = "T" + rank_index;
                    leaderboard_records[current_index - 1].position = "T" + rank_index;
                }
            } else {
                previous_points = record.net_score;
                if (net_score_idx < 3) {
                    leaderboard_records[current_index].medal_type = net_score_idx + 1;
                    net_score_idx = net_score_idx + 1;
                } else {
                    leaderboard_records[current_index].medal_type = 0;
                }

                leaderboard_records[current_index].position = current_index + 1;
                rank_index = current_index + 1;
            }
            current_index++;
        }

        if (leaderboard_records.length) {
            createEntryWithoutId("EVENT_NS_LEADERBOARDS", {
                type: "EVENTS",
                season_type: "ONETIME",
                holes: 18,
                records: leaderboard_records,
                season: 5,
                season_name: "Summer 2022",
                is_admin: false,
                is_active: true,
                event_id: curr_event.id,
                leaderboard_generated_timestamp: new Date().getTime()
            });
        }
        console.log("Leaderboard created successfully");
    } else {
        throw new Error("Minumum 3 players are needed to generate a leaderboard.");

    }

  };

  const generateEventPeoriaLeaderBoard = async (curr_event:any) => {
    
    const event_leaderboard_query = query(collection(db, "EVENT_PEORIA_LEADERBOARDS"), where("event_id", "==", curr_event.id));
    const eventLeaderboardQuerySnapshot = await getDocs(event_leaderboard_query);

    let eventLeaderboard = eventLeaderboardQuerySnapshot.docs && eventLeaderboardQuerySnapshot.docs.length > 0 ? true : false;
    if (eventLeaderboard) {
        console.log("Leaderboard is already created for this event");
        return;
    }

    let configData = await setDataStoreObject();
    
    var randomHolesFront = _.sampleSize([1, 2, 3, 4, 5, 6, 7, 8, 9], 6);
    var randomHolesBack = _.sampleSize([10, 11, 12, 13, 14, 15, 16, 17, 18], 6);
    var randomHoles = (randomHolesFront.concat(randomHolesBack)).sort((a, b) => b - a);
    var results = [];
    
    const getScores = query(collection(db, "SCORE_RECORDS"), where('event_id', '==', curr_event.id), where('holes', '==', 18));
    const scoresQuerySnapshot = await getDocs(getScores);
    if (scoresQuerySnapshot.docs && scoresQuerySnapshot.docs.length > 0) {
        for (var i = 0; i < scoresQuerySnapshot.docs.length; i++) {
            let score_item = scoresQuerySnapshot.docs[i].data();
            score_item.id = scoresQuerySnapshot.docs[i].id;
            console.log("score_item", score_item);
            if (score_item.score_submitted && score_item.hole_details.length == 18) {
                var selected_hole_points = {
                    "triple_bogey": 0,
                    "double_bogey": 0,
                    "bogey": 0,
                    "par_value": 0,
                    "birdie": 0,
                    "eagle": 0,
                    "double_eagle": 0,
                };

                var all_hole_points = {
                    "triple_bogey": 0,
                    "double_bogey": 0,
                    "bogey": 0,
                    "par_value": 0,
                    "birdie": 0,
                    "eagle": 0,
                    "double_eagle": 0,
                };

                var total_points = 0;
                var peoria_points = 0;//peoria target
                var par_value = 0

                // eslint-disable-next-line no-loop-func
                score_item.leaderboard_holes = score_item.hole_details.map((hole:any) => {
                    if (randomHoles.indexOf(hole.hole_number) > -1) {
                        if ((hole.raw_score - hole.par) > 2) {
                            hole.points = configData.triple_bogeys;
                            hole.points_type = "triple_bogey";
                            all_hole_points.triple_bogey++;
                            selected_hole_points.triple_bogey++;
                        } else if ((hole.raw_score - hole.par) == 2) {
                            hole.points = configData.double_bogeys;
                            hole.points_type = "double_bogey";
                            all_hole_points.double_bogey++;
                            selected_hole_points.double_bogey++;
                        } else if ((hole.raw_score - hole.par) == 1) {
                            hole.points = configData.bogeys;
                            hole.points_type = "bogey";
                            all_hole_points.bogey++;
                            selected_hole_points.bogey++;
                        } else if ((hole.raw_score - hole.par) == 0) {
                            hole.points = configData.pars;
                            hole.points_type = "par_value";
                            all_hole_points.par_value++;
                            selected_hole_points.par_value++;
                        } else if ((hole.raw_score - hole.par) == -1) {
                            hole.points = configData.birdies;
                            hole.points_type = "birdie";
                            all_hole_points.birdie++;
                            selected_hole_points.birdie++;
                        } else if ((hole.raw_score - hole.par) == -2) {
                            hole.points = configData.eagles;
                            hole.points_type = "eagle";
                            all_hole_points.eagle++;
                            selected_hole_points.eagle++;
                        } else if ((hole.raw_score - hole.par) == -3) {
                            hole.points = configData.eagles;
                            hole.points_type = "double_eagle";
                            all_hole_points.double_eagle++;
                            selected_hole_points.double_eagle++;
                        }

                        total_points += hole.points;
                        peoria_points += hole.points;
                        hole.is_picked = true;
                        par_value += hole.par;
                        return hole;
                    } else {
                        if ((hole.raw_score - hole.par) > 2) {
                            hole.points = configData.triple_bogeys;
                            hole.points_type = "triple_bogey";
                            all_hole_points.triple_bogey++;
                        } else if ((hole.raw_score - hole.par) == 2) {
                            hole.points = configData.double_bogeys;
                            hole.points_type = "double_bogey";
                            all_hole_points.double_bogey++;
                        } else if ((hole.raw_score - hole.par) == 1) {
                            hole.points = configData.bogeys;
                            hole.points_type = "bogey";
                            all_hole_points.bogey++;
                        } else if ((hole.raw_score - hole.par) == 0) {
                            hole.points = configData.pars;
                            hole.points_type = "par_value";
                            all_hole_points.par_value++;
                        } else if ((hole.raw_score - hole.par) == -1) {
                            hole.points = configData.birdies;
                            hole.points_type = "birdie";
                            all_hole_points.birdie++;
                        } else if ((hole.raw_score - hole.par) == -2) {
                            hole.points = configData.eagles;
                            hole.points_type = "eagle";
                            all_hole_points.eagle++;
                        } else if ((hole.raw_score - hole.par) == -3) {
                            hole.points = configData.eagles;
                            hole.points_type = "double_eagle";
                            all_hole_points.double_eagle++;
                        }

                        total_points += hole.points;
                        hole.is_picked = false;
                        par_value += hole.par;
                        return hole;
                    }
                });
                let peoria_quota = Math.floor(peoria_points * 1.5);//peoria target
                peoria_quota = peoria_quota > score_item.quota/2 ? peoria_quota : Math.ceil(score_item.quota / 2);
                score_item.net_score = Math.floor(total_points - peoria_quota);
                score_item.hole_details = score_item.leaderboard_holes;

                score_item.total_points = Number(total_points);
                score_item.peoria_points = Number(peoria_points);
                score_item.peoria_quota = Number(peoria_quota);
                //createEntryWithoutId('PPW_COMPETITIONS', score_item);
                results.push(score_item);
            }
        }
    }

    let leaderboard_records = _.orderBy(results, ['net_score'], ['desc']);
    
    if (leaderboard_records.length > 2) {

        let current_index = 0;
        let rank_index = 0;
        let previous_points = 0;
        let net_score_idx = 0;

        for (var record of leaderboard_records) {
            if (record.net_score == previous_points) {
                if (rank_index == 0) {
                    leaderboard_records[current_index].position = rank_index + 1;
                    leaderboard_records[current_index].medal_type = net_score_idx + 1;
                } else {
                    if (net_score_idx < 3) {
                        leaderboard_records[current_index].medal_type = net_score_idx;
                        leaderboard_records[current_index - 1].medal_type = net_score_idx;
                    }
                    leaderboard_records[current_index].position = "T" + rank_index;
                    leaderboard_records[current_index - 1].position = "T" + rank_index;
                }
            } else {
                previous_points = record.net_score;
                if (net_score_idx < 3) {
                    leaderboard_records[current_index].medal_type = net_score_idx + 1;
                    net_score_idx = net_score_idx + 1;
                } else {
                    leaderboard_records[current_index].medal_type = 0;
                }

                leaderboard_records[current_index].position = current_index + 1;
                rank_index = current_index + 1;
            }
            current_index++;
        }

        if (leaderboard_records.length) {
            createEntryWithoutId("EVENT_PEORIA_LEADERBOARDS", {
                type: "EVENTS",
                season_type: "ONETIME",
                holes: 18,
                records: leaderboard_records,
                season: 5,
                season_name: "Summer 2022",
                is_admin: false,
                is_active: true,
                event_id: curr_event.id,
                leaderboard_generated_timestamp: new Date().getTime()
            });
        }
        console.log("Leaderboard created successfully");
    } else {
        throw new Error("Minumum 3 players are needed to generate a leaderboard.");

    }
}; 

  const getEvents = async () => {
    const q = query(
      collection(db, "EVENTS"),
      where("created_at", ">", 0),
      orderBy("created_at", "desc")
    );
    const querySnapshot = await getDocs(q);
    if(querySnapshot.docs && querySnapshot.docs.length > 0) {
      await Promise.all(
        querySnapshot.docs.map(async (event) => {
          var doc_item = event.data();
          doc_item.id = event.id;
          return doc_item;
        })
      ).then((results) => {
        setEventsData(results);
        setEventsMasterData(results);
      });
    }
  };
  const openActionsheet = async (event_id: string) => {
    presentIonSheet({
      buttons: [
        {
          text: "Edit",
          handler: () => {
            setEventId(event_id);
            let event = events_master_data.filter((x:any)=> x.id == event_id);
            console.log(event[0]);
            setEventName(event[0].event_name);
            setRounds(event[0].rounds);
            setUrl(event[0].url);
            setEventFormat(event[0].format);
            setDaysOfWeek(event[0].days_of_week);
            setAge(event[0].age);
            setGender(event[0].gender);
            setOrganizedBy(event[0].organized_by);
            setSelectedDate(format(new Date(event[0].event_date), "MM/dd/yyyy"));
            setContent(event[0].event_description);
            setShowEventEditModal(true);
          },
        },
        {
          text: "Delete",
          handler: async () => {
            presentAlert({
              header: 'Delete event?',
              message: 'Are you sure to delete this event?',
              buttons: [
                'Cancel',
                { text: 'Yes', handler: async (d) => {
                  await deleteDoc(doc(db, "EVENTS", event_id));
                  getEvents();
                  alert("Post deleted successfully!");
                }},
              ]
            })
          },
        },
        {
          text: "Close",
          role: "cancel",
        },
      ],
    });
  };
  const filterEvents = async (event_type: string, event_month: any, event_region:string, event_city:string) => { 
    let date =
    new Date().getFullYear() +
    " " +
    event_month +
    " " +
    new Date().getDate();
    let startOf = startOfMonth(new Date(date));
    let endOf = endOfMonth(new Date(date));
    let results = new Array();
    if(events_master_data.length > 0) {
      if(event_type == "All Types" && event_month == "2022" && event_region == "All Regions" && event_city == "All Cities") {
        results = events_master_data;
      }
      if(event_type != "All Types") {
        let filteredEvents = events_master_data.filter(
            (x: any) => x.event_type.toLowerCase() == event_type.toLowerCase() &&
            (event_month != "2022" ? (x.event_date_timestamp >= new Date(startOf).getTime() &&
            x.event_date_timestamp <= new Date(endOf).getTime()) : x.event_date_timestamp > 0) &&
            (event_region == "All Regions" ? x.region_name.length > 0 : x.region_name.toLowerCase() == event_region.toLowerCase()) &&
            (event_city == "All Cities" ? x.city.length > 0 : x.city.toLowerCase() == event_city.toLowerCase())
        );
        results = results.concat(filteredEvents);
      }
      if(event_month != "2022") {
        let filteredEvents = events_master_data.filter(
            (x: any) =>
            (event_type == "All Types" ? x.event_type.length > 0 : x.event_type.toLowerCase() == event_type.toLowerCase()) &&
            x.event_date_timestamp >= new Date(startOf).getTime() &&
            x.event_date_timestamp <= new Date(endOf).getTime() &&
            (event_region == "All Regions" ? x.region_name.length > 0 : x.region_name.toLowerCase() == event_region.toLowerCase()) &&
            (event_city == "All Cities" ? x.city.length > 0 : x.city.toLowerCase() == event_city.toLowerCase())
        );
        results = results.concat(filteredEvents);
      }
      if(event_region != "All Regions") {
        let filteredEvents = events_master_data.filter(
            (x: any) => (event_type == "All Types" ? x.event_type.length > 0 : x.event_type.toLowerCase() == event_type.toLowerCase()) &&
            (event_month != "2022" ? (x.event_date_timestamp >= new Date(startOf).getTime() &&
            x.event_date_timestamp <= new Date(endOf).getTime()) : x.event_date_timestamp > 0) &&
            x.region_name.toLowerCase() == event_region.toLowerCase() &&
            (event_city == "All Cities" ? x.city.length > 0 : x.city.toLowerCase() == event_city.toLowerCase())
          );
          results = results.concat(filteredEvents);
      }
      if(event_city != "All Cities") {
        let filteredEvents = events_master_data.filter(
            (x: any) => (event_type == "All Types" ? x.event_type.length > 0 : x.event_type.toLowerCase() == event_type.toLowerCase()) &&
            (event_month != "2022" ? (x.event_date_timestamp >= new Date(startOf).getTime() &&
            x.event_date_timestamp <= new Date(endOf).getTime()) : x.event_date_timestamp > 0) &&
            (event_region == "All Regions" ? x.region_name.length > 0 : x.region_name.toLowerCase() == event_region.toLowerCase()) &&
            x.city.toLowerCase() == event_city.toLowerCase()
          );
          results = results.concat(filteredEvents);
      }
      let uniqueEvents = _.uniqBy(results, 'id');
      setEventsData(uniqueEvents);
    }
  };
  useEffect(() => {
    let user = localStorage.getItem("firebase_user");
    if (user != null) {
      setCurrentUser(JSON.parse(user));
      getConfig();
      getEvents();
    }
  }, []);
  return (
    <IonPage>
      <IonToolbar
        mode="ios"
        className={
          isPlatform("ios")
            ? "ios-toolbar-top box-shadow"
            : "toolbar-top box-shadow"
        }
      >
        <IonButtons slot="start">
          <a onClick={() => (window.location.href = "/admin_home")}>
            <IonIcon className="well-back-icon" icon={chevronBackOutline} />
          </a>
        </IonButtons>
        <IonTitle>
          <IonLabel>
            <IonText>Events</IonText>
          </IonLabel>
        </IonTitle>
        <IonButtons slot="end"></IonButtons>
      </IonToolbar>
      <IonContent>
        <IonRow className="ion-justify-content-center mt-10">
          <IonCol size="3">
            <IonInput
              value={event_type}
              placeholder="Select type"
              className="s-14 select-dropdown pl-20"
              mode="ios"
              onClick={() => {
                setShowEventTypeModal(true);
                setEventType(event_type);
              }}
            ></IonInput>
          </IonCol>
          <IonCol size="3">
            <IonInput
              value={event_month}
              placeholder="Select month"
              className="s-14 select-dropdown pl-20"
              mode="ios"
              onClick={() => {
                setShowEventMonthModal(true);
                setEventMonth(event_month);
              }}
            ></IonInput>
          </IonCol>
          <IonCol size="3">
            <IonInput
              value={event_region}
              placeholder="Select region"
              className="s-14 select-dropdown pl-20"
              mode="ios"
              onClick={() => {
                setShowEventRegionModal(true);
                setEventRegion(event_region);
              }}
            ></IonInput>
          </IonCol>
          <IonCol size="3">
            <IonInput
              value={event_city}
              placeholder="Select city"
              className="s-14 select-dropdown pl-20"
              mode="ios"
              onClick={() => {
                setShowEventCityModal(true);
                setEventCity(event_city);
              }}
            ></IonInput>
          </IonCol>
        </IonRow>
        <IonContent style={{ overflowY: "auto" }}>
          {events_data &&
            events_data.map((item: any, index: number) => {
              return (
                <IonCard key={index} className="pro-shop-cards">
                  <IonRow className="ion-text-start">
                    <IonCol size="10" className="pad-5">
                      <IonText className="s-16 wg-600 color-blue">{item.event_name}</IonText><br/>
                      <IonText className="s-12 wg-600 color-gray">{format(item.event_date_timestamp, "MM/dd/yyyy")}</IonText>
                    </IonCol>
                    <IonCol size="2" className="events-card-options">
                      <IonRow
                        onClick={() =>
                          openActionsheet(item.id)
                        }
                      >
                        <IonIcon
                          icon={ellipsisVerticalCircleOutline}
                          className=""
                        />
                      </IonRow>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-text-start">
                    <IonCol size="12" className="pad-5">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.event_description,
                        }}
                        className="mb-10 color-dark-gray"
                      ></div>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-text-start mt-10">
                    <IonCol size="3">
                      <IonButton className="pro-button" onClick={e => {
                        console.log(item);
                        Browser.open(item.url);
                      }}>
                        <IonText>
                          About
                        </IonText>
                      </IonButton>
                    </IonCol>
                    <IonCol size="4">
                      <IonButton className="pro-button" onClick={e => {
                            window.location.href = "/event_admin_checkin/" + item.id
                          }}>

                        <IonText>
                          Post Score
                        </IonText>
                      </IonButton>
                    </IonCol>
                    <IonCol size="5">
                    {
                        (item.status === "Pending Approval") ? (
                          <IonButton className="pro-button" onClick={e=> {
                                    approveEvent(item.id);
                                }}>
                                    Approve
                          </IonButton>
                        ): (
                          <IonButton className="pro-button" onClick={e=> {
                                    generateLeaderboard(item.id);
                                }}>
                                    Create Leaderboard
                          </IonButton>
                        )
                      }
                    </IonCol>
                  </IonRow>
                </IonCard>
              );
            })}
        </IonContent>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton
            className="main-fab"
            onClick={() => {
              window.location.href = "/create_event";
            }}
          >
            <IonIcon icon={calendar} />
          </IonFabButton>
        </IonFab>
        <IonModal
          isOpen={showEventTypeModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowEventTypeModal(false)}
          mode="md"
        >
          <>
            <IonContent>
              <IonGrid>
                <IonList>
                  {event_types &&
                    event_types.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              setEventType(key);
                              filterEvents(key, event_month, event_region, event_city);
                              setShowEventTypeModal(false);
                            }}
                          >
                            {key}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showEventMonthModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowEventMonthModal(false)}
          mode="md"
        >
          <>
            <IonContent>
              <IonGrid>
                <IonList>
                  {months &&
                    months.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              setEventMonth(key.text);
                              filterEvents(event_type, key.value, event_region, event_city);
                              setShowEventMonthModal(false);
                            }}
                          >
                            {key.text}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showEventCityModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowEventCityModal(false)}
          mode="md"
        >
          <>
            <IonSearchbar
              onIonChange={(e: any) => {
                if (String(e.detail.value!).length > 0) {
                  let glo = new RegExp(String(e.detail.value!).toLowerCase());
                  let new_city_arr = new Array();
                  cities.map((item: any) => {
                    let real_item = item;
                    item = item.state_name.toLowerCase();

                    if (glo.test(item)) {
                      new_city_arr.push(real_item);
                    }
                  });
                  setNewCities(new_city_arr);
                } else {
                  setNewCities(cities);
                }
              }}
              mode="ios"
            ></IonSearchbar>
            <IonContent>
              <IonGrid>
                <IonList>
                  {cities &&
                    cities.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              setEventCity(key);
                              filterEvents(event_type, event_month, event_region, key);
                              setShowEventCityModal(false);
                            }}
                          >
                            {key}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showEventRegionModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowEventRegionModal(false)}
          mode="md"
        >
          <>
            <IonSearchbar
              onIonChange={(e: any) => {
                if (String(e.detail.value!).length > 0) {
                  let glo = new RegExp(String(e.detail.value!).toLowerCase());
                  let new_city_arr = new Array();
                  regions.map((item: any) => {
                    let real_item = item;
                    item = item.state_name.toLowerCase();
                    if (glo.test(item)) {
                      new_city_arr.push(real_item);
                    }
                  });
                  setNewRegions(new_city_arr);
                } else {
                  setNewRegions(regions);
                }
              }}
              mode="ios"
            ></IonSearchbar>
            <IonContent>
              <IonGrid>
                <IonList>
                <IonItem>
                    <IonLabel
                      onClick={() => {
                        setEventRegion("All Regions");
                        filterEvents(event_type, event_month, "All Regions", event_city);
                        setShowEventRegionModal(false);
                      }}
                    >
                      All Regions
                    </IonLabel>
                  </IonItem>
                  {regions &&
                    regions.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              setEventRegion(key.region_name);
                              filterEvents(event_type, event_month, key.region_name, event_city);
                              setShowEventRegionModal(false);
                            }}
                          >
                            {key.region_name}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showEventEditModal}
          showBackdrop={true}
          backdropDismiss={true}
          onDidDismiss={() => setShowEventEditModal(false)}
          mode="md"
        >
          <IonHeader mode="ios">
              <IonToolbar>
                <IonButtons slot="start">
                  {/* <a onClick={()=> setShowModal(false)}>
                  <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                </a> */}
                </IonButtons>
                <IonTitle>Edit Event</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
        <IonGrid>
          <>
            <IonRow className="ion-justify-content-center mt-10 select-modal">
              <IonInput
                value={event_name}
                placeholder="Event name"
                className="s-14 select-pack-dropdown pl-20"
                mode="ios"
                onIonChange={(e) => {
                  setEventName(e.detail.value!);
                }}
              >
              </IonInput>
            </IonRow>
            <IonRow
              className="ion-justify-content-center mt-10 select-modal"
              style={{ padding: "0px 10px 0px 10px" }}
            >
              <IonCol className="pad-0">
                <IonInput
                  value={event_format}
                  placeholder="Event format"
                  className="s-14 select-pack-dropdown pl-20"
                  mode="ios"
                  onIonChange={(e) => {
                    setEventFormat(e.detail.value!);
                  }}
                >
                </IonInput>
              </IonCol>
              <IonCol className="pad-0">
                <IonInput
                  value={rounds}
                  placeholder="Rounds"
                  className="s-14 select-pack-dropdown pl-20"
                  mode="ios"
                  onIonChange={(e) => {
                    setRounds(e.detail.value!);
                  }}
                >
                </IonInput>
              </IonCol>
            </IonRow>
            <IonRow
              className="ion-justify-content-center mt-10 select-modal"
              style={{ padding: "0px 10px 0px 10px" }}
            >
              <IonCol className="pad-0">
                <IonInput value={selected_date}            
                  placeholder="Select date" className="s-14 select-pack-dropdown pl-20" 
                  mode="ios" 
                  onClick={()=> {
                    setShowDatePicker(true);
                  }}>
                </IonInput>
                {
                  (showDatePicker) ? (
                      <IonDatetime
                          presentation="date"
                          size='cover'
                          onIonChange={(e) => {
                              setSelectedDate(format(new Date(e.detail.value!), "MM/dd/yyyy"));
                              setShowDatePicker(false);
                          }}
                      ></IonDatetime>
                  ) : (
                      <></>
                  )
                }
              </IonCol>
              <IonCol className="pad-0">
                <IonInput
                  value={days_of_week}
                  placeholder="Days of week"
                  className="s-14 select-pack-dropdown pl-20"
                  mode="ios"
                  onIonChange={(e) => {
                    setDaysOfWeek(e.detail.value!);
                  }}
                >
                </IonInput>
              </IonCol>
            </IonRow>
            <IonRow
              className="ion-justify-content-center mt-10 select-modal"
            >
                <IonInput
                  value={age}
                  placeholder="Age/Gender"
                  className="s-14 select-pack-dropdown pl-20"
                  mode="ios"
                  onIonChange={(e) => {
                    setAge(e.detail.value!);
                  }}
                >
                </IonInput>
            </IonRow>
            <IonRow
              className="ion-justify-content-center mt-10 select-modal"
            >
              <IonInput
                value={organized_by}
                placeholder="Organized by"
                className="s-14 select-pack-dropdown pl-20"
                mode="ios"
                onIonChange={(e) => {
                  setOrganizedBy(e.detail.value!);
                }}
              >
              </IonInput>
            </IonRow> 
            <IonRow
              className="ion-justify-content-center mt-10 mb-10 select-modal"
            >
              <IonInput
                value={url}
                placeholder="Add URL"
                className="s-14 select-pack-dropdown pl-20"
                mode="ios"
                onIonChange={(e) => {
                  setUrl(e.detail.value!);
                }}
              >
              </IonInput>
            </IonRow>
            <Quill onQuill={onQuillChange} content={content}/>
          </>
          <IonRow className="ion-justify-content-center ion-text-center mt-25">
            <button className="button-send white s-15 bg" onClick={updateEvent}>
              <IonText className="wg-bold">Update</IonText>
            </button>
          </IonRow>
        </IonGrid>
        <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '45px'}}>
          <IonFabButton
          className="main-fab"
          onClick={() => {
            setShowEventEditModal(false);
          }}
          >
          <IonIcon icon={arrowBackOutline} />
          </IonFabButton>
        </IonFab>
      </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Events;
