import { collection, query } from '@firebase/firestore';
import { IonContent, IonPage, IonTitle, IonToolbar, IonLabel,IonButtons, IonGrid, IonSegment, IonSegmentButton,  IonText, isPlatform, IonDatetime, IonModal, IonCol, IonRow, IonFab, IonFabButton, IonIcon, IonCard, IonHeader } from '@ionic/react';
import { format, nextSunday,startOfWeek } from 'date-fns';
import { getDocs, getFirestore, where } from 'firebase/firestore';
import { arrowBackOutline } from 'ionicons/icons';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import bronze_medal from '../../img/bronze.png';
import silver_medal from '../../img/silver.png';
import gold_medal from '../../img/gold.png';
import firebase from '../../js/firebase';
import ScoreTable from '../Components/score_table';

const db = getFirestore(firebase);

const PlayPostWinLeaderboards: React.FC = () => {
    const [current_user, setCurrentUser] = useState<any>([]);
    const [users,setUsers] = useState<any>("Players");
    const [open,setOpen] = useState<boolean>(false);
    const [selected_date, setSelectedDate] = useState<any>(format(startOfWeek(new Date(), { weekStartsOn: 0 }), "yyyy-MM-dd"));
    const [min_date, setMinDate] = useState<string>('2019-01-01');
    const [max_date, setMaxDate] = useState<string>(format(new Date(), "yyyy-mm-dd"));
    const [showLeaderboardPicker, setShowLeaderboardPicker] = useState<boolean>(false);
    const [leaderboards_tab_value, setLeaderboardsTabValue] = useState<string>('Weekly');
    const [weekly_leaderboard_data, setWeeklyLeaderboardData] = useState<any>([]);
    const [score_record, setScoreRecord] = useState<any>([]);
    const [score_modal,setScoreModal] = useState<any>(false)
    const [season_leaderboard_users_data, setSeasonLeaderboardUsersData] = useState<any>([]);
    const [season_leaderboard_others_data, setSeasonLeaderboardOthersData] = useState<any>([]);
    const [season_name, setSeasonName] = useState<string>('');
    const getLeaderBoards = async (leaderboards_tab_value:string, date_to:string) => {
        if(leaderboards_tab_value == 'Weekly') {
            const q = query(collection(db, "WEEKLY_PPW_LEADERBOARDS"), where('created_at', '>', 0), where('to_date', '==',date_to));              
            const querySnapshot = await getDocs(q);
            if(querySnapshot.docs && querySnapshot.docs.length > 0) {
                await Promise.all(
                    querySnapshot.docs.map((item:any)=> {
                        var doc_item = item.data();
                        doc_item.id = item.id;
                        return doc_item;
                    })
                ).then((results) => {
                    //setTeeTimes(_.sortBy(results, (e: any) => e.created_at).reverse());
                    let records = JSON.parse(JSON.stringify(results));
                    
                    if (records.length > 0) {
                        records = records[0].records.map((record:any)=> {
                            if (record.medal_type == undefined || record.medal_type > 3) {
                                record.medal_type = 0
                            }
                            if(record.net_score == 0) {
                                record.net_score = 'E';
                            }
                            return record;
                        });
                        setSeasonName(' - '+results[0].season_name);
                    } else {
                        setSeasonName('');
                        records = [];
                    }
                    setWeeklyLeaderboardData(records);
                });
            } else {
                setSeasonName('');
                setWeeklyLeaderboardData([]);
            }
        } else if(leaderboards_tab_value == 'Season') {
            const q = query(collection(db, "PPW_SEASON_LEADERBOARDS"), where('to_date', '==', date_to));              
            const querySnapshot = await getDocs(q);
            if(querySnapshot.docs && querySnapshot.docs.length > 0) {
                await Promise.all(
                    querySnapshot.docs.map((item:any)=> {
                        var doc_item = item.data();
                        doc_item.id = item.id;
                        return doc_item;
                    })
                ).then((results) => {
                    let records = JSON.parse(JSON.stringify(results));
                    if(records.length > 0) {
                        let leaderboardUsers = records[0].users;
                        //let otherUsers = records[0].users.filter((user:any)=> user.competitions_length < 8);
                        if(records[0] && records[0].users) {
                            setSeasonName(' - '+results[0].season_name);
                            setSeasonLeaderboardUsersData(leaderboardUsers);
                            //setSeasonLeaderboardOthersData(otherUsers);
                        } else {
                            setSeasonName('');
                            setSeasonLeaderboardUsersData([]);
                            setSeasonLeaderboardOthersData([]);
                        }
                    } else {
                        setSeasonName('');
                        setSeasonLeaderboardUsersData([]);
                        setSeasonLeaderboardOthersData([]);
                    }
                });
            } else {
                setSeasonName('');
                setSeasonLeaderboardUsersData([]);
                setSeasonLeaderboardOthersData([]);
            }
        }
    }
    const showScoreRecord = async(idx:number) => {
        let record = weekly_leaderboard_data[idx] || {};
        record.user_name = record.first_name+' '+record.last_name;
        record.date = record.played_at;
        let courseStr = record.course_display_value.split("-");
        if (courseStr[0] != undefined || courseStr[0] != null)
        record.course_name = courseStr[0];
        if (courseStr[1] != undefined || courseStr[1] != null)
        record.course_sub_name = courseStr[1];
        if(record.user_name) {
            // console.log("score record",score_record)
            setScoreRecord(record)
            setScoreModal(true)
        }
    }
    useEffect(()=> {
        let user = localStorage.getItem('firebase_user'); 
        if(user != null) {
            setCurrentUser(JSON.parse(user));
            getLeaderBoards("Weekly", format(startOfWeek(new Date(), { weekStartsOn: 0 }), "yyyy-MM-dd"))
        }
    }, [])
    return (
        <IonPage>
            <IonToolbar mode="ios" className={isPlatform('ios') ? 'ios-toolbar-top box-shadow': 'toolbar-top box-shadow' }>
                <IonLabel slot="start" className="s-16 w-800 color-blue" style={{padding: '0px 0px 0px 10px'}}>
                    Play.Post.Win.{season_name}
                </IonLabel>
                <IonRow slot="end" style={{flexDirection: 'column', textAlign: 'center'}} onClick={()=> {setShowLeaderboardPicker(true);
                    }}>
                    <IonText className="s-12">
                        Week ending:
                    </IonText>
                    <IonText className="color-blue s-14" >{selected_date}
                    </IonText>
                </IonRow>
            </IonToolbar>
            <IonContent>
                <>
                {
                    leaderboards_tab_value == "Weekly" ? (
                        <IonGrid>
                            <IonRow className="ion-justify-content-center ion-text-center bb-line">
                                <IonCol size="1.5">
                                    <IonText className="s-11 wg-600">Pos</IonText>
                                </IonCol>
                                <IonCol size="3.5">
                                    <IonText className="s-11 wg-600">Player</IonText>
                                </IonCol>
                                <IonCol size="2.5">
                                    <IonText className="s-11 wg-600">Net<br/>Score</IonText>
                                </IonCol>
                                <IonCol size="2.5">
                                    <IonText className="s-11 wg-600">Points</IonText>
                                </IonCol>
                                <IonCol size="2">
                                    <IonText className="s-11 wg-600">Medals</IonText>
                                </IonCol>
                            </IonRow>
                            {
                                weekly_leaderboard_data && weekly_leaderboard_data.map((item:any, index:number)=> {
                                    return(
                                        <IonRow className="ion-justify-content-center ion-text-center bb-line" key={index}>
                                            <IonCol size="1.5">
                                                <IonText className="s-12 wg-600">{item.position}</IonText>
                                            </IonCol>
                                            <IonCol size="3.5">
                                                <IonText className="s-12 wg-600 color-blue" onClick={()=>{showScoreRecord(index)}}>{item.first_name}<br />{item.last_name}<br/><span className="s-10 color-gray">{item.city}</span></IonText>
                                            </IonCol>
                                            <IonCol size="2.5">
                                                <IonText className="s-12 wg-600">{item.net_score}</IonText>
                                            </IonCol>
                                            <IonCol size="2.5">
                                                <IonText className="s-12 wg-600">{item.seasonal_points}</IonText>
                                            </IonCol>
                                            {
                                                (item.medal_type == 1) ? (
                                                    <>
                                                    <IonCol size="2">
                                                    <img src={gold_medal} height="30" width="25"/>
                                                    </IonCol>
                                                    </>
                                                ) : (item.medal_type == 2) ? (
                                                    <>
                                                    <IonCol size="2">
                                                    <img src={silver_medal} height="30" width="25"/>
                                                    </IonCol>
                                                    </>
                                                ) : (item.medal_type == 3) ? (
                                                    <>
                                                    <IonCol size="2">
                                                    <img src={bronze_medal} height="30" width="25"/>
                                                    </IonCol>
                                                    </>
                                                ) : (
                                                    <IonCol size="2">
                                                    </IonCol>
                                                )
                                            }
                                        </IonRow>
                                    )
                                })
                            }
                        </IonGrid>
                    ) : leaderboards_tab_value == "Season" ? (
                        <IonGrid>
                            <IonRow className='ion-justify-content-center ion-text-center'>
                                <IonText className="s-14">
                                    <sup>*</sup>*Note: Total points are based on best 4 finishes. Prize pool will be distributed to top 30%.
                                </IonText>
                            </IonRow>
                            <IonRow className="ion-justify-content-center ion-text-center bb-line">
                                <IonCol size="2.5">
                                    <IonText className="s-11 wg-600">Pos</IonText>
                                </IonCol>
                                <IonCol size="4">
                                    <IonText className="s-11 wg-600">Player</IonText>
                                </IonCol>
                                <IonCol size="3">
                                    <IonText className="s-11 wg-600">Medals</IonText>
                                </IonCol>
                                <IonCol size="2.5">
                                    <IonText className="s-11 wg-600">Total<br/>Points</IonText>
                                </IonCol>
                            </IonRow>
                            {
                                season_leaderboard_users_data && season_leaderboard_users_data.map((item:any, index:number)=> {
                                    return(
                                        <IonRow className="ion-justify-content-center ion-text-center bb-line" key={index}>
                                            <IonCol size="2.5">
                                                <IonText className="s-12 wg-600">{index+1}</IonText>
                                            </IonCol>
                                            <IonCol size="4">
                                                <IonText className="s-12 wg-600">{item.user_name}</IonText>
                                            </IonCol>
                                            <IonCol size="3">
                                                <span className='gold-background'>{item.medals.gold}</span> <span className='silver-background'>{item.medals.silver}</span> <span className='bronze-background'>{item.medals.bronze}</span>
                                            </IonCol>
                                            <IonCol size="2.5">
                                                <IonText className="s-12 wg-600 color-blue">{item.seasonal_points}</IonText>
                                            </IonCol>
                                        </IonRow>
                                    )
                                })
                            }
                        </IonGrid>
                    ) : (
                        <></>
                    )
                }
                </>
                <IonModal isOpen={showLeaderboardPicker} className='my-custom-class2'
                initialBreakpoint={0.5} showBackdrop={true} backdropDismiss={true} 
                onDidDismiss={() => setShowLeaderboardPicker(false)} mode="md">
                    <IonContent>
                        <IonDatetime 
                            presentation="date"
                            size='cover'
                            onIonChange={(e) => {
                                setSelectedDate(format(startOfWeek(new Date(e.detail.value!), { weekStartsOn: 0 }), "yyyy-MM-dd"));
                                getLeaderBoards(leaderboards_tab_value, format(startOfWeek(new Date(e.detail.value!), { weekStartsOn: 0 }), "yyyy-MM-dd"))
                                setShowLeaderboardPicker(false);
                            }}
                        ></IonDatetime>
                    </IonContent>
                </IonModal>
                <IonModal isOpen={score_modal} className='my-custom-class' onDidDismiss={()=>setScoreModal(false)}>
                <>
                    <IonHeader>
                        <IonToolbar>
                            {/* <IonButtons slot="start">
                                <a onClick={()=> setScoreModal(false)}>
                                    <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                                </a>
                            </IonButtons> */}
                             <IonLabel slot="start" className="s-16 w-600 color-blue">
                                    {score_record.course_name}-{score_record.tee_name}
                                </IonLabel>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <ScoreTable options={score_record}/>
                        <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '45px'}}>
                            <IonFabButton
                            className="main-fab"
                            onClick={() => {
                                setScoreModal(false)
                            }}
                            >
                            <IonIcon icon={arrowBackOutline} />
                            </IonFabButton>
                        </IonFab>
                    </IonContent>
                </>
            </IonModal>
                <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '25px'}}>
                    <IonFabButton
                    className="main-fab"
                    onClick={() => {
                        window.location.href="/leagues/formats";
                    }}
                    >
                    <IonIcon icon={arrowBackOutline} />
                    </IonFabButton>
                </IonFab>
            </IonContent>
            <IonToolbar mode="ios" className="single-segment-toolbar" slot="bottom">
                <IonSegment
                onIonChange={(e) => {
                    console.log("tab1 Segment selected", e.detail.value);
                    setLeaderboardsTabValue(e.detail.value!);
                    if(e.detail.value == "Weekly") {
                        getLeaderBoards("Weekly", selected_date);
                    } else if(e.detail.value == "Season"){
                        getLeaderBoards("Season", selected_date);
                    }
                }}
                value={leaderboards_tab_value}
                mode="ios"
                className="new-segment"
                >
                    <IonSegmentButton value="Weekly" className="new-segment-button">
                        <IonLabel className="new-segment-button-label">Weekly</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="Season" className="new-segment-button">
                        <IonLabel className="new-segment-button-label">Season</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
            </IonToolbar>
        </IonPage>
    );
};
export default PlayPostWinLeaderboards;
