import { IonHeader, IonToolbar, IonButtons, IonIcon, IonTitle, IonContent, IonGrid, IonRow, IonText, IonCard, IonItem, IonAvatar, IonLabel } from '@ionic/react';
import { chevronBackOutline, alarmOutline } from 'ionicons/icons';
import { format, parse } from 'date-fns';
import { collection, getDocs, getFirestore, limit, orderBy, query, where } from 'firebase/firestore';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import firebase from '../../js/firebase';
import { useHistory } from 'react-router-dom';
const db = getFirestore(firebase);


const UserLogins: React.FC = () => {
    const [logins, setLogins] = useState<any>();

    const getLogins = async() => {
    const q = query(collection(db, "LOGIN_NOTIFICATIONS"), where('created_at', '>', 0), orderBy('created_at', 'desc'), limit(50));
    const querySnapshot = await getDocs(q);
    let current_date = format(new Date(), "MM/dd/yyyy");
    if(querySnapshot.docs && querySnapshot.docs.length > 0) {
            await Promise.all(querySnapshot.docs.map(async login => {
                var doc_item = login.data();
                doc_item.id = login.id;
                let date = format(doc_item.created_at, "MM/dd/yyyy");
                date = (date == current_date ? 'Today' : date)+'_'+new Date(date).getTime();
                doc_item.created_time = format(doc_item.created_at, 'HH:mm');
                doc_item.date = date;
                return doc_item;
            }))
            .then(results => {
                let grouped_data = _.groupBy(results, 'date');
                let finalDocs = new Array();
                Object.keys(grouped_data).forEach((key:any) => {
                    finalDocs.push({
                        date: key.split("_")[0],
                        date_time: key.split("_")[1],
                        notifications: grouped_data[key]
                    });
                });
                finalDocs = _.sortBy(finalDocs, e => e.date_time).reverse();
                setLogins(finalDocs);
            });
    }
}

  useEffect(() => {
      getLogins();
  }, [])
  // console.log("logins",logins)
  const history = useHistory();
    return (
        <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
                <a onClick={()=> history.goBack()}>
                  <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                  </a>
              </IonButtons>
            <IonTitle>
              User Logins
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
            <IonGrid>
            {
                logins && logins.map((login: any, index:number) => {
                    return (
                        <div key={index}>
                            <IonRow className="ion-justify-content-center ion-text-center">
                                <button className='not-button s-14 wg-600'>
                                    <IonText>{login.date}</IonText>
                                </button>
                            </IonRow>
                        {
                            login.notifications && login.notifications.map((notification:any, idx:number)=> {
                                return(
                                    <IonCard className="admin-user-logins-card" key={idx}>
                                        <IonItem lines="none" className="notification-item">
                                        <IonAvatar slot="start" style={{border:"1px solid gray",height:"45px",width:"45px",marginInline:"0px 13px"}}>
                                        <img src={ require(`../../img/golf-bag.png`).default}/>
                                        </IonAvatar>
                                        <IonLabel>
                                            <IonText className="s-16 wg-600">{notification.created_name}</IonText>
                                            <IonText className="fr s-14 gray"><IonIcon icon={alarmOutline} className="s-12 gray"/>{notification.created_time}</IonText>
                                            <br/>
                                            <IonText className="s-14 gray">Logged in at system time: {format(notification.created_at, 'MM/dd/yyyy HH:mm')}</IonText>
                                        </IonLabel>
                                        </IonItem>
                                    </IonCard>
                                )
                            })
                        }
                        </div>
                    )
                })
            }
            </IonGrid>
        </IonContent>
        </>
    );
};

export default UserLogins;