import {
    Chatbubbles,
    Calendar,
    Gift,
    Grid,
    Golf
  } from '@styled-icons/ionicons-outline';
  import {
    Golf as Golf_Solid,
    Chatbubbles as Chatbubbles_Solid,
    Calendar as Calendar_Solid,
    Gift as Gift_Solid,
    Grid as Grid_Solid
  } from '@styled-icons/ionicons-solid';
  import {
    useEffect,
    useState,
  } from "react";

  import {
    IonMenuToggle, IonTabBar, IonTabButton, IonLabel
  } from "@ionic/react";

interface Props{
    cur_page: {}
}
const BottomBar: React.FC<Props> = (props)  => {
    let curpage = props.cur_page;
    const [page, setCurPage] = useState<any>('');

    useEffect(() => {
        setCurPage(curpage);
      }, [curpage]);
    return(
        <>
            <IonTabBar id="bottom_bar" slot="bottom">
                <IonTabButton tab="lockerroom" href="/locker_room" className={ (page == "lockerroom") ? "color-blue"   : "color-red" } >
                    {(page == "lockerroom")?(
                        <Chatbubbles_Solid size="22" />

                      ):(
                        <Chatbubbles size="22" />
                      )
                    }

                    <IonLabel className="s-11">Locker room</IonLabel>
                </IonTabButton>
                <IonTabButton tab="leagues" href="/leagues/formats" className={ (page == "leagues") ? "color-blue " : "color-red" } >
                  {(page == "leagues")?(
                        <Golf_Solid size="22" />

                      ):(
                        <Golf size="22" />
                      )
                    }
                    <IonLabel className="s-11">Leagues</IonLabel>
                </IonTabButton>
                <IonTabButton tab="noticeboard" href="/noticeboard" className={ (page == "noticeboard") ? "color-blue" : "color-red" } >
                  {(page == "noticeboard")?(
                        <Calendar_Solid size="22" />

                      ):(
                    <Calendar size="22" />
                    )
                  }
                    <IonLabel className="s-11">Events</IonLabel>
                </IonTabButton>
                <IonTabButton tab="proshop" href="/pro_shop" className={ (page == "proshop") ? "color-blue" : "color-red" } >
                  {(page == "proshop")?(
                    <Gift_Solid size="22" />

                    ):(
                    <Gift size="22" />
                    )
                  }
                    <IonLabel className="s-11">Pro shop</IonLabel>
                </IonTabButton>
                <IonTabButton tab="more" className="color-red">
                    <IonMenuToggle autoHide={false} style={{ marginTop: '-3px' }}><Grid size="22" /></IonMenuToggle>
                    <IonMenuToggle autoHide={false}><IonLabel className="s-11">Lobby</IonLabel></IonMenuToggle>
                </IonTabButton>
            </IonTabBar>

      </>
    )
}
export default BottomBar;
