import { IonContent, IonPage, IonTitle, IonToolbar,  IonIcon,IonLabel, IonGrid, IonSegment, IonSegmentButton, IonFab, IonFabButton, IonAvatar, IonCard, IonItem, IonRow, IonText, isPlatform, IonCol, useIonAlert, useIonToast, IonList, IonModal, IonSearchbar, IonInput } from '@ionic/react';
import { alarmOutline, arrowBackOutline, chevronBackOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getFirestore, collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { getEntry, updateEntry } from '../../js/firebase_doc_functions';
import firebase from '../../js/firebase';
import * as utils from '../../js/utils';
import {
  CheckmarkDoneCircle,
  CloseCircle
} from '@styled-icons/ionicons-solid';
import { format } from 'date-fns';
import _, { ceil } from 'lodash';


const db = getFirestore(firebase);

const Notifications: React.FC = () => {
    const [notifications_tab_value,setNotificationsTabValue] = useState<any>('Matches');
    const history = useHistory();
    const [showTeesLoading, setShowTeesLoading] = useState(false);
    const [showTeesModal, setShowTeesModal] = useState(false);
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [showToast, hideToast] = useIonToast();
    const [presentAlert] = useIonAlert();
    const [available_tees, setAvailableTees] = useState<any>();
    const [playing_quota, setPlayingQuota] = useState<number>(0);
    const [select_tees,setSelectTees] = useState("")
    const [tees_list, setTeesList] = useState<any>([]);
    const [new_tees,setNewTees] = useState<any>(tees_list);
    const [invites_count, setInvitesCount] = useState<any>(0);
    const [teetimes_count, setTeetimesCount] = useState<any>(0);
    const [others_count, setOthersCount] = useState<any>(0);
    const [current_user, setCurrentUser] = useState<any>([]);
    const [invites, setInvites] = useState<any>([]);
    const [target_tees, setTargetTees] = useState<any>();
    const [teeset_rating_id, setTeesetRatingId] = useState<number>();
    const [teeset_rating_gender, setTeesetRatingGender] = useState<string>('');
    const [slope_rating, setSlopeRating] = useState<number>();
    const [course_rating, setCourseRating] = useState<number>();
    const [course_id, setCourseId] = useState<any>();
    const [par, setPar] = useState<number>();
    const [yards, setYards] = useState<number>();
    const [notification_id, setNotificationId] = useState<any>();
    const [invited_by, setInvitedBy] = useState<any>();
    const [invited_to, setInvitedTo] = useState<any>();
    const [relation_id, setRelationId] = useState<any>();
    const [holes, setHoles] = useState<any>();
    const [gender, setGender] = useState<string>('');
    const [edit_hole_details, setEditHoleDetails] = useState<any>([]);
    const [teeset_rating_name, setTeesetRatingName] = useState<string>('');
    const [tee_time_notifications, setTeetimeNotifications] = useState<any>([]);
    const [other_notifications, setOtherNotifications] = useState<any>([]);
    const inputRef = useRef<any>(null);

    const getInvites = async(user_id:string) => {
      const q = query(collection(db, "CHECKIN_NOTIFICATIONS"), where('created_at', '>', 0), where('user_id', '==', user_id));
      const querySnapshot = await getDocs(q);
      let current_date = format(new Date(), "MM/dd/yyyy");
      await Promise.all(querySnapshot.docs.map(async notification => {
          var doc_item = notification.data();
          doc_item.id = notification.id;
          let courseStr = (doc_item.source.name).split("-");
          if(courseStr[0] != undefined || courseStr[0] != null ) doc_item.course_name = courseStr[0];
          if(courseStr[1] != undefined || courseStr[1] != null ) doc_item.course_sub_name = courseStr[1];
          doc_item.tee = doc_item.source.tee_name;
          doc_item.match_date = doc_item.source.date;
          doc_item.tee_time = doc_item.source.time;
          doc_item.tee_type = doc_item.source.tee_type;
          let date = format(doc_item.created_at, "MM/dd/yyyy");
          date = (date == current_date ? 'Today' : date)+'_'+new Date(date).getTime();
          doc_item.created_time = format(doc_item.created_at, 'HH:mm');
          doc_item.date = date;
          if(doc_item.type === "NSC") {
            doc_item.hero_text = "Net Score Challenge"
          }
          if(doc_item.type === "TT") {
            doc_item.hero_text = doc_item.source.tour_name;
          }
          return doc_item;
      }))
      .then(results => {
        let grouped_data = _.groupBy(results, 'date');
        let finalDocs = new Array();
        Object.keys(grouped_data).forEach((key:any) => {
          finalDocs.push({
              date: key.split("_")[0],
              date_time: key.split("_")[1],
              notifications: grouped_data[key]
          });
        });
        finalDocs = _.sortBy(finalDocs, e => e.date_time).reverse();
        setInvites(finalDocs);
      });
    }
    const getTeeDetails = async(courseid:number, holes:number, tee:string)=> {
      let currentTeeValues = tee.split("~");
      setTeesetRatingId(holes == 18 ? parseInt(currentTeeValues[2]) : parseInt(currentTeeValues[3]));
      setTeesetRatingName(currentTeeValues[0]);
      setTeesetRatingGender(currentTeeValues[1]);
      let teeSetValues = holes == 18 ? (currentTeeValues[1].substring(1, currentTeeValues[1].length-1)).split("/") : (currentTeeValues[2].substring(1, currentTeeValues[2].length-1)).split("/");
      setSlopeRating(parseFloat(teeSetValues[1]));
      setCourseRating(parseInt(teeSetValues[0]));
      setPar(parseInt(teeSetValues[2]));
      setGender(current_user.gender);
      let tees = await utils.getCourseDetails(courseid) || [];
      let hc = 36 - current_user.quota;
      let phc = hc * parseFloat(teeSetValues[1]) / 113.0;
      phc = phc + (parseFloat(teeSetValues[0]) - parseInt(teeSetValues[2]));
      let pq = 36 - phc;
      if(pq < 9)
          pq = 9;
      
      console.log("2~1:1 Matches Playing Quota: "+pq);
      if(holes == 18) {
          setPlayingQuota(ceil(pq));
          setTargetTees(current_user.preferred_tee);
      } else {
          setPlayingQuota(ceil(pq/2));
          setTargetTees(current_user.preferred_tee)
      }
      tees && tees.forEach((tee:any)=>{
          if(holes == 18){
              let yardsData = tee.Holes.map((hole:any)=> {return hole.Length}),
              yards = yardsData.reduce((a:number, b:number) => a + b, 0);
              setEditHoleDetails(tee.Holes);
              setYards(yards);
          } else if(holes == 9) {
              let rating = tee.Ratings.filter((rating:any)=> rating.RatingType == 'Total');
              let nineHoleRatings = tee.Ratings.filter((rating:any)=> rating.RatingType != 'Total');
              if(tee.HolesNumber == 9){
                  let yardsData = tee.Holes.map((hole:any)=> {return hole.Length}),
                  yards = yardsData.reduce((a:number, b:number) => a + b, 0);
                  setEditHoleDetails(tee.Holes);
                  setYards(yards);
              } else {
                for (rating of nineHoleRatings) {
                  var front9Holes = tee.Holes.filter((hole:any)=> hole.Number <= 9);
                  var back9Holes = tee.Holes.filter((hole:any)=> hole.Number > 9);
                  if(rating.RatingType == 'Front'){
                      let yardsData = front9Holes.map((hole:any)=> {return hole.Length}),
                      yards = yardsData.reduce((a:number, b:number) => a + b, 0);
                      setEditHoleDetails(tee.Holes);
                      setYards(yards);
                  }
                  if(rating.RatingType == 'Back'){
                      let yardsData = back9Holes.map((hole:any)=> {return hole.Length}),
                      yards = yardsData.reduce((a:number, b:number) => a + b, 0);
                      setEditHoleDetails(tee.Holes);
                      setYards(yards);
                  }
                }
              }
          } 
      });
    }
    
    const getMatchNotifications = async(user_id:string) => {
      const q = query(collection(db, "CHECKIN_NOTIFICATIONS"), where('created_at', '>', 0), where('user_id', '==', user_id));
      const querySnapshot = await getDocs(q);
      let current_date = format(new Date(), "MM/dd/yyyy");
      await Promise.all(querySnapshot.docs.map(async notification => {
          var doc_item = notification.data();
          doc_item.id = notification.id;
          let courseStr = (doc_item.source.course_name).split("-");
          if(courseStr[0] != undefined || courseStr[0] != null ) doc_item.course_name = courseStr[0];
          if(courseStr[1] != undefined || courseStr[1] != null ) doc_item.course_sub_name = courseStr[1];
          doc_item.tee = doc_item.source.tee_name;
          doc_item.date = doc_item.source.date;
          doc_item.tee_time = doc_item.source.time;
          let date = format(doc_item.created_at, "MM/dd/yyyy");
          date = (date == current_date ? 'Today' : date)+'_'+new Date(date).getTime();
          doc_item.created_time = format(doc_item.created_at, 'HH:mm');
          doc_item.date = date;
          if(doc_item.type === "NSC") {
            doc_item.hero_text = "Net Score Challenge"
          }
          if(doc_item.type === "TT") {
            doc_item.hero_text = doc_item.source.tour_name;
          }
          return doc_item;
      }))
      .then(results => {
        console.log(results);
        let grouped_data = _.groupBy(results, 'date');
        let finalDocs = new Array();
        Object.keys(grouped_data).forEach((key:any) => {
          finalDocs.push({
              date: key.split("_")[0],
              date_time: key.split("_")[1],
              notifications: grouped_data[key]
          });
        });
        finalDocs = _.sortBy(finalDocs, e => e.date_time).reverse();
        setInvites(finalDocs);
      });
    }
    const getOtherNotifications = async(user_id:string)=> {
      const q1 = query(collection(db, "PACK_NOTIFICATIONS"), where('created_at', '>', 0), where('user_id', '==', user_id));
      const querySnapshot1 = await getDocs(q1);
      const q2 = query(collection(db, "NOTIFICATIONS"), where('created_at', '>', 0), where('user_id', '==', user_id));
      const querySnapshot2 = await getDocs(q2);
      if(querySnapshot1.docs && querySnapshot1.docs.length>0) {
        //let notificationRecords = [...querySnapshot1.docs, ...querySnapshot2.docs];
        let current_date = format(new Date(), "MM/dd/yyyy");
        await Promise.all(querySnapshot1.docs.map(async notification => {
            var doc_item = notification.data();
            doc_item.id = notification.id;
            let date = format(doc_item.created_at, "MM/dd/yyyy");
            date = (date == current_date ? 'Today' : date)+'_'+new Date(date).getTime();
            doc_item.created_time = format(doc_item.created_at, 'HH:mm');
            doc_item.date = date;
            return doc_item;
        }))
        .then(results => {
          console.log("results", results);
          let grouped_data = _.groupBy(results, 'date');
          let finalDocs = new Array();
          Object.keys(grouped_data).forEach((key:any) => {
            finalDocs.push({
                date: key.split("_")[0],
                date_time: key.split("_")[1],
                notifications: grouped_data[key]
            });
          });
          finalDocs = _.sortBy(finalDocs, e => e.date_time).reverse();
          setOtherNotifications(finalDocs);
        });
      }
    }
    const getNotificationCounts = async(user_id:string) => {
      const q = query(collection(db, "CHECKIN_NOTIFICATIONS"), where('created_at', '>', 0), where('user_id', '==', user_id));
      const querySnapshot = await getDocs(q);
      const q1 = query(collection(db, "PACK_NOTIFICATIONS"), where('created_at', '>', 0), where('user_id', '==', user_id));
      const querySnapshot1 = await getDocs(q1);
      const q2 = query(collection(db, "NOTIFICATIONS"), where('created_at', '>', 0), where('user_id', '==', user_id));
      const querySnapshot2 = await getDocs(q2);
      if(querySnapshot.docs.length > 0) {
        let count = (querySnapshot.docs.filter((x:any)=> x.data().is_read == false)).length;
        setInvitesCount(count);
      }
      if((querySnapshot1.docs && querySnapshot1.docs.length>0) || (querySnapshot2.docs && querySnapshot2.docs.length>0)) {
        let notificationRecords = [...querySnapshot1.docs, ...querySnapshot2.docs];
        let count = (notificationRecords.filter((x:any)=> x.data().is_read == false)).length;
        setOthersCount(count);
      }
    }
    const getTaoCompetitionDetails = async(notification_id:string, relation_id:string, player_one:string, player_two:string)=> {
      setShowTeesLoading(true);
      setNotificationId(notification_id);
      setInvitedBy(player_one);
      setInvitedTo(player_two);
      setRelationId(relation_id);
      const q1 = query(collection(db, "TAO_COMPETITIONS"), where('created_at', '>', 0), where('user_id', '==', player_two), where('competition_relation_id', '==', relation_id));
      const querySnapshot1 = await getDocs(q1);
      if(current_user.chips<querySnapshot1.docs[0].data().price) {
        showToast("Your chips balance is lower than those required to participate in the match.", 2000);
      } else {
        setCourseId(querySnapshot1.docs[0].data().course_id);
        setHoles(querySnapshot1.docs[0].data().holes);
        let tees = await utils.getCourseDetails(querySnapshot1.docs[0].data().course_id) || [];
        var tee_sets = new Array();
        let finalTees = current_user.gender === "Male" ? tees.filter((x:any)=> x.Gender === "Male") : tees.filter((x:any)=> x.Gender === "Female");
        let sortedTees =  _.sortBy(finalTees, (e: any) => e.TotalMeters).reverse();
        setAvailableTees(sortedTees);
        tees && tees.forEach((tee:any)=>{
            if(querySnapshot1.docs[0].data().holes == 18 && current_user.gender === tee.Gender){
                let rating = tee.Ratings.filter((rating:any)=> rating.RatingType == 'Total');
                let pars = tee.Holes.map((hole:any)=> {return hole.Par}),
                par = pars.reduce((a:number, b:number) => a + b, 0);
                if(tee.HolesNumber == 18){
                    tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+'('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')', 
                    'teeValue': tee.TeeSetRatingName+'~'+'('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                  });
                }
            } else if(querySnapshot1.docs[0].data().holes == 9) {
                let rating = tee.Ratings.filter((rating:any)=> rating.RatingType == 'Total');
                let nineHoleRatings = tee.Ratings.filter((rating:any)=> rating.RatingType != 'Total');
                let pars = tee.Holes.map((hole:any)=> {return hole.Par});
                if(tee.HolesNumber == 9){
                  let pars = tee.Holes.map((hole:any)=> {return hole.Par}),
                  par = pars.reduce((a:number, b:number) => a + b, 0);
                  tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+rating[0].RatingType+'~('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')', 
                    'teeValue': tee.TeeSetRatingName+'~'+rating[0].RatingType+'~('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                  });
                  
                } else {
                  for (rating of nineHoleRatings) {
                    var front9Holes = tee.Holes.filter((hole:any)=> hole.Number <= 9);
                    var back9Holes = tee.Holes.filter((hole:any)=> hole.Number > 9);
                    if(rating.RatingType == 'Front'){
                      let pars = front9Holes.map((hole:any)=> {return hole.Par}),
                      par = pars.reduce((a:number, b:number) => a + b, 0);
                      tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')', 
                        'teeValue': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                      });
                      
                    }
                    if(rating.RatingType == 'Back'){
                      let pars = back9Holes.map((hole:any)=> {return hole.Par}),
                      par = pars.reduce((a:number, b:number) => a + b, 0);
                      tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')', 
                        'teeValue': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                      });
                      
                    }
                  }
                }
            } 
        });
        setTeesList(tee_sets);
        setNewTees(tee_sets);
        setShowTeesLoading(false);
        setShowTeesModal(true);
      }
    }
    const getTtCompetitionDetails = async(notification_id:string, relation_id:string, player_one:string, player_two:string)=> {
      setShowTeesLoading(true);
      setNotificationId(notification_id);
      setInvitedBy(player_one);
      setInvitedTo(player_two);
      setRelationId(relation_id);
      console.log("fgdgdfgdf", player_two);
      console.log("dsdfsdsfsdf", relation_id);
      const q1 = query(collection(db, "TT_COMPETITIONS"), where('created_at', '>', 0), where('user_id', '==', player_two), where('competition_relation_id', '==', relation_id));
      const querySnapshot1 = await getDocs(q1);
      console.log("dsfsfdsf", querySnapshot1)
      if(current_user.chips<querySnapshot1.docs[0].data().price) {
        showToast("Your chips balance is lower than those required to participate in the match.", 2000);
      } else {
        setCourseId(querySnapshot1.docs[0].data().course_id);
        setHoles(querySnapshot1.docs[0].data().holes);
        let tees = await utils.getCourseDetails(querySnapshot1.docs[0].data().course_id) || [];
        var tee_sets = new Array();
        let finalTees = current_user.gender === "Male" ? tees.filter((x:any)=> x.Gender === "Male") : tees.filter((x:any)=> x.Gender === "Female");
        let sortedTees =  _.sortBy(finalTees, (e: any) => e.TotalMeters).reverse();
        setAvailableTees(sortedTees);
        console.log(tees);
        tees && tees.forEach((tee:any)=>{
          if(tee.Gender.toLowerCase() === current_user.gender.toLowerCase()) {
            if(querySnapshot1.docs[0].data().holes == 18){
                let rating = tee.Ratings.filter((rating:any)=> rating.RatingType == 'Total');
                let pars = tee.Holes.map((hole:any)=> {return hole.Par}),
                par = pars.reduce((a:number, b:number) => a + b, 0);
                if(tee.HolesNumber == 18){
                    tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+'('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')', 
                    'teeValue': tee.TeeSetRatingName+'~'+'('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                  });
                }
            } else if(querySnapshot1.docs[0].data().holes == 9) {
                let rating = tee.Ratings.filter((rating:any)=> rating.RatingType == 'Total');
                let nineHoleRatings = tee.Ratings.filter((rating:any)=> rating.RatingType != 'Total');
                let pars = tee.Holes.map((hole:any)=> {return hole.Par});
                if(tee.HolesNumber == 9){
                  let pars = tee.Holes.map((hole:any)=> {return hole.Par}),
                  par = pars.reduce((a:number, b:number) => a + b, 0);
                  tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+rating[0].RatingType+'~('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')', 
                    'teeValue': tee.TeeSetRatingName+'~'+rating[0].RatingType+'~('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                  });
                  
                } else {
                  for (rating of nineHoleRatings) {
                    var front9Holes = tee.Holes.filter((hole:any)=> hole.Number <= 9);
                    var back9Holes = tee.Holes.filter((hole:any)=> hole.Number > 9);
                    if(rating.RatingType == 'Front'){
                      let pars = front9Holes.map((hole:any)=> {return hole.Par}),
                      par = pars.reduce((a:number, b:number) => a + b, 0);
                      tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')', 
                        'teeValue': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                      });
                      
                    }
                    if(rating.RatingType == 'Back'){
                      let pars = back9Holes.map((hole:any)=> {return hole.Par}),
                      par = pars.reduce((a:number, b:number) => a + b, 0);
                      tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')', 
                        'teeValue': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                      });
                      
                    }
                  }
                }
            } 
          }
        });
        console.log(tee_sets);
        setTeesList(tee_sets);
        setNewTees(tee_sets);
        setShowTeesLoading(false);
        setShowTeesModal(true);
      }
    }
    const acceptNsc = async(notification_id:string, relation_id:string, player_one:string, player_two:string)=> {
      const q1 = query(collection(db, "TAO_COMPETITIONS"), where('created_at', '>', 0), where('user_id', '==', player_one), where('competition_relation_id', '==', relation_id));
      const querySnapshot1 = await getDocs(q1);
      const q2 = query(collection(db, "TAO_COMPETITIONS"), where('created_at', '>', 0), where('user_id', '==', player_two), where('competition_relation_id', '==', relation_id));
      const querySnapshot2 = await getDocs(q2);
      if(current_user.chips<querySnapshot2.docs[0].data().price) {
        showToast("Your chips balance is lower than those required to participate in the match.", 2000);
      } else {
        if(querySnapshot1.docs && querySnapshot1.docs.length > 0 && querySnapshot2.docs && querySnapshot2.docs.length > 0) {
          let doc_item1 = querySnapshot1.docs[0].data();
          await updateEntry("TAO_COMPETITIONS", querySnapshot1.docs[0].id, {partner_accepted: true, scheduled: true});
          await updateEntry("CHECKIN_NOTIFICATIONS", notification_id, {is_read:true, match_status: 'Accepted'});
          let doc_item2 = querySnapshot2.docs[0].data();
          await updateEntry("TAO_COMPETITIONS", querySnapshot2.docs[0].id, {partner_accepted: true, scheduled: true, quota: holes == 18 ? playing_quota : playing_quota/2,tee: teeset_rating_name,teeset_id: teeset_rating_id});
          await updateEntry("SCORE_RECORDS", doc_item2.score_id, {partner_accepted: true, scheduled: true, quota: holes == 18 ? playing_quota : playing_quota/2,tee: teeset_rating_name,teeset_id: teeset_rating_id});
          let userData = current_user;
          userData.chips = current_user.chips-querySnapshot2.docs[0].data().price;
          localStorage.setItem("firebase_user", JSON.stringify(userData));
          presentAlert({
            header: 'Your match is added to the schedule',
            message: 'Please visit schedule page to post score',
            buttons: [
              'Cancel',
              { 
                text: 'Ok',
                handler: (d) => {
                   window.location.href="/netscore_challenge_checkins"
                }
              },
            ],
            onDidDismiss: (e) => console.log('did dismiss'),
          })
        }
      }
      
    }
    const declineNsc = async(notification_id:string, relation_id:string, player_one:string, player_two:string)=> {
      const q1 = query(collection(db, "TAO_COMPETITIONS"), where('created_at', '>', 0), where('user_id', '==', player_one), where('competition_relation_id', '==', relation_id));
      const querySnapshot1 = await getDocs(q1);
      const q2 = query(collection(db, "TAO_COMPETITIONS"), where('created_at', '>', 0), where('user_id', '==', player_two), where('competition_relation_id', '==', relation_id));
      const querySnapshot2 = await getDocs(q2);
      if(querySnapshot1.docs && querySnapshot1.docs.length > 0 && querySnapshot2.docs && querySnapshot2.docs.length > 0) {
        let doc_item1 = querySnapshot1.docs[0].data();
        await updateEntry("TAO_COMPETITIONS", querySnapshot1.docs[0].id, {partner_accepted: true, partner_rejected:true});
        await updateEntry("CHECKIN_NOTIFICATIONS", notification_id, {is_read:true,  match_status: 'Declined'});
        let doc_item2 = querySnapshot1.docs[0].data();
        await updateEntry("TAO_COMPETITIONS", querySnapshot2.docs[0].id, {partner_accepted: true, partner_rejected:true});
      }
      getInvites(current_user.user_id);
    }
    const acceptTt = async(notification_id:string, relation_id:string, player_one:string, player_two:string)=> {
      console.log("fddfgdfggdf", playing_quota);
      const q1 = query(collection(db, "TT_COMPETITIONS"), where('created_at', '>', 0), where('user_id', '==', player_one), where('competition_relation_id', '==', relation_id));
      const querySnapshot1 = await getDocs(q1);
      const q2 = query(collection(db, "TT_COMPETITIONS"), where('created_at', '>', 0), where('user_id', '==', player_two), where('competition_relation_id', '==', relation_id));
      const querySnapshot2 = await getDocs(q2);
      if(current_user.chips<querySnapshot2.docs[0].data().price) {
        showToast("Your chips balance is lower than those required to participate in the match.", 2000);
      } else {
        if(querySnapshot1.docs && querySnapshot1.docs.length > 0 && querySnapshot2.docs && querySnapshot2.docs.length > 0) {
          let doc_item1 = querySnapshot1.docs[0].data();
          await updateEntry("TT_COMPETITIONS", querySnapshot1.docs[0].id, {partner_accepted: true, scheduled: true});
          await updateEntry("CHECKIN_NOTIFICATIONS", notification_id, {is_read:true, match_status: 'Accepted'});
          let doc_item2 = querySnapshot1.docs[0].data();
          await updateEntry("TT_COMPETITIONS", querySnapshot2.docs[0].id, {partner_accepted: true, scheduled: true, quota: playing_quota});
          let userData = current_user;
          userData.chips = current_user.chips-querySnapshot2.docs[0].data().price;
          localStorage.setItem("firebase_user", JSON.stringify(userData));
          presentAlert({
            header: 'Your match is added to the schedule',
            message: 'Please visit schedule page to post score',
            buttons: [
              'Cancel',
              { 
                text: 'Ok',
                handler: (d) => {
                   window.location.href="/leagues/formats"
                }
              },
            ],
            onDidDismiss: (e) => console.log('did dismiss'),
          })
        }
      }
      
    }
    const declineTt = async(notification_id:string, relation_id:string, player_one:string, player_two:string)=> {
      const q1 = query(collection(db, "TT_COMPETITIONS"), where('created_at', '>', 0), where('user_id', '==', player_one), where('competition_relation_id', '==', relation_id));
      const querySnapshot1 = await getDocs(q1);
      const q2 = query(collection(db, "TT_COMPETITIONS"), where('created_at', '>', 0), where('user_id', '==', player_two), where('competition_relation_id', '==', relation_id));
      const querySnapshot2 = await getDocs(q2);
      if(querySnapshot1.docs && querySnapshot1.docs.length > 0 && querySnapshot2.docs && querySnapshot2.docs.length > 0) {
        let doc_item1 = querySnapshot1.docs[0].data();
        await updateEntry("TT_COMPETITIONS", querySnapshot1.docs[0].id, {partner_accepted: true, partner_rejected:true});
        await updateEntry("CHECKIN_NOTIFICATIONS", notification_id, {is_read:true,  match_status: 'Declined'});
        let doc_item2 = querySnapshot1.docs[0].data();
        await updateEntry("TT_COMPETITIONS", querySnapshot2.docs[0].id, {partner_accepted: true, partner_rejected:true});
      }
      getInvites(current_user.user_id);
    }
    const acceptInvite = async(id:string, user_id:string, user_name:string, pack_id:string)=> {
      const q1 = query(collection(db, "PACKS"), where('created_at', '>', 0), where('pack_id', '==', pack_id));
      const querySnapshot1 = await getDocs(q1);
      const q2 = query(collection(db, "USER_PROFILE"), where('user_id', '==', user_id));
      const querySnapshot2 = await getDocs(q2);
      if(querySnapshot1.docs && querySnapshot1.docs.length > 0) {
        let packData = querySnapshot1.docs[0].data();
        packData.players.push({user_id: user_id, user_name:user_name, is_pack_lead: false});
        let userPacks = querySnapshot2.docs[0].data().packs;
        userPacks.push({pack_id: pack_id, pack_name: packData.pack_name, min_quota: 0, enable_min_quota: false});
        await updateEntry("PACKS", querySnapshot1.docs[0].id, {players:packData.players});
        await updateEntry("PACK_NOTIFICATIONS", id, {is_read:true});
        await updateEntry("USER_PROFILE", querySnapshot2.docs[0].id, {packs: userPacks});
        getOtherNotifications(current_user.user_id);
      }
    }
    const declineInvite = async(id:string, user_id:string)=> {
      await updateEntry("PACK_NOTIFICATIONS", id, {is_read:true});
    }
    useEffect(() => {
      let user = localStorage.getItem('firebase_user');
      if(user != null) {
        getNotificationCounts(JSON.parse(user).user_id);
        setCurrentUser(JSON.parse(user));
        getInvites(JSON.parse(user).user_id);
      }
    }, []);
    return (
      <IonPage>
        <IonToolbar className={isPlatform('ios') ? 'ios-toolbar-top': 'toolbar-top' }>
          <IonToolbar>
            <IonLabel slot="start" className="s-18 w-800 color-blue">Notifications</IonLabel>
          </IonToolbar>
        </IonToolbar>
        <IonToolbar mode="ios" className="segment-toolbar" slot="top">
          <IonSegment onIonChange={e => {
              setNotificationsTabValue(e.detail.value)
              if(e.detail.value == "Matches"){
                getInvites(current_user.user_id);
              } else if(e.detail.value == "Tee Times"){
                getMatchNotifications(current_user.user_id)
              } else if(e.detail.value == "Others"){
                getOtherNotifications(current_user.user_id);
              }
            }} value={notifications_tab_value} mode="ios" className="new-segment">
            
            <IonSegmentButton value="Matches" className="new-segment-button">
              <IonLabel className="new-segment-button-label">
                Matches
                {
                  invites_count > 0 ? (
                    <span className='new-notification'>{invites_count}</span>
                  ) : (
                    <></>
                  )
                }
              </IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="Tee Times" className="new-segment-button">
              <IonLabel className="new-segment-button-label">
                Tee Times
                {
                  teetimes_count > 0 ? (
                    <span className='new-notification'>{teetimes_count}</span>
                  ) : (
                    <></>
                  )
                }
              </IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="Others" className="new-segment-button">
              <IonLabel className="new-segment-button-label">
                Other
                {
                  others_count > 0 ? (
                    <span className='new-notification'>{others_count}</span>
                  ) : (
                    <></>
                  )
                }
              </IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
        <IonContent>
          {
            (notifications_tab_value=="App")?(
              <IonGrid>
                
              </IonGrid>
            ):(notifications_tab_value=="Matches")?(
              <IonGrid>
                {
                  invites && invites.map((invite: any, index:number) => {
                      return (
                          <div key={index}>
                            <IonRow className="ion-justify-content-center ion-text-center">
                              <button className='not-button s-14 wg-600'>
                                <IonText>{invite.date}</IonText>
                              </button>
                            </IonRow>
                          {
                            invite.notifications && invite.notifications.map((notification:any, idx:number)=> {
                              return(
                                <IonCard className="matches-card" key={idx}>
                                  <IonRow className='mt-10 mb-10'>
                                    <IonCol size='0.5'>
                                      <IonAvatar slot="start" style={{border:"1px solid gray",height:"45px",width:"45px",marginInline:"0px 13px"}}>
                                        <img src={ require(`../../img/golf-bag.png`).default}/>
                                      </IonAvatar>
                                    </IonCol>
                                    <IonCol size="11.5">
                                      <IonRow className="">
                                        <IonText className="s-16 wg-600">{notification.hero_text}</IonText>
                                      </IonRow>
                                      <IonRow className="">
                                      <IonText className="fr s-14 gray">{notification.created_name} has setup a match with you</IonText>
                                      </IonRow>
                                      <IonRow className="">
                                      <IonText className="fr s-12 gray">on {notification.match_date} at {notification.tee_time}</IonText>
                                      </IonRow>
                                      <IonRow>
                                        <IonText className="fr s-12 gray">{notification.course_name} - {notification.tee} {notification.tee_type}</IonText>
                                      </IonRow>
                                      {
                                        (notification.type === "NSC" || notification.type === "TT") && notification.is_read == false ? (
                                          <IonRow className="display-flex mt-10">
                                            {
                                              select_tees.length > 0 ? (
                                                <>
                                                  {
                                                    (notification.hero_text == "Net Score Challenge") ? (
                                                      <button className='button-accept white s-15 bg-blue' onClick={()=> {
                                                        acceptNsc(notification.id, notification.source.competition_relation_id, notification.created_by, notification.user_id)
                                                      }}>                            
                                                        <IonText className="wg-bold">Accept</IonText>
                                                      </button>
                                                    ) : (
                                                      <button className='button-accept white s-15 bg-blue' onClick={()=> {
                                                        acceptTt(notification.id, notification.source.competition_relation_id, notification.created_by, notification.user_id)
                                                      }}>                            
                                                        <IonText className="wg-bold">Accept</IonText>
                                                      </button>
                                                    )
                                                  }
                                                </>
                                              ) : (
                                                <>
                                                  {
                                                    (notification.hero_text == "Net Score Challenge") ? (
                                                      <button className='button-select-tees'onClick={()=> {
                                                        getTaoCompetitionDetails(notification.id, notification.source.competition_relation_id, notification.created_by, notification.user_id)
                                                      }}>                            
                                                        <IonText className="wg-bold">Select Tees to Accept</IonText>
                                                      </button>
                                                    ) : (
                                                      <button className='button-select-tees' onClick={()=> {
                                                        getTtCompetitionDetails(notification.id, notification.source.competition_relation_id, notification.created_by, notification.user_id)
                                                      }}>                            
                                                        <IonText className="wg-bold">Select Tees to Accept</IonText>
                                                      </button>
                                                    )
                                                  }
                                                </>
                                              )
                                            }
                                            {
                                              (notification.hero_text == "Net Score Challenge") ? (
                                                <button className='button-decline' onClick={()=> {
                                                  declineNsc(notification.id, notification.source.competition_relation_id, notification.created_by, notification.user_id)
                                                }}>                            
                                                  <IonText className="wg-bold">Decline</IonText>
                                                </button>
                                              ) : (
                                                <button className='button-decline' onClick={()=> {
                                                  declineTt(notification.id, notification.source.competition_relation_id, notification.created_by, notification.user_id)
                                                }}>                            
                                                  <IonText className="wg-bold">Decline</IonText>
                                                </button>
                                              )
                                            }
                                          </IonRow>
                                        ) : (notification.type === "NSC" || notification.type === "TT") && notification.is_read == true ? (
                                          <>
                                            {
                                              notification.match_status === "Accepted" ? (
                                                <IonRow className="">
                                                  <IonText className="fr s-14 gray"><CheckmarkDoneCircle size="16" />{notification.match_status}</IonText>
                                                </IonRow>
                                              ) : (
                                                <IonRow className="">
                                                  <IonText className="fr s-14 gray"><CloseCircle size="16" />{notification.match_status}</IonText>
                                                </IonRow>
                                              )
                                            }
                                          </>
                                        ) : (
                                          <></>
                                        )
                                      }
                                    </IonCol>
                                  </IonRow>
                                </IonCard>
                              )
                            })
                          }
                          </div>
                      )
                  })
                }
              </IonGrid>
            ):(notifications_tab_value=="Tee Times")?(
              <IonGrid>
                  {/* <IonRow className="ion-justify-content-center ion-text-center">
                      <button className='not-button s-14 wg-600'>
                          <IonText>1/30/2022</IonText>
                      </button>

                  </IonRow>
                  <IonCard className="search-card">
                  <IonItem lines="none" className="notification-item">
                    <IonAvatar slot="start" style={{border:"1px solid gray",height:"45px",width:"45px",marginInline:"0px 13px"}}>
                    <img src={ require(`../../img/golf-bag.png`).default}/>
                    </IonAvatar>
                    <IonLabel>
                      <IonText className="s-14 wg-600">Overland Park Golf Club</IonText>
                      <IonText className="fr gray"><IonIcon icon={alarmOutline} className="s-15 gray"/>23:25</IonText>
                      <br/>
                      <IonText className="s-13 wg-600">Kantha Kanchiraju</IonText>
                      <IonText className="s-12 pl-5 gray">shared a Tee Time: 13:12 with you</IonText>
                      <br/>
                    </IonLabel>
                  </IonItem>

                  </IonCard> */}
              </IonGrid>
            ):(notifications_tab_value=="Others")?(
              <IonGrid>
                {
                  other_notifications && other_notifications.map((item: any, index:number) => {
                      return (
                          <div key={index}>
                            <IonRow className="ion-justify-content-center ion-text-center">
                              <button className='not-button s-14 wg-600'>
                                <IonText>{item.date}</IonText>
                              </button>
                            </IonRow>
                          {
                            item.notifications && item.notifications.map((notification:any, idx:number)=> {
                              return(
                                <IonCard className="matches-card" key={idx}>
                                  <IonRow className='mt-10 mb-10'>
                                    <IonCol size='0.5'>
                                      <IonAvatar slot="start" style={{border:"1px solid gray",height:"45px",width:"45px",marginInline:"0px 13px"}}>
                                        <img src={ require(`../../img/golf-bag.png`).default}/>
                                      </IonAvatar>
                                    </IonCol>
                                    <IonCol size="11.5">
                                      {
                                        (notification.type === "JOIN_REQUEST") && notification.is_read == false ? (
                                          <>
                                          <IonRow className="">
                                            <IonText className="s-16 wg-600">Pack Join Request</IonText>
                                          </IonRow>
                                          <IonRow className="">
                                          <IonText className="fr s-14 gray">{notification.created_name} has requested to join your pack {notification.pack_name}</IonText>
                                          </IonRow>
                                          <IonRow className="">
                                          <IonText className="fr s-12 gray">on {format(notification.created_at, "MM/dd/yyyy")}</IonText>
                                          </IonRow>
                                          <IonRow>
                                            <IonText className="fr s-12 gray">Message: {notification.description}</IonText>
                                          </IonRow>
                                          <IonRow className="display-flex mt-10">
                                              <button className='button-accept white s-15 bg-blue' onClick={()=> {
                                                acceptInvite(notification.id, notification.created_by, notification.created_name, notification.pack_id)
                                              }}>                            
                                                <IonText className="wg-bold">Accept</IonText>
                                              </button>
                                                  
                                            
                                              <button className='button-decline white s-15 bg-red' onClick={()=> {
                                                declineInvite(notification.id, notification.created_by)
                                              }}>                            
                                                <IonText className="wg-bold">Decline</IonText>
                                              </button>
                                          </IonRow>
                                          </>
                                        ) : (notification.type === "JOIN_REQUEST") && notification.is_read == true ? (
                                          <div style={{opacity:'0.7'}}>
                                          <IonRow className="">
                                            <IonText className="s-16 wg-600">Pack Join Request</IonText>
                                          </IonRow>
                                          <IonRow className="">
                                          <IonText className="fr s-14 gray">{notification.created_name} has requested to join your pack {notification.pack_name}</IonText>
                                          </IonRow>
                                          <IonRow className="">
                                          <IonText className="fr s-12 gray">on {format(notification.created_at, "MM/dd/yyyy")}</IonText>
                                          </IonRow>
                                          <IonRow>
                                            <IonText className="fr s-12 gray">Message: {notification.description}</IonText>
                                          </IonRow>
                                          </div>
                                        ) : (
                                          <></>
                                        )
                                      }
                                    </IonCol>
                                  </IonRow>
                                </IonCard>
                              )
                            })
                          }
                          </div>
                      )
                  })
                }
              </IonGrid>
            ):(
              <></>
            ) 
          }
          <IonModal isOpen={showTeesModal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true} 
              onDidDismiss={() => setShowTeesModal(false)}
              initialBreakpoint={0.7} onDidPresent={()=> {setTimeout(() => inputRef.current.setFocus(), 100)}} mode="md">
                <>
                  <IonSearchbar ref={(ref) => inputRef.current = ref} onIonChange={(e:any) => {
                      console.log(e.detail.value!)
                      if(String(e.detail.value!).length>0){
                          let glo = new RegExp(String(e.detail.value!).toLowerCase());
                          let new_tees_arr = new Array() 
                          tees_list.map((item:any)=>{
                              console.log("a",item);
                              let real_item = item
                              item = item.teeName.toLowerCase()

                              if(glo.test(item)){
                                  console.log("item",real_item)
                                  new_tees_arr.push(real_item)
                              }
                          })    
                          console.log(new_tees_arr)                            
                          setNewTees(new_tees_arr)                                    
                      }
                      else{
                          setNewTees(tees_list)
                      }
                      }} mode="ios">
                  </IonSearchbar>

                  <IonContent>
                      <IonGrid>
                          <IonList>
                              {
                                  (new_tees && new_tees.map((key:any,i:number)=>{
                                      return(
                                          <IonItem key={i}>
                                              <IonLabel onClick={()=>{
                                                  console.log(key)
                                                  setSelectTees(key.teeValue)
                                                  getTeeDetails(course_id, holes, key.teeValue)
                                                  setShowTeesModal(false)
                                              }}>
                                              {key.teeName}
                                              </IonLabel>
                                          </IonItem>
                                      )
                                  }))
                              }                                    
                          </IonList>
                      </IonGrid>
                  </IonContent>
              </>
          </IonModal>
          <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '45px'}}>
              <IonFabButton
              className="main-fab"
              onClick={() => {
                  window.history.back()
              }}
              >
              <IonIcon icon={arrowBackOutline} />
              </IonFabButton>
          </IonFab>
        </IonContent>
      </IonPage>
    );
};
export default Notifications;