import { IonGrid, IonAvatar, IonCol, IonRow, IonText } from '@ionic/react';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
interface Props{
    options:  {},
}
const Likes: React.FC<Props> = (props)  => {
    let obj = props.options;
    const [val,setVal] = useState<any>()
    // console.log(obj)
    useEffect(() => {
        console.log(obj)
        setVal(obj)
    }, []);
    return(
        <>
            <IonGrid className="mt-15">
            {
                val && val.map((key:any,i:number)=>{
                    return(
                        <IonRow className="ion-justify-content-center" key={i}>
                            <IonCol size="2" className="pad-ltb">
                                <IonAvatar style={{height:"30px",width:"30px"}}>
                                    <img src={key.user_image}/>
                                </IonAvatar>
                            </IonCol>
                            <IonCol size="6" className="pad-t" style={{marginRight: '-10px'}}>
                                <IonText className="color-blue s-14">
                                    {key.user_name}
                                </IonText>
                            </IonCol>
                            <IonCol size="4" className="pad-t ion-text-end">
                                <IonText className="s-12">
                                    {format(key.created_at, 'MM-dd-yyyy HH:mm')}
                                </IonText>
                            </IonCol>
                        </IonRow>    
                    )
                })
            }            
            </IonGrid>
        </>
    )
}
export default Likes;