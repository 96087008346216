import { IonGrid, IonRow, IonCol, IonText } from '@ionic/react';
import { key } from 'ionicons/icons';
import { useEffect, useState } from 'react';


interface Props {
    options: {},
}

const ScoreTable: React.FC<Props> = (props) => {

    let obj = props.options;
    const [val, setVal] = useState<any>(obj);
    const [front_par_total, setFrontParTotal] = useState<any>();
    const [front_score_total, setFrontScoreTotal] = useState<any>();
    const [front_points_total, setFrontPointsTotal] = useState<any>();
    const [back_par_total, setBackParTotal] = useState<any>();
    const [back_score_total, setBackScoreTotal] = useState<any>();
    const [back_points_total, setBackPointsTotal] = useState<any>();
    const [randomTweleveHolePoints, setRandomTweleveHolePoints] = useState<number>(0);
    const [randomHoles, setRandomHoles] = useState<any>([]);
    const [finalScore, setFinalScore] = useState<number>(0);
    const [finalTarget, setFinalTarget] = useState<number>(0);
    const setTotalValues = async (data: any) => {
        let front_pars = 0;
        let front_scores = 0;
        let front_points = 0;
        let back_pars = 0;
        let back_scores = 0;
        let back_points = 0;
        data && data.hole_details.forEach((key: any, i: number) => {
            if (key.hole_number <= 9) {

                front_pars += key.par

                front_scores += key.raw_score

                front_points += key.points

            } else {
                back_pars += key.par

                back_scores += key.raw_score

                back_points += key.points
            }
        });
        setFrontParTotal(front_pars);
        setFrontScoreTotal(front_scores);
        setFrontPointsTotal(front_points);
        setBackParTotal(back_pars);
        setBackScoreTotal(back_scores);
        setBackPointsTotal(back_points);
    }
    /* const generateRandomNumbers = () => {
        let randomNumbers = new Array();
        while (randomNumbers.length < 13) {
            const number =Math.floor(Math.random() * 18) + 1;
            if (randomNumbers.indexOf(number) === -1) {
                randomNumbers.push(number);
            }
        }
        randomNumbers.sort((a, b) => a - b);
        console.log(randomNumbers);
        setRandomHoles(randomNumbers);
    } */
    const calculateRandomHolePoints = () => {
        let tweleveHolePoints = 0;
        if (val && val.hole_details && val.hole_details.length > 0) {
            val.hole_details.forEach((hole: any) => {
                if (hole.is_picked) {
                    tweleveHolePoints += hole.points;
                }
            });
            setRandomTweleveHolePoints(tweleveHolePoints);
           setFinalScore(val.net_score);
        //    setFinalTarget(Math.floor(tweleveHolePoints * 1.5) > val.quota ? Math.floor(tweleveHolePoints * 1.5) : val.quota);
            let peoriaTarget = Math.floor(tweleveHolePoints * 1.5); 
            setFinalTarget(val.peoria_quota);
        }
    }
    useEffect(() => {
        if (val) {
            //generateRandomNumbers();
            calculateRandomHolePoints()
            setTotalValues(obj);
        }
    }, [val && val.first_name]);
    const [val2, setVal2] = useState<any>(["Eagle", "Birdie", "Par", "Bogey", "Double", "Triple"])

    return (
        <>
            <IonGrid className="">
                <IonRow className="ion-justify-content-center ion-text-center mt-10">
                    <IonText className="black s-18">{val && val.user_name}</IonText>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center">
                    <IonText className="black s-16">{val && val.date}</IonText>
                </IonRow>

            </IonGrid>

            <IonGrid>
                {
                    (val.hole_details.length == 18 ?
                        <>
                            <IonRow className="ion-justify-content-center ion-text-center">
                                <IonText className="wg-600 black">Front 9</IonText>
                            </IonRow>
                            <IonRow className="points-table-row-color">
                                <IonCol size="2" style={{ color: "black" }}>
                                    Hole
                                </IonCol>
                                {
                                    val && val.hole_details.map((key: any, i: number) => {
                                        // console.log("key",key)
                                        if (key.hole_number <= 9) {
                                            return (
                                                <IonCol size="1" style={{ color: "black" }} key={i}>
                                                    {key.hole_number}
                                                </IonCol>

                                            )

                                        }
                                    })
                                }
                                <IonCol size="1" style={{ color: "black" }}>
                                    Tot
                                </IonCol>
                            </IonRow>
                            <IonRow className="points-table-row-color">
                                <IonCol size="2" style={{ color: "black" }}>
                                    Par
                                </IonCol>
                                {
                                    val && val.hole_details.map((key: any, i: number) => {
                                        // console.log("key",key)
                                        if (key.hole_number <= 9) {
                                            return (
                                                <IonCol size="1" style={{ color: "black" }} key={i}>
                                                    {key.par}
                                                </IonCol>

                                            )

                                        }
                                    })
                                }
                                <IonCol size="1" style={{ color: "black", paddingInlineStart: "0", paddingInlineEnd: "0" }}>
                                    {front_par_total}
                                </IonCol>
                            </IonRow>
                            <IonRow className="points-table-row-app-color">
                                <IonCol size="2" style={{ color: "white" }}>
                                    Score
                                </IonCol>
                                {
                                    val && val.hole_details.map((key: any, i: number) => {
                                        // console.log("key",key)
                                        if (key.hole_number <= 9) {
                                            return (
                                                <IonCol size="1" style={{ color: "white" }} key={i}>
                                                    {
                                                        (key.raw_score == 0) ? (
                                                            <>
                                                                _
                                                            </>
                                                        ) : (
                                                            <>
                                                                {
                                                                    key.raw_score - key.par > 2 ? (
                                                                        <IonText style={{ padding: '1px 5px 1px 5px', textDecoration: 'underline', color: '#fff' }}>
                                                                            {key.raw_score}
                                                                        </IonText>
                                                                    ) : key.raw_score - key.par == 2 ? (
                                                                        <IonText style={{ padding: '1px 5px 1px 5px', borderStyle: 'double', color: '#fff' }}>
                                                                            {key.raw_score}
                                                                        </IonText>
                                                                    ) : key.raw_score - key.par == 1 ? (
                                                                        <IonText style={{ padding: '1px 5px 1px 5px', border: '1px solid #fff', color: '#fff' }}>
                                                                            {key.raw_score}
                                                                        </IonText>
                                                                    ) : key.raw_score - key.par == 0 ? (
                                                                        <IonText style={{ padding: '1px 5px 1px 5px', color: '#fff' }}>
                                                                            {key.raw_score}
                                                                        </IonText>
                                                                    ) : key.raw_score - key.par == -1 ? (
                                                                        <IonText style={{ padding: '1px 5px 1px 5px', border: '1px solid #fff', color: '#fff', borderRadius: '50%' }}>
                                                                            {key.raw_score}
                                                                        </IonText>
                                                                    ) : key.raw_score - key.par == -2 ? (
                                                                        <IonText style={{ padding: '1px 5px 1px 5px', background: '#fff', color: '#00365f', borderRadius: '50%' }}>
                                                                            {key.raw_score}
                                                                        </IonText>
                                                                    ) : key.raw_score - key.par <= -3 ? (
                                                                        <IonText style={{ padding: '1px 5px 1px 5px', background: '#fff', color: '#00365f', borderRadius: '50%' }}>
                                                                            {key.raw_score}
                                                                        </IonText>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                    {/* <IonInput
                                                        type="number"
                                                        value={key.raw_score}                                        
                                                        onIonChange={(e) => Edit_row(parseInt(e.detail.value!, 10),key.hole_number)}
                                                        ></IonInput> */}
                                                </IonCol>
                                            )


                                        }
                                    })
                                }
                                <IonCol size="1" style={{ color: "white" }}>
                                    {front_score_total}
                                </IonCol>
                            </IonRow>
                            <IonRow className="points-table-row-blank">
                                <IonCol size="2" style={{ color: "black" }}>
                                    Points
                                </IonCol>
                                {
                                    val && val.hole_details.map((key: any, i: number) => {
                                        // console.log("key",key)
                                        if (key.hole_number <= 9) {
                                            return (
                                                <IonCol size="1" style={{ color: "black" }} key={i}>
                                                    {key && key.is_picked ?
                                                        <IonText style={{ padding: '1px 5px 1px 5px', background: 'red', color: '#fff' }}>
                                                            {key.points}
                                                        </IonText>
                                                        :
                                                        <IonText>
                                                            {key.points}
                                                        </IonText>
                                                    }

                                                </IonCol>
                                            )
                                        }
                                    })
                                }
                                <IonCol size="1" style={{ color: "black" }}>
                                    {front_points_total}
                                </IonCol>
                            </IonRow>

                            <IonRow className="ion-justify-content-center ion-text-center mt-10">
                                <IonText className="wg-600 black">Back 9</IonText>
                            </IonRow>
                            <IonRow className="points-table-row-color">
                                <IonCol size="2" style={{ color: "black" }}>
                                    Hole
                                </IonCol>
                                {
                                    val && val.hole_details.map((key: any, i: number) => {
                                        // console.log("key",key)
                                        if (key.hole_number > 9) {
                                            return (
                                                <IonCol size="1" style={{ color: "black" }} key={i}>
                                                    {key.hole_number}
                                                </IonCol>
                                            )
                                        }
                                    })
                                }
                                <IonCol size="1" style={{ color: "black" }}>
                                    Tot
                                </IonCol>
                            </IonRow>
                            <IonRow className="points-table-row-color">
                                <IonCol size="2" style={{ color: "black" }}>
                                    Par
                                </IonCol>
                                {
                                    val && val.hole_details.map((key: any, i: number) => {
                                        // console.log("key",key)
                                        if (key.hole_number > 9) {
                                            return (
                                                <IonCol size="1" style={{ color: "black" }} key={i}>
                                                    {key.par}
                                                </IonCol>
                                            )

                                        }
                                    })
                                }
                                <IonCol size="1" style={{ color: "black" }}>
                                    {back_par_total}
                                </IonCol>
                            </IonRow>
                            <IonRow className="points-table-row-app-color">
                                <IonCol size="2" style={{ color: "white" }}>
                                    Score
                                </IonCol>
                                {
                                    val && val.hole_details.map((key: any, i: number) => {
                                        if (i >= 9) {
                                            return (
                                                <IonCol size="1" style={{ color: "white" }} key={i}>
                                                    {
                                                        (key.raw_score == 0) ? (
                                                            <>
                                                                _
                                                            </>
                                                        ) : (
                                                            <>
                                                                {
                                                                    key.raw_score - key.par > 2 ? (
                                                                        <IonText style={{ padding: '1px 5px 1px 5px', textDecoration: 'underline', color: '#fff' }}>
                                                                            {key.raw_score}
                                                                        </IonText>
                                                                    ) : key.raw_score - key.par == 2 ? (
                                                                        <IonText style={{ padding: '1px 5px 1px 5px', borderStyle: 'double', color: '#fff' }}>
                                                                            {key.raw_score}
                                                                        </IonText>
                                                                    ) : key.raw_score - key.par == 1 ? (
                                                                        <IonText style={{ padding: '1px 5px 1px 5px', border: '1px solid #fff', color: '#fff' }}>
                                                                            {key.raw_score}
                                                                        </IonText>
                                                                    ) : key.raw_score - key.par == 0 ? (
                                                                        <IonText style={{ padding: '1px 5px 1px 5px', color: '#fff' }}>
                                                                            {key.raw_score}
                                                                        </IonText>
                                                                    ) : key.raw_score - key.par == -1 ? (
                                                                        <IonText style={{ padding: '1px 5px 1px 5px', border: '1px solid #fff', color: '#fff', borderRadius: '50%' }}>
                                                                            {key.raw_score}
                                                                        </IonText>
                                                                    ) : key.raw_score - key.par == -2 ? (
                                                                        <IonText style={{ padding: '1px 5px 1px 5px', background: '#fff', color: '#00365f', borderRadius: '50%' }}>
                                                                            {key.raw_score}
                                                                        </IonText>
                                                                    ) : key.raw_score - key.par <= -3 ? (
                                                                        <IonText style={{ padding: '1px 5px 1px 5px', background: '#fff', color: '#00365f', borderRadius: '50%' }}>
                                                                            {key.raw_score}
                                                                        </IonText>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                    {/* <IonInput
                                                        type="number"
                                                        value={key.raw_score}                                        
                                                        onIonChange={(e) => Edit_row(parseInt(e.detail.value!, 10),key.hole_number)}
                                                        ></IonInput> */}
                                                </IonCol>
                                            )

                                        }
                                    })
                                }
                                <IonCol size="1" style={{ color: "white" }}>
                                    {back_score_total}
                                </IonCol>
                            </IonRow>
                            <IonRow className="points-table-row-blank">
                                <IonCol size="2" style={{ color: "black" }}>
                                    Points
                                </IonCol>
                                {
                                    val && val.hole_details.map((key: any, i: number) => {
                                        // console.log("key",key)
                                        if (key.hole_number > 9) {
                                            return (
                                                <IonCol size="1" style={{ color: "black" }} key={i}>
                                                    {key && key.is_picked ?
                                                        <IonText style={{ padding: '1px 5px 1px 5px', background: 'red', color: '#fff' }}>
                                                            {key.points}
                                                        </IonText>
                                                        :
                                                        <IonText>
                                                            {key.points}
                                                        </IonText>
                                                    }

                                                </IonCol>

                                            )

                                        }
                                    })
                                }
                                <IonCol size="1" style={{ color: "black", paddingInlineStart: "0", paddingInlineEnd: "0" }}>
                                    {back_points_total}
                                </IonCol>
                            </IonRow>
                        </>
                        : <></>
                    )
                }

                <br />
                <br />
                <IonRow className="ion-justify-content-center ion-text-center" style={{ width: "85%", margin: "auto", borderBottom: "0.5px  solid black" }}>
                    {
                        val2 && val2.map((key: any, i: number) => {
                            return (
                                <IonCol size="2" key={i}>
                                    <IonText className="s-11">{key}</IonText>
                                </IonCol>

                            )

                        })
                    }
                    <IonCol size="2">
                        <IonText className="black s-14">{val && val.points.eagle}</IonText>
                    </IonCol>
                    <IonCol size="2">
                        <IonText className="black s-14">{val && val.points.birdie}</IonText>
                    </IonCol>
                    <IonCol size="2">
                        <IonText className="black s-14">{val && val.points.par_value}</IonText>
                    </IonCol>
                    <IonCol size="2">
                        <IonText className="black s-14">{val && val.points.bogey}</IonText>
                    </IonCol>
                    <IonCol size="2">
                        <IonText className="black s-14">{val && val.points.double_bogey}</IonText>
                    </IonCol>
                    <IonCol size="2">
                        <IonText className="black s-14">{val && val.points.triple_bogey}</IonText>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center mt-10">
                    <IonText className="wg-500 black">Total Points: {val && val.points.total_points}</IonText>
                </IonRow>
            </IonGrid>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonText>Points from 12 random holes: <span style={{ color: 'red' }}>{randomTweleveHolePoints}</span> </IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonText>Peoria target: <span style={{ color: 'red' }}>{Math.floor(randomTweleveHolePoints * 1.5)}</span> ({randomTweleveHolePoints} * 1.5,rounded down)</IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonText>Playing target: <span style={{ color: 'red' }}> {val.quota} </span></IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonText>Final target: <span style={{ color: 'red' }}>{finalTarget}</span> (Best of Peoria Target and 50% Playing Target)</IonText>
                    </IonCol>
                </IonRow>
                { /*
                <IonRow>
                    <IonCol>
                        <IonText>Playing target: <span style={{ fontWeight: 900 }}> {val.quota} </span></IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonText>Final target: <span style={{ fontWeight: 900 }}>{finalTarget}</span> (Best of Peoria target and Playing target)</IonText>
                    </IonCol>
                </IonRow>
                */ }
                <IonRow>
                    <IonCol>
                        <IonText>Points from all 18 holes: <span style={{ color: 'red' }}>{val.points.total_points}</span></IonText>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <IonText>Net points over Final target: <span style={{ color: 'red' }}>{finalScore}</span>  ({val.points.total_points} - {finalTarget})</IonText>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </>
    );
};

export default ScoreTable;