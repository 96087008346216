import main_logo from "../../img/eclub.golf.png"
import default_profile_logo from "../../img/fewbats.svg";
import { IonContent, IonPage, IonToolbar, IonTabBar, IonTabButton, IonIcon, IonLabel, IonButtons, IonGrid, IonSegment, IonSegmentButton, IonCard, IonCol, IonItem, IonRow, IonText, IonInput, IonList, IonModal, IonMenuToggle, IonSearchbar, IonHeader, IonTitle, IonFab, IonFabButton, IonAvatar, IonCardContent, IonCardHeader, IonSkeletonText, IonSelectOption, IonSelect, useIonAlert, useIonToast, IonButton } from '@ionic/react';
import '../../css/noticeboard.css';
import {add,notificationsOutline, funnelOutline, arrowBackOutline, paperPlane, peopleOutline, closeOutline, analytics } from 'ionicons/icons';
import { isPlatform } from '@ionic/react';
import default_user_logo from "../../img/default.png";
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import _ from 'lodash';
import firebase from '../../js/firebase.js';
import {
  Chatbubbles,
  Golf,
  Gift,
  Grid,
} from '@styled-icons/ionicons-outline';
import {
  Calendar
} from '@styled-icons/ionicons-solid';
import { Browser } from "@capacitor/browser";
import { getFirestore, collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { endOfMonth, startOfMonth } from "date-fns/esm";
import Likes from "../Components/like";
import * as utils from '../../js/utils';
import { createEntryWithoutId, updateEntry } from "../../js/firebase_doc_functions";
import BottomBar from "../Components/bottom_toolbar";
import EventSettings from "./create_event";
import PayPal from '../Components/paypal_payment';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';


const db = getFirestore(firebase);

const Noticeboard: React.FC = () => {
  const [noticeboard_tab_value, setNoticeboardTabValue] = useState<any>("Events");
  const [user_image, setUserImage] = useState<any>("");
  const [events_data, setEventsData] = useState<any>([]);
  const [current_event, setCurrentEvent] = useState<any>({})
  const [championships_data, setChampionshipsData] = useState<any>([]);
  const [events_master_data, setEventsMasterData] = useState<any>([]);
  const [championships_master_data, setChampionshipsMasterData] = useState<any>([]);
  const [current_user, setCurrentUser] = useState<any>({});
  const [event_type, setEventType] = useState<any>("All Types");
  const [notifications, setNotifications] = useState<boolean>(false);
  const [championship_type, setChampionshipType] = useState<any>("All Types");
  const [event_month, setEventMonth] = useState<any>("2022");
  const [championship_month, setChampionshipMonth] = useState<any>("2022");
  const [showEventTypeModal, setShowEventTypeModal] = useState<boolean>(false);
  const [showEventMonthModal, setShowEventMonthModal] = useState<boolean>(false);
  const [showEventCityModal, setShowEventCityModal] = useState(false);
  const [showChampionshipRegionModal, setShowChampionshipRegionModal] = useState(false);
  const [showChampionshipCityModal, setShowChampionshipCityModal] = useState(false);
  const [showEventRegionModal, setShowEventRegionModal] = useState(false);
  const [showChampionshipTypeModal, setShowChampionshipTypeModal] = useState<boolean>(false);
  const [showChampionshipMonthModal, setShowChampionshipMonthModal] = useState<boolean>(false);
  const [regions, setRegions] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [event_region, setEventRegion] = useState<string>("All Regions");
  const [championship_region, setChampionshipRegion] = useState<string>("All Regions");
  const [new_types, setNewTypes] = useState<any>([]);
  const [new_cities, setNewCities] = useState<any>(cities);
  const [new_regions, setNewRegions] = useState<any>(regions);
  const [event_types, setEventTypes] = useState<any>([]);
  const [event_city, setEventCity] = useState<string>("All Cities");
  const [championship_city, setChampionshipCity] = useState<string>("All Cities");
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [showFiltersValue, setShowFiltersValue] = useState<boolean>(false);
  const [filtersCount, setFiltersCount] = useState<number>(0);
  const [showJoinPackModal, setShowJoinPackModal] = useState(false);
  const [selected_pack_id, setSelectedPackId] = useState("");
  const [url, setUrl] = useState<string>("");
  const [selected_pack, setSelectedPack] = useState<any>([]);
  const [packs_list, setPacksList] = useState<any>([]);
  const [presentToast, dismissToast] = useIonToast();
  const [pack_message, setPackMessage] = useState<string>();
  const [presentAlert, dismissAlert] = useIonAlert();
  const [pack_city, setPackCity] = useState<string>('');
  const [espn_feed, setEspnFeed] = useState<any>([]);
  const [set_show_event, setShowEvent] = useState(false);
  const [months, setMonths] = useState<any>([
    { text: "2022", value: "2022" },
    { text: "January", value: 1 },
    { text: "February", value: 2 },
    { text: "March", value: 3 },
    { text: "April", value: 4 },
    { text: "May", value: 5 },
    { text: "June", value: 6 },
    { text: "July", value: 7 },
    { text: "August", value: 8 },
    { text: "September", value: 9 },
    { text: "October", value: 10 },
    { text: "November", value: 11 },
    { text: "December", value: 12 },
  ])

  const customAlertOptions = {
    header: "Select Pack",
  };

  const customAlertOptions1 = {
    header: "Select city",
  };

  const [chips_qty, setChipsQty] = useState<any>(250);
  const [amount, setAmount] = useState<any>(25);
  const [required_data, setRequiredData] = useState<any>();
  const [paypal_data, setPaypalData] = useState<any>();
  const [set_paypal_payment, setPaypalPayment] = useState(false);
  const [showToast, hideToast] = useIonToast();

  const getEvents = async (user_id: string) => {
    const q = query(collection(db, "EVENTS"), where('created_at', '>', 0), where("status", "==", "Approved"), orderBy('created_at', 'desc'));
    const querySnapshot = await getDocs(q);
    console.log(user_id);
    if (querySnapshot.docs && querySnapshot.docs.length > 0) {
      await Promise.all(querySnapshot.docs.map(async event => {
        var doc_item = event.data();
        doc_item.event_id = event.id;
        let players = doc_item.players || [];
        if (players.filter((x: any) => x.user_id === user_id).length <= 0) {
          doc_item.joined = false;
        } else {
          doc_item.joined = true;
        }
        return doc_item;
      }))
        .then(results => {
          setEventsData(results);
          setEventsMasterData(results);
        });
    }
  }
  const getNotificationCounts = async (user_id: string) => {
    const q = query(collection(db, "CHECKIN_NOTIFICATIONS"), where('created_at', '>', 0), where('user_id', '==', user_id));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
      querySnapshot.docs.forEach((notifications) => {
        const notification = notifications.data();
        if (!notification.match_status && !(notification.match_status === 'Declined' || notification.match_status === 'Accepted')) {
          setNotifications(true);
        }
      });
    }
  }

  const allPacksList = async (user_packs: any) => {
    const q = query(
      collection(db, "PACKS"),
      where("pack_id", "!=", "kuFOI2OPKCuhCwrewzBqDI8w71IE")
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs && querySnapshot.docs.length > 0) {
      await Promise.all(
        querySnapshot.docs.map(async (pack) => {
          var doc_item = pack.data();
          doc_item.id = pack.id;
          return doc_item;
        })
      ).then((results) => {
        let new_packs = _.differenceBy(results, user_packs, "pack_id");
        console.log("curr", new_packs);
        setPacksList(new_packs);
      });
    } else {
      console.log("No packs available");
    }
  };
  const [filtered_packs, setFilteredPacks] = useState<any>([]);
  const filterPacks = async (city: string) => {
    if (city === "Any") {
      setFilteredPacks(packs_list);
    } else {
      let filtered_packs = packs_list.filter((x: any) => x.city === city);
      setFilteredPacks(filtered_packs);
    }
  }
  const setPack = async (pack_id: string) => {
    let filtered_pack = packs_list.filter((x: any) => x.pack_id == pack_id);
    if (filtered_pack.length > 0) {
      setSelectedPack(filtered_pack[0]);
    }
  };
  const sendJoinRequest = async () => {
    if (selected_pack && selected_pack.pack_name != null) {
      var obj = {
        created_name: current_user.first_name + " " + current_user.last_name,
        created_by: current_user.user_id,
        type: "JOIN_REQUEST",
        description: pack_message,
        source: {
          user_name: current_user.first_name + " " + current_user.last_name,
          user_id: current_user.user_id,
        },
        is_read: false,
        pack_id: selected_pack.pack_id,
        pack_name: selected_pack.pack_name,
        user_id: selected_pack.pack_lead_id,
      };
      await createEntryWithoutId("PACK_NOTIFICATIONS", obj);
      presentToast("Request sent", 1800);
    }
  };
  const getChampionships = async () => {
    const q = query(collection(db, "CHAMPIONSHIPS"), where('created_at', '>', 0), orderBy('created_at', 'desc'));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs && querySnapshot.docs.length > 0) {
      await Promise.all(querySnapshot.docs.map(async event => {
        var doc_item = event.data();
        doc_item.id = event.id;
        return doc_item;
      }))
        .then(results => {
          setChampionshipsData(results);
          setChampionshipsMasterData(results);
        });
    }
  }
  const filterEvents = async (event_type: string, event_month: any, event_region: string, event_city: string) => {
    let date =
      new Date().getFullYear() +
      " " +
      event_month +
      " " +
      new Date().getDate();
    let startOf = startOfMonth(new Date(date));
    let endOf = endOfMonth(new Date(date));
    let results = new Array();
    if (events_master_data.length > 0) {
      let filterCount = 0;
      if (event_type == "All Types" && event_month == "2022" && event_region == "All Regions" && event_city == "All Cities") {
        results = events_master_data;
      }
      if (event_type != "All Types") {
        filterCount++;
        let filteredEvents = events_master_data.filter(
          (x: any) => x.event_type.toLowerCase() == event_type.toLowerCase() &&
            (event_month != "2022" ? (x.event_date_timestamp >= new Date(startOf).getTime() &&
              x.event_date_timestamp <= new Date(endOf).getTime()) : x.event_date_timestamp > 0) &&
            (event_region == "All Regions" ? x.region_name.length > 0 : x.region_name.toLowerCase() == event_region.toLowerCase()) &&
            (event_city == "All Cities" ? x.city.length > 0 : x.city.toLowerCase() == event_city.toLowerCase())
        );
        results = results.concat(filteredEvents);
      }
      if (event_month != "2022") {
        filterCount++;
        let filteredEvents = events_master_data.filter(
          (x: any) =>
            (event_type == "All Types" ? x.event_type.length > 0 : x.event_type.toLowerCase() == event_type.toLowerCase()) &&
            x.event_date_timestamp >= new Date(startOf).getTime() &&
            x.event_date_timestamp <= new Date(endOf).getTime() &&
            (event_region == "All Regions" ? x.region_name.length > 0 : x.region_name.toLowerCase() == event_region.toLowerCase()) &&
            (event_city == "All Cities" ? x.city.length > 0 : x.city.toLowerCase() == event_city.toLowerCase())
        );
        results = results.concat(filteredEvents);
      }
      if (event_region != "All Regions") {
        filterCount++;
        let filteredEvents = events_master_data.filter(
          (x: any) => (event_type == "All Types" ? x.event_type.length > 0 : x.event_type.toLowerCase() == event_type.toLowerCase()) &&
            (event_month != "2022" ? (x.event_date_timestamp >= new Date(startOf).getTime() &&
              x.event_date_timestamp <= new Date(endOf).getTime()) : x.event_date_timestamp > 0) &&
            x.region_name.toLowerCase() == event_region.toLowerCase() &&
            (event_city == "All Cities" ? x.city.length > 0 : x.city.toLowerCase() == event_city.toLowerCase())
        );
        results = results.concat(filteredEvents);
      }
      if (event_city != "All Cities") {
        filterCount++;
        let filteredEvents = events_master_data.filter(
          (x: any) => (event_type == "All Types" ? x.event_type.length > 0 : x.event_type.toLowerCase() == event_type.toLowerCase()) &&
            (event_month != "2022" ? (x.event_date_timestamp >= new Date(startOf).getTime() &&
              x.event_date_timestamp <= new Date(endOf).getTime()) : x.event_date_timestamp > 0) &&
            (event_region == "All Regions" ? x.region_name.length > 0 : x.region_name.toLowerCase() == event_region.toLowerCase()) &&
            x.city.toLowerCase() == event_city.toLowerCase()
        );
        results = results.concat(filteredEvents);
      }
      let uniqueEvents = _.uniqBy(results, 'id');
      setFiltersCount(filterCount);
      setShowFiltersValue(filterCount > 0 ? true : false);
      setEventsData(uniqueEvents);
      setShowFiltersModal(false);
    }
  };
  const filterChampionships = async (championship_type: string, championship_month: any, championship_region: string, championship_city: string) => {
    let date =
      new Date().getFullYear() +
      " " +
      event_month +
      " " +
      new Date().getDate();
    let startOf = startOfMonth(new Date(date));
    let endOf = endOfMonth(new Date(date));
    let results = new Array();
    if (championships_master_data.length > 0) {
      let filterCount = 0;
      if (championship_type == "All Types" && event_month == "2022" && championship_region == "All Regions" && championship_city == "All Cities") {
        results = championships_master_data;
      }
      if (championship_type != "All Types") {
        filterCount++;
        let filteredChampionships = championships_master_data.filter(
          (x: any) => x.event_type.toLowerCase() == championship_type.toLowerCase() &&
            (championship_month != "2022" ? (x.event_date_timestamp >= new Date(startOf).getTime() &&
              x.event_date_timestamp <= new Date(endOf).getTime()) : x.event_date_timestamp > 0) &&
            (championship_region == "All Regions" ? x.region_name.length > 0 : x.region_name.toLowerCase() == championship_region.toLowerCase()) &&
            (championship_city == "All Cities" ? x.city.length > 0 : x.city.toLowerCase() == championship_city.toLowerCase())
        );
        results = results.concat(filteredChampionships);
      }
      if (championship_month != "2022") {
        filterCount++;
        let filteredChampionships = championships_master_data.filter(
          (x: any) =>
            (championship_type == "All Types" ? x.event_type.length > 0 : x.event_type.toLowerCase() == championship_type.toLowerCase()) &&
            x.event_date_timestamp >= new Date(startOf).getTime() &&
            x.event_date_timestamp <= new Date(endOf).getTime() &&
            (championship_region == "All Regions" ? x.region_name.length > 0 : x.region_name.toLowerCase() == championship_region.toLowerCase()) &&
            (championship_city == "All Cities" ? x.city.length > 0 : x.city.toLowerCase() == championship_city.toLowerCase())
        );
        results = results.concat(filteredChampionships);
      }
      if (championship_region != "All Regions") {
        filterCount++;
        let filteredChampionships = championships_master_data.filter(
          (x: any) => (championship_type == "All Types" ? x.event_type.length > 0 : x.event_type.toLowerCase() == championship_type.toLowerCase()) &&
            (championship_month != "2022" ? (x.event_date_timestamp >= new Date(startOf).getTime() &&
              x.event_date_timestamp <= new Date(endOf).getTime()) : x.event_date_timestamp > 0) &&
            x.region_name.toLowerCase() == championship_region.toLowerCase() &&
            (championship_city == "All Cities" ? x.city.length > 0 : x.city.toLowerCase() == championship_city.toLowerCase())
        );
        results = results.concat(filteredChampionships);
      }
      if (championship_city != "All Cities") {
        filterCount++;
        let filteredChampionships = championships_master_data.filter(
          (x: any) => (championship_type == "All Types" ? x.event_type.length > 0 : x.event_type.toLowerCase() == championship_type.toLowerCase()) &&
            (championship_month != "2022" ? (x.event_date_timestamp >= new Date(startOf).getTime() &&
              x.event_date_timestamp <= new Date(endOf).getTime()) : x.event_date_timestamp > 0) &&
            (championship_region == "All Regions" ? x.region_name.length > 0 : x.region_name.toLowerCase() == championship_region.toLowerCase()) &&
            x.city.toLowerCase() == championship_city.toLowerCase()
        );
        results = results.concat(filteredChampionships);
      }
      let uniqueChampionships = _.uniqBy(results, 'id');
      setFiltersCount(filterCount);
      setShowFiltersValue(filterCount > 0 ? true : false);
      setChampionshipsData(uniqueChampionships);
      setShowFiltersModal(false);
    }
  };
  const toggleEventCard = (header: any) => {
    events_data && events_data.forEach((key: any, i: number) => {
      if (key.id == header) {
        key.toggle = key.toggle == true ? false : true;
      } else {
        key.toggle = false
      }
    })
    // console.log(header,cards)
    setEventsData(Array.from(new Set([...events_data])))
  }
  const toggleChampionshipCard = (header: any) => {
    championships_data && championships_data.forEach((key: any, i: number) => {
      if (key.id == header) {
        key.toggle = key.toggle == true ? false : true;
      } else {
        key.toggle = false
      }
    })
    // console.log(header,cards)
    setChampionshipsData(Array.from(new Set([...championships_data])))
  }
  const getConfig = async () => {
    let typesQuery = query(
      collection(db, "CONFIG"),
      where("type", "==", "EVENT_TYPES")
    );
    const typesQuerySnapshot = await getDocs(typesQuery);
    setEventTypes(typesQuerySnapshot.docs[0].data().types);
    setNewTypes(typesQuerySnapshot.docs[0].data().types);

    let citiesQuery = query(
      collection(db, "CONFIG"),
      where("type", "==", "EVENT_CITIES")
    );
    const citiesQuerySnapshot = await getDocs(citiesQuery);
    setCities(citiesQuerySnapshot.docs[0].data().cities);
    setNewCities(citiesQuerySnapshot.docs[0].data().cities);

    let regionsQuery = query(
      collection(db, "REGIONS"),
      where("created_at", ">", 0)
    );
    const regionsQuerySnapshot = await getDocs(regionsQuery);
    await Promise.all(
      regionsQuerySnapshot.docs.map(async (region) => {
        var doc_item = region.data();
        doc_item.id = region.id;
        return doc_item;
      })
    ).then((results) => {
      setRegions(results);
    });
  }

  const showTTPaypalPayment = async (event: any, chips: any, amount: any) => {
    const q = query(collection(db, "CONFIG"), where('type', '==', "PAYPAL"));
    const querySnapshot = await getDocs(q);
    if (querySnapshot && querySnapshot.docs.length > 0) {
      var obj = {
        "currency": "USD",
        "client-id": querySnapshot.docs[0].data().value,
        "components": "buttons"
      }
      setRequiredData(event);
      setPaypalData(obj);
      setChipsQty(parseInt(chips));
      setAmount(parseInt(amount));
      setPaypalPayment(true);
    } else {
      setPaypalPayment(false);
      setPaypalData({});
      showToast('Error fecthing payment details. Please try again after some time.', 2000);
    }
  }
  const handleCallback = async () => {
    if (current_event && current_event.id.length > 0) {
      let players = current_event.players.filter((x: any) =>
        x.user_id == current_user.user_id,
      );
      if (players.length <= 0) {
        current_event.players.push({ user_id: current_user.user_id, user_name: current_user.first_name + ' ' + current_user.last_name });
        await updateEntry("EVENTS", current_event.event_id, { players: current_event.players })
        setPaypalPayment(false);
        showToast('Successfully signed up for event', 2000);
      } else {
        setPaypalPayment(false);
        showToast('Error!', 2000);
      }
    } else {
      showToast('Error fecthing event details. Please try again after some time.', 2000);
    }
  }

  useEffect(() => {
    let user = localStorage.getItem('firebase_user');
    console.log(user);
    if (user != null) {
      getConfig();
      let cu = JSON.parse(user);
      setCurrentUser(cu);
      setUserImage(JSON.parse(user).logo_url);
      //  getFeed();
      getEvents(cu.user_id);
      setFiltersCount(0);
      setShowFiltersValue(false);
      getNotificationCounts(cu.user_id);
    }
  }, [])
  return (
    <IonPage>
      <IonToolbar mode="ios" className={isPlatform('ios') ? 'ios-toolbar-top' : 'toolbar-top'}>
        <IonButtons slot="start" className="slot-start-padding">

        </IonButtons>
        {/* <IonTitle>
          <img src={main_logo} className="main_logo"/>
        </IonTitle> */}
        <IonLabel slot="start" className="s-18 w-800 color-blue">{noticeboard_tab_value}</IonLabel>
        <IonButtons slot="end" className="slot-end-padding" onClick={() => {
          allPacksList(current_user.packs);
          setShowJoinPackModal(true);
        }}>
          <IonIcon icon={peopleOutline} className="s-30 color-blue" />
        </IonButtons>
        <IonButtons slot="end" style={{ padding: '0px 5px 0px 10px' }} onClick={() => window.location.href = "/notifications"}>
          <IonIcon icon={notificationsOutline} className="s-30 color-blue" />
          {
            notifications ? (
              <span className='notification-dot'></span>
            ) : (
              <></>
            )
          }
        </IonButtons>
        {/* <IonButtons slot="end" style={{padding: '0px 5px 0px 10px'}}>
          {
            (user_image)?(
              <a href="/profile" className="pd-r-10"><img src={user_image} className="pro_pic "/></a>
            ):(
              <a href="/profile" className="pd-r-10"><img src={default_profile_logo} className="pro_pic"/></a>
            )
          }
        </IonButtons> */}
      </IonToolbar>
      <IonContent>
        {
          (noticeboard_tab_value == "Championships") ? (
            <>
              <IonRow className="ion-justify-content-center mt-10">
                {/* <IonCol size="3">
                <IonInput
                  value={championship_type}
                  placeholder="Select type"
                  className="s-14 select-dropdown pl-20"
                  mode="ios"
                  onClick={() => {
                    setShowChampionshipTypeModal(true);
                    setChampionshipType(championship_type);
                  }}
                ></IonInput>
              </IonCol>
              <IonCol size="3">
                <IonInput
                  value={championship_month}
                  placeholder="Select month"
                  className="s-14 select-dropdown pl-20"
                  mode="ios"
                  onClick={() => {
                    setShowChampionshipMonthModal(true);
                    setChampionshipMonth(championship_month);
                  }}
                ></IonInput>
              </IonCol>
              <IonCol size="3">
                <IonInput
                  value={championship_region}
                  placeholder="Select region"
                  className="s-14 select-dropdown pl-20"
                  mode="ios"
                  onClick={() => {
                    setShowChampionshipRegionModal(true);
                    setChampionshipRegion(championship_region);
                  }}
                ></IonInput>
              </IonCol>
              <IonCol size="3">
                <IonInput
                  value={championship_city}
                  placeholder="Select city"
                  className="s-14 select-dropdown pl-20"
                  mode="ios"
                  onClick={() => {
                    setShowChampionshipCityModal(true);
                    setChampionshipCity(championship_city);
                  }}
                ></IonInput>
              </IonCol> */}
              </IonRow>
              <IonContent style={{ overflowY: 'auto' }}>
                {
                  (championships_data && championships_data.map((item: any, index: number) => {
                    return (
                      <IonCard key={index} className="noticebaord-cards ">
                        <IonRow className="ion-text-start">
                          <IonCol size="12" className="pad-5">
                            <IonText className="s-16 wg-600 color-blue">{item.event_name}</IonText><br />
                            <IonText className="s-12 wg-600 color-gray">{format(item.event_date_timestamp, "MM/dd/yyyy")}; by {item.organized_by}</IonText>
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-text-start">
                          <IonCol size="12" className="pad-5">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.event_description,
                              }}
                              className="mb-10 color-dark-gray"
                            ></div>
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-text-start mt-10">
                          <IonCol size="12">
                            <a className="blue-anchor-button" style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => {
                              Browser.open({ url: item.url });
                            }}>
                              <IonText>
                                Details
                              </IonText>
                            </a>
                          </IonCol>
                        </IonRow>
                      </IonCard>
                    )
                  }))
                }
              </IonContent>
            </>
          ) : (noticeboard_tab_value == "Events") ? (
            <>
            <IonFab vertical="bottom" horizontal="end" slot="fixed">
                <IonFabButton
                  className="main-fab"
                  onClick={() => {
                    window.location.href = "/event_settings";
                  }}
                >
                  <IonIcon icon={add} />
                </IonFabButton>
            </IonFab>
              <IonRow className="ion-justify-content-center ion-text-center mt-10">
                <IonCol>
                  <IonText className="s-13">Please <a href="mailto:'info@eclub.golf">contact us</a> for listing your events</IonText>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10">
                {/* <IonCol size="3">
                <IonInput
                  value={event_type}
                  placeholder="Select type"
                  className="s-14 select-dropdown pl-20"
                  mode="ios"
                  onClick={() => {
                    setShowEventTypeModal(true);
                    setEventType(event_type);
                  }}
                ></IonInput>
              </IonCol>
              <IonCol size="3">
                <IonInput
                  value={event_month}
                  placeholder="Select month"
                  className="s-14 select-dropdown pl-20"
                  mode="ios"
                  onClick={() => {
                    setShowEventMonthModal(true);
                    setEventMonth(event_month);
                  }}
                ></IonInput>
              </IonCol>
              <IonCol size="3">
                <IonInput
                  value={event_region}
                  placeholder="Select region"
                  className="s-14 select-dropdown pl-20"
                  mode="ios"
                  onClick={() => {
                    setShowEventRegionModal(true);
                    setEventRegion(event_region);
                  }}
                ></IonInput>
              </IonCol>
              <IonCol size="3">
                <IonInput
                  value={event_city}
                  placeholder="Select city"
                  className="s-14 select-dropdown pl-20"
                  mode="ios"
                  onClick={() => {
                    setShowEventCityModal(true);
                    setEventCity(event_city);
                  }}
                ></IonInput>
              </IonCol> */}
              </IonRow>
              <IonContent style={{ overflowY: 'auto' }}>
                {
                  (events_data && events_data.map((item: any, index: number) => {
                    return (
                      <IonCard key={index} className="noticebaord-cards ">
                        <IonRow className="ion-text-start">
                          <IonCol size="12" className="pad-5">
                            <IonText className="s-16 wg-600 color-blue">{item.event_name}</IonText><br />
                            <IonText className="s-12 wg-600 color-gray">{format(item.event_date_timestamp, "MM/dd/yyyy")}; by {item.organized_by}</IonText>
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-text-start">
                          <IonCol size="12" className="pad-5">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.event_description,
                              }}
                              className="mb-10 color-dark-gray"
                            ></div>
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-text-start mt-10">
                          <IonCol size="3">
                            <IonButton className="pro-button" onClick={e => {
                              console.log(item);
                              setCurrentEvent(item);
                              setShowEvent(true);
                            }}>
                              <IonText>
                                About
                              </IonText>
                            </IonButton>
                          </IonCol>
                          <IonCol size="4">
                            {
                              (item.joined) ? (
                                <IonButton className="pro-button" onClick={e => {
                                  window.location.href = "/event_add_checkin/" + item.event_id
                                }}>

                                  <IonText>
                                    Post Score
                                  </IonText>
                                </IonButton>
                              ) : (
                                <IonButton className="pro-button" onClick={e => {
                                  showTTPaypalPayment(item, (item.event_dues + item.youth_on_course + item.portal_fees) * 10,
                                    item.event_dues + item.youth_on_course + item.portal_fees);
                                }}>
                                  <IonText>
                                    Pay to Join
                                  </IonText>
                                </IonButton>
                              )
                            }
                          </IonCol>
                          <IonCol size="5">
                            <IonButton className="pro-button" onClick={e => {
                              window.location.href = "/event_leaderboard/" + item.event_id
                            }}>
                              <IonText>
                                Leaderboard
                              </IonText>
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonCard>
                    )
                  }))
                }
              </IonContent>
            </>
          ) : (noticeboard_tab_value == "News Feed") ? (
            <>
              {
                espn_feed && espn_feed.length > 0 ? (
                  <>
                    {espn_feed.map((feed: any, index: number) => {
                      return (
                        <div key={index}>
                          {/* <IonRow className="ion-text-center ion-justify-content-center">
                          <IonText className="myclub-header">Exclusive for CLG Members</IonText>
                        </IonRow> */}
                          <IonCard className="social-card">
                            <IonCardHeader className="card-header">
                              <IonRow className="pd-5">
                                <IonCol size="2">
                                  <div>
                                    {(feed.user_image == "" ||
                                      feed.user_image == undefined || feed.user_image == null) ? (
                                      <IonAvatar
                                        slot="start"
                                        className="card-avatar profile-link"
                                      >
                                        <img src={default_user_logo} alt={feed.user_name} />
                                      </IonAvatar>
                                    ) : (
                                      <IonAvatar
                                        slot="start"
                                        className="card-avatar profile-link"
                                      >
                                        <img src={feed.user_image} alt={feed.user_name} />
                                      </IonAvatar>
                                    )}
                                  </div>
                                </IonCol>
                                <IonCol size="8" className="user-details mt-10">
                                  <IonLabel>
                                    <p>
                                      {format(
                                        feed.timestamp,
                                        "MMM dd, yyyy HH:mm"
                                      )}
                                    </p>
                                  </IonLabel>
                                </IonCol>
                                <IonCol size="2" className="card-options">

                                </IonCol>
                              </IonRow>
                            </IonCardHeader>
                            <IonCardContent className="card-content mt-0">
                              {feed.post_text}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: feed.post_content,
                                }}
                                className="mb-10"
                              ></div>
                              {/* <IonRow className="mb-5 text-overflow">
                              <a style={{textDecoration: 'none', cursor: 'pointer'}} onClick={()=> {
                                Browser.open({ url: feed.url });
                              }}>
                                <IonText className="s-13 pl-5">
                                  {feed.url}
                                </IonText>
                              </a>
                            </IonRow> */}
                              {feed.post_image == "" ||
                                feed.post_image == undefined ? (
                                <></>
                              ) : (
                                <>
                                  {
                                    feed.url.length > 0 ? (
                                      <a style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => {
                                        Browser.open({ url: feed.url });
                                      }}>
                                        <img src={feed.post_image} style={{ borderRadius: '10px' }} />
                                      </a>
                                    ) : (
                                      <img src={feed.post_image} style={{ borderRadius: '10px' }} />
                                    )
                                  }
                                </>
                              )}
                            </IonCardContent>
                          </IonCard>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )
              }
            </>
          ) : (noticeboard_tab_value == "Add New") ? (
            <EventSettings />
          ) : (
            <>
              <IonRow className="ion-justify-content-center vh-25">
                <IonText className="s-16 wg-600 black pl-20">Coming soon!</IonText>
              </IonRow>
            </>
          )
        }
        <IonModal
          isOpen={showEventTypeModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowEventTypeModal(false)}
          mode="md"
        >
          <>

            <IonContent>
              <IonGrid>
                <IonList>
                  {event_types &&
                    event_types.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              setEventType(key);
                              //filterEvents(key, event_month, event_region, event_city);
                              setShowEventTypeModal(false);
                            }}
                          >
                            {key}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showEventMonthModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowEventMonthModal(false)}
          mode="md"
        >
          <>

            <IonContent>
              <IonGrid>
                <IonList>
                  {months &&
                    months.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              setEventMonth(key.text);
                              //filterEvents(event_type, key.value, event_region, event_city);
                              setShowEventMonthModal(false);
                            }}
                          >
                            {key.text}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showEventCityModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowEventCityModal(false)}
          mode="md"
        >
          <>

            <IonSearchbar
              onIonChange={(e: any) => {
                if (String(e.detail.value!).length > 0) {
                  let glo = new RegExp(String(e.detail.value!).toLowerCase());
                  let new_city_arr = new Array();
                  cities.map((item: any) => {
                    let real_item = item;
                    item = item.state_name.toLowerCase();

                    if (glo.test(item)) {
                      new_city_arr.push(real_item);
                    }
                  });
                  setNewCities(new_city_arr);
                } else {
                  setNewCities(cities);
                }
              }}
              mode="ios"
            ></IonSearchbar>
            <IonContent>
              <IonGrid>
                <IonList>
                  {cities &&
                    cities.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              setEventCity(key);
                              //filterEvents(event_type, event_month, event_region, key);
                              setShowEventCityModal(false);
                            }}
                          >
                            {key}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showEventRegionModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowEventRegionModal(false)}
          mode="md"
        >
          <>

            <IonSearchbar
              onIonChange={(e: any) => {
                if (String(e.detail.value!).length > 0) {
                  let glo = new RegExp(String(e.detail.value!).toLowerCase());
                  let new_city_arr = new Array();
                  regions.map((item: any) => {
                    let real_item = item;
                    item = item.state_name.toLowerCase();
                    if (glo.test(item)) {
                      new_city_arr.push(real_item);
                    }
                  });
                  setNewRegions(new_city_arr);
                } else {
                  setNewRegions(regions);
                }
              }}
              mode="ios"
            ></IonSearchbar>
            <IonContent>
              <IonGrid>
                <IonList>
                  <IonItem>
                    <IonLabel
                      onClick={() => {
                        setEventRegion("All Regions");
                        //filterEvents(event_type, event_month, "All Regions", event_city);
                        setShowEventRegionModal(false);
                      }}
                    >
                      All Regions
                    </IonLabel>
                  </IonItem>
                  {regions &&
                    regions.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              setEventRegion(key.region_name);
                              //filterEvents(event_type, event_month, key.region_name, event_city);
                              setShowEventRegionModal(false);
                            }}
                          >
                            {key.region_name}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showChampionshipTypeModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowChampionshipTypeModal(false)}
          mode="md"
        >
          <>

            <IonContent>
              <IonGrid>
                <IonList>
                  {event_types &&
                    event_types.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              setEventType(key);
                              //filterChampionships(key, championship_month, championship_region, championship_city);
                              setShowChampionshipTypeModal(false);
                            }}
                          >
                            {key}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showChampionshipMonthModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowChampionshipMonthModal(false)}
          mode="md"
        >
          <>

            <IonContent>
              <IonGrid>
                <IonList>
                  {months &&
                    months.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              setEventMonth(key.text);
                              //filterChampionships(championship_month, key.value, championship_region, championship_city);
                              setShowChampionshipMonthModal(false);
                            }}
                          >
                            {key.text}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showChampionshipCityModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowChampionshipCityModal(false)}
          mode="md"
        >
          <>

            <IonSearchbar
              onIonChange={(e: any) => {
                if (String(e.detail.value!).length > 0) {
                  let glo = new RegExp(String(e.detail.value!).toLowerCase());
                  let new_city_arr = new Array();
                  cities.map((item: any) => {
                    let real_item = item;
                    item = item.state_name.toLowerCase();

                    if (glo.test(item)) {
                      new_city_arr.push(real_item);
                    }
                  });
                  setNewCities(new_city_arr);
                } else {
                  setNewCities(cities);
                }
              }}
              mode="ios"
            ></IonSearchbar>
            <IonContent>
              <IonGrid>
                <IonList>
                  {cities &&
                    cities.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              setChampionshipCity(key);
                              //filterChampionships(championship_type, championship_month, championship_region, key);
                              setShowChampionshipCityModal(false);
                            }}
                          >
                            {key}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showChampionshipRegionModal}
          className="my-custom-class2"
          showBackdrop={true}
          backdropDismiss={true}
          initialBreakpoint={0.5}
          onDidDismiss={() => setShowChampionshipRegionModal(false)}
          mode="md"
        >
          <>

            <IonSearchbar
              onIonChange={(e: any) => {
                if (String(e.detail.value!).length > 0) {
                  let glo = new RegExp(String(e.detail.value!).toLowerCase());
                  let new_city_arr = new Array();
                  regions.map((item: any) => {
                    let real_item = item;
                    item = item.state_name.toLowerCase();
                    if (glo.test(item)) {
                      new_city_arr.push(real_item);
                    }
                  });
                  setNewRegions(new_city_arr);
                } else {
                  setNewRegions(regions);
                }
              }}
              mode="ios"
            ></IonSearchbar>
            <IonContent>
              <IonGrid>
                <IonList>
                  <IonItem>
                    <IonLabel
                      onClick={() => {
                        setChampionshipRegion("All Regions");
                        //filterChampionships(championship_type, championship_month, "All Regions", championship_city);
                        setShowEventRegionModal(false);
                      }}
                    >
                      All Regions
                    </IonLabel>
                  </IonItem>
                  {regions &&
                    regions.map((key: any, i: number) => {
                      return (
                        <IonItem key={i}>
                          <IonLabel
                            onClick={() => {
                              setChampionshipRegion(key.region_name);
                              //filterChampionships(championship_type, championship_month, key.region_name, championship_city);
                              setShowChampionshipRegionModal(false);
                            }}
                          >
                            {key.region_name}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonList>
              </IonGrid>
            </IonContent>
          </>
        </IonModal>
        <IonModal isOpen={showFiltersModal}
          className="filters-modal"
          showBackdrop={true}
          backdropDismiss={true}

          onDidDismiss={() => setShowFiltersModal(false)}
          mode="md">
          <>
            <IonHeader mode="ios">
              <IonToolbar>
                {/* <IonButtons slot="start">
                  <a onClick={() => setShowFiltersModal(false)}>
                    <IonIcon
                      className="well-back-icon"
                      icon={chevronBackOutline}
                    />
                  </a>
                </IonButtons> */}
                <IonTitle>Filters</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              {
                (noticeboard_tab_value === "Events") ? (
                  <IonGrid className="grid-1-bg h-100">
                    <IonText className="s-13 black pl-20">Type</IonText>
                    <IonRow className="ion-justify-content-center mt-5">
                      <IonItem lines="none" className="select-pack-dropdown item-bg" style={{ height: "40px" }}>
                        <IonInput
                          value={event_type}
                          placeholder="Select type"
                          className="s-14 pl-20 search-text-padding"
                          mode="ios"
                          onClick={() => {
                            setShowEventTypeModal(true);
                            setEventType(event_type);
                          }}
                        ></IonInput>
                      </IonItem>
                    </IonRow>
                    <br />
                    <IonText className="s-13 black pl-20">Month</IonText>
                    <IonRow className="ion-justify-content-center mt-5">
                      <IonItem lines="none" className="select-pack-dropdown item-bg" style={{ height: "40px" }}>
                        <IonInput
                          value={event_month}
                          placeholder="Select month"
                          className="s-14 pl-20 search-text-padding"
                          mode="ios"
                          onClick={() => {
                            setShowEventMonthModal(true);
                            setEventMonth(event_month);
                          }}
                        ></IonInput>
                      </IonItem>
                    </IonRow>
                    <br />
                    <IonText className="s-13 black pl-20">Region</IonText>
                    <IonRow className="ion-justify-content-center mt-5">
                      <IonItem lines="none" className="select-pack-dropdown item-bg" style={{ height: "40px" }}>
                        <IonInput
                          value={event_region}
                          placeholder="Select region"
                          className="s-14 pl-20 search-text-padding"
                          mode="ios"
                          onClick={() => {
                            setShowEventRegionModal(true);
                            setEventRegion(event_region);
                          }}
                        ></IonInput>
                      </IonItem>
                    </IonRow>
                    <br />
                    <IonText className="s-13 black pl-20">City</IonText>
                    <IonRow className="ion-justify-content-center mt-5">
                      <IonItem lines="none" className="select-pack-dropdown item-bg" style={{ height: "40px" }}>
                        <IonInput
                          value={event_city}
                          placeholder="Select city"
                          className="s-14 pl-20 search-text-padding"
                          mode="ios"
                          onClick={() => {
                            setShowEventCityModal(true);
                            setEventCity(event_city);
                          }}
                        ></IonInput>
                      </IonItem>
                    </IonRow>
                    <br />
                    <br />
                    <IonRow className="display-flex ion-justify-content-center ion-text-center mt-25">
                      <button className='button-close bg-blue wg-bold' style={{ width: "40%", height: "35px", borderRadius: "50px", fontSize: "16px" }} onClick={() => {
                        filterEvents(event_type, event_month, event_region, event_city);
                      }}>
                        Apply
                      </button>
                      <button className='button-close bg-blue wg-bold' style={{ width: "40%", height: "35px", borderRadius: "50px", fontSize: "16px" }} onClick={() => {
                        setShowFiltersModal(false);
                      }}>
                        Close
                      </button>
                    </IonRow>
                  </IonGrid>
                ) : (
                  <IonGrid className="grid-1-bg h-100">
                    <IonText className="s-13 black pl-20">Type</IonText>
                    <IonRow className="ion-justify-content-center mt-5">
                      <IonItem lines="none" className="select-pack-dropdown item-bg" style={{ height: "40px" }}>
                        <IonInput
                          value={championship_type}
                          placeholder="Select type"
                          className="s-14 pl-20 search-text-padding"
                          mode="ios"
                          onClick={() => {
                            setShowChampionshipTypeModal(true);
                            setChampionshipType(championship_type);
                          }}
                        ></IonInput>
                      </IonItem>
                    </IonRow>
                    <br />
                    <IonText className="s-13 black pl-20">Month</IonText>
                    <IonRow className="ion-justify-content-center mt-5">
                      <IonItem lines="none" className="select-pack-dropdown item-bg" style={{ height: "40px" }}>
                        <IonInput
                          value={championship_month}
                          placeholder="Select month"
                          className="s-14 pl-20 search-text-padding"
                          mode="ios"
                          onClick={() => {
                            setShowChampionshipMonthModal(true);
                            setChampionshipMonth(championship_month);
                          }}
                        ></IonInput>
                      </IonItem>
                    </IonRow>
                    <br />
                    <IonText className="s-13 black pl-20">Region</IonText>
                    <IonRow className="ion-justify-content-center mt-5">
                      <IonItem lines="none" className="select-pack-dropdown item-bg" style={{ height: "40px" }}>
                        <IonInput
                          value={championship_region}
                          placeholder="Select region"
                          className="s-14 pl-20 search-text-padding"
                          mode="ios"
                          onClick={() => {
                            setShowChampionshipRegionModal(true);
                            setChampionshipRegion(championship_region);
                          }}
                        ></IonInput>
                      </IonItem>
                    </IonRow>
                    <br />
                    <IonText className="s-13 black pl-20">City</IonText>
                    <IonRow className="ion-justify-content-center mt-5">
                      <IonItem lines="none" className="select-pack-dropdown item-bg" style={{ height: "40px" }}>
                        <IonInput
                          value={championship_city}
                          placeholder="Select city"
                          className="s-14 pl-20 search-text-padding"
                          mode="ios"
                          onClick={() => {
                            setShowChampionshipCityModal(true);
                            setChampionshipCity(championship_city);
                          }}
                        ></IonInput>
                      </IonItem>
                    </IonRow>
                    <br />
                    <IonRow className="display-flex ion-justify-content-center ion-text-center mt-25">
                      <button className='button-close bg-blue wg-bold' style={{ width: "40%", height: "35px", borderRadius: "50px", fontSize: "16px" }} onClick={() => {
                        filterChampionships(championship_type, championship_month, championship_region, championship_city);
                      }}>
                        Apply
                      </button>
                      <button className='button-close bg-blue wg-bold' style={{ width: "40%", height: "35px", borderRadius: "50px", fontSize: "16px" }} onClick={() => {
                        setShowFiltersModal(false);
                      }}>
                        Close
                      </button>
                    </IonRow>
                  </IonGrid>
                )
              }
            </IonContent>
          </>
        </IonModal>
        <IonModal
          isOpen={showJoinPackModal}
          showBackdrop={true}
          className="join-pack-modal"
          backdropDismiss={true}
          initialBreakpoint={0.7}
          onDidDismiss={() => setShowJoinPackModal(false)} mode="md"
        >
          <IonHeader mode="ios">
            <IonToolbar>
              <IonTitle>Join a Pack</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid className="">
              {selected_pack && selected_pack.pack_name ? (
                <>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <img src={selected_pack.logo_url} width="80" height="80" />
                    <br />
                  </IonRow>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <IonText>Pack name: {selected_pack.pack_name}</IonText>
                  </IonRow>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <IonText>City: {selected_pack.city}</IonText>
                  </IonRow>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <IonText>Pack Type: {selected_pack.pack_type}</IonText>
                  </IonRow>
                  <IonRow className="display-flex ion-justify-content-center ion-text-center">
                    <IonText>Description: {selected_pack.description}</IonText>
                    <br />
                  </IonRow>
                </>
              ) : (
                <></>
              )}
              <IonRow className="ion-justify-content-center mt-10">
                <IonSelect value={pack_city}
                  interfaceOptions={customAlertOptions1}
                  interface="popover"
                  placeholder="Select a City" className="s-14 select-pack-dropdown" onIonChange={e => {
                    setPackCity(e.detail.value)
                    filterPacks(e.detail.value)
                  }} mode="ios">
                  <IonSelectOption value="Any">Any</IonSelectOption>
                  <IonSelectOption value="Kansas City">Kansas City Metro</IonSelectOption>
                  <IonSelectOption value="St. Louis">St. Louis, MO</IonSelectOption>
                  <IonSelectOption value="Des Moines">Des Moines, IA</IonSelectOption>
                  <IonSelectOption value="Wichita">Wichita, KS</IonSelectOption>
                  <IonSelectOption value="Salt Lake City">Salt Lake City, UT</IonSelectOption>
                  <IonSelectOption value="Topeka">Topeka, KS</IonSelectOption>
                  <IonSelectOption value="Salina">Salina, KS</IonSelectOption>
                  <IonSelectOption value="Manhattan">Manhattan, KS</IonSelectOption>
                  <IonSelectOption value="Hutchinson">Hutchinson, KS</IonSelectOption>
                  <IonSelectOption value="Lawrence">Lawrence, KS</IonSelectOption>
                  <IonSelectOption value="Leavenworth">Leavenworth, KS</IonSelectOption>
                  <IonSelectOption value="Denver">Denver, CO</IonSelectOption>
                </IonSelect>
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10">
                <IonSelect
                  value={selected_pack_id}
                  interfaceOptions={customAlertOptions}
                  interface="popover"
                  placeholder="Select Pack"
                  className="s-14 select-pack-dropdown"
                  onIonChange={(e) => {
                    setSelectedPackId(e.detail.value);
                    setPack(e.detail.value);
                  }}
                  mode="ios"
                >
                  {filtered_packs &&
                    filtered_packs.map((pack: any, index: number) => {
                      return (
                        <IonSelectOption value={pack.pack_id} key={index}>
                          {pack.pack_name}
                        </IonSelectOption>
                      );
                    })}
                </IonSelect>
              </IonRow>
              <IonRow className="ion-justify-content-center mt-10">
                <IonItem
                  lines="none"
                  className="select-pack-dropdown"
                  style={{ height: "43px" }}
                >
                  <IonInput
                    value={pack_message}
                    placeholder="Message"
                    onIonChange={(e) => setPackMessage(e.detail.value!)}
                    clearInput
                  ></IonInput>
                </IonItem>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-25">
                <button
                  className="button-send white s-15"
                  onClick={sendJoinRequest}
                >
                  <IonIcon icon={paperPlane} className="white" />
                  <IonText className="pl-5 send-request">Send Request</IonText>
                </button>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-25">
                <IonText>Or you can <span className="create-button-outline color-blue" onClick={() => {
                  window.location.href = "/create_pack"
                }}>create</span> your own pack</IonText>
              </IonRow>
            </IonGrid>
            <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{ marginBottom: '45px' }}>
              <IonFabButton
                className="main-fab"
                onClick={() => {
                  setShowJoinPackModal(false)
                }}
              >
                <IonIcon icon={arrowBackOutline} />
              </IonFabButton>
            </IonFab>
          </IonContent>
        </IonModal>
        <IonModal isOpen={set_show_event}>
          <IonContent>
            {
              (current_event) ? (
                <IonGrid className="mt-15">
                  <IonRow className="ion-justify-content-center mt-5">
                    <img
                      src={require(`../../img/eclub.png`).default}
                      width="140"
                      height="50"
                    />
                  </IonRow>
                  <IonRow className="ion-justify-content-center ion-text-center mt-10">
                    <IonText className="s-13 wg-bold black">
                      {current_event.event_name}
                    </IonText>
                  </IonRow>
                  <IonRow className="ion-justify-content-center ion-text-center">
                    <IonCol size="5.5" className="pad-0 text-align-right">
                      <IonText className="s-13 wg-bold black">Format</IonText>
                    </IonCol>
                    <IonCol size="1" className="pad-0">
                      <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="5.5" className="pad-0 text-align-left">
                      <IonText className="s-13">
                        {(current_event.peoria_format && current_event.netscore_format) ? "Peoria and Netscore" :
                          (current_event.netscore_format ? "Netscore" : "Peoria")
                        }
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-justify-content-center ion-text-center">
                    <IonCol size="5.5" className="pad-0 text-align-right">
                      <IonText className="s-13 wg-bold black">Total dues</IonText>
                    </IonCol>
                    <IonCol size="0.5" className="pad-0">
                      <IonText className="s-13 wg-bold black">:</IonText>
                    </IonCol>
                    <IonCol size="5.5" className="pad-0 text-align-left">

                      {
                        current_event.youth_on_course > 0 ? (
                          <IonText className="s-13">
                            ${current_event.event_dues + current_event.youth_on_course + current_event.portal_fees}
                          </IonText>
                        ) : (
                          <IonText className="s-13">
                            ${current_event.event_dues + current_event.portal_fees}
                          </IonText>
                        )
                      }
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-justify-content-center ion-text-center mt-15">
                    <IonText className="s-15 black wg-bold">Players list</IonText>
                  </IonRow>
                  <IonRow className="ion-justify-content-center ion-text-center bb-line">
                    <IonCol size="2">
                      <IonText className="s-14 wg-600 color-gray">S.No</IonText>
                    </IonCol>
                    <IonCol size="10">
                      <IonText className="s-14 wg-600 color-gray">Name</IonText>
                    </IonCol>
                  </IonRow>


                  {
                    current_event.players && current_event.players.map((player: any, i: number) => {
                      return (
                        <IonRow className="ion-justify-content-center ion-text-center bb-line" key={i}
                          onClick={() => { window.location.href = "/player_profile/" + player.user_id }} >
                          <IonCol size="2">
                            <IonText className="s-14 wg-600 color-black">{i + 1}</IonText>
                          </IonCol>
                          <IonCol size="10">
                            <IonText className="s-14 wg-600 color-black">{player.user_name}</IonText>
                          </IonCol>
                        </IonRow>
                      )
                    })
                  }
                </IonGrid>
              ) : (
                <> </>
              )
            }
          </IonContent>
          <IonFab
            vertical="bottom"
            horizontal="end"
            slot="fixed"
            className="back-fab-button"
            style={{ marginBottom: "45px" }}
          >
            <IonFabButton
              className="main-fab"
              onClick={() => {
                setShowEvent(false);
              }}
            >
              <IonIcon icon={closeOutline} />
            </IonFabButton>
          </IonFab>
        </IonModal>
        <IonModal isOpen={set_paypal_payment}>
          <>
            <IonHeader mode="ios">
              <IonToolbar>
                <IonButtons slot="start">
                  {/* <a onClick={()=> setShowModal(false)}>
                        <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                        </a> */}
                </IonButtons>
                <IonTitle>
                  Event Payment
                </IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonGrid className="mt-15">
                <IonRow className="ion-justify-content-center ion-text-center mt-10">
                  <IonCol>
                    <IonLabel position="fixed">Total Price:</IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonText>${amount}</IonText>
                  </IonCol>

                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center mt-10">
                  <IonCol>
                    <IonText>Total Processing Charges:</IonText>
                  </IonCol>
                  <IonCol>
                    <IonText>{((((chips_qty * 0.1) * 3.49) / 100) + 0.60).toFixed(2)}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center mt-10">
                  <IonCol>
                    <IonLabel position="fixed">Total Cost:</IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonText>${amount + Number((((amount * 3.49) / 100) + 0.60).toFixed(2))}</IonText>
                  </IonCol>

                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center mt-25">

                </IonRow>
              </IonGrid>
              <PayPalScriptProvider
                options={paypal_data}
              >
                <PayPal paypal_data={paypal_data} required_data={required_data} referrer={'events'} chips_qty={chips_qty} amount={amount} currency={'USD'} showSpinner={false} parentCallback={handleCallback} />
              </PayPalScriptProvider>
              <IonRow className="display-flex ion-justify-content-center ion-text-center mt-25">
                <button className='button-close bg-blue wg-bold' style={{ width: "50%", height: "35px", borderRadius: "50px", fontSize: "16px" }} onClick={() => setPaypalPayment(false)}>
                  Close
                </button>
              </IonRow>
            </IonContent>
          </>
        </IonModal>
        {
          noticeboard_tab_value == "Events" ? (
            <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{ marginBottom: '45px' }}>
              <IonFabButton
                className="main-fab"
                onClick={() => {
                  setShowFiltersModal(true)
                }}
              >
                <IonIcon icon={funnelOutline} size="20" />
                {
                  (showFiltersValue) ? (
                    <span className="filter-badge">{filtersCount}</span>
                  ) : (
                    <></>
                  )
                }
              </IonFabButton>
          </IonFab>
          ) : (
            <></>
          )
        }
      </IonContent>
      <IonToolbar mode="ios" className="segment-toolbar" slot="bottom">
        <IonSegment onIonChange={e => {
          setNoticeboardTabValue(e.detail.value)
          if (e.detail.value == "Events") {
            getEvents(current_user.user_id);
            setFiltersCount(0);
            setShowFiltersValue(false);
          } else if (e.detail.value == "Championships") {
            // getChampionships();
            // setFiltersCount(0);
            // setShowFiltersValue(false);
          }
          // else if(e.detail.value == "News Feed"){
          //   getFeed();
          // }
        }} value={noticeboard_tab_value} mode="ios"
          className="new-segment">
          { /* Moving News Feed from Noticeboard to Locker Room
          <IonSegmentButton value="News Feed" className="new-segment-button">
            <IonLabel className="new-segment-button-label">News Feed</IonLabel>
        </IonSegmentButton> */}
          <IonSegmentButton value="Events" className="new-segment-button">
            <IonLabel className="new-segment-button-label">Events</IonLabel>
          </IonSegmentButton>
          {/* <IonSegmentButton value="Championships" className="new-segment-button">
            <IonLabel className="new-segment-button-label">Championships</IonLabel>
          </IonSegmentButton> */}
          {/*<IonSegmentButton value="Add New" className="new-segment-button">
            <IonLabel className="new-segment-button-label">Add New</IonLabel>
          </IonSegmentButton> */}
        </IonSegment>
      </IonToolbar>
      <BottomBar cur_page="noticeboard" />
    </IonPage>
  );
};

export default Noticeboard;
