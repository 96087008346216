import {
  IonPage,
  IonToolbar,
  isPlatform,
  IonTitle,
  IonLabel,
  IonText,
  IonContent,
  IonGrid,
  IonCard,
  IonRow,
  IonCol,
  IonFab,
  IonFabButton,
  IonIcon,
  IonTabBar,
  IonTabButton,
} from "@ionic/react";
import { settings } from "firebase/analytics";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { arrowBackOutline, list } from "ionicons/icons";
import { useEffect, useState } from "react";
import firebase from "../../js/firebase";
const db = getFirestore(firebase);

const PackSettings: React.FC = () => {
  const [current_pack, setCurrentPack] = useState<any>([]);
  const [pack_settings, setPackSettings] = useState<any>([]);
  const getPackSettings = async (pack_id:string) => {
    const packQuery = query(
      collection(db, "TOUR_SETTINGS"),
      where("pack_id", "==", pack_id)
    );
    const packQuerySnapshot = await getDocs(packQuery);
    if (packQuerySnapshot.docs && packQuerySnapshot.docs.length > 0) {
      let doc_item = packQuerySnapshot.docs[0].data();
      doc_item.id = packQuerySnapshot.docs[0].id;
      setPackSettings(doc_item);
    }
  };
  useEffect(() => {
    let current_pack = localStorage.getItem("current_pack");
    if (current_pack != null) {
      setCurrentPack(JSON.parse(current_pack));
      getPackSettings(JSON.parse(current_pack).pack_id)
    }
  }, []);
  return (
    <IonPage>
      <IonToolbar
        mode="ios"
        className={
          isPlatform("ios")
            ? "ios-toolbar-top box-shadow"
            : "toolbar-top box-shadow"
        }
      >
        <IonTitle>
          <IonLabel>
            <IonText className="wg-bold s-16">
              {current_pack.pack_name + " " + current_pack.city}
            </IonText>
            <br />
          </IonLabel>
        </IonTitle>
      </IonToolbar>
      <IonContent>
        <IonGrid className="mt-15">
          <IonRow className="ion-justify-content-center ion-text-center mt-10">
            <IonText className="s-15 black wg-bold">Settings</IonText>
          </IonRow>
          <IonRow className="ion-justify-content-center ion-text-center mt-5">
            <IonCol size="5.5">
              <IonText className="s-13 wg-bold black">Format</IonText>
            </IonCol>
            <IonCol size="0.5">
              <IonText className="s-13 wg-bold black">:</IonText>
            </IonCol>
            <IonCol size="6">
              <IonText className="s-13">
                {(pack_settings.format || '').split('~')[1]}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center ion-text-center mt-5">
            <IonCol size="5.5">
              <IonText className="s-13 wg-bold black">Partner required</IonText>
            </IonCol>
            <IonCol size="0.5">
              <IonText className="s-13 wg-bold black">:</IonText>
            </IonCol>
            <IonCol size="6">
              <IonText className="s-13">
                {pack_settings.partner_required}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center ion-text-center mt-5">
            <IonCol size="5.5">
              <IonText className="s-13 wg-bold black">Season prize pool</IonText>
            </IonCol>
            <IonCol size="0.5">
              <IonText className="s-13 wg-bold black">:</IonText>
            </IonCol>
            <IonCol size="6">
              <IonText className="s-13">
                {pack_settings.season_prize_pool}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center ion-text-center mt-5">
            <IonCol size="5.5">
              <IonText className="s-13 wg-bold black">Weekly prize pool</IonText>
            </IonCol>
            <IonCol size="0.5">
              <IonText className="s-13 wg-bold black">:</IonText>
            </IonCol>
            <IonCol size="6">
              <IonText className="s-13">
                {pack_settings.weekly_prize_pool}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center ion-text-center mt-5">
            <IonCol size="5.5">
              <IonText className="s-13 wg-bold black">Pull scores from</IonText>
            </IonCol>
            <IonCol size="0.5">
              <IonText className="s-13 wg-bold black">:</IonText>
            </IonCol>
            <IonCol size="6">
              <IonText className="s-13">
                {pack_settings.pull_scores_from}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center ion-text-center mt-5">
            <IonCol size="5.5">
              <IonText className="s-13 wg-bold black">Limited courses</IonText>
            </IonCol>
            <IonCol size="0.5">
              <IonText className="s-13 wg-bold black">:</IonText>
            </IonCol>
            <IonCol size="6">
              <IonText className="s-13">
                {pack_settings.fixed_courses}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center ion-text-center mt-5">
            <IonCol size="5.5">
              <IonText className="s-13 wg-bold black">Selected courses</IonText>
            </IonCol>
            <IonCol size="0.5">
              <IonText className="s-13 wg-bold black">:</IonText>
            </IonCol>
            <IonCol size="6">
              {pack_settings.selected_courses && pack_settings.selected_courses.map((item:string, index:number)=> {
                return (
                  <div  key={index}>
                  <IonText className="s-13">
                    {item}
                  </IonText>
                  <br/>
                  </div>
                )
              })}
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center ion-text-center mt-10">
            <IonText className="s-12 black">
              *If your pack has any custom configurations, please reach out to
              pack admin
            </IonText>
          </IonRow>
          <IonRow className="ion-justify-content-center ion-text-center mt-15">
            <IonText className="s-15 black wg-bold">Players list</IonText>
          </IonRow>
          <IonGrid>
            <IonCard className="social-card">
                <IonRow className="ion-justify-content-center ion-text-center bb-line">
                    <IonCol size="2">
                        <IonText className="s-14 wg-600 color-gray">S.No</IonText>
                    </IonCol>
                    <IonCol size="10">
                    <IonText className="s-14 wg-600 color-gray">Name</IonText>
                    </IonCol>
                </IonRow>
                {
                  pack_settings.players && pack_settings.players.map((item:any, index:number)=> {
                    return(
                      <IonRow className="ion-justify-content-center ion-text-center bb-line" key={index}>
                          <IonCol size="2">
                              <IonText className="s-14 color-black wg-500">{index+1}</IonText>
                          </IonCol>
                          <IonCol size="10">
                          <IonText className="s-14 color-black wg-600">{item.user_name}</IonText>
                          </IonCol>
                      </IonRow>
                    )
                  })
                }
            </IonCard>
        </IonGrid>
         
        </IonGrid>
        <IonFab
          vertical="bottom"
          horizontal="end"
          slot="fixed"
          className="back-fab-button"
          style={{ marginBottom: "45px" }}
        >
          <IonFabButton
            className="main-fab"
            onClick={() => {
              window.history.back();
            }}
          >
            <IonIcon icon={arrowBackOutline} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default PackSettings;
