import Table from '../Components/table'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonIcon,IonLabel,IonButtons, IonGrid, IonCard, IonCardContent, IonCardHeader, IonText, IonBackButton, IonModal, isPlatform, IonFab, IonFabButton } from '@ionic/react';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import _ from 'lodash';
import firebase from '../../js/firebase.js';

import { getFirestore, collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { arrowBackOutline, chevronBackOutline } from 'ionicons/icons';

const db = getFirestore(firebase);

const PlayPostWinCheckins: React.FC = () => {
    const [ppw_checkins, setPpwCheckins] = useState<any>([]);
    const [score_record, setScoreRecord] = useState<any>([]);
    const [front_holes, setFrontHoles] = useState<boolean>(true);
    const [back_holes, setBackHoles] = useState<boolean>(true);
    const [ppw_score_modal, setPpwScoreModal] = useState(false);
    const [score_hole,setScoreHole] = useState<any>([]);
    const getPpwCheckins = async() => {
        var user = localStorage.getItem('firebase_user');
        if(user != null) {
            var current_user = JSON.parse(user);
            const q = query(collection(db, "PPW_COMPETITIONS"), where('created_at', '>', 0), where('user_id', '==', current_user.user_id), orderBy('created_at', 'desc'));
            const querySnapshot = await getDocs(q);
            await Promise.all(querySnapshot.docs.map(async competition => {
                var doc_item = competition.data();
                doc_item.id = competition.id;
                //doc_item.date = format(new Date(doc_item.played_at), "MM-dd-yyyy");
                return doc_item;
            }))
            .then(results => {
                setPpwCheckins(_.sortBy(results, e => e.creayed_at).reverse());
            });
        }
    }
    const getScoreCardData = async(index: number, player_id: string) => {
        if (ppw_checkins.length > 0) {
            var doc_item = ppw_checkins[index];
            var points = {
                "triple_bogey": 0,
                "double_bogey": 0,
                "bogey": 0,
                "par_value": 0,
                "birdie": 0,
                "eagle": 0,
                "double_eagle": 0,        
            };
            var total_points = 0;
            await Promise.all(doc_item.hole_details.map((hole: any)=> {
                if((hole.raw_score-hole.par) > 2) {
                    points.triple_bogey++;
                    total_points += 0;
                    hole.hole_point = hole.raw_score-hole.par;
                }
                if((hole.raw_score-hole.par) == 2) {
                    points.double_bogey++;
                    total_points += 0;
                    hole.hole_point = hole.raw_score-hole.par;
                }
                if((hole.raw_score-hole.par) == 1) {
                    points.bogey++;
                    total_points += 1;
                    hole.hole_point = hole.raw_score-hole.par;
                }
                if((hole.raw_score-hole.par) == 0) {
                    points.par_value++;
                    total_points += 2;
                    hole.hole_point = hole.raw_score-hole.par;
                }
                if((hole.raw_score-hole.par) == -1) {
                    points.birdie++;
                    total_points += 3;
                    hole.hole_point = hole.raw_score-hole.par;
                }
                if((hole.raw_score-hole.par) == -2) {
                    points.eagle++;
                    total_points += 5;
                    hole.hole_point = hole.raw_score-hole.par;
                }
                if((hole.raw_score-hole.par) == -3) {
                    points.double_eagle++;
                    total_points += 5;
                    hole.hole_point = hole.raw_score-hole.par;
                }
            }))
            .then(results=> {
                var score_record = {
                    hole_details: _.sortBy(doc_item.hole_details, e => e.hole_number),
                    points: points,
                    total_points: total_points,
                    user_name: doc_item.first_name+' '+doc_item.last_name,
                    course_name: doc_item.course_display_value,
                    tee: doc_item.tee_name,
                    holes: doc_item.holes,
                    date: format(new Date(doc_item.played_at), "MM/dd/yyyy"),
                };
                setScoreRecord(score_record);
                setPpwScoreModal(true);
            });
        } else {
            console.log("Score card not available");
        }
    }
    useEffect(() => {
        getPpwCheckins()
    }, [])
    return (
        <IonPage>
            <IonToolbar mode="ios" className={isPlatform('ios') ? 'ios-toolbar-top box-shadow': 'toolbar-top box-shadow' }>
                {/* <IonButtons slot="start">
                    <IonBackButton defaultHref="/" />
                </IonButtons> */}
                <IonLabel slot="start" className="s-18 w-800 color-blue" style={{padding: '0px 0px 0px 10px'}}>
                    Play.Post.Win. Scores
                </IonLabel>
            </IonToolbar>
            <IonContent>
                <IonGrid className="grid-1-bg h-100">
                    {
                        ppw_checkins.map((checkin: any, index:number) => {
                            return (
                                <IonCard className="social-card" key={index}>
                                    <IonCardHeader style={{padding: '10px 0px 0px 20px'}}>
                                        <IonText className="color s-17 wg-600">{checkin.course_display_value}</IonText>
                                    </IonCardHeader>
                                    <IonCardContent style={{padding:'3px 0px 10px 20px'}}>
                                        <IonText>{checkin.date}</IonText>
                                        <IonText className="pl-5 wg-500 s-16">Quota:</IonText>
                                        <IonText className="pl-5">{checkin.quota}</IonText>
                                        <IonText className="pl-5 color wg-500 underline s-16" onClick={()=>getScoreCardData(index, checkin.user_id)}>Score:</IonText>
                                        <IonText className="pl-5">{checkin.total_points}</IonText>
                                    </IonCardContent>
                                </IonCard>
                            )
                        })
                    }   
                </IonGrid>
                <IonModal isOpen={ppw_score_modal} className='my-custom-class'>
                    <>
                        <IonHeader>
                            <IonToolbar>
                                <IonButtons slot="start">
                                    <a onClick={()=> setPpwScoreModal(false)}>
                                        <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
                                    </a>
                                </IonButtons>
                                <IonTitle>
                                    Points
                                </IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <Table options={score_record} holes={18} front9={front_holes} back9={back_holes}/>
                        </IonContent>
                    </>
                </IonModal>
                <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button" style={{marginBottom: '45px'}}>
                    <IonFabButton
                    className="main-fab"
                    onClick={() => {
                        window.location.href="/leagues/formats"
                    }}
                    >
                    <IonIcon icon={arrowBackOutline} />
                    </IonFabButton>
                </IonFab>
            </IonContent>
        </IonPage>
    );
};
export default PlayPostWinCheckins;