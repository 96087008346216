import { Route } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  isPlatform,
  setupIonicReact,
  useIonToast
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';

/* Common Pages*/
import Login from './pages/Common/login';
import Register from './pages/Common/register';
import Menu from './components/menu';
import Landing from './pages/Common/landing';

/*Golfer Pages*/
import LockerRoom from './pages/Golfer/locker_room';
import Noticeboard from './pages/Golfer/noticeboard';
import Proshop from './pages/Golfer/pro_shop';
import Leagues from './pages/Golfer/leagues';
import NetScoreChallengeCheckins from './pages/Golfer/net_score_challenge_checkins';
import NetScoreChallengeCreateCheckin from './pages/Golfer/net_score_challenge_create_checkin';
import NetScoreChallengeLeaderboards from './pages/Golfer/net_score_challenge_leaderboards';
import TwilighTourCheckins from './pages/Golfer/twilightour_checkins';
import TwilighTourCreateCheckin from './pages/Golfer/twilightour_create_checkin';
import TwilighTourLeaderboards from './pages/Golfer/twilightour_leaderboards';
import PlayPostWinCheckins from './pages/Golfer/play_post_win_checkins';
import PlayPostWinLeaderboards from './pages/Golfer/play_post_win_leaderboards';
import PlayPostWinAddChekin from './pages/Golfer/play_post_win_add_checkin';
import ReportAbuse from './pages/Golfer/report_abuse';
import CreateNewScore from './pages/Golfer/create_new_score';
import CreateNewPost from './pages/Golfer/create_post';
import JoinPack from './pages/Golfer/join_pack';
import CreateTeeTime from './pages/Golfer/create_tee_time';
import Notifications from './pages/Golfer/notifications';
import MatchInvites from './pages/Golfer/match_invites';
import Earnings from './pages/Golfer/earnings';
import Balances from './pages/Golfer/balances';
import Wallet from './pages/Golfer/wallet';
import MyPack from './pages/Golfer/my_pack';
import Quota from './pages/Golfer/quota';
import Handicap from './pages/Golfer/get_handicap';
import Profile from './pages/Golfer/profile';
import ConnectAbout from './pages/Golfer/connect_about';
import CreatePack from './pages/Golfer/create_pack';
import PlayerProfile from './pages/Golfer/player_profile';
import CreateTour from './pages/Golfer/create_tour';
import PackTourSettings from './pages/Golfer/pack_tour_settings';
import Tours from './pages/Golfer/tours';
import TourDetails from './pages/Golfer/tour_details';
import TAOEventCheckins from './pages/Golfer/event_checkins';
import TAOEventAddCheckin from './pages/Golfer/event_add_checkin';
import EventDetails from './pages/Golfer/event_details';
import EventLeaderboard from './pages/Golfer/event_leaderboards';
//questions page
import Questions from './pages/Golfer/questions';

/* Admin Pages */
import AdminHome from './pages/Admin/home';
import Users from './pages/Admin/users';
//import UserReports from './pages/Admin/user_reports';
import UserLogins from './pages/Admin/user_logins';
//import ManageUsers from './pages/Admin/manage_users';
import Orders from './pages/Admin/orders';
//import AdminBalances from './pages/Admin/balances';
import AdminLeaderboards from './pages/Admin/leaderboards';
import UserRegistrations from './pages/Admin/registrations';
import AdminFeed from './pages/Admin/feed';
import AdminCreateEvent from './pages/Admin/create_event';
import AdminEvents from './pages/Admin/events';
import AdminChampionships from './pages/Admin/championships';
import ManageProShop from './pages/Admin/manage_pro_shop';
import AdminTours from './pages/Admin/tours';
import TwilightTourOneOnOneLeaderboards from './pages/Golfer/twilight_tour_one_on_one_leaderboards';
import TAOEventAdminCheckin from './pages/Admin/event_admin_checkin';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { useEffect, useState } from 'react';
import * as utils from './js/utils';
import { createEntryWithoutId } from './js/firebase_doc_functions';
import { getFirestore, getDoc, query, collection, where, getDocs,setDoc,doc } from 'firebase/firestore';
import firebase from './js/firebase';
import EventSettings from './pages/Golfer/create_event';

setupIonicReact({
  mode: 'ios'
});
const db = getFirestore(firebase);
const App: React.FC = () => {
  const nullEntry: any[] = []
  const [email, setEmail] = useState('');
  const [present, dismiss] = useIonToast();
  const [notifications, setnotifications] = useState(nullEntry);
  const [id, setId] = useState('');
  // const checkToken = async (current_user: any) => {
  //   let ghin_number = current_user.ghin_number.length > 0 ? current_user.ghin_number : 0;
  //   let last_name = current_user.last_name.length > 0 ? current_user.last_name : '';
  //   let response = await utils.getGolferData(ghin_number, last_name);
  //   if (response == "error") {
  //     utils.usgaAdminLogin();
  //   }
  // }
  const registerFcmToken = (email: string, id: string, docId: string) => {

    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      async (token: Token) => {
        let mode;
        if (isPlatform('ios')) {
          mode = "ios"
        } else {
          mode = "android"
        }
        //alert('Device: '+ mode + ' Push registration success, token: ' + token.value);
        let user_token_info = {
          "email": email,
          "id": id,
          "fcm_token": token.value,
          "device": mode
        }
        //await createEntryWithoutId('USER_APP_TOKENS', user_token_info);
        await setDoc(doc(db, "USER_APP_TOKENS", docId) , user_token_info);

        //present('Push registration success', 2000);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        //alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
      }
    );
  }
  useEffect(() => {
    // localStorage.setItem('token','hello')
    // setUseremail('')
    let user = localStorage.getItem('firebase_user');
    if (user != null) {
      setEmail((JSON.parse(user)).email);
      let email = JSON.parse(user).email;
      let id = localStorage.getItem('user_id') || '';
      setId(id);
      if (localStorage.getItem("token") != null) {
        //checkToken(JSON.parse(user));
      } else {
        utils.usgaAdminLogin();
      }
      if (!(isPlatform('desktop') || isPlatform('mobileweb'))) {
        PushNotifications.requestPermissions().then(async (res: any) => {
          if (res.receive === 'denied') {
            //present('Push Notification permission denied', 2000);
          }
          else {
            //present('Push Notification permission granted', 2000);
            if (email !== "" && id !== "") {
              const getScores = query(collection(db, "USER_APP_TOKENS"), where("id", "==", id));
              const querySnapshot = await getDocs(getScores);
              let docId =  querySnapshot && querySnapshot.docs.length > 0 ? querySnapshot.docs[0].data().id :  await utils.randomString(20, '#aA');
              registerFcmToken(email, id, docId);
            }
          }
        });
      }
    }
  }, [email]);
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main" when="(min-width: 1640px)">
          {
            (email === '') ? (
              <>
              </>
            ) :
              (
                <Menu />
              )
          }
          <IonRouterOutlet id="main">
            <Route exact path="/">
              <Landing />
            </Route>
            <Route exact path="/questions">
              <Questions />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/register">
              <Register />
            </Route>
            <Route exact path="/profile">
              <Profile />
            </Route>
            <Route exact path="/locker_room">
              <LockerRoom />
            </Route>
            <Route exact path="/noticeboard">
              <Noticeboard />
            </Route>
            <Route exact path="/pro_shop">
              <Proshop />
            </Route>
            <Route exact path="/leagues/:tab">
              <Leagues />
            </Route>
            <Route exact path="/netscore_challenge_checkins">
              <NetScoreChallengeCheckins />
            </Route>
            <Route exact path="/netscore_challenge_create_checkin">
              <NetScoreChallengeCreateCheckin />
            </Route>
            <Route exact path="/netscore_challenge_leaderboards">
              <NetScoreChallengeLeaderboards />
            </Route>
            <Route exact path="/event_add_checkin/:id">
              <TAOEventAddCheckin />
            </Route>
            <Route exact path="/event_checkins/:id">
              <TAOEventCheckins />
            </Route>
            <Route exact path="/event_leaderboard/:id">
              <EventLeaderboard />
            </Route>
            <Route exact path="/twilightour_checkins/:id">
              <TwilighTourCheckins />
            </Route>
            <Route exact path="/twilightour_create_checkin/:id">
              <TwilighTourCreateCheckin />
            </Route>
            <Route exact path="/twilightour_leaderboards/:id">
              <TwilighTourLeaderboards />
            </Route>
            <Route exact path="/twilightour_one_on_oneleaderboards/:id">
              <TwilightTourOneOnOneLeaderboards />
            </Route>
            <Route exact path="/ppw_checkins">
              <PlayPostWinCheckins />
            </Route>
            <Route exact path="/ppw_leaderboards">
              <PlayPostWinLeaderboards />
            </Route>
            <Route exact path="/add_ppw_checkin">
              <PlayPostWinAddChekin />
            </Route>
            <Route exact path="/report_abuse">
              <ReportAbuse />
            </Route>
            <Route exact path="/notifications">
              <Notifications />
            </Route>
            <Route exact path="/match_invites">
              <MatchInvites />
            </Route>
            <Route exact path="/create_score">
              <CreateNewScore />
            </Route>
            <Route exact path="/create_post">
              <CreateNewPost />
            </Route>
            <Route exact path="/create_event">
              <AdminCreateEvent />
            </Route>
            <Route exact path="/event_settings">
              <EventSettings />
            </Route>
            <Route exact path="/admin_events">
              <AdminEvents />
            </Route>
            <Route exact path="/admin_championships">
              <AdminChampionships />
            </Route>
            <Route exact path="/join_pack">
              <JoinPack />
            </Route>
            <Route exact path="/create_pack">
              <CreatePack />
            </Route>
            <Route exact path="/connect_about">
              <ConnectAbout />
            </Route>
            <Route exact path="/get_handicap">
              <Handicap />
            </Route>
            <Route exact path="/create_tour">
              <CreateTour />
            </Route>
            <Route exact path="/create_tee_time">
              <CreateTeeTime />
            </Route>
            <Route exact path="/balances">
              <Balances />
            </Route>
            <Route exact path="/wallet">
              <Wallet />
            </Route>
            <Route exact path="/player_profile/:id">
              <PlayerProfile />
            </Route>
            <Route exact path="/my_pack">
              <MyPack />
            </Route>
            <Route exact path="/earnings">
              <Earnings />
            </Route>
            <Route exact path="/quota">
              <Quota />
            </Route>
            <Route exact path="/pack_tour_settings">
              <PackTourSettings />
            </Route>
            <Route exact path="/tours">
              <Tours />
            </Route>
            <Route exact path="/tour_details/:id">
              <TourDetails />
            </Route>
            <Route exact path="/event_details/:id">
              <EventDetails />
            </Route>
            {/* admin routes */}
            <Route exact path="/admin_orders">
              <Orders />
            </Route>
            <Route exact path="/admin_users">
              <Users />
            </Route>
            <Route exact path="/admin_user_logins">
              <UserLogins />
            </Route>
            <Route exact path="/admin_home">
              <AdminHome />
            </Route>
            <Route exact path="/admin_feed">
              <AdminFeed />
            </Route>
            <Route exact path="/admin_tours">
              <AdminTours />
            </Route>
            <Route exact path="/manage_pro_shop">
              <ManageProShop />
            </Route>
            <Route exact path="/user_registrations">
              <UserRegistrations />
            </Route>
            <Route exact path="/leaderboards">
              <AdminLeaderboards />
            </Route>
            <Route exact path="/event_admin_checkin/:id">
              <TAOEventAdminCheckin />
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  )
}
export default App;
