// Import the functions you need from the SDKs you need
import { Capacitor } from '@capacitor/core';
import { initializeApp } from "firebase/app";
import { indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAzC5Up-k85_XhgpxQf1ckd5RUT_lGpZHI",
  authDomain: "theamateuropen-v2.firebaseapp.com",
  databaseURL: "https://theamateuropen-v2.firebaseio.com",
  projectId: "theamateuropen-v2",
  storageBucket: "theamateuropen-v2.appspot.com",
  messagingSenderId: "129082884071",
  appId: "1:129082884071:web:499d5334e795a379f48742",
  measurementId: "G-SN78HDVH3N"
};

const devFirebaseConfig = {
  apiKey: "AIzaSyC5Vnb2Ol0qOd2_6OXsBzcKjIxqYwg1EkU",
  authDomain: "theamateuropen-dev-v2.firebaseapp.com",
  databaseURL: "https://theamateuropen-dev-v2.firebaseio.com",
  projectId: "theamateuropen-dev-v2",
  storageBucket: "theamateuropen-dev-v2.appspot.com",
  messagingSenderId: "885041672642",
  appId: "1:885041672642:web:2748ce696bc8739c5e265b",
  measurementId: "G-KH6XGYSWP6"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
if (Capacitor.isNativePlatform) {
  initializeAuth(firebase, {
    persistence: indexedDBLocalPersistence
  });
}
const analytics = getAnalytics(firebase);

export default firebase;
