import logo from "../../img/eclub.png";
import {
  IonToast,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonInput,
  IonRippleEffect,
  IonButton,
  IonText,
  IonGrid,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonCol,
  IonSelectOption,
  IonSelect,
  IonCheckbox,
  IonModal,
  IonButtons,
  IonIcon,
  IonCard,
  useIonToast,
  IonLoading,
} from "@ionic/react";
import "../../css/register.css";
import { useEffect, useState } from "react";
import { RefresherEventDetail } from "@ionic/core";
import { Redirect, Route, useHistory } from "react-router-dom";
import { chevronBackOutline } from "ionicons/icons";
import _ from "lodash";
import { format } from "date-fns";
import firebase from "../../js/firebase.js";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import {
  createEntryWithoutId,
  updateEntry,
} from "../../js/firebase_doc_functions";
import * as utils from "../../js/utils";

const db = getFirestore(firebase);
const auth = getAuth();

const Register: React.FC = () => {
  const history = useHistory();
  const [default_url, setDefaultUrl] = useState<any>(
    "https://members.eclub.golf/"
  );
  const [city, setCity] = useState<string>("");
  const [firstname, setFirstName] = useState<string>("");
  const [lastname, setLastName] = useState<string>("");
  const [user_email, setUserEmail] = useState<string>("");
  const [zip_code, setZipcode] = useState<any>();
  const [user_password, setUserPassword] = useState<string>("");
  const [confirm_password, setConfirmPassword] = useState<string>("");
  const [checked, setChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [all_regions, setAllRegions] = useState<any>([]);
  const [admin_user, setAdminUser] = useState<any>({});
  const [general_pack, setGeneralPack] = useState<any>({});
  const [showLoading, setShowLoading] = useState(false);

  const [showToast, hideToast] = useIonToast();
  const [showToast1, setShowToast1] = useState(false);
  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    console.log("Begin async operation");
    setTimeout(() => {
      console.log("Async operation has ended");
      event.detail.complete();
      window.location.href = "/register";
    }, 2000);
  }

  function isEmail(useremail: string) {
    var regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(useremail);
  }

  const getRegions = async () => {
    var condition = where("created_at", ">", 0);
    const q = query(collection(db, "REGIONS"), condition);

    const querySnapshot = await getDocs(q);
    var records = new Array();
    querySnapshot.docs.forEach((doc) => {
      let doc_item = doc.data();
      doc_item.id = doc.id;
      records.push(doc_item);
    });
    setAllRegions(records);
  };

  const getAdminUser = async () => {
    var condition = where("is_portal_admin", "==", true);
    const q = query(collection(db, "USER_PROFILE"), condition);

    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs && querySnapshot.docs.length > 0) {
      let doc_item = querySnapshot.docs[0].data();
      doc_item.id = querySnapshot.docs[0].id;
      setAdminUser(doc_item);
    } else {
      setAdminUser({});
    }
  };

  const getGeneralPack = async () => {
    var condition = where("pack_name", "==", "General");
    const q = query(collection(db, "PACKS"), condition);

    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs && querySnapshot.docs.length > 0) {
      let doc_item = querySnapshot.docs[0].data();
      doc_item.id = querySnapshot.docs[0].id;
      setGeneralPack(doc_item);
    } else {
      setGeneralPack({});
    }
  };

  const registerNewUser = async () => {
    setShowLoading(true);
    let userData = {
        first_name: firstname,
        last_name: lastname,
        email: user_email,
        password: user_password,
        confirm_password: confirm_password,
        address_city: city.length > 0 ? city : "",
        zip_code: 0,
      },
      validate = false;
    Object.keys(userData).forEach((key) => {
      if (key == "") {
        validate = true;
      }
    });
    if (
      userData.address_city == "" ||
      userData.address_city == undefined ||
      userData.address_city == null
    ) {
      setShowLoading(false);
      alert("Please select a city");
      return;
    }
    if (
      userData.address_city == "Other" &&
      new RegExp(/^\d{5,6}$/g).test(zip_code)
    ) {
      userData.zip_code = parseInt(zip_code);
    } else if (userData.address_city != "Other") {
      userData.zip_code = 0;
    } else if (
      userData.address_city == "Other" &&
      new RegExp(/^\d{5,6}$/g).test(zip_code) == false
    ) {
      setShowLoading(false);
      alert("Zip code is not valid. Please retry.");
      return;
    }
   
    if (validate) {
      setShowLoading(false);
      alert("All fields are required");
    } else if (!isEmail(userData.email)) {
      setShowLoading(false);
      alert("Please enter a valid email to signup");
    } else if (userData.password !== userData.confirm_password) {
      setShowLoading(false);
      alert("Password and Confirm Password does not match");
    } else {
      userData.email = userData.email.toLowerCase().trim();
      userData.password = userData.password.trim();
      let region = all_regions.filter((x: any) =>
        x.cities.includes(userData.address_city)
      );

      if (region.length > 0) {
        let referral_id = await utils.randomString(8, "#aA");
        let coupon_code = await utils.randomString(8, "#aA");
        await createUserWithEmailAndPassword(auth, user_email, user_password)
          .then((userCredential) => {
            // Signed in
            createEntryWithoutId("USER_PROFILE", {
              user_id: userCredential.user.uid,
              first_name: userData.first_name,
              last_name: userData.last_name,
              email: userData.email,
              city: userData.address_city,
              ghin_number: 0,
              status: "Active",
              ghin_validated: false,
              zip_code: userData.zip_code,
              packs: [
                {
                  pack_name: general_pack.pack_name,
                  pack_id: general_pack.pack_id,
                },
              ],
              referral_id: referral_id,
              auto_validate: true,
              adjustment_percentage: 80,
              quota_adjustment_percentage: 75,
              ppw_exceptional_score_adjustment: 0,
              ppw_validated: false,
              tt_validated: false,
              exceptional_score_adjustment: 0,
              chips: 0,
              earnings: 0,
              total_earnings: 0,
              region_id: region[0].region_id,
              region_name: region[0].region_name,
              tao_validated: false,
              btb_validated: false,
              is_portal_admin: false,
              is_region_admin: false,
            });
            createEntryWithoutId("USER_COUPONS", {
              coupon_code: coupon_code,
              coupon_value: 0,
              ppw_coupon_value: 0,
              ppw_redeemed: false,
              redeemed: false,
              tao_coupon_value: 0,
              tao_redeemed: false,
              tt_coupon_value: 0,
              tt_redeemed: false,
              user_doc_id: userCredential.user.uid,
              user_id: userCredential.user.uid,
              user_name: userData.first_name + " " + userData.last_name,
            });
            let source = {
              id: userCredential.user.uid,
              name: userData.first_name + " " + userData.last_name,
              date: new Date().getTime(),
              image_url: "",
              ghin_number: "",
              email: userData.email,
              city: userData.address_city,
              region_id: region[0].region_id,
              region_name: region[0].region_name,
            };
            createEntryWithoutId("NOTIFICATIONS", {
              type: "REGISTRATION",
              created_name: userData.first_name + " " + userData.last_name,
              created_by: userCredential.user.uid,
              user_id: admin_user.user_id,
              user_name: "Portal Admin",
              source: source,
              pack_id: general_pack.pack_id,
              pack_name: general_pack.pack_name,
              is_read: false,
            });
            general_pack.players.push({
              user_name: userData.first_name + " " + userData.last_name,
              user_id: userCredential.user.uid,
            });
            updateEntry("PACKS", general_pack.id, {
              players: general_pack.players,
            });
            const actionCodeSettings = {
              url: default_url,
              handleCodeInApp: true,
            };
            sendEmailVerification(userCredential.user, actionCodeSettings).then(
              function () {
                signOut(auth);
                setShowLoading(false);
                showToast(
                  "An email has been sent with link to verify your account",
                  3000
                );
                setTimeout(function () {
                  history.push("/login");
                }, 3500);
              }
            );
          })
          .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            setShowLoading(false);
            showToast(error.code.toString(), 2000);
          });
      } else {
        let referral_id = await utils.randomString(8, "#aA");
        let coupon_code = await utils.randomString(8, "#aA");
        await createUserWithEmailAndPassword(auth, user_email, user_password)
        .then((userCredential) => {
          // Signed in
          createEntryWithoutId("USER_PROFILE", {
            user_id: userCredential.user.uid,
            first_name: userData.first_name,
            last_name: userData.last_name,
            email: userData.email,
            city: userData.address_city,
            ghin_number: 0,
            status: "Active",
            ghin_validated: false,
            zip_code: userData.zip_code,
            packs: [
              {
                pack_name: general_pack.pack_name,
                pack_id: general_pack.pack_id,
              },
            ],
            referral_id: referral_id,
            auto_validate: true,
            adjustment_percentage: 80,
            quota_adjustment_percentage: 75,
            ppw_exceptional_score_adjustment: 0,
            ppw_validated: false,
            tt_validated: false,
            exceptional_score_adjustment: 0,
            chips: 0,
            earnings: 0,
            total_earnings: 0,
            region_id: '',
            region_name: '',
            tao_validated: false,
            btb_validated: false,
            is_portal_admin: false,
            is_region_admin: false,
          });
          createEntryWithoutId("USER_COUPONS", {
            coupon_code: coupon_code,
            coupon_value: 0,
            ppw_coupon_value: 0,
            ppw_redeemed: false,
            redeemed: false,
            tao_coupon_value: 0,
            tao_redeemed: false,
            tt_coupon_value: 0,
            tt_redeemed: false,
            user_doc_id: userCredential.user.uid,
            user_id: userCredential.user.uid,
            user_name: userData.first_name + " " + userData.last_name,
          });
          let source = {
            id: userCredential.user.uid,
            name: userData.first_name + " " + userData.last_name,
            date: new Date().getTime(),
            image_url: "",
            ghin_number: "",
            email: userData.email,
            city: userData.address_city,
            region_id: '',
            region_name: '',
          };
          createEntryWithoutId("NOTIFICATIONS", {
            type: "REGISTRATION",
            created_name: userData.first_name + " " + userData.last_name,
            created_by: userCredential.user.uid,
            user_id: admin_user.user_id,
            user_name: "Portal Admin",
            source: source,
            pack_id: general_pack.pack_id,
            pack_name: general_pack.pack_name,
            is_read: false,
          });
          general_pack.players.push({
            user_name: userData.first_name + " " + userData.last_name,
            user_id: userCredential.user.uid,
          });
          updateEntry("PACKS", general_pack.id, {
            players: general_pack.players,
          });
          const actionCodeSettings = {
            url: default_url,
            handleCodeInApp: true,
          };
          sendEmailVerification(userCredential.user, actionCodeSettings).then(
            function () {
              signOut(auth);
              setShowLoading(false);
              showToast(
                "An email has been sent with link to verify your account",
                3000
              );
              setTimeout(function () {
                history.push("/login");
              }, 3500);
            }
          );
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          setShowLoading(false);
          showToast(error.code.toString(), 2000);
        });
      }
    }
  };

  const customAlertOptions = {
    header: "Select a City",
    translucent: true,
  };
  useEffect(() => {
    let user = localStorage.getItem("firebase_user");
    if (user != null) {
      window.location.href = "/leagues/formats";
    } else {
      getRegions();
      getAdminUser();
      getGeneralPack();
    }
  }, []);

  return (
    <>
      <IonGrid className="background-image ion-padding pad-l">
        <IonRow className="vh-5">
          <img src={logo} className="logo" />
        </IonRow>
        <IonRow className="transparent ion-justify-content-center r-land">
          <IonCol className="pad-0" size={city === "Other" ? "6" : "12"}>
            <IonItem
              lines="none"
              className={
                city === "Other"
                  ? "register-fields item-bg float-right"
                  : "register-row-fields item-bg"
              }
              style={{ height: "40px" }}
            >
              <IonSelect
                value={city}
                interfaceOptions={customAlertOptions}
                interface="popover"
                placeholder="Select a City"
                className="login-input wid-100 select-box"
                onIonChange={(e) => setCity(e.detail.value)}
                mode="ios"
              >
                <IonSelectOption value="Kansas City">
                  Kansas City Metro
                </IonSelectOption>
                <IonSelectOption value="Port St. Lucie">
                  Port St. Lucie, FL
                </IonSelectOption>
                <IonSelectOption value="St. Louis">
                  St. Louis, MO
                </IonSelectOption>
                <IonSelectOption value="Des Moines">
                  Des Moines, IA
                </IonSelectOption>
                <IonSelectOption value="Wichita">Wichita, KS</IonSelectOption>
                <IonSelectOption value="Salt Lake City">
                  Salt Lake City, UT
                </IonSelectOption>
                <IonSelectOption value="Topeka">Topeka, KS</IonSelectOption>
                <IonSelectOption value="Salina">Salina, KS</IonSelectOption>
                <IonSelectOption value="Manhattan">
                  Manhattan, KS
                </IonSelectOption>
                <IonSelectOption value="Hutchinson">
                  Hutchinson, KS
                </IonSelectOption>
                <IonSelectOption value="Lawrence">Lawrence, KS</IonSelectOption>
                <IonSelectOption value="Leavenworth">
                  Leavenworth, KS
                </IonSelectOption>
                <IonSelectOption value="Denver">Denver, CO</IonSelectOption>
                <IonSelectOption value="Other">Other</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
          {city === "Other" ? (
            <IonCol className="pad-0" size="6">
              <IonItem
                lines="none"
                className="register-fields item-bg float-left"
                style={{ height: "40px" }}
              >
                <IonInput
                  type="text"
                  value={zip_code}
                  onKeyUp={(e: any) => {
                    setZipcode(e.target.value);
                  }}
                  className="login-input"
                  placeholder="zip code"
                ></IonInput>
              </IonItem>
            </IonCol>
          ) : (
            <></>
          )}
        </IonRow>
        <IonRow className="transparent ion-justify-content-center mt-5">
          <IonCol className="pad-0">
            <IonItem
              lines="none"
              className="register-fields item-bg float-right"
              style={{ height: "40px" }}
            >
              <IonInput
                type="text"
                value={firstname}
                onKeyUp={(e: any) => {
                  setFirstName(e.target.value);
                }}
                className="login-input"
                placeholder="first name"
              ></IonInput>
            </IonItem>
          </IonCol>
          <IonCol className="pad-0">
            <IonItem
              lines="none"
              className="register-fields item-bg float-left"
              style={{ height: "40px" }}
            >
              <IonInput
                type="text"
                value={lastname}
                onKeyUp={(e: any) => {
                  setLastName(e.target.value);
                }}
                className="login-input"
                placeholder="last name"
              ></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow className="transparent ion-justify-content-center mt-5">
          <IonItem
            lines="none"
            className="register-row-fields item-bg"
            style={{ height: "40px" }}
          >
            <IonInput
              type="email"
              value={user_email}
              onIonChange={(e: any) => {
                setUserEmail(e.target.value);
              }}
              className="login-input"
              placeholder="email"
            ></IonInput>
          </IonItem>
        </IonRow>
        <IonRow className="transparent ion-justify-content-center mt-5">
          <IonCol className="pad-0">
            <IonItem
              lines="none"
              className="register-fields item-bg float-right"
              style={{ height: "40px" }}
            >
              <IonInput
                type="password"
                value={user_password}
                onIonChange={(e: any) => {
                  setUserPassword(e.target.value);
                }}
                className="login-input"
                placeholder="password"
              ></IonInput>
            </IonItem>
          </IonCol>
          <IonCol className="pad-0">
            <IonItem
              lines="none"
              className="register-fields item-bg float-left"
              style={{ height: "40px" }}
            >
              <IonInput
                type="password"
                value={confirm_password}
                onKeyUp={(e: any) => {
                  setConfirmPassword(e.target.value);
                }}
                className="login-input"
                placeholder="confirm password"
              ></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow className="ion-justify-content-center ion-text-center h-login mt-15 mb-10">
          <IonCheckbox
            mode="md"
            checked={checked}
            onIonChange={(e) => setChecked(e.detail.checked)}
            className="inner-checkbox"
            style={{ marginRight: "5px" }}
          />
          <IonText className="color-black"> I agree to the </IonText>
          <IonText
            className="pl-5 color-blue"
            onClick={() => {
              console.log("terms and conditions");
              setShowModal(true);
            }}
          >
            terms &amp; conditions
          </IonText>
        </IonRow>
        <div className="">
          <a
            className="full-width"
            style={{ textDecoration: "none" }}
            onClick={registerNewUser}
          >
            <IonButton
              className="family-done-button-1 family-send wid ion-activatable ripple-parent1"
              color="#00365f"
            >
              <IonRippleEffect></IonRippleEffect>
              <IonText className="family-next-2">Sign Up</IonText>
            </IonButton>
          </a>
        </div>
        <IonRow className="ion-justify-content-center ion-text-center mt-10">
          <IonText className="color-black">Already signed up? </IonText>
          <IonText
            className="pl-5 color-blue"
            onClick={() => {
              window.location.href = "/login";
            }}
          >
            {" "}
            Sign in
          </IonText>
        </IonRow>
        <IonLoading isOpen={showLoading} message={"Signing up.."} />
      </IonGrid>
      <IonModal isOpen={showModal} className="my-custom-class">
        <>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <a onClick={() => setShowModal(false)}>
                  <IonIcon
                    className="well-back-icon"
                    icon={chevronBackOutline}
                  />
                </a>
              </IonButtons>
              <IonText>Terms &amp; Conditions</IonText>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid className="">
              <IonRow className="ion-justify-content-center ion-text-center mt-10">
                <IonText className="heading-terms">Media Disclaimer</IonText>
              </IonRow>
              <IonRow className="ion-padding">
                <IonText className="text-terms">
                  eClubgolf reserve the right to use the name and scoring record
                  of the players, without the expressed written permission of
                  those players. eClubgolf may use the name and scoring record
                  in publications or other media material produced, used or
                  contracted by eClubgolf including but not limited to:
                  brochures, invitations, books, newspapers, magazines,
                  television, websites, etc.
                </IonText>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-25">
                <IonText className="heading-terms">
                  Eligibility Disclaimer
                </IonText>
              </IonRow>
              <IonRow className="ion-padding">
                <IonText className="text-terms">
                  By entering eClubgolf competitions, I agree to certify my
                  eligibility, if requested to eClubgolf Championship Committee.
                  eClubgolf may require proof of age, proof of residence or
                  proof of playing ability for any or all competitions.
                  Participants are responsible for knowing all entry
                  requirements for eClubgolf events. Any participant that has a
                  question regarding event eligibility should contact
                  info@eclub.golf{" "}
                </IonText>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-25">
                <IonText className="heading-terms">
                  Liability Disclaimer
                </IonText>
              </IonRow>
              <IonRow className="ion-padding">
                <IonText className="text-terms">
                  eClubgolf, its agents, officers, and directors hereby disclaim
                  any liability including but not limited to the loss related to
                  theft, property damage, personal injury and death, suffered by
                  the undersigned, of their heirs, executors, administrators,
                  and assigns as a result or in any manner connected with
                  his/her participation in any activities conducted by
                  eClubgolf, regardless of the cause. By signing this
                  disclaimer, and in consideration for being permitted on and/or
                  participating in any activities conducted by eClubgolf and the
                  undersigned, his/her heirs, executors, administrators, and
                  assigns agrees not to prosecute or in any way aid in the
                  prosecution of any claim, demand, action, cause of action for
                  damages, costs, damage, personal injury or death suffered by
                  the undersigned, regardless of cause, against eClubgolf, its
                  agents, officers, and directors. Furthermore, by signing this
                  disclaimer, the undersigned attests that he/she is at least 21
                  years of age and that he/she has read this document and
                  understands its terms and legal ramifications.{" "}
                </IonText>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center mt-10"></IonRow>
              <IonRow className="ion-padding">
                <IonText className="text-terms">
                  WE ARE FULLY AWARE OF THE RISKS ASSOCIATED WITH GOLF,
                  INCLUDING THE POSSIBLE CONDUCT OF OTHER PARTICIPANTS. WE HAVE
                  CAREFULLY READ THIS AGREEMENT; AGREE TO ACCEPT ANY AND ALL
                  INHERENT RISKS ASSOCIATED WITH PARTICIPATION.{" "}
                </IonText>
              </IonRow>
            </IonGrid>
          </IonContent>
        </>
      </IonModal>
      {/* <IonContent>
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message="Login Success"
        duration={2000}
      />
      <IonGrid className="pad-0 background-white" style={{height:"100%"}}>
        <img src={bg} className='background-image2'/>
        <div className="mar">
          <IonItem className="user" lines="none">
            <IonSelect value={city} 
            interfaceOptions={customAlertOptions}
            interface="popover"
              placeholder="Select a City" className="emailInput wid-100 select-box" onIonChange={e => setCity(e.detail.value)} mode="ios">
              <IonSelectOption value="Kansas City">Kansas City Metro</IonSelectOption>
              <IonSelectOption value="St. Louis">St. Louis, MO</IonSelectOption>
              <IonSelectOption value="Des Monies">Des Monies, IA</IonSelectOption>
              <IonSelectOption value="Wichita">Wichita, KS</IonSelectOption>
              <IonSelectOption value="Salt Lake City">Salt Lake City, UT</IonSelectOption>
              <IonSelectOption value="Topeka">Topeka, KS</IonSelectOption>
              <IonSelectOption value="Salina">Salina, KS</IonSelectOption>
              <IonSelectOption value="Manhattan">Manhattan, KS</IonSelectOption>
              <IonSelectOption value="Hutchinson">Hutchinson, KS</IonSelectOption>
              <IonSelectOption value="Lawrence">Lawrence, KS</IonSelectOption>
              <IonSelectOption value="Leavenworth">Leavenworth, KS</IonSelectOption>
              <IonSelectOption value="Denver">Denver, CO</IonSelectOption>
              <IonSelectOption value="Other">Other</IonSelectOption>
            </IonSelect>
          </IonItem>
        </div>
        <IonRow>
          <IonCol className="pad-0">
            <div className="mar mt-10">
              <IonItem className="pass mrl-3" lines="none">
                <IonInput type="text" value={firstname} onKeyUp={(e: any)=> {setFirstName(e.target.value)}} className="emailInput" placeholder="First Name"></IonInput>
              </IonItem>
            </div>
          </IonCol>
          <IonCol className="pad-0">
            <div className="mar mt-10">
              <IonItem className="pass mar1" lines="none">
                <IonInput type="text" value={lastname} onKeyUp={(e: any)=> {setLastName(e.target.value)}} className="emailInput" placeholder="Last Name"></IonInput>
              </IonItem>
            </div>
          </IonCol>
        </IonRow>
        <div className="mar mt-10">
          <IonItem className="pass" lines="none">
            <IonInput type="email" value={email} onKeyUp={(e: any)=> {setEmail(e.target.value)}} className="emailInput" placeholder="Email"></IonInput>
          </IonItem>
        </div>
        <IonRow>
          <IonCol className="pad-0" size="5.5">
            <div className="mar mt-10">
              <IonItem className="pass mrl-3" lines="none">
                <IonInput type="password" value={password} onKeyUp={(e: any)=> {setPassword(e.target.value)}} className="emailInput" placeholder="Password"></IonInput>
              </IonItem>
            </div>
          </IonCol>
          <IonCol className="pad-0" size="6.5">
            <div className="mar mt-10">
              <IonItem className="pass mar1" lines="none">
                <IonInput type="password" value={confirm_password} onKeyUp={(e: any)=> {setConfirmPassword(e.target.value)}} className="emailInput" placeholder="Confirm Password"></IonInput>
              </IonItem>
            </div>
          </IonCol>
        </IonRow>
        <IonRow className="ion-justify-content-center ion-text-center h-login mt-10">
          <IonItem lines="none" className="checkbox">
            <IonCheckbox mode="md" checked={checked} onIonChange={e => setChecked(e.detail.checked)} className="inner-checkbox"/>
          </IonItem>
          <div className="mtv-5">
            <IonText className="color-black"> I agree to the </IonText><IonText className="pl-5 color-blue" onClick={()=>{
                console.log("terms and conditions")
                setShowModal(true)
              }}>terms &amp; conditions
            </IonText>
          </div>
        </IonRow>
        <div className="">
          <a className="full-width" style={{textDecoration: "none"}} onClick={registerNewUser}>
            <IonButton className="family-done-button-1 family-send wid ion-activatable ripple-parent1" color="#00365f">
              <IonRippleEffect></IonRippleEffect>
                <IonText className="family-next-2" onClick={registerNewUser}>
                  Sign Up
                </IonText>    
            </IonButton>
          </a>
        </div>
        <IonRow className="ion-justify-content-center ion-text-center mt-10">
          <IonText className="color-black">Already signed up? </IonText> 
          <IonText className="pl-5 color-blue" onClick={()=>{window.location.href="/login"}}> Sign in</IonText>
        </IonRow>
      </IonGrid>
      <IonModal isOpen={showModal} className='my-custom-class'>
        <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <a onClick={()=> setShowModal(false)}>
                <IonIcon className="well-back-icon" icon={chevronBackOutline}/>
              </a>
            </IonButtons>
            <IonTitle>
              Terms &amp; Conditions
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid className="">
            <IonRow className="ion-justify-content-center ion-text-center mt-10">
              <IonText className="heading-terms">Media Disclaimer</IonText>
            </IonRow>
            <IonRow className="ion-padding">
              <IonText className="text-terms">eClubgolf reserve the right to use the name and scoring record of the players, without the expressed written permission of those players. eClubgolf may use the name and scoring record in publications or other media material produced, used or contracted by eClubgolf including but not limited to: brochures, invitations, books, newspapers, magazines, television, websites, etc.</IonText>
            </IonRow>
            <IonRow className="ion-justify-content-center ion-text-center mt-25">
              <IonText className="heading-terms">Eligibility Disclaimer</IonText>
            </IonRow>
            <IonRow className="ion-padding">
              <IonText className="text-terms">By entering eClubgolf competitions, I agree to certify my eligibility, if requested to eClubgolf Championship Committee.  eClubgolf may require proof of age, proof of residence or proof of playing ability for any or all competitions.  Participants are responsible for knowing all entry requirements for eClubgolf events.  Any participant that has a question regarding event eligibility should contact info@eclub.golf </IonText>
            </IonRow>
            <IonRow className="ion-justify-content-center ion-text-center mt-25">
              <IonText className="heading-terms">Liability Disclaimer</IonText>
            </IonRow>
            <IonRow className="ion-padding">
              <IonText className="text-terms">eClubgolf, its agents, officers, and directors hereby disclaim any liability including but not limited to the loss related to theft, property damage, personal injury and death, suffered by the undersigned, of their heirs, executors, administrators, and assigns as a result or in any manner connected with his/her participation in any activities conducted by eClubgolf, regardless of the cause. By signing this disclaimer, and in consideration for being permitted on and/or participating in any activities conducted by eClubgolf and the undersigned, his/her heirs, executors, administrators, and assigns agrees not to prosecute or in any way aid in the prosecution of any claim, demand, action, cause of action for damages, costs, damage, personal injury or death suffered by the undersigned, regardless of cause, against eClubgolf, its agents, officers, and directors. Furthermore, by signing this disclaimer, the undersigned attests that he/she is at least 21 years of age and that he/she has read this document and understands its terms and legal ramifications. </IonText>
            </IonRow>
            <IonRow className="ion-justify-content-center ion-text-center mt-10">
            </IonRow>
            <IonRow className="ion-padding">
              <IonText className="text-terms">WE ARE FULLY AWARE OF THE RISKS ASSOCIATED WITH GOLF, INCLUDING THE POSSIBLE CONDUCT OF OTHER PARTICIPANTS. WE HAVE CAREFULLY READ THIS AGREEMENT; AGREE TO ACCEPT ANY AND ALL INHERENT RISKS ASSOCIATED WITH PARTICIPATION. </IonText>
            </IonRow>
          </IonGrid>
        </IonContent>
        </>
      </IonModal>

    </IonContent> */}
    </>
  );
};

export default Register;
