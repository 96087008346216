import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonIcon,IonLabel,IonButtons, IonGrid, IonFab, IonFabButton, IonCol, IonItem, IonRow, IonText, IonInput, IonDatetime, IonModal, IonSearchbar, IonList, IonSegment, IonSegmentButton, useIonToast, IonLoading, useIonAlert } from '@ionic/react';
import { arrowBackOutline, chevronBackOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import EditTable from '../Components/tt_score_table'
import IntlTtEditTable from '../Components/intl_tt_score_table';
import * as utils from '../../js/utils';
import { createEntryWithoutId, getEntry, updateEntry } from '../../js/firebase_doc_functions';
import firebase from '../../js/firebase.js';
import { useParams } from "react-router";
import { format } from 'date-fns';

import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import _, { ceil } from 'lodash';

const db = getFirestore(firebase);
const season_name =localStorage.getItem("season_name");
const TwilighTourCreateCheckin: React.FC = () => {
    let {id}:any = useParams(); 
    const [tour_id, setTourId] = useState<any>('');
    const [current_user, setCurrentUser] = useState<any>([]);
    const [select_state,setSelectState] = useState<any>([])    
    const [course, setCourse] = useState<any>('');
    const [course_id, setCourseId] = useState<any>();
    const [course_list, setCourseList] = useState<any>([]);
    const [tt_score_id, setTtScoreId] = useState<any>([]);
    const [set_disabled_date, setDisabledDate] = useState<boolean>(false);
    const [front_holes, setFrontHoles] = useState<boolean>(true);
    const [back_holes, setBackHoles] = useState<boolean>(false);
    const [tees_list, setTeesList] = useState<any>([]);
    const [presentToast, dismissToast] = useIonToast();
    const [all_users, setAllUsers] = useState<any>();
    const [holes, setHoles] = useState<any>();
    const [select_tees,setSelectTees] = useState("")
    const [selected_date, setSelectedDate] = useState<string>(format(new Date(), "MM/dd/yyyy"));
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selected_time, setSelectedTime] = useState<any>(format(new Date().getTime(), "HH:mm"));
    const [showTimePicker, setShowTimePicker] = useState(false);
    const [selected_player, setSelectedPlayer] = useState<string>('');
    const [selected_player_id, setSelectedPlayerId] = useState<string>('');
    const [teeset_rating_id, setTeesetRatingId] = useState<number>();
    const [teeset_rating_name, setTeesetRatingName] = useState<string>('');
    const [teeset_rating_gender, setTeesetRatingGender] = useState<string>('');
    const [slope_rating, setSlopeRating] = useState<number>();
    const [presentAlert] = useIonAlert();
    const [course_rating, setCourseRating] = useState<number>();
    const [par, setPar] = useState<number>();
    const [yards, setYards] = useState<number>();
    const [gender, setGender] = useState<string>('');
    const [ppw_score_modal, setPpwScoreModal] = useState(false);
    const [score_record, setScoreRecord] = useState<any>([]);
    const [edit_hole_details, setEditHoleDetails] = useState<any>([]);
    const [playing_quota, setPlayingQuota] = useState<number>(0);
    const [available_tees, setAvailableTees] = useState<any>();
    const [tour, setTour] = useState<any>();
    const [tour_format, setTourFormat] = useState<any>();
    const [tour_players, setTourPlayers] = useState<any>([]);
    const [pull_scores_from, setPullScoresFrom] = useState<any>();
    const [fixed_courses, setFixedCourses] = useState<any>();
    const [tee_type, setTeetype] = useState<any>('');
    const [partner_required, setPartnerRequired] = useState<any>();
    const [tour_courses, setTourCourses] = useState<any>([]);
    const [tour_name, setTourName] = useState<any>();
    const [hole_points, setHolePoints] = useState<any>();
    const [searchText,setSearchText] = useState<any>("");
    const [score_id,setScoreId] = useState<any>();
    const [showLoading, setShowLoading] = useState(false);
    const [showCourseLoading, setShowCourseLoading] = useState(false);
    const [showTeesLoading, setShowTeesLoading] = useState(false);
    const [target_tees, setTargetTees] = useState<any>();
    const inputRef = useRef<any>(null);
    const[showStartLoading,setShowStartLoading]=useState(false);
    const [course_display_value, setCourseDisplayValue] = useState<any>();
    const [country_name, setCountryName] = useState<string>('');
    const [city_name, setCityName] = useState<string>('');
    const [sel_tees, setSelTees] = useState<string>('');
    const [course_name, setCourseName] = useState<string>('');
    const [intl_score_modal, setIntlScoreModal] = useState<boolean>(false);
    const customAlertOptions = {
      header: 'Select State',
      translucent: true
    };
    const customAlertOptions1 = {
        header: 'Select Course',
    };
    const customAlertOptions2 = {
        header: 'Select Tees(Course/Slope/Par)',
    };

    const customAlertOptions3 = {
        header: 'Playing with',
    };

    const getCourses = async(state:string)=> {
        setShowCourseLoading(true);
        let course_list = await utils.getCourseList(state) || [];
        setCourseList(course_list);
        setNewCourses(course_list);
        setShowCourseLoading(false);
        setShowCourseModal(true);
    }

    const getFixedCourses = async(current_tour:any)=> {
        let course_list = await utils.getCourseList(current_tour.state) || [];
        let courseArray = new Array();
        if(current_tour.fixed_courses == "Yes" && current_tour.selected_courses && current_tour.selected_courses.length > 0) {
            current_tour.selected_courses.forEach((course_name:any)=> {
                let filteredCourse = course_list.filter((x:any)=> x.FullName == course_name);
                courseArray.push(filteredCourse[0]);
            });
            setCourseList(courseArray);
            setNewCourses(courseArray);
        } else {
            setCourseList(course_list);
            setNewCourses(course_list);
        }
    }

    const handleCallback = async(data:any) => {
        if(data == "Done") {
            presentToast('Thank you for adding score.', 2000);
            window.location.href="/twilightour_checkins/"+tour_id;
        } else {
            presentToast('Thank you for adding score.', 2000);
            window.location.href="/leagues/formats";
        }
        
    }

    const getTourData = async(tour_id:string) => {
        setShowLoading(true);
        const querySnapshot = await getEntry("TOUR_SETTINGS", tour_id);
        if(querySnapshot.created_at) {
            let doc_item = querySnapshot;
            doc_item.id = tour_id;
            setTour(doc_item);
            setNewPlayers(doc_item.players.filter((x:any)=> x.user_id !== current_user.user_id));
            if(doc_item.holes == 9) {
                setHoles(9);
            } else {
                setHoles(18);
            }
            setDisabledDate(doc_item.format == "1~Peoria based" ? true : false);
            setTourFormat(doc_item.format);
            setPullScoresFrom(doc_item.pull_scores_from);
            setTourCourses(doc_item.selected_courses);
            setTourName(doc_item.tour_name);
            setPartnerRequired(doc_item.partner_required);
            setFixedCourses(doc_item.fixed_courses);
            setTourPlayers(doc_item.players.filter((x:any)=> x.user_id !== current_user.user_id));
            if(doc_item.fixed_courses && doc_item.fixed_courses == "Yes") {
                let course_list = await utils.getCourseList(doc_item.state) || [];
                let courseArray = new Array();
                if(doc_item.fixed_courses == "Yes" && doc_item.selected_courses && doc_item.selected_courses.length > 0) {
                    doc_item.selected_courses.forEach((course_name:any)=> {
                        let filteredCourse = course_list.filter((x:any)=> x.FullName == course_name);
                        courseArray.push(filteredCourse[0]);
                    });
                    setCourseList(courseArray);
                    setNewCourses(courseArray);
                } else {
                    setCourseList(course_list);
                    setNewCourses(course_list);
                }
            }
            setShowLoading(false);
        } else {
            setShowLoading(false);
        }
    }

    const getUsers = async() => {
        const q = query(collection(db, "USER_PROFILE"), where('created_at', '>', 0));
    
        const querySnapshot = await getDocs(q);
        var records = new Array();
        querySnapshot.forEach((doc) => {
            let doc_item = doc.data();
            doc_item.id = doc.id;
            records.push(doc_item);
        });
        records = records.filter((x:any)=> x.user_id !== current_user.user_id);
        setAllUsers(records);
    }

    const getTees = async(course_id:number, holes:number)=> {
        if(course_id > 0) {
            setShowTeesLoading(true);
            let tees = await utils.getCourseDetails(course_id) || [];
            var tee_sets = new Array();
            let finalTees = current_user.gender === "Male" ? tees.filter((x:any)=> x.Gender === "Male") : tees.filter((x:any)=> x.Gender === "Female");
            let sortedTees =  _.sortBy(finalTees, (e: any) => e.TotalMeters).reverse();
            setAvailableTees(sortedTees);
            sortedTees && sortedTees.forEach((tee:any)=>{
                if(holes == 18){
                    let rating = tee.Ratings.filter((rating:any)=> rating.RatingType == 'Total');
                    let pars = tee.Holes.map((hole:any)=> {return hole.Par}),
                    par = pars.reduce((a:number, b:number) => a + b, 0);
                    if(tee.HolesNumber == 18){
                        tee_sets.push({'teeName': tee.TeeSetRatingName+'~('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')', 
                        'teeValue': tee.TeeSetRatingName+'~('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                    });
                    }
                } else if(holes == 9) {
                    let rating = tee.Ratings.filter((rating:any)=> rating.RatingType == 'Total');
                    let nineHoleRatings = tee.Ratings.filter((rating:any)=> rating.RatingType != 'Total');
                    let pars = tee.Holes.map((hole:any)=> {return hole.Par});
                    if(tee.HolesNumber == 9){
                    let pars = tee.Holes.map((hole:any)=> {return hole.Par}),
                    par = pars.reduce((a:number, b:number) => a + b, 0);
                    tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+rating[0].RatingType+'~('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')', 
                        'teeValue': tee.TeeSetRatingName+'~'+rating[0].RatingType+'~('+rating[0].CourseRating+'/'+rating[0].SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                    });
                    
                    } else {
                        for (rating of nineHoleRatings) {
                            var front9Holes = tee.Holes.filter((hole:any)=> hole.Number <= 9);
                            var back9Holes = tee.Holes.filter((hole:any)=> hole.Number > 9);
                            if(rating.RatingType == 'Front'){
                                let pars = front9Holes.map((hole:any)=> {return hole.Par}),
                                par = pars.reduce((a:number, b:number) => a + b, 0);
                                tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')', 
                                    'teeValue': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                                });
                            
                            }
                            if(rating.RatingType == 'Back'){
                                let pars = back9Holes.map((hole:any)=> {return hole.Par}),
                                par = pars.reduce((a:number, b:number) => a + b, 0);
                                tee_sets.push({'teeName': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')', 
                                    'teeValue': tee.TeeSetRatingName+'~'+rating.RatingType+'~('+rating.CourseRating+'/'+rating.SlopeRating+'/'+par+')~'+tee.TeeSetRatingId
                                });
                            
                            }
                        }
                    }
                } 
            });
            setTeesList(tee_sets);
            setNewTees(tee_sets);
            setShowTeesLoading(false);
            //setShowTeesModal(true);
        } else {
            presentToast("Please select a state and course.", 2000);
        }
    }

    const getTeeDetails = async(courseid:number, holes:number, tee:string)=> {
        let currentTeeValues = tee.split("~");
        setTeesetRatingId(holes == 18 ? parseInt(currentTeeValues[2]) : parseInt(currentTeeValues[3]));
        setTeesetRatingName(currentTeeValues[0]);
        setTeesetRatingGender(currentTeeValues[1]);
        let teeSetValues = holes == 18 ? (currentTeeValues[1].substring(1, currentTeeValues[1].length-1)).split("/") : (currentTeeValues[2].substring(1, currentTeeValues[2].length-1)).split("/");
        setSlopeRating(parseInt(teeSetValues[1]));
        setCourseRating(parseFloat(teeSetValues[0]));
        setPar(parseInt(teeSetValues[2]));
        setGender(current_user.gender);
        let tees = await utils.getCourseDetails(courseid) || [];
        if(tour_format === '1~Peoria based') {
            if(pull_scores_from === "GHIN"){
                let x = 36;
                let courseHandicap = holes == 18 ? ((current_user.handicap_index*(parseFloat(teeSetValues[1])/113)) + (parseFloat(teeSetValues[0]) - parseInt(teeSetValues[2]))).toFixed(2) : 
                (((current_user.handicap_index/2)*(parseFloat(teeSetValues[1])/113)) + (parseFloat(teeSetValues[0]) - parseInt(teeSetValues[2]))).toFixed(2);
                var roundQuota = x-Math.floor(parseFloat(courseHandicap));
                setPlayingQuota(roundQuota);
                setTargetTees('GHIN');
            } else {
                let hc = 36 - current_user.quota;
                let phc = hc * parseFloat(teeSetValues[1]) / 113.0;
                phc = phc + (parseFloat(teeSetValues[0]) - parseInt(teeSetValues[2]));
                let pq = 36 - phc;
                if(pq < 9)
                    pq = 9;
                console.log("1~ Peoria Based Playing Quota: "+pq);
                if(holes == 18) {
                    pq = ceil(pq);
                    setPlayingQuota(ceil(pq));
                    setTargetTees(current_user.preferred_tee);
                } else {
                    setPlayingQuota(ceil(pq/2));
                    setTargetTees(current_user.preferred_tee)
                }
            }
        } else if(tour_format === '2~1:1 Matches') {
            if(pull_scores_from === "GHIN"){
                let x = 36;
                let courseHandicap = holes == 18 ? ((current_user.handicap_index*(parseFloat(teeSetValues[1])/113)) + (parseFloat(teeSetValues[0]) - parseInt(teeSetValues[2]))).toFixed(2) : 
                (((current_user.handicap_index/2)*(parseFloat(teeSetValues[1])/113)) + (parseFloat(teeSetValues[0]) - parseInt(teeSetValues[2]))).toFixed(2);
                var roundQuota = x-Math.floor(parseFloat(courseHandicap));
                setPlayingQuota(roundQuota);
                setTargetTees('GHIN');
            } else {
                let hc = 36 - current_user.quota;
                let phc = hc * parseFloat(teeSetValues[1]) / 113.0;
                phc = phc + (parseFloat(teeSetValues[0]) - parseInt(teeSetValues[2]));
                let pq = 36 - phc;
                if(pq < 9)
                    pq = 9;
                
                console.log("2~1:1 Matches Playing Quota: "+pq);
                if(holes == 18) {
                    setPlayingQuota(ceil(pq));
                    setTargetTees(current_user.preferred_tee);
                } else {
                    setPlayingQuota(ceil(pq/2));
                    setTargetTees(current_user.preferred_tee)
                }
            }
        }
        tees && tees.forEach((tee:any)=>{
            if(holes == 18 && (tee.TeeSetRatingId == parseInt(currentTeeValues[2]))){
                let yardsData = tee.Holes.map((hole:any)=> {return hole.Length}),
                yards = yardsData.reduce((a:number, b:number) => a + b, 0);
                setEditHoleDetails(tee.Holes);
                setYards(yards);
            } else if(holes == 9 && tee.TeeSetRatingId == parseInt(currentTeeValues[3])) {
                let rating = tee.Ratings.filter((rating:any)=> rating.RatingType == 'Total');
                let nineHoleRatings = tee.Ratings.filter((rating:any)=> rating.RatingType != 'Total');
                if(tee.HolesNumber == 9){
                    let yardsData = tee.Holes.map((hole:any)=> {return hole.Length}),
                    yards = yardsData.reduce((a:number, b:number) => a + b, 0);
                    setEditHoleDetails(tee.Holes);
                    setYards(yards);
                } else {
                  for (rating of nineHoleRatings) {
                    var front9Holes = tee.Holes.filter((hole:any)=> hole.Number <= 9);
                    var back9Holes = tee.Holes.filter((hole:any)=> hole.Number > 9);
                    if(rating.RatingType == 'Front'){
                        let yardsData = front9Holes.map((hole:any)=> {return hole.Length}),
                        yards = yardsData.reduce((a:number, b:number) => a + b, 0);
                        if(rating.RatingType == currentTeeValues[1] && tee.TeeSetRatingId == currentTeeValues[3]) {
                            setEditHoleDetails(front9Holes);
                            setTeetype("Front");
                        }
                        setYards(yards);
                    }
                    if(rating.RatingType == 'Back'){
                        let yardsData = back9Holes.map((hole:any)=> {return hole.Length}),
                        yards = yardsData.reduce((a:number, b:number) => a + b, 0);
                        if(rating.RatingType == currentTeeValues[1] && tee.TeeSetRatingId == currentTeeValues[3]) {
                            setEditHoleDetails(back9Holes);
                            setTeetype("Back");
                        }
                        setYards(yards);
                    }
                  }
                }
            } 
        });
    }

    const createIntlScore = async()=> {
        if(country_name == '' || country_name?.length <= 0) {
            presentToast("Country name is required", 2000);
            setShowStartLoading(false);
            return;
        }
        if(city_name == '' || city_name?.length <= 0) {
            presentToast("City name is required", 2000);
            setShowStartLoading(false);
            return;
        }
        if(course_name == '' || course_name?.length <= 0) {
            presentToast("Course Name is required", 2000);
            setShowStartLoading(false);
            return;
        }
        if(sel_tees == '' || sel_tees?.length <= 0) {
            presentToast("Tees is required", 2000);
            setShowStartLoading(false);
            return;
        }
        var hole_details = [];
        for(let i = 0;i<Number(holes);i++) {
            var hole_detail = {
                raw_score :  4,
                points : 0,
                par : 0,
                hole_id : Number(i)+1,
                hole_number : Number(i)+1
            };
            hole_details.push(hole_detail);
        }
        let competition_relation_id = await utils.randomString(12, '#aA');
        let player = all_users && all_users.filter((x:any)=> x.user_id == selected_player_id);
        setCourseDisplayValue(course_name);
        var score_obj = {
            course_name: course_name,
            country_name: country_name,
            city_name: city_name,
            competition_relation_id: competition_relation_id,
            competition_date: format(new Date(selected_date),"yyyy-MM-dd"),
            competition_date_timestamp: new Date(selected_date).getTime(),
            competition_setby: current_user.user_id,
            tee: sel_tees,
            game_date_time: selected_time,
            user_name: current_user.first_name+' '+current_user.last_name,
            course_id: 0,
            teeset_id: 0,
            user_id: current_user.user_id,
            quota: holes==9 ? Math.ceil(current_user.quota/2) : current_user.quota,
            holes: Number(holes),
            yards: 0,
            price: 0,
            format: tour_format,
            season_name: season_name,
            course_rating: 0,
            slope_rating: 0,
            target_tees: sel_tees,
            tour_name: tour.tour_name,
            tour_id: tour.id,
            gender: gender,
            tee_type: holes == 9 ? "Front" : "Back",
            par: 0,
            hole_details: hole_details,
            partner_accepted: false,
            user_accepted: true,
            is_score_added: false,
            score_submitted: false,
            status: (tour_format == "1~Peoria based" || tour_format === "3~Intl Peoria") ? "Inactive" : "Active",
            individual_players: (player && player.length>0)? [{
                user_name: player[0].first_name +' '+player[0].last_name,
                user_id: player[0].user_id,
                quota: player[0].quota
            }]:[],
            points:{
                "triple_bogey": 0,
                "double_bogey": 0,
                "bogey": 0,
                "par_value": 0,
                "birdie": 0,
                "eagle": 0,
                "double_eagle": 0,
                "total_points": 0,
                "front_par_total": 0,
                "back_par_total": 0,
                "front_score_total": 0,
                "back_score_total": 0,
                "front_points_total": 0,
                "back_points_total": 0
            },
            score_id: ''
        }
        if(tour_format == "2~1:1 Matches") {
            var player_score_obj = {
                course_name: course_name,
                competition_relation_id: competition_relation_id,
                competition_date: format(new Date(selected_date),"yyyy-MM-dd"),
                competition_date_timestamp: new Date(selected_date).getTime(),
                competition_setby: current_user.user_id,
                tee: sel_tees,
                game_date_time: selected_time,
                user_name: player.length>0 && player[0].first_name && player[0].last_name? player[0].first_name +' '+player[0].last_name:[],
                course_id: 0,
                teeset_id: 0,
                user_id: player.length>0 && player[0].user_id ? player[0].user_id : '',
                quota: player.length>0 && player[0].quota ? player[0].quota : 0,
                holes: Number(holes),
                yards: 0,
                scheduled: false,
                course_rating: 0,
                slope_rating: 0,
                target_tees: sel_tees,
                gender: gender,
                season_name: season_name,
                price: 0,
                format: tour_format,
                par: 0,
                tee_type: sel_tees,
                tour_name: tour.tour_name,
                tour_id: tour.id,
                status: (tour_format == "1~Peoria based" || tour_format == "3~Intl Peoria") ? "Inactive" : "Active",
                hole_details: hole_details,
                partner_accepted: true,
                user_accepted: false,
                is_score_added: false,
                score_submitted: false,
                individual_players: [{
                    user_name: current_user.first_name+' '+current_user.last_name,
                    user_id: current_user.user_id,
                    quota: current_user.quota,
                }],
                points:{
                    "triple_bogey": 0,
                    "double_bogey": 0,
                    "bogey": 0,
                    "par_value": 0,
                    "birdie": 0,
                    "eagle": 0,
                    "double_eagle": 0,
                    "total_points": 0,
                    "front_par_total": 0,
                    "back_par_total": 0,
                    "front_score_total": 0,
                    "back_score_total": 0,
                    "front_points_total": 0,
                    "back_points_total": 0
                },
                score_id: ''
            }
            score_obj.score_id = await createEntryWithoutId("SCORE_RECORDS", score_obj);
            delete score_obj.hole_details;
            await createEntryWithoutId("TT_COMPETITIONS", score_obj);
            player_score_obj.score_id = await createEntryWithoutId("SCORE_RECORDS", player_score_obj);
            delete player_score_obj.hole_details;
            let doc_id = await createEntryWithoutId("TT_COMPETITIONS", player_score_obj);
            let source = {
                id: doc_id,
                name: player_score_obj.course_name || '',
                date: format(new Date(selected_date),"MM/dd/yyyy"),
                time: player_score_obj.game_date_time,
                image_url: '',
                format: tour_format,
                tee_name: player_score_obj.tee,
                pack_id: '',
                pack_name: '',
                competition_relation_id: player_score_obj.competition_relation_id,
                season: 5,
                type: 'TT',
                tour_name: tour_name,
                tee_type: tee_type,
                season_name: season_name
            }
            await createEntryWithoutId("CHECKIN_NOTIFICATIONS", {
                type: 'TT',
                created_name: score_obj.user_name,
                created_by: score_obj.user_id,
                user_id: player_score_obj.user_id,
                user_name: player_score_obj.user_name,
                source: source,
                is_best: false,
                pack_id: '',
                pack_name: '',
                is_read: false,
                season: 5,
                season_name: season_name,
                created_at: new Date().getTime(),
                updated_at: new Date().getTime()
            });
            setShowStartLoading(false);
            presentAlert({
                header: 'Your match is added to the schedule',
                message: 'Please visit schedule page to post score',
                buttons: [
                    'Cancel',
                    { 
                        text: 'Ok',
                        handler: (d:any) => {
                            window.location.href="/twilightour_checkins/"+tour.id;
                        }
                    },
                ],
                onDidDismiss: (e:any) => console.log('did dismiss'),
            });
        } else {
            console.log("score object");
            console.log(score_obj);
            score_obj.score_id = await createEntryWithoutId("SCORE_RECORDS", score_obj);
            //delete score_obj.hole_details;
            let score_id = await createEntryWithoutId("TT_COMPETITIONS", score_obj);
            if(score_obj.holes == 9) {
                setFrontHoles(true);
                setBackHoles(false);
            }
            setTtScoreId(score_id);
            setScoreId(score_obj.score_id);
            setHoles(score_obj.holes);
            setScoreRecord(score_obj);
            setEditHoleDetails(score_obj.hole_details);
            setShowStartLoading(false);
            setHolePoints(score_obj.points);
            setIntlScoreModal(true);
            
        }
    }

    const createScore = async()=> {
        if(partner_required == "Yes" && (selected_player_id == '' || selected_player_id.length == 0)){
            presentToast("A Playing partner is required", 2000);
            return;
        }
        let selected_course = course_list.filter((x:any)=> x.CourseID == course_id);
        var hole_details = edit_hole_details.map((hole:any)=>{
            hole.raw_score = 0;
            hole.points_type = null;
            hole.points = 0;
            hole.par = hole.Par;
            hole.allocation = hole.Allocation;
            hole.hole_id = hole.HoleId;
            hole.length = hole.Length;
            hole.hole_number = hole.Number;
            delete hole.Allocation;
            delete hole.HoleId;
            delete hole.Length;
            delete hole.Number;
            delete hole.Par;
            return hole;
        });
        let competition_relation_id = await utils.randomString(12, '#aA');
        let player = all_users.filter((x:any)=> x.user_id == selected_player_id);
        let courseStr = selected_course[0].FullName.split("-");
        if (courseStr[0] != undefined || courseStr[0] != null)
        setCourseDisplayValue(courseStr[0])
        if (courseStr[1] != undefined || courseStr[1] != null)
        setCourseDisplayValue(courseStr[1])
        var score_obj = {
            course_name: selected_course[0].FullName,
            competition_relation_id: competition_relation_id,
            competition_date: format(new Date(selected_date),"yyyy-MM-dd"),
            competition_date_timestamp: new Date(selected_date).getTime(),
            competition_setby: current_user.user_id,
            tee: teeset_rating_name,
            game_date_time: selected_time,
            user_name: current_user.first_name+' '+current_user.last_name,
            course_id: selected_course[0].CourseID,
            teeset_id: teeset_rating_id,
            user_id: current_user.user_id,
            quota: playing_quota,
            holes: Number(holes),
            yards: yards,
            price: 0,
            format: tour_format,
            season_name: season_name,
            course_rating: course_rating,
            slope_rating: slope_rating,
            target_tees: target_tees,
            tour_name: tour.tour_name,
            tour_id: tour.id,
            gender: gender,
            tee_type: tee_type,
            par: par,
            hole_details: hole_details,
            partner_accepted: false,
            user_accepted: true,
            is_score_added: false,
            score_submitted: false,
            status: (tour_format == "1~Peoria based" || tour_format === "3~Intl Peoria") ? "Inactive" : "Active",
            individual_players: player.length>0? [{
                user_name: player[0].first_name +' '+player[0].last_name,
                user_id: player[0].user_id,
                quota: player[0].quota
            }]:[],
            points:{
                "triple_bogey": 0,
                "double_bogey": 0,
                "bogey": 0,
                "par_value": 0,
                "birdie": 0,
                "eagle": 0,
                "double_eagle": 0,
                "total_points": 0,
                "front_par_total": 0,
                "back_par_total": 0,
                "front_score_total": 0,
                "back_score_total": 0,
                "front_points_total": 0,
                "back_points_total": 0
            },
            score_id: ''
        }
        if(tour_format == "2~1:1 Matches") {
            var player_score_obj = {
                course_name: selected_course[0].FullName,
                competition_relation_id: competition_relation_id,
                competition_date: format(new Date(selected_date),"yyyy-MM-dd"),
                competition_date_timestamp: new Date(selected_date).getTime(),
                competition_setby: current_user.user_id,
                tee: teeset_rating_name,
                game_date_time: selected_time,
                user_name: player.length>0 && player[0].first_name && player[0].last_name? player[0].first_name +' '+player[0].last_name:[],
                course_id: selected_course[0].CourseID,
                teeset_id: teeset_rating_id,
                user_id: player.length>0 && player[0].user_id ? player[0].user_id : '',
                quota: player.length>0 && player[0].quota ? player[0].quota : 0,
                holes: Number(holes),
                yards: yards,
                scheduled: false,
                course_rating: course_rating,
                slope_rating: slope_rating,
                target_tees: target_tees,
                gender: gender,
                season_name: season_name,
                price: 0,
                format: tour_format,
                par: par,
                tee_type: tee_type,
                tour_name: tour.tour_name,
                tour_id: tour.id,
                status: (tour_format == "1~Peoria based" || tour_format == "3~Intl Peoria") ? "Inactive" : "Active",
                hole_details: hole_details,
                partner_accepted: true,
                user_accepted: false,
                is_score_added: false,
                score_submitted: false,
                individual_players: [{
                    user_name: current_user.first_name+' '+current_user.last_name,
                    user_id: current_user.user_id,
                    quota: current_user.quota,
                }],
                points:{
                    "triple_bogey": 0,
                    "double_bogey": 0,
                    "bogey": 0,
                    "par_value": 0,
                    "birdie": 0,
                    "eagle": 0,
                    "double_eagle": 0,
                    "total_points": 0,
                    "front_par_total": 0,
                    "back_par_total": 0,
                    "front_score_total": 0,
                    "back_score_total": 0,
                    "front_points_total": 0,
                    "back_points_total": 0
                },
                score_id: ''
            }
            score_obj.score_id = await createEntryWithoutId("SCORE_RECORDS", score_obj);
            delete score_obj.hole_details;
            await createEntryWithoutId("TT_COMPETITIONS", score_obj);
            player_score_obj.score_id = await createEntryWithoutId("SCORE_RECORDS", player_score_obj);
            delete player_score_obj.hole_details;
            let doc_id = await createEntryWithoutId("TT_COMPETITIONS", player_score_obj);
            let source = {
                id: doc_id,
                name: player_score_obj.course_name || '',
                date: format(new Date(selected_date),"MM/dd/yyyy"),
                time: player_score_obj.game_date_time,
                image_url: '',
                format: tour_format,
                tee_name: player_score_obj.tee,
                pack_id: '',
                pack_name: '',
                competition_relation_id: player_score_obj.competition_relation_id,
                season: 5,
                type: 'TT',
                tour_name: tour_name,
                tee_type: tee_type,
                season_name: season_name
            }
            await createEntryWithoutId("CHECKIN_NOTIFICATIONS", {
                type: 'TT',
                created_name: score_obj.user_name,
                created_by: score_obj.user_id,
                user_id: player_score_obj.user_id,
                user_name: player_score_obj.user_name,
                source: source,
                is_best: false,
                pack_id: '',
                pack_name: '',
                is_read: false,
                season: 5,
                season_name: season_name,
                created_at: new Date().getTime(),
                updated_at: new Date().getTime()
            });
            setShowStartLoading(false);
            presentAlert({
                header: 'Your match is added to the schedule',
                message: 'Please visit schedule page to post score',
                buttons: [
                    'Cancel',
                    { 
                        text: 'Ok',
                        handler: (d:any) => {
                            window.location.href="/twilightour_checkins/"+tour.id;
                        }
                    },
                ],
                onDidDismiss: (e:any) => console.log('did dismiss'),
            });
        } else {
            console.log("score object");
            console.log(score_obj);
            score_obj.score_id = await createEntryWithoutId("SCORE_RECORDS", score_obj);
            //delete score_obj.hole_details;
            let score_id = await createEntryWithoutId("TT_COMPETITIONS", score_obj);
            if(score_obj.holes == 9) {
                setFrontHoles(tee_type == "Front" ? true : false);
                setBackHoles(tee_type == "Back" ? true : false);
            }
            setTtScoreId(score_id);
            setScoreId(score_obj.score_id);
            setHoles(score_obj.holes);
            setScoreRecord(score_obj);
            setEditHoleDetails(score_obj.hole_details);
            setShowStartLoading(false);
            setHolePoints(score_obj.points);
            setPpwScoreModal(true);
            
            //delete score_obj.hole_details;
            //let doc_id = await createEntryWithoutId("TT_COMPETITIONS", score_obj);
            // presentAlert({
            //     header: 'Your match is added to the schedule',
            //     message: 'Please visit schedule page to post score',
            //     buttons: [
            //         'Cancel',
            //         { 
            //             text: 'Ok',
            //             handler: (d:any) => {
            //                 window.location.href="/twilightour_checkins"
            //             }
            //         },
            //     ],
            //     onDidDismiss: (e:any) => console.log('did dismiss'),
            // });
        }
    }
  
    const states = [{"state_code":"US-AL","state_name":"Alabama"},
      {"state_code":"US-AK","state_name":"Alaska"},
      {"state_code":"US-AZ","state_name":"Arizona"},
      {"state_code":"US-AR","state_name":"Arkansas"},
      {"state_code":"US-CA","state_name":"California"},
      {"state_code":"US-CO","state_name":"Colorado"},
      {"state_code":"US-CT","state_name":"Connecticut"},
      {"state_code":"US-DE","state_name":"Delaware"},
      {"state_code":"US-DC","state_name":"District Of Columbia"},
      {"state_code":"US-FL","state_name":"Florida"},
      {"state_code":"US-GA","state_name":"Georgia"},
      {"state_code":"US-HI","state_name":"Hawaii"},
      {"state_code":"US-ID","state_name":"Idaho"},
      {"state_code":"US-IL","state_name":"Illinois"},
      {"state_code":"US-IN","state_name":"Indiana"},
      {"state_code":"US-IA","state_name":"Iowa"},
      {"state_code":"US-KS","state_name":"Kansas"},
      {"state_code":"US-KY","state_name":"Kentucky"},
      {"state_code":"US-LA","state_name":"Louisiana"},
      {"state_code":"US-ME","state_name":"Maine"},
      {"state_code":"US-MD","state_name":"Maryland"},
      {"state_code":"US-MA","state_name":"Massachusetts"},
      {"state_code":"US-MI","state_name":"Michigan"},
      {"state_code":"US-MN","state_name":"Minnesota"},
      {"state_code":"US-MS","state_name":"Mississippi"},
      {"state_code":"US-MO","state_name":"Missouri"},
      {"state_code":"US-MT","state_name":"Montana"},
      {"state_code":"US-NE","state_name":"Nebraska"},
      {"state_code":"US-NV","state_name":"Nevada"},
      {"state_code":"US-NH","state_name":"New Hampshire"},
      {"state_code":"US-NJ","state_name":"New Jersey"},
      {"state_code":"US-NM","state_name":"New Mexico"},
      {"state_code":"US-NY","state_name":"New York"},
      {"state_code":"US-NC","state_name":"North Carolina"},
      {"state_code":"US-ND","state_name":"North Dakota"},
      {"state_code":"US-OH","state_name":"Ohio"},
      {"state_code":"US-OK","state_name":"Oklahoma"},
      {"state_code":"US-OR","state_name":"Oregon"},
      {"state_code":"US-PA","state_name":"Pennsylvania"},
      {"state_code":"US-RI","state_name":"Rhode Island"},
      {"state_code":"US-SC","state_name":"South Carolina"},
      {"state_code":"US-SD","state_name":"South Dakota"},
      {"state_code":"US-TN","state_name":"Tennessee"},
      {"state_code":"US-TX","state_name":"Texas"},
      {"state_code":"US-UT","state_name":"Utah"},
      {"state_code":"US-VT","state_name":"Vermont"},
      {"state_code":"US-VA","state_name":"Virginia"},
      {"state_code":"US-WA","state_name":"Washington"},
      {"state_code":"US-WV","state_name":"West Virginia"},
      {"state_code":"US-WI","state_name":"Wisconsin"},
      {"state_code":"US-WY","state_name":"Wyoming"}
    ];
    const [new_states,setNewStates] = useState<any>(states);
    const [new_courses,setNewCourses] = useState<any>([]);
    const [new_tees,setNewTees] = useState<any>(tees_list);
    const [new_players,setNewPlayers] = useState<any>();

    useEffect(() => {
        let user = localStorage.getItem('firebase_user');
        if(user != null) {
          setCurrentUser(JSON.parse(user));
          //setPlayingQuota(JSON.parse(user).quota);
        }
        if (id != null) {
            setTourId(id);
            getTourData(id);
        }
        utils.usgaAdminLogin();
    }, []);
    const [showStateModal, setShowStateModal] = useState(false);
    const [showCourseModal, setShowCourseModal] = useState(false);
    const [showTeesModal, setShowTeesModal] = useState(false);
    const [showPlayersModal, setShowPlayersModal] = useState(false);
    return (
        <IonPage>
             <IonHeader mode="ios">
             <IonToolbar>
                <IonLabel slot="start" className="s-18 w-800 color-blue" style={{padding: '0px 0px 0px 10px'}}>
                    {tour_name}
                </IonLabel>
            </IonToolbar>
             </IonHeader>
            <IonContent>
                <IonGrid>
                    <>
                        <IonRow className="ion-justify-content-center mt-10 ion-text-center">
                            <IonText className="wg-600 black s-18 myclub-header">{current_user.first_name+' '+current_user.last_name}</IonText>
                        </IonRow>
                        {   tour_format === "3~Intl Peoria" ? (
                                <>
                                    <IonRow className="ion-justify-content-center mt-10 select-modal">
                                        <IonInput value={country_name} type="text"              
                                                    placeholder="Country" className="s-14 select-pack-dropdown pl-20" 
                                                    mode="ios" onIonChange={(e:any) => {                                  
                                                        setCountryName(e.target.value)                                                                                                                                          
                                                    }}>
                                        </IonInput>
                                    </IonRow>
                                    <IonRow className="ion-justify-content-center mt-10 select-modal">
                                        <IonInput value={city_name} type="text"               
                                                    placeholder="City" className="s-14 select-pack-dropdown pl-20" 
                                                    mode="ios" onIonChange={(e:any) => {                                  
                                                        setCityName(e.target.value)                                                                                                                                          
                                                    }}>
                                        </IonInput>
                                    </IonRow>
                               </>
                            ) : (
                                <></>
                            )
                        }
                               
                        {
                            fixed_courses === "Yes" || tour_format === "3~Intl Peoria" ? (
                                <></>
                            ) : (
                                <IonRow className="ion-justify-content-center mt-10 select-modal">
                                    <IonInput value={select_state}               
                                        placeholder="Select State" className="s-14 select-pack-dropdown pl-20" 
                                        mode="ios" onClick={()=>{
                                            setShowStateModal(true)
                                            setNewStates(states)
                                        }}>
                                    </IonInput>
                                </IonRow>
                            )
                        }
                        
                        {
                            tour_format === "3~Intl Peoria" ? (
                                <IonRow className="ion-justify-content-center mt-10 select-modal">
                                    <IonInput value={course_name} type="text"            
                                                placeholder="Course Name" className="s-14 select-pack-dropdown pl-20" 
                                                mode="ios" onIonChange={(e:any) => {                                  
                                                    setCourseName(e.target.value)                                                                                                                                          
                                                }}>
                                    </IonInput>
                                </IonRow>
                            ) : (
                                <IonRow className="ion-justify-content-center mt-10 select-modal">
                                    <IonInput value={course}                  
                                        placeholder="Select Course" className="s-14 select-pack-dropdown pl-20" 
                                        mode="ios" onClick={()=>{
                                            setShowCourseModal(true);
                                        }}>
                                    </IonInput>     
                                </IonRow>
                            )
                        }

                        {
                            holes == 9 && course.length > 0 ? (
                                <IonRow className="ion-padding">
                                    <IonSegment value={holes} mode="ios">
                                        <IonSegmentButton value="9">
                                        <IonLabel>9 Holes</IonLabel>
                                        </IonSegmentButton>
                                    </IonSegment>
                                </IonRow>
                            ) : holes == 18 && course.length > 0 ? (
                                <IonRow className="ion-padding">
                                    <IonSegment value={holes} mode="ios">
                                        <IonSegmentButton value="18">
                                        <IonLabel>18 Holes</IonLabel>
                                        </IonSegmentButton>
                                    </IonSegment>
                                </IonRow>
                            ) : (
                                <></>
                            )
                        }
                        {/* <IonRow className="ion-padding">
                            <IonSegment onIonChange={(e:any) => {                                
                                setHoles(e.detail.value)
                                getTees(course_id, e.detail.value)
                                }} value={holes} mode="ios">
                                <IonSegmentButton value="18">
                                <IonLabel>18 Holes</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="9">
                                <IonLabel>9 Holes</IonLabel>
                                </IonSegmentButton>
                            </IonSegment>
                        </IonRow> */}
                        {
                            tour_format === "3~Intl Peoria" ? (
                                <IonRow className="ion-justify-content-center mt-10 select-modal">
                                    <IonInput value={sel_tees} type="text"              
                                                placeholder="tees" className="s-14 select-pack-dropdown pl-20" 
                                                mode="ios" onIonChange={(e:any) => {                                  
                                                    setSelTees(e.target.value)                                                                                                                                          
                                                }}>
                                    </IonInput>
                                </IonRow>
                            ) : (
                                <IonRow className="ion-justify-content-center mt-10 select-modal">
                                    <IonInput value={select_tees}                
                                        placeholder="Select Tee" className="s-14 select-pack-dropdown pl-20" 
                                        mode="ios" onClick={()=>{
                                            setShowTeesModal(true)
                                            setNewTees(tees_list)
                                        }}>
                                    </IonInput>
                                </IonRow>
                            )
                        }
                        {
                            target_tees && target_tees.length > 0 ? (
                                <IonRow className="ion-justify-content-center mt-10 ion-text-center">
                                    <IonText className="black s-15 myclub-header">Playing Target: {playing_quota}</IonText>
                                </IonRow>
                            ) : (
                                <></>
                            )
                        }
                        
                       {/*  <IonRow className="ion-justify-content-center mt-10 ion-text-center">
                            <IonText className="black s-15 myclub-header">Playing Target: {holes == 18 ? playing_quota : playing_quota/2}</IonText>
                        </IonRow> */}
                        <IonRow className="ion-justify-content-center mt-10 ion-text-center">
                            <IonText className="black s-12">Date &#38; Tee time</IonText>
                        </IonRow>
                        <IonRow className="ion-justify-content-center mt-5 mb-10" style={{padding:"0px 10px 0px 10px"}}>
                            <IonCol className="pad-0">
                                <IonInput value={selected_date}            
                                    placeholder="Select date" className="s-14 select-pack-dropdown pl-20" 
                                    mode="ios" 
                                    onClick={()=> {
                                        setShowDatePicker(true);
                                    }}
                                    disabled={set_disabled_date}>
                                </IonInput>
                                {
                                    (showDatePicker && !set_disabled_date) ? (
                                        <IonDatetime
                                            presentation="date"
                                            size='cover'
                                            onIonChange={(e) => {
                                                setSelectedDate(format(new Date(e.detail.value!), "MM/dd/yyyy"));
                                                setShowDatePicker(false);
                                            }}
                                        ></IonDatetime>
                                    ) : (
                                        <></>
                                    )
                                }
                            </IonCol>
                            <IonCol className="pad-0">
                                <IonInput value={selected_time}            
                                    placeholder="tee time" className="s-14 select-pack-dropdown pl-20" 
                                    mode="ios" 
                                    type="time"
                                    onClick={()=> {
                                        //setShowTimePicker(true);
                                    }}
                                    onIonChange={(e)=>{
                                        setSelectedTime(e.detail.value!);
                                    }
                                    }
                                    style={{height: '39px'}}>
                                </IonInput>
                                {/* {
                                    (showTimePicker) ? (
                                        <IonDatetime
                                            presentation="time"
                                            size='cover'
                                            onIonChange={(e) => {
                                                setSelectedTime(format(new Date(e.detail.value!), "HH:mm"));
                                                setShowTimePicker(false);
                                            }}
                                        ></IonDatetime>
                                    ) : (
                                        <></>
                                    )
                                } */}
                            </IonCol>
                        </IonRow>
                        {
                        (tour_format == "3~Intl Peoria") ? (
                            <></>
                        ) : (
                            <IonRow className="ion-justify-content-center select-modal">
                                <IonInput value={selected_player}              
                                    placeholder="Playing with" className="s-14 select-pack-dropdown pl-20" 
                                    mode="ios" onClick={()=>{
                                        setShowPlayersModal(true);
                                        setNewPlayers(tour_players.filter((x:any)=> x.user_id !== current_user.user_id))
                                    }}>
                                </IonInput>
                            </IonRow>
                        )
                        }
                    </>
                    {
                        (tour_format == "1~Peoria based") ? (
                            <IonRow className="ion-justify-content-center ion-text-center mt-25">
                                <button className='button-send white s-15 bg button-border-20' onClick={()=>{
                                    setShowStartLoading(true);
                                    createScore();}}>                         
                                    <IonText className="wg-bold">Start</IonText>
                                </button>
                            </IonRow>
                        ) : (tour_format == "3~Intl Peoria") ? (
                            <IonRow className="ion-justify-content-center ion-text-center mt-25">
                                <button className='button-send white s-15 bg button-border-20' onClick={()=>{
                                    setShowStartLoading(true);
                                    createIntlScore();}}>                         
                                    <IonText className="wg-bold">Start</IonText>
                                </button>
                            </IonRow>
                    
                        ) : (
                            <IonRow className="ion-justify-content-center ion-text-center mt-25">
                                <button className='button-send white s-15 bg button-border-20' onClick={()=>{
                                    setShowStartLoading(true);
                                    createScore();}}>                            
                                    <IonText className="wg-bold">Set a match</IonText>
                                </button>
                            </IonRow>
                        )
                    }
                </IonGrid>
                <IonModal isOpen={ppw_score_modal} className='my-custom-class' onDidDismiss={()=>{setPpwScoreModal(false);}}>
                    <>
                        <IonHeader>
                            <IonToolbar>
                            <IonLabel slot="start" className="s-1 w-600 color-blue">{course_display_value} - {score_record.tee}</IonLabel>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <EditTable options={score_record} options1={edit_hole_details} options2={hole_points} score_id={score_id} tt_score_id={tt_score_id} holes={holes} front9={front_holes} back9={back_holes} tourId={tour_id} parentCallback={handleCallback}/>
                            <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button">
                                <IonFabButton
                                className="main-fab"
                                onClick={() => {
                                    window.location.href="/leagues/formats"
                                }}
                                >
                                <IonIcon icon={arrowBackOutline} />
                                </IonFabButton>
                            </IonFab>
                        </IonContent>
                    </>
                </IonModal>
                <IonModal isOpen={intl_score_modal} className='my-custom-class' onDidDismiss={()=>{setIntlScoreModal(false);}}>
                    <>
                        <IonHeader>
                            <IonToolbar>
                            <IonLabel slot="start" className="s-1 w-600 color-blue">{course_display_value} - {score_record.tee}</IonLabel>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <IntlTtEditTable options={score_record} options1={edit_hole_details} options2={hole_points} score_id={score_id} tt_score_id={tt_score_id} holes={holes} front9={front_holes} back9={back_holes} tourId={tour_id} parentCallback={handleCallback}/>
                            <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button">
                                <IonFabButton
                                className="main-fab"
                                onClick={() => {
                                    window.location.href="/leagues/formats"
                                }}
                                >
                                <IonIcon icon={arrowBackOutline} />
                                </IonFabButton>
                            </IonFab>
                        </IonContent>
                    </>
                </IonModal>
                <IonModal isOpen={showStateModal} className='my-custom-class2'  showBackdrop={true} backdropDismiss={true} 
                onDidDismiss={() => setShowStateModal(false)}
                initialBreakpoint={0.5} onDidPresent={()=> {setTimeout(() => inputRef.current.setFocus(), 100)}} mode="ios">
                    <>
                   
                    <IonSearchbar ref={(ref) => inputRef.current = ref} onIonChange={(e:any) => {
                                if(String(e.detail.value!).length>0){
                                    let glo = new RegExp(String(e.detail.value!).toLowerCase());
                                    let new_city_arr = new Array() 
                                    states.map((item:any)=>{
                                        let real_item = item
                                        item = item.state_name.toLowerCase()

                                        if(glo.test(item)){
                                            new_city_arr.push(real_item)
                                        }
                                    })                          
                                    setNewStates(new_city_arr)                                    
                                }
                                else{
                                    setNewStates(states)
                                }
                                }} mode="ios"></IonSearchbar>

                    <IonContent className='content-height'>
                        <IonGrid>
                            <IonList>
                                    {
                                    (new_states && new_states.map((key:any,i:number)=>{
                                        return(
                                                <IonItem key={i}>
                                                    <IonLabel onClick={()=>{
                                                        setSelectState(key.state_name)
                                                        getCourses(key.state_code)
                                                        setShowStateModal(false)

                                                    }}>
                                                    {key.state_name}
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        }))
                                    }                                    
                                
                            </IonList>
                        </IonGrid>
                    </IonContent>
                    </>
                </IonModal>
                <IonModal isOpen={showCourseModal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true} 
                onDidDismiss={() => setShowCourseModal(false)}
                initialBreakpoint={0.5} onDidPresent={()=> {/*setTimeout(() => inputRef.current.setFocus(), 2000)*/}} mode="md">
                    <>
                    
                    <IonSearchbar ref={(ref) => inputRef.current = ref} onIonChange={(e:any) => {
                                if(String(e.detail.value!).length>0){
                                    let glo = new RegExp(String(e.detail.value!).toLowerCase());
                                    let new_course_arr = new Array();
                                    course_list.map((item:any)=>{
                                        let real_item = item
                                        item = item.FullName.toLowerCase()

                                        if(glo.test(item)){
                                            new_course_arr.push(real_item)
                                        }
                                    })                         
                                    setNewCourses(new_course_arr)                                    
                                }
                                else{
                                    setNewCourses(course_list)
                                }
                                }} mode="ios"></IonSearchbar>

                    <IonContent className='content-height'>
                        <IonGrid>
                            <IonList>
                                    {
                                    (new_courses && new_courses.map((key:any,i:number)=>{
                                        return(
                                                <IonItem key={i}>
                                                    <IonLabel onClick={()=>{
                                                        setCourse(key.FullName)
                                                        setCourseId(key.CourseID)
                                                        getTees(key.CourseID!, holes)
                                                        getUsers()
                                                        setShowCourseModal(false)
                                                    }}>
                                                    {key.FullName}
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        }))
                                    }                                    
                                
                            </IonList>
                        </IonGrid>
                    </IonContent>
                    </>
                </IonModal>
                <IonModal isOpen={showTeesModal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true} 
                onDidDismiss={() => setShowTeesModal(false)}
                initialBreakpoint={0.5} onDidPresent={()=> {setTimeout(() => inputRef.current.setFocus(), 1000)}} mode="md">
                    <>
                   
                        <IonSearchbar ref={(ref) => inputRef.current = ref} onIonChange={(e:any) => {
                            if(String(e.detail.value!).length>0){
                                let glo = new RegExp(String(e.detail.value!).toLowerCase());
                                let new_tees_arr = new Array() 
                                tees_list.map((item:any)=>{
                                    let real_item = item
                                    item = item.teeName.toLowerCase()

                                    if(glo.test(item)){
                                        new_tees_arr.push(real_item)
                                    }
                                })                             
                                setNewTees(new_tees_arr)                                    
                            }
                            else{
                                setNewTees(tees_list)
                            }
                            }} mode="ios">
                        </IonSearchbar>

                        <IonContent className='content-height'>
                            <IonGrid>
                                <IonList>
                                    {
                                        (new_tees && new_tees.map((key:any,i:number)=>{
                                            return(
                                                <IonItem key={i}>
                                                    <IonLabel onClick={()=>{
                                                        setSelectTees(key.teeValue);
                                                        setPlayingQuota(0);
                                                        getTeeDetails(course_id, holes, key.teeValue)
                                                        setShowTeesModal(false)
                                                    }}>
                                                    {key.teeName}
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        }))
                                    }                                    
                                    
                                </IonList>
                            </IonGrid>
                        </IonContent>
                    </>
                </IonModal>
                <IonModal isOpen={showPlayersModal} className='my-custom-class2' showBackdrop={true} backdropDismiss={true} 
                onDidDismiss={() => setShowPlayersModal(false)}
                initialBreakpoint={0.5} onDidPresent={()=> {setTimeout(() => inputRef.current.setFocus(), 1000)}} mode="md">
                    <>
                   
                        <IonSearchbar ref={(ref) => inputRef.current = ref} onIonChange={(e:any) => {
                            if(String(e.detail.value!).length>0){
                                let glo = new RegExp(String(e.detail.value!).toLowerCase());
                                let new_players_arr = new Array() 
                                new_players.map((item:any)=>{
                                    let real_item = item
                                    item = item.user_name.toLowerCase()

                                    if(glo.test(item)){
                                        new_players_arr.push(real_item)
                                    }
                                })                       
                                setNewPlayers(new_players_arr)                                    
                            }
                            else{
                                setNewPlayers(tour_players.filter((x:any)=> x.user_id !== current_user.user_id))
                            }
                            }} mode="ios">
                        </IonSearchbar>

                        <IonContent className='modal-height'>
                            <IonGrid>
                                <IonList>
                                    {
                                        (new_players && new_players.map((key:any,i:number)=>{
                                            return(
                                                <IonItem key={i}>
                                                    <IonLabel onClick={()=>{
                                                        setSelectedPlayer(key.user_name)
                                                        setSelectedPlayerId(key.user_id)
                                                        setShowPlayersModal(false)
                                                    }}>
                                                    {key.user_name}
                                                    </IonLabel>
                                                </IonItem>
                                            )
                                        }))
                                    }                                    
                                    
                                </IonList>
                            </IonGrid>
                        </IonContent>
                    </>
                </IonModal>
                <IonFab vertical="bottom" horizontal="end" slot="fixed" className="back-fab-button">
                    <IonFabButton
                    className="main-fab"
                    onClick={() => {
                        window.location.href = "/leagues/formats";
                    }}
                    >
                    <IonIcon icon={arrowBackOutline} />
                    </IonFabButton>
                </IonFab>
            </IonContent>
            <IonLoading
                isOpen={showLoading}
                message={'Please wait..'}
            />
            <IonLoading
                isOpen={showCourseLoading}
                message={'loading courses..'}
            />
            <IonLoading
                isOpen={showTeesLoading}
                message={'loading tees..'}
            />
             <IonLoading
                isOpen={showStartLoading}
                message={'Creating Score Card..'}
            />
        </IonPage>
    );
};
export default TwilighTourCreateCheckin;